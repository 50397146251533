/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-layout-form-section',
  templateUrl: './layout-form-section.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFormSectionComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() hasDivider: boolean;

  constructor() {}

  ngOnInit(): void {}
}
