import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseSearchLabelPipe } from './base-search-label.pipe';

@NgModule({
  declarations: [BaseSearchLabelPipe],
  imports: [CommonModule],
  exports: [BaseSearchLabelPipe],
})
export class BaseSearchLabelModule {}
