import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { OrganisationApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';

import {
  ApiResponse,
  IssueModel,
  OrganisationModel,
  OrganisationSearchResultModel,
} from '@fleet/model';

import { BehaviorSubject, Observable } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'fleet-organisation-autocomplete',
  templateUrl: './organisation-autocomplete.component.html',
  styleUrls: ['./organisation-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganisationAutocompleteComponent),
      multi: true,
    },
  ],
  animations: fuseAnimations,
})
export class OrganisationAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  @Input() placeHolder: any;
  @Input() label: string;

  searching: boolean;
  issues: IssueModel[];

  searchControl: UntypedFormControl = new UntypedFormControl(null);

  organisationSearch$: Observable<OrganisationSearchResultModel[]>;
  emptySearch$ = new BehaviorSubject(false);
  searchCompleted = false;
  @Input() prefixIcon = 'search';

  @ViewChild('input') input: ElementRef;

  @Input() initialEmptySearch = false;

  searchComplete = false;
  @Input() mode: string;

  value: OrganisationModel;
  @Output() organisationObjectSelected = new EventEmitter();

  @Output() organisationSelected = new EventEmitter();
  constructor(
    private organisationApiService: OrganisationApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.organisationSearch$ = this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        this.issues = [];
        this.searchComplete = false;

        this.changeDetectorRef.markForCheck();
      }),
      switchMap((value) => {
        const params = this.assembleParams(value);
        return this.organisationApiService.searchOrganisations(params).pipe(
          map((resp: HttpResponse<ApiResponse<OrganisationModel[]>> | any) => {
            this.searchComplete = true;
            this.searching = false;
            this.changeDetectorRef.markForCheck();
            return resp.body.data;
          }),
          catchError((issues: IssueModel[]) => {
            this.issues = issues;
            this.searchComplete = false;
            this.searching = false;
            this.changeDetectorRef.markForCheck();
            return [];
          })
        );
      })
    );
  }

  assembleParams(orgName: string) {
    const params: any = { limit: 10, offset: 0, name: orgName };

    if (orgName === 'empty-search') {
      delete params['name'];
    }

    return params;
  }

  displayFn(organisation?: OrganisationModel): string | undefined {
    return organisation && organisation.name ? organisation.name : '';
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  selectOrganisation(result: any) {
    if (result) {
      this.value = result?.option?.value;

      this.onChange(
        this.mode !== 'id' ? this.value : this.value.organisationId
      );
      this.organisationSelected.emit(this.value);

      this.onTouched();
    }
  }

  writeValue(value: OrganisationModel) {
    if (value) {
      if (!value?.organisationId || typeof value === 'string') {
        this.searching = true;
        this.organisationApiService
          .getOrganisation(
            typeof value === 'string' ? value : value.organisationId
          )
          .pipe(
            map((resp: ApiResponse<OrganisationModel> | any) => resp.data),
            catchError((error) => (this.issues = error)),
            tap(() => (this.searching = false))
          )
          .subscribe((r: OrganisationModel) => {
            this.setNativeElementValue(r);
            this.selectOrganisation({ option: { value: r } });
            this.changeDetectorRef.markForCheck();
          });
      } else {
        this.setNativeElementValue(value);

        this.selectOrganisation({ option: { value: value } });
        this.changeDetectorRef.markForCheck();
      }
    } else {
      this.searchControl.reset();
    }
  }

  private setNativeElementValue(value: any) {
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.value = value ? value?.name : '';
      }
    }, 1);
  }

  onInput(value: any) {
    this.onChange(null);
    this.onTouched();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(c: UntypedFormControl) {
    return c.value
      ? null
      : c.touched
      ? {
          organisationAutocomplete: {
            message:
              'Please ensure search and select an organisation from the list',
          },
        }
      : null;
  }

  inputClick(eventTarget: any) {
    if (
      (this.searchControl.value === '' || this.searchControl.value == null) &&
      this.initialEmptySearch
    ) {
      this.searchControl.setValue('empty-search');
      this.searchControl.updateValueAndValidity();
    } else {
      eventTarget.select();
    }
  }

  initialiseSearching() {
    //Initial search on click when blank
    if (this.searchControl.value === '' || this.searchControl.value == null) {
      this.searchControl.setValue('');
      this.searchControl.updateValueAndValidity();
    }
    setTimeout(() => this.input.nativeElement.focus(), 0);
  }
}
