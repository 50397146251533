import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { OrganisationApiService } from '@fleet/api';
import { delay, catchError, map, tap } from 'rxjs/operators';
import {
  getNextPageParams,
  handleApiError,
  nextPageAvailable,
  pageDataFromSearchResultHeaders,
  shouldGetNextPage,
} from '@fleet/utilities';
import { HttpResponse } from '@angular/common/http';
import {
  ApiResponse,
  BaseSearchState,
  initialBaseSearchState,
  OrganisationGroupModel,
  OrganisationGroupSearchResultModel,
} from '@fleet/model';
import { searchResultFromResponse } from '@fleet/utilities';
import { AuthService } from '@fleet/auth';
import { BreakpointState } from '@angular/cdk/layout';
import { NetworkGroupService } from '@fleet/network-group';

@Injectable({
  providedIn: 'root',
})
export class OrganisationGroupSearchService {
  initialDisplayColumns = [
    'name',
    'type',
    'code',
    'status',
    'parentOrganisationGroupId',
  ];

  organisationGroupSearchSubject: BehaviorSubject<
    BaseSearchState<OrganisationGroupSearchResultModel>
  > = new BehaviorSubject({
    ...initialBaseSearchState,
    displayColumns: this.initialDisplayColumns,
  });

  getAll: BehaviorSubject<boolean> = new BehaviorSubject(null);
  organisationId: string;
  allGroups: BehaviorSubject<OrganisationGroupSearchResultModel[]> =
    new BehaviorSubject(null);

  get searching$() {
    return this.organisationGroupSearchSubject
      .asObservable()
      .pipe(
        map(
          (search: BaseSearchState<OrganisationGroupSearchResultModel>) =>
            search.searching
        )
      );
  }

  constructor(
    private organisationApiService: OrganisationApiService,

    private authService: AuthService,
    private networkGroupService: NetworkGroupService
  ) {
    combineLatest([
      this.organisationGroupSearchSubject.asObservable(),
      this.getAll.asObservable(),
    ])
      .pipe(
        tap(([search, getAll]) => {
          // if (
          //   search.pageData &&
          //   !search.searching &&
          //   search.pageData.currentPage === search.pageData.totalPages
          // ) {
          //   this.allGroups.next(search.data);
          // }
        }),
        map(([search, getAll]) => {
          if (
            getAll &&
            search &&
            nextPageAvailable(search.pageData) &&
            !search.error &&
            !search.searching
          ) {
            return true;
          }

          return false;
        })
      )
      .subscribe((shouldGetNextPage: boolean) => {
        if (shouldGetNextPage) {
          this.getNextPage(this.organisationId);
        }
      });
  }

  getOrganisationGroupSearch() {
    return this.organisationGroupSearchSubject.asObservable();
  }

  get allGroups$() {
    return this.allGroups.asObservable();
  }
  searchOrganisationGroups(params: any, organisationId: string) {
    if (this.authService.networkId) {
      params['networkId'] = this.authService.networkId;
    }
    if (this.networkGroupService.networkGroupId) {
      params['networkGroupId'] = this.networkGroupService.networkGroupId;
    }

    this.organisationGroupSearchSubject.next({
      ...this.organisationGroupSearchSubject.value,
      issues: [],
      searching: true,
    });

    this.organisationApiService
      .searchGroups(params, organisationId)
      .pipe(catchError(handleApiError))
      .subscribe(
        (
          resp:
            | HttpResponse<ApiResponse<OrganisationGroupSearchResultModel[]>>
            | any
        ) => {
          const pageData = pageDataFromSearchResultHeaders(
            resp.headers,
            params
          );
          let data = [];
          if (pageData.currentPage === 1) {
            data = resp.body.data;
          } else {
            data = [
              ...this.organisationGroupSearchSubject.value.data,
              ...resp.body.data,
            ];
          }

          this.organisationGroupSearchSubject.next({
            ...this.organisationGroupSearchSubject.value,
            searching: false,
            data: data,
            filteredData: data,
            pageData: pageData,
          });
          //set
          if (pageData.currentPage === pageData.totalPages) {
            this.allGroups.next(data);
          }
        },
        (error) => {
          this.organisationGroupSearchSubject.next({
            ...this.organisationGroupSearchSubject.value,
            issues: error,
            searching: false,
          });
        }
      );
  }

  setDisplayColumnsByWidth(breakPointState: BreakpointState) {
    let newDisplayColumns =
      this.organisationGroupSearchSubject.value.displayColumns;
    if (breakPointState.breakpoints['(max-width: 480px)']) {
      newDisplayColumns = ['name', 'code'];
    } else if (breakPointState.breakpoints['(min-width: 1025px)']) {
      newDisplayColumns = [
        'name',
        'type',
        'code',
        'status',
        'parentOrganisationGroupId',
      ];
    }
    this.organisationGroupSearchSubject.next({
      ...this.organisationGroupSearchSubject.value,
      displayColumns: newDisplayColumns,
    });
  }

  setGetAll(getAll: boolean, organisationId: string) {
    this.organisationId = organisationId;
    this.getAll.next(getAll);
  }

  getNextPage(organisationId: string) {
    const nextPageParams = getNextPageParams(
      this.organisationGroupSearchSubject.value.pageData
    );
    if (nextPageParams) {
      this.searchOrganisationGroups(nextPageParams, organisationId);
    }
  }

  resetOrganisationGroupSearchState() {
    this.organisationGroupSearchSubject.next({
      ...initialBaseSearchState,
      displayColumns: this.initialDisplayColumns,
    });
    this.allGroups.next([]);
  }

  removeParamAndSearch(param: string, organisationId: string) {
    const params = Object.assign(
      {},
      this.organisationGroupSearchSubject.value.pageData.params
    );

    delete params[param];

    this.searchOrganisationGroups(params, organisationId);
  }

  setGroupPreview(group: any) {
    this.organisationGroupSearchSubject.next({
      ...this.organisationGroupSearchSubject.value,
      selectedPreview: group,
    });

    // this.selectedOrganisation.next(organisation);
  }
}
