<div
  class="absolute inset-0 flex flex-col"
  [ngClass]="backgroundClass ? backgroundClass : null"
>
  <div class="flex flex-col flex-auto" cdkScrollable>
    <div
      class="flex flex-row items-center justify-between pr-3 py-6 sm:pr-5 pl-6 sm:pl-8 bg-primary-600 text-white"
    >
      <div class="text-lg font-medium line-clamp-2">{{ title }}</div>
      <button mat-icon-button [tabIndex]="-1" (click)="closeSidebar.emit()">
        <mat-icon
          class="text-current"
          [svgIcon]="'heroicons_outline:x'"
        ></mat-icon>
      </button>
    </div>

    <ng-content></ng-content>
  </div>
</div>
