import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntityDetailLayoutService {
  drawOpened: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor() {}

  get drawOpened$() {
    return this.drawOpened.asObservable();
  }

  setDrawOpened(opened: boolean) {
    this.drawOpened.next(opened);
  }

  toggleDraw() {
    this.drawOpened.next(!this.drawOpened.value);
  }
}
