<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <!-- Header -->

  <mat-drawer-container class="flex-auto h-full dark:bg-transparent" autosize>
    <!-- Drawer -->
    <mat-drawer
      #matDrawer
      *ngIf="!hideLeftDrawer"
      [disableClose]="disableLeftSidebarClose"
      [mode]="leftDrawerMode"
      [opened]="leftDrawerOpened"
      (backdropClick)="setLeftDrawer(false)"
      class="w-full dark:bg-gray-900"
      [ngClass]="
        customLeftDrawerWidth === '' ? 'md:w-120' : customLeftDrawerWidth
      "
    >
      <ng-content select="[slot=leftdrawer]"> </ng-content>
    </mat-drawer>

    <mat-drawer-content class="flex flex-col">
      <mat-drawer-container
        class="absolute inset-0 flex flex-col bg-default"
        [hasBackdrop]="true"
        [class.noBackdropColor]="rightDrawerBackdrop"
        (backdropClick)="setRightDrawer(false)"
      >
        <mat-drawer
          class="w-full"
          [ngClass]="
            customRightDrawerWidth === '' ? 'md:w-120' : customRightDrawerWidth
          "
          #matSidebarDrawer
          [mode]="'over'"
          [position]="'end'"
          [opened]="rightDrawerOpened"
        >
          <ng-content select="[slot=rightdrawer]"> </ng-content>
        </mat-drawer>

        <mat-drawer-content
          #mainContent
          class="flex flex-col bg-default"
          id="previewContainer"
        >
          <ng-content select="[slot=maincontent]"></ng-content>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
