import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';

import { OnscreenNotificationModule } from '@fleet/ui';
import { EmptyLayoutModule } from './layouts/empty/empty.module';
import { FleetFlexLayoutModule } from './layouts/fleet-flex-layout/fleet-flex-layout.module';
import { FleetLayoutModule } from './layouts/fleet-layout/fleet-layout.module';
import { FleetTravellerLayoutModule } from './layouts/fleet-traveller-layout/fleet-traveller-layout.module';
import { KioskLayoutModule } from './layouts/kiosk-layout/kiosk-layout.module';
import { NativeAppWebviewLayoutModule } from './layouts/native-app-webview-layout/native-app-webview-layout.module';
@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    FleetLayoutModule,
    OnscreenNotificationModule,
    EmptyLayoutModule,
    NativeAppWebviewLayoutModule,
    FleetTravellerLayoutModule,
    KioskLayoutModule,
    FleetFlexLayoutModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
