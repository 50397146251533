import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { sentryInitialise } from '@fleet/error';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      if (
        registration.active &&
        registration.active.scriptURL.includes('ngsw-worker.js')
      ) {
        registration.unregister();
      }
    }
  });
}

if (environment.production) {
  enableProdMode();
  sentryInitialise(
    'https://b46959ce13ac48f09f5a2dc2dde536c8@o189246.ingest.sentry.io/6510433',
    null,
    environment.envName
  );
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
