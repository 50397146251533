<div
  class="bg-card rounded-md shadow-sm border border-gray-300 payment-styles"
  [ngClass]="{
    'border-gray-500': hostFocused && !showRequired && paymentControl.enabled,
    'border-red-600': paymentControl.errors && paymentControl.touched,
    'opacity-60': paymentControl.disabled
  }"
>
  <div class="flex flex-col w-full">
    <div
      class="w-full flex flex-row justify-between items-center py-3 px-4 w-full"
    >
      <div
        class="flex shrink flex-row"
        [ngClass]="{
          'text-red-600': paymentControl.errors && paymentControl.touched
        }"
      >
        <mat-icon class="mr-3 icon-size-5 text-hint">credit_card</mat-icon>

        <div>
          {{
            paymentControl.value
              ? showList
                ? 'Select ' + title
                : title
              : 'Select ' + title
          }}
        </div>
      </div>
      <mat-icon
        *ngIf="
          !loading &&
          paymentControl.enabled &&
          payments.length > 1 &&
          !forceSelection
        "
        [svgIcon]="showList ? 'expand_less' : 'expand_more'"
        class="icon-size-5"
        [ngClass]="{ 'cursor-pointer': paymentControl.enabled }"
        (click)="paymentControl?.enabled ? toggleList() : null"
      >
      </mat-icon>
      <mat-progress-spinner
        *ngIf="loading"
        diameter="18"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>

    <fuse-alert
      *ngFor="let alert of issues | alertsFromIssues"
      class="m-1"
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>

    <ng-container *ngIf="showList; else showSelected">
      <ng-container *ngTemplateOutlet="list"></ng-container>
    </ng-container>
    <ng-template #showSelected>
      <div
        class="px-4"
        *ngIf="paymentControl.value"
        [ngClass]="{ 'cursor-pointer': paymentControl.enabled }"
      >
        <div (click)="paymentControl?.enabled ? toggleList() : null">
          <ng-container
            *ngTemplateOutlet="
              paymentMethodTemplate;
              context: {
                $implicit: {
                  payment:
                    paymentControl.value | jobPaymentOptionDisplay : payments,
                  isLast: true
                }
              }
            "
          ></ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<mat-error
  class="text-sm font-medium"
  *ngIf="paymentControl.getError('organisationGroupPayment') as orgGroupError"
  >{{ orgGroupError }}</mat-error
>
<mat-error
  class="text-sm font-medium"
  *ngIf="
    paymentControl.getError(
      'groupRequiredOnPayDriver'
    ) as groupRequiredOnPayDriverError
  "
  >{{ groupRequiredOnPayDriverError }}</mat-error
>

<ng-template #list>
  <mat-selection-list
    #paymentList
    cdkFocusInitial
    class="w-full selection-hide-checkboxes"
    *ngIf="!paymentControl.disabled"
  >
    <mat-list-option
      class="h-auto"
      (click)="payment.disabledMessage ? null : paymentOptionSelected(payment)"
      (keydown.enter)="
        payment.disabledMessage ? null : paymentOptionSelected(payment)
      "
      *ngFor="let payment of payments; let last = last"
      [value]="payment"
      [disabled]="payment.disabledMessage"
    >
      <ng-container
        *ngTemplateOutlet="
          paymentMethodTemplate;
          context: { $implicit: { payment: payment, last: false } }
        "
      ></ng-container>
    </mat-list-option>
    <mat-list-option
      class="h-auto"
      *ngIf="!payInVehicleWithGroupOption; else showPayInVehicleWithGroup"
      (click)="
        payInVehicleOption.disabledMessage
          ? null
          : paymentOptionSelected(payInVehicleOption)
      "
      (keydown.enter)="
        payInVehicleOption.disabledMessage
          ? null
          : paymentOptionSelected(payInVehicleOption)
      "
      [value]="payInVehicleOption"
      [disabled]="payInVehicleOption.disabledMessage"
    >
      <ng-container
        *ngTemplateOutlet="
          paymentMethodTemplate;
          context: { $implicit: { payment: payInVehicleOption, last: true } }
        "
      ></ng-container>
    </mat-list-option>
    <ng-template #showPayInVehicleWithGroup
      ><mat-list-option
        class="h-auto"
        (click)="
          payInVehicleWithGroupOption.disabledMessage
            ? null
            : paymentOptionSelected(payInVehicleWithGroupOption)
        "
        (keydown.enter)="
          payInVehicleOption.disabledMessage
            ? null
            : paymentOptionSelected(payInVehicleWithGroupOption)
        "
        [value]="payInVehicleWithGroupOption"
        [disabled]="payInVehicleWithGroupOption.disabledMessage"
      >
        <ng-container
          *ngTemplateOutlet="
            paymentMethodTemplate;
            context: {
              $implicit: { payment: payInVehicleWithGroupOption, last: true }
            }
          "
        ></ng-container>
      </mat-list-option>
    </ng-template>
  </mat-selection-list>
</ng-template>

<ng-template #paymentMethodTemplate let-paymentMethod>
  <div
    class="flex flex-col w-full py-3"
    [class.border-b]="
      showList && !paymentControl.disabled && !paymentMethod.last
    "
  >
    <div class="flex flex-row w-full items-start">
      <mat-icon
        [svgIcon]="paymentMethod.payment?.icon"
        class="mr-3 icon-size-5"
      ></mat-icon>

      <div class="flex flex-col">
        <div class="text-md">{{ paymentMethod?.payment?.displayName }}</div>

        <div
          class="min-w-0 flex-1 text-sm text-ellipsis"
          *ngIf="paymentMethod.payment?.description"
        >
          {{ paymentMethod.payment.description }}
        </div>
        <div
          class="flex flex-row gap-2 items-center"
          *ngIf="
            paymentMethod.payment?.defaultPaymentMethodOnOrganisationProfile
          "
        >
          <ng-container
            *ngTemplateOutlet="
              travellerPaymentDisplay;
              context: {
                $implicit:
                  paymentMethod.payment
                    .defaultPaymentMethodOnOrganisationProfile
              }
            "
          ></ng-container>

          <mat-menu #paymentMenu="matMenu">
            <ng-container *ngFor="let travellerPayment of travellerPayments">
              <button
                mat-menu-item
                (click)="
                  setTravellerPaymentOnGroup(
                    travellerPayment,
                    paymentMethod.payment
                  )
                "
              >
                <ng-container
                  *ngTemplateOutlet="
                    travellerPaymentDisplay;
                    context: { $implicit: travellerPayment }
                  "
                ></ng-container>
              </button>
              <button
                mat-menu-item
                (click)="setPayDriverOnGroup(paymentMethod.payment)"
              >
                <div class="text-sm">Pay Driver</div>
              </button>
            </ng-container>
          </mat-menu>
          <span
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="paymentMenu"
            class="cursor-pointer hover:underline text-xs text-primary"
            *ngIf="travellerPayments.length > 0"
            >(change)</span
          >
        </div>

        <div
          class="flex flex-col items-center"
          *ngIf="
            paymentMethod.payment?.type === 'OTHER' &&
            currentProfile?.groupPaymentOptions?.length > 0
          "
        >
          <div
            class="cursor-pointer hover:underline text-xs text-primary"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="paymentMenu"
          >
            {{
              payInVehicleWithGroupOption?.organisationProfileGroupWhenPayingDriver
                ? 'Booked under ' +
                  payInVehicleWithGroupOption
                    .organisationProfileGroupWhenPayingDriver?.displayName
                : 'Please select a group'
            }}
          </div>
          <mat-menu #paymentMenu="matMenu">
            <ng-container
              *ngFor="let group of currentProfile?.groupPaymentOptions"
            >
              <button mat-menu-item (click)="setPayDriverOnGroup(group)">
                {{ group?.displayName }}
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </div>
    </div>
    <div *ngIf="paymentMethod.payment?.disabledMessage" class="text-xs">
      {{ '*' + paymentMethod.payment.disabledMessage }}
    </div>
  </div>
</ng-template>

<ng-template #travellerPaymentDisplay let-travellerPayment>
  <div class="flex flex-row gap-3 w-full items-center">
    <mat-icon
      *ngIf="travellerPayment.cardType"
      [svgIcon]="travellerPayment.cardType | creditCard : 'svg'"
      class="icon-size-5"
    ></mat-icon>
    <mat-icon
      *ngIf="travellerPayment.card"
      [svgIcon]="travellerPayment.card?.cardType | creditCard : 'svg'"
      class="icon-size-5"
    ></mat-icon>
    <div class="text-sm">
      {{ travellerPayment?.description }}
    </div>
  </div>
</ng-template>
