import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import { ActionButtonsModule } from '@fleet/ui';
import { SidebarFormLayoutModule } from '@fleet/layout';
import { ProgressButtonModule } from '@fleet/shared';
import { PlacesAutocompleteModule } from '../places-autocomplete';
import { PlacesTestUiComponent } from './places-test-ui.component';

@NgModule({
  declarations: [PlacesTestUiComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProgressButtonModule,
    PlacesAutocompleteModule,
    ActionButtonsModule,
    SidebarFormLayoutModule,
  ],
  exports: [PlacesTestUiComponent],
})
export class PlacesTestUiModule {}
