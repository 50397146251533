import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateWithTimeControlComponent } from './date-with-time-control.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [DateWithTimeControlComponent],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  exports: [DateWithTimeControlComponent],
})
export class DateWithTimeControlModule {}
