import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { ApiResponse, NoteModel } from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoteApiService {
  host;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.note;
  }

  addNote(payload: NoteModel): Observable<any> {
    return this.http
      .post(`${this.host}`, payload)
      .pipe(catchError(handleApiError));
  }

  updateNote(noteId: string, payload: NoteModel): Observable<any> {
    return this.http
      .put(`${this.host}/${noteId}`, payload)
      .pipe(catchError(handleApiError));
  }

  searchNotes(params: any): Observable<any> {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  deleteNote(noteId: string): Observable<any> {
    return this.http
      .delete(`${this.host}/${noteId}`)
      .pipe(catchError(handleApiError));
  }
}
