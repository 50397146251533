<button
  [ngClass]="buttonClass ? buttonClass : null"
  mat-flat-button
  [type]="type"
  [color]="color"
  class="bg-primary-600 text-white disabled:bg-gray-200 hover:bg-primary-500"
  [disabled]="state.loading || disabled"
  (click)="onClick.emit()"
  [matTooltip]="state.matTooltip"
>
  <span *ngIf="!state.loading; else loading"> {{ state.buttonLabel }} </span>
  <ng-template #loading>
    <mat-progress-spinner
      [diameter]="24"
      [mode]="'indeterminate'"
    ></mat-progress-spinner>
  </ng-template>
</button>
