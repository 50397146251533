import { Injectable } from '@angular/core';
import { JobApiService } from '@fleet/api';
import {
  ApiResponse,
  IssueModel,
  JobLifecycleListenerModel,
  JobModel,
  JobProgressModel,
  JobStatus,
  PositionModel,
} from '@fleet/model';
import { OnscreenNotificationService } from '@fleet/ui';
import { failureNotification } from '@fleet/utilities';
import { BehaviorSubject, Subject } from 'rxjs';

import { JobListenerService } from '../services/job-listener.service';
import { MOCK_FINISHED_JOB_RESPONSE } from '../mocks/mock-job';

export const listeningJobStatus = [
  JobStatus.ASSIGNED,
  JobStatus.STARTED,
  JobStatus.IN_PROGRESS,
  JobStatus.ABANDONED,
  JobStatus.DISPATCHED,
];
@Injectable({ providedIn: 'root' })
export class JobDetailService {
  job: BehaviorSubject<JobModel> = new BehaviorSubject(null);

  issues: Subject<IssueModel[]> = new Subject();
  shouldListen: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(
    private jobApiService: JobApiService,
    private jobListener: JobListenerService,
    private onScreenNotificationService: OnscreenNotificationService
  ) {}

  setJob(job: JobModel) {
    // if (job) {
    //   job = MOCK_FINISHED_JOB_RESPONSE.data as any;
    // }
    this.job.next(job);
    if (job) {
      if (listeningJobStatus.includes(job.jobStatus as JobStatus)) {
        this.shouldListen.next(true);
        if (!this.jobListener.listener) {
          //hookup the listener
          this.jobListener.client = 'Job Detail Service';
          this.jobListener.registerListener({
            jobId: job.jobId,
          } as JobLifecycleListenerModel);

          this.jobListener.currentJobProgressUpdate$.subscribe(
            (jobProgress: JobProgressModel) => {
              if (
                this.job.value &&
                jobProgress.jobId === this.job.value.jobId
              ) {
                this.job.next({ ...this.job.value, jobProgress: jobProgress });
              }
            }
          );
          this.jobListener.currentJob$.subscribe({
            next: (job: JobModel) => {
              if (job && this.job.value && job.jobId === this.job.value.jobId) {
                //updated current job
                if (
                  job.jobStatus != this.job.value.jobStatus ||
                  job.paymentStatus !== this.job.value.paymentStatus
                ) {
                  //refresh Job
                  this.job.next({
                    ...this.job.value,
                    jobStatus: job.jobStatus,
                  });
                  console.log('refreshing job from listenering status cahnge');
                  this.getJob(job.jobId);
                } else {
                  this.job.next(Object.assign({}, this.job.value, job));
                }
              }
            },
          });
        } else {
          //already have listener
        }
      } else {
        this.shouldListen.next(false);
        // deregister listener, it might not exist
        this.jobListener.deregisterListener();
      }
    } else {
      // deregister listener, it might not exist
      this.jobListener.deregisterListener();
    }
  }

  updateJobProgressWithPositionModel(position: PositionModel) {
    this.job.next({
      ...this.job.value,
      jobProgress: { ...this.job.value.jobProgress, position: position },
    });
  }

  get job$() {
    return this.job.asObservable();
  }

  getJob(jobId: string) {
    this.jobApiService.getJob(jobId).subscribe({
      next: (resp: ApiResponse<JobModel> | any) => {
        this.setJob(resp.data);
      },
      error: (error) => this.issues.next(error),
    });
  }

  extendExpiry() {
    this.jobApiService.extendExpiry(this.job.value.jobId).subscribe({
      next: (resp: ApiResponse<JobModel>) => {
        this.setJob(resp.data);
      },
      error: (issues: any) => {
        this.onScreenNotificationService.setNotification({
          ...failureNotification,
          title: 'Something went wrong',
          subTitle: 'Please try again',
        });
      },
    });
  }

  get listening$() {
    return this.jobListener.isListening$;
  }

  get shouldListen$() {
    return this.shouldListen.asObservable();
  }

  // get jobSecondsTillExpiry$(): Observable<number> {
  //   return this.job$.pipe(
  //     map((job: JobModel) => {
  //       if (job && job.jobDetail.startTime) {
  //         const seconds = DateTime.now().diff(
  //           DateTime.fromISO(job.jobDetail.startTime.toString()).plus(30000000)
  //         ).seconds;
  //         if (seconds > 0) {
  //           return seconds;
  //         }
  //         return 0;
  //       }
  //       return 0;
  //     })
  //   );
  // }
}
