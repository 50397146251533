import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatchExportItemComponent } from './batch-export-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [BatchExportItemComponent],
  imports: [CommonModule, MatIconModule, MatProgressBarModule],
  exports: [BatchExportItemComponent],
})
export class BatchExportItemModule {}
