import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityHeaderWithDataItemsComponent } from './entity-header-with-data-items.component';
import { MatIconModule } from '@angular/material/icon';
import { EntityHeaderDataItemModule } from '../entity-header-data-item';

@NgModule({
  declarations: [EntityHeaderWithDataItemsComponent],
  imports: [CommonModule, MatIconModule, EntityHeaderDataItemModule],
  exports: [EntityHeaderWithDataItemsComponent],
})
export class EntityHeaderWithDataItemsModule {}
