import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProgressButtonModule } from '@fleet/shared';
import { JobIdSearchControlComponent } from './job-id-search-control.component';

@NgModule({
  declarations: [JobIdSearchControlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ProgressButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
  ],
  exports: [JobIdSearchControlComponent],
})
export class JobIdSearchControlModule {}
