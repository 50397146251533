<div
  class="flex flex-row items-center"
  (click)="click()"
  [matTooltip]="tooltip"
>
  <mat-icon
    *ngIf="labelIcon"
    class="flex-shrink-0 mr-1.5 icon-size-5"
    [ngClass]="iconColor ? iconColor : 'text-gray-400'"
    >phone</mat-icon
  >
  <span [ngClass]="phoneNumberClasses" class="underline cursor-pointer">{{
    phoneNumber
  }}</span>
</div>
