import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { StatusChipModule } from '@fleet/ui';
import { VehicleCardComponent } from './vehicle-card.component';

@NgModule({
  declarations: [VehicleCardComponent],
  imports: [CommonModule, MatIconModule, StatusChipModule],
  exports: [VehicleCardComponent],
})
export class VehicleCardModule {}
