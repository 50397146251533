import { Pipe, PipeTransform } from '@angular/core';
import {
  ServiceClassModel,
  ServiceClassWithLines,
  ServiceLineModel,
} from '@fleet/model';

@Pipe({
  name: 'serviceLinesFromServiceClassAndLines',
})
export class ServiceLinesFromServiceClassAndLinesPipe implements PipeTransform {
  transform(
    classAndLines: ServiceClassWithLines[],
    serviceClass: ServiceClassModel
  ): ServiceLineModel[] {
    if (!classAndLines || !serviceClass) {
      return [];
    }
    const selectedClass = classAndLines.find(
      (c) => c.class.serviceClassId === serviceClass.serviceClassId
    );
    return selectedClass ? selectedClass.lines : [];
  }
}
