import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { PlaceApiService } from '@fleet/api';
import { ApiResponse, RegionModel } from '@fleet/model';
import { values } from 'lodash-es';

@Component({
  selector: 'fleet-region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegionSelectorComponent),
      multi: true,
    },
  ],
})
export class RegionSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() matLabelClasses = 'text-sm font-light';
  @Input() label: string;
  @Input() classes: string;
  _countryCode: string;
  regions: RegionModel[];
  @Input() set countryCode(value: string) {
    this._countryCode = value;
    if (value) {
      this.placesApiService
        .searchRegions({
          limit: 100,
          offset: 0,
          countryCode: value,
        })
        .subscribe({
          next: (resp: ApiResponse<RegionModel[]>) => {
            this.regions = resp.data;
          },
        });
    }
  }
  @Input() mode = 'id';

  control = new UntypedFormControl();

  constructor(private placesApiService: PlaceApiService) {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe((value: RegionModel) => {
      if (value) {
        this.onChange(this.mode === 'id' ? value.regionCode : value);
      } else {
        this.onChange(null);
      }
      this.onTouched();
    });
  }

  onChange: any = (group: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(region: RegionModel) {
    this.control.setValue(region, { emitEvent: false });
  }
}
