import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravellerStatusChangeComponent } from './traveller-status-change.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ProgressButtonModule } from '@fleet/shared';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { DialogLayoutModule } from '@fleet/ui';
import { EntityBlockAttributeListModule } from '@fleet/blocklist';

@NgModule({
  declarations: [TravellerStatusChangeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    ProgressButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    TitleCaseAndCleanPipeModule,
    MatButtonModule,
    MatDialogModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    DialogLayoutModule,
    EntityBlockAttributeListModule,
  ],
  exports: [TravellerStatusChangeComponent],
})
export class TravellerStatusChangeModule {}
