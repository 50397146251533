import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fleet-dialable-phone-number',
  templateUrl: './dialable-phone-number.component.html',
  styleUrls: ['./dialable-phone-number.component.scss'],
})
export class DialablePhoneNumberComponent implements OnInit {
  fleetProduct: string;
  @Input() phoneNumber: string;
  @Input() tooltip: string;
  @Input() labelIcon: string;
  @Input() buttonIcon: string;
  @Input() iconColor: string;
  @Input() phoneNumberClasses: string;

  constructor(
    @Inject('env') env: any
    // private productConfigurationService: ProductConfigurationService
  ) {
    this.fleetProduct = env;
  }

  ngOnInit(): void {}

  click() {
    // if (this.productConfigurationService.getLayout() === 'flex') {
    //   //post message to flex
    //   window.parent.postMessage(
    //     {
    //       action: 'outbound-call',
    //       phoneNumber: this.phoneNumber,
    //     },
    //     '*'
    //   );
    // } else {
    // window.open('tel:', '_self');
    document.location.href = 'tel:' + this.phoneNumber;
    // }
  }
}
