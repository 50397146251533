<fleet-sidebar-form-layout
  class="flex-auto"
  *ngIf="getState() | async as state"
  [title]="title"
  (closeSidebar)="cancelled.emit()"
>
  <form
    *ngIf="form"
    [formGroup]="form"
    form="ngForm"
    class="flex flex-col flex-auto"
    (ngSubmit)="refundPTD()"
  >
    <div class="mt-8 px-6 flex flex-col gap-3">
      <mat-form-field>
        <mat-label> {{ label | titleCaseAndClean }} amount </mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          currencyMask
          [options]="{ align: 'left', allowNegative: false }"
          matInput
          formControlName="refundAmount"
          [placeholder]="
            'Amount to be ' + (label | titleCaseAndClean) + 'ed...'
          "
        />
        <mat-hint *ngIf="paymentTransactionGroup?.totalRefundableAmount">
          Maximum refundable amount is
          {{ paymentTransactionGroup?.totalRefundableAmount | currency }}
        </mat-hint>
        <mat-error *ngIf="form.get('refundAmount')?.errors?.required">
          Refundable amount is required
        </mat-error>
        <mat-error *ngIf="form.get('refundAmount').errors?.max">
          {{
            paymentTransactionGroup?.totalRefundableAmount
              ? 'You cannot refund more than the total refundable amount of ' +
                (paymentTransactionGroup?.totalRefundableAmount | currency)
              : 'You cannot refund more than the total refundable amount'
          }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> Reason </mat-label>

        <textarea
          matInput
          formControlName="reason"
          placeholder="Enter a reason..."
          maxlength="255"
          matInput
        ></textarea>
        <mat-error> Reason is required</mat-error>
      </mat-form-field>
    </div>
    <div class="flex-grow"></div>

    <fuse-alert
      *ngFor="let alert of state.issues | alertsFromIssues"
      class="pb-3 mx-6"
      [appearance]="'outline'"
      [showIcon]="false"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>

    <fleet-action-buttons>
      <button mat-button type="button" (click)="cancelled.emit()">
        Cancel
      </button>
      <fleet-progress-button
        class="pl-3"
        [disabled]="!form.valid"
        (onClick)="refundPTD()"
        [state]="state"
      >
      </fleet-progress-button>
    </fleet-action-buttons>
  </form>
</fleet-sidebar-form-layout>
