/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fleet/fuse';
import { JobModel, JobSearchResultModel, JobState } from '@fleet/model';

import { ConfirmationDialogComponent } from '@fleet/shared';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { deleteNotification } from '@fleet/utilities';
import { JobCancelDialogComponent } from '../job-cancel-dialog/job-cancel-dialog.component';

import { JobAction } from './job-action.model';
import { JobActionService } from './job-action.service';

@Component({
  selector: 'fleet-job-action',
  templateUrl: './job-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class JobActionComponent implements OnInit {
  @Input() extraActions: any[] = [];
  @Output() customAction = new EventEmitter();
  @Input() job: JobModel | JobSearchResultModel;
  @Input() hideActions: string[];
  @Input() viewMode: 'ICON' | 'BUTTON' = 'ICON';
  @Output() executeAction = new EventEmitter();
  label: string;
  constructor(
    private jobActionService: JobActionService,
    private matDialog: MatDialog,
    private confirmationService: FuseConfirmationService,
    private onScreenNotificationService: OnscreenNotificationService,
    private fuseConfirmationService: FuseConfirmationService
  ) {}

  ngOnInit(): void {}

  click(jobAction: JobAction) {
    if (jobAction.needsConfirmation) {
      //cancel dialog needs its own - uses ref data for cancel reasons
      if (jobAction.type === 'CANCEL') {
        const dialog = this.matDialog.open(JobCancelDialogComponent, {
          height: 'full',
        });
        dialog.componentInstance.job = this.job;
        dialog.componentInstance.successful.subscribe((job: JobModel) => {
          dialog.close();
          this.onScreenNotificationService.setNotification({
            ...deleteNotification,
            title: 'Job Cancelled!',
          } as OnScreenNotification);
        });
      } else {
        //launch dialog

        const dialogRef = this.fuseConfirmationService.open({
          title: jobAction.confirmationTitle,
          message: jobAction.confirmationDescription,
          actions: {
            confirm: { color: 'primary', label: jobAction.confirmButtonLabel },
            cancel: { label: jobAction.cancelButtonLabel },
          },
        });

        // Subscribe to afterClosed from the dialog reference
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result === 'confirmed') {
            this.jobActionService.executeAction(jobAction, this.job);
          }
        });
      }
    } else {
      this.jobActionService.executeAction(jobAction, this.job);
    }
  }
}
