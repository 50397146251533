import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

export const phoneNumberValidator = (excludeTypes: string[] = []) => {
  return (control: any) => {
    const error = { validatePhoneNumber: true };
    let numberInstance: PhoneNumber;
    if (control.value) {
      try {
        numberInstance = parsePhoneNumber(control.value);
      } catch (e) {
        return error;
      }

      if (numberInstance) {
        const type = numberInstance.getType();
        if (!numberInstance.isValid()) {
          if (!control.touched) {
            control.markAsTouched();
          }
          return error;
        } else if (excludeTypes.includes(type)) {
          console.log(numberInstance.getType());
          if (!control.touched) {
            control.markAsTouched();
          }
          return error;
        }
      }
    }
    return null;
  };
};
