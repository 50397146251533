<mat-sidenav-container class="h-full w-full">
  <mat-sidenav mode="side" opened class="w-80">
    <fleet-reverse-geo-location
      (cancel)="cancel.emit()"
      (reverseGeocodeConfirmed)="confirmLocation($event)"
    ></fleet-reverse-geo-location
  ></mat-sidenav>

  <mat-sidenav-content>
    <fleet-map height="100%"></fleet-map>
  </mat-sidenav-content>
</mat-sidenav-container>
