import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlacesAutocompleteComponent } from './places-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LocationListItemModule } from '../location-list-item/location-list-item.module';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { LocationFormModule } from './../location-form/location-form.module';
import { MatIconModule } from '@angular/material/icon';
import { LetterIconModule } from '@fleet/ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LocationFromMapModule } from '../location-from-map/location-from-map.module';

@NgModule({
  declarations: [PlacesAutocompleteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    LocationListItemModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    LocationFormModule,
    LetterIconModule,
    DragDropModule,
    MatTooltipModule,
    MatProgressSpinnerModule,

    LocationFromMapModule,
  ],
  exports: [PlacesAutocompleteComponent],
})
export class PlacesAutocompleteModule {}
