import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityLayoutWithRightDrawerComponent } from './entity-layout-with-right-drawer.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { EntityHeaderWithBreadcrumbsModule } from './../entity-header-with-breadcrumbs/entity-header-with-breadcrumbs.module';

@NgModule({
  declarations: [EntityLayoutWithRightDrawerComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    EntityHeaderWithBreadcrumbsModule,
  ],
  exports: [EntityLayoutWithRightDrawerComponent],
})
export class EntityLayoutWithRightDrawerModule {}
