import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  Validator,
  NgControl,
  FormGroup,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { hasRequiredValidator } from '@fleet/utilities';

import { toNumber } from '@ngneat/transloco';
import { DateTime } from 'luxon';

@Component({
  selector: 'fleet-date-with-time-control',
  templateUrl: './date-with-time-control.component.html',
  styleUrls: ['./date-with-time-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateWithTimeControlComponent
  implements OnInit, ControlValueAccessor
{
  //NOTE FOR LATER - PARENT CAN WRITE VALUE DOWN INTO ERROR THAT A THING IS INCORRECT?

  @Input() includeTime = true;
  @Input() timeRequired = true;
  @ViewChild('datePicker') datePicker: ElementRef;
  @ViewChild('timePicker') timePicker: ElementRef<HTMLInputElement>;

  dateTimeForm: FormGroup;

  @Input() controlCustomClass: string;

  @Input() wrap = false;

  @Input() minDate: DateTime = null;
  @Input() maxDate: DateTime = null;

  _showNowControl: boolean;
  @Input() set showNowControl(value: boolean) {
    this._showNowControl = value;
    if (value) {
      setTimeout(() => {
        const now = DateTime.now();
        this.dateTimeForm.setValue({
          date: now.toISO(),
          time: now.toFormat('HH:mm'),
        });
      }, 1000);
    }
  }
  get showNowControl() {
    return this._showNowControl;
  }
  nowControl = new UntypedFormControl('NOW');

  _defaultTime: DateTime;
  @Input() set defaultTime(value: DateTime) {
    this._defaultTime = value;
    if (!this.dateTimeForm) {
      this.buildForm();
    }
    if (value && value !== undefined) {
      if (this.defaultTime && this.dateTimeForm.get('time').value) {
        if (!isNaN(this.defaultTime.hour) || !isNaN(this.defaultTime.minute)) {
          this.dateTimeForm
            .get('time')
            .patchValue(this.defaultTime.hour + ':' + this.defaultTime.minute);
        }

        this.changeDetectorRef.markForCheck();
      }
    }
  }

  get defaultTime() {
    return this._defaultTime;
  }

  @Input() timeLabel: string;
  @Input() dateLabel: string;
  @Input() timePlaceholder = 'HH MM';
  @Input() datePlaceholder = 'Choose Date';

  @Input() timePrefix = 'schedule';
  @Input() datePrefix = 'event';

  @Input() labelClass = '';

  _futureDateOnly: boolean;
  @Input() set futureDateOnly(value: boolean) {
    this._futureDateOnly = value;
    if (!this.dateTimeForm) {
      this.buildForm();
    }
    if (value) {
      this.minDate = DateTime.now();
    } else {
      this.minDate = null;
    }
    this.dateTimeForm.updateValueAndValidity();
    this.changeDetectorRef.markForCheck();
  }

  get futureDateOnly() {
    return this._futureDateOnly;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    @Self() public ngControl: NgControl
  ) {
    ngControl.valueAccessor = this;

    if (!this.dateTimeForm) {
      this.buildForm();
    }
  }

  buildForm() {
    this.dateTimeForm = this.fb.group({
      date: [null],
      time: [null, [this.timeFormatValidator()]],
    });
  }

  ngOnInit(): void {
    if (!this.dateTimeForm) {
      this.buildForm();
    }

    // this.ngControl.control.setValidators(this.validate.bind(this));

    const updateValidators = () => {
      if (hasRequiredValidator(this.ngControl.control)) {
        const currentValidators = this.ngControl.control.validator;
        const newValidators =
          Array.isArray(currentValidators) && currentValidators !== null
            ? [
                ...currentValidators.filter((validator) => validator !== null),
                Validators.required,
              ]
            : currentValidators !== null
            ? [currentValidators, Validators.required]
            : [Validators.required];
        this.dateTimeForm.setValidators(newValidators);

        this.dateTimeForm.get('date').setValidators(Validators.required);

        if (this.includeTime && this.timeRequired) {
          this.dateTimeForm
            .get('time')
            .setValidators([Validators.required, this.timeFormatValidator()]);
        }

        this.dateTimeForm
          .get('time')
          .updateValueAndValidity({ emitEvent: false });

        this.dateTimeForm
          .get('date')
          .updateValueAndValidity({ emitEvent: false });
      }

      const controlErrors = this.ngControl.control.errors;
      if (controlErrors) {
        this.dateTimeForm.setErrors(controlErrors);
      } else {
        this.dateTimeForm.setErrors(null);
      }

      if (this.dateTimeForm.errors && this.dateTimeForm.errors.dateNotFuture) {
        //errors is on the form, set the invidiual control depending on
        const currentErrors = this.dateTimeForm.get('date').errors || {};
        this.dateTimeForm.get('date').setErrors({
          ...currentErrors,
          dateNotFuture: this.dateTimeForm.errors.dateNotFuture,
        });
      } else {
        const currentErrors = this.dateTimeForm.get('date').errors || {};
        if (currentErrors.dateNotFuture) {
          delete currentErrors.dateNotFuture;
        }

        this.dateTimeForm
          .get('date')
          .setErrors(Object.keys(currentErrors).length ? currentErrors : null);
      }

      if (this.includeTime && this.timeRequired) {
        if (
          this.dateTimeForm.errors &&
          this.dateTimeForm.errors.timeNotFuture
        ) {
          const currentErrors = this.dateTimeForm.get('time').errors || {};
          this.dateTimeForm.get('time').setErrors({
            ...currentErrors,
            timeNotFuture: this.dateTimeForm.errors.timeNotFuture,
          });
        } else {
          const currentErrors = this.dateTimeForm.get('time').errors || {};
          if (currentErrors.timeNotFuture) {
            delete currentErrors.timeNotFuture;
          }
          this.dateTimeForm
            .get('time')
            .setErrors(
              Object.keys(currentErrors).length ? currentErrors : null
            );
        }
      }

      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    };

    // Run once on load
    updateValidators();

    // Subscribe to status changes
    this.ngControl.control.statusChanges.subscribe((status: string) => {
      updateValidators();
    });

    this.dateTimeForm.valueChanges.subscribe((value: any) => {
      if (
        value &&
        value.date &&
        (value.time ||
          (!value.time && (!this.includeTime || !this.timeRequired)))
      ) {
        //convert it

        let date = value.date
          ? DateTime.fromISO(value.date)
          : this.datePicker &&
            this.datePicker.nativeElement.value &&
            this.datePicker.nativeElement.value !== ''
          ? DateTime.invalid('Date invalid')
          : (this.includeTime &&
              this.datePicker.nativeElement.value &&
              this.datePicker.nativeElement.value !== '') ||
            (this.includeTime &&
              !this.datePicker.nativeElement.value &&
              value.time)
          ? DateTime.invalid('Time without date')
          : null;
        const time = value.time ? value.time : null;

        if (time) {
          const [hours, minutes] = time
            .split(':')
            .map((t: string) => parseInt(t, 10));
          date = date.set({ hour: hours, minute: minutes });
        } else if (!time && this.includeTime && this.timeRequired) {
          date = DateTime.invalid('Empty time not allowed');
        } else if (!time) {
          date = date.set({ hour: 0, minute: 0 });
        }
        //Only emit good dates.

        this.onChange(date.isValid ? date : null);
      } else {
        //no VALID date in form, could be not required,
        //but if we have garbage in the input lets get them to clear it so they arent confused
        if (this.datePicker.nativeElement.value !== '') {
          this.onChange(DateTime.invalid('Input contains bad characters'));
        } else {
          this.onChange(null);
        }
      }
    });

    this.nowControl.valueChanges.subscribe((value: any) => {
      if (value == 'NOW') {
        const now = DateTime.now();
        this.dateTimeForm.setValue({
          date: now.toISO(),
          time: now.toFormat('HH:mm'),
        });
      }
    });
  }
  onTimeInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.value) {
      // The time input has been cleared
      console.log('Time input cleared');
    } else {
      // The time input has some value
      console.log('Time input value:', inputElement.value);
    }
  }
  returnTimeWithHoursAndMinutes(
    dateTime: DateTime,
    hours: number,
    mins: number
  ) {
    if (!isNaN(hours)) {
      dateTime = dateTime.set({ hour: hours });
    }
    if (!isNaN(mins)) {
      dateTime = dateTime.set({ minute: mins });
    }

    return dateTime;
  }

  onChange: any = (locality: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      let dateTime: DateTime;
      if (typeof value === 'string') {
        dateTime = DateTime.fromISO(value);
      } else {
        dateTime = value as DateTime;
      }
      const hour = dateTime.hour < 10 ? '0' + dateTime.hour : dateTime.hour;
      const minute =
        dateTime.minute < 10 ? '0' + dateTime.minute : dateTime.minute;
      this.dateTimeForm.setValue(
        { date: value, time: hour + ':' + minute },
        { emitEvent: false }
      );
      this.changeDetectorRef.markForCheck();
    } else {
      this.dateTimeForm.reset({}, { emitEvent: false });
    }
  }

  reset() {
    this.dateTimeForm.reset({}, { emitEvent: false });
    this.changeDetectorRef.markForCheck();
  }

  markControlAsTouched() {
    this.dateTimeForm.markAllAsTouched();
    this.changeDetectorRef.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateTimeForm.disable({ emitEvent: false });
    } else {
      this.dateTimeForm.enable({ emitEvent: false });
    }
    this.dateTimeForm.updateValueAndValidity();
  }

  timeFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value && !this.includeTime) {
        return null;
      }
      const isValid = /^([01]\d|2[0-3]):?([0-5]\d)( ?[APap][Mm])?$/.test(value);
      return isValid ||
        (this.timePicker && this.timePicker.nativeElement.validity.valid)
        ? null
        : { invalidTimeFormat: true };
    };
  }
}
