import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobCancelDialogComponent } from './job-cancel-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { ProgressButtonModule } from '@fleet/shared';
import { DialogLayoutModule } from '@fleet/ui';

@NgModule({
  declarations: [JobCancelDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ProgressButtonModule,
    DialogLayoutModule,
  ],
  exports: [JobCancelDialogComponent],
})
export class JobCancelDialogModule {}
