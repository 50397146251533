import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PlacesAutocompleteModule } from '@fleet/location';

import { DateTimeRangeControlModule } from '@fleet/shared';
import { EntityJobSearchFormComponent } from './entity-job-search-form.component';

@NgModule({
  declarations: [EntityJobSearchFormComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,

    PlacesAutocompleteModule,
    DateTimeRangeControlModule,
  ],
  exports: [EntityJobSearchFormComponent],
})
export class EntityJobSearchFormModule {}
