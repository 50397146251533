import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NetworkApiService } from '@fleet/api';
import {
  ApiResponse,
  BaseSearchState,
  ConditionConfigurationModel,
  IssueModel,
  initialBaseSearchState,
} from '@fleet/model';
import { ProductConfigurationService } from '@fleet/product-configuration';
import { handleApiError } from '@fleet/utilities';
import { BehaviorSubject } from 'rxjs';
import { catchError, distinct } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkConditionsService {
  driverConditions: BehaviorSubject<ConditionConfigurationModel[]> =
    new BehaviorSubject(null);
  vehicleConditions: BehaviorSubject<ConditionConfigurationModel[]> =
    new BehaviorSubject(null);
  travellerConditions: BehaviorSubject<ConditionConfigurationModel[]> =
    new BehaviorSubject(null);
  jobConditions: BehaviorSubject<ConditionConfigurationModel[]> =
    new BehaviorSubject(null);
  allConditions: BehaviorSubject<ConditionConfigurationModel[]> =
    new BehaviorSubject([]);
  organisationConditions: BehaviorSubject<ConditionConfigurationModel[]> =
    new BehaviorSubject(null);

  networkConditionsSearchSubject: BehaviorSubject<
    BaseSearchState<ConditionConfigurationModel>
  > = new BehaviorSubject(initialBaseSearchState);
  fleetProduct: string;

  constructor(
    private networkApiService: NetworkApiService,
    private productConfigService: ProductConfigurationService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
    this.productConfigService.networkConfig$.subscribe({
      next: (config: any) => {
        if (config?.networkId) {
          switch (this.fleetProduct) {
            case 'HUB':
              this.getAllNetworkTypes(config.networkId);
              break;
            case 'DRIVER':
              this.searchNetworkConditions('DRIVER', config.networkId);
              break;
            case 'TRAVELLER':
              this.searchNetworkConditions('BOOKING', config.networkId);
              break;
            case 'BUSINESS':
              this.searchNetworkConditions('BOOKING', config.networkId);
              this.searchNetworkConditions('ORGANISATION', config.networkId);
          }
        }
      },
    });
  }

  get conditions$() {
    return this.networkConditionsSearchSubject.asObservable();
  }

  get allConditions$() {
    return this.allConditions
      .asObservable()
      .pipe(distinct((condition: any) => condition.networkConditionId));
  }

  getAllNetworkTypes(networkId?: string) {
    this.searchNetworkConditions('DRIVER', networkId);
    this.searchNetworkConditions('TRAVELLER', networkId);
    this.searchNetworkConditions('VEHICLE', networkId);
    this.searchNetworkConditions('BOOKING', networkId);
    this.searchNetworkConditions('ORGANISATION', networkId);
  }
  searchNetworkConditions(type: string, networkId?: string) {
    this.networkConditionsSearchSubject.next({
      ...this.networkConditionsSearchSubject.value,
      searching: true,
      issues: [],
    });

    const params = {} as any;

    // switch (this.fleetProduct) {
    //   case 'TRAVELLER':
    //     params['filter'] = 'TRAVELLER';
    //     break;

    //   case 'BUSINESS':
    //     params['filter'] = 'ORGANISATION';
    //     break;

    //   case 'HUB':
    //     params['filter'] = 'NETWORK';
    //     break;
    // }

    if (type) {
      params['type'] = type;
    }

    this.networkApiService
      .searchNetworkConditionConfiguration(params, networkId)
      .pipe(catchError(handleApiError))
      .subscribe(
        (resp: HttpResponse<ApiResponse<ConditionConfigurationModel[]>>) => {
          this.networkConditionsSearchSubject.next({
            ...this.networkConditionsSearchSubject.value,
            searching: false,
          });

          if (type === 'DRIVER') {
            this.driverConditions.next(resp.body.data);
          } else if (type === 'TRAVELLER') {
            this.travellerConditions.next(resp.body.data);
          } else if (type === 'VEHICLE') {
            this.vehicleConditions.next(resp.body.data);
          } else if (type === 'BOOKING') {
            this.jobConditions.next(resp.body.data);
          } else if (type === 'ORGANISATION') {
            this.organisationConditions.next(resp.body.data);
          }
          this.allConditions.next([
            ...this.allConditions.value,
            ...resp.body.data,
          ]);
        },
        (error: IssueModel[]) => {
          //error lets next state
          this.networkConditionsSearchSubject.next({
            ...this.networkConditionsSearchSubject.value,
            searching: false,
            issues: error,
          });
        }
      );
  }
}
