import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntitySearchLayoutComponent } from './entity-search-layout.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { EntitySearchLayoutSidebarComponent } from './entity-search-layout-sidebar/entity-search-layout-sidebar.component';
import { MatButtonModule } from '@angular/material/button';
import { FuseDrawerModule } from '@fleet/fuse/lib/components/drawer/drawer.module';
import { EntitySearchLayoutActionsComponent } from './entity-search-layout-actions/entity-search-layout-actions.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseCardModule } from '@fleet/fuse';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NoPermissionLabelModule, NoResultsLabelModule } from '@fleet/shared';
import { HasFunctionPipeModule } from '@fleet/security-shared';

@NgModule({
  declarations: [
    EntitySearchLayoutComponent,
    EntitySearchLayoutSidebarComponent,
    EntitySearchLayoutActionsComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSidenavModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    FuseDrawerModule,
    MatTooltipModule,
    FuseCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NoResultsLabelModule,
    HasFunctionPipeModule,
    NoPermissionLabelModule,
  ],
  exports: [
    EntitySearchLayoutComponent,
    EntitySearchLayoutSidebarComponent,
    EntitySearchLayoutActionsComponent,
  ],
})
export class EntitySearchLayoutModule {}
