<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <mat-drawer-container class="flex flex-col flex-auto shadow bg-default">
    <!-- Drawer -->
    <mat-drawer
      class="dark:bg-gray-900 sm:w-80 w-full"
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened$ | async"
      #matDrawer
    >
      <ng-container *ngIf="drawerMode === 'over'">
        <div class="m-6 overflow-hidden">
          <ng-content select="[slot=sidebar-header]"></ng-content>
        </div>
      </ng-container>

      <fuse-vertical-navigation
        [appearance]="'default'"
        [navigation]="navigation"
        [inner]="true"
        [mode]="'side'"
        [name]="'demo-sidebar-navigation'"
        [opened]="true"
      ></fuse-vertical-navigation>
    </mat-drawer>

    <mat-drawer-content class="absolute inset-0 flex flex-col">
      <ng-content select="[slot=banner]"></ng-content>
      <ng-content select="[slot=entity-header]"></ng-content>
      <ng-content select="[slot=main]"></ng-content>
      <!-- <div class="flex flex-col flex-auto">
        <div class="flex flex-col gap- pt-9 px-6">
          <div class="flex flex-row items-center">
            <button
              [class.hidden]="matDrawer.opened"
              mat-icon-button
              (click)="matDrawer.toggle()"
            >
              <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <div
              class="w-full max-w-7xl flex flex-wrap items-center justify-between"
            >
              <ng-content select="[slot=entity-card]"></ng-content>
              <ng-content select="[slot=status]"></ng-content>
            </div>
          </div>

          <div class="text-2xl font-bold tracking-tight leading-none">
            {{ title }}
          </div>
        </div>

        <div class="mt-8 mx-0 flex flex-auto">
          <ng-content select="[slot=main]"></ng-content>
        </div>
      </div> -->
    </mat-drawer-content>
  </mat-drawer-container>
</div>
