import { PlaceModel } from '@fleet/model';

export const MOCK_LOCATION_JSON = `{
		"locationReference": "96114",
		"organisationLocationReference": "5",
		"placeName": "1149 Spence Ridge",
		"streetNumber": "1149",
		"addressLine1": "Spence Ridge",
		"displayAddress": "1149 Spence Ridge",
		"maskedDisplayAddress": "*** Spence Ridge",
		"suburb": "Riceboro",
    "state":"NSW",
		"latitude": "-33.889838",
		"longitude": "151.107147",
		"validPickupLocation": true,
		"phoneNumber": "+61491582228",
		"type": "PICKUP",
		"name": "pull Office @ 1149",
		"info": "Pickup instructions",
		"deleted": false
	}`;

export const MOCK_AIRPORT_PLACE: any = {
  id: 'f:TEFORE1BUks6VFJNLVNZRC1UMS1EUk9QT0ZG',
  displayLine1: 'Sydney International Terminal 1',
  displayLine2: 'Departure Plaza, Sydney International Airport, NSW, 2020',
  structuredAddress: {
    name: 'Sydney International Terminal 1',
    streetName: 'Departure Plaza',
    area: 'Sydney International Airport',
    state: 'NSW',
    postalCode: '2020',
  },
  latitude: '-33.936310',
  longitude: '151.165730',
  placeType: 'AIRPORT',
  placeCode: 'SYD',
  displayName: 'Sydney International Terminal 1',
  state: 'Departure Plaza, Sydney International Airport, NSW, 2020',
};
