import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkConditionsDisplayListComponent } from './network-conditions-display-list.component';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [NetworkConditionsDisplayListComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  exports: [NetworkConditionsDisplayListComponent],
})
export class NetworkConditionsDisplayListModule {}
