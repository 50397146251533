import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@fleet/auth';

@Pipe({
  name: 'hasFunction',
})
export class HasFunctionPipe implements PipeTransform {
  transform(functionTypeAndAction: string): boolean {
    const split = functionTypeAndAction.split(':');

    return this.authService.userHasFunction(split[0], split[1]);
  }

  constructor(private authService: AuthService) {}
}
