import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EntityHeaderWithBreadcrumbsComponent } from './entity-header-with-breadcrumbs.component';

import { MatIconModule } from '@angular/material/icon';

import { MatTooltipModule } from '@angular/material/tooltip';

import { BreadcrumbItemModule } from './breadcrumb-item/breadcrumb-item.module';

import {
  AvatarModule,
  DialablePhoneNumberModule,
  EntityHeaderDataItemModule,
  LetterIconModule,
  StatusChipModule,
} from '@fleet/ui';

@NgModule({
  declarations: [EntityHeaderWithBreadcrumbsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    AvatarModule,
    MatTooltipModule,
    LetterIconModule,
    BreadcrumbItemModule,
    StatusChipModule,
    EntityHeaderDataItemModule,
    DialablePhoneNumberModule,
  ],
  exports: [EntityHeaderWithBreadcrumbsComponent],
})
export class EntityHeaderWithBreadcrumbsModule {}
