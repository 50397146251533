<fuse-alert
  *ngFor="let alert of issues | alertsFromIssues"
  [appearance]="'outline'"
  [showIcon]="true"
  [type]="alert.type"
  [@shake]="alert.type === 'error'"
>
  {{ alert.message }}
</fuse-alert>

<mat-form-field class="w-full">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    #groupSearchInput
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    [placeholder]="placeHolder"
    [required]="required"
    matInput
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    (click)="initialiseSearching()"
  />
  <mat-error> Organisation group is required</mat-error>
  <mat-icon matPrefix *ngIf="prefixIcon">{{ prefixIcon }} </mat-icon>

  <mat-spinner matSuffix diameter="18" *ngIf="searching"></mat-spinner>
</mat-form-field>
<mat-autocomplete
  #groupAutoComplete
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  (optionSelected)="selectGroup($event)"
>
  <ng-container *ngIf="groupSearch$ | async as groupSearch">
    <mat-option *ngFor="let group of groupSearch" [value]="group">
      <span>{{ group.displayName }}</span>
    </mat-option>
    <mat-option disabled *ngIf="groupSearch.length === 0 && searchComplete"
      >No results found</mat-option
    >
  </ng-container>
</mat-autocomplete>
