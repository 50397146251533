import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  LoginModel,
  NetworkUserModel,
  VerificationModel,
} from '@fleet/model';
import { AuthApiService } from '@fleet/api';
import { AuthService } from './../../services/auth.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'fleet-change-username-dialog',
  templateUrl: './change-username-dialog.component.html',
  styleUrls: ['./change-username-dialog.component.scss'],
})
export class ChangeUsernameDialogComponent implements OnInit {
  verification: VerificationModel;

  @Input() user: any;
  usernameControl = new UntypedFormControl(null, [
    Validators.required,
    Validators.email,
  ]);
  codeControl = new UntypedFormControl(null, [Validators.required]);
  fleetProduct: string;
  loading = false;
  issues: IssueModel[] = [];
  hasUsernameChanged = false;

  jwt: string;
  @Output() changeSuccess = new EventEmitter();
  @Output() driverUpdated = new EventEmitter();
  constructor(
    private authService: AuthService,
    @Inject('env') env: any,
    private matDialog: MatDialogRef<ChangeUsernameDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  ngOnInit(): void {
    this.authService.user$.subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }

  begin() {
    const verificationPayload = {
      email: this.usernameControl.value,
    } as any;
    if (this.user.networkUserId) {
      verificationPayload['networkUserId'] = this.user.networkUserId;
    } else if (this.user.driverId) {
      verificationPayload['driverId'] = this.user.driverId;
    }

    //DP
    this.loading = true;
    this.issues = [];
    this.changeDetectorRef.markForCheck();
    this.authService
      .verifyEmailWithJwt(verificationPayload as VerificationModel)
      .subscribe({
        next: (resp: ApiResponse<VerificationModel>) => {
          this.verification = resp.data;
          if (this.verification && this.verification.code) {
            this.codeControl.setValue(this.verification.code);
          }
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        },
        error: (error: any) => {
          this.loading = false;
          this.issues = error;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  verify() {
    this.loading = true;
    this.issues = [];
    this.changeDetectorRef.markForCheck();
    const verifyPayload = {
      ...this.verification,

      code: this.codeControl.value,
    };
    // if (this.networkUser) {
    //   verifyPayload['username'] = this.networkUser.username;
    // }
    this.authService.verify(verifyPayload).subscribe({
      next: (resp: ApiResponse<VerificationModel>) => {
        this.verification = resp.data;
        if (resp.data.token) {
          const payload = {
            verificationToken: resp.data.token,
            //username: this.networkUser.username,
          } as LoginModel;
          this.authService.changeUsername(payload).subscribe({
            next: (resp: HttpResponse<any>) => {
              const jwtHeader = resp.headers.get('Authorization');
              const jwt = jwtHeader.replace('Bearer ', '');
              this.authService.accessToken = jwt;
              this.hasUsernameChanged = true;

              //POST TO MOBILE APP

              this.loading = false;
              this.changeDetectorRef.markForCheck();
              this.changeSuccess.emit(this.usernameControl.value);
            },
            error: (error: any) => {
              this.loading = false;
              this.issues = error;
              this.changeDetectorRef.markForCheck();
            },
          });
        }
      },
      error: (error: any) => {
        this.loading = false;
        this.issues = error;
        this.changeDetectorRef.markForCheck();
      },
    });
  }
}
