<div class="w-full flex flex-col">
  <div class="flex flex-row gap-1">
    <mat-form-field class="w-full">
      <input
        (keyup.enter)="$event.preventDefault(); searchJob()"
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        matInput
        placeholder="Enter Job ID"
        [formControl]="searchControl"
      />
      <mat-spinner matSuffix diameter="24" *ngIf="searching"></mat-spinner>
    </mat-form-field>

    <button
      type="button"
      mat-flat-button
      (click)="searchJob()"
      [disabled]="!searchControl.valid || searching || !searchControl.value"
      [matTooltip]="'Find'"
      class="rounded-lg mt-1 bg-primary-600 text-white disabled:bg-gray-200"
    >
      Find
    </button>
  </div>

  <mat-error class="-mt-3" *ngIf="searchControl.errors?.NO_RESULTS">{{
    searchControl.errors?.NO_RESULTS
  }}</mat-error>
</div>
