import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BaseSearchState } from '@fleet/model';

@Component({
  selector: 'fleet-search-summary',
  templateUrl: './search-summary.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSummaryComponent implements OnInit {
  @Input() search: BaseSearchState<any>;
  @Input() entity = '';
  @Input() plural: string;
  constructor() {}

  ngOnInit(): void {}
}
