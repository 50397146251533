import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatReceiptPipe } from './format-receipt.pipe';

@NgModule({
  declarations: [FormatReceiptPipe],
  imports: [CommonModule],
  exports: [FormatReceiptPipe],
})
export class FormatReceiptPipeModule {}
