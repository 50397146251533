import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PlaceModel } from '@fleet/model';
import { displayLinesFromAddress } from '@fleet/utilities';

import { LocationService, ReverseGeocodeState } from '../location.service';

@Component({
  selector: 'fleet-reverse-geo-location',
  templateUrl: './reverse-geo-location.component.html',
  styleUrls: ['./reverse-geo-location.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReverseGeoLocationComponent implements OnInit, OnDestroy {
  reverseGeocodeState: ReverseGeocodeState;
  selectedPlace: PlaceModel;
  places: PlaceModel[];
  form: UntypedFormGroup;
  @Output() reverseGeocodeConfirmed = new EventEmitter();
  @Output() cancel = new EventEmitter();
  constructor(
    private locationService: LocationService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.locationService.reverseGeocode$.subscribe({
      next: (state) => {
        this.reverseGeocodeState = state;

        this.changeDetectorRef.markForCheck();
      },
    });
    this.locationService.reverseGeocodeResult$.subscribe({
      next: (places) => {
        this.places = places;
        if (places.length === 1) {
          this.selectedPlace = places[0];
          this.form.patchValue(places[0].structuredAddress);
        }
      },
    });
  }

  buildForm(): void {
    this.form = this.fb.group({
      unitNumber: [null],
      streetNumber: [null],
      streetName: [null],
      locality: [null],
      regionCode: [null, Validators.required],
      postalCode: [null, Validators.required],
      country: [],
    });
  }

  confirm() {
    // this.locationService.setSelectedPlace(Object.assign({},this.form.value,{});
    const structuredAddress = this.form.value;
    const displayLines = displayLinesFromAddress(structuredAddress);
    this.reverseGeocodeConfirmed.emit({
      structuredAddress: this.form.value,
      displayLine1: displayLines.displayLine1 ? displayLines.displayLine1 : '',
      displayLine2: displayLines.displayLine2 ? displayLines.displayLine2 : '',
      latitude: this.reverseGeocodeState.latitude,
      longitude: this.reverseGeocodeState.longitude,
    });
  }

  selectPlace(place: PlaceModel) {
    if (!this.form) {
      this.buildForm();
    }
    this.form.patchValue(place.structuredAddress);
    this.selectedPlace = place;
  }

  ngOnDestroy(): void {
    this.locationService.resetReverseGeoState();
  }
}
