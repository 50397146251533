import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { OperatorApiService, OrganisationApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  ContractModel,
  ContractSearchResultModel,
  IssueModel,
  OperatorModel,
  OrganisationModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { OrganisationService } from '@fleet/organisation-shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  selector: 'fleet-contract-selector',
  templateUrl: './contract-selector.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractSelectorComponent),
      multi: true,
    },
  ],
})
export class ContractSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() matLabelClasses = false;
  hasWritten = false;

  @Input() required = true;

  @Input() mode: 'id' | 'reference' | 'object' = 'id';

  hasContracts: BehaviorSubject<ContractSearchResultModel[]> =
    new BehaviorSubject([]);

  @Input()
  set operatorId(value: string) {
    if (value) {
      this.searchContracts(value, 'OPERATOR');
    }
  }

  _organisationId: string;
  @Input() set organisationId(value: string) {
    this._organisationId = value;
    if (value) {
      this.searchContracts(value, 'ORGANISATION');
    }
  }

  contractControl = new UntypedFormControl();
  constructor(
    private organisationApiService: OrganisationApiService,
    private networkGroupService: NetworkGroupService,
    private operatorApiService: OperatorApiService,
    private organisationService: OrganisationService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.contractControl.valueChanges.subscribe((val) => {
      // this.writeValue(val);
      this.onChange(val);
      this.onTouched();
    });
  }

  searchContracts(entityId: string, type: 'OPERATOR' | 'ORGANISATION') {
    let contractParams = { limit: 100, offset: 0 } as any;
    if (this.authService.networkId) {
      contractParams['networkId'] = this.authService.networkId;
    }
    if (this.networkGroupService.networkGroupId) {
      contractParams['networkGroupId'] =
        this.networkGroupService.networkGroupId;
    }

    let apiCall;

    if (type === 'OPERATOR') {
      apiCall = this.operatorApiService.searchContracts(
        contractParams,
        entityId
      );
    } else {
      apiCall = this.organisationApiService.searchContracts(
        contractParams,
        entityId
      );
    }

    apiCall.subscribe({
      next: (
        resp: HttpResponse<ApiResponse<ContractSearchResultModel[]>> | any
      ) => {
        if (resp.body.data.length > 0) {
          //Lets make any ACTIVE contracts be the top of the list
          //- as adjustments can only be added on contracts that are ACTIVE so it is a nice default.

          let contracts = resp.body.data.sort(
            (a: ContractModel, b: ContractModel) => {
              if (a.status === b.status) {
                return 0;
              } else if (a.status === 'ACTIVE') {
                return -1;
              } else if (b.status === 'ACTIVE') {
                return 1;
              } else if (a.status !== 'ACTIVE') {
                return -1;
              } else {
                return 1;
              }
            }
          );

          this.hasContracts.next(contracts);

          //We have contract - as we are searching on it by default we want to set it
          // this.contractControl.setValue(contracts[0]);
        } else {
          this.hasContracts.next([]);
          this.contractControl.updateValueAndValidity();
        }
      },
      error: (issues: IssueModel[]) => {
        this.hasContracts.next([]);
        this.contractControl.updateValueAndValidity();
      },
    });
  }

  writeValue(value: string) {
    if (!this.hasWritten) {
      this.contractControl.setValue(value);
    }

    this.hasWritten = false;

    if (value) {
      this.contractControl.setValue(value);
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
