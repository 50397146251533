import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './components/map/map.component';

import { MapMarkersPipe } from './pipes/map-markers.pipe';
import { MarkerSvgComponent } from './components/markers/marker-svg/marker-svg.component';
import { MatButtonModule } from '@angular/material/button';

import { PositionDetailCardModule } from '@fleet/card';

import { InternationalizationDatePipeModule } from '@fleet/internationalization';

import { ActiveJobCardModule } from '@fleet/card';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [MapComponent, MapMarkersPipe, MarkerSvgComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,

    MatButtonModule,
    PositionDetailCardModule,
    ActiveJobCardModule,
    InternationalizationDatePipeModule,
    MatProgressSpinnerModule,
  ],
  exports: [MapComponent],
  providers: [],
})
export class MapModule {}
