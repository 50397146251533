<fleet-dialog-layout
  *ngIf="attributeCreateEditState | async as state"
  [title]="state.title"
  [icon]="null"
  [showCancel]="true"
  [instructions]="state.instructions"
>
  <div slot="main" class="w-full">
    <fleet-attribute-control
      [mode]="'verticle'"
      class="w-full"
      [formControl]="addControl"
    >
    </fleet-attribute-control>
  </div>

  <fuse-alert
    slot="error"
    *ngFor="let alert of issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <button
    slot="action"
    [disabled]="!addControl.valid"
    mat-flat-button
    class="bg-primary-600 text-white disabled:bg-gray-200"
    (click)="saveAttribute()"
  >
    {{ state.buttonLabel }}
  </button>
</fleet-dialog-layout>
