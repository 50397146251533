import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationAutocompleteComponent } from './organisation-autocomplete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';

@NgModule({
  declarations: [OrganisationAutocompleteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
  ],
  exports: [OrganisationAutocompleteComponent],
})
export class OrganisationAutocompleteModule {}
