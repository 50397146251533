import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@fleet/auth';
// import { driverSettingsNavigationMenu } from '@fleet/driver';
import { DriverModel, DriverStatus, LifeCycleStage } from '@fleet/model';

import {
  FleetNavigationService,
  driverSettingsNavigationForMainMenu,
  driverSettingsNavigationMenu,
  filterNavigationItemsByFunctions,
  logoutNavigationMenu,
} from '@fleet/navigation';
import { ProductConfigurationService } from '@fleet/product-configuration';

import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fleet-native-app-webview-layout',
  templateUrl: './native-app-webview-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativeAppWebviewLayoutComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  title: string;
  pageGroupTitle: string;
  pageTitle: string;
  navigationMenu: any[] = [];
  showToolbar = true;
  isRegistration = false;
  @ViewChild('drawer')
  drawer: MatDrawer;
  logoSrc = 'assets/logos/fleet-driver-horiz.svg';
  constructor(
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _productConfigurationService: ProductConfigurationService,
    private authService: AuthService,
    private navigationService: FleetNavigationService
  ) {}

  ngOnInit(): void {
    //close draw when navigation end

    combineLatest([
      this.navigationService.nativeAppNavigation$,
      this._activatedRoute.queryParams,
    ]).subscribe(([navigation, queryParams]) => {
      const settingsNavigation = {
        type: 'group',
        title: 'Settings',
        children: [
          ...driverSettingsNavigationMenu.map((navItem: any) => {
            const treatedLink = navItem.link.replace('.', '');
            return { ...navItem, link: './driver/settings' + treatedLink };
          }),
          logoutNavigationMenu,
        ],
      };
      let settingsInDetail = false;
      let combinedNavigation: any = navigation
        .filter((navItem: any) => navItem.id !== 'logout')
        .map((navItem: any) => {
          if (navItem.title === 'Settings') {
            settingsInDetail = true;
            return settingsNavigation;
          }
          return navItem;
        });
      if (!settingsInDetail) {
        combinedNavigation = [settingsNavigation];
      }

      this.navigationMenu = filterNavigationItemsByFunctions(
        combinedNavigation,
        this.authService.permissions.value
      );
      if (queryParams['menu'] === 'settings') {
        //we have come to settings via native app so show user menu
        setTimeout(() => {
          this.drawer.open();
        }, 100);
      }
      this._changeDetectorRef.markForCheck();
    });

    let url = this._router.url;
    if (url.includes('?')) {
      url = url.split('?')[0];
    }
    this.syncRouteToPageTitle(url);
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((event: any) => {
        if (event.url.includes('/driver/registration')) {
          this.isRegistration = true;
          setTimeout(() => {
            this.navigationMenu = [
              { title: '', children: [logoutNavigationMenu] },
            ];
          }, 100);
        } else {
          this.isRegistration = false;
        }
        const routeData = this._activatedRoute.snapshot.firstChild.data;
        this.title = routeData.title;

        this.syncRouteToPageTitle(event.url);

        this.drawer.close();
        this._changeDetectorRef.markForCheck();
      });

    this._productConfigurationService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (config: any) => {
          if (config.horizontalMonoLogo) {
            this.logoSrc = config.horizontalMonoLogo;
            this._changeDetectorRef.markForCheck();
          }
        },
      });
  }

  toggleNavigation() {}

  syncRouteToPageTitle(url: string) {
    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    this.navigationMenu.forEach((group) => {
      if (group.children) {
        group.children.forEach((child: any) => {
          if (child.link.replace('./', '') == url) {
            this.pageTitle = child.title;
            this.pageGroupTitle = group.title;
            this._changeDetectorRef.markForCheck();
          }
        });
      }
    });

    if (url && url.includes('/auth/')) {
      this.showToolbar = false;
    } else {
      this.showToolbar = true;
    }
    this._changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
