import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsApiService {
  googleApiKey;
  httpClient: HttpClient;
  private isApiLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isApiLoaded$ = this.isApiLoaded.asObservable();

  constructor(
    private httpBackend: HttpBackend,
    private googleJSDKHttpClient: HttpClient,
    @Inject('env') env: any
  ) {
    this.httpClient = new HttpClient(httpBackend);
    this.googleApiKey = env.googleApiKey;
    console.log('🚀 ~ GoogleMapsService ~ loading google maps api...');
    this.googleJSDKHttpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=geometry,visualization,drawing,marker`,
        'callback'
      )
      .subscribe(
        () => {
          console.log('🚀 ~ GoogleMapsService ~ google maps api loaded');
          this.isApiLoaded.next(true);
        },
        (error) => {
          console.log(
            '🚀 ~ GoogleMapsService ~ google maps api cannot be loaded',
            error
          );
        }
      );
  }

  getTimezoneId(location: string): Observable<string> {
    const timeStamp = DateTime.now().toMillis();
    return this.httpClient
      .get(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=${timeStamp}&key=${this.googleApiKey}`
      )
      .pipe(
        map((resp: any) => {
          if (resp.status && resp.status === 'OK') {
            return resp.timeZoneId;
          }
          return null;
        })
      );
  }
}
