import { Pipe, PipeTransform } from '@angular/core';
import { FuseAlertAppearance, FuseAlertType } from '@fleet/fuse';
import { IssueLevel, IssueModel } from '@fleet/model';

export interface Alert {
  appearance?: FuseAlertAppearance;
  dismissed?: boolean;
  dismissible?: boolean;
  name?: string;
  showIcon?: boolean;
  type: FuseAlertType;
  message: string;
  fields: string[];
}

@Pipe({
  name: 'alertsFromIssues',
})
export class AlertsFromIssuesPipe implements PipeTransform {
  transform(value: IssueModel[], ...args: unknown[]): Alert[] {
    //need to handle tas errors which are different.
    // TAS errors are literally a string not an object

    if (value) {
      return value.map((issue: IssueModel) => {
        return {
          type: issueLevelToFuseType(issue.level),
          showIcon: true,
          appearance: 'soft',
          message: issue.message ? issue.message : issue,
          fields: issue.fields,
        } as Alert;
      });
    }
    //

    return [];
  }
}

function issueLevelToFuseType(level: string) {
  switch (level) {
    case IssueLevel.ERROR:
      return 'error';
    case IssueLevel.WARN:
      return 'warning';
    case IssueLevel.INFO:
      return 'primary';
    default:
      return 'error';
  }
}
