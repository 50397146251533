import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkGroupApiService } from '@fleet/api';
import {
  ApiResponse,
  DriverModel,
  DriverStatus,
  FunctionPermissionModel,
  FuseNavigationItem,
  GroupHierarchyItem,
  LifeCycleStage,
  NetworkGroupModel,
  NetworkGroupSearchResultModel,
  NetworkUserModel,
  OperatorModel,
  OperatorUserModel,
  OrganisationModel,
  OrganisationUserModel,
  TravellerModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { decodeToken } from '@fleet/utilities';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { fleetFlexNavigation } from './navigation-menus/flex-navigation';
import { networkNavigation } from './navigation-menus/network-navigation';

import { organisationMenu } from './navigation-menus/organisation-menu';
import { logoutNavigationMenu } from './navigation-menus/shared-navigation';
import { LocaleService } from '@fleet/locale';
import {
  filterNavigationItemsByFunctions,
  filterNavigationItemsForFleet,
} from './navigation-security';

import { operatorUserNavigation } from './navigation-menus/operator-user-navigation';

@Injectable({
  providedIn: 'root',
})
export class FleetNavigationService {
  currentUser: NetworkUserModel;
  navigation: BehaviorSubject<any> = new BehaviorSubject([]);
  currentGroup: BehaviorSubject<GroupHierarchyItem> = new BehaviorSubject(null);
  currentOrganisation: BehaviorSubject<OrganisationModel> = new BehaviorSubject(
    null
  );
  currentOperator: BehaviorSubject<OperatorModel> = new BehaviorSubject(null);
  flexNavigation: boolean;
  functions: FunctionPermissionModel[];
  nativeAppNavigation: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private networkGroupApiService: NetworkGroupApiService,
    private networkGroupService: NetworkGroupService,
    private localeService: LocaleService
  ) {}

  get navigation$(): Observable<FuseNavigationItem[]> {
    return this.navigation.asObservable();
  }

  get currentGroup$(): Observable<GroupHierarchyItem> {
    return this.currentGroup.asObservable();
  }

  get nativeAppNavigation$() {
    return this.nativeAppNavigation.asObservable();
  }

  get currentOrganisation$() {
    return this.currentOrganisation.asObservable();
  }

  get currentOperator$() {
    return this.currentOperator.asObservable();
  }

  setNavigation(navigation: FuseNavigationItem[], functions: any[]) {
    let secured = filterNavigationItemsByFunctions(navigation, functions);

    secured = filterNavigationItemsForFleet(navigation, this.currentUser);
    this.navigation.next(secured);
  }

  get currentOrganisationAndGroup() {
    return combineLatest();
  }

  clearNavigation() {
    this.navigation.next([]);
  }

  setFunctions(functions: FunctionPermissionModel[]) {
    this.functions = functions;
  }

  //this is for HUB only
  setCurrentOrganisation(
    organisation: OrganisationModel,
    functions?: FunctionPermissionModel[]
  ) {
    //check if this is the same on the id
    this.currentOrganisation.next(organisation);
    setTimeout(() => {
      if (organisation) {
        const orgNavigationIndex = networkNavigation[0].children.findIndex(
          (n: any) => n.id === 'organisation'
        );

        let newMenu = filterNavigationItemsByFunctions(
          organisationMenu,
          functions
        );
        newMenu = filterNavigationItemsForFleet(
          organisationMenu,
          this.currentUser
        );
        const orgNavChildren = newMenu.map(
          (orgNavigationItem: FuseNavigationItem) => {
            return {
              ...orgNavigationItem,

              children: orgNavigationItem.children
                ? orgNavigationItem.children
                    .filter((c: any) => c.id !== 'organisation-kiosk')
                    .map((childItem: any) => {
                      if (childItem.type === 'basic') {
                        return {
                          ...childItem,
                          link: organisation
                            ? 'organisation/' +
                              organisation.organisationId +
                              '/' +
                              childItem.link
                            : childItem.link,
                          classes: {
                            wrapper: 'bg-gray-800 -mx-2 pl-4 -mb-2 ',
                          },
                        };
                      } else {
                        //this is a collapsable menu item

                        return {
                          ...childItem,
                          link: childItem.link
                            ? organisation
                              ? 'organisation/' +
                                organisation.organisationId +
                                '/' +
                                childItem.link
                              : childItem.link
                            : null,
                          classes: {
                            wrapper: 'bg-gray-800 -mx-2 pl-4 -mb-2 ',
                          },
                          children: childItem.children.map(
                            (collapsedChildItem: any) => {
                              return {
                                ...collapsedChildItem,
                                link: organisation
                                  ? 'organisation/' +
                                    organisation.organisationId +
                                    '/' +
                                    collapsedChildItem.link
                                  : collapsedChildItem.link,
                              };
                            }
                          ),
                        };
                      }
                    })
                : [],

              link:
                'organisation/' +
                organisation.organisationId +
                orgNavigationItem.link,
              classes: {
                wrapper: 'bg-gray-800 -mx-2 pl-4 -mb-2',
              },
            };
          }
        );

        const orgNav = {
          type: 'group',

          id: 'current.organisation',
          title: organisation.name,
          classes: {
            wrapper: 'bg-gray-800 -mx-2 px-4 pt-3 h-16',
            title: 'text-white',
            host: 'bg-gray-800',
          },

          children: [
            ...orgNavChildren,
            {
              type: 'group',
              classes: { wrapper: 'bg-gray-800  -mx-2' },
            },
          ],
        } as any;

        const networkNavigationClone = JSON.parse(
          JSON.stringify(this.navigation.value)
        );

        const newNavigation = networkNavigationClone.map((n: any) => {
          //Does this ALREADY have a current org? If it does - replace it, otherwise, we are adding it
          const exists = n.children.find(
            (child: any) => child.id === 'current.organisation'
          );

          n.children.splice(orgNavigationIndex + 1, exists ? 1 : 0, orgNav);

          return n;
        });
        this.navigation.next(newNavigation);
      } else {
        //remove dividers and current org if present
        const currentNavigationClone = JSON.parse(
          JSON.stringify(this.navigation.value)
        );

        const cleanedNavigation = currentNavigationClone.map((n: any) => {
          const cleanedChildren = n.children
            ? n.children.filter((s: any) => {
                if (s.type === 'divider' || s.id === 'current.organisation') {
                  return false;
                }
                return true;
              })
            : [];
          return { ...n, children: cleanedChildren };
        });
        this.navigation.next(cleanedNavigation);
      }
    }, 100);
  }

  setCurrentOperator(operator: OperatorModel) {
    this.currentOperator.next(operator);

    if (operator) {
      setTimeout(() => {
        const operatorNavigationIndex = networkNavigation[0].children.findIndex(
          (n: any) => n.id === 'operator'
        );

        const operatorNavChildren = [
          {
            id: 'overview',
            title: 'Overview',
            icon: 'widgets',
            link: '/',
            type: 'basic',
            exactMatch: false,
            securityFunctions: ['OPERATOR_DETAIL:read'],
          },
          {
            id: 'vehicle',
            title: 'Vehicles',
            icon: 'local_taxi',
            type: 'basic',
            link: '/vehicle',
            securityFunctions: ['VEHICLE_SEARCH:read'],
          },
        ].map((operatorNavItem: any) => {
          return {
            ...operatorNavItem,
            link: 'operator/' + operator.operatorId + operatorNavItem.link,

            classes: {
              wrapper: 'bg-gray-800 -mx-2 pl-4 -mb-2',
            },
          };
        });

        const operatorNavGroup = {
          type: 'group',

          id: 'current.operator',
          title: operator.displayName,
          classes: {
            wrapper: 'bg-gray-800 -mx-2 px-4 pt-3 h-16',
            title: 'text-white',
            host: 'bg-gray-800',
          },

          children: [
            ...operatorNavChildren,
            {
              type: 'group',
              classes: { wrapper: 'bg-gray-800  -mx-2' },
            },
          ],
        } as any;

        const networkNavigationClone = JSON.parse(
          JSON.stringify(this.navigation.value)
        );

        let newNavigation = networkNavigationClone.map((n: any) => {
          n.children.splice(operatorNavigationIndex + 1, 0, operatorNavGroup);

          return n;
        });

        newNavigation = filterNavigationItemsByFunctions(
          newNavigation,
          this.functions
        );

        newNavigation = filterNavigationItemsForFleet(
          newNavigation,
          this.currentUser
        );

        console.log(' Setting Operator Navigation' + newNavigation);
        this.navigation.next(newNavigation);
      }, 100);
    } else {
      //remove dividers and current org if present
      const currentNavigationClone = JSON.parse(
        JSON.stringify(this.navigation.value)
      );

      const cleanedNavigation = currentNavigationClone.map((n: any) => {
        const cleanedChildren = n.children
          ? n.children.filter((s: any) => {
              if (s.type === 'divider' || s.id === 'current.operator') {
                return false;
              }
              return true;
            })
          : [];
        return { ...n, children: cleanedChildren };
      });
      console.log('setting null operator navigation:' + cleanedNavigation);
      this.navigation.next(cleanedNavigation);
    }
  }

  setCurrentGroupByNetworkGroup(group: NetworkGroupModel | any) {
    const currentGroup = {
      displayName: group.displayName,
      groupId: group.networkGroupId,

      type: group.type,
      link: '/group/' + group.networkGroupId,
    } as GroupHierarchyItem;

    //HACK FOR GENERIC
    //currentGroup.displayName = 'Taxii Perth';
    if (!currentGroup.groupId) {
      currentGroup.groupId = group.groupId;
    }
    if (!currentGroup.displayName) {
      currentGroup.displayName = group.groupName;
    }
    this.setCurrentGroup(currentGroup);
  }

  setCurrentGroup(group: any) {
    //find if group is child of
    let newGroup = null;

    if (
      this.currentGroup.value &&
      group &&
      group.parentId === this.currentGroup.value.groupId
    ) {
      newGroup = { ...group, parent: this.currentGroup.value };
    } else {
      newGroup = group;
    }

    this.currentGroup.next(newGroup);
    if (!this.flexNavigation) {
      let navigation = networkNavigation.map((navigationItem: any) => {
        const children = navigationItem.children;

        return {
          ...navigationItem,

          title: group ? group.displayName : null,
          classes: { title: 'text-white' },
          children: children.map((childItem: any) => {
            if (childItem.id === 'signout') {
              return childItem;
            }
            if (childItem.type === 'basic') {
              return {
                ...childItem,
                link: childItem.link,
              };
            } else {
              //this is a collapsable menu item
              const updatedCollaspable = {
                ...childItem,
                link: childItem.link ? childItem.link : null,
                children: childItem.children.map((collapsedChildItem: any) => {
                  return {
                    ...collapsedChildItem,
                    link: collapsedChildItem.link,
                  };
                }),
              };
              return updatedCollaspable;
            }
          }),
        };
      });
      navigation = filterNavigationItemsByFunctions(navigation, this.functions);

      navigation = filterNavigationItemsForFleet(navigation, this.currentUser);

      console.log('Setting navigation by group:' + navigation);
      this.navigation.next(navigation);
    } else {
      let navigation = filterNavigationItemsByFunctions(
        fleetFlexNavigation,
        this.functions
      );

      navigation = filterNavigationItemsForFleet(
        fleetFlexNavigation,
        this.currentUser
      );
      console.log('Setting navigation by group' + navigation);
      this.navigation.next(navigation);
    }
  }

  clearGroupFromNavigation() {
    this.navigation.next({ ...this.navigation.value });
  }

  configureMenuForUser(
    type: string,
    functions: FunctionPermissionModel[],
    user: any
  ) {
    this.currentUser = user;
    this.functions = functions;
    if (type === 'NETWORK') {
      //networkUser
      this.configureMenuForNetworkUser(user, functions);
    } else if (type === 'ORGANISATION') {
      //organisation user
      this.configureMenuForOrganisationUser(user, functions);
    } else if (type === 'STAFF') {
      //staff
    } else if (type === 'DRIVER') {
      this.configureMenuForDriver(user, functions);
      //driver
    } else if (type === 'TRAVELLER') {
      //fleet
      this.configureMenuForTraveller(user);
    } else if (type === 'OPERATOR') {
      this.configureMenuForOperatorUser(user, functions);
    }
  }

  configureMenuForNetworkUser(
    networkUser: NetworkUserModel,
    functions: FunctionPermissionModel[]
  ) {
    const jwt = localStorage.getItem('access_token');
    const decodedToken = decodeToken(jwt);
    if (decodedToken.groups.length > 0) {
      //this is swan
      if (decodedToken.groups.length === 1) {
        //just one groups
        this.setCurrentGroupByNetworkGroup(decodedToken.groups[0]);
        this.networkGroupService.getNetworkGroup(
          decodedToken.groups[0].groupId,
          decodedToken.networkId
        );
      } else {
        //here we pop the selector to choose group
        this.setCurrentGroupByNetworkGroup(decodedToken.groups[0]);
        this.networkGroupService.getNetworkGroup(
          decodedToken.groups[0].groupId,
          decodedToken.networkId
        );
      }
    } else {
      //admin god level
      this.networkGroupApiService
        .searchNetworkGroups(
          {
            includeChildren: true,
          },
          networkUser.networkId
        )
        .subscribe({
          next: (
            resp: HttpResponse<ApiResponse<NetworkGroupSearchResultModel[]>>
          ) => {
            const groupRemovedNavigation = networkNavigation.map(
              (topLevel: any) => {
                return {
                  ...topLevel,
                  children: topLevel.children.filter(
                    (s: any) => s.id !== 'network-group'
                  ),
                };
              }
            );

            let navigation = filterNavigationItemsByFunctions(
              groupRemovedNavigation,
              functions
            );

            navigation = filterNavigationItemsForFleet(
              groupRemovedNavigation,
              networkUser
            );
            console.log('Setting navigation for network user:' + navigation);
            this.navigation.next(navigation);
          },
          error: (issues) => {
            //error probably navigation to something went wrong page
          },
        });
    }
  }

  configureMenuForOrganisationUser(
    organisationUser: OrganisationUserModel,
    functions: any
  ) {
    let newMenu = filterNavigationItemsByFunctions(organisationMenu, functions);

    newMenu = filterNavigationItemsForFleet(organisationMenu, organisationUser);

    this.navigation.next([
      {
        type: 'group',
        children: [...newMenu, logoutNavigationMenu],
      },
    ]);
  }

  configureMenuForOperatorUser(
    operatorUser: OperatorUserModel,
    functions: any
  ) {
    const newMenu = filterNavigationItemsByFunctions(
      [
        // {
        //   id: 'job',
        //   title: 'Jobs',
        //   icon: 'place',
        //   link: '/job',
        //   id: 'shift',
        //   title: 'Shifts',
        //   icon: 'heroicons_outline:collection',
        //   link: '/shift',
        //   type: 'basic',
        //   exactMatch: false,
        // },
        {
          id: 'vehicle',
          title: 'Vehicles',
          icon: 'local_taxi',
          type: 'basic',
          link: '/vehicle',
          securityFunctions: ['VEHICLE_SEARCH:read'],
        },
        ...operatorUserNavigation,
      ],

      functions
    );

    this.navigation.next([
      {
        type: 'group',
        children: [...newMenu, logoutNavigationMenu],
      },
    ]);
  }

  configureMenuForDriver(driver: DriverModel, functions: any) {
    if (
      (driver.lifecycleStage === LifeCycleStage.Opportunity &&
        driver.status === DriverStatus.Incomplete) ||
      (driver.lifecycleStage === LifeCycleStage.Customer &&
        driver.status == DriverStatus.Incomplete)
    ) {
      //registration so just logout and the driver container will handle the menu for customers
      this.navigation.next([
        {
          id: 'logout',
          title: 'Logout',
          type: 'basic',
          icon: 'heroicons_outline:logout',
          link: '/auth/logout',
        },
      ]);
    } else {
      // let securedNavigation = this.filterByUserFunction(
      //   driverNavigation,
      //   this.functions
      // );
      // this.navigation.next(securedNavigation);
    }
  }

  configureMenuForTraveller(traveller: TravellerModel) {
    this.navigation.next([
      {
        type: 'group',
        children: [
          {
            id: 'job.new',
            title: 'New Trip',
            type: 'basic',
            icon: 'drive_eta',
            link: '/traveller/job/new',
          },
          {
            id: 'job.upcoming',
            title: 'Current / Upcoming Trips',
            type: 'basic',
            icon: 'event',
            link: '/traveller/job/upcoming',
          },
          {
            id: 'job.past',
            title: 'Past Trips',
            type: 'basic',
            icon: 'history',
            link: '/traveller/job/history',
          },
          {
            id: 'traveller.settings',
            title: 'Settings',
            type: 'basic',
            icon: 'heroicons_outline:cog',
            link: '/traveller/settings/personal',
          },
          {
            id: 'logout',
            title: 'Logout',
            type: 'basic',
            icon: 'heroicons_outline:logout',
            link: '/auth/logout',
          },
        ],
      },
    ]);
  }
}
