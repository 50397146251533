/* eslint-disable @typescript-eslint/no-empty-function */
import {
  IssueModel,
  LoginModel,
  ValidationModel,
  VerificationModel,
} from '@fleet/model';
import { Action, createAction, props } from '@ngrx/store';

export enum UserAuthActionTypes {
  UpdateVerificationPartial = '[User Auth] Update Verifcation Parital',
  Login = '[UserAuth] Login ',
  LoginSuccess = '[UserAuth] Login Success ',
  LoginFailure = '[UserAuth] Login Failure',

  GetUser = '[UserAuth] Get User ',
  GetUserSuccess = '[UserAuth] Get User Success',
  GetUserFailure = '[UserAuth] Get User Failure',

  GetPermissions = '[PermissionsAuth] Get Permissions ',
  GetPermissionsSuccess = '[PermissionsAuth] Get Permissions Success',
  GetPermissionsFailure = '[PermissionsAuth] Get Permissions Failure',

  Signup = '[UserAuth] Signup',
  SignupSuccess = '[UserAuth] Signup Success',
  SignupFailure = '[UserAuth] Signup Failure',

  UserLookup = '[UserAuth] UserLookup',
  UserLookupSuccess = '[UserAuth] UserLookup Success',
  UserLookupFailure = '[UserAuth] UserLookup Failure',

  AutoLogin = '[UserAuth] Auto Login',
  AutoLoginSuccess = '[UserAuth] Auto Login Success',
  AutoLoginFailure = '[UserAuth] Auto Login Failure',

  Logout = '[UserAuth] Logout',

  SetJWT = '[UserAuth] Set JWT',

  Lock = '[UserAuth] Lock',
  UnLock = '[UserAuth] Unlock',
  UnLockSuccess = '[UserAuth] Unlock Success',
  UnLockFailure = '[UserAuth] Unlock Failure',

  ForgotPassword = '[UserAuth] Forgot Password',
  ForgotPasswordSuccess = '[UserAuth] Forgot Password Success',
  ForgotPasswordFailure = '[UserAuth] Forgot Password Failure',

  NewPassword = '[UserAuth] New Password',
  NewPasswordSuccess = '[UserAuth] New Password Success',
  NewPasswordFailure = '[UserAuth] New Password Failure',

  Verify = '[UserAuth] Verify',
  VerifySuccess = '[UserAuth] Verify Success',
  VerifyFailure = '[UserAuth] Verify Failure',
  VerifyComplete = '[UserAuth] Verify Complete',

  ChangePhoneNumber = '[Auth] ChangePhoneNumber ',
  ChangePhoneNumberSuccess = '[Auth] ChangePhoneNumber Success',
  ChangePhoneNumberFailure = '[Auth] ChangePhoneNumber Failure',

  ChangeUsername = '[Auth] ChangeUsername ',
  ChangeUsernameSuccess = '[Auth] ChangeUsername Success',
  ChangeUsernameFailure = '[Auth] ChangeUsername Failure',

  //ADD RESET PASSWORD TO THE MAGIC

  ClearError = '[UserAuth] Clear Error',

  SetRoles = '[UserAuth] Set Roles',

  ValidateUsername = '[UserAuth] ValidateUsername ',
  ValidateUsernameSuccess = '[UserAuth] ValidateUsername Success',
  ValidateUsernameFailure = '[UserAuth] ValidateUsername Failure',
  ResetValidateUsername = '[UserAuth] Reset ValidateUsername',

  VerifyEmail = '[Auth] Verify Email',
  VerifyEmailSuccess = '[Auth] Verify Email Success',
  VerifyEmailFailure = '[Auth] Verify Email Failure',

  VerifyPhoneNumber = '[Auth] VerifyPhoneNumber ',
  VerifyPhoneNumberSuccess = '[Auth] VerifyPhoneNumber Success',
  VerifyPhoneNumberFailure = '[Auth] VerifyPhoneNumber Failure',

  SetVerificationToken = '[User Auth] Set verification token',

  GetInvitation = '[Auth] Get Invitation',
  GetInvitationSuccess = '[Auth] Get Invitation Success',
  GetInvitationFailure = '[Auth] Get Invitation Failure',
}

//THIS IS THE NEW ACTIONS
export const resetPassword = createAction(
  '[UserAuth] Reset Password',
  props<{ payload: any }>()
);

export const resetPasswordSuccess = createAction(
  '[UserAuth] Reset Password Success',
  props<{ payload: any }>()
);

export const resetPasswordFailure = createAction(
  '[UserAuth] Reset Password Failure',
  props<{ payload: any }>()
);

export const verifyCodeForEmail = createAction(
  '[UserAuth] Verify Code',
  props<{ payload: any }>()
);

export const verifyCodeForEmailSuccess = createAction(
  '[UserAuth] Verify Code Success',
  props<{ payload: any }>()
);

export const verifyCodeForEmailFailure = createAction(
  '[UserAuth] Verify Code Failure',
  props<{ payload: any }>()
);

export const loginViaGoogle = createAction(
  '[Google Auth SignIn]',
  props<{ payload: any }>()
);

export const setRoutedFrom = createAction(
  '[User Auth] Set routedFrom',
  props<{ payload: string }>()
);

export class VerifyPhoneNumber implements Action {
  readonly type = UserAuthActionTypes.VerifyPhoneNumber;
  constructor(public payload: any) {}
}

export class VerifyPhoneNumberSuccess implements Action {
  readonly type = UserAuthActionTypes.VerifyPhoneNumberSuccess;
  constructor(public payload: any) {}
}

export class VerifyPhoneNumberFailure implements Action {
  readonly type = UserAuthActionTypes.VerifyPhoneNumberFailure;
  constructor(public payload: any) {}
}

export class SetVerificationToken implements Action {
  readonly type = UserAuthActionTypes.SetVerificationToken;
  constructor(public verificationToken: string) {}
}

export const selectValidationModel = createAction(
  '[User Auth] Select Validation Model',
  props<{ validationModel: ValidationModel }>()
);

export class Login implements Action {
  readonly type = UserAuthActionTypes.Login;
  constructor(public payload: { username: string; password: string }) {}
}

export class LoginSuccess implements Action {
  readonly type = UserAuthActionTypes.LoginSuccess;
  constructor(public payload: any) {}
}

export class LoginFailure implements Action {
  readonly type = UserAuthActionTypes.LoginFailure;
  constructor(public payload: any) {}
}

export class GetUser implements Action {
  readonly type = UserAuthActionTypes.GetUser;
  constructor(public payload: { decodedJwt: any; route: boolean }) {}
}

export class GetUserSuccess implements Action {
  readonly type = UserAuthActionTypes.GetUserSuccess;
  constructor(public payload: { user: any; route: boolean }) {}
}

export class GetUserFailure implements Action {
  readonly type = UserAuthActionTypes.GetUserFailure;
  constructor(public payload: any) {}
}

export class GetPermissions implements Action {
  readonly type = UserAuthActionTypes.GetPermissions;
  constructor(public payload: any) {}
}

export class GetPermissionsSuccess implements Action {
  readonly type = UserAuthActionTypes.GetPermissionsSuccess;
  constructor(public payload: any) {}
}

export class GetPermissionsFailure implements Action {
  readonly type = UserAuthActionTypes.GetPermissionsFailure;
  constructor(public payload: any) {}
}

export class Signup implements Action {
  readonly type = UserAuthActionTypes.Signup;
  constructor(public payload: any) {}
}

export class SignupSuccess implements Action {
  readonly type = UserAuthActionTypes.SignupSuccess;
  constructor(public payload: any) {}
}

export class SignupFailure implements Action {
  readonly type = UserAuthActionTypes.SignupFailure;
  constructor(public payload: any) {}
}

export class UserLookup implements Action {
  readonly type = UserAuthActionTypes.UserLookup;
  constructor(public payload: any) {}
}

export class UserLookupSuccess implements Action {
  readonly type = UserAuthActionTypes.UserLookupSuccess;
  constructor(public payload: any) {}
}

export class UserLookupFailure implements Action {
  readonly type = UserAuthActionTypes.UserLookupFailure;
  constructor(public payload: any) {}
}

export class AutoLogin implements Action {
  readonly type = UserAuthActionTypes.AutoLogin;
  constructor(public jwt: string) {}
}

export class AutoLoginSuccess implements Action {
  readonly type = UserAuthActionTypes.AutoLoginSuccess;
}

export class AutoLoginFailure implements Action {
  readonly type = UserAuthActionTypes.AutoLoginFailure;
}

export class Logout implements Action {
  readonly type = UserAuthActionTypes.Logout;
}

export class SetJWT implements Action {
  readonly type = UserAuthActionTypes.SetJWT;
  constructor(public jwt: string) {}
}

export class UnLock implements Action {
  readonly type = UserAuthActionTypes.UnLock;
  constructor(public payload: any) {}
}

export class UnLockSuccess implements Action {
  readonly type = UserAuthActionTypes.UnLockSuccess;
  constructor() {}
}

export class UnLockFailure implements Action {
  readonly type = UserAuthActionTypes.UnLockFailure;
  constructor(public payload: any) {}
}

export class Lock implements Action {
  readonly type = UserAuthActionTypes.Lock;
}

export class SetRoles implements Action {
  readonly type = UserAuthActionTypes.SetRoles;
  constructor(public payload: any) {}
}

export class ForgotPassword implements Action {
  readonly type = UserAuthActionTypes.ForgotPassword;
  constructor(public payload: any) {}
}

export class ForgotPasswordSuccess implements Action {
  readonly type = UserAuthActionTypes.ForgotPasswordSuccess;
  constructor(public payload: any) {}
}

export class ForgotPasswordFailure implements Action {
  readonly type = UserAuthActionTypes.ForgotPasswordFailure;
  constructor(public payload: string) {}
}

export class NewPassword implements Action {
  readonly type = UserAuthActionTypes.NewPassword;
  constructor(public payload: any) {}
}

export class NewPasswordSuccess implements Action {
  readonly type = UserAuthActionTypes.NewPasswordSuccess;
}

export class NewPasswordFailure implements Action {
  readonly type = UserAuthActionTypes.NewPasswordFailure;
  constructor(public payload: string) {}
}

export class Verify implements Action {
  readonly type = UserAuthActionTypes.Verify;
  constructor(public payload: any) {}
}

export class VerifySuccess implements Action {
  readonly type = UserAuthActionTypes.VerifySuccess;
  constructor(public payload: any) {}
}

export class VerifyFailure implements Action {
  readonly type = UserAuthActionTypes.VerifyFailure;
  constructor(public payload: any) {}
}

export class GetInvitation implements Action {
  readonly type = UserAuthActionTypes.GetInvitation;
  constructor(public payload: any) {}
}

export class GetInvitationSuccess implements Action {
  readonly type = UserAuthActionTypes.GetInvitationSuccess;
  constructor(public payload: VerificationModel) {}
}

export class GetInvitationFailure implements Action {
  readonly type = UserAuthActionTypes.GetInvitationFailure;
  constructor(public payload: any) {}
}

export class VerifyComplete implements Action {
  readonly type = UserAuthActionTypes.VerifyComplete;
  constructor(public payload: any) {}
}

export class ClearError implements Action {
  readonly type = UserAuthActionTypes.ClearError;
}

export class ValidateUsername implements Action {
  readonly type = UserAuthActionTypes.ValidateUsername;
  constructor(public payload: any) {}
}

export class ValidateUsernameSuccess implements Action {
  readonly type = UserAuthActionTypes.ValidateUsernameSuccess;
  constructor(public payload: any) {}
}

export class ValidateUsernameFailure implements Action {
  readonly type = UserAuthActionTypes.ValidateUsernameFailure;
  constructor(public payload: any) {}
}

export class ResetValidateUsername implements Action {
  readonly type = UserAuthActionTypes.ResetValidateUsername;
  constructor() {}
}

export class VerifyEmail implements Action {
  readonly type = UserAuthActionTypes.VerifyEmail;
  constructor(public payload: any) {}
}

export class VerifyEmailSuccess implements Action {
  readonly type = UserAuthActionTypes.VerifyEmailSuccess;
  constructor(public payload: VerificationModel) {}
}

export class VerifyEmailFailure implements Action {
  readonly type = UserAuthActionTypes.VerifyEmailFailure;
  constructor(public payload: string) {}
}

export class ChangePhoneNumber implements Action {
  readonly type = UserAuthActionTypes.ChangePhoneNumber;
  constructor(public payload: LoginModel) {}
}

export class ChangePhoneNumberSuccess implements Action {
  readonly type = UserAuthActionTypes.ChangePhoneNumberSuccess;
  constructor() {}
}

export class ChangePhoneNumberFailure implements Action {
  readonly type = UserAuthActionTypes.ChangePhoneNumberFailure;
  constructor(public payload: any) {}
}

export class ChangeUsername implements Action {
  readonly type = UserAuthActionTypes.ChangeUsername;
  constructor(public payload: LoginModel) {}
}

export class ChangeUsernameSuccess implements Action {
  readonly type = UserAuthActionTypes.ChangeUsernameSuccess;
  constructor(public payload: any) {}
}

export class ChangeUsernameFailure implements Action {
  readonly type = UserAuthActionTypes.ChangeUsernameFailure;
  constructor(public payload: any) {}
}

export class UpdateVerificationPartial implements Action {
  readonly type = UserAuthActionTypes.UpdateVerificationPartial;
  constructor(public payload: any) {}
}

export type UserAuthActions =
  | Login
  | LoginSuccess
  | LoginFailure
  | GetUser
  | GetUserSuccess
  | GetUserFailure
  | GetPermissions
  | GetPermissionsSuccess
  | GetPermissionsFailure
  | Signup
  | SignupSuccess
  | SignupFailure
  | UserLookup
  | UserLookupSuccess
  | UserLookupFailure
  | AutoLogin
  | AutoLoginSuccess
  | AutoLoginFailure
  | Logout
  | SetJWT
  | UnLock
  | UnLockFailure
  | UnLockSuccess
  | Lock
  | SetRoles
  | ForgotPassword
  | ForgotPasswordSuccess
  | ForgotPasswordFailure
  | NewPassword
  | NewPasswordSuccess
  | NewPasswordFailure
  | Verify
  | VerifySuccess
  | VerifyFailure
  | VerifyComplete
  | ClearError
  | ValidateUsername
  | ValidateUsernameSuccess
  | ValidateUsernameFailure
  | ResetValidateUsername
  | VerifyEmail
  | VerifyEmailSuccess
  | VerifyEmailFailure
  | VerifyPhoneNumber
  | VerifyPhoneNumberSuccess
  | VerifyPhoneNumberFailure
  | SetVerificationToken
  | ChangePhoneNumber
  | ChangePhoneNumberSuccess
  | ChangePhoneNumberFailure
  | ChangeUsername
  | ChangeUsernameSuccess
  | ChangeUsernameFailure
  | GetInvitation
  | GetInvitationSuccess
  | GetInvitationFailure
  | UpdateVerificationPartial;
