import { FuseNavigationItem } from '@fleet/model';

export const fleetFlexNavigation: FuseNavigationItem[] = [
  {
    id: 'driver',
    title: 'Drivers',
    icon: 'person',
    link: '/driver',

    type: 'basic',
  },
  {
    id: 'job',
    title: 'Job',
    icon: 'place',
    link: '/job',
    type: 'basic',
  },
  {
    id: 'organisation',
    title: 'Organisations',
    icon: 'business',
    link: '/organisation',

    type: 'basic',
  },
  {
    id: 'traveller',
    title: 'Travellers',
    icon: 'hail',
    type: 'basic',
    link: '/traveller',
  },
];
