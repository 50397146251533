import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

const form = {
  firstName: new UntypedFormControl(),
  lastName: new UntypedFormControl(),
  phoneNumber: new UntypedFormControl(),
  email: new UntypedFormControl(),
  lifecycleStage: new UntypedFormControl(),
  status: new UntypedFormControl(),
};

@Injectable({
  providedIn: 'root',
})
export class TravellerSearchForm extends UntypedFormGroup {
  constructor() {
    super(form);
  }
}
