import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, IssueModel, VerificationModel } from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { catchError } from 'rxjs/operators';
import { AuthApiService } from '../auth/auth-api.service';
import { paths } from '@fleet/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationApiService {
  nonAuthorisedHttp: HttpClient;
  headers: HttpHeaders;
  host: string;

  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    @Inject('env') env: any
  ) {
    this.headers = new HttpHeaders({
      'fleet-channel': 'WEB',
      'fleet-product': env.fleetProduct,
    });
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.validation;
  }

  driverLookup(params: any) {
    return this.http
      .get(`${this.host}/driver/lookup`, {
        headers: this.headers,
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  driverUsernameInUse(username: string) {
    return this.http
      .get(`${this.host}/driver/username?username=${username}`, {
        headers: this.headers,
      })
      .pipe(catchError(handleApiError));
  }

  validateABN(abn: string) {
    {
      return this.http
        .get(`${this.host}/abn?abn=${abn}`)
        .pipe(catchError(handleApiError));
    }
  }

  searchBsb(bsb: string) {
    return this.http
      .get(`${this.host}/bsb?bsb=${bsb}`)
      .pipe(catchError(handleApiError));
  }
}
