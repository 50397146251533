import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { JobApiService } from '@fleet/api';
import { ApiResponse, IssueModel, JobModel } from '@fleet/model';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { failureNotification } from '@fleet/utilities';
import { DateTime } from 'luxon';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fleet-job-extend-expiry',
  templateUrl: './job-extend-expiry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobExtendExpiryComponent implements OnInit, OnDestroy {
  _job: JobModel;
  _unsubscribeAll = new Subject();
  @Output() jobExtended = new EventEmitter();
  show = false;
  @Input() set job(value: JobModel) {
    this._job = value;
  }

  loading = false;

  get job() {
    return this._job;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private jobApiService: JobApiService,
    private onScreenNotificationService: OnscreenNotificationService
  ) {}

  ngOnInit(): void {
    timer(1000, 1000)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this.job) {
          if (
            this.job.jobDetail.extendConfirmationTime &&
            DateTime.now() > this.job.jobDetail.extendConfirmationTime
            // DateTime.fromISO(
            //   this.job.jobDetail.extendConfirmationTime.toString()
            // )
          ) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          this.show = false;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  extend() {
    this.loading = true;
    this.changeDetectorRef.markForCheck();
    this.jobApiService.extendExpiry(this.job.jobId).subscribe({
      next: (resp: ApiResponse<JobModel>) => {
        this.jobExtended.emit(resp.data);
      },
      error: (error: IssueModel[]) => {
        this.onScreenNotificationService.setNotification({
          ...failureNotification,
          title: 'Failed to extend job expiry. Please try again',
        } as OnScreenNotification);
      },
    });
  }
}
