import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NoResultsLabelModule } from '@fleet/shared';
import { EntityHeaderWithBreadcrumbsModule } from './../entity-header-with-breadcrumbs/entity-header-with-breadcrumbs.module';
import { RelatedEntitySearchLayoutComponent } from './related-entity-search-layout.component';

@NgModule({
  declarations: [RelatedEntitySearchLayoutComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    EntityHeaderWithBreadcrumbsModule,
    MatProgressSpinnerModule,
    NoResultsLabelModule,
  ],
  exports: [RelatedEntitySearchLayoutComponent],
})
export class RelatedEntitySearchLayoutModule {}
