<div
  class="mt-2 flex items-center text-sm leading-none dark:text-gray-300"
  [matTooltip]="dataItem.toolTip"
  (click)="dataItem.link ? dataItemClicked() : null"
  [ngClass]="[
    dataItem.link && !dataItem.isChip ? 'hover:border-b-2 cursor-pointer' : '',
    dataItem.color ? dataItem.color : 'text-gray-500'
  ]"
>
  <ng-container [ngSwitch]="dataItem.type">
    <fleet-dialable-phone-number
      *ngSwitchCase="'DIALABLE_PHONENUMBER'"
      [phoneNumber]="dataItem.dialablePhoneNumber.phoneNumber"
      [tooltip]="dataItem.dialablePhoneNumber.tooltip"
      [labelIcon]="dataItem.dialablePhoneNumber.labelIcon"
    ></fleet-dialable-phone-number>

    <ng-container *ngSwitchCase="'BADGE'">
      <fleet-badge
        [variationConfig]="{
          variation: 'flat',
          color: dataItem.badgeColor,
          size: 'small',
          dark: true
        }"
        [label]="dataItem.label"
      >
      </fleet-badge>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="!dataItem.isChip; else isChip">
        <ng-container
          *ngTemplateOutlet="standard; context: { $implicit: dataItem }"
        ></ng-container>
      </ng-container>
      <ng-template #isChip>
        <ng-container
          *ngTemplateOutlet="chip; context: { $implicit: dataItem }"
        ></ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #standard let-dateItem>
  <ng-container *ngIf="dataItem.icon?.length === 1; else normalIcon">
    <fleet-letter-icon
      class="flex-shrink-0 mr-1.5"
      [text]="dataItem.icon"
      iconSize="5"
    ></fleet-letter-icon>
  </ng-container>
  <ng-template #normalIcon>
    <mat-icon
      class="flex-shrink-0 mr-1.5 icon-size-5"
      [ngClass]="dataItem.color ? dataItem.color : 'text-gray-400'"
    >
      {{ dataItem.icon }}</mat-icon
    >
  </ng-template>
  <span>{{ dataItem.label }}</span>
  <div class="ml-1 flex flex-row gap-0.5" *ngIf="dataItem.dialablePhoneNumber">
    <span>(</span>
    <fleet-dialable-phone-number
      [phoneNumber]="dataItem.dialablePhoneNumber.phoneNumber"
      [tooltip]="dataItem.dialablePhoneNumber.tooltip"
    ></fleet-dialable-phone-number>
    <span>)</span>
  </div>
</ng-template>

<ng-template #chip let-dataItem>
  <fleet-status-chip [status]="dataItem.label"></fleet-status-chip>
</ng-template>
