import { Routes } from '@angular/router';
import { FunctionAuthorisedGuard } from '@fleet/auth';
import { JobGuard } from '@fleet/job';
import { LayoutComponent } from '@fleet/layout';
import { TravellerResolveGuard } from '@fleet/traveller';
import { InitialDataResolverTravellerService } from './guards/initial-data-resolver-traveller.service';
import { TravellerAuthGuard } from './guards/traveller-auth.guard';
import { TravellerGuestJobGuard } from './guards/traveller-guest-job.guard';
const jobRoutes = [
  {
    path: 'not-authorised',
    loadChildren: () =>
      import('@fleet/authorisation').then((m) => m.AuthorisationModule),
  },

  {
    path: 'job/not-authorised',
    loadChildren: () =>
      import('@fleet/authorisation').then((m) => m.AuthorisationModule),
  },
  {
    path: 'job/edit/:jobId',
    data: {
      title: 'Jobs',
      functions: ['TRAVELLER_JOB_SEARCH:read'],
    },
    canActivate: [FunctionAuthorisedGuard],
    loadChildren: () =>
      import(
        './../../../../libs/job/src/lib/job-create/job-create.module'
      ).then((m) => m.JobCreateModule),
  },
  // {
  //   path: 'job/:jobId/map',
  //   canActivate: [JobGuard],
  //   loadChildren: () =>
  //     import('./../../../../libs/job/src/lib/job-map/job-map.module').then(
  //       (m) => m.JobMapModule
  //     ),
  //   data: { title: 'Map' },
  // },
  {
    path: 'job/upcoming',
    data: {
      title: 'Jobs',
      functions: ['TRAVELLER_JOB_SEARCH:read'],
      nonAuthorisedRedirect: '/not-authorised',
    },
    canActivate: [FunctionAuthorisedGuard],
    loadChildren: () =>
      import(
        './../../../../libs/traveller/src/lib/traveller-upcoming-jobs/traveller-upcoming-jobs.module'
      ).then((m) => m.TravellerUpcomingJobsModule),
  },
  {
    path: 'job/history',
    data: {
      title: 'Jobs',
      functions: ['TRAVELLER_JOB_SEARCH:read'],
      nonAuthorisedRedirect: '/not-authorised',
    },
    canActivate: [FunctionAuthorisedGuard],
    loadChildren: () =>
      import(
        './../../../../libs/traveller/src/lib/traveller-past-jobs/traveller-past-jobs.module'
      ).then((m) => m.TravellerPastJobsModule),
  },
  {
    path: 'job/:jobId',
    canActivate: [JobGuard],
    loadChildren: () =>
      import(
        './../../../../libs/job/src/lib/job-detail/job-detail.module'
      ).then((m) => m.JobDetailModule),
  },
  {
    path: 'settings/not-authorised',
    loadChildren: () =>
      import('@fleet/authorisation').then((m) => m.AuthorisationModule),
  },
  {
    path: 'settings',
    data: {
      functions: ['TRAVELLER_SETTINGS:read'],
      nonAuthorisedRedirect: '/not-authorised',
    },
    canActivate: [FunctionAuthorisedGuard],
    loadChildren: () =>
      import(
        './../../../../libs/traveller/src/lib/traveller-settings/traveller-settings.module'
      ).then((m) => m.TravellerSettingsModule),
  },
];
export const travellerAppRoutes: Routes = [
  { path: '', redirectTo: 'traveller/job/new', pathMatch: 'full' },
  {
    path: 'not-authorised',
    loadChildren: () =>
      import('@fleet/authorisation').then((m) => m.AuthorisationModule),
  },
  {
    path: 'auth',
    component: LayoutComponent,
    data: { layout: 'empty' },
    loadChildren: () => import('@fleet/auth').then((m) => m.AuthModule),
  },
  {
    path: 'link/:token',
    component: LayoutComponent,
    data: { layout: 'empty' },
    loadChildren: () =>
      import('./job-link/job-link.module').then((m) => m.JobLinkModule),
  },
  {
    path: 'guest',
    component: LayoutComponent,
    data: { layout: 'traveller' },
    children: [
      {
        path: 'job/new',
        canActivate: [TravellerGuestJobGuard],
        loadChildren: () =>
          import(
            './../../../../libs/job/src/lib/job-create/job-create.module'
          ).then((m) => m.JobCreateModule),
      },
      ...jobRoutes,
    ],
    canActivate: [TravellerAuthGuard],
  },
  {
    path: 'traveller',
    component: LayoutComponent,
    data: { layout: 'traveller' },
    resolve: {
      initialData: InitialDataResolverTravellerService,
      traveller: TravellerResolveGuard,
    },
    canActivate: [TravellerAuthGuard],
    children: [
      {
        path: 'job/new',
        loadChildren: () =>
          import(
            './../../../../libs/job/src/lib/job-create/job-create.module'
          ).then((m) => m.JobCreateModule),
      },
      ...jobRoutes,
      {
        path: 'details-required',
        data: { layout: 'empty' },
        loadChildren: () =>
          import(
            './../../../../libs/traveller/src/lib/traveller-details-required/traveller-details-required.module'
          ).then((m) => m.TravellerDetailsRequiredModule),
      },
    ],
  },
  {
    path: 'error-500',
    loadChildren: () =>
      import(
        './../../../../libs/maintenance/src/lib/error-five-hundred/error-five-hundred.module'
      ).then((m) => m.ErrorFiveHundredModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('@fleet/fuse/lib/pages/error/error-404/error-404.module').then(
        (m) => m.Error404Module
      ),
  },
];
