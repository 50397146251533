import { Pipe, PipeTransform } from '@angular/core';
import { JobSearchResultModel } from '@fleet/model';

export interface JobColors {
  textColor: string;
  bgColor: string;
  badgeColor: string;
  textAndBgColor: string;
}

const GREEN_COLOR = {
  textColor: 'text-green-700',
  bgColor: 'bg-green-50',
  badgeColor: 'green',
  textAndBgColor: 'bg-green-50 text-green-700',
};
const BLUE_COLOR = {
  textColor: 'text-blue-700',
  bgColor: 'bg-blue-50',
  badgeColor: 'blue',
  textAndBgColor: 'bg-blue-50 text-blue-700',
};
const YELLOW_COLOR = {
  textColor: 'text-yellow-700',
  bgColor: 'bg-yellow-50',
  badgeColor: 'yellow',
  textAndBgColor: 'bg-yellow-50 text-yellow-700',
};
const ORANGE_COLOR = {
  textColor: 'text-orange-700',
  bgColor: 'bg-orange-50',
  badgeColor: 'orange',
  textAndBgColor: 'bg-orange-50 text-orange-700',
};
const GRAY_COLOR = {
  textColor: 'text-gray-700',
  bgColor: 'bg-gray-50',
  badgeColor: 'gray',
  textAndBgColor: 'bg-gray-50 text-gray-700',
};
const RED_COLOR = {
  textColor: 'text-red-700',
  bgColor: 'bg-red-50',
  badgeColor: 'red',
  textAndBgColor: 'bg-red-50 text-red-700',
};
const PURPLE_COLOR = {
  textColor: 'text-purple-700',
  bgColor: 'bg-purple-50',
  badgeColor: 'purple',
  textAndBgColor: 'bg-purple-50 text-purple-700',
};
const PINK_COLOR = {
  textColor: 'text-pink-700',
  bgColor: 'bg-pink-50',
  badgeColor: 'pink',
  textAndBgColor: 'bg-pink-50 text-pink-700',
};
const INDIGO_COLOR = {
  textColor: 'text-indigo-700',
  bgColor: 'bg-indigo-50',
  badgeColor: 'indigo',
  textAndBgColor: 'bg-indigo-50 text-indigo-700',
};
const TEAL_COLOR = {
  textColor: 'text-teal-700',
  bgColor: 'bg-teal-50',
  badgeColor: 'teal',
  textAndBgColor: 'bg-teal-50 text-teal-700',
};
const DEFAULT_COLOR = {
  textColor: 'text-black',
  bgColor: 'bg-white',
  badgeColor: 'black',

  textAndBgColor: 'bg-white text-black',
};
const RED_600_COLOR = {
  textColor: 'text-red-600',
  bgColor: 'bg-red-50',
  badgeColor: 'red-600',
  textAndBgColor: 'bg-red-50 text-red-600',
};
const ORANGE_500_COLOR = {
  textColor: 'text-orange-500',
  bgColor: 'bg-orange-50',
  badgeColor: 'orange-500',
  textAndBgColor: 'bg-orange-50 text-orange-500',
};
const AMBER_500_COLOR = {
  textColor: 'text-amber-500',
  bgColor: 'bg-amber-50',
  badgeColor: 'amber-500',
  textAndBgColor: 'bg-amber-50 text-amber-500',
};
const EMERALD_500_COLOR = {
  textColor: 'text-emerald-500',
  bgColor: 'bg-emerald-50',
  badgeColor: 'emerald-500',
  textAndBgColor: 'bg-emerald-50 text-emerald-500',
};
const LIME_500_COLOR = {
  textColor: 'text-lime-500',
  bgColor: 'bg-lime-50',
  badgeColor: 'lime-500',
  textAndBgColor: 'bg-lime-50 text-lime-500',
};
const GREEN_500_COLOR = {
  textColor: 'text-green-500',
  bgColor: 'bg-green-50',
  badgeColor: 'green-500',
  textAndBgColor: 'bg-green-50 text-green-500',
};
const BLUE_500_COLOR = {
  textColor: 'text-blue-500',
  bgColor: 'bg-blue-50',
  badgeColor: 'blue-500',
  textAndBgColor: 'bg-blue-50 text-blue-500',
};

@Pipe({
  name: 'jobColors',
})
export class JobColorsPipe implements PipeTransform {
  transform(job: JobSearchResultModel | any, ...args: unknown[]): JobColors {
    if (job) {
      switch (job.status) {
        case 'OPEN':
        case 'ACKNOWLEDGEMENT_REQUIRED':
          return GRAY_COLOR;

        case 'ABANDONED':
          return ORANGE_500_COLOR;
        case 'DISPATCHED':
          return AMBER_500_COLOR;

        case 'ASSIGNED':
          return EMERALD_500_COLOR;

        case 'STARTED':
          return GREEN_500_COLOR;
        case 'IN_PROGRESS':
          return LIME_500_COLOR;
        case 'FINISHED':
          return BLUE_500_COLOR;
        case 'EXPIRED':
        case 'CANCELLED':
          return RED_600_COLOR;

        default:
          return DEFAULT_COLOR;
      }
    }
    return null;
  }
}
