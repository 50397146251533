import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  AuditDiffModel,
  DocumentModel,
  IssueModel,
  NetworkConditionModel,
  NoteModel,
  ReferenceDataItemModel,
  ReturnAuthorisationModel,
  StateChangeModel,
  SwapModel,
  VehicleAreaRankingModel,
  VehicleAuthorityModel,
  VehicleInsuranceModel,
  VehicleMakeSearchResultModel,
  VehicleModel,
  VehicleModelSearchResultModel,
  VehicleRankingModel,
  VehicleRegistrationModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VehicleApiService {
  host;
  envName: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.vehicle;

    //TEST end point - Simon is using a test end point for his own ease (for ranking and vicinity)

    this.envName = env.envName;
  }

  searchVehicles(params: any): Observable<any> {
    return this.http
      .get(`${this.host}`, {
        //note the /search
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getVehicle(passengerRef: string): Observable<any> {
    return this.http
      .get(`${this.host}/${passengerRef}`, {})
      .pipe(catchError(handleApiError));
  }

  createVehicle(payload: VehicleModel) {
    return this.http
      .post(`${this.host}`, payload)
      .pipe(catchError(handleApiError));
  }

  processUploadedDocument(vehicleId: string, documentId: string) {
    return this.http
      .post(`${this.host}/${vehicleId}/document/${documentId}`, {})
      .pipe(catchError(handleApiError));
  }

  updateVehicle(vehicle: VehicleModel) {
    return this.http
      .put(`${this.host}/${vehicle.vehicleId}`, vehicle)
      .pipe(catchError(handleApiError));
  }

  activate(vehicleId: string): Observable<any> {
    return this.http
      .post(`${this.host}/${vehicleId}/activate`, {})
      .pipe(catchError(handleApiError));
  }

  searchAssets(vehicleId: string, params: any) {
    return this.http
      .get(`${this.host}/${vehicleId}/asset`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }

  provisionAsset(vehicleId: string, params: any) {
    return this.http
      .post(`${this.host}/${vehicleId}/provision`, params)
      .pipe(catchError(handleApiError));
  }

  searchDocuments(
    vehicleId: string,
    params: any
  ): Observable<ApiResponse<DocumentModel[]> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/document`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchModels(
    params: any
  ): Observable<ApiResponse<VehicleModelSearchResultModel[]> | any> {
    return this.http
      .get(`${this.host}/model`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }

  searchMakes(
    params: any
  ): Observable<ApiResponse<VehicleMakeSearchResultModel[]> | any> {
    return this.http
      .get(`${this.host}/make`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }

  updateAction(vehicleId: string, notificationId: string, action: any) {
    return this.http
      .put(
        `${this.host}/${vehicleId}/notification/${notificationId}/action/${action.actionId}`,
        action
      )
      .pipe(catchError(handleApiError));
  }

  createRegistration(
    vehicleId: string,
    vehicleRegistration: VehicleRegistrationModel
  ): Observable<ApiResponse<VehicleRegistrationModel> | any> {
    return this.http
      .post(`${this.host}/${vehicleId}/registration`, vehicleRegistration)
      .pipe(catchError(handleApiError));
  }

  updateRegistration(
    vehicleId: string,
    vehicleRegistrationId: string,
    vehicleRegistration: VehicleRegistrationModel
  ): Observable<ApiResponse<VehicleRegistrationModel> | any> {
    return this.http
      .put(
        `${this.host}/${vehicleId}/registration/${vehicleRegistrationId}`,
        vehicleRegistration
      )
      .pipe(catchError(handleApiError));
  }

  getRegistration(
    vehicleId: string,
    vehicleRegistrationId: string
  ): Observable<ApiResponse<VehicleRegistrationModel> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/registration/${vehicleRegistrationId}`)
      .pipe(catchError(handleApiError));
  }

  searchRegistrations(
    vehicleId: string
  ): Observable<ApiResponse<VehicleRegistrationModel[]> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/registration/`)
      .pipe(catchError(handleApiError));
  }

  processUploadedRegistrationDocument(
    vehicleId: string,
    vehicleRegistrationId: string,
    documentId: string
  ): Observable<ApiResponse<DocumentModel> | any> {
    return this.http
      .post(
        `${this.host}/${vehicleId}/registration/${vehicleRegistrationId}/document/${documentId}`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  searchRegistrationDocuments(
    vehicleId: string,
    vehicleRegistrationId: string,
    params: any
  ): Observable<ApiResponse<DocumentModel[]> | any> {
    return this.http
      .get(
        `${this.host}/${vehicleId}/registration/${vehicleRegistrationId}/document`,
        {
          params: encodeParams(params),
        }
      )
      .pipe(catchError(handleApiError));
  }

  createAuthority(
    vehicleId: string,
    vehicleAuthority: any
  ): Observable<ApiResponse<VehicleRegistrationModel> | any> {
    return this.http
      .post(`${this.host}/${vehicleId}/authority`, vehicleAuthority)
      .pipe(catchError(handleApiError));
  }

  updateAuthority(
    vehicleId: string,
    vehicleRegistrationId: string,
    vehicleAuthority: any
  ): Observable<ApiResponse<VehicleRegistrationModel> | any> {
    return this.http
      .put(
        `${this.host}/${vehicleId}/authority/${vehicleRegistrationId}`,
        vehicleAuthority
      )
      .pipe(catchError(handleApiError));
  }

  getAuthority(
    vehicleId: string,
    vehicleAuthorityId: string
  ): Observable<ApiResponse<VehicleRegistrationModel> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/authority/${vehicleAuthorityId}`)
      .pipe(catchError(handleApiError));
  }

  processUploadedAuthorityDocument(
    vehicleId: string,
    vehicleAuthorityId: string,
    documentId: string
  ): Observable<ApiResponse<DocumentModel> | any> {
    return this.http
      .post(
        `${this.host}/${vehicleId}/authority/${vehicleAuthorityId}/document/${documentId}`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  searchAuthorityDocuments(
    vehicleId: string,
    vehicleAuthorityId: string,
    params: any
  ): Observable<ApiResponse<DocumentModel[]> | any> {
    return this.http
      .get(
        `${this.host}/${vehicleId}/authority/${vehicleAuthorityId}/document`,
        {
          params: encodeParams(params),
        }
      )
      .pipe(catchError(handleApiError));
  }

  searchAuthorities(
    vehicleId: string
  ): Observable<ApiResponse<VehicleAuthorityModel[]> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/authority`)
      .pipe(catchError(handleApiError));
  }

  getVehicleRanking(
    vehicleId: string
  ): Observable<ApiResponse<VehicleRankingModel[]> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/ranking`)
      .pipe(catchError(handleApiError));
  }

  getVehicleAreaRankings(
    vehicleId: string,
    areaId: string,
    params: any
  ): Observable<ApiResponse<VehicleAreaRankingModel[]> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/ranking/area/${areaId}`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchVehicleInsurance(
    vehicleId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<VehicleInsuranceModel[]>> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/insurance`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchVehicleInsuranceDocuments(
    vehicleId: string,
    vehicleInsuranceId: string,
    params: any
  ): Observable<ApiResponse<DocumentModel[]> | any> {
    return this.http
      .get(
        `${this.host}/${vehicleId}/insurance/${vehicleInsuranceId}/document`,
        {
          params: encodeParams(params),
        }
      )
      .pipe(catchError(handleApiError));
  }

  createVehicleInsurance(
    vehicleId: string,
    insurance: VehicleInsuranceModel
  ): Observable<ApiResponse<DocumentModel> | any> {
    return this.http
      .post(`${this.host}/${vehicleId}/insurance`, insurance)
      .pipe(catchError(handleApiError));
  }

  updateVehicleInsurance(
    vehicleId: string,
    vehicleInsuranceId: string,
    insurance: VehicleInsuranceModel
  ): Observable<ApiResponse<DocumentModel> | any> {
    return this.http
      .put(
        `${this.host}/${vehicleId}/insurance/${vehicleInsuranceId}`,
        insurance
      )
      .pipe(catchError(handleApiError));
  }

  processUploadedInsuranceDocument(
    vehicleId: string,
    vehicleInsuranceId: string,
    documentId: string
  ): Observable<ApiResponse<DocumentModel> | any> {
    return this.http
      .post(
        `${this.host}/${vehicleId}/insurance/${vehicleInsuranceId}/document/${documentId}`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  statuses(
    params: any
  ): Observable<ApiResponse<ReferenceDataItemModel[]> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/status`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  addCondition(
    condition: NetworkConditionModel,
    vehicleId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/${vehicleId}/condition`, condition)
      .pipe(catchError(handleApiError));
  }

  removeCondition(
    vehicleIdConditionId: string,
    vehicleId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .delete(`${this.host}/${vehicleId}/condition/${vehicleIdConditionId}`)
      .pipe(catchError(handleApiError));
  }

  searchLifecycleTransitions(
    vehicleId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/lifecycleTransition`)
      .pipe(catchError(handleApiError));
  }

  suspendVehicle(vehicleId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${vehicleId}/suspend`, stateChange)
      .pipe(catchError(handleApiError));
  }

  deactivateVehicle(vehicleId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${vehicleId}/deactivate`, stateChange)
      .pipe(catchError(handleApiError));
  }

  activateVehicle(vehicleId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${vehicleId}/activate`, stateChange)
      .pipe(catchError(handleApiError));
  }

  installVehicle(vehicleId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${vehicleId}/install`, stateChange)
      .pipe(catchError(handleApiError));
  }

  approveVehicle(vehicleId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${vehicleId}/approve`, stateChange)
      .pipe(catchError(handleApiError));
  }

  closeVehicle(vehicleId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${vehicleId}/close`, stateChange)
      .pipe(catchError(handleApiError));
  }

  exchangeAsset(
    vehicleId: string,
    assetId: string,
    returnAuthorisation: ReturnAuthorisationModel
  ) {
    return this.http
      .post(
        `${this.host}/${vehicleId}/asset/${assetId}/exchange`,
        returnAuthorisation
      )
      .pipe(catchError(handleApiError));
  }

  activateAsset(vehicleId: string, assetId: string) {
    return this.http
      .put(`${this.host}/${vehicleId}/asset/${assetId}/activate`, {})
      .pipe(catchError(handleApiError));
  }

  reportLostAsset(
    vehicleId: string,
    assetId: string,
    payload: ReturnAuthorisationModel
  ) {
    return this.http
      .post(`${this.host}/${vehicleId}/asset/${assetId}/lost`, payload)
      .pipe(catchError(handleApiError));
  }

  recoverAsset(
    vehicleId: string,
    assetId: string,
    payload: ReturnAuthorisationModel
  ) {
    return this.http
      .post(`${this.host}/${vehicleId}/asset/${assetId}/recover`, payload)
      .pipe(catchError(handleApiError));
  }

  // searchVehicleRankings(vehicleId: string, params: any) {
  //   return this.http
  //     .get(`${this.host}/${vehicleId}/ranking`, {
  //       observe: 'response',
  //       params: params,
  //     })
  //     .pipe(catchError(handleApiError));
  // }
  searchVehicleRankingsByArea(vehicleId: string, areaId: string, params: any) {
    return this.http
      .get(`${this.host}/${vehicleId}/area/${areaId}`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  searchDispatchLogs(vehicleId: string, params: any) {
    return this.http
      .get(`${this.host}/${vehicleId}/dispatchLog`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchVehicleRevisions(
    vehicleId: string,
    params: any
  ): Observable<ApiResponse<AuditDiffModel[]> | any> {
    return this.http
      .get(`${this.host}/${vehicleId}/revisions`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  deleteLifecycleTransition(
    lifecycleTransitionId: string,
    vehicleId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .delete(
        `${this.host}/${vehicleId}/lifecycleTransition/${lifecycleTransitionId}`
      )
      .pipe(catchError(handleApiError));
  }

  offroadVehicle(vehicleId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${vehicleId}/offroad`, stateChange)
      .pipe(catchError(handleApiError));
  }

  uninstallNotificationVehicle(vehicleId: string, stateChange: any) {
    return this.http
      .post(`${this.host}/${vehicleId}/uninstallNotification`, stateChange)
      .pipe(catchError(handleApiError));
  }

  uninstallVehicle(vehicleId: string, payload: any): Observable<any> {
    return this.http
      .post(`${this.host}/${vehicleId}/uninstall`, payload)
      .pipe(catchError(handleApiError));
  }

  transferVehicle(
    vehicleId: string,
    operatorId: string,
    stateChange: StateChangeModel
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${vehicleId}/operator/${operatorId}/transfer`,
        stateChange
      )
      .pipe(catchError(handleApiError));
  }

  cancelUninstallNotification(vehicleId: string): Observable<any> {
    return this.http
      .post(`${this.host}/${vehicleId}/cancelUninstallNotification`, {})
      .pipe(catchError(handleApiError));
  }

  swapAsset(vehicleId: string, assetId: string, swap: SwapModel) {
    return this.http
      .post(`${this.host}/${vehicleId}/asset/${assetId}/swap`, swap)
      .pipe(catchError(handleApiError));
  }
}
