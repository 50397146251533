import { Inject, Pipe, PipeTransform } from '@angular/core';
import { JobModel, JobSearchResultModel } from '@fleet/model';
import { JobStatus } from '@fleet/model';
import { JobState } from '@fleet/model';
import { JobStatusPipe } from '@fleet/pipes';

import {
  JobAction,
  JobActionType,
  cancelUpdateAction,
  cloneAction,
  updateAction,
  bookAction,
  cancelAction,
  resetAction,
  clearAction,
  deleteAction,
  manualDispatchAction,
  unAssignAction,
} from './job-action.model';

@Pipe({
  name: 'jobAction',
})
export class JobActionPipe implements PipeTransform {
  fleetProduct: string;
  constructor(@Inject('env') env: any) {
    this.fleetProduct = env.fleetProduct;
  }
  transform(value: any, hideActions?: string[]): JobAction[] {
    if (!value) return null;
    const status = value.jobStatus ? value.jobStatus : value.status;
    let availableActions: any[] = [];
    switch (status) {
      // case JobStatus.AWAITING_DISPATCH:
      //   availableActions = [cancelAction];
      //   break;
      case JobStatus.OPEN:
      case JobStatus.ACKNOWLEDGEMENT_REQUIRED:
        availableActions = [deleteAction];
        break;

      case JobStatus.CANCELLED:
        availableActions = [];
        break;
      case JobStatus.DISPATCHED:
        availableActions = [cancelAction];

        if (this.fleetProduct === 'HUB') {
          availableActions.push(manualDispatchAction);
        }
        break;

      case JobStatus.ASSIGNED:
      case JobStatus.STARTED:
        availableActions = [cancelAction];
        if (this.fleetProduct === 'HUB') {
          availableActions.push(unAssignAction);
          availableActions.push(manualDispatchAction);
        }

        break;

      case JobStatus.ABANDONED:
        availableActions = [cancelAction];
        if (this.fleetProduct === 'HUB') {
          availableActions.push(manualDispatchAction);
        }
        break;

      case JobStatus.IN_PROGRESS:
        availableActions = [cancelAction];
        if (this.fleetProduct === 'HUB') {
          availableActions.push(unAssignAction);
          //force
        }
        break;
      case JobStatus.EXPIRED:
        break;
    }

    if (this.fleetProduct === 'HUB' || this.fleetProduct === 'BUSINESS') {
      availableActions.push(cloneAction);
    }

    if (hideActions) {
      availableActions = availableActions.filter((action) => {
        if (hideActions.includes(action.type)) {
          return false;
        }
        return true;
      });
    }
    return availableActions;
  }
}

export function canDispatch(job: JobModel) {
  if (
    job &&
    job.jobDetail &&
    (job.jobStatus == null || job.jobStatus == undefined)
  ) {
    if (
      job.jobDetail.startLocation &&
      job.jobDetail.endLocation &&
      job.jobDetail.startTime &&
      job.payment &&
      // job.payment.primarySale.paymentMethod.profileId &&
      job.payment.primarySale?.paymentMethod?.paymentMethodId &&
      job.traveller &&
      job.traveller.travellerProfileId
    ) {
      return true;
    }
  }
  return false;
}
