import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { ApiResponse, PaymentTransactionModel } from '@fleet/model';
import { encodeParams } from '@fleet/utilities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  host;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.payment;
  }

  searchPaymentTransactions(
    params: any
  ): Observable<ApiResponse<PaymentTransactionModel[]> | any> {
    return this.http.get(`${this.host}/paymentTransaction`, {
      observe: 'response',
      params: encodeParams(params),
    });
  }

  getPaymentTransaction(
    paymentTransactionId: string
  ): Observable<ApiResponse<PaymentTransactionModel> | any> {
    return this.http.get(`${this.host}/${paymentTransactionId}`, {});
  }
}
