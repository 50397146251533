import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface NotAuthorisedRouteDetail {
  route: string;
  functionTypes: string[];
}
@Injectable({
  providedIn: 'root',
})
export class AuthorisationService {
  notAuthorisedRouteDetail: BehaviorSubject<NotAuthorisedRouteDetail> =
    new BehaviorSubject(null);
  constructor() {}

  get notAuthorisedRouteDetail$() {
    return this.notAuthorisedRouteDetail.asObservable();
  }

  setNotAuthorisedRouteDetails(detail: NotAuthorisedRouteDetail) {
    this.notAuthorisedRouteDetail.next(detail);
  }
}
