/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { AddressModel, LocalityModel } from '@fleet/model';

import { LocationService } from '../location.service';

@Component({
  selector: 'fleet-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationFormComponent),
      multi: true,
    },
  ],
})
export class LocationFormComponent
  implements OnInit, ControlValueAccessor, Validator
{
  _mode: 'DEFAULT' | 'SEARCH' | 'FORM' = 'DEFAULT';
  @Input() set mode(value: 'DEFAULT' | 'SEARCH' | 'FORM') {
    this._mode = value;
    if (value === 'SEARCH') {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].clearValidators();
        this.form.controls[key].updateValueAndValidity();
      });
      this.form.clearValidators();
      this.form.updateValueAndValidity();
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  get mode() {
    return this._mode;
  }
  form: FormGroup;
  //params for locality searching
  _regionCode: string;
  @Input() set regionCode(value: string) {
    this._regionCode = value;
    if (!this.form) {
      this.buildForm();
    }
    this.form.patchValue({ regionCode: value });
  }
  get regionCode() {
    return this._regionCode;
  }

  @Input() appearance: any = 'fill';

  @Output() manualSubmit = new EventEmitter();

  regionControl = new FormControl();
  localityControl = new FormControl();

  @Output() localityLatLng = new EventEmitter();

  @Input() set onlyRegionPostcodeRequired(value: boolean) {
    if (value) {
      this.form = this.fb.group({
        unitNumber: [null],
        streetNumber: [null],
        streetName: [null],
        locality: [null],
        regionCode: [null, Validators.required],
        postalCode: [null, Validators.required],
        countryCode: [],
      });
    }
  }

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.buildForm();

    this.form.valueChanges.subscribe((changes: any) => {
      this.onChange(changes);
      this.onTouched();
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.localityControl.valueChanges.subscribe((locality: LocalityModel) => {
      this.localityLatLng.emit({
        latitude: locality.latitude,
        longitude: locality.longitude,
      });
    });
  }

  buildForm(): void {
    this.form = this.fb.group({
      unitNumber: [null],
      streetNumber: [
        null,
        this.mode === 'DEFAULT' ? [Validators.required] : [],
      ],
      streetName: [null, this.mode === 'DEFAULT' ? [Validators.required] : []],
      locality: [null, this.mode === 'DEFAULT' ? [Validators.required] : []],
      regionCode: [null, this.mode === 'DEFAULT' ? [Validators.required] : []],
      postalCode: [null, this.mode === 'DEFAULT' ? [Validators.required] : []],
      countryCode: [],
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  onValidatorChange: any = () => {};

  writeValue(address: AddressModel | null): void {
    if (address && this.form) {
      this.form.reset(); // Reset the form state before setting new values
      this.form.setValue(
        {
          unitNumber: address.unitNumber ?? null,
          streetNumber: address.streetNumber ?? null,
          streetName: address.streetName ?? null,
          locality: address.locality ?? null,
          regionCode: address.regionCode ?? null,
          postalCode: address.postalCode ?? null,
          countryCode: address.countryCode ?? null,
        },
        { emitEvent: false }
      ); // Set values without emitting an event
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.form.valid ? null : {};
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  submit() {
    this.manualSubmit.emit(this.form.value);
    //this.locationService.setPlaceDetailFromAddressModel(this.form.value);
  }
}
