import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BatchExportItemModule } from '../batch-export-item/batch-export-item.module';
import { ExportComponent } from './export.component';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ExportComponent],
  imports: [
    CommonModule,
    BatchExportItemModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [ExportComponent],
})
export class ExportModule {}
