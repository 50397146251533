import {
  ActiveJobDisplay,
  DriverSearchResultModel,
  JobDriverModel,
  JobStatusUpdateAndProgress,
  ShiftModel,
} from '@fleet/model';
import { JobModel, JobSearchResultModel, JobTemplateModel } from '@fleet/model';
import { DateTime } from 'luxon';

export function jobModelToJobTemplate(job: JobModel): JobTemplateModel {
  const template: JobTemplateModel = {
    travellerId: job.traveller ? job.traveller.travellerId : null,
    serviceClass: job.jobDetail.serviceClass,
    serviceClassId: job.jobDetail.serviceClassId,
    serviceLine: job.jobDetail.serviceLine,
    serviceLineId: job.jobDetail.serviceLineId,
    networkServiceId: job.jobDetail.networkServiceId,
    pricingMethodId: job.fare.pricingMethodId,
    organisationId: job.organisation ? job.organisation.organisationId : null,
    organisationGroupId:
      job.organisation && job.organisation.organisationGroupId
        ? job.organisation.organisationGroupId
        : null,

    startLocation: job.jobDetail.startLocation,
    endLocation: job.jobDetail.endLocation,
    additionalInstructions: job.jobDetail.additionalInstructions,
    phoneNumber: job.traveller ? job.traveller.contactNumber : null,

    conditions: job.jobDetail.conditions
      ? job.jobDetail.conditions.map((s) => {
          return { networkConditionId: s.networkConditionId };
        })
      : [],

    primaryTravellerProfileId: job.traveller
      ? job.traveller.travellerProfileId
      : null,
  } as any;

  if (template.endLocation && !template.endLocation.latitude) {
    delete template['endLocation'];
  }

  return template;
}

export function nextSLA(now: DateTime, sla: DateTime) {
  const dif = sla.diff(now, ['minutes', 'seconds']).toObject();
  let time = '';
  if (dif.minutes > 0) {
    time += dif.minutes + 'm ';
  }
  time += Math.round(dif.seconds) + 's';
  return 'Next ' + time;
}

export function jobModelFromJobSearchResultModel(
  searchResult: JobSearchResultModel
) {
  return {
    jobStatus: searchResult.status,
    jobDetail: {
      startLocation: {
        latitude: searchResult.startLatitude,
        longitude: searchResult.startLongitude,
      },
      endLocation: {
        latitude: searchResult.endLatitude,
        longitude: searchResult.endLongitude,
      },
    },
  } as JobModel;
}

export function jobModelFromJobStatusUpdateAndProgress(
  job: JobStatusUpdateAndProgress
) {
  return {
    ...jobModelFromJobSearchResultModel(job.latestStatusUpdate),
    jobProgress: job.latestJobProgress,
  } as JobModel;
}

export function driverSearchResultToJobDriver(dsr: DriverSearchResultModel) {
  return {
    driverId: dsr.driverId,
    name: dsr.displayName,
    contactNumber: dsr.phoneNumber,
    photoUrl: dsr.imageUrl,
    rating: null,
    taxiAuthorityNumber: dsr.taxiAuthorityNumber,
  } as JobDriverModel;
}

export function isJobStartwithinShift(job: JobModel, shift: ShiftModel) {
  let shiftStart = shift.scheduledStart;
  if (shift.start) {
    shiftStart = shift.start;
  }
  let shiftEnd = shift.scheduledEnd;
  if (shift.end) {
    shiftEnd = shift.end;
  }
  let jobStartTime = job.jobDetail.startTime;
  if (jobStartTime < DateTime.now()) {
    jobStartTime = DateTime.now();
  }

  if (typeof shiftEnd === 'string') {
    shiftEnd = DateTime.fromISO(shiftEnd);
  }
  if (typeof shiftStart === 'string') {
    shiftStart = DateTime.fromISO(shiftStart);
  }
  if (typeof jobStartTime === 'string') {
    jobStartTime = DateTime.fromISO(jobStartTime);
  }

  if (jobStartTime > shiftStart && jobStartTime < shiftEnd) {
    return { withinShift: true, withinExtendedShift: false };
  } else if (
    jobStartTime &&
    shiftEnd &&
    jobStartTime > shiftStart &&
    jobStartTime < shiftEnd.plus({ hours: 2 })
  ) {
    return { withinShift: false, withinExtendedShift: true };
  }
  return { withinShift: false, withinExtendedShift: false };
}

export function jobColorFromStatus(status: string) {
  switch (status) {
    case 'OPEN':
    case 'ACKNOWLEDGEMENT_REQUIRED':
      return { badgeColor: 'gray', borderColor: 'border-gray' };

    case 'ABANDONED':
      return { badgeColor: 'orange-500', borderColor: 'border-orange-500' };
    case 'DISPATCHED':
      return { badgeColor: 'amber-500', borderColor: 'border-amber-500' };
    case 'ASSIGNED':
      return { badgeColor: 'emerald-500', borderColor: 'border-emerald-500' };
    case 'STARTED':
      return { badgeColor: 'green-500', borderColor: 'border-green-500' };
    case 'IN_PROGRESS':
      return { badgeColor: 'lime-500', borderColor: 'border-lime-500' };
    case 'FINISHED':
      return { badgeColor: 'blue-500', borderColor: 'border-blue-500' };
    case 'EXPIRED':
    case 'CANCELLED':
      return { badgeColor: 'red-600', borderColor: 'border-red-600' };
    default:
      return { badgeColor: 'gray', borderColor: 'border-gray' };
  }
}

export function createActiveJobDisplay(
  job: JobStatusUpdateAndProgress
): ActiveJobDisplay {
  const display = {} as ActiveJobDisplay;

  const slaStartTime = DateTime.fromISO(
    job.latestStatusUpdate?.dispatchSlaStartTime.toString()
  );

  display.slaStartTime = slaStartTime.toFormat('h:mm');
  display.slaStartTimeAMPM = slaStartTime.toFormat('a');
  display.slaLevel = 0;
  display.outerRingStroke = job.statusColor?.badgeColor;
  display.slaColor = 'gray-500';
  display.startLocality = job.latestStatusUpdate?.startLocality;
  display.endLocality = job.latestStatusUpdate?.endLocality;
  display.jobId = job.latestStatusUpdate?.jobId;
  display.networkConditionIds =
    job.latestStatusUpdate?.networkConditionIds ?? [];
  display.status = job.latestStatusUpdate?.status ?? '';
  display.eta = job.latestJobProgress?.displayEta;
  display.distance = job.latestJobProgress?.distance?.displayValue;
  display.travellerFirstName = job.latestStatusUpdate?.travellerFirstName;
  display.travellerLastName = job.latestStatusUpdate?.travellerLastName;
  display.organisationName = job.latestStatusUpdate?.organisationName;
  display.driverFirstName = job.latestStatusUpdate?.driverFirstName;
  display.driverTaxiAuthorityNumber =
    job.latestStatusUpdate?.driverAuthorityNumber;
  display.plateNumber = job.latestStatusUpdate?.plateNumber;
  display.numberOfTravellers = job.latestStatusUpdate?.numberOfTravellers;

  const now = DateTime.now().setZone(slaStartTime.zone, {
    keepLocalTime: true,
  });
  const dispatchSlaLevelThree = DateTime.fromISO(
    job.latestStatusUpdate?.dispatchSlaLevelThree.toString()
  );
  display.millisecondsTillSLA3 = now.diff(dispatchSlaLevelThree).toMillis();

  if (now > slaStartTime) {
    const dispatchSlaLevelTwo = DateTime.fromISO(
      job.latestStatusUpdate?.dispatchSlaLevelTwo.toString()
    );
    const dispatchSlaLevelOne = DateTime.fromISO(
      job.latestStatusUpdate?.dispatchSlaLevelOne.toString()
    );

    if (now > dispatchSlaLevelThree) {
      display.slaLevel = 4;
      display.slaColor = 'red-500';

      const minutes = Math.round(
        now.diff(slaStartTime, 'minutes').toObject().minutes
      );
      display.timeUntilNextSla = `Late ${minutes}min`;
    } else if (now > dispatchSlaLevelTwo) {
      display.slaLevel = 3;
      display.timeUntilNextSla = nextSLA(now, dispatchSlaLevelThree);
      display.slaColor = 'red-500';
    } else if (now > dispatchSlaLevelOne) {
      display.slaLevel = 2;
      display.timeUntilNextSla = nextSLA(now, dispatchSlaLevelTwo);
      display.slaColor = 'orange-500';
    } else {
      display.slaLevel = 1;
      display.timeUntilNextSla = nextSLA(now, dispatchSlaLevelOne);
      display.slaColor = 'emerald-500';
    }
  }

  return display;
}
