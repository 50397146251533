import { Pipe, PipeTransform } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

@Pipe({
  name: 'removeProperties',
})
export class RemovePropertiesPipe implements PipeTransform {
  transform(value: any, propertiesToRemove: string[]): unknown {
    const clone = Object.assign({}, value);
    propertiesToRemove.forEach((property) => {
      delete clone[property];
    });
    return clone;
  }
}
