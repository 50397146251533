<mat-form-field class="w-full" *ngIf="networkGroupSearchState | async as state">
  <mat-label [ngClass]="matLabelClasses ? 'text-sm font-light' : ''">Network group</mat-label>

  <mat-select [required]="required" placeholder="Select network" [formControl]="networkGroupControl"
    name="networkGroupId">
    <mat-option *ngFor="let networkGroup of state.data" [value]="networkGroup">
      {{ networkGroup.displayName | titleCaseAndClean }}
    </mat-option>
  </mat-select>
</mat-form-field>
