export class JobAction {
  label: string;
  isPrimary: boolean;
  type: JobActionType;
  needsConfirmation: boolean;
  confirmationTitle?: string;
  confirmationDescription?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  enabled: boolean;
}

export enum JobActionType {
  book = 'BOOK',
  update = 'UPDATE',
  cancel = 'CANCEL',
  bid = 'BID',
  dispatch = 'DISPATCH',
  manualDispatch = 'MANUAL_DISPATCH',
  updateBid = 'UPDATE_BID',
  decline = 'DECLINE',
  confirm = 'CONFIRM',
  abandon = 'ABANDON',
  arrived = 'ARRIVED',
  depart = 'DEPART',
  startJob = 'START_JOB',
  approach = 'APPROACH',
  finish = 'FINISH',
  clear = 'CLEAR',
  clone = 'CLONE',
  edit = 'EDIT',
  cancelUpdate = 'CANCEL_UPDATE',
  delete = 'DELETE',
  unassign = 'UNASSIGN',
}

export const cancelAction: JobAction = {
  type: JobActionType.cancel,
  isPrimary: true,
  label: 'Cancel',
  needsConfirmation: true,
  enabled: true,
  cancelButtonLabel: 'NO',
  confirmButtonLabel: 'YES',
  confirmationDescription: 'Are you sure you want to cancel this job',
} as JobAction;

export const deleteAction: JobAction = {
  type: JobActionType.delete,
  isPrimary: true,
  label: 'Delete',
  needsConfirmation: true,
  enabled: true,
  cancelButtonLabel: 'NO',
  confirmButtonLabel: 'YES',
  confirmationDescription: 'Are you sure you want to delete this job',
} as JobAction;

export const clearAction: JobAction = {
  type: JobActionType.clear,
  isPrimary: false,
  label: 'New Booking',
  enabled: true,
} as JobAction;

export const cloneAction: JobAction = {
  type: JobActionType.clone,
  isPrimary: true,
  label: 'Clone Job',
  enabled: true,
} as JobAction;

export const unAssignAction: JobAction = {
  type: JobActionType.unassign,
  isPrimary: false,
  label: 'Un-assign Driver',
  enabled: true,
} as JobAction;

export const resetAction: JobAction = {
  type: JobActionType.clear,
  isPrimary: true,
  label: 'RESET FORM',
  enabled: true,
  needsConfirmation: true,
  cancelButtonLabel: 'NO',
  confirmButtonLabel: 'YES',
  confirmationDescription: 'Are you sure you reset the booking form?',
} as JobAction;

export const bookAction: JobAction = {
  type: JobActionType.book,
  isPrimary: true,
  label: 'BOOK',
  needsConfirmation: false,
  enabled: false,
};

export const updateAction: JobAction = {
  type: JobActionType.update,
  label: 'Edit',
  needsConfirmation: false,
  enabled: false,
} as JobAction;

export const editAction: JobAction = {
  type: JobActionType.edit,
  label: 'EDIT',
  needsConfirmation: false,
  enabled: true,
} as JobAction;

export const cancelUpdateAction: JobAction = {
  type: JobActionType.cancelUpdate,
  label: 'CANCEL EDIT',
  needsConfirmation: false,
  enabled: false,
} as JobAction;

export const dispatchAction: JobAction = {
  type: JobActionType.dispatch,
  label: 'DISPATCH',
  needsConfirmation: false,
  enabled: true,
} as JobAction;

export const manualDispatchAction: JobAction = {
  type: JobActionType.manualDispatch,
  label: 'Manual Dispatch',
  enabled: true,
  needsConfirmation: false,
} as JobAction;
