import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternationalizationDatePipe } from './internationalization-date.pipe';

@NgModule({
  declarations: [InternationalizationDatePipe],
  imports: [CommonModule],
  exports: [InternationalizationDatePipe],
})
export class InternationalizationDatePipeModule {}
