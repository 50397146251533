<!-- exports toggle -->
<ng-container *ngIf="exports">
  <button
    mat-icon-button
    class="relative"
    matTooltip="Exports"
    (click)="openPanel()"
    #exportsOrigin
  >
    <ng-container *ngIf="exports.length > 0">
      <span
        class="animate-pulse absolute top-0 right-0 left-0 flex items-center justify-center h-3"
      >
        <span
          class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium"
        >
          {{ exports.length }}
        </span>
      </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:download'"></mat-icon>
    <!-- <mat-icon>cloud_download</mat-icon> -->
  </button>

  <!-- exports panel -->
  <ng-template #exportsPanel>
    <div
      class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg"
    >
      <!-- Header -->
      <div
        class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary-600 text-white"
      >
        <div class="sm:hidden -ml-1 mr-3">
          <button mat-icon-button (click)="closePanel()">
            <mat-icon
              class="icon-size-5 text-current"
              [svgIcon]="'heroicons_solid:x'"
            ></mat-icon>
          </button>
        </div>
        <div class="text-lg font-medium leading-10">Exports</div>
        <div class="ml-auto">
          <!-- <button
            mat-icon-button
            [disabled]="unreadCount === 0"
            [matTooltip]="'Mark all as read'"
            (click)="markAllAsRead()"
          >
            <mat-icon
              class="icon-size-5 text-current"
              [svgIcon]="'heroicons_solid:mail-open'"
            ></mat-icon>
          </button> -->
        </div>
      </div>

      <!-- Content -->
      <div
        class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card"
      >
        <!-- exports -->
        <ng-container *ngFor="let export of exports; trackBy: trackByFn">
          <fleet-batch-export-item
            [exportDisplay]="export"
          ></fleet-batch-export-item>
        </ng-container>

        <ng-container *ngIf="exports && exports.length > 0">
          <div
            class="flex flex-col flex-auto items-center justify-center sm:justify-start py-4 px-8"
          >
            <div
              (click)="clearExports()"
              class="w-full max-w-60 mt-1 text-sm text-center text-secondary cursor-pointer underline"
            >
              Clear Exports
            </div>
          </div>
        </ng-container>

        <!-- No exports -->
        <ng-container *ngIf="!exports || !exports.length">
          <div
            class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
          >
            <div
              class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100"
            >
              <mat-icon
                class="text-primary-500-700"
                [svgIcon]="'heroicons_outline:inbox'"
              ></mat-icon>
            </div>
            <div class="mt-5 text-2xl font-semibold tracking-tight">
              No exports
            </div>
            <div
              class="w-full max-w-60 mt-1 text-md text-center text-secondary"
            >
              When you have exports, they will appear here.
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
