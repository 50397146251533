<fleet-dialog-layout
  [showCancel]="false"
  *ngIf="travellerActionState | async as state"
  [title]="state.action.title"
  [instructions]="
    !travellerStatusActioned
      ? state.action.messageBody
      : 'Traveller ' +
        state.traveller.firstName +
        ' ' +
        state.traveller.lastName +
        ' has been suspended'
  "
  [iconColour]="state.action.iconColour ? state.action.iconColour : 'basic'"
  [icon]="state.action.icon ? state.action.icon : 'info'"
>
  <form
    *ngIf="form"
    [formGroup]="form"
    class="flex flex-col w-full"
    slot="main"
  >
    <mat-form-field
      *ngIf="state?.action?.action !== 'CLOSE' && !travellerStatusActioned"
      class="w-full"
    >
      <mat-label>Description</mat-label>
      <textarea
        matInput
        formControlName="description"
        maxlength="255"
        rows="4"
        placeholder="Enter description..."
        class="w-full"
      >
      </textarea>
    </mat-form-field>

    <ng-container
      *ngIf="
        state?.action?.action === 'SUSPEND_TRAVELLER' && travellerStatusActioned
      "
    >
      <div class="pb-4 leading-5 font-bold text-secondary">
        If you suspect fraudulent activity, select account details to be added
        to the blocklist.
      </div>
      <fleet-entity-block-attribute-list
        [mode]="'LIST'"
        [formControl]="attributeControl"
        [traveller]="state?.traveller"
      >
      </fleet-entity-block-attribute-list>
    </ng-container>
  </form>

  <ng-container slot="error">
    <fuse-alert
      *ngFor="let alert of state.issues | alertsFromIssues"
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>
  </ng-container>

  <ng-container slot="action">
    <fleet-progress-button
      *ngIf="!travellerStatusActioned; else blockActionButton"
      class="ml-3"
      [state]="{ loading: state.loading, buttonLabel: state.buttonLabel }"
      [disabled]="!form.valid || state.loading"
      type="submit"
      (onClick)="actionTraveller()"
    >
    </fleet-progress-button>
    <ng-template #blockActionButton>
      <fleet-progress-button
        class="ml-3"
        [state]="{
          loading: state.loading,
          buttonLabel:
            attributeControl?.value?.length > 0 ? 'Add to blocklist' : 'Close'
        }"
        [disabled]="state.loading"
        type="submit"
        (onClick)="blockAttributes()"
      >
      </fleet-progress-button>
    </ng-template>
  </ng-container>
</fleet-dialog-layout>
