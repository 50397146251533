import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  AuthService,
  Login,
  LoginSuccess,
  SetJWT,
  UserAuthActionTypes,
} from '@fleet/auth';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fleet-kiosk-exit',
  templateUrl: './kiosk-exit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskExitComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  error: any;
  @Output() loginSuccess = new EventEmitter();
  constructor(
    private fb: UntypedFormBuilder,
    private store$: Store,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  submit() {
    this.loading = true;
    this.error = null;
    this.changeDetectorRef.markForCheck();
    this.authService
      .signIn(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe({
        next: (resp: any) => {
          localStorage.removeItem('kiosk_mode');
          this.router.navigate(['']);
          // const authHeader = resp.headers.get('Authorization');
          // const jwt = authHeader.replace('Bearer ', '');
          // this.authService.setPermissions(resp.body.data.permissions);
          // this.store$.dispatch(new SetJWT(jwt));
          // this.store$.dispatch(new LoginSuccess(jwt));
          this.loading = false;
          this.loginSuccess.emit();
          this.changeDetectorRef.markForCheck();
        },
        error: (error: any) => {
          this.error = error;
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        },
      });
    // this.store$.dispatch(new Login(this.loginForm.value));
  }
}
