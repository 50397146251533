import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  IssueModel,
  RefundModel,
  SaleModel,
  SaleSearchResultModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SaleApiService {
  host;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.sale;
  }

  refundSale(
    saleId: string,
    refundModel: RefundModel
  ): Observable<ApiResponse<any> | any> {
    return this.http
      .post(`${this.host}/${saleId}/refund`, refundModel)
      .pipe(catchError(handleApiError));
  }

  getSale(saleId: string): Observable<ApiResponse<SaleModel> | any> {
    return this.http
      .get(`${this.host}/${saleId}`)
      .pipe(catchError(handleApiError));
  }

  searchSales(
    params: any
  ): Observable<ApiResponse<SaleSearchResultModel[]> | any> {
    return this.http
      .get(`${this.host}/`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
}
