import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { JobCallComponent } from './job-call.component';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MobileFormatModule } from '@fleet/pipes';
import { HasFunctionPipeModule } from '@fleet/security-shared';

@NgModule({
  declarations: [JobCallComponent],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,

    MobileFormatModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    HasFunctionPipeModule,
  ],
  exports: [JobCallComponent],
})
export class JobCallModule {}
