<div class="absolute inset-0 flex flex-col flex-auto bg-default">
  <div
    class="flex flex-row items-center justify-between pr-3 py-6 sm:pr-5 pl-6 sm:pl-8 bg-primary-600 text-white"
  >
    <div class="text-lg font-medium">{{ title }}</div>
    <button mat-icon-button [tabIndex]="-1" (click)="closeSidebar.emit()">
      <mat-icon
        class="text-current"
        [svgIcon]="'heroicons_outline:x'"
      ></mat-icon>
    </button>
  </div>
  <div class="overflow-auto p-6 md:p-8 bg-default" style="flex: 1">
    <ng-content></ng-content>
  </div>
  <div class="mt-auto">
    <ng-content select="[slot=actions]"></ng-content>
  </div>
</div>
