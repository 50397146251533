import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationSelectorComponent } from './location-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LocationTypePipeModule } from '../pipes/location-type-pipe/location-type-pipe.module';
import { LocationSearchToLocationPipeModule } from '../pipes/location-search-to-location-pipe/location-search-to-location-pipe.module';
import { LetterIconModule } from '@fleet/ui';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LocationSelectorComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    LocationTypePipeModule,
    LocationSearchToLocationPipeModule,
    LetterIconModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  exports: [LocationSelectorComponent],
})
export class LocationSelectorModule {}
