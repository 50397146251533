import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimLeadingSpacesDirective } from './trim-leading-spaces.directive';

@NgModule({
  declarations: [TrimLeadingSpacesDirective],

  exports: [TrimLeadingSpacesDirective],
})
export class TrimLeadingSpacesModule {}
