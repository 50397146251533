import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { JobCardModule } from '@fleet/card';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { RelatedEntitySearchLayoutModule } from '@fleet/layout';
import { RemovePropertiesPipeModule } from '@fleet/pipes';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { SearchChipsListModule } from '@fleet/shared';
import { SidebarHeaderModule } from '@fleet/ui';
import { TicketCreateEditModule } from '../../../ticket-create-edit/ticket-create-edit.module';
import { TicketLinkModule } from '../../../ticket-link/ticket-link.module';
import { TicketSearchFormModule } from '../ticket-search-form/ticket-search-form.module';
import { TicketSearchResultsModule } from '../ticket-search-results/ticket-search-results.module';
import { TicketSearchContainerComponent } from './ticket-search-container.component';

@NgModule({
  declarations: [TicketSearchContainerComponent],
  imports: [
    CommonModule,
    RelatedEntitySearchLayoutModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,

    SearchChipsListModule,
    RemovePropertiesPipeModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    JobCardModule,
    SidebarHeaderModule,
    TicketSearchResultsModule,
    TicketSearchFormModule,
    TicketCreateEditModule,
    TicketLinkModule,
    MatDialogModule,
    HasFunctionPipeModule,
  ],
  exports: [TicketSearchContainerComponent],
})
export class TicketSearchContainerModule {}
