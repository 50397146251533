import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { CreditCardPipeModule } from '@fleet/pipes';
import { JobTravellerProfileSelectorComponent } from './job-traveller-profile-selector.component';

@NgModule({
  declarations: [JobTravellerProfileSelectorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    CreditCardPipeModule,
  ],
  exports: [JobTravellerProfileSelectorComponent],
})
export class JobTravellerProfileSelectorModule {}
