import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ActionModel,
  ApiResponse,
  AssetGroupModel,
  AssetModel,
  AuditDiffModel,
  DocumentModel,
  DriverCertificateModel,
  DriverCommissionModel,
  DriverGroupMemberModel,
  DriverGroupModel,
  DriverModel,
  InvoiceModel,
  IssueLevel,
  IssueModel,
  LocationModel,
  NetworkConditionModel,
  NoteModel,
  NotificationModel,
  NotificationThreadModel,
  OrderModel,
  PaymentMethodModel,
  PlanModel,
  ReferenceDataItemModel,
  ReturnAuthorisationModel,
  ReviewModel,
  ReviewTransitionSearchResultModel,
  StateChangeModel,
  TransactionSearchResultModel,
} from '@fleet/model';
import { catchError } from 'rxjs/operators';

import { paths } from '@fleet/environment';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DriverApiService {
  host;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.driver;
  }

  searchDrivers(params: any) {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
  //http://localhost:8081/portal/driver/search/?page=0&registrationStatus=APPROVED
  // http://localhost:8180/api/v2/driver/search?limit=100&offset=0

  getDriver(driverRef: string): Observable<any> {
    return this.http
      .get(`${this.host}/${driverRef}`)
      .pipe(catchError(handleApiError));
  }

  createDriver(driver: DriverModel): Observable<DriverModel> | any {
    return this.http
      .post(`${this.host}`, driver)
      .pipe(catchError(handleApiError));
  }

  // updateDriver(driver: DriverSummaryInfo) {
  //   return this.http.put(`${this.host}`, driver);
  // }

  updateDriver(driver: any): Observable<DriverModel> | any {
    return this.http
      .put(`${this.host}/${driver.driverId}`, driver)
      .pipe(catchError(handleApiError));
  }

  searchDriverProfiles(driverRef: string) {
    return this.http.get(`${this.host}/${driverRef}/profile`);
  }

  // deleteDriver(driverRef: string) {
  //   let x;
  //   return this.http.get(`${this.host}`);
  // }

  getDriverDailyActivity(params: any, driverId: any) {
    return this.http
      .get(`${this.host}/${driverId}/activity/daily`, { params: params })
      .pipe(catchError(handleApiError));
  }

  getDriverRollingActivity(params: any, driverId: any) {
    return this.http
      .get(`${this.host}/${driverId}/activity/rolling`, {
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  createDriverAddress(locationModel: LocationModel, driverId: any) {
    return this.http
      .post(`${this.host}/${driverId}/location`, locationModel)
      .pipe(catchError(handleApiError));
  }

  updateDriverAddress(locationModel: LocationModel, driverId: any) {
    return this.http
      .put(
        `${this.host}/${driverId}/location/${locationModel.driverLocationId}`,
        locationModel
      )
      .pipe(catchError(handleApiError));
  }

  getPaymentMethod(
    driverId: string,
    paymentMethodId: string
  ): Observable<PaymentMethodModel> | Observable<any> {
    return this.http
      .get(`${this.host}/${driverId}/paymentMethod/${paymentMethodId}`)
      .pipe(catchError(handleApiError));
  }

  createPaymentMethod(driverId: string, payment: PaymentMethodModel) {
    return this.http
      .post(`${this.host}/${driverId}/paymentMethod`, payment)
      .pipe(catchError(handleApiError));
  }

  updatePaymentMethod(driverId: string, payment: PaymentMethodModel) {
    return this.http
      .put(
        `${this.host}/${driverId}/paymentMethod/${payment.paymentMethodId}`,
        payment
      )
      .pipe(catchError(handleApiError));
  }

  processDocument(driverId: string, documentId: string) {
    return this.http
      .post(`${this.host}/${driverId}/document/${documentId}`, {})
      .pipe(catchError(handleApiError));
  }

  getDriverDocuments(driverId: string, types?: any) {
    const queryParams =
      'variant=IMAGE_SMALL,IMAGE_FACE' + types ? '&type=' + types : '';
    return this.http
      .get(`${this.host}/${driverId}/document?${queryParams}`)
      .pipe(catchError(handleApiError));
  }

  getDriverDocumentswithVarients(
    driverId: string,
    includeExpired: string,
    types?: any,
    variant?: any
  ) {
    const params = {} as any;
    if (variant) {
      params['variant'] = variant;
    }
    if (types) {
      params['type'] = types;
    }
    params['includeExpired'] = includeExpired;

    return this.http
      .get(`${this.host}/${driverId}/document`, { params: params })
      .pipe(catchError(handleApiError));
  }

  searchDocuments(driverId: any, params: any): Observable<any> {
    return this.http
      .get(`${this.host}/${driverId}/document`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  // to be used by hub when completeting registration
  activate(driverId: string, stateChange: StateChangeModel): Observable<any> {
    return this.http
      .post(`${this.host}/${driverId}/activate`, stateChange)
      .pipe(catchError(handleApiError));
  }

  createReview(driverId: any) {
    return this.http
      .post(`${this.host}/driver/${driverId}/review`, { type: 'DRIVER' })
      .pipe(catchError(handleApiError));
  }

  rejectReview(driverId: any, reviewId: any) {
    return this.http
      .post(`${this.host}/${driverId}/review/${reviewId}/reject`, {})
      .pipe(catchError(handleApiError));
  }

  submitReview(
    driverId: any,
    reviewId: any
  ): Observable<ApiResponse<ReviewModel> | any> {
    return this.http
      .post(`${this.host}/${driverId}/review/${reviewId}/submit`, {})
      .pipe(catchError(handleApiError));
  }

  startReview(driverId: any, reviewId: any) {
    return this.http
      .post(`${this.host}/${driverId}/review/${reviewId}/start`, {})
      .pipe(catchError(handleApiError));
  }

  approveReview(driverId: any, reviewId: any) {
    return this.http
      .post(`${this.host}/${driverId}/review/${reviewId}/approve`, {})
      .pipe(catchError(handleApiError));
  }

  getReview(
    driverId: any,
    reviewId: any,
    params: any
  ): Observable<ApiResponse<ReviewModel> | any> {
    return this.http
      .get(`${this.host}/${driverId}/review/${reviewId}`, {
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  searchReviews(
    params: any
  ): Observable<HttpResponse<ApiResponse<ReviewModel[]>> | any> {
    return this.http
      .get(`${this.host}/review`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchReviewTransitions(
    reviewId: string,
    params: any
  ): Observable<
    HttpResponse<ApiResponse<ReviewTransitionSearchResultModel[]>> | any
  > {
    return this.http
      .get(`${this.host}/review/${reviewId}/transition`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  createAction(driverId: any, notificationId: any, action: any) {
    return this.http
      .post(
        `${this.host}/${driverId}/notification/${notificationId}/action`,
        action
      )
      .pipe(catchError(handleApiError));
  }

  getAction(driverId: any, notificationId: any, actionId: any) {
    return this.http
      .get(
        `${this.host}/${driverId}/notification/${notificationId}/action/${actionId}`
      )
      .pipe(catchError(handleApiError));
  }

  updateAction(driverId: any, notificationId: any, action: any) {
    return this.http
      .put(
        `${this.host}/${driverId}/notification/${notificationId}/action/${action.actionId}`,
        action
      )
      .pipe(catchError(handleApiError));
  }

  searchActions(driverId: any, params: any): Observable<ActionModel[] | any> {
    return this.http
      .get(`${this.host}/${driverId}/action`, {
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  searchTransactions(
    params: any,
    driverId: any,
    paymentMethodId: string
  ): Observable<ApiResponse<TransactionSearchResultModel[]> | any> {
    return this.http
      .get(
        `${this.host}/${driverId}/paymentMethod/${paymentMethodId}/transaction`,
        {
          observe: 'response',
          params: params,
        }
      )
      .pipe(catchError(handleApiError));
  }

  searchSettlements(driverId: any, params: any) {
    return this.http
      .get(`${this.host}/${driverId}/settlement`, {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getDailyActivity(params: any, driverId: string) {
    return this.http
      .get(`${this.host}/${driverId}/activity/daily`, {
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  getRollingActivity(params: any, driverId: string) {
    return this.http
      .get(`${this.host}/${driverId}/activity/rolling`, {
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  assetSearch(driverId: any) {
    return this.http
      .get(`${this.host}/${driverId}/asset`)
      .pipe(catchError(handleApiError));
  }

  // activateAsset(driverId:any, assetAllocationId) {
  //   return this.http
  //     .put(`${this.host}/${driverId}/asset/${assetAllocationId}/activate`, {})
  //     .pipe(catchError(handleApiError));
  // }

  order(order: OrderModel, driverId: any) {
    return this.http
      .post(`${this.host}/${driverId}/order`, order)
      .pipe(catchError(handleApiError));
  }

  searchOrders(
    driverId: string,
    params: any
  ): Observable<ApiResponse<OrderModel[]> | any> {
    return this.http
      .get(`${this.host}/${driverId}/order`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getOrder(driverId: string, orderId: string) {
    return this.http
      .get(`${this.host}/${driverId}/order/${{ orderId }}`)
      .pipe(catchError(handleApiError));
  }

  hubspotVisitorToken(driverId: string): Observable<ApiResponse<any> | any> {
    return this.http
      .get(`${this.host}/${driverId}/startChatbotSession`)
      .pipe(catchError(handleApiError));
  }

  // GET /api/v2/driver/{driverId}/paymentMethod/token

  generateClientToken(driverId: any): Observable<any> {
    return this.http
      .get(`${this.host}/${driverId}/paymentMethod/token`)
      .pipe(catchError(handleApiError));
  }

  createNoncePaymentMethod(driverId: any, payment: any) {
    return this.http
      .post(`${this.host}/${driverId}/paymentMethod`, payment)
      .pipe(catchError(handleApiError));
  }

  searchInvoices(driverId: any, params: any) {
    return this.http
      .get(`${this.host}/${driverId}/invoice`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  getInvoice(
    driverId: string,
    invoiceId: string
  ): Observable<ApiResponse<InvoiceModel> | any> {
    return this.http
      .get(`${this.host}/${driverId}/invoice/${invoiceId}`)
      .pipe(catchError(handleApiError));
  }

  searchContracts(driverId: any, params: any) {
    return this.http
      .get(`${this.host}/${driverId}/contract`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  searchPaymentMethods(driverId: any, params: any) {
    return this.http
      .get(`${this.host}/${driverId}/paymentMethod`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  refundBookingPayment(
    refundPayload: any,
    driverId: any,
    bookingId: any,
    bookingPaymentId: any
  ) {
    return this.http
      .post(
        `${this.host}/${driverId}/booking/${bookingId}/payment/${bookingPaymentId}/refund`,
        refundPayload
      )
      .pipe(catchError(handleApiError));
  }

  applyAdjustment(
    driverId: any,
    paymentMethodId: any,
    adjustment: any
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${driverId}/paymentMethod/${paymentMethodId}/adjust`,
        adjustment
      )
      .pipe(catchError(handleApiError));
  }

  searchDriverSettlements(driverId: any, params: any) {
    return this.http
      .get(`${this.host}/${driverId}/MIA`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  transferFunds(
    driverId: any,
    paymentMethodId: any,
    driverAdjustmentModel: any
  ) {
    return this.http
      .post(
        `${this.host}/${driverId}/paymentMethod/${paymentMethodId}/transfer`,
        driverAdjustmentModel
      )
      .pipe(catchError(handleApiError));
  }

  payInvoice(
    driverId: any,
    invoiceId: any,
    invoicePaymentModel: any
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${driverId}/chargeInvoice/${invoiceId}/pay`,
        invoicePaymentModel
      )
      .pipe(catchError(handleApiError));
  }

  activateDriver(driverId: any, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${driverId}/activate`, stateChange)
      .pipe(catchError(handleApiError));
  }

  banDriver(driverId: any, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${driverId}/ban`, stateChange)
      .pipe(catchError(handleApiError));
  }

  suspendDriver(driverId: any, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${driverId}/suspend`, stateChange)
      .pipe(catchError(handleApiError));
  }

  // closeDriver(driverId:any, stateChange: StateChangeModel) {
  //   return this.http.post(`${this.host}/${driverId}/close`, stateChange).pipe(catchError(handleApiError));
  // }

  deleteDriver(driverId: any) {
    return this.http
      .delete(`${this.host}/${driverId}`)
      .pipe(catchError(handleApiError));
  }

  searchNotifications(
    driverId: any,
    queryParams: any
  ): Observable<ApiResponse<NotificationModel[]> | any> {
    return this.http
      .get(`${this.host}/${driverId}/notification`, { params: queryParams })
      .pipe(catchError(handleApiError));
  }

  createNotification(driverId: any, payload: NotificationModel) {
    return this.http
      .post(`${this.host}/${driverId}/notification`, payload)
      .pipe(catchError(handleApiError));
  }

  getDriverDepositBalance(driverId: any) {
    return this.http
      .get(`${this.host}/${driverId}/depositBalance`)
      .pipe(catchError(handleApiError));
  }

  getTransactionSummary(driverId: any, paymentMethodId: string, params: any) {
    return this.http
      .get(
        `${this.host}/${driverId}/paymentMethod/${paymentMethodId}/transactionSummary`,
        { params: params }
      )
      .pipe(catchError(handleApiError));
  }

  deletePaymentMethod(driverId: any, paymentMethodId: any) {
    return this.http
      .delete(`${this.host}/${driverId}/paymentMethod/${paymentMethodId}`)
      .pipe(catchError(handleApiError));
  }

  updateSettlementAccount(
    driverId: any,
    paymentMethodId: any,
    account: PaymentMethodModel
  ) {
    return this.http
      .put(`${this.host}/${driverId}/paymentMethod/${paymentMethodId}`, account)
      .pipe(catchError(handleApiError));
  }

  cancelInvoice(driverId: any, invoiceId: any, invoice: InvoiceModel) {
    // return this.http
    //   .delete(`${this.host}/${driverId}/invoice/${invoiceId}`, {})
    //   .pipe(catchError(handleApiError));

    return this.http
      .request(
        'delete',
        `${this.host}/${driverId}/chargeInvoice/${invoiceId}`,
        {
          body: invoice,
        }
      )
      .pipe(catchError(handleApiError));
  }

  searchPtds(invoiceId: any, driverId: any, params: any) {
    //Add sort for paymentTransactionDetailId descending when available

    //NOTE: Use "-paymentTransactionId"
    return this.http
      .get(`${this.host}/${driverId}/invoice/${invoiceId}/payment`, {
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  confirmOrder(
    driverId: any,
    orderId: any
  ): Observable<ApiResponse<AssetGroupModel> | any> {
    return this.http
      .post(`${this.host}/${driverId}/order/${orderId}/confirm`, {})
      .pipe(catchError(handleApiError));
  }

  exchangeAsset(
    driverId: any,
    assetId: any,
    returnAuthorisation: ReturnAuthorisationModel
  ) {
    return this.http
      .post(
        `${this.host}/${driverId}/asset/${assetId}/exchange`,
        returnAuthorisation
      )
      .pipe(catchError(handleApiError));
  }

  provisionAsset(driverId: any, orderId: any, assetModel: AssetModel) {
    return this.http
      .post(`${this.host}/${driverId}/order/${orderId}/provision`, assetModel)
      .pipe(catchError(handleApiError));
  }

  activateAsset(driverId: any, assetId: any) {
    return this.http
      .put(`${this.host}/${driverId}/asset/${assetId}/activate`, {})
      .pipe(catchError(handleApiError));
  }

  searchAssets(driverId: string) {
    return this.http
      .get(`${this.host}/${driverId}/asset`)
      .pipe(catchError(handleApiError));
  }

  closeDriver(driverId: any, payload: ReturnAuthorisationModel) {
    return this.http
      .post(`${this.host}/${driverId}/close`, payload)
      .pipe(catchError(handleApiError));
  }

  reportLostAsset(
    driverId: any,
    assetId: any,
    payload: ReturnAuthorisationModel
  ) {
    return this.http
      .post(`${this.host}/${driverId}/asset/${assetId}/lost`, payload)
      .pipe(catchError(handleApiError));
  }

  changeProduct(DriverModel: DriverModel) {
    return this.http
      .post(`${this.host}/${DriverModel.driverId}/changeProduct`, DriverModel)
      .pipe(catchError(handleApiError));
  }

  addNetwork(driverId: any, network: any) {
    return this.http
      .post(`${this.host}/${driverId}/network`, network)
      .pipe(catchError(handleApiError));
  }

  getPlanTerms(
    driverId: string,
    networkId: string,

    networkFeatureSetId: string,
    variant: string
  ): Observable<any> {
    return this.http
      .get(
        `${this.host}/${driverId}/network/${networkId}/networkFeatureSet/${networkFeatureSetId}/terms?variant=${variant}`
      )
      .pipe(catchError(handleApiError));
  }

  acceptPlanTerms(
    driverId: string,
    networkId: string,
    networkFeatureSetId: string
  ): Observable<ApiResponse<DriverModel> | any> {
    return this.http
      .post(
        `${this.host}/${driverId}/network/${networkId}/networkFeatureSet/${networkFeatureSetId}/acceptPlanTerms`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  acceptTermsandConditions(
    driverId: string
  ): Observable<ApiResponse<DriverModel> | IssueModel[] | any> {
    return this.http.post(`${this.host}/${driverId}/acceptTerms`, {});
  }

  getLatestTermsandConditions(
    driverId: string,
    variant: string
  ): Observable<any> {
    return this.http
      .get(`${this.host}/${driverId}/terms?variant=${variant}`)
      .pipe(catchError(handleApiError));
  }

  selectPlan(
    driverId: string,
    plan: PlanModel
  ): Observable<ApiResponse<DriverModel> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/${driverId}/plan`, plan)
      .pipe(catchError(handleApiError));
  }

  acknowledgeNotification(driverId: string, notificationId: string) {
    return this.http.post(
      `${this.host}/${driverId}/notification/${notificationId}/acknowledge`,
      {}
    );
  }

  setPassword(driverId: string, loginModel: any) {
    return this.http
      .post(`${this.host}/${driverId}/setPassword`, loginModel)

      .pipe(catchError(handleApiError));
  }

  searchLifecycleTransitions(
    driverId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/${driverId}/lifecycleTransition`)
      .pipe(catchError(handleApiError));
  }

  searchDriverCertificates(
    driverId: string,
    params: any
  ): Observable<ApiResponse<DriverCertificateModel[]> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/${driverId}/certificate`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  updateCertificateRecord(
    driverId: string,
    driverCertificateId: string,
    certificate: DriverCertificateModel
  ): Observable<ApiResponse<DriverCertificateModel> | IssueModel[] | any> {
    return this.http
      .put(
        `${this.host}/${driverId}/certificate/${driverCertificateId}`,
        certificate
      )
      .pipe(catchError(handleApiError));
  }

  deleteDriverCertificate(driverId: string, certificateId: string) {
    return this.http
      .delete(`${this.host}/${driverId}/certificate/${certificateId}`)
      .pipe(catchError(handleApiError));
  }

  processUploadedCertificateDocument(
    driverId: string,
    driverCertificateId: string,
    documentId: string
  ): Observable<ApiResponse<DocumentModel>> | any {
    return this.http
      .post(
        `${this.host}/${driverId}/certificate/${driverCertificateId}/document/${documentId}`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  createDriverCertificateRecord(
    driverId: string,
    certificate: DriverCertificateModel
  ): Observable<ApiResponse<DriverModel> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/${driverId}/certificate`, certificate)
      .pipe(catchError(handleApiError));
  }

  searchDriverCertificateDocuments(
    driverId: string,
    driverCertificateId: string,
    params: any
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .get(
        `${this.host}/${driverId}/certificate/${driverCertificateId}/document`,
        { params: params }
      )
      .pipe(catchError(handleApiError));
  }

  addCondition(
    condition: NetworkConditionModel,
    driverId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/${driverId}/condition`, condition)
      .pipe(catchError(handleApiError));
  }

  removeCondition(
    driverConditionId: string,
    driverId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .delete(`${this.host}/${driverId}/condition/${driverConditionId}`)
      .pipe(catchError(handleApiError));
  }

  statuses(
    params: any
  ): Observable<ApiResponse<ReferenceDataItemModel[]> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/status`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  onLeave(
    driverId: string,
    stateChangeModel: StateChangeModel
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/${driverId}/onleave`, stateChangeModel)
      .pipe(catchError(handleApiError));
  }

  searchGroups(params: any) {
    return this.http
      .get(`${this.host}/group`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  searchGroupMembers(driverGroupId: string, params: any) {
    return this.http
      .get(`${this.host}/group/${driverGroupId}/member`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  createDriverGroup(
    group: DriverGroupModel
  ): Observable<ApiResponse<DriverGroupModel> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/group/`, group)
      .pipe(catchError(handleApiError));
  }

  updateDriverGroup(
    driverGroupId: string,
    group: DriverGroupModel
  ): Observable<ApiResponse<DriverGroupModel> | IssueModel[] | any> {
    return this.http
      .put(`${this.host}/group/${driverGroupId}`, group)
      .pipe(catchError(handleApiError));
  }

  updateGroupMember(
    driverGroupId: string,
    driverGroupMemberId: string,
    groupMember: DriverGroupMemberModel
  ): Observable<ApiResponse<DriverGroupMemberModel> | IssueModel[] | any> {
    return this.http
      .put(
        `${this.host}/group/${driverGroupId}/member/${driverGroupMemberId}`,
        groupMember
      )
      .pipe(catchError(handleApiError));
  }

  addGroupMember(
    driverGroupId: string,
    groupMember: DriverGroupMemberModel
  ): Observable<ApiResponse<DriverGroupMemberModel> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/group/${driverGroupId}/member`, groupMember)
      .pipe(catchError(handleApiError));
  }

  removeGroupMember(
    driverGroupId: string,
    driverGroupMemberId: string
  ): Observable<ApiResponse<null> | IssueModel[] | any> {
    return this.http
      .delete(
        `${this.host}/group/${driverGroupId}/member/${driverGroupMemberId}`
      )
      .pipe(catchError(handleApiError));
  }

  searchThreads(
    driverId: any,
    queryParams: any
  ): Observable<ApiResponse<any[]> | any> {
    return this.http
      .get(`${this.host}/${driverId}/thread`, { params: queryParams })
      .pipe(catchError(handleApiError));
  }

  getThread(
    driverId: string,
    notificationThreadId: string
  ): Observable<NotificationThreadModel | any> {
    return this.http
      .get(`${this.host}/${driverId}/thread/${notificationThreadId}`)
      .pipe(catchError(handleApiError));
  }

  searchDispatchLogs(driverId: string, params: any) {
    return this.http
      .get(`${this.host}/${driverId}/dispatchLog`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchDriverCommissions(
    driverId: string,
    params: any
  ): HttpResponse<ApiResponse<DriverCommissionModel[]>> | any {
    return this.http
      .get(`${this.host}/${driverId}/commission`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
  searchDriverCommissionEntries(
    driverId: string,
    driverCommissionId: any
  ): ApiResponse<DriverCommissionModel[]> | any {
    return this.http
      .get(`${this.host}/${driverId}/commission/${driverCommissionId}`)
      .pipe(catchError(handleApiError));
  }
  resurrectDriver(driverId: string) {
    return this.http
      .post(`${this.host}/${driverId}/resurrect`, {})
      .pipe(catchError(handleApiError));
  }

  searchSettlementTransaction(
    driverId: string,
    jobId: string
  ): Observable<any> {
    return this.http
      .get(`${this.host}/${driverId}/job/${jobId}/settlementTransaction`)
      .pipe(catchError(handleApiError));
  }

  searchDriverRevisions(
    driverId: string,
    params: any
  ): Observable<ApiResponse<AuditDiffModel[]> | any> {
    return this.http
      .get(`${this.host}/${driverId}/revisions`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
}
