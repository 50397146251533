<mat-form-field class="w-full" *ngIf="blockReasons">
  <mat-label [ngClass]="inSearch ? 'text-sm font-light' : ''"
    >Reason
  </mat-label>

  <mat-select
    [required]="required"
    [formControl]="blockReasonControl"
    class="w-full"
    placeholder="Select reason..."
  >
    <mat-option *ngFor="let type of blockReasons" [value]="type.code">
      {{ type.displayValue }}
    </mat-option>
  </mat-select>
</mat-form-field>
