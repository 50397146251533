import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';
import { fuseAnimations } from '@fleet/fuse';
import { ReferenceDataItemModel } from '@fleet/model';
import { ReferenceService } from '@fleet/reference';
import { Observable } from 'rxjs';

@Component({
  selector: 'fleet-block-action-selector',
  templateUrl: './block-action-selector.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BlockActionSelectorComponent),
      multi: true,
    },
  ],
})
export class BlockActionSelectorComponent
  implements OnInit, ControlValueAccessor
{
  @Input() set disabled(value: boolean) {
    this.setDisabledState(value);
  }
  blockActionControl = new UntypedFormControl();
  blockActions$: Observable<ReferenceDataItemModel[]>;

  @Input() inSearch = false;
  @Input() required = false;
  constructor(
    private referenceService: ReferenceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.blockActions$ = this.referenceService.blockActions$;

    this.blockActionControl.valueChanges.subscribe((val: string) => {
      this.onTouched();
      this.onChange(val);
    });
  }

  onChange: any = (condition: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(val: string): void {
    if (val) {
      this.blockActionControl.setValue(val);
    }
    this.changeDetectorRef.markForCheck();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.blockActionControl.disable({ emitEvent: false })
      : this.blockActionControl.enable({ emitEvent: false });
  }
}
