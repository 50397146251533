import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NetworkConditionsService } from '@fleet/network-shared';
import { ReferenceService } from '@fleet/reference';
@Injectable({
  providedIn: 'root',
})
export class InitialDataResolverTravellerService  {
  constructor(
    private networkConditionService: NetworkConditionsService,
    private referenceService: ReferenceService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //this.networkConditionService.getAllNetworkTypes();
    this.referenceService.searchReferences('localities');
  }
}
