import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchChipsListComponent } from './search-chips-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SearchTermsPipeModule } from '../search-terms-pipe/search-terms.module';

@NgModule({
  declarations: [SearchChipsListComponent],
  imports: [CommonModule, MatChipsModule, MatIconModule, SearchTermsPipeModule],
  exports: [SearchChipsListComponent],
})
export class SearchChipsListModule {}
