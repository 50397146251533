import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSearchListComponent } from './job-search-list.component';
import { JobSearchResultItemModule } from '../job-search-result-item';
import { NoResultsLabelModule } from '@fleet/shared';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';

@NgModule({
  declarations: [JobSearchListComponent],
  imports: [
    CommonModule,
    JobSearchResultItemModule,
    NoResultsLabelModule,
    TitleCaseAndCleanPipeModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
  ],
  exports: [JobSearchListComponent],
})
export class JobSearchListModule {}
