<div class="flex flex-col gap-3 w-full" *ngIf="lifecycles">
  <mat-form-field class="w-full">
    <mat-label class="text-sm font-light">Lifecycle</mat-label>
    <mat-select [formControl]="lifecycleStageControl" placeholder="Lifecycle">
      <mat-option [value]="''"> None</mat-option>
      <mat-option *ngFor="let lifecycle of lifecycles" [value]="lifecycle">
        {{ lifecycle.displayValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="mode === 'select'; else list">
    <mat-form-field class="w-full" *ngIf="statuses">
      <mat-label class="text-sm font-light">Status</mat-label>
      <mat-select
        [required]="statuses.length === 0"
        [disabled]="statuses.length === 0"
        multiple
        [formControl]="statusControl"
        placeholder="Status"
      >
        <mat-option *ngFor="let status of statuses" [value]="status">
          {{ status.description | titleCaseAndClean }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-template #list>
    <mat-selection-list
      *ngIf="statuses && statuses.length !== 0; else noStatuses"
      [formControl]="statusControl"
      #status
      class="grid grid-cols-2"
    >
      <mat-list-option
        class="text-md font-medium"
        checkboxPosition="before"
        *ngFor="let status of statuses"
        [value]="status"
      >
        {{ status?.description | titleCaseAndClean }}
      </mat-list-option>
    </mat-selection-list>

    <ng-template #noStatuses>
      <fleet-no-results-label
        *ngIf="
          lifecycleStageControl.value !== '' &&
          lifecycleStageControl.value !== null
        "
        [type]="'statuses'"
      ></fleet-no-results-label>
    </ng-template>
  </ng-template>
</div>
