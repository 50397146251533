<fuse-alert
  *ngFor="let alert of issues | alertsFromIssues"
  class="mb-3"
  [appearance]="'outline'"
  [showIcon]="true"
  [type]="alert.type"
  [@shake]="alert.type === 'error'"
>
  {{ alert.message }}
</fuse-alert>

<div
  class="flow-root"
  *ngIf="search | lifeycleTransitionFilter as lifecycleSearch"
>
  <ul role="list" class="-mb-8" *ngIf="mode === 'CARDS'; else lifecycleCards">
    <li *ngFor="let transition of lifecycleSearch; last as isLast">
      <div
        *ngIf="transition.status | statusChip : true as status"
        class="relative pb-8"
      >
        <span
          *ngIf="!isLast"
          class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
          aria-hidden="true"
        ></span>
        <div class="relative flex space-x-3">
          <div class="self-start">
            <span
              class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
              [ngClass]="status.background"
            >
              <mat-icon class="text-white">
                {{ status.icon }}
              </mat-icon>
            </span>
          </div>
          <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div>
              <div class="flex flex-row gap-1 text-sm text-gray-500">
                <div>Transitioned to</div>

                <div class="font-medium text-gray-900">
                  {{ transition.status | titleCaseAndClean }}
                </div>
              </div>
            </div>
            <div class="text-right text-sm whitespace-nowrap text-gray-500">
              {{
                transition.created | internationalizationDate : 'DATETIME_SHORT'
              }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <ng-template #lifecycleCards>
    <div
      *ngFor="let transition of lifecycleSearch; last as isLast"
      class="w-full"
    >
      <div
        *ngIf="transition.status | statusChip : true as status"
        class="relative bg-card py-3 px-4 shadow rounded-lg overflow-hidden mt-4"
      >
        <div class="flex flex-row justify-start items-center">
          <div>
            <span
              class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
              [ngClass]="status.backgroundAndText"
            >
              <mat-icon class="text-white">
                {{ status.icon }}
              </mat-icon>
            </span>
          </div>

          <div class="flex flex-col ml-3 justify-between w-full">
            <div class="flex flex-row justify-between w-full items-center">
              <div
                class="flex flex-row gap-1 text-sm text-gray-500 items-center"
              >
                <div class="font-medium text-gray-900">
                  {{
                    transition.status === 'PENDING_UNINSTALLATION'
                      ? 'Leaving the network'
                      : (transition.status | titleCaseAndClean)
                  }}
                </div>
                <div
                  class="flex flex-row gap-1"
                  *ngIf="
                    !transition?.effectiveTo &&
                    transition?.effectiveFrom &&
                    !transition?.createdBy
                  "
                >
                  <div class="text-sm text-gray-900">as of</div>
                  <div
                    class="text-right text-sm whitespace-nowrap text-gray-500"
                  >
                    {{
                      transition.effectiveFrom
                        | internationalizationDate : 'DATETIME_SHORT'
                    }}
                  </div>
                </div>
              </div>

              <ng-container *ngIf="vehicleId">
                <ng-container
                  *ngIf="transition | lifecycleTransitionAction as actions"
                >
                  <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
                    <button
                      mat-menu-item
                      *ngFor="let action of actions"
                      (click)="actionLifecycleTransition(action, transition)"
                    >
                      {{ action.label }}
                    </button>
                  </mat-menu>

                  <button
                    class="icon-size-5 -mr-2"
                    mat-icon-button
                    [matMenuTriggerFor]="appMenu"
                    [disabled]="loading"
                  >
                    <mat-icon class="icon-size-5">more_vert</mat-icon>
                  </button>
                </ng-container>
              </ng-container>
            </div>

            <div
              class="flex flex-row w-full pt-2"
              *ngIf="transition?.createdBy"
            >
              <div class="flex flex-row justify-between w-full gap-3">
                <div class="flex flex-row wrap">
                  <div class="text-sm font-medium text-gray-900 w-20">
                    Actioned by
                  </div>
                  <div
                    style="overflow-wrap: anywhere"
                    class="text-sm text-gray-500"
                  >
                    {{ transition.createdBy }}
                  </div>
                </div>

                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                  {{
                    transition.created
                      | internationalizationDate : 'DATETIME_SHORT'
                  }}
                </div>
              </div>
            </div>

            <div
              class="flex flex-row w-full pt-2"
              *ngIf="
                vehicleId &&
                transition.status === 'PENDING_UNINSTALLATION' &&
                transition?.effectiveFrom
              "
            >
              <div class="flex flex-row gap-1">
                <div class="text-sm font-medium text-gray-900">
                  Effective from
                </div>

                <div class="text-sm text-gray-500">
                  {{
                    transition.effectiveFrom
                      | internationalizationDate : 'DATETIME_SHORT'
                  }}
                </div>
              </div>
            </div>

            <div
              class="flex flex-row w-full pt-2"
              *ngIf="transition?.note?.content"
            >
              <div class="flex flex-row text-sm text-gray-500">
                {{ transition.note.content }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-row justify-between items-end gap-2 mt-3"
          *ngIf="transition.effectiveFrom && transition.effectiveTo"
        >
          <div class="flex flex-col gap-1" *ngIf="transition.effectiveFrom">
            <div class="font-medium text-sm text-gray-900">Effective from</div>
            <div class="text-right text-sm whitespace-nowrap text-gray-500">
              {{
                transition.effectiveFrom
                  | internationalizationDate : 'DATETIME_SHORT'
              }}
            </div>
          </div>
          <div class="flex flex-col gap-1" *ngIf="transition.effectiveTo">
            <div class="font-medium text-sm text-gray-900">Effective to</div>
            <div class="text-right text-sm whitespace-nowrap text-gray-500">
              {{
                transition.effectiveTo
                  | internationalizationDate : 'DATETIME_SHORT'
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
