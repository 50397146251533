import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  PositionListenerModel,
  PositionUpdateModel,
  SocketConnectivityModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PositionApiService {
  host: string;
  locationTestHost: string;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.position;
    this.locationTestHost = env.host + 'api/test/location';
  }

  deregisterListener(
    listenerId: string
  ): Observable<ApiResponse<PositionListenerModel> | any> {
    return this.http
      .delete(`${this.host}/listener/${listenerId}`)
      .pipe(catchError(handleApiError));
  }

  getListener(
    listenerId: string
  ): Observable<ApiResponse<PositionListenerModel> | any> {
    return this.http
      .get(`${this.host}/listener/${listenerId}`)
      .pipe(catchError(handleApiError));
  }

  listenerFrom(positionListener: PositionListenerModel, listenerId: string) {
    //no mapping
  }

  listenerHeartBeat(
    listenerId: string
  ): Observable<ApiResponse<PositionListenerModel> | any> {
    return this.http
      .post(`${this.host}/listener/${listenerId}/heartbeat`, {})
      .pipe(catchError(handleApiError));
  }

  listenerSocket(
    listenerId: string,
    socketConnectivity: SocketConnectivityModel
  ): Observable<ApiResponse<PositionListenerModel> | any> {
    return this.http
      .post(`${this.host}/listener/${listenerId}/socket`, socketConnectivity)
      .pipe(catchError(handleApiError));
  }

  position(positionUpdate: PositionUpdateModel) {
    //no mapping
  }

  registerListener(
    positionListener: PositionListenerModel
  ): Observable<ApiResponse<PositionListenerModel> | any> {
    return this.http
      .post(`${this.host}/listener`, positionListener)
      .pipe(catchError(handleApiError));
  }

  updateListener(
    positionListener: PositionListenerModel
  ): Observable<ApiResponse<PositionListenerModel> | any> {
    return this.http
      .put(
        `${this.host}/listener/${positionListener.listenerId}`,
        positionListener
      )
      .pipe(catchError(handleApiError));
  }

  search(params: any): Observable<any> {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getIsochrone(params: any): Observable<any> {
    return this.http
      .get(`${this.locationTestHost}/isochrone`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
}
