import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketCreateEditComponent } from './ticket-create-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { ProgressButtonModule } from '@fleet/shared';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DialogLayoutModule } from '@fleet/ui';

@NgModule({
  declarations: [TicketCreateEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    ProgressButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    TitleCaseAndCleanPipeModule,
    MatButtonModule,
    MatDialogModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    DialogLayoutModule,
  ],
  exports: [TicketCreateEditComponent],
})
export class TicketCreateEditModule {}
