<fleet-dialog-layout
  [title]="'Email Receipt'"
  [instructions]="'Please select the email you wish to send a receipt to'"
  [iconColour]="'primary'"
  [icon]="'info'"
  [showCancel]="true"
>
  <div slot="main">
    <form [formGroup]="form" class="flex flex-col gap-3 w-full">
      <div class="flex flex-col gap-2" *ngIf="traveller">
        <mat-label>Send receipt to:</mat-label>

        <mat-radio-group
          required
          class="flex flex-col gap-3"
          formControlName="receipt"
        >
          <mat-radio-button
            [disabled]="
              traveller &&
              !traveller?.receiptEmail &&
              traveller?.status === 'UNREGISTERED'
            "
            [value]="'TRAVELLER'"
            >Traveller's email</mat-radio-button
          >
          <mat-radio-button [value]="'EMAIL'">Other email</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field
        *ngIf="form?.get('receipt')?.value === 'EMAIL' || !traveller"
        class="w-full"
      >
        <mat-label>Email</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Email"
          formControlName="email"
        />
        <mat-error>A valid email address is required</mat-error>
      </mat-form-field>
    </form>
  </div>
  <fuse-alert
    slot="error"
    *ngFor="let alert of issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <ng-container slot="action">
    <fleet-progress-button
      [state]="{ buttonLabel: 'Send', loading: loading }"
      [disabled]="
        loading || (!form.valid && form?.get('receipt')?.value === 'EMAIL')
      "
      [loading]="loading"
      (onClick)="send()"
    >
    </fleet-progress-button>
  </ng-container>
</fleet-dialog-layout>
