import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityDetailLayoutComponent } from './entity-detail-layout.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FuseNavigationModule } from '@fleet/fuse';
import { MatButtonModule } from '@angular/material/button';
import { EntityHeaderWithBreadcrumbsModule } from '../entity-header-with-breadcrumbs/entity-header-with-breadcrumbs.module';

@NgModule({
  declarations: [EntityDetailLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatIconModule,
    FuseNavigationModule,
    MatButtonModule,
    EntityHeaderWithBreadcrumbsModule,
  ],
  exports: [EntityDetailLayoutComponent],
})
export class EntityDetailLayoutModule {}
