import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ServiceSearchListComponent } from './service-search-list.component';

import { MatListModule } from '@angular/material/list';

import { A11yModule } from '@angular/cdk/a11y';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { SelectionListInDrawerModule } from '@fleet/layout';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
@NgModule({
  declarations: [ServiceSearchListComponent],
  imports: [
    CommonModule,
    TitleCaseAndCleanPipeModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,

    MatListModule,

    CdkScrollableModule,
    A11yModule,
    SelectionListInDrawerModule,
  ],
  exports: [ServiceSearchListComponent],
})
export class ServiceSearchListModule {}
