import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveJobCardComponent } from './active-job-card.component';
import { MatIconModule } from '@angular/material/icon';
import { NetworkConditionsDisplayListModule } from '@fleet/network-shared';

import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DoubleClickDirectiveModule } from '@fleet/shared';

@NgModule({
  declarations: [ActiveJobCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NetworkConditionsDisplayListModule,

    MatButtonModule,
    MatTooltipModule,
    DoubleClickDirectiveModule,
  ],
  exports: [ActiveJobCardComponent],
})
export class ActiveJobCardModule {}
