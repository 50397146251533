<div
  class="relative flex flex-col h-full"
  [ngClass]="[width || 'md:w-128', margin || '-m-6']"
>
  <div class="absolute top-0 right-0 pt-4 pr-4" *ngIf="showClose">
    <button mat-icon-button [matDialogClose]="'cancelled'">
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x'"
      ></mat-icon>
    </button>
  </div>
  <div
    class="flex flex-col sm:flex-row items-center sm:items-start p-8 pb-3 sm:pb-5"
  >
    <div
      *ngIf="icon"
      class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full"
      [ngClass]="{
        'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600':
          iconColour === 'primary',
        'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600':
          iconColour === 'accent',
        'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600':
          iconColour === 'warn',
        'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600':
          iconColour === 'basic',
        'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600':
          iconColour === 'info',
        'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500':
          iconColour === 'success',
        'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500':
          iconColour === 'warning',
        'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600':
          iconColour === 'error'
      }"
    >
      <mat-icon class="text-current">{{ icon }}</mat-icon>
    </div>

    <div
      class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left"
    >
      <!-- Title -->

      <div class="text-xl leading-6 font-medium" [innerHTML]="title"></div>

      <div
        *ngIf="instructions"
        class="text-secondary"
        [innerHTML]="instructions"
      ></div>
    </div>
  </div>

  <!-- Actions -->

  <div class="flex flex-col px-8 py-3">
    <mat-dialog-content>
      <ng-content select="[slot=main]"></ng-content>
    </mat-dialog-content>
  </div>

  <div class="px-8 pb-6">
    <ng-content select="[slot=error]"></ng-content>
  </div>

  <div class="flex-grow"></div>

  <div
    class="flex items-center justify-end px-6 p-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
  >
    <!-- Cancel -->
    <button
      *ngIf="showCancel"
      mat-stroked-button
      [matDialogClose]="'cancelled'"
      (click)="cancelled.emit()"
    >
      {{ cancelLabel }}
    </button>

    <!-- Confirm -->
    <ng-content select="[slot=action]"></ng-content>
  </div>
</div>
