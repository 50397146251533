import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  AuthInterceptor,
  AuthService,
  TravellerAuthService,
  UserAuthEffects,
  userAuthReducer,
} from '@fleet/auth';
import {
  CoreModule,
  FuseConfigModule,
  FuseModule,
  appConfig,
} from '@fleet/fuse';
import { LayoutModule } from '@fleet/layout';
import {
  ProductConfigurationModule,
  ProductConfigurationService,
  productConfig,
} from '@fleet/product-configuration';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import localeAu from '@angular/common/locales/en-AU';

import { environment } from '../environments/environment';

import { registerLocaleData } from '@angular/common';
import {
  LuxonDateAdapter,
  MatLuxonDateModule,
} from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FuseLoadingInterceptor } from '@fleet/fuse/lib/services/loading/loading.interceptor';
import { JobListenerService } from '@fleet/job';

import { travellerAppRoutes } from './traveller-app.routing';
import { UnregisteredTravellerLayoutModule } from './unregistered-traveller-layout/unregistered-traveller-layout.module';
import { DATEPICKER_FORMAT, LocaleService } from '@fleet/locale';
import { CURRENCY_MASK_CONFIG } from '@fleet/shared';

registerLocaleData(localeAu, 'en-Au');

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        UnregisteredTravellerLayoutModule,
        StoreModule.forRoot({ userAuth: userAuthReducer }),
        EffectsModule.forRoot([UserAuthEffects]),
        LayoutModule,
        ProductConfigurationModule.forRoot(productConfig),
        FuseModule,
        CoreModule,
        FuseConfigModule.forRoot(appConfig),
        RouterModule.forRoot(travellerAppRoutes, {
            initialNavigation: 'enabledBlocking',
        }),
        MatLuxonDateModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        })], providers: [
        {
            provide: 'env',
            useValue: environment,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FuseLoadingInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (productConfigurationService: ProductConfigurationService) => () => productConfigurationService.getProductConfiguration(),
            multi: true,
            deps: [ProductConfigurationService],
        },
        {
            provide: LOCALE_ID,
            deps: [LocaleService],
            useFactory: (localeService: LocaleService) => localeService.getLocale(),
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useFactory: (localeService: LocaleService) => localeService.getCurrency(),
            deps: [LocaleService],
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useFactory: (localeService: LocaleService) => localeService.getCurrencyMaskConfig(),
            deps: [LocaleService], // Declare dependency on CurrencyConfigService
        },
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: DATEPICKER_FORMAT,
        },
        { provide: AuthService, useClass: TravellerAuthService },
        JobListenerService,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ] })
export class AppModule {}
