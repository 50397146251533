/* eslint-disable prefer-const */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DateTime } from 'luxon';

import { titleCaseClean } from '@fleet/utilities';

@Component({
  selector: 'fleet-search-chips-list',
  templateUrl: './search-chips-list.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchChipsListComponent implements OnInit {
  _searchParams: any;
  @Input() set searchParams(value: any) {
    this._searchParams = value;
  }

  get searchParams() {
    return this._searchParams;
  }

  @Input() paramLabelMap: any;

  @Output() removeSearchTerm = new EventEmitter();

  @Input() disabled = false;
  constructor(private changeDetectionRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  remove(term: {
    name: string;
    value: string;
    key: string;
    disabled?: boolean;
  }) {
    this.removeSearchTerm.emit(term.key);
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 10);
  }

  formatDate(value: string) {
    let date = DateTime.fromISO(value);
    if (date.isValid) {
      return date.toLocaleString(DateTime.DATETIME_MED);
    }
    return null;
  }

  displayValue(value: string) {
    if (DateTime.isDateTime(value)) {
      return value;
    } else {
      //Now just replace _ with space
      if (value && value !== undefined) {
        return value.replace('_', ' ');
      } else return value;
    }
  }
}
