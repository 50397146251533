import { Pipe, PipeTransform } from '@angular/core';
import { LifecycleTransitionModel } from '@fleet/model';

@Pipe({
  name: 'lifeycleTransitionFilter',
})
export class LifeycleTransitionFilterPipe implements PipeTransform {
  transform(
    lifecycleTransitions: LifecycleTransitionModel[]
  ): LifecycleTransitionModel[] {
    if (lifecycleTransitions) {
      const results = lifecycleTransitions.filter(
        (filter) => filter.status !== 'DELETED'
      );
      return results;
    }

    return lifecycleTransitions;
  }
}
