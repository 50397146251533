import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JobDetailService } from '../job-detail/job-detail.service';

@Injectable({
  providedIn: 'root',
})
export class JobDeactivateGuard  {
  constructor(private jobDetailService: JobDetailService) {}
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.jobDetailService.setJob(null);
    return true;
  }
}
