import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({
  name: 'mobileFormat',
})
export class MobileFormatPipe implements PipeTransform {
  transform(phone: string, args?: any): any {
    if (!phone) {
      return;
    }

    const phoneNumber = parsePhoneNumberFromString(phone);

    if (!args) {
      try {
        return phoneNumber.format('INTERNATIONAL');
      } catch (error) {
        return phone;
      }
    }
    if (args === 'NATIONAL') {
      return phoneNumber.format('NATIONAL');
    }

    if (args === 'INTERNATIONAL') {
      return phoneNumber.format('INTERNATIONAL');
    }

    if (args === 'RFC3966') {
      return phoneNumber.format('RFC3966');
    }
  }
}
