import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { fuseAnimations } from '@fleet/fuse';

@Component({
  selector: 'fleet-entity-layout-with-right-drawer',
  templateUrl: './entity-layout-with-right-drawer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class EntityLayoutWithRightDrawerComponent implements OnInit {
  @Output() backdropClosed = new EventEmitter();
  @Input() hideHeader = false;
  @Input() showTitleAndActions = true;
  @Input() title: string;
  @Input() widthFull = false;
  @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {}

  backdropClicked() {
    // this.toggleDrawer();
    this.matDrawer.close();
    this.backdropClosed.emit();
    this.changeDetector.markForCheck();
  }

  toggleDrawer() {
    this.matDrawer.toggle();
    this.changeDetector.markForCheck();
    //emit to close draw
  }
}
