<ng-container *ngIf="size$ | async as size">
  <ng-container [ngSwitch]="size.alias">

    <ng-container *ngSwitchCase="'xs'">
      <ng-content select="[slot=xs]"></ng-content>
    </ng-container>

    <ng-container *ngSwitchCase="'sm'">
      <ng-content select="[slot=sm]"></ng-content>
    </ng-container>

    <ng-container *ngSwitchCase="'md'">
      <ng-content select="[slot=md]"></ng-content>
    </ng-container>

    <ng-container *ngSwitchCase="'lg'">
      <ng-content select="[slot=lg]"></ng-content>
    </ng-container>

    <ng-container *ngSwitchCase="'xl'">
      <ng-content select="[slot=xl]"></ng-content>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-content select="[slot=default]"></ng-content>
    </ng-container>
  </ng-container>
</ng-container>
