import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { FuseLoadingBarModule } from '@fleet/fuse';
import { SettingsTabLabelModule } from '@fleet/ui';
import { NativeAppWebviewLayoutComponent } from './native-app-webview-layout.component';

@NgModule({
  declarations: [NativeAppWebviewLayoutComponent],
  imports: [
    RouterModule,
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    FuseLoadingBarModule,
    SettingsTabLabelModule,
  ],
  exports: [NativeAppWebviewLayoutComponent],
})
export class NativeAppWebviewLayoutModule {}
