<div
  class="bg-white shadow overflow-hidden sm:rounded-lg pt-4"
  [ngClass]="backgroundClassOnCard"
>
  <div
    *ngIf="title || subtitle"
    class="flex flex-row w-full justify-between items-center px-4 pb-4"
  >
    <div>
      <div *ngIf="title" class="flex flex-row gap-3">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ title }}
        </h3>
        <ng-content select="[slot=status-pill]"></ng-content>
      </div>

      <p *ngIf="subtitle" class="mt-1 max-w-2xl text-sm text-gray-500">
        {{ subtitle }}
      </p>
    </div>

    <div>
      <ng-content *ngTemplateOutlet="actionSlot"> </ng-content>
    </div>
  </div>

  <div>
    <div class="flex justify-end px-4">
      <ng-content *ngTemplateOutlet="actionSlot"> </ng-content>
    </div>
    <dl
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 px-4"
      [ngClass]="title || subtitle ? 'py-3' : 'pb-3'"
    >
      <ng-content select="[slot=main]"></ng-content>
    </dl>

    <ng-container *ngIf="subsection">
      <div class="flex flex-row w-full justify-between items-center">
        <div class="px-4 py-5">
          <h3 class="text-sm font-medium text-gray-500">
            {{ subsection.title }}
          </h3>
          <p
            *ngIf="subsection.subtitle"
            class="mt-1 max-w-2xl text-sm text-gray-500"
          >
            {{ subsection.subtitle }}
          </p>
        </div>
      </div>
      <div class="px-4">
        <ng-content select="[slot=subsectionaction]"></ng-content>
      </div>
      <ng-content select="[slot=subsectionmain]"></ng-content>
    </ng-container>
  </div>

  <ng-content select="[slot=results]"> </ng-content>
</div>

<ng-template #actionSlot>
  <div class="flex justify-end">
    <ng-content select="[slot=action]"></ng-content>
  </div>
</ng-template>
