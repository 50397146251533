import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationGroupAutocompleteComponent } from './organisation-group-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
@NgModule({
  declarations: [OrganisationGroupAutocompleteComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatIconModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
  ],
  exports: [OrganisationGroupAutocompleteComponent],
  providers: [],
})
export class OrganisationGroupAutocompleteModule {}
