<ng-container
  *ngIf="paymentMethodCreateEditState | async as paymentMethodState"
>
  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'SIDEBAR'">
      <fleet-sidebar-form-layout
        class="flex-auto gap-3"
        [title]="paymentMethodState.title"
        (closeSidebar)="onCancel.emit()"
      >
        <div class="mt-3"></div>
        <div
          class="px-6 flex flex-col"
          *ngIf="paymentTypeControl && !paymentMethodType"
        >
          <mat-form-field>
            <mat-label>Payment method type</mat-label>

            <mat-select
              [disabled]="paymentMethodState.paymentMethod !== null"
              [formControl]="paymentTypeControl"
            >
              <mat-option
                *ngFor="let type of PAYMENT_TYPES"
                [value]="type.value"
              >
                {{ type.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <form
          class="flex flex-col flex-auto"
          [formGroup]="paymentMethodForm"
          (ngSubmit)="submit()"
          *ngIf="paymentMethodForm && paymentTypeControl.value"
        >
          <div class="px-6 flex flex-col">
            <mat-form-field *ngIf="paymentMethodForm.get('displayName')">
              <mat-label>Display name</mat-label>
              <input
                required
                matInput
                placeholder="Display name"
                formControlName="displayName"
              />
              <mat-error
                *ngIf="
                  paymentMethodForm.get('displayName').hasError('required')
                "
              >
                Payment method name is required</mat-error
              >
              <mat-error
                *ngIf="paymentMethodForm.get('displayName').hasError('pattern')"
              >
                Please enter a valid value using only letters, spaces, and
                apostrophes</mat-error
              >
            </mat-form-field>

            <fleet-account-model-form
              *ngIf="paymentMethodForm.get('account')"
              [padding]="false"
              formControlName="account"
              [paymentType]="paymentTypeControl?.value"
            >
            </fleet-account-model-form>

            <fleet-contract-selector
              *ngIf="!payment && paymentMethodForm.get('contractId')"
              formControlName="contractId"
              [required]="true"
              [organisationId]="
                organisation ? organisation.organisationId : null
              "
              [mode]="'id'"
            >
            </fleet-contract-selector>
          </div>

          <fleet-braintree-hosted-fields
            *ngIf="paymentTypeControl?.value === 'BRAINTREE' && !payment"
            class="px-6"
            [driver]="driver"
            [traveller]="traveller"
            [operator]="operator"
            (braintreeError)="brainTreeError($event)"
            (nonceGenerated)="createPaymentMethodFromNonce($event)"
          ></fleet-braintree-hosted-fields>
          <div class="flex-grow"></div>

          <ng-container
            *ngTemplateOutlet="
              error;
              context: {
                $implicit: {
                  issues: paymentMethodState?.issues
                }
              }
            "
          >
          </ng-container>

          <ng-container *ngIf="braintreeStatus?.issues">
            <ng-container
              *ngTemplateOutlet="
                error;
                context: {
                  $implicit: {
                    issues: braintreeStatus.issues
                  }
                }
              "
            >
            </ng-container>
          </ng-container>

          <fleet-action-buttons>
            <button mat-button type="button" (click)="cancel()">Cancel</button>

            <ng-container
              *ngIf="
                paymentTypeControl?.value !== 'BRAINTREE' ||
                  (organisation && payment);
                else braintreeSubmit
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  submitButton;
                  context: {
                    $implicit: {
                      state: paymentMethodState,
                      disabled:
                        !paymentMethodForm.valid ||
                        paymentMethodState.loading ||
                        !paymentTypeControl.value,
                      paymentType: paymentTypeControl?.value
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>
          </fleet-action-buttons>
        </form>
      </fleet-sidebar-form-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'DIALOG'">
      <fleet-dialog-layout
        [title]="'Create Payment Method'"
        [iconColour]="'primary'"
        [icon]="'info'"
      >
        <!-- <fleet-braintree-payment-method
          slot="main"
          #braintreePayment
          class="px-6"
          *ngIf="paymentTypeControl?.value === 'BRAINTREE' && !payment"
          (nonceGenerated)="createPaymentMethodFromNonce($event)"
          [issuesFromParent]="paymentMethodState.issues"
        >
        </fleet-braintree-payment-method> -->

        <fleet-braintree-hosted-fields
          slot="main"
          *ngIf="paymentTypeControl?.value === 'BRAINTREE' && !payment"
          class="px-6"
          [driver]="driver"
          [traveller]="traveller"
          [operator]="operator"
          (braintreeError)="brainTreeError($event)"
          (nonceGenerated)="createPaymentMethodFromNonce($event)"
        ></fleet-braintree-hosted-fields>

        <ng-container slot="action">
          <ng-container *ngIf="braintreeButtonState">
            <ng-container
              *ngTemplateOutlet="
                submitButton;
                context: {
                  $implicit: {
                    state: braintreeButtonState,
                    disabled:
                      !braintreeStatus?.isValid ||
                      !paymentTypeControl.value ||
                      !paymentMethodForm.valid,
                    paymentType: paymentTypeControl?.value
                  }
                }
              "
            >
            </ng-container>
          </ng-container>
        </ng-container>
      </fleet-dialog-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'BRAINTREE'">
      <form
        class="flex flex-col flex-auto mt-8 -mb-6"
        *ngIf="paymentMethodForm"
        [formGroup]="paymentMethodForm"
      >
        <mat-form-field *ngIf="paymentMethodForm.get('displayName')">
          <mat-label>Display name</mat-label>
          <input
            required
            matInput
            placeholder="Display name"
            formControlName="displayName"
          />
          <mat-error
            *ngIf="paymentMethodForm.get('displayName').hasError('required')"
          >
            Payment method name is required</mat-error
          >
          <mat-error
            *ngIf="paymentMethodForm.get('displayName').hasError('pattern')"
          >
            Please enter a valid value using only letters, spaces, and
            apostrophes</mat-error
          >
        </mat-form-field>
      </form>
      <!-- <fleet-braintree-payment-method
        #braintreePayment
        class="px-6"
        (nonceGenerated)="createPaymentMethodFromNonce($event)"
        [issuesFromParent]="paymentMethodState.issues"
      >
      </fleet-braintree-payment-method> -->
      <fleet-braintree-hosted-fields
        slot="main"
        *ngIf="paymentTypeControl?.value === 'BRAINTREE' && !payment"
        class="px-6"
        [driver]="driver"
        [traveller]="traveller"
        [operator]="operator"
        (braintreeError)="brainTreeError($event)"
        (nonceGenerated)="createPaymentMethodFromNonce($event)"
      ></fleet-braintree-hosted-fields>
    </ng-container>
  </ng-container>

  <ng-template #braintreeSubmit>
    <ng-container *ngIf="braintreeButtonState">
      <ng-container
        *ngTemplateOutlet="
          submitButton;
          context: {
            $implicit: {
              state: braintreeButtonState,
              disabled:
                !braintreeStatus?.isValid ||
                !paymentTypeControl.value ||
                !paymentMethodForm.valid,
              paymentType: paymentTypeControl?.value
            }
          }
        "
      >
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #submitButton let-data>
    <fleet-progress-button
      class="ml-3"
      [state]="data.state"
      [disabled]="data.disabled"
      type="button"
      (onClick)="
        data.paymentType === 'BRAINTREE'
          ? submitBraintreePaymentMethod()
          : submit()
      "
    >
    </fleet-progress-button>
  </ng-template>

  <ng-template #error let-data>
    <fuse-alert
      *ngFor="let alert of data?.issues | alertsFromIssues"
      class="px-6 py-3"
      [appearance]="'outline'"
      [showIcon]="false"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>
  </ng-template>
</ng-container>
