import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';

import { OrganisationApiService } from '@fleet/api';

import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  IssueModel,
  OrganisationUserModel,
  OrganisationUserSearchResultModel,
} from '@fleet/model';

import { EMPTY, Observable } from 'rxjs';

import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'fleet-organisation-user-autocomplete',
  templateUrl: './organisation-user-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganisationUserAutocompleteComponent),
      multi: true,
    },
  ],
})
export class OrganisationUserAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  // userAutocompleteErrorState = new OrganisationUserAutocompleteErrorState();

  _organisationId: string;
  @Input() set organisationId(value: string) {
    this._organisationId = value;
    if (value) {
      this.users$ = EMPTY;
      this.wireAutoComplete();
    }
  }
  get organisationId() {
    return this._organisationId;
  }
  @Input() placeHolder: string;
  @Input() label: string;
  @Input() prefixIcon = 'search';

  searchControl = new UntypedFormControl();
  searching = false;
  issues: IssueModel[] = [];
  @Input() mode: string;
  users$: Observable<OrganisationUserSearchResultModel[]>;
  ngControl: NgControl;
  searchComplete = false;

  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() organisationUserSelected = new EventEmitter();
  constructor(
    private organisationApiService: OrganisationApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.ngControl = this.injector.get(NgControl);
      if (this.ngControl?.control != null) {
        this.searchControl.setValidators(this.ngControl.control.validator);
        this.searchControl.updateValueAndValidity({
          emitEvent: false,
          onlySelf: true,
        });
        this.ngControl.control.statusChanges.subscribe((change: any) => {
          const validators = [userValidator()];
          if (this.ngControl.control.validator) {
            validators.push(this.ngControl.control.validator);
          }

          this.searchControl.setValidators(validators);
          if (this.ngControl.control.touched) {
            this.searchControl.markAsTouched();
          }
          this.searchControl.updateValueAndValidity({
            emitEvent: false,
            onlySelf: true,
          });
          this.changeDetectorRef.markForCheck();
        });
      }
    });
  }

  ngOnInit(): void {}

  wireAutoComplete() {
    this.users$ = this.searchControl.valueChanges.pipe(
      debounceTime(300),

      tap((value) => {}),
      tap(() => {
        this.searching = true;
        this.issues = [];
        this.searchComplete = false;

        this.changeDetectorRef.markForCheck();
      }),
      switchMap((value) => {
        if (value && value !== 'not-found' && typeof value === 'string') {
          const params = { limit: 25, offset: 0, name: value } as any;
          if (value === 'empty-search') {
            delete params['name'];

            this.searchControl.setValue(null, { emitEvent: false });
          }

          return this.organisationApiService
            .searchUsers(params, this.organisationId)
            .pipe(
              map(
                (
                  resp:
                    | HttpResponse<
                        ApiResponse<OrganisationUserSearchResultModel[]>
                      >
                    | any
                ) => {
                  this.searching = false;

                  this.searchComplete = true;
                  this.changeDetectorRef.markForCheck();
                  return resp.body.data;
                }
              ),

              catchError((issues: IssueModel[]) => {
                this.issues = issues;
                this.searching = false;
                this.searchComplete = false;

                this.changeDetectorRef.markForCheck();
                return [];
              })
            );
        } else {
          if (value === '') {
            this.searchControl.setValue(null, { emitEvent: false });
            this.onChange(null);
          }
          this.searching = false;
          this.searchComplete = false;
          this.changeDetectorRef.markForCheck();

          return [];
        }
      })
    );
  }

  selectUser(e: any) {
    let value = e?.option?.value;
    this.organisationUserSelected.emit(value);

    value = this.mode === 'id' ? value?.organisationUserId : value ? value : '';
    this.onChange(value);
    this.onTouched();
  }

  displayFn(user?: OrganisationUserSearchResultModel): string | undefined {
    if (user && user.firstName) {
      return user.firstName + ' ' + user.lastName;
    }
    return '';
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  writeValue(value: any) {
    if (value) {
      if (!value?.organisationId || typeof value === 'string') {
        this.searching = true;
        this.changeDetectorRef.markForCheck();
        this.organisationApiService
          .getUser(
            typeof value === 'string' ? value : value.organisationUserId,
            this.organisationId
          )
          .pipe(
            map((resp: ApiResponse<OrganisationUserModel> | any) => resp.data),
            catchError((error) => (this.issues = error)),
            tap(() => (this.searching = false))
          )
          .subscribe({
            next: (user: OrganisationUserModel) => {
              this.searching = false;
              //on change
              this.searchControl.setValue(user, { emitEvent: false });
              this.selectUser({ option: { value: user } });

              if (this.input) {
                this.input.nativeElement.value =
                  user.firstName + ' ' + user.lastName;
              }
              this.changeDetectorRef.markForCheck();
            },
            error: (error) => {
              this.changeDetectorRef.markForCheck();
            },
          });
      } else {
        this.input.nativeElement.value = value.firstName + ' ' + value.lastName;
        this.selectUser({ option: { value: value } });
        this.changeDetectorRef.markForCheck();
      }
    } else {
      this.searchControl.reset();
      this.changeDetectorRef.markForCheck();
    }
  }
  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onInput() {}

  inputClick(eventTarget: any) {
    if (
      eventTarget.value === '' &&
      (this.searchControl.value === '' || this.searchControl.value == null)
    ) {
      this.searchControl.setValue('empty-search');
      this.searchControl.updateValueAndValidity({ emitEvent: false });
    } else {
      eventTarget.select();
    }
  }
}
function userValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (control?.value?.organisationUserId) {
      return null;
    } else {
      return {
        userNotSelected: 'Please ensure you search and select a user',
      };
    }
  };
}
