import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupViewControlComponent } from './group-view-control.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [GroupViewControlComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, RouterModule],
  exports: [GroupViewControlComponent],
})
export class GroupViewControlModule {}
