import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ValidationApiService } from '@fleet/api';
import { ApiResponse, BsbModel, IssueModel } from '@fleet/model';
import { bsbValidator, restrictedWords } from '@fleet/utilities';

@Component({
  selector: 'fleet-bsb-form-control',
  templateUrl: './bsb-form-control.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BsbFormControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BsbFormControlComponent),
      multi: true,
    },
  ],
})
export class BsbFormControlComponent implements OnInit, ControlValueAccessor {
  bsbControl = new UntypedFormControl(null, [
    Validators.required,
    bsbValidator(),

    restrictedWords(['000000']) as ValidatorFn,
  ]);
  issues: IssueModel[] = [];
  validating = false;
  valid = false;
  bsb = '';
  initialSearch = false;
  @Input() showRequired = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private validationApiService: ValidationApiService
  ) {}

  ngOnInit(): void {
    this.bsbControl.valueChanges.subscribe((value: string) => {
      this.valid = false;

      const bsb = value
        .replace(/-/g, '')
        .replace(/ /g, '')
        .replace(' ', '')
        .replace('-', '');
      if (bsb.length === 6) {
        this.validateBsb(bsb);
      } else {
        this.bsb = null;
        this.onChange(null);
        this.onTouched();
        this.bsbControl.setErrors({ invalidBsb: true });
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  writeValue(value: string): void {
    if (value) {
      //needs to always emit so we can trigger the search and display the bsb object
      this.bsbControl.setValue(value);
    } else {
      this.bsb = null;
      this.bsbControl.setValue(null, { emitEvent: false });
    }
    this.changeDetectorRef.markForCheck();
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.bsbControl.disable({ emitEvent: false });
    } else {
      this.bsbControl.enable({ emitEvent: false });
    }
    this.changeDetectorRef.markForCheck();
  }

  validateBsb(bsb: string) {
    this.issues = [];
    this.validating = true;
    this.bsb = null;

    this.changeDetectorRef.markForCheck();

    this.validationApiService.searchBsb(bsb).subscribe(
      (resp: ApiResponse<BsbModel[]> | any) => {
        this.validating = false;

        if (resp.data && resp.data.length > 0) {
          //result found
          this.bsb = resp.data[0].bank + ' - ' + resp.data[0].locality;
          this.valid = true;
          //REMOVE THE DASH FROM THE SETTER HERE
          const val = bsb.replace('-', '');
          this.onChange(val);
        } else {
          this.bsb = null;
          this.bsbControl.setErrors({ noResults: true });

          this.valid = false;
          this.onChange(null);
        }
        this.onTouched();

        this.changeDetectorRef.markForCheck();
      },
      (error) => {
        this.issues = error;
        this.validating = false;
        this.valid = false;
        this.onChange(null);
        this.onTouched();
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(c: UntypedFormControl) {
    return c.value
      ? null
      : c.touched && this.valid
      ? {
          bsbValidator: {
            message: 'Please ensure you have provided a valid BSB',
          },
        }
      : null;
  }
}
