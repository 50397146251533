import { I } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { fuseAnimations, FuseMediaWatcherService } from '@fleet/fuse';
import { RelatedEntitySearchLayoutComponent } from '@fleet/layout';
import {
  BaseSearchState,
  CallModel,
  DriverModel,
  // CallModel,
  JobModel,
  OrganisationModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';
import { FleetNavigationService } from '@fleet/navigation';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityCallSearchService } from './entity-call-search.service';
import { DateTimeRangeControlComponent } from '@fleet/shared';
import { DateTime } from 'luxon';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@fleet/auth';
import { NetworkGroupService } from '@fleet/network-group';

const inBoundOutBound = 'INBOUND,OUTBOUND';
const proxy = 'PROXY';
@Component({
  selector: 'fleet-entity-call-search',
  templateUrl: './entity-call-search.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class EntityCallSearchComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('matTabs') matTabs: MatTabGroup;
  REMOVED_PROPERTIES = [
    'limit',
    'offset',
    'type',
    'networkId',
    'networkGroupId',
    'driverId',
    'vehicleId',
    'jobId',
    'travellerId',
    'organisationId',
  ];

  defaultStartTime: DateTime;

  defaultEndTime: DateTime;

  lastIndexTab = 0;

  lastSearchedNumber: string;
  @Input() entityType: string;
  drawerType: 'PREVIEW' = null;
  autoPlay = false;

  protected _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() securityFunctions: any;

  // phoneNumberControl = new UntypedFormControl();
  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
    if (value) {
      this.entityType = 'DRIVER';
      this.changeDetectorRef.markForCheck();
    }
  }
  get driver() {
    return this._driver;
  }

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      this.entityType = 'TRAVELLER';

      this.changeDetectorRef.markForCheck();
    }
  }
  get traveller() {
    return this._traveller;
  }
  _job: JobModel;
  @Input() set job(value: JobModel) {
    this._job = value;
    if (value) {
      this.searchForm.get('fromDate').reset();
      this.searchForm.get('toDate').reset();

      this.REMOVED_PROPERTIES.push('toDate');
      this.REMOVED_PROPERTIES.push('fromDate');

      this.entityType = 'JOB';

      this.changeDetectorRef.markForCheck();
    }
  }
  get job() {
    return this._job;
  }

  _organisation: OrganisationModel;
  @Input() set organisation(value: OrganisationModel) {
    this._organisation = value;
    if (value) {
      this.entityType = 'ORGANISATION';

      this.changeDetectorRef.markForCheck();
    }
  }

  get organisation() {
    return this._organisation;
  }

  @Input() noEntity: boolean;

  searchForm: FormGroup;

  paramLabelMap: any = {};

  search: BaseSearchState<CallModel>;
  @ViewChild('searchLayout', { static: false })
  searchLayout: RelatedEntitySearchLayoutComponent;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  constructor(
    private entityCallService: EntityCallSearchService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private networkGroupService: NetworkGroupService,
    private authService: AuthService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.paramLabelMap['fromDate'] = {
      dateFormat: 'D hh:mm a ZZ',
      label: 'From',
      disabled: true,
    };
    this.paramLabelMap['toDate'] = {
      dateFormat: 'D hh:mm a ZZ',
      label: 'To',
      disabled: true,
    };
  }

  buildForm() {
    this.searchForm = this.fb.group({
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
      phoneNumber: [],
    });
  }

  ngOnDestroy(): void {
    this.entityCallService.clearState();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    this.entityCallService.callSearchState
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: any) => {
        if (data) {
          this.search = data;
          this.changeDetectorRef.detectChanges();
        }
      });

    combineLatest([
      this.networkGroupService.networkGroup$,
      this.activatedRoute.queryParams,
    ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([networkGroup, queryParams]) => {
        if (networkGroup) {
          if (this.driver || this.traveller) {
            this.defaultStartTime = DateTime.now().minus({ months: 1 });
          } else if (this.noEntity) {
            this.defaultStartTime = DateTime.now().minus({
              hours: 24,
            });
          }
          this.defaultEndTime = DateTime.now();

          let patch = Object.assign({}, queryParams);

          patch['networkGroupId'] = networkGroup.networkGroupId;
          patch['networkId'] = networkGroup.networkId;

          if (
            patch.phoneNumber &&
            this.searchForm.get('phoneNumber').value !== patch.phoneNumber
          ) {
            this.searchForm.get('phoneNumber').setValue(patch.phoneNumber);
          }

          if (!patch.toDate) {
            patch = Object.assign({}, patch, {
              toDate: this.defaultEndTime,
            });
          }

          if (!patch.fromDate) {
            patch = Object.assign({}, patch, {
              fromDate: this.defaultStartTime,
            });
          }

          let dates = {} as any;
          if (patch.fromDate) {
            dates['fromDate'] =
              typeof patch.fromDate === 'string'
                ? DateTime.fromISO(patch.fromDate)
                : patch.fromDate;
          }

          if (patch.toDate) {
            dates['toDate'] =
              typeof patch.fromDate === 'string'
                ? DateTime.fromISO(patch.toDate)
                : patch.toDate;
          }

          if (this.entityType === 'JOB') {
            this.searchForm.get('fromDate').reset();
            this.searchForm.get('toDate').reset();

            delete patch['toDate'];
            delete patch['fromDate'];
          } else {
            this.searchForm.get('fromDate').patchValue(dates['fromDate']);
            this.searchForm.get('toDate').patchValue(dates['toDate']);
          }

          if (patch.type === inBoundOutBound) {
            this.searchByTabIndex(0);
            this.tabGroup.selectedIndex = 0;
          } else if (patch.type === proxy) {
            this.searchByTabIndex(1);
            this.tabGroup.selectedIndex = 1;
          } else {
            this.searchByTabIndex(0);
            this.tabGroup.selectedIndex = 0;
          }
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  clearAndSearch() {
    this.searchForm.reset();
    this.searchByTabIndex(this.lastIndexTab);
    this.changeDetectorRef.markForCheck();
  }

  previewCall(call: CallModel) {
    this.autoPlay = false;
    this.entityCallService.setCallPreview(call);
    this.searchLayout.setEndDrawer(true);
    this.drawerType = 'PREVIEW';
    this.changeDetectorRef.markForCheck();
  }

  playRecording(call: CallModel) {
    this.autoPlay = true;
    this.entityCallService.setCallPreview(call);
    this.searchLayout.setEndDrawer(true);
    this.drawerType = 'PREVIEW';
    this.changeDetectorRef.markForCheck();
  }

  closeDrawer() {
    this.drawerType = null;
    this.searchLayout.setEndDrawer(false);
    this.searchLayout.setSearchFilter(false);
    this.changeDetectorRef.markForCheck();
  }

  setMode(value: 'PREVIEW' | null) {
    this.drawerType = value;
    this.changeDetectorRef.markForCheck();
  }

  searchByTabIndex(index: number) {
    this.lastIndexTab = index;
    const params = {
      limit: 50,
      offset: 0,
    } as any;
    if (this.searchForm.get('phoneNumber').value && !this.job) {
      params['phoneNumber'] = this.searchForm.get('phoneNumber').value;
      this.lastSearchedNumber = params.phoneNumber;
    } else {
      this.lastSearchedNumber = null;
    }

    if (index === 0) {
      params['type'] = inBoundOutBound;
    } else {
      params['type'] = proxy;
    }

    if (this.searchForm.get('fromDate').value && !this.job) {
      params['fromDate'] = this.searchForm.get('fromDate').value;
    }

    if (this.searchForm.get('toDate').value && !this.job) {
      params['toDate'] = this.searchForm.get('toDate').value;
    }

    this.checkEntitiesAndSearch(params);
    this.changeDetectorRef.markForCheck();
  }

  checkEntitiesAndSearch(params: any) {
    if (this.job) {
      params.jobId = this.job.jobId;
    }
    if (this.organisation) {
      params.organisationId = this.organisation.organisationId;
    }
    if (this.traveller) {
      params.travellerId = this.traveller.travellerId;
    }
    if (this.driver) {
      params.driverId = this.driver.driverId;
    }
    this.searchLayout.setEndDrawer(false);
    this.searchLayout.setSearchFilter(false);

    this.entityCallService.searchCalls(params);
  }

  getNextPage() {
    this.entityCallService.getNextPage();
  }

  removeParamAndSearch(term: string) {
    if (term === 'phoneNumber') {
      this.searchForm.get('phoneNumber').reset();
    } else if (term === 'fromDate' || term === 'toDate') {
      this.searchForm.get('fromDate').reset();
      this.searchForm.get('toDate').reset();
    }

    this.populateRouteAndSearchByTabIndex(this.lastIndexTab);

    this.changeDetectorRef.markForCheck();
  }

  clearAndSearchByTabIndex(index: number) {
    this.populateRouteAndSearchByTabIndex(index);
  }

  populateRouteAndSearchByTabIndex(index: number) {
    const newParams = Object.assign({}, this.searchForm.value);

    if (index === 0) {
      newParams['type'] = inBoundOutBound;
    } else {
      newParams['type'] = proxy;
    }
    newParams['r'] = Math.random();
    for (const key in newParams) {
      // eslint-disable-next-line no-prototype-builtins
      if (newParams.hasOwnProperty(key) && newParams[key] === '') {
        delete newParams[key];
      }
    }
    this.router.navigate(['./'], {
      queryParams: newParams,
      relativeTo: this.activatedRoute,
    });
  }
}
