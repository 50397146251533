import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validator,
  Validators,
} from '@angular/forms';
import { AccountModel } from '@fleet/model';

@Component({
  selector: 'fleet-account-model-form',
  templateUrl: './account-model-form.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountModelFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AccountModelFormComponent),
      multi: true,
    },
  ],
})
export class AccountModelFormComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input() padding = false;
  _paymentType: string;
  @Input() set paymentType(value: string) {
    this._paymentType = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      if (value === 'DIRECT_DEBIT_BANK_ACCOUNT') {
        this.form.get('settlementFrequency').clearValidators();
        this.form.get('settlementFrequency').disable();
      } else {
        this.form
          .get('settlementFrequency')
          .setValidators([Validators.required]);
        this.form.get('settlementFrequency').enable();
      }

      this.form.get('settlementFrequency').updateValueAndValidity();
    }
  }

  get paymentType() {
    return this._paymentType;
  }

  @ViewChild('bsbInput', { read: ElementRef }) bsbInput: ElementRef;
  @ViewChild('accountNameInput', { read: ElementRef })
  accountNameInput: ElementRef;

  form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.form) {
      this.buildForm();
    }
    this.form.valueChanges.subscribe((account: AccountModel) => {
      if (account && account.accountName) {
        //always force this

        account.accountName = account.accountName.toUpperCase();
      }
      this.onChange(account);
      this.onTouched();
    });
  }

  buildForm() {
    this.form = this.fb.group({
      bsb: [null, [Validators.required]],
      accountNumber: [
        null,
        [
          Validators.required,
          Validators.maxLength(9),
          Validators.pattern('^[0-9\\s]+$'),
        ],
      ],
      accountName: [
        null,
        [
          Validators.required,
          Validators.maxLength(32),
          Validators.pattern('^[a-zA-Z0-9\\-\\s]+$'),
        ],
      ],
      settlementFrequency: ['DAILY', [Validators.required]],
    });
  }

  writeValue(value: AccountModel): void {
    //needs to always emit so we can trigger the search and display the bsb object
    if (!this.form) {
      this.buildForm();
    }

    if (value) {
      setTimeout(() => {
        this.form.patchValue(value);

        this.form.get('bsb').disable();
        this.form.get('accountNumber').disable();
        if (this.paymentType !== 'SETTLEMENT_BANK_ACCOUNT') {
          this.form.get('settlementFrequency').disable();
          this.form.get('settlementFrequency').clearValidators();
          this.form.get('settlementFrequency').updateValueAndValidity();
        }
      }, 100);
      this.form.updateValueAndValidity();
      this.changeDetectorRef.markForCheck();
    } else {
      setTimeout(() => {
        //It is patched as null - reset form and enable
        this.form.reset();
        this.form.get('bsb').enable();
        this.form.get('accountNumber').enable();
        if (this.paymentType === 'SETTLEMENT_BANK_ACCOUNT') {
          //reset to daily - it is REQUIRED and is a radio button so should always be selected as one of the two options

          this.form.get('settlementFrequency').patchValue('DAILY');
          this.form.get('settlementFrequency').enable();
          this.form.get('settlementFrequency').updateValueAndValidity();
        }
      }, 100);
    }
    this.changeDetectorRef.markForCheck();
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  focusField(value: string) {
    if (value) {
      if (value === 'accountName') {
        setTimeout(() => this.accountNameInput.nativeElement.focus(), 0);
      }

      if (value === 'bsb') {
        setTimeout(() => this.bsbInput.nativeElement.focus(), 0);
      }
    }
  }

  validate() {
    return this.form.valid
      ? null
      : { message: 'Account details are not valid' };
  }
}
