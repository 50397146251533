import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteCreateEditComponent } from './note-create-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FuseAlertModule } from '@fleet/fuse';
import { ActionButtonsModule } from '@fleet/ui';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { ProgressButtonModule } from '@fleet/shared';
import { SidebarFormLayoutModule } from '@fleet/layout';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [NoteCreateEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    ActionButtonsModule,
    ProgressButtonModule,
    MatCheckboxModule,
    SidebarFormLayoutModule,
  ],
  exports: [NoteCreateEditComponent],
})
export class NoteCreateEditModule {}
