import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  BaseSearchState,
  DriverModel,
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
} from '@fleet/model';

export interface PaymentMethodListItem {
  paymentMethod: any;
  isDefault?: boolean;
  actions?: [{ label: string; value: string }];
}

@Component({
  selector: 'fleet-payment-method-list',
  templateUrl: './payment-method-list.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodListComponent implements OnInit {
  //Put logic for sorting here - is default at top.

  @Input() disableActions = false;

  _search: BaseSearchState<PaymentMethodListItem>;
  @Input() set search(value: BaseSearchState<PaymentMethodListItem>) {
    if (value) {
      value.data.some(
        (item: any, idx: any) =>
          item.isDefault == true &&
          value.data.unshift(
            // remove the found item, in-place (by index with splice),
            // returns an array of a single item removed
            value.data.splice(idx, 1)[0]
          )
      );

      this.listItems = value.data;
      this.changeDetectorRef.markForCheck();
    }
  }

  _paymentMethods: PaymentMethodSearchResultModel[];
  @Input() set paymentMethods(value: PaymentMethodSearchResultModel[]) {
    if (value) {
      const newItems = value.map((p: PaymentMethodSearchResultModel) => ({
        paymentMethod: p,
        isDefault: false,
        actions: null,
      })) as PaymentMethodListItem[];
      this.listItems = newItems;
      this.changeDetectorRef.markForCheck();
    }
  }

  listItems: PaymentMethodListItem[] = [];

  @Input() mode: 'card' | 'list' = 'list';

  @Input() showDefault = true;

  @Output() actionSelected = new EventEmitter();
  @Output() cardSelected = new EventEmitter();

  @Input() includeCreate: boolean;
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  selectAction() {}
}
