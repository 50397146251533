<div class="flex flex-col gap-3 px-6 py-3" *ngIf="exportDisplay">
  <div class="flex flex-col justify-between w-full">
    <div class="flex flex-row gap-3 truncate">
      <!-- <img class="w-10 h-10 rounded-md overflow-hidden" [src]="document.url"> -->
      <mat-icon
        *ngIf="exportDisplay.export.status !== 'COMPLETED'; else completed"
        >cloud_download</mat-icon
      >
      <ng-template #completed>
        <mat-icon>download_done</mat-icon>
      </ng-template>
      <div class="flex flex-col truncate">
        <div class="text-md font-medium truncate">
          {{ exportDisplay.name }}
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <div class="self-end mt-auto">
        <a
          *ngIf="exportDisplay.export.s3Url"
          [href]="exportDisplay.export.s3Url"
          download
          class="cursor-pointer underline text-md text-secondary"
          >Download</a
        >
      </div>
    </div>
  </div>
  <mat-progress-bar
    *ngIf="exportDisplay.export.status !== 'COMPLETED'"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
