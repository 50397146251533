export function getOrganisationRoutePrefix(
  fleetProduct: string,
  organisationId: string
) {
  if (fleetProduct === 'BUSINESS') {
    return '';
  } else if (fleetProduct === 'HUB' && organisationId) {
    return '/organisation/' + organisationId;
  }
  return '';
}
export function getDriverRoutePrefix(fleetProduct: string, driverId: string) {
  if (fleetProduct === 'DRIVER') {
    return '/driver/';
  } else if (fleetProduct === 'HUB' && driverId) {
    return '/driver/' + driverId;
  }
  return '';
}
export function getOperatorRoutePrefix(
  fleetProduct: string,
  operatorId: string
) {
  if (fleetProduct === 'OPERATOR') {
    return '';
  } else if (fleetProduct === 'HUB' && operatorId) {
    return '/operator/' + operatorId;
  }
  return '';
}
export function getTravellerRoutePrefix(
  fleetProduct: string,
  travellerId: string
) {
  if (fleetProduct === 'TRAVELLER') {
    return '';
  } else if (fleetProduct === 'HUB' && travellerId) {
    return '/traveller/' + travellerId;
  }
  return '';
}
