import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReverseGeoLocationComponent } from './reverse-geo-location.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

import { SelectionListInDrawerModule } from '@fleet/layout';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ReverseGeoLocationComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,

    SelectionListInDrawerModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
  exports: [ReverseGeoLocationComponent],
})
export class ReverseGeoLocationModule {}
