import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { ApiResponse, ShiftModel, ShiftSearchResultModel } from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShiftApiService {
  host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.shift;
  }

  searchShifts(
    params: any
  ): Observable<HttpResponse<ApiResponse<ShiftSearchResultModel[]>> | any> {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getShift(shiftId: string): Observable<ApiResponse<ShiftModel> | any> {
    return this.http
      .get(`${this.host}/${shiftId}`, {})
      .pipe(catchError(handleApiError));
  }

  createShift(shift: any): Observable<ApiResponse<ShiftModel> | any> {
    return this.http
      .post(`${this.host}`, shift)
      .pipe(catchError(handleApiError));
  }

  updateShift(
    shiftId: string,
    shift: any
  ): Observable<ApiResponse<ShiftModel> | any> {
    return this.http
      .put(`${this.host}/${shiftId}`, shift)
      .pipe(catchError(handleApiError));
  }

  finishShift(
    shiftId: string,
    shift?: any
  ): Observable<ApiResponse<ShiftModel> | any> {
    return this.http
      .post(`${this.host}/${shiftId}/finish`, shift)
      .pipe(catchError(handleApiError));
  }

  extendShift(shiftId: string): Observable<any> {
    return this.http
      .post(`${this.host}/${shiftId}/extend`, {})
      .pipe(catchError(handleApiError));
  }

  deleteShift(shiftId: string): Observable<any> {
    return this.http
      .delete(`${this.host}/${shiftId}`)
      .pipe(catchError(handleApiError));
  }
}
