<fuse-loading-bar></fuse-loading-bar>
<mat-drawer-container class="flex flex-col flex-auto w-full min-w-0">
  <mat-drawer class="w-full" mode="over" #drawer>
    <div
      class="fixed top-0 left-0 right-0 flex border-b-2 justify-end p-4 bg-card"
    >
      <mat-icon class="cursor-pointer" (click)="drawer.toggle()"
        >close</mat-icon
      >
    </div>
    <div class="mt-20">
      <ng-container *ngFor="let group of navigationMenu">
        <div
          *ngIf="group.title"
          class="flex items-center justify-between m-8 mr-6 sm:mt-4 sm:mb-6"
        >
          <!-- Title -->
          <div
            [id]="group.title"
            class="text-2xl font-bold tracking-tight leading-none mt-2"
          >
            {{ group.title }}
          </div>
          <!-- Close button -->
        </div>

        <div class="flex flex-col divide-y border-t border-b">
          <ng-container *ngFor="let item of group.children">
            <fleet-settings-tab-label [panel]="item">
            </fleet-settings-tab-label>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </mat-drawer>
  <mat-drawer-content class="flex flex-col flex-auto w-full min-w-0">
    <!-- Wrapper -->
    <!-- Wrapper -->
    <!-- <div class="flex flex-col flex-auto w-full min-w-0"> -->
    <!-- Header -->
    <div
      *ngIf="showToolbar"
      class="bg-card dark:bg-transparent relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
    >
      <!-- Navigation toggle button -->
      <button mat-icon-button (click)="drawer.open()">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
      <div class="flex flex-col ml-6 gap-1">
        <div class="text-secondary text-xs">
          {{ pageGroupTitle }}
        </div>
        <div class="text-lg font-medium">{{ pageTitle }}</div>
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div
    class="
      relative
      flex flex-0
      items-center
      justify-start
      w-full
      h-14
      px-4
      md:px-6
      z-49
      border-t
      bg-card
      dark:bg-transparent
      print:hidden
    "
  >
    <span class="font-medium text-secondary"
      >Fleet &copy; {{ currentYear }}</span
    >
  </div> -->
    <!-- </div> -->
  </mat-drawer-content>
</mat-drawer-container>
