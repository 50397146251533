import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validator,
  Validators,
} from '@angular/forms';
import { NetworkGroupModel } from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { DateTime } from 'luxon';
import { DateWithTimeControlComponent } from '../date-with-time-control/date-with-time-control.component';
import { ComponentType } from '@angular/cdk/portal';
import { MatDateRangePicker } from '@angular/material/datepicker';
import {
  dateRangeBeforeAfterValidator,
  hasRequiredValidator,
} from '@fleet/utilities';
import { CustomCalendarHeaderComponent } from './custom-calendar-header.component';

@Component({
  selector: 'fleet-date-time-range-control',
  templateUrl: './date-time-range-control.component.html',
  styleUrls: ['./date-time-range-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class DateTimeRangeControlComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, Validator
{
  @Input() enforceDateBeforeAfter = true;
  toDateMin: DateTime = null;
  @Input() allowEmptyTime = false;
  @Input() includeTime = true;
  customHeaderComponent: ComponentType<
    CustomCalendarHeaderComponent<DateTime>
  > = CustomCalendarHeaderComponent;

  @ViewChild(MatDateRangePicker) picker: MatDateRangePicker<DateTime>;

  @ViewChild('fromDate') fromDateControl: DateWithTimeControlComponent;
  @ViewChild('toDate') toDateControl: DateWithTimeControlComponent;

  @Input() type: 'SELECT' | 'RANGE' = 'SELECT';

  _hideToDate: boolean;
  @Input() set hideToDate(value: boolean) {
    this._hideToDate = value;
    if (value) {
      if (!this.customTimeForm) {
        this.buildForm();
      }

      this.customTimeForm.get('toDate').reset();
      this.customTimeForm.get('toDate').updateValueAndValidity();
    }
  }

  get hideToDate() {
    return this._hideToDate;
  }

  @Input() formCustomClass: string;
  @Input() controlCustomClass: string;

  timeTypeControl = new UntypedFormControl();
  customTimeForm: UntypedFormGroup;
  @Input() label = 'Time Range';
  @Input() defaultTimeRange = 'TODAY';

  avaiableTimezoneControl = new UntypedFormControl();
  timezones: string[] = [];
  networkGroup: NetworkGroupModel;

  @Input() defaultStartTime: DateTime = DateTime.now().set({
    hour: 0,
    minute: 0,
    second: 0,
  });

  @Input() defaultEndTime: DateTime = DateTime.now().set({
    hour: 23,
    minute: 59,
    second: 59,
  });

  @Input() effectiveToLabel: string = null;
  @Input() effectiveFromLabel: string = null;

  @ViewChild('startDate', { static: false })
  startDate: DateWithTimeControlComponent;

  @ViewChild('endDate', { static: false })
  endDate: DateWithTimeControlComponent;

  @Input() mode: 'LUXON' | 'LOCAL' | 'ISO' = 'LUXON';

  @Input() minDate: DateTime = null;
  @Input() showNowControl: boolean;

  _customOnly: boolean;
  @Input() set customOnly(value: boolean) {
    this._customOnly = value;
    if (value) {
      this.timeTypeControl.setValue('CUSTOM');
    }
  }

  get customOnly() {
    return this._customOnly;
  }

  @Input() hideTimezone = false;

  _fromRequired: boolean;
  @Input() set fromRequired(value: boolean) {
    this._fromRequired = value;
    if (!this.customTimeForm) {
      this.buildForm();
    }
    if (value) {
      this.customTimeForm.get('fromDate').addValidators([Validators.required]);
      this.customTimeForm.get('fromDate').updateValueAndValidity();
    } else {
      this.customTimeForm.get('fromDate').clearValidators();
      this.customTimeForm.get('fromDate').updateValueAndValidity();
    }
  }

  get fromRequired() {
    return this._fromRequired;
  }

  _toRequired: boolean;
  @Input() set toRequired(value: boolean) {
    this._toRequired = value;
    if (!this.customTimeForm) {
      this.buildForm();
    }

    if (value) {
      this.customTimeForm.get('toDate').addValidators([Validators.required]);
      this.customTimeForm.get('toDate').updateValueAndValidity();
    } else {
      this.customTimeForm.get('toDate').clearValidators();
      this.customTimeForm.get('toDate').updateValueAndValidity();
    }
  }

  get toRequired() {
    return this._toRequired;
  }

  @Input() inSearchForm = false;

  constructor(
    private fb: UntypedFormBuilder,
    private networkGroupService: NetworkGroupService,
    private changeDetectorRef: ChangeDetectorRef,
    @Self() public ngControl: NgControl
  ) {
    ngControl.valueAccessor = this;
  }

  ngAfterViewInit(): void {
    if (!this.customOnly) {
      this.timeTypeControl.setValue(this.defaultTimeRange);
    }

    this.changeDetectorRef.markForCheck();
  }

  setTimeTypeControl(value: string) {
    this.timeTypeControl.setValue(value);
  }

  setIntialTimezone() {
    this.avaiableTimezoneControl.disable();
    this.timezones.push(Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.avaiableTimezoneControl.setValue(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    this.changeDetectorRef.markForCheck();
  }

  buildForm() {
    this.customTimeForm = this.fb.group(
      {
        fromDate: [
          null,
          this.fromRequired || this.type === 'RANGE'
            ? [Validators.required]
            : null,
        ],
        toDate: [
          null,
          this.toRequired || this.type === 'RANGE'
            ? [Validators.required]
            : null,
        ],
      },
      {
        validator: this.enforceDateBeforeAfter
          ? dateRangeBeforeAfterValidator('fromDate', 'toDate')
          : null,
      }
    );
  }
  ngOnInit(): void {
    if (!this.customTimeForm) {
      this.buildForm();
    }
    this.setIntialTimezone();

    this.ngControl.control.setValidators((control) =>
      this.validate(control.value)
    );

    this.ngControl.control.statusChanges.subscribe((status: string) => {
      this.customTimeForm.setValidators(this.ngControl.control.validator);

      if (hasRequiredValidator(this.ngControl.control)) {
        //chat gpt says to add the required validator manually here if there specifcally...

        const currentValidators = this.ngControl.control.validator;
        const newValidators =
          Array.isArray(currentValidators) && currentValidators !== null
            ? [
                ...currentValidators.filter((validator) => validator !== null),
                Validators.required,
              ]
            : currentValidators !== null
            ? [currentValidators, Validators.required]
            : [Validators.required];
        this.customTimeForm.setValidators(newValidators);

        this.customTimeForm.updateValueAndValidity({ emitEvent: false });
      }

      if (this.fromRequired || this.type === 'RANGE') {
        const newValidators = [Validators.required];

        this.customTimeForm.get('fromDate').setValidators(newValidators);

        this.customTimeForm
          .get('fromDate')
          .updateValueAndValidity({ emitEvent: false });
      } else {
        this.customTimeForm.get('fromDate').clearValidators();

        this.customTimeForm
          .get('fromDate')
          .updateValueAndValidity({ emitEvent: false });
      }

      if (this.toRequired || this.type === 'RANGE') {
        const newValidators = [Validators.required];

        this.customTimeForm.get('toDate').setValidators(newValidators);

        this.customTimeForm
          .get('toDate')
          .updateValueAndValidity({ emitEvent: false });
      } else {
        this.customTimeForm.get('toDate').clearValidators();

        this.customTimeForm
          .get('toDate')
          .updateValueAndValidity({ emitEvent: false });
      }

      if (this.ngControl.touched) {
        this.customTimeForm.markAsTouched();
        this.customTimeForm.updateValueAndValidity({ emitEvent: false });
      }

      if (this.customTimeForm.get('fromDate').touched) {
        this.customTimeForm.get('fromDate').markAsTouched();

        this.customTimeForm
          .get('fromDate')
          .updateValueAndValidity({ emitEvent: false });
      }
      if (this.customTimeForm.get('toDate').touched) {
        this.customTimeForm.get('toDate').markAsTouched();

        this.customTimeForm
          .get('toDate')
          .updateValueAndValidity({ emitEvent: false });
      }

      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });

    this.avaiableTimezoneControl.valueChanges.subscribe((timezone) => {
      if (timezone) {
        this.sync();
      }
    });

    this.networkGroupService.networkGroup$.subscribe(
      (networkGroup: NetworkGroupModel) => {
        this.networkGroup = networkGroup;

        if (networkGroup && networkGroup.timezone) {
          this.avaiableTimezoneControl.enable();
          this.timezones.unshift(networkGroup.timezone);
          this.avaiableTimezoneControl.setValue(networkGroup.timezone);
          this.changeDetectorRef.markForCheck();
        }
      }
    );

    this.timeTypeControl.valueChanges.subscribe((type: string) => {
      let now = DateTime.now();

      let time = now;
      if (type !== 'CUSTOM') {
        switch (type) {
          case '3HRS':
            time = time.minus({ hours: 3 });
            break;
          case '12HRS':
            time = time.minus({ hours: 12 });
            break;
          case '24HRS':
            time = time.minus({ hours: 24 });
            break;
          case 'TODAY':
            time = time.startOf('day');
            now = now.endOf('day');
            break;
          case 'TOMORROW':
            time = time.plus({ days: 1 }).startOf('day');
            now = now.plus({ days: 1 }).endOf('day');

            break;

          case 'NOW':
            now = time.plus({ hours: 1 });
            time = DateTime.now().minus({ hours: 1 });
            break;

          case 'NEXT_HOUR':
            now = time.plus({ hours: 1 });
            time = DateTime.now();
            break;

          case 'NEXT_3HOURS':
            now = time.plus({ hours: 3 });
            time = DateTime.now();
            break;

          case 'NEXT_12HOURS':
            now = time.plus({ hours: 12 });
            time = DateTime.now();
            break;

          case '2DAYS':
            time = time.plus({ days: 1 }).startOf('day');
            now = now.plus({ days: 2 }).endOf('day');
            break;
          case 'WEEK':
            time = time.plus({ days: 1 }).startOf('day');
            now = now.plus({ days: 7 }).endOf('day');
            break;
          default:
            time = time.minus({ hours: 24 });
            break;
        }

        if (this.networkGroup && this.networkGroup.timezone) {
          //reset the selected timezone - in the event that it was changed when playing with custom times
          this.avaiableTimezoneControl.setValue(this.networkGroup.timezone);
        }

        this.onTouched();

        //reset the custom pickers
        if (this.startDate) {
          this.startDate.reset();
        }
        if (this.endDate) {
          this.endDate.reset();
        }

        if (this.mode === 'LUXON') {
          this.onChange({
            fromDate: time,
            toDate: now,
          });
        } else if (this.mode === 'LOCAL') {
          let from = time.toString().split(/([+-]\d{2}:\d{2})/);
          let to = now.toString().split(/([+-]\d{2}:\d{2})/);

          this.onChange({
            fromDate: from[0],
            toDate: to[0],
          });
        } else {
          this.onChange({
            fromDate: time,
            toDate: now,
          });
        }

        this.changeDetectorRef.markForCheck();
      }
    });

    this.customTimeForm.valueChanges.subscribe((val) => {
      if (val) {
        this.sync();
      }
    });
  }

  onChange: any = (locality: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: { fromDate: DateTime; toDate: DateTime }) {
    if (value) {
      setTimeout(() => {
        if (value.fromDate) {
          this.customTimeForm.get('fromDate').patchValue(value.fromDate);
          this.changeDetectorRef.markForCheck();
        }
        if (value.toDate) {
          this.customTimeForm.get('toDate').patchValue(value.toDate);
          this.changeDetectorRef.markForCheck();
        }
      }, 10);
    }

    this.changeDetectorRef.markForCheck();
  }

  sync() {
    //This may have just been written with dd/mm/yyyy from a date range, or a proper date time object.

    const fromValue = this.customTimeForm.get('fromDate').value;
    const toValue = this.customTimeForm.get('toDate').value;
    let from =
      typeof fromValue === 'string' ? DateTime.fromISO(fromValue) : fromValue;
    let to = typeof toValue === 'string' ? DateTime.fromISO(toValue) : toValue;

    if (this.avaiableTimezoneControl.value) {
      if (from) {
        if (this.mode !== 'LOCAL') {
          from = from.setZone(this.avaiableTimezoneControl.value, {
            keepLocalTime: true,
          });
        }
      }

      if (to) {
        if (this.mode !== 'LOCAL') {
          to = to.setZone(this.avaiableTimezoneControl.value, {
            keepLocalTime: true,
          });
        }
      }
    }

    this.onTouched();
    this.changeDetectorRef.markForCheck();

    if (this.mode === 'LUXON') {
      this.onChange({
        fromDate: from && from.isValid ? from : null,
        toDate: to && to.isValid ? to : null,
      });
    } else if (this.mode === 'LOCAL') {
      let fromLocal = {} as any;
      let toLocal = {} as any;

      if (this.customTimeForm.value.fromDate) {
        const fromDateValue = this.customTimeForm
          .get('fromDate')
          .value.toString();
        const fromDateSplit = fromDateValue.split(/([+-]\d{2}:\d{2})/);
        fromLocal = {
          dateTime: fromDateSplit[0],
          timeZone: fromDateSplit[1] ? fromDateSplit[1] : '',
        };
      }

      if (this.customTimeForm.value.toDate) {
        const toDateValue = this.customTimeForm.get('toDate').value.toString();
        const toDateSplit = toDateValue.split(/([+-]\d{2}:\d{2})/);
        toLocal = {
          dateTime: toDateSplit[0],
          timeZone: toDateSplit[1] ? toDateSplit[1] : '',
        };
      }

      this.onChange({
        fromDate: fromLocal.dateTime,
        toDate: toLocal.dateTime,
      });
    } else {
      this.onChange({
        fromDate: from,
        toDate: to,
      });
    }
  }

  resetControl() {
    if (this.networkGroup && this.networkGroup.timezone) {
      this.avaiableTimezoneControl.setValue(this.networkGroup.timezone);
    }

    //could be hidden since it relies on the selector to make it exist.
    if (this.customTimeForm.get('fromDate').value) {
      this.customTimeForm.get('fromDate').reset();
      this.customTimeForm.get('fromDate').updateValueAndValidity();
    }
    if (this.customTimeForm.get('toDate').value) {
      this.customTimeForm.get('toDate').reset();
      this.customTimeForm.get('toDate').updateValueAndValidity();
    }
    this.timeTypeControl.reset();
    this.changeDetectorRef.markForCheck();
  }

  resetStartDate() {
    if (this.customTimeForm.get('fromDate').value) {
      this.customTimeForm.get('fromDate').reset();
      this.customTimeForm.get('fromDate').updateValueAndValidity();
    }
    this.changeDetectorRef.markForCheck();
  }

  resetEndDate() {
    if (this.customTimeForm.get('toDate').value) {
      this.customTimeForm.get('toDate').reset();
      this.customTimeForm.get('toDate').updateValueAndValidity();
    }

    this.changeDetectorRef.markForCheck();
  }

  validate(value: any) {
    //We could be using the DATE PICKER or the RANGE PICKER - just handle both
    const fromDateValue = this.customTimeForm.get('fromDate').value;
    const toDateValue = this.customTimeForm.get('toDate').value;
    const fromDate =
      typeof fromDateValue === 'string'
        ? DateTime.fromISO(fromDateValue)
        : fromDateValue;
    const toDate =
      typeof toDateValue === 'string'
        ? DateTime.fromISO(toDateValue)
        : toDateValue;

    if (this.type === 'RANGE') {
      //Range is 100% always going to require a to/from as it is a range
      if (
        !fromDate ||
        !fromDate.isValid ||
        !toDate ||
        !toDate.isValid ||
        this.minDate > toDate ||
        this.minDate > fromDate ||
        fromDate >= toDate
      ) {
        return { required: true };
      } else {
        return null;
      }
    } else {
      if (
        fromDate &&
        fromDate.isValid &&
        toDate &&
        toDate.isValid &&
        this.toDateControl
      ) {
        if (
          fromDate.hasSame(toDate, 'day') &&
          !this.includeTime &&
          this.enforceDateBeforeAfter
        ) {
          this.toDateControl.dateTimeForm.setErrors({
            dateTimeNotFuture: true,
          });
          this.toDateControl.dateTimeForm
            .get('date')
            .setErrors({ dateNotFuture: true });

          this.toDateControl.markControlAsTouched();
          return { invalidDateRange: true };
        } else if (
          this.enforceDateBeforeAfter &&
          fromDate.hasSame(toDate, 'day') &&
          (fromDate.hour > toDate.hour ||
            (fromDate.hour === toDate.hour && fromDate.minute >= toDate.minute))
        ) {
          //It is the SAME date and the from TIMES are greater..
          this.toDateControl.dateTimeForm.setErrors({
            dateTimeNotFuture: true,
          });
          this.toDateControl.dateTimeForm
            .get('time')
            .setErrors({ timeNotFuture: true });
          this.toDateControl.dateTimeForm.get('date').setErrors(null);
          this.toDateControl.markControlAsTouched();

          return { invalidDateRange: true };

          //So lets purposely only set the time to need to be
        } else if (
          this.enforceDateBeforeAfter &&
          (fromDate.equals(toDate) || fromDate > toDate)
        ) {
          // its the same date, but the time is okay
          this.toDateControl.dateTimeForm.setErrors({
            dateTimeNotFuture: true,
          });
          this.toDateControl.dateTimeForm
            .get('date')
            .setErrors({ dateNotFuture: true });
          this.toDateControl.dateTimeForm.get('time').setErrors(null);
          this.toDateControl.markControlAsTouched();

          return { invalidDateRange: true };
        } else {
          this.toDateControl.dateTimeForm.get('date').setErrors(null);
          this.toDateControl.dateTimeForm.get('time').setErrors(null);
          this.toDateControl.dateTimeForm.setErrors(null);
        }
      }

      if (!this.toRequired && !toDate && this.toDateControl) {
        this.toDateControl.dateTimeForm.get('date').setErrors(null);
        this.toDateControl.dateTimeForm.get('time').setErrors(null);
        this.toDateControl.dateTimeForm.setErrors(null);
      }
      if (!this.fromRequired && !fromDate && this.fromDateControl) {
        this.fromDateControl.dateTimeForm.get('date').setErrors(null);
        this.fromDateControl.dateTimeForm.get('time').setErrors(null);
        this.fromDateControl.dateTimeForm.setErrors(null);
      }

      if (
        (this.fromRequired && (!fromDate || !fromDate.isValid)) ||
        (this.toRequired && (!toDate || !toDate.isValid)) ||
        (this.toRequired && this.minDate && this.minDate > toDate) ||
        (this.fromRequired && this.minDate && this.minDate > fromDate) ||
        (fromDate && !fromDate.isValid) ||
        (toDate && !toDate.isValid)
      ) {
        return { invalidDateRange: true };
      } else {
        return null;
      }
    }
  }

  updateSelectionLabel(isSelectingEnd: boolean) {
    const label = isSelectingEnd ? 'Select an end date' : 'Select a start date';

    this.picker.calendarHeaderComponent.prototype.selectionLabel = label;

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  onDateChange() {
    const startDate = this.customTimeForm.get('fromDate').value;
    const endDate = this.customTimeForm.get('toDate').value;

    if (!startDate) {
      this.updateSelectionLabel(false);
    } else if (startDate && !endDate) {
      this.updateSelectionLabel(true);
    }
  }
}
