import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseMediaWatcherService } from '@fleet/fuse';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityDetailLayoutService } from '../entity-detail-layout';

@Component({
  selector: 'fleet-entity-settings-layout',
  templateUrl: './entity-settings-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitySettingsLayoutComponent implements OnInit {
  @Input() showBackButton = true;
  @Input() navigationMenu: any[] = [];
  title: string;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened$: Observable<any>;
  currentMediaAlias: string[];
  @Input() entityName: string;
  @Input() customURL: string = null;

  showHeader = true;
  isSmallScreen = false;
  @Input() hideDrawer = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    protected entityDetailLayoutService: EntityDetailLayoutService
  ) {}

  ngOnInit(): void {
    this.drawerOpened$ = this.entityDetailLayoutService.drawOpened$;
    const routeData = this._activatedRoute.snapshot.firstChild.data;
    this.title = routeData.title;
    this.showHeader = routeData.showHeader;

    this._router.events
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const routeData = this._activatedRoute.snapshot.firstChild.data;
          this.title = routeData.title;
          this.showHeader = routeData.showHeader;
          this._changeDetectorRef.markForCheck();
        }
      });

    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((media: any) => {
        this.currentMediaAlias = media.matchingAliases;
        // Set the drawerMode and drawerOpened if
        if (media.matchingAliases.includes('md')) {
          this.drawerMode = 'side';
          this.entityDetailLayoutService.setDrawOpened(true);
        } else {
          this.drawerMode = 'over';

          // this.drawerOpened = false;
          this.entityDetailLayoutService.setDrawOpened(false);
        }
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  closeDrawer() {
    this.entityDetailLayoutService.setDrawOpened(false);
    this._changeDetectorRef.markForCheck();
  }

  toggleDrawer() {
    this.entityDetailLayoutService.toggleDraw();
  }
}
