import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NetworkConditionsDisplayListModule } from '@fleet/network-shared';
import { JobColorsPipeModule, TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { BadgeModule, LetterIconModule, StatusChipModule } from '@fleet/ui';
import { JobSearchResultItemComponent } from './job-search-result-item.component';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
@NgModule({
  declarations: [JobSearchResultItemComponent],
  imports: [
    CommonModule,
    MatIconModule,

    NetworkConditionsDisplayListModule,
    TitleCaseAndCleanPipeModule,
    StatusChipModule,
    LetterIconModule,
    JobColorsPipeModule,
    BadgeModule,
    InternationalizationDatePipeModule,
  ],
  exports: [JobSearchResultItemComponent],
})
export class JobSearchResultItemModule {}
