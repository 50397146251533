import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteSearchResultsComponent } from './note-search-results.component';
import { NoResultsLabelModule } from '@fleet/shared';
import { ResponsiveElementModule } from '@fleet/layout';
import { MatTableModule } from '@angular/material/table';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { LineBreakPipeModule, TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BadgeModule, StatusChipModule } from '@fleet/ui';

@NgModule({
  declarations: [NoteSearchResultsComponent],
  imports: [
    CommonModule,
    MatTableModule,
    ResponsiveElementModule,
    NoResultsLabelModule,
    TitleCaseAndCleanPipeModule,

    InternationalizationDatePipeModule,
    LineBreakPipeModule,
    MatMenuModule,
    MatButtonModule,
    StatusChipModule,
    MatIconModule,
    BadgeModule,
  ],
  exports: [NoteSearchResultsComponent],
})
export class NoteSearchResultsModule {}
