/* eslint-disable prefer-const */
import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '@fleet/locale';
import { DateTime } from 'luxon';
@Pipe({
  name: 'searchTerms',
})
export class SearchTermsPipe implements PipeTransform {
  transform(
    value: any,
    paramLabelMap?: any,
    combine?: boolean
  ): [{ name: string; value: string; key: string; disabled?: boolean }] {
    let terms: [
      { name: string; value: string; key: string; disabled?: boolean }
    ] = [] as any;
    if (value) {
      Object.entries(value).forEach((element) => {
        if (
          element[1] !== null &&
          element[0] !== 'limit' &&
          element[0] !== 'offset'
        ) {
          let key = element[0];

          let treatedName = key.replace(/([A-Z])/g, ' $1');
          treatedName =
            treatedName.charAt(0).toUpperCase() + treatedName.slice(1);

          let value = element[1] as any;
          let chipDisabled = false;

          //test if there is a
          if (paramLabelMap && paramLabelMap[key]) {
            chipDisabled = paramLabelMap[key].disabled;

            if (paramLabelMap[key].label) {
              treatedName = paramLabelMap[key].label;
            }
            //Test if this is not a string
            if (value.length > 0 && typeof value !== 'string') {
              let x = value.map((val: any) =>
                paramLabelMap[key].find((s: any) => s.value === val)
              );
              const labelValues = value
                .map((val: any) =>
                  paramLabelMap[key].find((s: any) => s.value === val)
                )
                .map((valuelabel: any) => valuelabel.label)
                .join(', ');
              value = labelValues;
              //value is array;
            } else {
              //

              if (paramLabelMap[key].dateFormat) {
                //llets use the key value as our format for this date

                if (paramLabelMap[key].fromFormat) {
                  value = DateTime.fromFormat(value, 'yyyyLLddHHmmss')
                    .setLocale(this.localeService.getLocale())
                    .toFormat(paramLabelMap[key].dateFormat);
                } else {
                  //is date

                  if (typeof value === 'string') {
                    value = DateTime.fromISO(value)
                      .setLocale(this.localeService.getLocale())
                      .toFormat(paramLabelMap[key].dateFormat);
                  } else {
                    value = value
                      .setLocale(this.localeService.getLocale())
                      .toFormat(paramLabelMap[key].dateFormat);
                  }
                }
              } else {
                //not a date

                const foundParam = paramLabelMap[key].find(
                  (s: any) => s.value === value
                );

                if (foundParam) {
                  value = foundParam.label;
                }
              }
            }
          }
          let term = {
            name: treatedName,
            value: value,
            key: key,
            disabled: chipDisabled,
          };
          terms.push(term);
        }
      });

      if (combine) {
        return this.combineDates(terms);
      } else {
        return terms;
      }
    }

    return null;
  }

  combineDates(
    terms: [{ name: string; value: string; key: string }]
  ): [{ name: string; value: string; key: string }] {
    let i = terms.findIndex((t) => t.key === 'fromDate');
    let j = terms.findIndex((t) => t.key === 'toDate');

    if (i > -1 && j > -1) {
      let fromDate = terms[i];
      let toDate = terms[j];

      let newTerm = {
        name: 'To & From',
        value: fromDate.value + ' - ' + toDate.value,
        key: fromDate.key + '-' + toDate.key,
      };

      terms.push(newTerm);
      terms.splice(
        terms.findIndex((t) => t.key === 'fromDate'),
        1
      );
      terms.splice(
        terms.findIndex((t) => t.key === 'toDate'),
        1
      );
    }

    //fromDate pairs with toDate

    return terms;
  }

  constructor(private localeService: LocaleService) {}
}
