/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';

import { HttpResponse } from '@angular/common/http';
import { OrganisationApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import { ApiResponse, IssueModel, OrganisationStaffModel } from '@fleet/model';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'fleet-organisation-staff-autocomplete',
  templateUrl: './organisation-staff-autocomplete.component.html',
  styleUrls: ['./organisation-staff-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganisationStaffAutocompleteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class OrganisationStaffAutocompleteComponent implements OnInit {
  _organisationId: string;
  @Input() set organisationId(value: string) {
    this._organisationId = value;
    if (value) {
      this.staff$ = EMPTY;
      this.wireAutoComplete();
    }
  }
  get organisationId() {
    return this._organisationId;
  }

  @Input() organisationGroupId: string;
  @Input() placeHolder: any;
  @Input() label: string;
  @Input() prefixIcon = 'search';
  @ViewChild('input') input: ElementRef;
  @Input() initialEmptySearch = false;
  ngControl: NgControl;
  staff$: Observable<any>;
  searching: boolean;
  issues: IssueModel[];
  searchControl: UntypedFormControl = new UntypedFormControl();
  @Output() organisationStaffSelected = new EventEmitter();
  searchComplete = false;
  constructor(
    private organisationApiService: OrganisationApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.ngControl = this.injector.get(NgControl);
      if (this.ngControl?.control != null) {
        this.searchControl.setValidators(this.ngControl.control.validator);
        this.searchControl.updateValueAndValidity({
          emitEvent: false,
          onlySelf: true,
        });
        this.ngControl.control.statusChanges.subscribe((change: any) => {
          const validators = [staffValidator()];
          if (this.ngControl.control.validator) {
            validators.push(this.ngControl.control.validator);
          }
          this.searchControl.setValidators(validators);

          if (this.ngControl.control.touched) {
            this.searchControl.markAsTouched();
          }
          this.searchControl.updateValueAndValidity({
            emitEvent: false,
            onlySelf: true,
          });
          this.changeDetectorRef.markForCheck();
        });
      }
    });
  }
  ngOnInit(): void {}

  wireAutoComplete() {
    this.staff$ = this.searchControl.valueChanges.pipe(
      debounceTime(300),

      tap(() => {
        this.searching = true;
        this.issues = [];
        this.searchComplete = false;
        this.changeDetectorRef.markForCheck();
      }),
      switchMap((value) => {
        if (value && value !== 'not-found' && typeof value === 'string') {
          const params = { limit: 25, offset: 0, name: value } as any;
          if (this.organisationGroupId) {
            params['organisationGroupId'] = this.organisationGroupId;
          }
          if (value === 'empty-search') {
            delete params['name'];

            this.searchControl.setValue(null, { emitEvent: false });
          }
          return this.organisationApiService
            .searchStaff(params, this.organisationId)
            .pipe(
              map(
                (
                  resp:
                    | HttpResponse<ApiResponse<OrganisationStaffModel[]>>
                    | any
                ) => {
                  this.searchComplete = true;
                  this.searching = false;
                  this.changeDetectorRef.markForCheck();
                  return resp.body.data;
                }
              ),
              catchError((issues: IssueModel[]) => {
                this.issues = issues;
                this.searchComplete = false;
                this.searching = false;
                this.changeDetectorRef.markForCheck();
                return [];
              })
            );
        } else {
          if (value === '') {
            this.searchControl.setValue(null, { emitEvent: false });
            this.onChange(null);
          }
          this.searching = false;
          this.searchComplete = false;
          this.changeDetectorRef.markForCheck();

          return [];
        }
      })
    );
  }

  displayFn(staff?: OrganisationStaffModel): string | undefined {
    return staff && staff.firstName
      ? staff.firstName + ' ' + staff.lastName
      : '';
  }
  onChange: any = () => {};
  onTouched: any = () => {};
  @Input() mode: string;
  value: OrganisationStaffModel;
  onStaffSelected(result: any) {
    if (result) {
      this.value = result?.option?.value;
      this.onChange(
        this.mode !== 'id' ? this.value : this.value.organisationStaffId
      );
      this.organisationStaffSelected.emit(this.value);
      this.onTouched();
    }
  }
  writeValue(value: OrganisationStaffModel) {
    if (value) {
      if (!value?.organisationId || typeof value === 'string') {
        this.searching = true;
        this.organisationApiService
          .getStaff(
            typeof value === 'string' ? value : value.organisationStaffId,
            this.organisationId
          )
          .pipe(
            map((resp: ApiResponse<OrganisationStaffModel> | any) => resp.data),
            catchError((error) => (this.issues = error)),
            tap(() => (this.searching = false))
          )
          .subscribe((r: OrganisationStaffModel) => {
            this.setNativeElementValue(r);
            this.searchControl.setValue(r, { emitEvent: false });
            this.onStaffSelected({ option: { value: r } });
            this.changeDetectorRef.markForCheck();
          });
      } else {
        this.setNativeElementValue(value);
        this.onStaffSelected({ option: { value: value } });
        this.changeDetectorRef.markForCheck();
      }
    } else {
      this.searchControl.reset();
      this.changeDetectorRef.markForCheck();
    }
  }
  private setNativeElementValue(value: OrganisationStaffModel) {
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.value = value
          ? value?.firstName + ' ' + value?.lastName
          : '';
      }
    }, 1);
  }
  onInput(value: any) {
    this.onChange(null);
    this.onTouched();
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  validate(c: UntypedFormControl) {
    return c.value
      ? null
      : c.touched
      ? {
          staffAutocomplete: {
            message: 'Please ensure search and select a staff from the list',
          },
        }
      : null;
  }

  inputClick(eventTarget: any) {
    if (
      eventTarget.value === '' &&
      (this.searchControl.value === '' || this.searchControl.value == null)
    ) {
      this.searchControl.setValue('empty-search');
      this.searchControl.updateValueAndValidity({ emitEvent: false });
    } else {
      eventTarget.select();
    }
  }
}

function staffValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (control?.value?.organisationStaffId) {
      return null;
    } else {
      return {
        staffNotSelected: 'Please ensure you search and select a staff member',
      };
    }
  };
}
