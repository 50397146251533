import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-no-results-label',
  templateUrl: './no-results-label.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultsLabelComponent implements OnInit {
  @Input() type: string;
  constructor() {}

  ngOnInit(): void {}
}
