import { FLEET_VERSION } from '@fleet/version';
export const environment = {
  production: true,
  hmr: false,
  xApiKey: 'tp_e30de5b4e27f5451e6063ee0010244d75677',
  host: 'https://preprod-tapi-test4.fleet.com.au',
  googleApiKey: 'AIzaSyDOOk5iG_N8crmcgz_fvooLtY3C9hSIVwA',
  braintreeWebClientToken: 'sandbox_bnnjgsp2_37d8t9fxb3ydvmxs',
  minTokenExpiry: 60,
  fleetProduct: 'TRAVELLER',
  envName: 'test4',
  socketHost: 'wss://socket-awspreprod.ingogo.mobi/socket',
  socketTopicPrefix: '/topic/test4/core/v3/',

  socketAcknowledge:
    'https://socket-awspreprod.ingogo.mobi/socket/client/{{clientId}}/acknowledge/',
  tasHost: 'https://preprod-svc-tas.fleet.com.au/tas/api/test4/v1',
  firebaseConfig: {
    apiKey: 'AIzaSyCh7s0kGmCmJrt4QudOz2TPlt3FcsydoYk',
    authDomain: 'fleet-hub-pre-prod.firebaseapp.com',
    databaseURL: 'https://fleet-hub-pre-prod.firebaseio.com',
    projectId: 'fleet-hub-pre-prod',
    storageBucket: 'fleet-hub-pre-prod.appspot.com',
    messagingSenderId: '724478988396',
    appId: '1:724478988396:web:e5f24b530b21bb449b8849',
    measurementId: 'G-7LMMM8CN40',
  },
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  version: FLEET_VERSION,
};
