import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobConditionSelectorComponent } from './job-condition-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { NetworkConditionsModule } from '@fleet/network-shared';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [JobConditionSelectorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NetworkConditionsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  exports: [JobConditionSelectorComponent],
})
export class JobConditionSelectorModule {}
