import { NgModule } from '@angular/core';

import { JobTimePipe } from './job-time.pipe';
import { JobStartTimeComponent } from './job-start-time/job-start-time.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
// import { DateTimePickerModule, TimeInputModule } from '@fleet/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { JobIssuesModule } from '../job-issues/job-issues.module';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TimeControlModule } from '@fleet/custom-controls';

@NgModule({
  declarations: [JobStartTimeComponent, JobTimePipe],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,

    ReactiveFormsModule,
    JobIssuesModule,

    MatDatepickerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    TimeControlModule,
  ],
  exports: [JobTimePipe, JobStartTimeComponent],
})
export class JobTimeModule {}
