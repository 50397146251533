import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { DateTime } from 'luxon';
import { Subject } from 'rxjs';

import { ActiveJobDisplay, JobStatusUpdateAndProgress } from '@fleet/model';
import { createActiveJobDisplay } from '@fleet/utilities';

@Component({
  selector: 'fleet-active-job-card',
  templateUrl: './active-job-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ActiveJobCardComponent implements OnInit {
  fleetProduct;
  stopTimer = new Subject();
  @Input() isCard = true;
  slaCountDown$ = new Subject();
  @Input() paddingClasses = 'py-3 px-4';
  private _unsubscribeAll: Subject<any> = new Subject();
  display: ActiveJobDisplay;
  _job: JobStatusUpdateAndProgress;
  @Input() set job(value: JobStatusUpdateAndProgress) {
    this._job = value;
    if (value) {
      this.display = createActiveJobDisplay(value);
      this.changeDetectorRef.markForCheck();
    }
  }
  get job() {
    return this._job;
  }

  @Output() selectJob = new EventEmitter();
  @Output() dismiss = new EventEmitter();
  @Input() showActionButton = false;
  @Input() set changeIndex(value: number) {
    //check job status first only do for dispatched and assigned

    if (
      this.job.latestStatusUpdate.status === 'DISPATCHED' ||
      this.job.latestStatusUpdate.status === 'ASSIGNED' ||
      this.job.latestStatusUpdate.status === 'ABANDONED'
    ) {
      this.job = Object.assign({}, this.job);
    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  get dateTime() {
    return DateTime;
  }
  ngOnInit(): void {}

  dismissJob(event$: any) {
    event$.stopPropagation();
    this.dismiss.emit(this.job.latestStatusUpdate.jobId);
  }
}
