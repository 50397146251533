import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@fleet/auth';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class TravellerAuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const redirectUrl = state.url === '/logout' ? '/' : state.url;
    return this.authService.checkAuth().pipe(
      switchMap((authenticated: boolean) => {
        if (!authenticated) {
          if (state.url === '/guest/job/new') {
            return of(true);
          } else {
            this.router.navigate(['/auth/welcome']);
            return of(false);
          }
        } else {
          if (this.authService.userType === 'TRAVELLER') {
            return of(true);
          } else if (this.authService.decodedToken?.jobId) {
            const jobRoute =
              '/guest/job/' + this.authService.decodedToken.jobId + '/map';
            if (state.url === jobRoute) {
              return of(true);
            } else {
              localStorage.removeItem('access_token');
              this.router.navigate(['/auth/welcome']);
              return of(false);
            }
          }
          return of(true);
        }
        return of(true);
      })
    );
  }
}
