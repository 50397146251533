import { IssueModel } from './../api-model';
import { PageData } from './page-data';

export interface BaseSearchState<T> {
  data: T[];
  filteredData: T[];
  pageData: PageData;
  searching: boolean;
  error: string;
  issues: IssueModel[];
  displayColumns: string[];
  displayMode: 'table' | 'list';
  selectedPreview?: T;
  parentEntity?: any;
  parentEntityId?: string;
  searchParamLabelMap?: any;
}

export const initialBaseSearchState: BaseSearchState<any> = {
  data: [],
  filteredData: [],
  pageData: null,
  searching: false,
  error: null,
  issues: [],
  displayColumns: [],
  displayMode: 'table',
};
