import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-no-permission-label',
  templateUrl: './no-permission-label.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoPermissionLabelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
