import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobTimelineCombinedPipe } from './job-timeline-combined.pipe';

@NgModule({
  declarations: [JobTimelineCombinedPipe],
  imports: [CommonModule],
  exports: [JobTimelineCombinedPipe],
})
export class JobTimelineCombinedPipeModule {}
