import { Pipe, PipeTransform } from '@angular/core';
import { IssueModel, JobState } from '@fleet/model';

export const paymentFields = ['secondaryPaymentMethod', 'primaryPaymentMethod'];
export const locationFields = ['startLocation', 'endLocation'];
export const timeFields = ['startTime'];
export const locationAndStartTimeFields = [
  'startLocation',
  'endLocation',
  'startTime',
];

export interface IssueDisplay {
  issue: IssueModel;
  display: boolean;
  field: string;
}
@Pipe({
  name: 'jobIssues',
})
export class JobIssuesPipe implements PipeTransform {
  transform(value: JobState, ...args: unknown[]): IssueModel[] {
    if (!args || !value) return [];

    if (args[0] === 'locations') {
      return this.filterByFields(value.issues, locationFields);
      //   .map((issue) => {
      //   if (issue.fields.includes('startLocation')) {
      //     let display = true;
      //     if (value.job.jobDetail.startLocation != value.previousJobState.jobDetail.startLocation) {
      //       display = false;
      //     }
      //     return <IssueDisplay>{
      //       issue: issue,
      //       display: display,
      //       field: 'startLocation',
      //     };
      //   } else if (issue.fields.includes('endLocation')) {
      //     let display = true;
      //     if (value.job.jobDetail.endLocation != value.previousJobState.jobDetail.endLocation) {
      //       display = false;
      //     }
      //     return <IssueDisplay>{
      //       issue: issue,
      //       display: display,
      //       field: 'endLocation',
      //     };
      //   }
      //   return <IssueDisplay>{
      //     issue: issue,
      //     display: true,
      //   };
      // });
    } else if (args[0] === 'payments') {
      if (value.job.payment) {
        return this.filterByFields(value.issues, paymentFields);
        //   .map((issue) => {
        //   if (issue.fields.includes('primaryPaymentMethod')) {
        //     let display = true;
        //     if (
        //       (value.job.payment.primarySale &&
        //         value.previousJobState.payment.primarySale &&
        //         value.job.payment.primarySale.paymentMethod.paymentMethodId !=
        //           value.previousJobState.payment.primarySale.paymentMethod.paymentMethodId) ||
        //       (value.job.payment.primarySale && !value.previousJobState.payment.primarySale)
        //     ) {
        //       display = false;
        //     }
        //     return <IssueDisplay>{
        //       issue: issue,
        //       display: display,
        //       field: 'primaryPaymentMethod',
        //     };
        //   } else if (issue.fields.includes('secondaryPaymentMethod')) {
        //     let display = true;
        //     if (
        //       (value.job.payment.secondarySale &&
        //         value.previousJobState.payment.secondarySale &&
        //         value.job.payment.secondarySale.paymentMethod.paymentMethodId !=
        //           value.previousJobState.payment.secondarySale.paymentMethod.paymentMethodId) ||
        //       (value.job.payment.secondarySale && !value.previousJobState.payment.secondarySale)
        //     ) {
        //       display = false;
        //     }
        //     return <IssueDisplay>{
        //       issue: issue,
        //       display: display,
        //       field: 'secondaryPaymentMethod',
        //     };
        //   }
        //   return <IssueDisplay>{
        //     issue: issue,
        //     display: true,
        //   };
        // });
      } else {
        return [];
      }
    } else if (args[0] === 'startTime') {
      return this.filterByFields(value.issues, timeFields);
      // .map((issue) => {
      // let display = true;
      // if (value.job.jobDetail.startTime != value.previousJobState.jobDetail.startTime) {
      //   display = false;
      // }
      // return <IssueDisplay>{
      //   issue: issue,
      //   display: display,
      // };
      //});
    } else if (args[0] === 'no-fields') {
      return value.issues.filter(
        (s) =>
          s.fields === null || s.fields === undefined || s.fields.length == 0
      );
    }

    return [];
  }

  filterByFields(issues: IssueModel[], fields: string[]) {
    if (!issues) {
      return [];
    }
    return issues.filter((issue) => {
      let found = false;
      if (issue.fields) {
        issue.fields.forEach((field) => {
          if (fields.includes(field)) {
            found = true;
          }
        });
      }
      return found;
    });
  }
}
