import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeAu from '@angular/common/locales/en-AU';
import localePe from '@angular/common/locales/es-PE';
import localeUs from '@angular/common/locales/es-US';
import localeJp from '@angular/common/locales/ja';
import localeSg from '@angular/common/locales/en-SG';
import localeZh from '@angular/common/locales/zh';
import localeGB from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeCaEn from '@angular/common/locales/en-CA';
import localeCaFr from '@angular/common/locales/fr-CA';
import localeChDe from '@angular/common/locales/de-CH';
import localeChFr from '@angular/common/locales/fr-CH';
import localeChIt from '@angular/common/locales/it-CH';
import localeIn from '@angular/common/locales/hi';
import localeBr from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeZa from '@angular/common/locales/en-ZA';
import localeNz from '@angular/common/locales/en-NZ';
import localeMx from '@angular/common/locales/es-MX';
import { CurrencyMaskConfig, NetworkGroupModel } from '@fleet/model';
import { currencyConfigs } from './locale-data';
// import { NetworkGroupService } from '@fleet/network-group';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';

export const DATEPICKER_FORMAT = {
  parse: {
    dateInput: 'D',
  },
  display: {
    dateInput: 'D',
    monthYearLabel: 'LLL yyyy',
    dateA11yLabel: 'fff',
    monthYearA11yLabel: 'LLLL yyyy',
  },
};

//This is for the sake of DEFAULTING to this if it does not get set.
registerLocaleData(localeAu);

// import { CurrencyMaskConfig } from 'libs/shared/src/lib/ngx-currency/currency-mask.config';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  // In English-speaking locales such as American (en - US) or Australian(en - AU) English,
  // the 12 - hour clock format including AM / PM is commonly used,
  // so Luxon displays the AM / PM part when formatting times.

  // In contrast, many other cultures, including Japanese(ja - JP),
  // prefer the 24 - hour clock format for most contexts.As a result,
  // the AM / PM part is not typically displayed in these locales when using standard
  //   time formatting options.

  // currentLocale = 'en-AU';
  // currencyCode = 'AUD';
  // symbol = '$';

  currentLocale: BehaviorSubject<string> = new BehaviorSubject('en-AU');
  currencyCode: BehaviorSubject<string> = new BehaviorSubject('AUD');
  symbol: BehaviorSubject<string> = new BehaviorSubject('$');

  get localeAndCurrency$() {
    return combineLatest([
      this.currentLocale.asObservable(),
      this.currencyCode.asObservable(),
    ]).pipe(
      map(([locale, currencyCode]) => ({
        locale: locale,
        currencyCode: currencyCode,
      }))
    );
  }

  currencyMaskConfig: BehaviorSubject<CurrencyMaskConfig> =
    new BehaviorSubject<CurrencyMaskConfig>({
      align: 'right',
      allowNegative: true,
      decimal: '.',
      precision: 2,
      prefix: '$ ',
      thousands: ',',
      suffix: '',
      nullable: false,
      allowZero: false,
      label: 'Default',
      currencyCode: 'AUD',
    });

  constructor() {}

  setLocale(newLocale: string) {
    switch (newLocale) {
      case 'fr-FR':
        registerLocaleData(localeFr);
        break;
      case 'en-AU':
        registerLocaleData(localeAu);
        break;
      case 'en-US':
        registerLocaleData(localeUs);
        break;
      case 'es-PE':
        registerLocaleData(localePe);
        break;
      case 'ja-JP':
        registerLocaleData(localeJp);
        break;
      case 'en-GB':
        registerLocaleData(localeGB);
        break;
      case 'en-SG':
        registerLocaleData(localeSg);
        break;
      case 'zh-CN':
        registerLocaleData(localeZh);
        break;
      case 'de-DE':
        registerLocaleData(localeDe);
        break;
      case 'es-ES':
        registerLocaleData(localeEs);
        break;
      case 'en-CA':
        registerLocaleData(localeCaEn);
        break;
      case 'fr-CA':
        registerLocaleData(localeCaFr);
        break;
      case 'de-CH':
        registerLocaleData(localeChDe);
        break;
      case 'fr-CH':
        registerLocaleData(localeChFr);
        break;
      case 'it-CH':
        registerLocaleData(localeChIt);
        break;
      case 'hi-IN':
        registerLocaleData(localeIn);
        break;
      case 'pt-BR':
        registerLocaleData(localeBr);
        break;
      case 'ru-RU':
        registerLocaleData(localeRu);
        break;
      case 'en-ZA':
        registerLocaleData(localeZa);
        break;
      case 'en-NZ':
        registerLocaleData(localeNz);
        break;
      case 'es-MX':
        registerLocaleData(localeMx);
        break;
      default:
        registerLocaleData(localeUs);
        break;
    }

    this.currentLocale.next(newLocale);
  }

  getLocale(): string {
    return this.currentLocale.value;
  }

  setCurrency(currency: string) {
    this.currencyCode.next(currency);
    this.symbol.next(
      (
        currencyConfigs.find((config) => config.currencyCode === currency)
          ?.prefix || ''
      ).trim()
    );
    const currentCurrency = currencyConfigs.find(
      (config) => config.currencyCode === currency
    );

    this.setCurrenctMaskConfig(currentCurrency);
  }

  getCurrency() {
    return this.currencyCode.value;
  }

  getCurrencyMaskConfig() {
    return this.currencyMaskConfig.value;
  }

  setCurrenctMaskConfig(config: CurrencyMaskConfig) {
    this.currencyMaskConfig.next(config);
  }

  getSymbol() {
    return this.symbol.value;
  }
}
