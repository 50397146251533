import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TicketSearchContainerModule } from './components/ticket-search-container/ticket-search-container.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, TicketSearchContainerModule],
  exports: [TicketSearchContainerModule],
})
export class TicketSearchModule {}
