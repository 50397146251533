import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, IssueModel, VerificationModel } from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { catchError } from 'rxjs/operators';
import { AuthApiService } from '../auth/auth-api.service';
import { paths } from '@fleet/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VerificationApiService {
  nonAuthorisedHttp: HttpClient;
  headers: HttpHeaders;
  host: string;

  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    @Inject('env') env: any
  ) {
    this.headers = new HttpHeaders({
      'fleet-channel': 'WEB',
      'fleet-product': env.fleetProduct,
    });
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.verify;
  }

  getInvitation(activationKey: string) {
    return this.nonAuthorisedHttp
      .get(`${this.host}/invitation/${activationKey}`, {
        headers: this.headers,
      })
      .pipe(catchError(handleApiError));
  }

  sendEmailActivationCode(payload: any) {
    return this.nonAuthorisedHttp
      .post(`${this.host}/email`, payload, { headers: this.headers })
      .pipe(catchError(handleApiError));
  }

  verifyEmail(
    verificationModel: VerificationModel
  ): Observable<ApiResponse<VerificationModel> | any> {
    return this.nonAuthorisedHttp
      .post(`${this.host}/email`, verificationModel, { headers: this.headers })
      .pipe(catchError(handleApiError));
  }

  verifyEmailWithJWT(
    verificationModel: VerificationModel
  ): Observable<ApiResponse<VerificationModel> | any> {
    const jwt = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + jwt,
      'fleet-channel': 'WEB',
      'fleet-product': this.headers.get('fleet-product'),
    });
    return this.http
      .post(`${this.host}/email`, verificationModel, { headers: headers })
      .pipe(catchError(handleApiError));
  }

  verify(verificationModel: VerificationModel): Observable<any> {
    return this.nonAuthorisedHttp
      .post(`${this.host}`, verificationModel, { headers: this.headers })
      .pipe(catchError(handleApiError));
  }

  verifyPhoneNumber(
    payload: any,
    includeJwt: boolean
  ): Observable<ApiResponse<VerificationModel> | any> {
    let headers = this.headers;
    if (includeJwt) {
      const jwt = localStorage.getItem('access_token');
      headers = new HttpHeaders({
        Authorization: 'Bearer ' + jwt,
        'fleet-channel': 'WEB',
        'fleet-product': this.headers.get('fleet-product'),
      });
    }
    return this.http
      .post(`${this.host}/phoneNumber`, payload, { headers: headers })
      .pipe(catchError(handleApiError));
  }

  verifyCreditCard(
    payload: any
  ): Observable<ApiResponse<VerificationModel> | any> {
    return this.http
      .post(`${this.host}/creditCard`, payload)
      .pipe(catchError(handleApiError));
  }
}
