import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { ResponsiveElementModule } from '@fleet/layout';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { StatusChipModule } from '@fleet/ui';
import { TicketSearchResultsComponent } from './ticket-search-results.component';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';

@NgModule({
  declarations: [TicketSearchResultsComponent],
  imports: [
    CommonModule,
    MatTableModule,
    TitleCaseAndCleanPipeModule,
    MatButtonModule,
    MatIconModule,
    ResponsiveElementModule,

    MatMenuModule,
    StatusChipModule,
    InternationalizationDatePipeModule,
  ],
  exports: [TicketSearchResultsComponent],
})
export class TicketSearchResultsModule {}
