import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { handleApiError } from '@fleet/utilities';
import { encodeParams } from '@fleet/utilities';
import { paths } from '@fleet/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApiResponse, ServiceClassModel, ServiceLineModel } from '@fleet/model';
@Injectable({
  providedIn: 'root',
})
export class ServiceApiService {
  host;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.service;
  }

  searchServices(params: any): Observable<any> {
    return this.http
      .get(`${this.host}`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchPricingMethod(serviceId: string, pricingMethodId: string) {
    return this.http
      .get(`${this.host}/${serviceId}/${pricingMethodId}`)
      .pipe(catchError(handleApiError));
  }

  searchServiceClasses(
    params: any
  ): Observable<ApiResponse<ServiceClassModel[]>> | any {
    return this.http
      .get(`${this.host}/class`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchServiceLines(
    classId: string,
    params: any
  ): Observable<ApiResponse<ServiceLineModel[]>> | any {
    return this.http
      .get(`${this.host}/class/${classId}/line`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchPricingMethods(params: any): Observable<any> {
    return this.http
      .get(`${this.host}/pricingMethod`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }
}
