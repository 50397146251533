import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarFormLayoutComponent } from './sidebar-form-layout.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [SidebarFormLayoutComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    CdkScrollableModule,
  ],
  exports: [SidebarFormLayoutComponent],
})
export class SidebarFormLayoutModule {}
