import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-sidebar-form-layout',
  templateUrl: './sidebar-form-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarFormLayoutComponent implements OnInit {
  @Output() closeSidebar = new EventEmitter();
  @Input() title: string;
  @Input() backgroundClass: string;
  constructor() {}

  ngOnInit(): void {}
}
