import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationFormComponent } from './location-form.component';
import { ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { LocalityAutocompleteModule } from './../locality-autocomplete/locality-autocomplete.module';
import { RegionSelectorModule } from './../region-selector/region-selector.module';

@NgModule({
  declarations: [LocationFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LocalityAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    RegionSelectorModule,
  ],
  exports: [LocationFormComponent],
})
export class LocationFormModule {}
