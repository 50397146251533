import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class JwtHelperService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }
    return decodeURIComponent(encodeURI(window.atob(output)));
  }

  decodeToken(token: string): any {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  isTokenValid(token: any) {
    if (!token) {
      return false;
    }
    const decodedToken = this.decodeToken(token);

    const now = DateTime.local();
    const epochTime = DateTime.fromMillis(decodedToken.exp);
    const diffInMinutes = now.diff(epochTime, 'minutes').minutes;
    console.log('Diff in minutes: ' + diffInMinutes);
    if (diffInMinutes > 0) {
      return true;
    }
    return false;
  }

  getUserRoles(decodedToken: any) {
    return decodedToken.roles;
  }

  getOrganisationId(decodedToken: any) {
    if (decodedToken.organisationId) {
      return decodedToken.organisationId;
    }
    return null;
  }

  getOrganisationUserId(decodedToken: any) {
    if (decodedToken.organisationUserId) {
      return decodedToken.organisationUserId;
    }
    return null;
  }

  isUserSuperUser(jwtToken: any) {
    if (jwtToken) {
      const decodedToken = this.decodeToken(jwtToken);

      if (decodedToken) {
        const res = decodedToken.roles.find((x: any) => x.name == 'SUPERUSER');
        if (res) {
          return true;
        } else {
          return false;
        }
      }
    }

    return null;
  }

  // doesUserHaveRoleOfType();

  doesUserHaveRoleOfType(rolesToCheck: string[], jwtToken: any) {
    if (jwtToken && rolesToCheck) {
      const decodedToken = this.decodeToken(jwtToken);

      let exists;
      if (decodedToken.roles) {
        decodedToken.roles.forEach((role: any) => {
          exists = rolesToCheck.find((r) => r == role.name);
        });
      }

      if (exists) {
        return true;
      }
    }
    return false;
  }
}
