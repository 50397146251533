import {
  AcknowledgementModel,
  IssueModel,
  JobModel,
  LocationModel,
} from './../api-model';
import { DateTime } from 'luxon';
export interface JobLocations {
  startLocation: LocationModel;
  endLocation: LocationModel;
  waypoints: LocationModel[];
}

export interface JobConfiguration {
  isStartLocationLocked: boolean;
}

export interface LocationsAndStartTimeState {
  locations: JobLocations;
  startTime: DateTime;
}

export interface JobState {
  job: JobModel;
  loading: boolean;
  saving?: boolean;
  dispatching?: boolean;
  error: any;
  issues: IssueModel[];
  syncedFrom: JobSyncedFrom;
  initialised: boolean;
  jobConfiguration: JobConfiguration;
  previousJobState: JobModel;
  changed: boolean;
  travelPolicyIssues: IssueModel[];
  acknowledgementIssues: IssueModel[];
  acknowledgement: AcknowledgementModel;
  secondaryPaymentRequired: boolean;
}

export enum JobSyncedFrom {
  CLONE = 'CLONE',
  BOOK_AGAIN = 'BOOK_AGAIN',
  JOB_TEMPLATE = 'JOB_TEMPLATE',
  JOB_ID = 'JOB_ID',
}
