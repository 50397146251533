<div class="flex flex-col" *ngIf="mode === 'list'; else select">
  <div [ngClass]="{ 'text-sm font-light': inSearchForm }">Job Statuses</div>

  <mat-selection-list
    [formControl]="formControl"
    class="grid grid-cols-2 condensed-leading-checkbox-selection-list"
  >
    <mat-list-option
      [disabled]="formControl.disabled"
      class="text-sm font-medium"
      checkboxPosition="before"
      *ngFor="let option of OPTIONS"
      [value]="option"
    >
      {{ option | titleCaseAndClean }}
    </mat-list-option>
  </mat-selection-list>
</div>

<ng-template #select>
  <div class="flex flex-row gap-3 w-full">
    <mat-form-field class="w-full">
      <mat-label [ngClass]="{ 'text-sm font-light': inSearchForm }">
        Job Statuses
      </mat-label>
      <mat-select multiple [formControl]="formControl" placeholder="Statuses">
        <mat-option *ngFor="let option of OPTIONS" [value]="option">
          {{ option | titleCaseAndClean }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>
