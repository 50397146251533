import { Injectable } from '@angular/core';
import { GoogleMap, MapPolyline } from '@angular/google-maps';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { decode } from './heremaps-flexible-polyline';
import { decodeOSRM, decodePathFromEncoded } from './map-utilities';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { GoogleMapsApiService } from '@fleet/api';
import { map } from 'rxjs/operators';
import { circleMarkerUrl } from './components/markers/marker-svg/marker-svg';
import { MapService } from './map.service';

@Injectable({
  providedIn: 'root',
})
export class MapPolylineService {
  // polygon: BehaviorSubject<any> = new BehaviorSubject(null);

  polygon$: Observable<any>;
  decodePolyline: BehaviorSubject<any> = new BehaviorSubject([]);
  polygonMarkers$: Observable<any[]>;
  polyline$: Observable<any>;
  selectedPolylinePathMarker$: Observable<any>;
  selectedPolylinePath: BehaviorSubject<any> = new BehaviorSubject(null);
  decodedPolylineWithSelected$: Observable<any>;
  editable: BehaviorSubject<boolean> = new BehaviorSubject(
    coerceBooleanProperty(false)
  );
  googleMap: GoogleMap;
  mapPolyline: MapPolyline;

  constructor(
    private googleApiService: GoogleMapsApiService,
    private mapService: MapService
  ) {
    this.polygon$ = combineLatest([
      this.decodePolyline.asObservable(),
      this.googleApiService.isApiLoaded$,
    ]).pipe(
      map(([decodedPolyLine, isLoaded]) => {
        if (isLoaded) {
          if (decodedPolyLine?.length > 0) {
            const cleanedPolylinePaths = decodedPolyLine.map(
              (path: any) => new google.maps.LatLng(path[0], path[1])
            );

            console.log('poly chage');
            return <google.maps.PolygonOptions>{
              paths: cleanedPolylinePaths,
              strokeOpacity: 0,
              strokeWeight: 0,
              fillColor: 'red',
              fillOpacity: 0.4,
            };
          } else return null;
        }
        return null;
      })
    );

    this.polyline$ = combineLatest([
      this.decodePolyline.asObservable(),
      this.selectedPolylinePath.asObservable(),
      this.googleApiService.isApiLoaded$,
      this.editable.asObservable(),
    ]).pipe(
      map(([decodedPolyLine, selectedPath, isLoaded, editable]) => {
        if (isLoaded) {
          if (decodedPolyLine?.length > 0) {
            const cleanedPolylinePaths = decodedPolyLine.map(
              (path: any) => new google.maps.LatLng(path[0], path[1])
            );

            return <google.maps.PolylineOptions>{
              path: cleanedPolylinePaths,

              editable: editable,
              draggable: false,
            };
          } else return null;
        }
        return null;
      })
    );

    this.selectedPolylinePathMarker$ = this.selectedPolylinePath.pipe(
      map((path: any) => {
        if (path) {
          return new google.maps.Marker({
            position: {
              lat: +path[0],
              lng: +path[1],
            },
            // clickable: true,
            // draggable: true,
            // optimized: true,
            icon: circleMarkerUrl('#fcba03'),
            anchor: new google.maps.Point(20, 30),
          } as google.maps.MarkerOptions);
        }
        return null;
      })
    );

    this.decodedPolylineWithSelected$ = combineLatest([
      this.decodePolyline.asObservable(),
      this.selectedPolylinePath.asObservable(),
    ]).pipe(
      map(([decodedPolyline, selected]) => {
        let i = 0;
        return decodedPolyline.map((path: any) => {
          if (selected === path) {
            return { path: path, selected: true };
          }
          return { path: path, selected: false };
        });
      })
    );

    // this.polygon$.subscribe({
    //   next: (polygon: any) => {
    //     if (polygon) {
    //       const bounds=
    //     }
    //   },
    // });

    this.polygonMarkers$ = combineLatest([
      this.decodePolyline.asObservable(),
      // this.selectedPolylineMarker.asObservable(),
      this.selectedPolylinePath.asObservable(),
      this.googleApiService.isApiLoaded$,
      this.editable.asObservable(),
    ]).pipe(
      map(([decodedPolyLine, selectedPath, isLoaded, editable]) => {
        if (isLoaded && decodedPolyLine?.length > 0) {
          const decodedWithSelected = [...decodedPolyLine];
          const markers = decodedPolyLine.map((path: any) => {
            const position = {
              lat: +path[0],
              lng: +path[1],
            };
            // const markerPosition = selectedMarker?.getPosition();
            const selected =
              JSON.stringify(selectedPath) === JSON.stringify(path);
            const marker = new google.maps.Marker({
              position: position,
              clickable: true,
              draggable: editable,
              optimized: true,
              icon: selected
                ? circleMarkerUrl('#ef4444')
                : circleMarkerUrl('#fcba03'),
              anchor: new google.maps.Point(15, 15),
            } as google.maps.MarkerOptions);
            if (path.length > 2) {
              //has time stamp
              marker.set('timestamp', path[2]);
            }
            marker.set('path', JSON.stringify(path));
            marker.set('selected', selected);
            return marker;
          });
          console.log(markers);
          return markers;
        } else {
          return [];
        }
      })
    );
  }

  // get polygon$() {
  //   return this.polygon.asObservable();
  // }

  get decodedPolyline$() {
    return this.decodePolyline.asObservable();
  }

  setEditable(editable: boolean) {
    this.editable.next(editable);
  }

  setPolylineFromEncoded(encodedPolyline: any, version: string) {
    let decodedPolyLinePaths;

    if (version === 'V2') {
      const decodedPolyLineWithTimestamp: any =
        decode(encodedPolyline).polyline;
      this.decodePolyline.next(decodedPolyLineWithTimestamp);
    } else {
      if (version === 'V1-5') {
        const decodedPolyline = decodeOSRM(encodedPolyline, 5);
        this.decodePolyline.next(decodedPolyline);
      } else if (version === 'V1-6') {
        const decodedPolyline = decodeOSRM(encodedPolyline, 6);
        this.decodePolyline.next(decodedPolyline);
      } else {
        //version 1

        decodedPolyLinePaths = decodePathFromEncoded(encodedPolyline).map(
          (latLng: google.maps.LatLng) => [latLng.lat(), latLng.lng()]
        );
        this.decodePolyline.next(decodedPolyLinePaths);
      }
    }
    this.selectedPolylinePath.next(null);

    // this.polygon.next(polygon);
    const bounds = new google.maps.LatLngBounds();
    this.decodePolyline.value.forEach((path: any) => {
      bounds.extend(new google.maps.LatLng(path[0], path[1]));
    });
    this.googleMap.fitBounds(bounds);
  }

  markerDragEnd(event: google.maps.PolyMouseEvent) {
    let newPoly: any = [];
    this.mapPolyline.getPath().forEach((path: google.maps.LatLng) => {
      newPoly.push([path.lat(), path.lng()]);
    });

    // const newDecodedPolyline = [...this.decodePolyline.value];
    // if (event.vertex > -1) {
    //   newDecodedPolyline[event.vertex][0] = event.latLng.lat();
    //   newDecodedPolyline[event.vertex][1] = event.latLng.lng();
    // } else if (event.edge > -1) {
    //   const edgeIndex = event.edge;

    //   // Calculate the midpoint of the edge
    //   const newLat = event.latLng.lat();
    //   const newLng = event.latLng.lng();

    //   // Insert the new coordinate after the edge
    //   newDecodedPolyline.splice(edgeIndex + 1, 0, [newLat, newLng]);

    //   // Insert the new coordinate at the edge index
    // } else {
    //   //must be new one
    //   console.log('New One');
    // }

    // newDecodedPolyline[index] = updatedPath;
    this.decodePolyline.next(newPoly);
  }

  polylineDragEnd(event: google.maps.PolyMouseEvent) {
    this.markerDragEnd(event);
  }

  setSelectedPolylinePath(path: any) {
    this.selectedPolylinePath.next(path);
  }

  setSelectedPolyLinePathFromMarkerPath(path: any) {
    const selectedPath = this.decodePolyline.value.filter((s: any) => {
      return JSON.stringify(s) === path;
    });
    if (selectedPath) {
      this.selectedPolylinePath.next(selectedPath[0]);
    }
  }

  removePath(path: any) {
    this.decodePolyline.next(
      this.decodePolyline.value.filter((s: any) => s != path)
    );
    this.selectedPolylinePath.next(null);
  }

  addPathFromLatLng(latLng: google.maps.LatLng) {
    this.decodePolyline.next([
      ...this.decodePolyline.value,
      [latLng.lat(), latLng.lng()],
    ]);
  }

  setSelectedPolylinePathByLatLng(latLng: google.maps.LatLng) {
    const selectedPath = this.decodePolyline.value.filter((s: any) => {
      return s[0] === latLng.lat() && s[1] === latLng.lng();
    });
    if (selectedPath) {
      this.selectedPolylinePath.next(selectedPath[0]);
    }
  }

  calculateDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = lat1 * (Math.PI / 180);
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = (lat2 - lat1) * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance;
  }

  calculateHeading(lat1: any, lon1: any, lat2: any, lon2: any) {
    const φ1 = lat1 * (Math.PI / 180);
    const φ2 = lat2 * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);

    const y = Math.sin(Δλ) * Math.cos(φ2);
    const x =
      Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);

    const heading = Math.atan2(y, x);
    return ((heading * 180) / Math.PI + 360) % 360; // Convert to degrees and normalize to [0, 360]
  }

  calculateNewLatLng(lat1: any, lon1: any, lat2: any, lon2: any) {
    const distance = this.calculateDistance(lat1, lon1, lat2, lon2);
    const heading = this.calculateHeading(lat1, lon1, lat2, lon2);

    const R = 6371e3; // Earth's radius in meters
    const δ = distance / R; // angular distance in radians

    const φ1 = lat1 * (Math.PI / 180);
    const λ1 = lon1 * (Math.PI / 180);

    const φ2 = Math.asin(
      Math.sin(φ1) * Math.cos(δ) +
        Math.cos(φ1) * Math.sin(δ) * Math.cos(heading)
    );

    const λ2 =
      λ1 +
      Math.atan2(
        Math.sin(heading) * Math.sin(δ) * Math.cos(φ1),
        Math.cos(δ) - Math.sin(φ1) * Math.sin(φ2)
      );

    return [φ2 * (180 / Math.PI), λ2 * (180 / Math.PI)];
  }
}
