import { CdkListboxModule } from '@angular/cdk/listbox';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { NgxCurrencyModule } from '@fleet/shared';
import { ServicePriceGroupPipe } from './service-price-group.pipe';
import { ServicePricingSelectComponent } from './service-pricing-select.component';
@NgModule({
  declarations: [ServicePricingSelectComponent, ServicePriceGroupPipe],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TitleCaseAndCleanPipeModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatInputModule,
    NgxCurrencyModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    CdkListboxModule,
  ],
  exports: [ServicePricingSelectComponent],
})
export class ServicePricingSelectModule {}
