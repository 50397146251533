import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  NgResizeObserver,
  ngResizeObserverProviders,
} from 'ng-resize-observer';
import { map } from 'rxjs/operators';

export interface Size {
  width: string;
  height: string;

  wAlias: string;
  hAlias: string;

  alias: string;
}

@Component({
  selector: 'fleet-responsive-element',
  templateUrl: './responsive-element.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...ngResizeObserverProviders],
})
export class ResponsiveElementComponent {
  @Output() resized = new EventEmitter();
  size$ = this.resize$.pipe(
    map((entry: any) => {
      const size: Size = {
        height: entry.contentRect.height,
        width: entry.contentRect.width,
      } as any;

      if (entry.contentRect.width > 1000) {
        size.alias = 'xl';
      } else if (entry.contentRect.width > 800) {
        size.alias = 'lg';
      } else if (entry.contentRect.width > 600) {
        size.alias = 'md';
      } else if (entry.contentRect.width > 500) {
        size.alias = 'sm';
      } else {
        size.alias = 'xs';
      }
      this.resized.emit(size);

      return size;
    })
  );
  constructor(private resize$: NgResizeObserver) {}
}
