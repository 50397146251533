import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialablePhoneNumberModule } from '../dialable-phone-number/dialable-phone-number.module';
import { LetterIconModule } from '../letter-icon';
import { StatusChipModule } from './../status-chip/status-chip.module';
import { EntityHeaderDataItemComponent } from './entity-header-data-item.component';
import { BadgeModule } from '../badge';

@NgModule({
  declarations: [EntityHeaderDataItemComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    LetterIconModule,
    DialablePhoneNumberModule,
    BadgeModule,

    StatusChipModule,
  ],
  exports: [EntityHeaderDataItemComponent],
})
export class EntityHeaderDataItemModule {}
