import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  BlockAttributeModel,
  BlockAttributeSearchResultModel,
  BlocklistModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BlocklistApiService {
  host: string;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.blocklist;
  }

  searchBlocklistAttributes(
    params: any
  ): Observable<
    HttpResponse<ApiResponse<BlockAttributeSearchResultModel[]>> | any
  > {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getBlocklistAttribute(
    blockAttributeId: string
  ): Observable<ApiResponse<BlockAttributeModel> | any> {
    return this.http
      .get(`${this.host}/${blockAttributeId}`)
      .pipe(catchError(handleApiError));
  }

  createBlocklistAttribute(
    blocklistAttribute: BlockAttributeModel
  ): Observable<ApiResponse<BlockAttributeModel> | any> {
    return this.http
      .post(`${this.host}`, blocklistAttribute)
      .pipe(catchError(handleApiError));
  }

  updateBlocklistAttribute(
    blocklistAttributeId: string,
    blocklistAttribute: BlockAttributeModel
  ): Observable<ApiResponse<BlockAttributeModel> | any> {
    return this.http
      .put(`${this.host}/${blocklistAttributeId}`, blocklistAttribute)
      .pipe(catchError(handleApiError));
  }

  removeBlocklistAttribute(
    blocklistAttributeId: string
  ): Observable<null | any> {
    return this.http
      .delete(`${this.host}/${blocklistAttributeId}`)
      .pipe(catchError(handleApiError));
  }
}
