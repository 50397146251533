<mat-chip-listbox class="flex flex-wrap gap-4">
  <mat-chip
    class="text-sm flex flex-row justify-between gap-2"
    *ngFor="let term of searchParams | searchTerms : paramLabelMap"
  >
    <div class="flex flex-row items-center gap-1">
      <div class="flex flex-row gap-2">
        <div>{{ term.name }}:</div>
        <div class="font-bold">{{ displayValue(term.value) }}</div>
      </div>

      <button
        mat-icon-button
        class="flex"
        (click)="remove(term)"
        [disabled]="disabled"
        *ngIf="!term.disabled"
      >
        <mat-icon
          [svgIcon]="'heroicons_solid:x-circle'"
          class="flex icon-size-5"
        ></mat-icon>
      </button>
    </div>
  </mat-chip>
</mat-chip-listbox>
