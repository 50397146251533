import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';

import { catchError, mergeMap, take } from 'rxjs/operators';

import { TravellerApiService } from '@fleet/api';
import { ApiResponse, TravellerModel } from '@fleet/model';

import { AuthService } from '@fleet/auth';
import { OnscreenNotificationService } from '@fleet/ui';
import { TravellerService } from './traveller.service';
import { failureNotification } from '@fleet/utilities';
// import { SetEntityMenu } from '../layout/store/layout.actions';

@Injectable({
  providedIn: 'root',
})
export class TravellerResolveGuard  {
  fleetProduct: string;
  constructor(
    private router: Router,
    private travellerApiService: TravellerApiService,
    private travellerService: TravellerService,
    private authService: AuthService,
    private onScreenNotificationService: OnscreenNotificationService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  previousTraveller: TravellerModel;

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Observable<never> {
    let travellerId = route.paramMap.get('travellerId');
    if (!travellerId) {
      const decodedJwtTravellerId = this.authService.decodedToken.travellerId;
      if (!decodedJwtTravellerId) {
        return EMPTY;
      }
      travellerId = decodedJwtTravellerId;
    }

    return this.travellerApiService.getTraveller(travellerId).pipe(
      take(1),
      mergeMap((resp: ApiResponse<TravellerModel> | any) => {
        const traveller = resp.data;
        if (traveller) {
          this.travellerService.setTraveller(traveller);

          return of(traveller);
        } else {
          // id not found
          this.onScreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Error Loading Traveller',
            subTitle: 'Please try again',
          });

          this.router.navigate(['/traveller']);
          // this.navigationService.setCurrentNavigation('main');
          return EMPTY;
        }
      }),
      catchError((error) => {
        if (this.fleetProduct === 'TRAVELLER') {
          this.onScreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Something went wrong',
            subTitle: 'Please login again',
          });
          this.authService.logout();

          return EMPTY;
          //navigate to error page
        } else {
          this.onScreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Error Loading Traveller',
            subTitle: 'Please try again',
          });
          this.router.navigate(['/traveller']);
        }
        // this.navigationService.setCurrentNavigation('main');
        return EMPTY;
      })
    );
  }
}
