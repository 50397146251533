import { generateContrasts, generatePalette } from './color-palette-generator';
import chroma from 'chroma-js';
export function generateStylesByColorHex(hex: string, name: string) {
  const palette: any = generatePalette(hex);
  const contrasts = generateContrasts(palette);
  const styles: any[] = [];
  styles.push({
    var: `--fuse-${name}`,
    value: hex,
  });
  styles.push({
    var: `--fuse-on-${name}`,
    value: contrasts[500],
  });
  styles.push({
    var: `--fuse-${name}-rgb`,
    value: chroma(hex).rgb().join(','),
  });
  styles.push({
    var: `--fuse-on-${name}-rgb`,
    value: chroma(contrasts[500]).rgb().join(','),
  });
  Object.keys(palette).forEach((variant: any) => {
    styles.push({
      var: `--fuse-${name}-${variant}`,
      value: palette[variant],
    });
    styles.push({
      var: `--fuse-${name}-${variant}-rgb`,
      value: chroma(palette[variant]).rgb().join(','),
    });
  });

  Object.keys(contrasts).forEach((variant: any) => {
    styles.push({
      var: `--fuse-on-${name}-${variant}`,
      value: contrasts[variant],
    });
    styles.push({
      var: `--fuse-on-${name}-${variant}-rgb`,
      value: chroma(contrasts[variant]).rgb().join(','),
    });
  });
  return styles;
}
