import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IssueModel, TravellerActionType, TravellerModel } from '@fleet/model';
import { BehaviorSubject } from 'rxjs';
import { TravellerStatusChangeComponent } from '../traveller-status-change/traveller-status-change.component';
import { TravellerStatusAction } from './traveller-status-action.pipe';

interface TravellerStatusState {
  loading: boolean;
  issues: IssueModel[];
  action: TravellerStatusAction;
}
@Component({
  selector: 'fleet-traveller-status',
  templateUrl: './traveller-status.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravellerStatusComponent implements OnInit {
  @Input() uiMode: 'MENU' | 'LIST' | 'SELECT' = 'SELECT';

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      this.statusControl.setValue(value.status, { emitEvent: false });
    }
  }

  get traveller() {
    return this._traveller;
  }
  @Input() uiConfiguration: any;
  travellerActionState: BehaviorSubject<TravellerStatusState> =
    new BehaviorSubject(<TravellerStatusState>{});

  statusControl = new UntypedFormControl();

  @Output() actionCompleted = new EventEmitter();

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.statusControl.valueChanges.subscribe(
      (action: TravellerStatusAction) => {
        const dialog = this.matDialog.open(TravellerStatusChangeComponent);

        dialog.componentInstance.references = {
          traveller: this.traveller,
          action: action,
        };
        dialog.componentInstance.cancelled.subscribe(() => {
          dialog.close();
        });
        dialog.componentInstance.actionCompleted.subscribe(
          (payload: {
            traveller: TravellerModel;
            action: TravellerStatusAction;
          }) => {
            this.actionCompleted.emit({
              traveller: payload.traveller,
              action: payload.action,
            });
            dialog.close();
          }
        );
      }
    );
  }

  actionTraveller(action: TravellerStatusAction) {
    const dialog = this.matDialog.open(TravellerStatusChangeComponent);

    dialog.componentInstance.references = {
      traveller: this.traveller,
      action: action,
    };
    dialog.componentInstance.cancelled.subscribe(() => {
      dialog.close();
    });

    dialog.componentInstance.updateTraveller.subscribe(() => {
      this.actionCompleted.emit({
        traveller: this.traveller,
      });
    });
    dialog.componentInstance.actionCompleted.subscribe(
      (payload: {
        traveller: TravellerModel;
        action: TravellerStatusAction;
      }) => {
        // if delete we want to navigate to search
        if (action.action === TravellerActionType.DELETE_TRAVELLER) {
          this.router.navigate(['traveller/']);
        } else {
          this.actionCompleted.emit({
            traveller: payload.traveller,
            action: payload.action,
          });
        }
        this.changeDetectionRef.markForCheck();

        dialog.close();
      }
    );
  }
}
