import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobTemplateSearchResultsComponent } from './job-template-search-results.component';
import { MatIconModule } from '@angular/material/icon';
import { ResponsiveElementModule } from '@fleet/layout';
import { MatButtonModule } from '@angular/material/button';
import {
  ConditionIdsPipeModule,
  MobileFormatModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { MatTableModule } from '@angular/material/table';
import { JobDatetimePipeModule } from '../../../pipes/job-datetime-pipe';
import { NetworkConditionsDisplayListModule } from '@fleet/network-shared';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [JobTemplateSearchResultsComponent],
  imports: [
    CommonModule,
    MatTableModule,
    TitleCaseAndCleanPipeModule,
    MatButtonModule,
    MatIconModule,
    ResponsiveElementModule,

    JobDatetimePipeModule,
    NetworkConditionsDisplayListModule,
    ConditionIdsPipeModule,
    MatCheckboxModule,
    MatMenuModule,
    MobileFormatModule,
  ],
  exports: [JobTemplateSearchResultsComponent],
})
export class JobTemplateSearchResultsModule {}
