import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IvrApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import {
  ApiResponse,
  BaseSearchState,
  initialBaseSearchState,
  // CallModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import {
  getNextPageParams,
  pageDataFromSearchResultHeaders,
} from '@fleet/utilities';
import { BehaviorSubject, Subscription } from 'rxjs';
import { mockCallSearch } from './mock-call-search';

@Injectable({
  providedIn: 'root',
})
export class EntityCallSearchService {
  callSearchState: BehaviorSubject<BaseSearchState<any>> = new BehaviorSubject(
    initialBaseSearchState
  );
  private apiCallSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private networkGroupService: NetworkGroupService,
    private ivrApiService: IvrApiService
  ) {}

  searchCalls(params: any) {
    if (this.authService.networkId) {
      params['networkId'] = this.authService.networkId;
    }
    if (this.networkGroupService.networkGroupId) {
      params['networkGroupId'] = this.networkGroupService.networkGroupId;
    }

    this.callSearchState.next({
      ...this.callSearchState.value,
      searching: true,
      issues: [],
    });

    if (this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
    }

    this.apiCallSubscription = this.ivrApiService
      .searchCalls(params)
      .subscribe({
        next: (resp: HttpResponse<ApiResponse<any[]>> | any) => {
          let calls = [];
          if (params['offset'] === 0) {
            calls = resp.body.data;
          } else {
            calls = [...this.callSearchState.value.data, ...resp.body.data];
          }

          this.callSearchState.next({
            ...this.callSearchState.value,
            data: calls,
            searching: false,
            pageData: pageDataFromSearchResultHeaders(resp.headers, params),
          });
        },
        error: (error) =>
          this.callSearchState.next({
            ...this.callSearchState.value,
            issues: error,
            searching: false,
          }),
      });
  }

  getNextPage() {
    const nextPageParams = getNextPageParams(
      this.callSearchState.value.pageData
    );

    if (nextPageParams) {
      this.searchCalls(nextPageParams);
    }
    // if (nextPageParams === this.callSearchState.value.pageData.params) {
    // } else {
    //   this.searchCalls(nextPageParams);
    // }
  }

  removeParamAndSearch(
    term: string,
    driverId?: string,
    vehicleId?: string,
    jobId?: string,
    travellerId?: string,
    organisationId?: string
  ) {
    const params = Object.assign(
      {},
      this.callSearchState.value.pageData.params
    );
    delete params[term];
    if (driverId) {
      params['driverId'] = driverId;
    }
    if (vehicleId) {
      params['vehicleId'] = vehicleId;
    }
    if (jobId) {
      params['jobId'] = jobId;
    }
    if (travellerId) {
      params['travellerId'] = travellerId;
    }

    if (organisationId) {
      params['organisationId'] = organisationId;
    }
    if (term === 'fromDate' || term === 'toDate') {
      delete params['fromDate'];
      delete params['toDate'];
    }

    this.searchCalls(params);
  }

  setCallPreview(call: any) {
    this.callSearchState.next({
      ...this.callSearchState.value,
      selectedPreview: call,
    });
  }

  clearState() {
    this.callSearchState.next(initialBaseSearchState);
  }

  removeTermAndReturnParamSearchTerms(
    term: string,
    driverId?: string,
    vehicleId?: string,
    jobId?: string,
    travellerId?: string,
    organisationId?: string
  ) {
    const params = Object.assign(
      {},
      this.callSearchState.value.pageData.params
    );
    delete params[term];
    if (driverId) {
      params['driverId'] = driverId;
    }
    if (vehicleId) {
      params['vehicleId'] = vehicleId;
    }
    if (jobId) {
      params['jobId'] = jobId;
    }
    if (travellerId) {
      params['travellerId'] = travellerId;
    }

    if (organisationId) {
      params['organisationId'] = organisationId;
    }
    if (term === 'fromDate' || term === 'toDate') {
      delete params['fromDate'];
      delete params['toDate'];
    }

    delete params[term];

    return params;
  }
}
