import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { JobApiService } from '@fleet/api';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  JobModel,
  JobTransitionModel,
} from '@fleet/model';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { successNotification } from '@fleet/utilities';
@Component({
  selector: 'fleet-job-unassign-dialog',
  templateUrl: './job-unassign-dialog.component.html',
  styleUrls: ['./job-unassign-dialog.component.scss'],
})
export class JobUnassignDialogComponent implements OnInit {
  loading = false;
  unassignReasonControl = new UntypedFormControl(null, [Validators.maxLength(50)]);
  issues: IssueModel[];
  @Output() cancel = new EventEmitter();
  @Output() unassigned = new EventEmitter();
  @Input() job: JobModel;
  constructor(
    private jobApiService: JobApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private onScreenNotificationService: OnscreenNotificationService
  ) {}

  ngOnInit(): void {}

  unAssign() {
    this.loading = true;
    const transitionModel: JobTransitionModel = {} as JobTransitionModel;
    if (this.unassignReasonControl.value) {
      transitionModel.reason = this.unassignReasonControl.value;
    }
    this.jobApiService.unassignJob(this.job.jobId, transitionModel).subscribe({
      next: (resp: ApiResponse<JobModel>) => {
        this.unassigned.emit(resp.data);
        this.onScreenNotificationService.setNotification({
          ...successNotification,
          title: 'Job UnAssigned!',
        } as OnScreenNotification);
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      },
      error: (error) => {
        this.issues = error;
        this.loading = true;
        this.changeDetectorRef.markForCheck();
      },
    });
  }
}
