import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsRequiredPipe } from './is-required.pipe';

@NgModule({
  declarations: [IsRequiredPipe],
  imports: [CommonModule],
  exports: [IsRequiredPipe],
})
export class IsRequiredPipeModule {}
