import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TravellerApiService } from '@fleet/api';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
  TravellerModel,
} from '@fleet/model';

@Component({
  selector: 'fleet-entity-block-attribute-list',
  templateUrl: './entity-block-attribute-list.component.html',
  styleUrls: ['./entity-block-attribute-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityBlockAttributeListComponent),
      multi: true,
    },
  ],
})
export class EntityBlockAttributeListComponent
  implements OnInit, ControlValueAccessor
{
  @Input() mode: 'LIST' | 'SELECT';
  ENTITY_ATTRIBUTES: {
    type: string;
    value: string;
    checked: boolean;
    display?: string;
    card?: PaymentMethodSearchResultModel;
  }[] = [];

  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
  }

  get driver() {
    return this._driver;
  }

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      this.ENTITY_ATTRIBUTES = [
        { type: 'EMAIL', value: value.username, checked: false },

        {
          type: 'PHONE_NUMBER',
          value: value.phoneNumber,
          checked: false,
        },
      ];

      //Rather than only using personal profile - get all payment methods and just show them.

      this.travellerApiService
        .searchPaymentMethods(value.travellerId, { limit: 100, offset: 0 })
        .subscribe({
          next: (
            resp: HttpResponse<ApiResponse<PaymentMethodSearchResultModel[]>>
          ) => {
            resp.body.data.forEach(
              (paymentMethod: PaymentMethodSearchResultModel) => {
                this.ENTITY_ATTRIBUTES.push({
                  type: 'STORED_CARD',
                  value: paymentMethod.cardDetailsId,
                  card: paymentMethod,
                  checked: false,
                });
                this.changeDetectorRef.markForCheck();
              }
            );
          },
          error: (issues: IssueModel[]) => {},
        });

      this.changeDetectorRef.markForCheck();
    }
  }

  get traveller() {
    return this._traveller;
  }

  blockAttributeControl = new UntypedFormControl();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private travellerApiService: TravellerApiService
  ) {}

  ngOnInit(): void {
    this.blockAttributeControl.valueChanges.subscribe((value: any) => {
      this.onChange(value);
      this.onTouched();

      this.changeDetectorRef.markForCheck();
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    //reset patching null
    if (obj === null) {
      this.blockAttributeControl.reset(null);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  checkboxChanged(attribute: {
    type: string;
    value: string;
    checked: boolean;
    display?: string;
  }) {
    const foundIndex = this.ENTITY_ATTRIBUTES.findIndex(
      (i) => i.value === attribute.value
    );
    this.ENTITY_ATTRIBUTES[foundIndex] = {
      ...attribute,
      checked: !attribute.checked,
    };
    const checkedAttributes = this.ENTITY_ATTRIBUTES.filter((e) => e.checked);
    this.onChange(checkedAttributes);
    this.onTouched();
  }
}
