import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LocationService } from '../location.service';
import { MapService } from '@fleet/map';
import { LocationModel, PlaceModel } from '@fleet/model';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GoogleMapsApiService } from '@fleet/api';

@Component({
  selector: 'fleet-location-from-map',
  templateUrl: './location-from-map.component.html',
  styleUrls: ['./location-from-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationFromMapComponent implements OnInit, OnDestroy {
  isApiLoaded: boolean;
  initialLatLng: BehaviorSubject<any> = new BehaviorSubject(null);
  @Output() cancel = new EventEmitter();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() set defaultLatLng(value: any) {
    if (value) {
      this.initialLatLng.next(value);
    }
  }

  _location: LocationModel | PlaceModel;
  @Input() set location(value: LocationModel | PlaceModel) {
    this._location = value;
    if (value) {
      this.initialLatLng.next(value);
    }
  }
  get location() {
    return this._location;
  }
  @Output() locationConfirmed = new EventEmitter();

  constructor(
    private mapService: MapService,
    private locationService: LocationService,
    private googleApiService: GoogleMapsApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.googleApiService.isApiLoaded$,
      this.initialLatLng.asObservable(),
    ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: ([isLoaded, latLng]) => {
          this.isApiLoaded = isLoaded;
          this.changeDetectorRef.markForCheck();
          if (isLoaded && latLng) {
            this.mapService.setConfirmLocationWithLatLng(0, {
              latitude: latLng.latitude,
              longitude: latLng.longitude,
            });
            this.mapService.setMapReady(true);
          }
        },
      });
    this.mapService.mapConfirmedLocation
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (value: any) => {
          this.locationService.reverseGeoCodeAndSetSelectedPlaceDetail(
            value.position.lat(),
            value.position.lng()
          );
        },
      });
  }

  confirmLocation(location: LocationModel) {
    this.mapService.confirmReverseGeocode();
    this.locationConfirmed.emit(location);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
