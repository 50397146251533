/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

import {
  AreaModel,
  AreaStatisticsModel,
  HexagonMetadataModel,
  IssueLevel,
  IssueModel,
  JobModel,
  JobTransitionModel,
  LocationSearchResultModel,
  PlaceModel,
  PositionAggregateModel,
  PositionDetailModel,
  PositionListenerModel,
  PositionModel,
  VehicleAreaRankingModel,
  VehicleModel,
  VehicleRankingModel,
} from '@fleet/model';
import { BehaviorSubject, Subject, combineLatest, map } from 'rxjs';

import { JobState } from '@fleet/model';

// import { fleetTheme, swanTheme, Theme, ThemeManagerService } from '../theme';

import {
  alphabetLetterAtIndex,
  centerOfAustralia,
  jobModelFromJobStatusUpdateAndProgress,
} from '@fleet/utilities';
import {
  MAP_AGGREGATE_RESOLUTION_CONSTANT,
  areaPolyLinePath,
  boundsFromArea,
  boundsFromAreas,
  decodePathFromEncoded,
  decodePolyline,
  drawMapFromAreas,
  generateMarkerFromActiveJobV2,
  generateVehicleMarkerFromPositionDetail,
  getAreaPolylineOptionsOnVehicleArea,
  getRankingPolygonsFromVehicleArea,
  isBoundsContainedByAnother,
  mapFromJob,
  pathFromHexId,
  routeTimestampMarkersFromJobDetail,
  jobsToH3HeatMap,
  polygonAreaColor,
  polygonFromArea,
  polygonsFromPositionListenerAggregations,
} from './map-utilities';

import {
  BrandConfig,
  ProductConfigurationService,
} from '@fleet/product-configuration';

import { PositionDetailMarker } from './map.model';

import { JobApiService } from '@fleet/api';
import { JobStatusUpdateAndProgress } from '@fleet/model';

import { activeJobMarkerv2 } from './map-marker-active-jobs';
export interface MapState {
  markers: google.maps.MarkerOptions[];
  routePolyLines: google.maps.PolylineOptions[];
  center: google.maps.LatLngLiteral | google.maps.LatLng;
  zoom: number;
  radius: google.maps.CircleOptions;
  boundary: any;
  // mode: string;
  pickUpMarker: google.maps.MarkerOptions;
  centeredMapMarker: google.maps.MarkerOptions;
  options: google.maps.MapOptions;
  showConfirmButton: boolean;
  heatmapOptions: google.maps.visualization.HeatmapLayerOptions;

  // areaPolygons: google.maps.PolygonOptions[];
  resolutionPolygons: google.maps.PolygonOptions[];
  // vehicleRankingPolygons: google.maps.PolygonOptions[];
  vehiclePositionMarker: PositionDetailMarker[];
  area: AreaModel;
}

const initialMapState: MapState = {
  markers: [],
  center: {
    lat: +centerOfAustralia.latitude,
    lng: +centerOfAustralia.longitude,
  }, // { lat: +centerOfAustralia.latitude, lng: +centerOfAustralia.longitude }
  zoom: 5,
  routePolyLines: [],

  radius: null,
  boundary: null,
  // mode: 'view',
  pickUpMarker: null,
  centeredMapMarker: null,
  options: {
    disableDefaultUI: true,
    draggable: true,
    maxZoom: 25,
    clickableIcons: false,
    keyboardShortcuts: false,
    scaleControl: false,
    mapId: '375d32aa02b8c5e0',
    zoomControl: false,
    zoomControlOptions: {
      position: 6, // BOTOTM LEFT
    },
  },
  showConfirmButton: false,
  heatmapOptions: {
    gradient: [
      'rgba(255, 255, 255, 0)',
      '#5ffbf1',
      '#46eefa',
      '#41dfff',
      '#52cffe',
      '#69bff8',
      '#79b3f4',
      '#8aa7ec',
      '#aa8fd8',
      '#ba83ca',
      '#c777b9',
      '#d16ba5',
    ],
    radius: 40,
  } as google.maps.visualization.HeatmapLayerOptions,
  resolutionPolygons: [],

  // vehicleRankingPolygons: [],
  vehiclePositionMarker: null,
  area: null,
};

export interface MarkerDraggedEvent {
  // markerType: string;
  index: number;
  position: google.maps.LatLng;
}

@Injectable({
  providedIn: 'root',
})
export class MapService {
  currentVehicleArea$: BehaviorSubject<AreaModel> = new BehaviorSubject(null);
  vehicleRankingPolygons$: BehaviorSubject<google.maps.PolygonOptions[]> =
    new BehaviorSubject([]);

  mapState: BehaviorSubject<MapState> = new BehaviorSubject(initialMapState);
  routePolyLines: BehaviorSubject<google.maps.PolylineOptions[]> =
    new BehaviorSubject([]);
  vehicleMarkers: BehaviorSubject<any[]> = new BehaviorSubject([]);
  heatMapData: BehaviorSubject<google.maps.visualization.WeightedLocation[]> =
    new BehaviorSubject([]);

  jobAggregations: BehaviorSubject<any[]> = new BehaviorSubject([]);
  vehicleAggregations: BehaviorSubject<any[]> = new BehaviorSubject([]);

  markers: BehaviorSubject<any[]> = new BehaviorSubject([]);
  activeJobMarkers: BehaviorSubject<any[]> = new BehaviorSubject([]);
  viewMode: BehaviorSubject<string> = new BehaviorSubject('view');
  activeJob: BehaviorSubject<any> = new BehaviorSubject(null);

  vehicle: VehicleModel;
  mapClick = new Subject<google.maps.LatLng>();
  markerDragEnd = new Subject<MarkerDraggedEvent>();
  mapConfirmedLocation = new Subject<any>();
  radiusCircle: BehaviorSubject<google.maps.CircleOptions> =
    new BehaviorSubject(null);
  brandConfig: BrandConfig;
  vehicleMarker: BehaviorSubject<any> = new BehaviorSubject(null);
  routeTimelineMarkers: BehaviorSubject<any[]> = new BehaviorSubject([]);
  waypointIndexToConfirm: number;

  readOnly: BehaviorSubject<boolean> = new BehaviorSubject(null);

  mapReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  setReadOnly(value: boolean) {
    this.readOnly.next(value);
  }

  _googleMap: GoogleMap;
  zoomChangingProgrammatically = false;

  set googleMap(value: GoogleMap) {
    this._googleMap = value;
    if (value) {
      this._googleMap.centerChanged.subscribe(() => {});
      this._googleMap.zoomChanged.subscribe(() => {
        if (
          this.viewMode.value === 'move-pickup' ||
          this.viewMode.value === 'confirm'
        ) {
          this.mapState.next({
            ...this.mapState.value,
            pickUpMarker: {
              ...this.mapState.value.pickUpMarker,
              position: this._googleMap.getCenter(),
            },
            centeredMapMarker: {
              ...this.mapState.value.centeredMapMarker,
              position: this._googleMap.getCenter(),
            },
          });
        }
        this.zoomChangingProgrammatically = false;
      });

      this._googleMap.mapDrag.subscribe(() => {
        if (
          this.viewMode.value === 'move-pickup' ||
          this.viewMode.value === 'confirm'
        ) {
          this.mapState.next({
            ...this.mapState.value,
            pickUpMarker: {
              ...this.mapState.value.pickUpMarker,
              position: this._googleMap.getCenter(),
            },
            centeredMapMarker: {
              ...this.mapState.value.centeredMapMarker,
              position: this._googleMap.getCenter(),
            },
          });
        }
      });
    }
  }

  get googleMap() {
    return this._googleMap;
  }

  get aggregateResolution() {
    return Math.round(
      this._googleMap.getZoom() / MAP_AGGREGATE_RESOLUTION_CONSTANT
    );
  }

  get vehicleMarkers$() {
    return this.vehicleMarkers.asObservable();
  }

  get activeJobMarkers$() {
    return this.activeJobMarkers.asObservable();
  }

  get radiusCircle$() {
    return this.radiusCircle.asObservable();
  }
  get heatMapData$() {
    return this.heatMapData.asObservable();
  }

  get routePolyLines$() {
    return this.routePolyLines.asObservable();
  }

  get vehicleMarker$() {
    return this.vehicleMarker.asObservable();
  }

  get markers$() {
    return this.markers.asObservable();
  }

  get viewMode$() {
    return this.viewMode.asObservable();
  }

  get routeTimelineMarkers$() {
    return this.routeTimelineMarkers.asObservable();
  }
  get vehicleAggregations$() {
    return this.vehicleAggregations.asObservable();
  }

  get jobAggregations$() {
    return this.jobAggregations.asObservable();
  }

  areaPolygons: BehaviorSubject<google.maps.PolylineOptions[]> =
    new BehaviorSubject([]);
  selectedAreas: BehaviorSubject<AreaModel[]> = new BehaviorSubject([]);

  isAnimationPaused = false;

  get selectedAreas$() {
    return this.selectedAreas.asObservable();
  }

  get activeJob$() {
    return this.activeJob.asObservable();
  }

  get areaPolygons$() {
    return combineLatest([
      this.areaPolygons.asObservable(),
      this.selectedAreas.asObservable(),
      this.readOnly.asObservable(),
    ]).pipe(
      map(([areaPolygons, selectedAreas, readOnly]) => {
        if (areaPolygons.length === 1) {
          //Normal selection on a map - SINGLE AREA
          return areaPolygons;
        } else {
          return areaPolygons.map((polygon: any) => {
            const selected = selectedAreas
              ? selectedAreas.find(
                  (area: AreaModel) =>
                    area.areaReference === polygon.area.areaReference
                )
              : null;
            return {
              ...polygonAreaColor(selected ? true : false, polygon.area),
              area: polygon.area,
              clickable: !readOnly,
            };
          });
        }
      })
    );
  }

  get mapReady$() {
    return this.mapReady.asObservable();
  }

  constructor(
    private productConfigurationService: ProductConfigurationService,
    private jobApiService: JobApiService
  ) {
    this.productConfigurationService.config$.subscribe(
      (config: BrandConfig) => {
        this.brandConfig = config;

        // this.vehicleSvgs = config.vehicleMarkerSet;
        if (config?.jobMapId) {
          this.mapState.next({
            ...this.mapState.value,
            options: {
              ...this.mapState.value.options,
              mapId: config.jobMapId,
            },
          });
        }
      }
    );
  }

  setCenter(latLng: google.maps.LatLngLiteral) {
    this.mapState.next({ ...this.mapState.value, center: latLng, zoom: 15 });
  }

  setMapReady(isReady: boolean) {
    this.mapReady.next(isReady);
  }

  toggleRouteVisibility(visble: boolean) {
    this.mapState.next({
      ...this.mapState.value,
      routePolyLines: visble ? this.mapState.value.routePolyLines : [],
    });
  }

  drawMapFromPositionListener(
    positionListener: PositionListenerModel,
    setBounds?: boolean
  ) {
    //

    let aggregations: any = [];

    let vehiclePositionMarkers: any[] = [];
    if (positionListener) {
      if (positionListener.aggregations) {
        //THIS IS HEAT MAP NEED TO CHANGE TO HEX polygonsFromPositionListenerAggregations from map

        aggregations = polygonsFromPositionListenerAggregations(
          positionListener.aggregations
        );
      }
      if (positionListener.positions) {
        if (!positionListener.delta) {
          vehiclePositionMarkers = positionListener.positions.map(
            (position: PositionDetailModel) => {
              return generateVehicleMarkerFromPositionDetail(
                position,
                this.vehicle
                  ? position.vehicleId === this.vehicle.vehicleId
                  : false,
                this.brandConfig
              );
            }
          );
        } else {
          const oldMarkers = Object.assign({}, this.vehicleMarkers.value);
          positionListener.positions.forEach(
            (position: PositionDetailModel) => {
              const indexOfPosition = oldMarkers.findIndex(
                (s) => s.vehicleId == position.vehicleId
              );
              if (oldMarkers[indexOfPosition]) {
                oldMarkers[indexOfPosition] =
                  generateVehicleMarkerFromPositionDetail(
                    position,
                    this.vehicle
                      ? position.vehicleId === this.vehicle.vehicleId
                      : false,
                    this.brandConfig
                  );
              }
            }
          );
          vehiclePositionMarkers = oldMarkers;
        }
      }
    }

    if (positionListener && positionListener.aggregations) {
      // this.heatMapData.next(aggregations);
      this.vehicleAggregations.next(aggregations);
      this.vehicleMarkers.next([]);
    } else if (positionListener && positionListener.positions) {
      this.heatMapData.next([]);
      this.vehicleAggregations.next([]);

      this.vehicleMarkers.next(vehiclePositionMarkers);

      if (setBounds) {
        const bounds = new google.maps.LatLngBounds();

        vehiclePositionMarkers.forEach((marker) => {
          bounds.extend(marker.getPosition());
        });

        setTimeout(() => {
          this.googleMap.fitBounds(bounds, 400);
        }, 100);
      }
    } else {
      this.heatMapData.next([]);
      this.vehicleMarkers.next([]);
      this.vehicleAggregations.next([]);
    }
  }

  clearPositionListener() {
    this.mapState.next({
      ...this.mapState.value,
    });
    this.vehicleMarkers.next([]);
    this.heatMapData.next([]);
  }

  drawAreaOnMap(area: AreaModel, highContrast?: boolean) {
    if (area) {
      this.areaPolygons.next([polygonFromArea(area, highContrast)]);
    }
  }

  drawMapFromVehicleRanking(
    vehicleArea: VehicleAreaRankingModel,
    highContrast: boolean
  ) {
    const rankingPolygons = getRankingPolygonsFromVehicleArea(
      vehicleArea,
      highContrast
    );
    this.vehicleRankingPolygons$.next(rankingPolygons);
  }

  extendBoundsByProperties(properties: string[]) {
    const bounds = new google.maps.LatLngBounds();

    properties.forEach((property) => {
      switch (property) {
        case 'vehicleRanking':
          if (
            this.vehicleRankingPolygons$.value &&
            this.vehicleRankingPolygons$.value.length > 0
          ) {
            this.vehicleRankingPolygons$.getValue().forEach((poly: any) => {
              if (poly.paths) {
                poly.paths.forEach((path: any) => {
                  bounds.extend(path);
                });
              }
            });
          }
          break;
        case 'area':
          if (this.areaPolygons.value && this.areaPolygons.value.length > 0) {
            this.areaPolygons.value.forEach((poly: any) => {
              if (poly.paths) {
                poly.paths.forEach((path: any) => {
                  bounds.extend(path);
                });
              }
            });
          }
          break;
        // Add more cases as needed for other properties
        default:
          console.log(`No action for property: ${property}`);
      }
    });

    setTimeout(() => {
      this.googleMap.fitBounds(bounds, 10);
    }, 100);
  }

  updateVehicleMarkers(vehiclePositions: PositionDetailMarker[]) {
    vehiclePositions.forEach((vm: PositionDetailMarker) => {
      const existingMarker = this.vehicleMarkers.value.find(
        (s) => s.positionDetail.vehicleId === vm.positionDetail.vehicleId
      );
      if (existingMarker) {
        existingMarker.markerOptions;
      }
    });
  }

  clearAreaPolygons() {
    this.areaPolygons.next([]);
  }

  drawMapFromAreas(areas: AreaModel[]) {
    const polygons = drawMapFromAreas(
      areas,
      this.selectedAreas.value
    ) as google.maps.PolylineOptions[];
    // this.readOnly.next(readOnly);
    this.areaPolygons.next(polygons);

    this.setAreasBounds(areas);
  }

  disableActiveJobMarkers() {}

  enableActiveJobMarkers() {}

  drawMapFromActiveJobs(
    jobs: JobStatusUpdateAndProgress[],
    fitBounds: boolean,
    selectedJob: JobStatusUpdateAndProgress
  ) {
    if (selectedJob) {
      const updatedJob = jobs.find(
        (s) =>
          s.latestStatusUpdate.jobId === selectedJob.latestStatusUpdate.jobId
      );
      if (updatedJob) {
        selectedJob = Object.assign({}, selectedJob, updatedJob);
      }
    }
    this.activeJob.next(selectedJob);

    const markers = jobs.map((job: JobStatusUpdateAndProgress) => {
      return activeJobMarkerv2(job, selectedJob ? true : false);
      // return generateMarkerFromActiveJobV2(job, selectedJob ? true : false);
    });
    this.activeJobMarkers.next(markers);

    if (selectedJob) {
      //draw selected job
      const job = jobModelFromJobStatusUpdateAndProgress(selectedJob);
      //leave route for now
      const updatedJobMap = mapFromJob(job, this.brandConfig, true);

      let jobHasChanged = false;
      //check if job markers are different
      if (
        JSON.stringify(this.markers.value) !==
        JSON.stringify(updatedJobMap.markers)
      ) {
        jobHasChanged = true;
        this.markers.next(updatedJobMap.markers);
      }
      if (
        updatedJobMap.vehicleMarker &&
        JSON.stringify(this.vehicleMarker.value) !==
          JSON.stringify(updatedJobMap.vehicleMarker)
      ) {
        this.vehicleMarker.next(updatedJobMap.vehicleMarker);
      }
      if (jobHasChanged) {
        setTimeout(() => {
          if (
            job.jobDetail.startLocation &&
            job.jobDetail.endLocation &&
            job.jobDetail.endLocation.latitude
          ) {
            this.googleMap.fitBounds(updatedJobMap.bounds);
          }
        }, 100);
      }
    } else {
      //check if fit bounds and set to  active jobs markers
      this.markers.next([]);
      this.vehicleMarker.next(null);
      if (fitBounds) {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach((marker: any) => {
          bounds.extend(marker.position);
        });
        setTimeout(() => {
          this.googleMap.fitBounds(bounds, 50);
        }, 100);
      }
    }
  }

  deselectActiveJob() {
    if (this.activeJobMarkers.value.length > 0) {
      this.activeJobMarkers.next(
        this.activeJobMarkers.value.map((marker: any) => {
          return { ...marker, opacity: 1 };
        })
      );

      //clear job on map
      this.routePolyLines.next([]);
      this.markers.next([]);
      this.vehicleMarker.next(null);
    }
  }

  drawMapFromJobState(
    jobState: JobState,
    setBounds: boolean,
    setBoundsTo?: string,
    withJobTransition?: JobTransitionModel,
    showRouteTimelineMarkersInsteadOfPolylines?: boolean
  ) {
    let updatedJobMap = mapFromJob(
      jobState.job,
      this.brandConfig,
      setBounds,
      setBoundsTo,
      withJobTransition,
      showRouteTimelineMarkersInsteadOfPolylines
    );

    const geoIssues = jobState.issues
      ? jobState.issues.filter((s: any) => s.geo)
      : [];
    if (geoIssues.length > 0) {
      const geoIssueMap = this.drawGeoIssues(geoIssues);
      if (geoIssueMap.boundary || geoIssueMap.radius) {
        updatedJobMap = {
          ...updatedJobMap,
          boundary: geoIssueMap.boundary,
          radius: geoIssueMap.radius,
          bounds: geoIssueMap.bounds.union(updatedJobMap.bounds),
        };
      }
    }

    if (updatedJobMap) {
      if (
        JSON.stringify(this.routePolyLines.value) !==
        JSON.stringify(updatedJobMap.routePolyLines)
      ) {
        this.routePolyLines.next(
          updatedJobMap.routePolyLines ? updatedJobMap.routePolyLines : []
        );
      }
      if (
        JSON.stringify(this.vehicleMarker.value) !==
        JSON.stringify(updatedJobMap.vehicleMarker)
      ) {
        this.vehicleMarker.next(updatedJobMap.vehicleMarker);
      }
      if (
        JSON.stringify(this.mapState.value.markers) !==
        JSON.stringify(updatedJobMap.markers)
      ) {
        this.markers.next(updatedJobMap.markers);
      }
      if (
        JSON.stringify(this.routeTimelineMarkers.value) !==
        JSON.stringify(updatedJobMap.routeTimelineMarkers)
      ) {
        this.routeTimelineMarkers.next(updatedJobMap.routeTimelineMarkers);
      }

      this.mapState.next(Object.assign({}, this.mapState.value, updatedJobMap));
      setTimeout(() => {
        if (
          updatedJobMap.bounds &&
          this.googleMap &&
          (setBounds || updatedJobMap.forceBounds) &&
          updatedJobMap.bounds != this.googleMap.getBounds() &&
          updatedJobMap.boundsExtended
        ) {
          if (updatedJobMap.forceBounds) {
            if (
              !isBoundsContainedByAnother(
                this.googleMap.getBounds(),
                updatedJobMap.bounds
              )
            ) {
              this.googleMap.fitBounds(updatedJobMap.bounds);
            }
          } else if (setBoundsTo === 'start-location') {
            this.googleMap.panTo(this.markers.value[0].position);
            this.mapState.next({ ...this.mapState.value, zoom: 15 });
          } else {
            if (this.markers.value && this.markers.value.length === 1) {
              this.googleMap.panTo(this.markers.value[0].position);
              this.mapState.next({ ...this.mapState.value, zoom: 15 });
            } else {
              this.googleMap.fitBounds(updatedJobMap.bounds);
            }
          }
        }
      }, 100);
    }
  }

  drawGeoIssues(geoIssues: IssueModel[]) {
    const updatedMap: any = { bounds: google.maps.LatLngBounds };

    if (geoIssues.length > 0) {
      geoIssues.forEach((issue: any) => {
        if (issue.geo.polygon) {
          const decoded = decodePathFromEncoded(issue.geo.polygon);
          (updatedMap.boundary = <google.maps.PolygonOptions>{
            paths: decoded,
            strokeOpacity: 0,
            strokeWeight: 0,
            fillColor: issue.level === IssueLevel.WARN ? 'orange' : 'red',
            fillOpacity: 0.4,
          }),
            updatedMap.boundary.paths.array.forEach((path: any) => {
              updatedMap.bounds.extend(path);
            });
        }
        if (issue.geo.radius) {
          const radiusInMetres =
            Number.parseFloat(issue.geo.radius.value) * 1000;
          const radiusCircle = {
            radius: radiusInMetres,
            latitude: +issue.geo.radius.latitude,
            longitude: +issue.geo.radius.longitude,
          };
          const centerSfo = new google.maps.LatLng(
            radiusCircle.latitude,
            radiusCircle.longitude
          );
          const circle = <google.maps.CircleOptions>{
            radius: radiusInMetres,
            center: centerSfo,
            fillOpacity: 0.4,
            fillColor: issue.level === IssueLevel.WARN ? 'orange' : 'red',
            strokeWeight: 0,
          };
          const circleBounds = new google.maps.Circle(circle).getBounds();

          updatedMap.bounds.union(circleBounds);
          updatedMap.radius = circle;
        }
      });
    }

    return updatedMap;
  }

  compareState(stateA: MapState, stateB: MapState) {
    return (
      stateA.markers == stateB.markers &&
      stateA.routePolyLines === stateB.routePolyLines
    );
  }

  drawJobTimelinePosition(position: PositionModel) {
    const opac = 0.3;
    // draw vehicle position
    this.vehicleMarker.next(
      generateVehicleMarkerFromPositionDetail(
        {
          position: position,
        } as any,
        true
      )
    );
    this.routePolyLines.next(
      this.routePolyLines.value.map((poly: any) => {
        return {
          ...poly,
          strokeOpacity: opac,
          icons: poly.icons
            ? poly.icons.map((polyIcon: any) => {
                return {
                  ...polyIcon,
                  icon: { ...polyIcon.icon, strokeOpacity: opac },
                };
              })
            : [],
        };
      })
    );
    this.markers.next(
      this.markers.value.map((marker: any) => {
        return { ...marker, opacity: opac };
      })
    );
  }

  drawRoutes(routes: string[]) {
    const polylines = routes.map((route) => {
      //@TODO MAP:  routes colors by theme
      // return { path: decodePathFromEncoded(route), strokeColor: this.theme.primary };
      return {
        path: decodePathFromEncoded(route),
      } as google.maps.PolylineOptions;
    });
    this.mapState.next({ ...this.mapState.value, routePolyLines: polylines });
    setTimeout(() => {
      this.setBounds();
    }, 300);
  }

  // setMapStyle(style: string) {
  //   let styles;
  //   let mapId = this.mapState.value.options.mapId;
  //   switch (style) {
  //     case 'DARK':
  //       styles = darkStyleMap;

  //       break;
  //     case 'GRAY':
  //       styles = grayStyleMap;
  //       break;
  //     case 'ULTRA_LIGHT':
  //       styles = ultraLightStyle;
  //       mapId = this.brandConfig.aggregationMapId;
  //       break;
  //   }
  //   this.mapState.next({
  //     ...this.mapState.value,
  //     options: { ...this.mapState.value.options, styles: styles },
  //   });
  // }

  setMapIdByType(type: string) {
    let mapId = this.mapState.value?.options?.mapId;
    switch (type) {
      case 'JOB':
        mapId = this.brandConfig.jobMapId;
        break;
      case 'AGGREGATION':
        mapId = this.brandConfig.aggregationMapId;
        break;
      case 'IN_VEHICLE':
        mapId = this.brandConfig.inVehicleMapId;
        break;
    }
    this.mapState.next({
      ...this.mapState.value,
      options: { ...this.mapState.value.options, mapId: mapId },
    });
  }

  setMapMode(mode: string) {
    let options = this.mapState.value.options;
    if (mode === 'move-pickup') {
      options = { ...options, draggable: true, maxZoom: 20 };
    } else if (mode === 'view') {
      options = { ...options, draggable: true, maxZoom: 15 };
    }
    const center = this.mapState.value.centeredMapMarker
      ? (this.mapState.value.centeredMapMarker
          .position as google.maps.LatLngLiteral)
      : this._googleMap.getCenter();

    this.mapState.next({
      ...this.mapState.value,

      options: options,
      center: center,
    });
    this.viewMode.next(mode);
    setTimeout(() => {
      this.setBounds(18);
    }, 100);
  }

  setConfirmLocationWithLatLng(
    waypointIndex: any,
    latLng: any,
    alphabetIndex?: number
  ) {
    let options = this.mapState.value.options;
    options = { ...options, draggable: true, maxZoom: 20 };
    this.waypointIndexToConfirm = waypointIndex;
    const center = latLng
      ? { lat: +latLng.latitude, lng: +latLng.longitude }
      : this._googleMap.getCenter();
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(center);
    if (!alphabetIndex && typeof waypointIndex === 'number') {
      alphabetIndex = waypointIndex;
    }
    this.mapState.next({
      ...this.mapState.value,

      options: options,
      center: center,
      zoom: 15,
      showConfirmButton: true,
      centeredMapMarker: {
        draggable: false,
        position: center,
        label: alphabetLetterAtIndex(alphabetIndex),
      } as google.maps.MarkerOptions,
    });
    this.viewMode.next('confirm');

    setTimeout(() => {
      this.googleMap.panTo(center);
    }, 100);
  }

  cancelReverseGeocode() {
    this.mapState.next({
      ...this.mapState.value,

      centeredMapMarker: null,
      showConfirmButton: false,
    });
    this.viewMode.next('view');

    setTimeout(() => {
      this.setBounds();
    }, 100);
  }

  confirmReverseGeocode() {
    this.mapState.next({
      ...this.mapState.value,

      centeredMapMarker: null,
      showConfirmButton: false,
    });
    this.viewMode.next('view');

    // setTimeout(() => {
    //   this.setBounds();
    // }, 100);
  }

  setConfirmLocationNeeded(marker: MarkerDraggedEvent) {
    if (this.mapState.value.showConfirmButton === false) {
      this.waypointIndexToConfirm = marker.index;
      this.mapState.next({ ...this.mapState.value, showConfirmButton: true });
    }
  }

  confirmLocation(mapMarker?: any) {
    this.mapState.next({
      ...this.mapState.value,
      showConfirmButton: false,
    });
    this.viewMode.next('view');

    const marker = {
      index: this.waypointIndexToConfirm,
      position: mapMarker ? mapMarker.position : this._googleMap.getCenter(),
    };
    this.markerDragEnd.next(marker);
    this.mapConfirmedLocation.next(marker);
  }

  isMapStateDifferent(newState: any) {
    const isMarkersDifferent =
      JSON.stringify(newState.markers) !==
      JSON.stringify(this.mapState.value.markers);
    const isRoutePolyLinesDifferent =
      JSON.stringify(newState.routePolyLines) !=
      JSON.stringify(this.mapState.value.routePolyLines);
    const isMapStateDifferent =
      isMarkersDifferent ||
      isRoutePolyLinesDifferent ||
      newState.boundary != this.mapState.value.boundary ||
      newState.radius != this.mapState.value.radius;

    return isMapStateDifferent;
  }

  setBounds(zoomOveride?: any) {
    let boundsExtended = false;
    let bounds = new google.maps.LatLngBounds();
    if (this.viewMode.value === 'view') {
      this.mapState.value.markers.forEach(
        (marker: google.maps.MarkerOptions) => {
          bounds.extend(marker.position);
          boundsExtended = true;
        }
      );
      this.mapState.value.routePolyLines.forEach(
        (polylineOption: google.maps.PolylineOptions) => {
          polylineOption.path.forEach((path: any) => {
            bounds.extend(path);
            boundsExtended = true;
          });
        }
      );
      if (this.mapState.value.boundary) {
        this.mapState.value.boundary.paths.forEach((path: any) => {
          bounds.extend(path);
          boundsExtended = true;
        });
      }

      if (this.mapState.value.radius) {
        const circleBounds = new google.maps.Circle(
          this.mapState.value.radius
        ).getBounds();

        bounds.union(circleBounds);
        boundsExtended = true;
      }

      if (
        boundsExtended === false &&
        (this.mapState.value.area ||
          (this.mapState.value.resolutionPolygons &&
            this.mapState.value.resolutionPolygons.length > 0))
      ) {
        bounds = boundsFromArea(this.mapState.value.area);
      }

      if (this.mapState.value.markers.length == 1) {
        this.googleMap.panTo(this.mapState.value.markers[0].position);
      } else {
        this.googleMap.fitBounds(bounds, 20);
      }

      const zoom = this.googleMap.getZoom();

      if (zoom > 15) {
        // this.googleMap.zoom = 15;

        this.mapState.next({ ...this.mapState.value, zoom: 15 });
      } else {
        if (this.mapState.value.markers.length == 1) {
          this.googleMap.panTo(this.mapState.value.markers[0].position);
        } else {
          this.googleMap.fitBounds(bounds, 20);
        }
      }
    } else if (this.viewMode.value === 'move-pickup') {
      bounds.extend(this.mapState.value.pickUpMarker.position);

      this.googleMap.fitBounds(bounds, 200);

      this.mapState.next({ ...this.mapState.value, zoom: zoomOveride });
    } else if (this.viewMode.value === 'confirm') {
      bounds.extend(this.mapState.value.centeredMapMarker.position);

      this.googleMap.fitBounds(bounds, 200);

      this.mapState.next({ ...this.mapState.value, zoom: zoomOveride });
    }
  }

  setAreaBounds(area: AreaModel) {
    this.mapState.next({ ...this.mapState.value, area: area });
    const bounds = boundsFromArea(area);

    setTimeout(() => {
      this._googleMap.fitBounds(bounds, 0);
    }, 100);
  }

  setAreasBounds(areas: AreaModel[]) {
    const bounds = boundsFromAreas(areas);

    setTimeout(() => {
      this._googleMap.fitBounds(bounds, 0);
    }, 100);
  }

  setBoundsByLocation(location: LocationSearchResultModel) {
    const bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: +location.latitude, lng: +location.longitude });
    setTimeout(() => {
      this._googleMap.fitBounds(bounds, 200);
    }, 100);
  }

  setZoom(zoom: number) {
    this.mapState.next({ ...this.mapState.value, zoom: zoom });
  }

  resetMap() {
    this.routePolyLines.next([]);
    this.mapState.next({
      ...initialMapState,
      options: { ...initialMapState.options, mapId: this.brandConfig.jobMapId },
    });
    this.vehicleMarkers.next([]);
    this.vehicle = null;
    this.activeJobMarkers.next([]);
    this.markers.next([]);
    this.vehicleMarker.next(null);
    this.heatMapData.next([]);
    this.activeJob.next(null);
    this.selectedAreas.next([]);
    this.areaPolygons.next([]);
    this.radiusCircle.next(null);
    this.routeTimelineMarkers.next([]);
    this.vehicleAggregations.next([]);
    this.jobAggregations.next([]);
    this.vehicleRankingPolygons$.next(null);
    this.setReadOnly(false);
    this.mapReady.next(false);
  }
  get map$() {
    return this.mapState.asObservable();
  }

  areaSelected(polygon: any) {
    const found = this.selectedAreas.value.find(
      (area: AreaModel) => area.areaReference === polygon.area.areaReference
    );

    //removing
    if (found) {
      this.selectedAreas.next(
        this.selectedAreas.value.filter(
          (area: AreaModel) => area.areaReference !== found.areaReference
        )
      );
    } else {
      //adding

      if (polygon && polygon.area) {
        this.selectedAreas.next([...this.selectedAreas.value, polygon.area]);
      }
    }
  }

  removeSelectedArea(selectedArea: AreaModel) {
    const found = this.selectedAreas.value.find(
      (area: AreaModel) => area.areaReference === selectedArea.areaReference
    );

    if (found) {
      this.selectedAreas.next(
        this.selectedAreas.value.filter(
          (area: AreaModel) => area.areaReference !== found.areaReference
        )
      );
    }
  }

  unselectAllAreas() {
    this.selectedAreas.next([]);
  }

  drawRadiusFromPlaceInKM(
    place: PlaceModel,
    radius: number,
    setBounds: boolean
  ) {
    if (place) {
      this.radiusCircle.next({
        center: { lat: +place.latitude, lng: +place.longitude },
        radius: radius,
        fillColor: 'red',
        fillOpacity: 0.3,
        strokeColor: 'red',
        strokeOpacity: 0.8,
        strokeWeight: 0,
      });
      const circleBounds = new google.maps.Circle({
        radius: radius,
        center: this.radiusCircle.value.center,
      }).getBounds();
      if (this.googleMap.getBounds() != circleBounds) {
        setTimeout(() => {
          this.googleMap.fitBounds(circleBounds);
        }, 100);
      }
    } else {
      this.radiusCircle.next(null);
    }
  }

  fitBoundsToAreasAndRadius() {
    let bounds = new google.maps.LatLngBounds();
    if (this.radiusCircle) {
      bounds = new google.maps.Circle({
        radius: this.radiusCircle.value.radius,
        center: this.radiusCircle.value.center,
      }).getBounds();
    }
    if (this.selectedAreas.value) {
      this.selectedAreas.value.forEach((area: AreaModel) => {
        const paths = area.multiPolygon.polygons[0].rings[0].points.map(
          (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
        );

        paths.forEach((path: any) => {
          bounds.extend(path);
        });
      });
    }
    if (this.googleMap.getBounds() != bounds) {
      setTimeout(() => {
        this.googleMap.fitBounds(bounds);
      }, 100);
    }
  }

  configureForDrawing(options: google.maps.drawing.DrawingManagerOptions) {
    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(this._googleMap as any);
  }

  setMaxZoom(zoom: any) {
    this.mapState.next({
      ...this.mapState.value,
      options: { ...this.mapState.value.options, maxZoom: zoom },
    });
  }

  jobsToH3HeatMap(
    jobs: JobStatusUpdateAndProgress[],
    fitBounds: boolean,
    currentZoom: number
  ) {
    const resolution = currentZoom / MAP_AGGREGATE_RESOLUTION_CONSTANT;
    const heatmapPolygons = jobsToH3HeatMap(jobs, resolution);

    this.jobAggregations.next(heatmapPolygons);

    if (fitBounds) {
      const bounds = new google.maps.LatLngBounds();
      heatmapPolygons.forEach((polygon: any) => {
        polygon.paths.forEach((path: google.maps.LatLng) => {
          bounds.extend(path);
        });
      });
      setTimeout(() => {
        this.googleMap.fitBounds(bounds);
      }, 100);
    }
  }

  clearJobsH3HeatMap() {
    this.jobAggregations.next([]);
  }
  clearActiveJobMarkers() {
    this.activeJobMarkers.next([]);
  }
}
