import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganisationApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import {
  ApiResponse,
  JobOrganisationModel,
  LocationModel,
  LocationSearchResultModel,
  NetworkConditionModel,
  OrganisationGroupSearchResultModel,
  OrganisationModel,
  OrganisationUserModel,
} from '@fleet/model';

import { BehaviorSubject, EMPTY, Observable, forkJoin, of } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JobOrganisationService {
  jobOrganisation: BehaviorSubject<JobOrganisationModel> = new BehaviorSubject(
    null
  );

  locations: BehaviorSubject<LocationSearchResultModel[]> = new BehaviorSubject(
    []
  );
  invalidFields: BehaviorSubject<string[]> = new BehaviorSubject([]);
  groupLocations: BehaviorSubject<{
    startLocation: LocationModel;
    endLocation: LocationModel;
    additionalInstructions: string;
  }> = new BehaviorSubject(null);
  organisation: BehaviorSubject<OrganisationModel> = new BehaviorSubject(null);
  bookingUnderOrganisation: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  organisationUser: BehaviorSubject<OrganisationUserModel> =
    new BehaviorSubject(null);
  fleetProduct: string;
  constructor(
    private organisationApiService: OrganisationApiService,

    private authService: AuthService
  ) {}

  get organisation$() {
    return this.organisation.asObservable();
  }

  get organisationConditions$(): Observable<NetworkConditionModel[]> {
    return this.organisation$.pipe(
      map((org: OrganisationModel) => {
        return org ? org.conditions : [];
      })
    );
  }

  get organisationConditions() {
    return this.organisation.value && this.organisation.value.conditions
      ? this.organisation.value.conditions
      : [];
  }

  get invalidFields$() {
    // return this.invalidFields.asObservable();
    return this.jobOrganisation$.pipe(
      switchMap((jobOrganisation) =>
        jobOrganisation ? this.invalidFields.asObservable() : of([])
      )
    );
  }

  get organisationShouldUseDefaultPaymentMethod$() {
    return this.organisationConditions$.pipe(
      map((conditions: NetworkConditionModel[]) => {
        if (conditions) {
          return (
            conditions.findIndex(
              (condition: NetworkConditionModel) =>
                condition.platformName === 'Default Payment Method'
            ) > -1
          );
        }
        return false;
      })
    );
  }

  get organisationUser$() {
    return this.organisationUser.asObservable();
  }

  get jobOrganisation$() {
    return this.jobOrganisation.asObservable();
  }

  get locations$() {
    return this.locations.asObservable();
  }

  get groupLocations$() {
    return this.groupLocations.asObservable();
  }

  get bookingUnderOrganisation$() {
    return this.bookingUnderOrganisation.asObservable();
  }

  get isBookingUnderOrganisation() {
    return this.bookingUnderOrganisation.value;
  }

  getOrganisation(organisationId: string) {
    this.organisationApiService.getOrganisation(organisationId).subscribe({
      next: (resp: ApiResponse<OrganisationModel>) => {
        this.setOrganisation(resp.data);
      },
    });
  }

  getJobOrganisation(organisationId: string) {
    this.organisationApiService.getOrganisation(organisationId).subscribe({
      next: (resp: ApiResponse<OrganisationModel>) => {
        console.log('Setting job org in job org service get job org method');
        this.setJobOrganisation({
          organisationId: resp.data.organisationId,
          organisationDisplayName: resp.data.name,
        } as JobOrganisationModel);
      },
    });
  }

  setBookingUnderOrganisation(bookingUnderOrganisation: boolean) {
    this.bookingUnderOrganisation.next(bookingUnderOrganisation);
  }

  setOrganisation(organisation: OrganisationModel) {
    this.organisation.next(organisation);
    if (organisation) {
      const jobOrg = {
        organisationId: organisation.organisationId,
        organisationDisplayName: organisation.name,
      } as JobOrganisationModel;
      if (
        (this.jobOrganisation.value &&
          this.jobOrganisation.value.organisationId !==
            jobOrg.organisationId) ||
        !this.jobOrganisation.value
      ) {
        this.jobOrganisation.next(jobOrg);
      }
    } else {
      this.jobOrganisation.next(null);
    }
  }

  setJobOrganisation(value: JobOrganisationModel) {
    this.jobOrganisation.next(value);
    if (!value) {
      this.locations.next([]);
      this.groupLocations.next(null);
    }
    if (
      value &&
      value.organisationId &&
      (!this.organisation.value ||
        value.organisationId !== this.organisation.value.organisationId)
    ) {
      this.getOrganisation(value.organisationId);
    } else if (!value) {
      this.organisation.next(null);
    }
  }

  updateJobOrganisation(value: JobOrganisationModel) {
    this.jobOrganisation.next(
      Object.assign({}, this.jobOrganisation.value, value)
    );
  }

  reset() {
    this.jobOrganisation.next(null);
    this.organisation.next(null);
    this.bookingUnderOrganisation.next(false);
    if (this.locations.value.length !== 0) {
      this.locations.next([]);
      // this.groupPickupLocation.next(null);
      // this.groupEndLocation.next(null);
      this.groupLocations.next(null);
    }

    this.organisationUser.next(null);
  }

  fetchLocationsByUsersGroups(groups: OrganisationGroupSearchResultModel[]) {
    forkJoin(
      groups.map((group: OrganisationGroupSearchResultModel) =>
        this.organisationApiService.searchLocations(
          { organisationGroupId: group.organisationGroupId },
          group.organisationId
        )
      )
    )
      .pipe(
        concatMap(
          (
            locationSearchResults: HttpResponse<
              ApiResponse<LocationSearchResultModel[]>
            >[]
          ) => locationSearchResults.map((s) => s.body.data)
        ),
        switchMap((locations: LocationSearchResultModel[]) => {
          // Other codes remain the same..
          this.locations.next(locations);
          let pickupLocationObservable: Observable<ApiResponse<LocationModel>>;
          let dropOffLocationObservable: Observable<ApiResponse<LocationModel>>;

          if (
            locations.filter((s) => s.locationType === 'PICKUP').length === 1 &&
            groups.length === 1
          ) {
            const pickup = locations.find((s) => s.locationType === 'PICKUP');
            pickupLocationObservable =
              this.organisationApiService.getLocationOnGroupLocationId(
                pickup.organisationGroupLocationId,
                groups[0].organisationGroupId,
                groups[0].organisationId
              );
          }

          if (
            locations.filter((s) => s.locationType === 'DROPOFF').length ===
              1 &&
            groups.length === 1
          ) {
            const dropOff = locations.find((s) => s.locationType === 'DROPOFF');
            dropOffLocationObservable =
              this.organisationApiService.getLocationOnGroupLocationId(
                dropOff.organisationGroupLocationId,
                groups[0].organisationGroupId,
                groups[0].organisationId
              );
          }

          return forkJoin({
            pickupResp: pickupLocationObservable
              ? pickupLocationObservable
              : of(null),
            dropOffResp: dropOffLocationObservable
              ? dropOffLocationObservable
              : of(null),
          });
        })
      )
      .subscribe({
        next: ({
          pickupResp,
          dropOffResp,
        }: {
          pickupResp: any;
          dropOffResp: any;
        }) => {
          const groupLocations: any = {
            startLocation: null,
            endLocation: null,
          };
          if (pickupResp) {
            groupLocations.startLocation = {
              ...pickupResp.data,
              locationId: null,
              status: null,
            };
          }
          if (dropOffResp) {
            groupLocations.endLocation = {
              ...dropOffResp.data,
              locationId: null,
              status: null,
            };
          }
          this.groupLocations.next(groupLocations);
        },
      });
  }

  setInvalidFields(fields: string[]) {
    this.invalidFields.next(fields);
  }
}
