import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobDatetimePipe } from './job-datetime.pipe';

@NgModule({
  declarations: [JobDatetimePipe],
  imports: [CommonModule],
  exports: [JobDatetimePipe],
})
export class JobDatetimePipeModule {}
