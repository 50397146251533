<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 p-6 pb-0">
      <!-- Light version -->
      <img class="dark:hidden w-30" [src]="logoSrc" alt="Logo image" />
      <!-- Dark version -->
      <img class="hidden dark:flex w-40" [src]="logoSrc" alt="Logo image" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
    [ngClass]="{
      'bg-card dark:bg-transparent': !currentGroup,
      'bg-gray-900 text-white': currentGroup
    }"
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon
        [class.text-current]="currentGroup"
        [svgIcon]="'heroicons_outline:menu'"
      ></mat-icon>
    </button>

    <fleet-group-view-control
      class="ml-2"
      *ngIf="currentGroup"
      [group]="currentGroup"
    ></fleet-group-view-control>
    <!-- <div class="text-1xl">{{ currentGroup.displayName }}</div> -->

    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-2">
      <button
        *ngIf="currentGroup?.type === 'TAXI_NETWORK'"
        mat-flat-button
        class="bg-primary-600 text-white disabled:bg-gray-200"
        [routerLink]="['/' + currentGroup.groupId + '/job/new']"
      >
        NEW JOB
      </button>

      <fuse-fullscreen></fuse-fullscreen>
      <search [appearance]="'bar'"></search>
      <!--
      <fleet-user-menu></fleet-user-menu> -->
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <div
    class="
      relative
      flex flex-0
      items-center
      justify-start
      w-full
      h-14
      px-4
      md:px-6
      z-49
      border-t
      bg-card
      dark:bg-transparent
      print:hidden
    "
  >
    <span class="font-medium text-secondary"
      >Fleet &copy; {{ currentYear }}</span
    >
  </div> -->
</div>
