export const swanOrange = '#f96938';
export const swanBlue = '#26308c';
export const swanWhite = '#ffffff';
export const swanMarkerSvgString = `
<svg
   id="Layer_1"
   data-name="Layer 1"
   viewBox="0 0 245.6693 245.6693"
   version="1.1"
   width="30"
   height="30"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <circle
     style="fill:#OUTER_RING_FILL#;fill-opacity:1;fill-rule:evenodd;stroke-width:3.25"
     id="path493"
     cx="122.83465"
     cy="-122.83465"
     r="122.83465"
     transform="scale(1,-1)" />
  <circle
     style="fill:#INNER_RING_FILL#;fill-rule:evenodd;stroke-width:2.75"
     id="path493-6"
     cx="122.69617"
     cy="122.03724"
     r="103.937" />
  <path

     d="m 69.180574,106.46133 a 6.9976952,7.0609259 0 0 0 3.862037,3.56312 c 8.47203,3.21479 19.597861,6.16833 36.045679,7.41651 13.11079,0.99419 22.76948,1.65457 32.01823,7.25686 a 0.76953071,0.77648414 0 0 0 0.97091,-1.19738 c -6.9042,-7.3004 -14.90157,-8.86788 -27.2644,-13.18572 -21.244806,-7.40925 -36.678571,-19.06377 -44.49614,-23.947638 -1.373648,-0.863566 -2.603459,-0.09434 -3.25792,1.71262 -2.488389,6.821449 0.287675,14.484698 2.121604,18.381628 z"
     id="path77"
     style="stroke-width:0.72243;fill:#SWAN_TAIL_FILL#" />
  <path

     d="m 117.98464,163.47123 c 6.62372,-3.33815 10.83097,-3.99853 16.98721,-3.90419 4.22163,0.0653 8.78848,1.45137 11.96728,2.64875 4.55246,1.71262 10.93166,4.35412 14.5204,5.283 7.72408,1.95935 16.03069,-0.28302 19.68417,-2.76486 a 12.61455,12.728534 0 0 0 5.24287,-7.98255 29.961355,30.232083 0 0 0 0,-12.29312 c -3.2723,-15.66757 -16.642,-24.23066 -29.77436,-34.52089 -1.04282,-0.82728 -2.15757,-1.61828 -3.20758,-2.38751 -32.11892,-22.663177 -13.07483,-49.941718 3.41614,-34.673282 a 27.724681,27.9752 0 0 0 7.99018,4.811299 c 2.92709,1.023217 5.53774,2.177058 7.65934,4.521024 a 53.48598,53.969276 0 0 0 5.7535,5.246711 14.275873,14.404869 0 0 0 3.43053,1.886784 1.5462533,1.5602251 0 0 0 1.37365,-0.341073 c 0.20856,-0.203192 0.0935,-0.899851 -0.0719,-1.298978 -1.26577,-3.040625 -2.78326,-6.001424 -3.82608,-9.121874 -1.11474,-3.338157 -3.0997,-6.371525 -3.40895,-10.014469 a 4.8832837,4.9274087 0 0 0 -1.28015,-2.481846 34.902173,35.217547 0 0 0 -3.66786,-3.730027 c -6.47269,-5.580526 -13.82278,-8.59938 -22.4818,-7.060926 -15.92282,2.837433 -34.708,31.422209 -3.07813,62.104217 6.10591,5.96514 9.68027,11.55292 11.38474,16.64724 a 2.7329128,2.7576072 0 0 1 -4.35827,2.99708 c -12.63613,-11.07397 -24.35889,-13.38891 -42.21631,-13.78803 -23.797921,-0.52975 -43.151259,-7.22058 -52.55104,-9.60083 -1.668515,-0.4209 -2.812024,0.72569 -2.740105,2.78663 a 21.251993,21.444025 0 0 0 1.589405,7.33669 c 2.502772,6.85774 7.127149,13.06235 12.326875,18.14215 5.343563,5.2322 14.189571,9.70243 22.00714,13.21475 a 3.8476536,3.8824207 0 0 1 -0.316443,7.25686 c -5.149383,1.78519 -10.320342,3.38895 -12.492288,4.64439 -1.395224,0.79826 -2.474005,2.22786 -0.985287,3.43975 a 23.73319,23.947642 0 0 0 13.585453,4.60811 c 12.23338,-0.0363 21.83454,-8.75903 27.53769,-11.61098 z"
     id="path79"
     style="stroke-width:0.72243;fill:#SWAN_FILL#" />
  <path

     d="m 168.70894,173.72518 a 37.138847,37.474431 0 0 1 -10.01828,-1.40058 c -2.94867,-0.81277 -4.96959,-1.7997 -8.68779,-2.98257 -3.08531,-0.98693 -8.47203,-2.61247 -12.37721,-2.69955 a 35.650128,35.97226 0 0 0 -11.88098,1.67633 c -1.3305,0.41365 -7.01927,2.90275 -13.57826,7.77936 a 61.411427,61.966337 0 0 1 -11.69399,6.53117 c -2.006538,0.81277 -1.43838,2.98983 3.3586,4.23801 8.36415,2.17706 17.1742,-7.81564 29.54423,-8.50504 13.03168,-0.72569 23.73319,4.61536 34.48504,0.39913 4.12814,-1.61828 5.27884,-2.30043 7.85353,-4.10739 0.81987,-0.57329 0.0431,-1.89404 -0.92775,-1.65456 a 26.315074,26.552855 0 0 1 -6.07714,0.72569 z"
     id="path81"
     style="stroke-width:0.72243;fill:#SWAN_FILL#" />
</svg>
`;

export const swanAvailableVehicle = swanMarkerSvgString
  .replace('#OUTER_RING_FILL#', swanBlue)
  .replace('#INNER_RING_FILL#', swanWhite)
  .replace('#SWAN_TAIL_FILL#', swanOrange)
  .replace('#SWAN_FILL#', swanBlue);

export const swanBusyVehicle = swanMarkerSvgString
  .replace('#OUTER_RING_FILL#', swanOrange)
  .replace('#INNER_RING_FILL#', swanWhite)
  .replace('#SWAN_TAIL_FILL#', swanOrange)
  .replace('#SWAN_FILL#', swanBlue);

export const swanAssignedVehicle = swanMarkerSvgString
  .replace('#OUTER_RING_FILL#', swanBlue)
  .replace('#INNER_RING_FILL#', swanBlue)
  .replace('#SWAN_TAIL_FILL#', swanWhite)
  .replace('#SWAN_FILL#', swanWhite);

export const swanAvailableVehicleDataUrl = `data:image/svg+xml;base64,${btoa(
  swanAvailableVehicle
)}`;

export const swanBusyVehicleDataUrl = `data:image/svg+xml;base64,${btoa(
  swanBusyVehicle
)}`;

export const swanAssignedVehicleDataUrl = `data:image/svg+xml;base64,${btoa(
  swanAssignedVehicle
)}`;
