<ng-container *ngIf="hasContracts | async as contracts">
  <mat-form-field class="w-full">
    <mat-label [ngClass]="matLabelClasses ? 'text-sm font-light' : ''"
      >Contract name</mat-label
    >
    <mat-select
      [required]="required"
      [formControl]="contractControl"
      required
      placeholder="Contract"
    >
      <mat-option
        *ngFor="let c of contracts; let i = index"
        [value]="
          mode === 'id'
            ? c.contractId
            : mode === 'reference'
            ? c.contractReference
            : c
        "
      >
        {{ c.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="contracts.length > 0">Contract is required</mat-error>
    <mat-error *ngIf="contracts.length === 0">
      No available contracts to select from</mat-error
    >
  </mat-form-field>
</ng-container>
