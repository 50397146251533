import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { IssueModel, ReferenceDataItemModel } from '@fleet/model';
import { ReferenceService } from '@fleet/reference';

@Component({
  selector: 'fleet-product-type-selector',
  templateUrl: './product-type-selector.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductTypeSelectorComponent),
      multi: true,
    },
  ],
})
export class ProductTypeSelectorComponent
  implements OnInit, ControlValueAccessor
{
  @Input() inSearchForm = false;
  productTypes: ReferenceDataItemModel[] = [];
  issues: IssueModel[];
  loading: boolean;
  productTypeControl = new UntypedFormControl();

  constructor(
    private referenceService: ReferenceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.referenceService.productTypes$.subscribe(
      (resp: ReferenceDataItemModel[]) => {
        if (resp) {
          this.productTypes = resp;
        }

        this.changeDetectorRef.markForCheck();
      }
    );

    this.productTypeControl.valueChanges.subscribe((value: string[]) => {
      this.onChange(value ? value : null);
      this.onTouched();

      this.changeDetectorRef.markForCheck();
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    //reset patching null
    if (obj === null) {
      this.productTypeControl.reset(null);
    } else {
      this.productTypeControl.setValue(obj);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
