import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobStatusSelectorComponent } from './job-status-selector.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';

@NgModule({
  declarations: [JobStatusSelectorComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatListModule,
    TitleCaseAndCleanPipeModule,
  ],
  exports: [JobStatusSelectorComponent],
})
export class JobStatusSelectorModule {}
