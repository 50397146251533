/* eslint-disable no-empty */
import { Store } from '@ngrx/store';

import * as userAuthActions from './user-auth.actions';
import { UserAuthActionTypes } from './user-auth.actions';
import * as fromUserAuth from './user-auth.reducer';

import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { DateTime, Interval } from 'luxon';
import { of, timer } from 'rxjs';

import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { UserInitialRouteService } from '../../services/user-initial-route.service';

import {
  ApiResponse,
  FunctionPermissionModel,
  LoginModel,
  NetworkModel,
  OrganisationUserModel,
  ValidationModel,
  VerificationModel,
} from '@fleet/model';
import { WebsocketService } from '@fleet/socket';
import { JwtHelperService, successNotification } from '@fleet/utilities';
import { AuthService } from '../../services';

import { FleetNavigationService } from '@fleet/navigation';

import { HttpResponse } from '@angular/common/http';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { preserveAndClearKeys } from '../../services/local-storage-manager';
import { GetInvitation } from './user-auth.actions';
import { getUserAuthState, verificationModel } from './user-auth.selectors';
import { NetworkGroupService } from '@fleet/network-group';
import { ProductConfigurationService } from '@fleet/product-configuration';

type UserModel = OrganisationUserModel | NetworkModel;

@Injectable()
export class UserAuthEffects {
  renderer: Renderer2;
  fleetProduct: string;
  constructor(
    private store$: Store<fromUserAuth.UserAuthState>,
    private router: Router,
    private actions$: Actions,
    private service: AuthService,
    private jwtHelper: JwtHelperService,
    private socketService: WebsocketService,
    private rendererFactory: RendererFactory2,
    private onScreenNotificationService: OnscreenNotificationService,
    private networkGroupService: NetworkGroupService,
    private productConfigurationService: ProductConfigurationService,
    private userInitialRouteService: UserInitialRouteService,
    private fleetNNavigationService: FleetNavigationService,
    @Inject('env') env: any
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.fleetProduct = env.fleetProduct;
  }

  //alex this is the change
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.Login),
      map((action: userAuthActions.Login) => action.payload),
      mergeMap((payload) =>
        this.service.signIn(payload.username, payload.password).pipe(
          mergeMap((resp: any) => {
            const authHeader = resp.headers.get('Authorization');
            const jwt = authHeader.replace('Bearer ', '');
            //set permissions
            this.service.setPermissions(resp.body.data.permissions);
            if (this.fleetProduct === 'DRIVER') {
              try {
                (window as any).MobileCallBack.postMessage(
                  JSON.stringify({
                    action: 'SIGNIN',
                    data: {
                      loginModel: {
                        jwtToken: jwt,
                        username: payload.username,
                        password: payload.password,
                      } as LoginModel,
                    },
                  })
                );
              } catch (error) {
                console.log(error);
              }

              try {
                (window as any).flutter_inappwebview.callHandler(
                  'MobileCallBack',

                  JSON.stringify({
                    action: 'SIGNIN',
                    data: {
                      loginModel: {
                        jwtToken: jwt,
                        username: payload.username,
                        password: payload.password,
                      } as LoginModel,
                    },
                  })
                );
              } catch (error) {
                console.log(error);
              }
            }

            return [
              new userAuthActions.SetJWT(jwt),
              new userAuthActions.LoginSuccess(jwt),
            ];
          }),
          catchError((error) => of(new userAuthActions.LoginFailure(error)))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.LoginSuccess),
      map((action: userAuthActions.LoginSuccess) => action.payload),
      map((jwt) => {
        const decode = this.jwtHelper.decodeToken(jwt);

        return new userAuthActions.GetPermissions({
          decodedJwt: decode,
          route: true,
        });
      })
    )
  );

  getPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.GetPermissions),
      mergeMap((action: any) =>
        this.service.getPermissions().pipe(
          map(
            (resp: ApiResponse<FunctionPermissionModel[]>) =>
              new userAuthActions.GetPermissionsSuccess({
                permissions: resp.data,
                decodedJwt: action.payload.decodedJwt,
                route: action.payload.route,
              })
          ),
          catchError((error) =>
            of(new userAuthActions.GetPermissionsFailure(error))
          )
        )
      )
    )
  );

  getPermissionsSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.GetPermissionsSuccess),
      tap((action: userAuthActions.GetPermissionsSuccess) => {
        this.service.setPermissions(action.payload.permissions);
      }),
      map(
        (action: userAuthActions.GetPermissionsSuccess) =>
          new userAuthActions.GetUser({
            decodedJwt: action.payload.decodedJwt,
            route: action.payload.route,
          })
      )
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.GetUser),
      map((action: userAuthActions.GetUser) => action.payload),
      mergeMap((payload: any) =>
        this.service.getUser(payload.decodedJwt).pipe(
          map(
            (resp: ApiResponse<any>) =>
              new userAuthActions.GetUserSuccess({
                user: resp.data,
                route: payload.route,
              })
          ),
          catchError((error) => of(new userAuthActions.GetUserFailure(error)))
        )
      )
    )
  );

  getUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.GetUserSuccess),
        map((action: userAuthActions.GetUserSuccess) => action.payload),
        tap((payload: { user: any; route: boolean }) => {
          // this.socketService.init(
          //   `organisation.${user.organisationId}-${user.organisationUserId}`
          // );

          const jwt = this.service.accessToken;
          const decode = this.jwtHelper.decodeToken(jwt);

          this.service.setUser(payload.user);

          this.fleetNNavigationService.configureMenuForUser(
            decode.type,
            this.service.permissions.value,
            payload.user
          );

          // set network group service if not network user
          if (
            decode.type !== 'NETWORK' &&
            this.productConfigurationService.networkConfig
          ) {
            this.networkGroupService.getNetworkGroup(
              this.productConfigurationService.networkConfigValue
                .networkGroupId,
              this.productConfigurationService.networkConfigValue.networkId
            );
          }

          //check for kiosk mode in business and route if set
          if (this.fleetProduct === 'BUSINESS') {
            if (localStorage.getItem('kiosk_mode') === 'true') {
              this.router.navigate(['/kiosk']);
            }
          }
          if (payload.route) {
            this.userInitialRouteService.routeUser(
              decode.type,
              this.service.permissions.value,
              payload.user
            );
          }
        })
      ),
    { dispatch: false }
  );

  setJWT$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.SetJWT),
        map((action: userAuthActions.SetJWT) => action.jwt),
        tap((jwt) => {
          if (jwt) {
            this.service.accessToken = jwt;

            const decodedToken = this.jwtHelper.decodeToken(jwt);
            localStorage.setItem('username', decodedToken.username);

            const start = DateTime.now();
            const finish = DateTime.fromMillis(decodedToken.exp);

            const millisecondsToLock = Interval.fromDateTimes(
              start,
              finish
            ).toDuration().milliseconds;

            const lockTimer$ = timer(millisecondsToLock);
            lockTimer$.subscribe((tick) => {
              this.store$.dispatch(new userAuthActions.Lock());
            });
          }
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.Logout),
        tap(() => {
          if (this.fleetProduct === 'DRIVER') {
            try {
              (window as any).MobileCallBack.postMessage(
                JSON.stringify({ action: 'LOGOUT' })
              );
            } catch (error) {}

            try {
              (window as any).flutter_inappwebview.callHandler(
                'MobileCallBack',
                JSON.stringify({
                  action: 'LOGOUT',
                })
              );
            } catch (error) {}
          }
          this.socketService.close();
          this.service.setUser(null);
          this.fleetNNavigationService.clearNavigation();
          this.router.navigate(['/auth/login']);

          preserveAndClearKeys();
        })
      ),
    { dispatch: false }
  );

  autologin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.AutoLogin),
      map((action: userAuthActions.AutoLogin) => action.jwt),
      mergeMap((oldJwt) =>
        this.service.checkToken().pipe(
          mergeMap((newJwt: any) => {
            let actions = [];
            if (newJwt == 'error') {
              actions.push(new userAuthActions.AutoLoginFailure());
            } else {
              const decode = this.jwtHelper.decodeToken(newJwt);
              if (newJwt != oldJwt) {
                actions = [
                  new userAuthActions.SetJWT(newJwt),
                  new userAuthActions.GetPermissions({
                    decodedJwt: decode,
                    route: false,
                  }),
                ];
              } else {
                actions = [
                  new userAuthActions.GetPermissions({
                    decodedJwt: decode,
                    route: false,
                  }),
                ];
              }
            }

            //route user

            return actions;
          }),
          catchError((error: any) => {
            return of(new userAuthActions.AutoLoginFailure());
          })
        )
      )
    )
  );

  Lock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.Lock),
        tap(() => {
          this.socketService.close();
          this.renderer.addClass(document.body, 'in_state-locked');
        })
      ),
    { dispatch: false }
  );

  unLock$ = this.actions$.pipe(
    ofType(UserAuthActionTypes.UnLock),
    map((action: userAuthActions.UnLock) => action.payload),
    mergeMap((payload: any) =>
      this.service.signIn(payload.username, payload.password).pipe(
        mergeMap((resp: any) => {
          const jwtToken = resp.headers.get('Authentication');

          return [
            new userAuthActions.SetJWT(jwtToken),
            new userAuthActions.UnLockSuccess(),
          ];
        }),
        catchError((error) => of(new userAuthActions.UnLockFailure(error)))
      )
    )
  );

  unLockSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.UnLockSuccess),
        tap(() => {
          this.renderer.removeClass(document.body, 'in_state-locked');
        })
      ),
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.ForgotPassword),
      map((action: userAuthActions.ForgotPassword) => action.payload),
      mergeMap((payload) =>
        this.service.forgotPassword(payload).pipe(
          map(
            (resp: ApiResponse<VerificationModel> | any) =>
              new userAuthActions.ForgotPasswordSuccess(resp.data)
          ),
          catchError((error) =>
            of(new userAuthActions.ForgotPasswordFailure(error))
          )
        )
      )
    )
  );

  forgotPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.ForgotPasswordSuccess),
        tap(() => this.router.navigate(['/auth/verify']))
      ),
    { dispatch: false }
  );

  newPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.NewPassword),
      map((action: userAuthActions.NewPassword) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([payload, verificationModel]) => {
        const loginModel = {
          username: verificationModel.email,
          password: payload.password,
          verificationToken: verificationModel.token,
        };
        return this.service.resetPassword(loginModel).pipe(
          mergeMap((resp: any) => {
            const authHeader = resp.headers.get('Authorization');
            const jwt = authHeader.replace('Bearer ', '');
            return [
              new userAuthActions.SetJWT(jwt),
              new userAuthActions.LoginSuccess(jwt),
            ];
          }),
          catchError((error) =>
            of(new userAuthActions.NewPasswordFailure(error))
          )
        );
      })
    )
  );

  newPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.NewPasswordSuccess),
        tap(() => {
          // this.snackBar.open('Password Successfully Reset', 'Dismiss', {
          //   duration: 3000,
          // });
          this.router.navigate(['/auth/login']);
          try {
            (window as any).MobileCallBack.postMessage(
              JSON.stringify({
                action: 'LOGOUT',
              })
            );
          } catch (error) {}

          try {
            (window as any).flutter_inappwebview.callHandler(
              'MobileCallBack',
              JSON.stringify({
                action: 'LOGOUT',
              })
            );
          } catch (error) {}
        })
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.resetPassword.type),
      map((action: any) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([payload, verificationModel]) => {
        const loginModel = {
          username: verificationModel.email,
          password: payload.password,
          verificationToken: verificationModel.token,
        };
        return this.service.resetPassword(loginModel).pipe(
          mergeMap((resp: any) => {
            const authHeader = resp.headers.get('Authorization');
            if (authHeader) {
              const jwt = authHeader.replace('Bearer ', '');
              return [
                new userAuthActions.SetJWT(jwt),
                new userAuthActions.LoginSuccess(jwt),
              ];
            } else {
              this.onScreenNotificationService.setNotification({
                ...successNotification,
                title: 'Password successfully reset! ',
                subTitle: 'Please login with your new password',
              } as OnScreenNotification);
              return [new userAuthActions.Logout()];
            }
          }),
          catchError((error) => of(userAuthActions.resetPasswordFailure(error)))
        );
      })
    )
  );

  verifyCodeForEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.verifyCodeForEmailSuccess.type),
      map((action: any) => action.payload),
      tap((payload) => {
        this.router.navigate(['auth/verify/' + payload.data.activationKey]);
      })
    )
  );

  verify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.Verify),
      map((action: userAuthActions.Verify) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([payload, verification]) => {
        const verificationPayload = {
          verificationId: verification.verificationId,
          code: payload.code,
        } as VerificationModel;

        return this.service.verify(verificationPayload).pipe(
          map(
            (resp: ApiResponse<VerificationModel> | any) =>
              new userAuthActions.VerifySuccess({
                verification: resp.data,
                routedFrom: payload.routedFrom,
              })
          ),
          catchError((error) => of(new userAuthActions.VerifyFailure(error)))
        );
      })
    )
  );

  verifySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifySuccess),
        map((action: userAuthActions.VerifySuccess) => action.payload),
        tap((payload) => {
          this.router.navigate(['/auth/verified'], {
            queryParams: {
              verificationToken: payload.verification.token,
              newUser: payload.verification.newUser,
              routedFrom: payload.routedFrom,
              maskedEmail: payload.verification.maskedEmail,
              firstName: payload.verification.firstName,
              email: payload.verification.email,
              username: payload.verification.username,
              resetPassword: payload.verification.resetPassword,
            },
          });
        })
      ),
    { dispatch: false }
  );

  verifyComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifyComplete),
        map((action: userAuthActions.VerifyComplete) => action.payload),
        tap((payload) => {
          if (payload.resetPassword === 'true') {
            this.router.navigate(['auth/reset-password']);
          } else {
            if (payload.routedFrom === 'invitation') {
              this.router.navigate(['auth/signup']);
            } else if (payload.routedFrom === 'forgot-password') {
              this.router.navigate(['auth/reset-password']);
            } else if (payload.routedFrom === 'change-number') {
              this.store$.dispatch(
                new userAuthActions.ChangePhoneNumber(<LoginModel>{
                  verificationToken: payload.verificationToken,
                })
              );
            } else if (payload.routedFrom === 'change-username') {
              this.store$.dispatch(
                new userAuthActions.ChangeUsername(<LoginModel>{
                  verificationToken: payload.verificationToken,
                  username: payload.email,
                })
              );
            } else if (payload.newUser) {
              switch (this.fleetProduct) {
                case 'BUSINESS':
              }
              this.router.navigate(['/auth/plans']);
            } else {
              this.router.navigate(['auth/existing-user']);
            }
          }
          //exisitn
        })
      ),
    { dispatch: false }
  );

  changeUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.ChangeUsername),
      mergeMap((action: userAuthActions.ChangeUsername) => {
        return this.service.changeUsername(action.payload).pipe(
          map((resp: HttpResponse<any>) => {
            const jwtHeader = resp.headers.get('Authorization');
            const jwt = jwtHeader.replace('Bearer ', '');

            return new userAuthActions.ChangeUsernameSuccess({
              jwtToken: jwt,
              username: action.payload.username,
            });
          }),
          catchError((error) =>
            of(new userAuthActions.ChangeUsernameFailure(error))
          )
        );
      })
    )
  );

  changeUsernameSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.ChangeUsernameSuccess),
        map((action: userAuthActions.ChangeUsernameSuccess) => action.payload),
        tap((payload: LoginModel) => {
          localStorage.setItem('access_token', payload.jwtToken);

          try {
            (window as any).MobileCallBack.postMessage(
              JSON.stringify({
                action: 'CHANGE_USERNAME',
                data: {
                  loginModel: payload,
                },
              })
            );
          } catch (error) {
            console.log(error);
          }

          try {
            (window as any).flutter_inappwebview.callHandler(
              'MobileCallBack',
              JSON.stringify({
                action: 'CHANGE_USERNAME',
                data: {
                  loginModel: payload,
                },
              })
            );
          } catch (error) {
            console.log(error);
          }
        })
      ),
    { dispatch: false }
  );

  verifyPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.VerifyPhoneNumber),
      map((action: userAuthActions.VerifyPhoneNumber) => action.payload),
      mergeMap((payload) => {
        const includeJwt =
          payload.routedFrom === 'change-number' ? true : false;
        delete payload['routedFrom'];
        return this.service.verifyPhoneNumber(payload, includeJwt).pipe(
          map((resp: ApiResponse<VerificationModel>) => {
            return new userAuthActions.VerifyPhoneNumberSuccess(resp.data);
          }),
          catchError((error) =>
            of(new userAuthActions.VerifyPhoneNumberFailure(error))
          )
        );
      })
    )
  );

  verifyPhoneSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifyPhoneNumberSuccess),
        tap((action: userAuthActions.VerifyPhoneNumberSuccess) => {
          this.router.navigate(['auth/verify']);
        })
      ),
    { dispatch: false }
  );

  userLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.UserLookup),
      map((action: userAuthActions.UserLookup) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([payload, verification]) => {
        const lookupPayload = {
          ...payload,
          verificationToken: verification.token,
        };
        return this.service.findUser(lookupPayload).pipe(
          map(
            (resp: ApiResponse<any>) =>
              new userAuthActions.UserLookupSuccess(resp.data)
          ),
          catchError((error) =>
            of(new userAuthActions.UserLookupFailure(error))
          )
        );
      })
    )
  );

  userLookupSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.UserLookupSuccess),
        map((action: userAuthActions.UserLookupSuccess) => action.payload),
        tap((payload: ValidationModel[]) => {
          if (payload.length === 1) {
            this.store$.dispatch(
              userAuthActions.selectValidationModel({
                validationModel: payload[0],
              })
            );
            //either new or existing
          } else {
            //multiple results, user needs to select
          }
        })
      ),
    { dispatch: false }
  );

  selectValidationModel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthActions.selectValidationModel),
        tap((action) => {
          if (action.validationModel.accountCreationRequired) {
            this.router.navigate(['/auth/signup']);
          } else {
            this.router.navigate(['auth/existing-user']);
          }
        })
      ),
    { dispatch: false }
  );

  validateUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.ValidateUsername),
      mergeMap((action: userAuthActions.ValidateUsername) => {
        return this.service.usernameInUse(action.payload).pipe(
          map((resp: ApiResponse<ValidationModel>) => {
            return new userAuthActions.ValidateUsernameSuccess(resp.data);
          }),
          catchError((error) =>
            of(new userAuthActions.ValidateUsernameFailure(error))
          )
        );
      })
    )
  );

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.VerifyEmail),
      map((action: userAuthActions.VerifyEmail) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([verifyPayload, verificationModel]) => {
        const payload = Object.assign({}, verificationModel, verifyPayload);
        return this.service.verifyEmail(payload).pipe(
          map((resp: ApiResponse<VerificationModel>) => {
            return new userAuthActions.VerifyEmailSuccess(resp.data);
          }),
          catchError((error) =>
            of(new userAuthActions.VerifyEmailFailure(error))
          )
        );
      })
    )
  );

  verifyEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifyEmailSuccess),
        tap((payload) => {
          this.router.navigate(['/auth/verify']);
        })
      ),
    { dispatch: false }
  );

  getInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.GetInvitation),
      map((action: GetInvitation) => action.payload),
      tap(() => {
        localStorage.clear();
        this.socketService.close();
      }),
      mergeMap((activationKey: string) => {
        return this.service.getInvitation(activationKey).pipe(
          map(
            (resp: any) => new userAuthActions.GetInvitationSuccess(resp.data)
          ),
          catchError((error) =>
            of(new userAuthActions.GetInvitationFailure(error))
          )
        );
      })
    )
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.Signup),
      map((action: userAuthActions.Signup) => action.payload),
      withLatestFrom(this.store$.select(getUserAuthState)),
      mergeMap(([payload, state]) => {
        const signUpPayload = Object.assign({}, state.signupModel, payload);
        if (
          state.userLookupValidation &&
          state.userLookupValidation.driverLeadId
        ) {
          signUpPayload['driverLeadId'] =
            state.userLookupValidation.driverLeadId;
        }

        return this.service.signup(signUpPayload).pipe(
          mergeMap((resp: HttpResponse<ApiResponse<any>>) => {
            const jwtHeader = resp.headers.get('Authorization');
            const jwt = jwtHeader.replace('Bearer ', '');
            this.service.setPermissions(resp.body.data.permissions);

            if (this.fleetProduct === 'DRIVER') {
              //EMIT OUT TO APP SIGNIN DETAILS
              try {
                (window as any).MobileCallBack.postMessage(
                  JSON.stringify({
                    action: 'SIGNIN',
                    data: {
                      loginModel: {
                        jwtToken: jwt,
                        username: signUpPayload.username,
                        password: signUpPayload.password,
                      } as LoginModel,
                    },
                  })
                );
              } catch (error) {
                console.log(error);
              }

              try {
                (window as any).flutter_inappwebview.callHandler(
                  'MobileCallBack',

                  JSON.stringify({
                    action: 'SIGNIN',
                    data: {
                      loginModel: {
                        jwtToken: jwt,
                        username: signUpPayload.username,
                        password: signUpPayload.password,
                      } as LoginModel,
                    },
                  })
                );
              } catch (error) {
                console.log(error);
              }
            }
            const driver = resp.body.data;

            return [
              new userAuthActions.SetJWT(jwt),
              new userAuthActions.SignupSuccess(driver),
            ];
          }),
          catchError((error) => {
            return of(new userAuthActions.SignupFailure(error));
          })
        );
      })
    )
  );

  signUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.SignupSuccess),
        tap((action: userAuthActions.SignupSuccess) => {
          this.service.setPermissions(action.payload.permissions);
          this.store$.dispatch(
            new userAuthActions.GetUserSuccess({
              user: action.payload,
              route: true,
            })
          );
        })
      ),
    { dispatch: false }
  );
}
