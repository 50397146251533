import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PRODUCT_CONFIG } from './product-configuration.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ProductConfigurationModule {
  /**
   * forRoot method for setting user configuration
   *
   * @param config
   */
  static forRoot(config: any): ModuleWithProviders<ProductConfigurationModule> {
    return {
      ngModule: ProductConfigurationModule,
      providers: [
        {
          provide: PRODUCT_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
