<ng-container *ngIf="traveller">
  <ng-container *ngIf="uiMode === 'LIST'">
    <div>Current status: {{ traveller.status | titleCaseAndClean }}</div>

    <button
      *ngFor="let action of traveller | travellerAction"
      (click)="actionTraveller(action)"
      mat-flat-button
      class="bg-primary-600 text-white disabled:bg-gray-200"
    >
      <!-- <mat-icon class="icon-size-4 mt-0.5"></mat-icon> -->
      <span class="hidden sm:flex ml-2 mr-1"> {{ action.label }}</span>
    </button>
  </ng-container>

  <ng-container *ngIf="uiMode === 'MENU'">
    <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
      <button
        mat-menu-item
        (click)="actionTraveller(action)"
        *ngFor="let action of traveller | travellerAction"
      >
        {{ action.label }}
      </button>

      <div
        *ngIf="(traveller | travellerAction) === null"
        fxLayout="column"
        fxLayoutGap="6px"
        fxLayoutAlign="space-between start"
        class="p-8"
      >
        <div>Traveller status: {{ traveller.status | titleCaseAndClean }}</div>
        <div>No actions available</div>
      </div>
    </mat-menu>

    <button
      mat-icon-button
      [matMenuTriggerFor]="appMenu"
      *ngIf="uiConfiguration === 'list'; else mainButton"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <ng-template #mainButton>
      <ng-container *ngIf="traveller.status | statusChip : true as status">
        <button
          mat-flat-button
          [matMenuTriggerFor]="appMenu"
          class="text-white flex"
          [ngClass]="status.background"
          [disabled]="(traveller | travellerAction) === null"
        >
          <span> {{ traveller.status | titleCaseAndClean }}</span>
          <mat-icon
            *ngIf="(traveller | travellerAction) !== null; else noOptions"
            class="ml-3"
            >expand_more</mat-icon
          >
          <ng-template #noOptions>
            <mat-icon class="ml-3">lock</mat-icon>
          </ng-template>
        </button>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="uiMode === 'SELECT'">
    <mat-form-field class="w-full">
      <mat-label
        >Traveller is {{ traveller.status | titleCaseAndClean }}</mat-label
      >
      <mat-select [formControl]="statusControl">
        <mat-option
          *ngFor="let action of traveller | travellerAction"
          [value]="action"
        >
          <div>{{ action.label }}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</ng-container>
