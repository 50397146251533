import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  DriverModel,
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
} from '@fleet/model';

export interface PaymentMethodCardDisplayConfig {
  card: boolean;
  paddingClass: string;
}

@Component({
  selector: 'fleet-payment-method-card',
  templateUrl: './payment-method-card.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodCardComponent implements OnInit {
  _paymentMethod: PaymentMethodModel | PaymentMethodSearchResultModel | any;
  @Input() set paymentMethod(
    value: PaymentMethodModel | PaymentMethodSearchResultModel | any
  ) {
    if (value) {
      this._paymentMethod = value;
      this.paymentMethodDisplay.displayLine1 = value.card.maskedCardNumber;
      this.paymentMethodDisplay.displayLine2 = value.card.description;
      this.paymentMethodDisplay.expiry = value?.card?.expiry
        ? 'Exp ' + value?.card?.expiry
        : 'Exp ' + value?.card?.expiryMonth + '/' + value?.card?.expiryYear;
      //we know from here this is within a search result
    }
  }

  get paymentMethod() {
    return this._paymentMethod;
  }

  @Input() set iconStyles(value: string) {
    if (value) {
      this.paymentMethodDisplay.iconStyles = value;
      this.changeDetectorRef.markForCheck();
    }
  }
  @Output() deletePaymentMethod = new EventEmitter();

  @Input() displayConfig: PaymentMethodCardDisplayConfig = {
    card: true,
    paddingClass: 'p-6',
  };

  paymentMethodDisplay: {
    displayLine1: string;
    displayLine2: string;
    expiry: string;
    iconStyles: string;
  } = { displayLine1: '', displayLine2: '', expiry: '', iconStyles: '' };
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  //     Consists of PaymentMethodIConMOdule and show displayLine1 and 2
  // inputs for paymetn method searcj resuilt and paymentmethod.
  // Declare PaymentMethodDisplay(icon, displayLine1, DisplayLone2) interface and inputs set display property
}
