import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverUpcomingJobsCardComponent } from './driver-upcoming-jobs-card.component';
import { CardModule } from '@fleet/ui';
import { JobSearchListModule } from '../job-search-list';

@NgModule({
  declarations: [DriverUpcomingJobsCardComponent],
  imports: [CommonModule, JobSearchListModule, CardModule],
  exports: [DriverUpcomingJobsCardComponent],
})
export class DriverUpcomingJobsCardModule {}
