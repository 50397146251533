import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { OnscreenNotificationService } from '@fleet/ui';
import { failureNotification } from '@fleet/utilities';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  fleetProduct: string;
  xApiKey: string;
  constructor(
    private _authService: AuthService,
    @Inject('env') env: any,
    private router: Router,
    private onScreenNotitifcationService: OnscreenNotificationService
  ) {
    this.fleetProduct = env.fleetProduct;
    this.xApiKey = env.xApiKey;
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method === 'JSONP') {
      return next.handle(req);
    }
    // Clone the request object
    let newReq = req.clone();

    //apply apiKey first if traveller
    if (this.fleetProduct === 'TRAVELLER') {
      newReq = req.clone({
        setHeaders: {
          'x-api-key': this.xApiKey,
          'fleet-product': this.fleetProduct,
          'fleet-channel': 'WEB',
        },
      });
    }
    const jwt = localStorage.getItem('access_token');

    if (jwt) {
      if (!this._authService.isTokenExpired()) {
        //valid jwt
        newReq = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this._authService.accessToken,
            'fleet-product': this.fleetProduct,
            'fleet-channel': 'WEB',
          },
        });
      } else {
        //jwt but its expired
        newReq = req.clone({
          setHeaders: {
            'fleet-product': this.fleetProduct,
            'fleet-channel': 'WEB',
          },
        });
      }
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        // Catch "401 Unauthorized" resnetowrponses
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0) {
            this.onScreenNotitifcationService.setNotification({
              ...failureNotification,
              title: 'Cannot contact server',
              subTitle: 'Please try again',
            });
          } else if (error.status === 401) {
            // Sign out
            this.onScreenNotitifcationService.setNotification({
              title: 'Session has expired',
              subTitle: 'Please log in again',
              autoDismiss: 3000,
              icon: { color: 'warn', name: 'warning' },
            });
            this._authService.logout();

            // Reload the app
            //location.reload();
          } else if (error.status === 503) {
            //redirect to 503 page;
            this.router.navigate(['/maintenance']);
          }
        }

        return throwError(error);
      })
    );
  }
}
