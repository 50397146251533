import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import {
  LifecycleTransitionActionPipeModule,
  LifecycleTransitionFilterPipeModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { StatusChipModule } from '@fleet/ui';
import { LifecycleTransitionsComponent } from './lifecycle-transitions.component';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';

@NgModule({
  declarations: [LifecycleTransitionsComponent],
  imports: [
    CommonModule,
    MatIconModule,

    TitleCaseAndCleanPipeModule,

    MatMenuModule,
    MatButtonModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
    StatusChipModule,
    LifecycleTransitionActionPipeModule,
    LifecycleTransitionFilterPipeModule,
    InternationalizationDatePipeModule,
  ],
  exports: [LifecycleTransitionsComponent],
})
export class LifecycleTransitionsModule {}
