import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetterIconComponent } from './letter-icon.component';

@NgModule({
  declarations: [LetterIconComponent],
  imports: [CommonModule],
  exports: [LetterIconComponent],
})
export class LetterIconModule {}
