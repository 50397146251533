import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemovePropertiesPipe } from './remove-properties.pipe';

@NgModule({
  declarations: [RemovePropertiesPipe],
  imports: [CommonModule],
  exports: [RemovePropertiesPipe],
})
export class RemovePropertiesPipeModule {}
