import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  BaseSearchState,
  initialBaseSearchState,
  JobSearchResultModel,
} from '@fleet/model';
import { pageDataFromSearchResultHeaders } from '@fleet/utilities';
import { BehaviorSubject, map, Subscription } from 'rxjs';

import { JobApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import { NetworkGroupService } from '@fleet/network-group';

@Injectable()
export class JobSearchService {
  jobSearchState: BehaviorSubject<BaseSearchState<JobSearchResultModel>> =
    new BehaviorSubject(initialBaseSearchState);
  private apiCallSubscription: Subscription;

  get jobSearch$() {
    return this.jobSearchState.asObservable();
  }

  constructor(
    private jobApiService: JobApiService,
    private networkGroupService: NetworkGroupService,
    private authService: AuthService
  ) {}

  getJobSearch() {
    return this.jobSearchState.asObservable();
  }

  searchJobs(params: any) {
    //Job search can take a while to return, need to stop searching if its loading.
    if (!this.jobSearchState.value.searching) {
      if (this.authService.networkId) {
        params['networkId'] = this.authService.networkId;
      }
      if (this.networkGroupService.networkGroupId) {
        params['networkGroupId'] = this.networkGroupService.networkGroupId;
      }
      this.jobSearchState.next({
        ...this.jobSearchState.value,
        searching: true,
        issues: [],
      });

      if (this.apiCallSubscription) {
        this.apiCallSubscription.unsubscribe();
      }

      this.apiCallSubscription = this.jobApiService
        .searchJobs(params)
        .subscribe({
          next: (
            resp: HttpResponse<ApiResponse<JobSearchResultModel[]>> | any
          ) => {
            let jobs = [];
            if (params['offset'] === 0) {
              // FIRST PAGE
              jobs = resp.body.data;
            } else {
              // SUBSEQUENT PAGES
              jobs = [...this.jobSearchState.value.data, ...resp.body.data];
            }

            this.jobSearchState.next({
              ...this.jobSearchState.value,
              data: jobs,
              searching: false,
              pageData: pageDataFromSearchResultHeaders(resp.headers, params),
            });
          },
          error: (error) => {
            this.jobSearchState.next({
              ...this.jobSearchState.value,
              issues: error,
              searching: false,
            });
          },
        });
    }
  }

  get pageDataParams$() {
    return this.jobSearch$.pipe(
      map((search: BaseSearchState<JobSearchResultModel>) => {
        return search.pageData.params;
      })
    );
  }

  getNextPage() {
    // TEST IF NOT LAST PAGE
    if (
      this.jobSearchState.value?.pageData?.currentPage !=
      this.jobSearchState.value?.pageData?.totalPages
    ) {
      //GET NEXT PAGE
      const newOffset = String(
        this.jobSearchState.value.pageData.currentPage *
          Number(this.jobSearchState.value.pageData.params.limit)
      );
      const newParams = {
        ...this.jobSearchState.value.pageData.params,
        offset: newOffset,
      };

      this.searchJobs(newParams);
    }
  }

  removeParamAndSearch(param: string) {
    const params = Object.assign({}, this.jobSearchState.value.pageData.params);

    if (param === 'fromDate-toDate') {
      delete params['fromDate'];
      delete params['toDate'];
    } else {
      delete params[param];
    }

    this.searchJobs(params);
  }

  setJobPreview(job: any) {
    this.jobSearchState.next({
      ...this.jobSearchState.value,
      selectedPreview: job,
    });
  }

  clearState() {
    this.jobSearchState.next(initialBaseSearchState);
  }

  removeTermAndReturnParamSearchTerms(term: string) {
    const params = Object.assign({}, this.jobSearchState.value.pageData.params);
    delete params[term];

    return params;
  }
}

//TEST JOBS
// jobs = [
//   {
//     jobId: 'a3a077b22',
//     productType: 'TRAVELLER',
//     channelType: 'APP',
//     serviceClassType: 'TAXI',
//     serviceLineType: 'BOOKING',
//     pricingMethodType: 'METERED',
//     status: 'FINISHED',
//     paymentStatus: 'PAID',
//     locationStatus: 'ARRIVED',
//     locationType: 'DROPOFF',
//     booked: '2023-08-01T08:19:25.285+08:00',
//     requestedPickup: '2023-08-01T08:19:25.289+10:00',
//     finished: '2023-08-01T09:19:25.289+10:00',

//     collected: '2023-08-02T08:19:26.379+08:00',
//     expiry: '2023-08-01T08:49:25.289+08:00',
//     extendConfirmation: '2023-08-01T08:44:25.289+08:00',
//     driverId: 'a28dc1621',
//     driverFirstName: 'Wayne',
//     driverLastName: 'Shepard Generated',
//     driverAuthorityNumber: '82404625',
//     organisationId: 'a2a6f64ff',
//     organisationName: 'Evelyn Perry Office supplies',
//     organisationGroupId: 'a26afa76c',
//     organisationGroupName: 'Myron Cuthbert Signs',
//     organisationGroupLegalName: 'Myron Cuthbert Signs Pty Ltd',
//     organisationGroupAbn: '09546142057',
//     organisationGroupCode: 'ACYLPWSV',
//     organisationGroupType: 'RELATED_ENTITY',
//     organisationStaffId: 'a2a6f64ff',
//     organisationStaffName: 'Mary Shepard',
//     customerReference: 'UOX78417US',
//     vehicleId: 'a2a6f64ff',
//     plateNumber: 'TAXI00007',
//     totalFare: '61.92',
//     totalFareSubsidy: '0.00',
//     totalSale: '61.92',
//     primarySale: '61.92',
//     primarySaleTotalFees: '6.19',
//     primarySaleTotalSurcharge: '0.00',
//     primarySaleTotalDue: '68.11',
//     primarySaleTotalTax: '6.18',
//     startStreetNumber: '139',
//     startStreetName: 'James St',
//     startLocality: 'Perth CBD',
//     startRegionCode: 'WA',
//     startPostcode: '6000',
//     startCountryCode: 'AUS',
//     startLatitude: '-31.949615',
//     startLongitude: '115.858921',
//     endLocality: 'Perth Airport',
//     endRegionCode: 'WA',
//     endPostcode: '6105',
//     endCountryCode: 'AUS',
//     endLatitude: '-31.945072',
//     endLongitude: '115.974477',
//     distanceInKm: '8.735',
//     networkServiceId: 'a17179149',
//     networkServiceDisplayName: 'Standard',
//     networkConditionIds: ['a305b9147'],
//     numberOfTravellers: 1,
//     dispatchSlaStartTime: '2023-08-01T08:19:25.000+08:00',
//     dispatchSlaLevelOne: '2023-08-01T08:24:25.289+08:00',
//     dispatchSlaLevelTwo: '2023-08-01T08:29:25.289+08:00',
//     dispatchSlaLevelThree: '2023-08-01T08:34:25.289+08:00',
//     pickupSuburb: 'Perth CBD',
//     destinationSuburb: 'Perth Airport',
//   },
//   {
//     jobId: 'a0bd85890',
//     productType: 'CONTACT_CENTRE_AGENT',
//     channelType: 'WEB',
//     serviceClassType: 'TAXI',
//     serviceLineType: 'BOOKING',
//     pricingMethodType: 'METERED',
//     status: 'CANCELLED',
//     paymentStatus: 'PAYMENT_DUE',
//     booked: '2023-08-01T08:00:06.632+08:00',
//     requestedPickup: '2023-07-31T12:22:00.000+08:00',
//     finished: '2023-08-01T00:19:08.126+08:00',
//     expiry: '2023-08-01T00:52:00.000+08:00',
//     extendConfirmation: '2023-08-02T13:47:00.000+08:00',
//     driverId: 'a26afa76c',
//     driverFirstName: 'Carmen',
//     driverLastName: 'Howell Generated',
//     driverAuthorityNumber: '12276402',
//     travellerId: 'a3647bd8f',
//     travellerFirstName: 'sdfdsfdsfdsf',
//     vehicleId: 'a17179149',
//     plateNumber: 'TAXI00001',
//     startPlaceName: 'DFO Perth',
//     startStreetNumber: '11',
//     startStreetName: 'High St',
//     startLocality: 'Perth Airport',
//     startRegionCode: 'WA',
//     startPostcode: '6105',
//     startCountryCode: 'AUS',
//     startLatitude: '-31.938372',
//     startLongitude: '115.948604',
//     endPlaceName: 'As Directed',
//     endLocality: 'Unknown',
//     networkServiceId: 'a17179149',
//     networkServiceDisplayName: 'Standard',
//     numberOfTravellers: 1,
//     dispatchSlaStartTime: '2023-08-02T12:22:00.000+08:00',
//     dispatchSlaLevelOne: '2023-08-02T12:27:00.000+08:00',
//     dispatchSlaLevelTwo: '2023-08-02T12:32:00.000+08:00',
//     dispatchSlaLevelThree: '2023-08-02T12:37:00.000+08:00',
//     pickupPlaceName: 'DFO Perth',
//     pickupSuburb: 'Perth Airport',
//     destinationPlaceName: 'As Directed',
//     destinationSuburb: 'Unknown',
//   },
//   {
//     jobId: 'a0bd85890',
//     productType: 'CONTACT_CENTRE_AGENT',
//     channelType: 'WEB',
//     serviceClassType: 'TAXI',
//     serviceLineType: 'BOOKING',
//     pricingMethodType: 'METERED',
//     status: 'FINISHED',
//     paymentStatus: 'PAYMENT_DUE',
//     booked: '2023-08-01T08:00:06.632+08:00',
//     requestedPickup: '2023-07-31T12:22:00.000+08:00',
//     finished: '2023-08-01T00:19:08.126+08:00',
//     expiry: '2023-08-01T00:52:00.000+08:00',
//     extendConfirmation: '2023-08-02T13:47:00.000+08:00',
//     driverId: 'a26afa76c',
//     driverFirstName: 'Carmen',
//     driverLastName: 'Howell Generated',
//     driverAuthorityNumber: '12276402',
//     travellerId: 'a3647bd8f',
//     travellerFirstName: 'sdfdsfdsfdsf',
//     vehicleId: 'a17179149',
//     plateNumber: 'TAXI00001',
//     startPlaceName: 'DFO Perth',
//     startStreetNumber: '11',
//     startStreetName: 'High St',
//     startLocality: 'Perth Airport',
//     startRegionCode: 'WA',
//     startPostcode: '6105',
//     startCountryCode: 'AUS',
//     startLatitude: '-31.938372',
//     startLongitude: '115.948604',
//     endPlaceName: 'As Directed',
//     endLocality: 'Unknown',
//     networkServiceId: 'a17179149',
//     networkServiceDisplayName: 'Standard',
//     numberOfTravellers: 1,
//     dispatchSlaStartTime: '2023-08-02T12:22:00.000+08:00',
//     dispatchSlaLevelOne: '2023-08-02T12:27:00.000+08:00',
//     dispatchSlaLevelTwo: '2023-08-02T12:32:00.000+08:00',
//     dispatchSlaLevelThree: '2023-08-02T12:37:00.000+08:00',
//     pickupPlaceName: 'DFO Perth',
//     pickupSuburb: 'Perth Airport',
//     destinationPlaceName: 'As Directed',
//     destinationSuburb: 'Unknown',
//   },
//   {
//     jobId: 'a3a077b22',
//     productType: 'TRAVELLER',
//     channelType: 'APP',
//     serviceClassType: 'TAXI',
//     serviceLineType: 'BOOKING',
//     pricingMethodType: 'METERED',
//     status: 'FINISHED',
//     paymentStatus: 'PAID',
//     locationStatus: 'ARRIVED',
//     locationType: 'DROPOFF',
//     booked: '2023-08-01T08:19:25.285+08:00',
//     requestedPickup: '2023-08-01T08:19:25.289+10:00',
//     collected: '2023-08-02T08:19:26.379+08:00',
//     expiry: '2023-08-01T08:49:25.289+08:00',
//     extendConfirmation: '2023-08-01T08:44:25.289+08:00',
//     driverId: 'a28dc1621',
//     driverFirstName: 'Wayne',
//     driverLastName: 'Shepard Generated',
//     driverAuthorityNumber: '82404625',
//     organisationId: 'a2a6f64ff',
//     organisationName: 'Evelyn Perry Office supplies',
//     organisationGroupId: 'a26afa76c',
//     organisationGroupName: 'Myron Cuthbert Signs',
//     organisationGroupLegalName: 'Myron Cuthbert Signs Pty Ltd',
//     organisationGroupAbn: '09546142057',
//     organisationGroupCode: 'ACYLPWSV',
//     organisationGroupType: 'RELATED_ENTITY',
//     organisationStaffId: 'a2a6f64ff',
//     organisationStaffName: 'Mary Shepard',
//     customerReference: 'UOX78417US',
//     vehicleId: 'a2a6f64ff',
//     plateNumber: 'TAXI00007',
//     totalFare: '61.92',
//     totalFareSubsidy: '0.00',
//     totalSale: '61.92',
//     primarySale: '61.92',
//     primarySaleTotalFees: '6.19',
//     primarySaleTotalSurcharge: '0.00',
//     primarySaleTotalDue: '68.11',
//     primarySaleTotalTax: '6.18',
//     startStreetNumber: '139',
//     startStreetName: 'James St',
//     startLocality: 'Perth CBD',
//     startRegionCode: 'WA',
//     startPostcode: '6000',
//     startCountryCode: 'AUS',
//     startLatitude: '-31.949615',
//     startLongitude: '115.858921',
//     endLocality: 'Perth Airport',
//     endRegionCode: 'WA',
//     endPostcode: '6105',
//     endCountryCode: 'AUS',
//     endLatitude: '-31.945072',
//     endLongitude: '115.974477',
//     distanceInKm: '8.735',
//     networkServiceId: 'a17179149',
//     networkServiceDisplayName: 'Standard',
//     networkConditionIds: ['a305b9147'],
//     numberOfTravellers: 1,
//     dispatchSlaStartTime: '2023-08-01T08:19:25.000+08:00',
//     dispatchSlaLevelOne: '2023-08-01T08:24:25.289+08:00',
//     dispatchSlaLevelTwo: '2023-08-01T08:29:25.289+08:00',
//     dispatchSlaLevelThree: '2023-08-01T08:34:25.289+08:00',
//     pickupSuburb: 'Perth CBD',
//     destinationSuburb: 'Perth Airport',
//   },
//   {
//     jobId: 'a22efe9d9',
//     productType: 'TRAVELLER',
//     channelType: 'APP',
//     serviceClassType: 'TAXI',
//     serviceLineType: 'BOOKING',
//     pricingMethodType: 'METERED',
//     status: 'FINISHED',
//     paymentStatus: 'PAID',
//     locationStatus: 'ARRIVED',
//     locationType: 'DROPOFF',
//     booked: '2023-08-01T08:19:20.657+08:00',
//     requestedPickup: '2023-08-02T08:19:20.662+08:00',
//     collected: '2023-08-02T08:59:21.855+08:00',
//     finished: '2023-08-02T02:19:24.244+08:00',
//     expiry: '2023-08-01T08:49:20.662+08:00',
//     extendConfirmation: '2023-08-01T08:44:20.662+08:00',
//     driverId: 'a28dc1621',
//     driverFirstName: 'Wayne',
//     driverLastName: 'Shepard Generated',
//     driverAuthorityNumber: '82404625',
//     organisationId: 'a2a6f64ff',
//     organisationName: 'Evelyn Perry Office supplies',
//     organisationGroupId: 'a0f981623',
//     organisationGroupName: 'Jackie Millen Realty',
//     organisationGroupLegalName: 'Jackie Millen Realty Pty Ltd',
//     organisationGroupAbn: '09383982128',
//     organisationGroupCode: 'BAUFZYPC',
//     organisationGroupType: 'RELATED_ENTITY',
//     organisationStaffId: 'a2a6f64ff',
//     organisationStaffName: 'Mary Shepard',
//     customerReference: 'YIC95322OA',
//     vehicleId: 'a2a6f64ff',
//     plateNumber: 'TAXI00007',
//     totalFare: '61.92',
//     totalFareSubsidy: '0.00',
//     totalSale: '61.92',
//     primarySale: '61.92',
//     primarySaleTotalFees: '6.19',
//     primarySaleTotalSurcharge: '0.00',
//     primarySaleTotalDue: '68.11',
//     primarySaleTotalTax: '6.18',
//     startStreetNumber: '139',
//     startStreetName: 'James St',
//     startLocality: 'Perth CBD',
//     startRegionCode: 'WA',
//     startPostcode: '6000',
//     startCountryCode: 'AUS',
//     startLatitude: '-31.949615',
//     startLongitude: '115.858921',
//     endLocality: 'Perth Airport',
//     endRegionCode: 'WA',
//     endPostcode: '6105',
//     endCountryCode: 'AUS',
//     endLatitude: '-31.945072',
//     endLongitude: '115.974477',
//     distanceInKm: '8.735',
//     networkServiceId: 'a17179149',
//     networkServiceDisplayName: 'Standard',
//     networkConditionIds: ['a305b9147'],
//     numberOfTravellers: 1,
//     dispatchSlaStartTime: '2023-08-01T08:19:21.000+08:00',
//     dispatchSlaLevelOne: '2023-08-01T08:24:20.662+08:00',
//     dispatchSlaLevelTwo: '2023-08-01T08:29:20.662+08:00',
//     dispatchSlaLevelThree: '2023-08-01T08:34:20.662+08:00',
//     pickupSuburb: 'Perth CBD',
//     destinationSuburb: 'Perth Airport',
//   },
//   {
//     jobId: 'a22efe9d9',
//     productType: 'TRAVELLER',
//     channelType: 'APP',
//     serviceClassType: 'TAXI',
//     serviceLineType: 'BOOKING',
//     pricingMethodType: 'METERED',
//     status: 'FINISHED',
//     paymentStatus: 'PAID',
//     locationStatus: 'ARRIVED',
//     locationType: 'DROPOFF',
//     booked: '2023-08-01T08:19:20.657+08:00',
//     requestedPickup: '2023-08-01T08:19:20.662+08:00',
//     collected: '2023-08-01T08:19:21.855+08:00',
//     finished: '2023-08-02T02:19:24.244+08:00',
//     expiry: '2023-08-01T08:49:20.662+08:00',
//     extendConfirmation: '2023-08-01T08:44:20.662+08:00',
//     driverId: 'a28dc1621',
//     driverFirstName: 'Wayne',
//     driverLastName: 'Shepard Generated',
//     driverAuthorityNumber: '82404625',
//     organisationId: 'a2a6f64ff',
//     organisationName: 'Evelyn Perry Office supplies',
//     organisationGroupId: 'a0f981623',
//     organisationGroupName: 'Jackie Millen Realty',
//     organisationGroupLegalName: 'Jackie Millen Realty Pty Ltd',
//     organisationGroupAbn: '09383982128',
//     organisationGroupCode: 'BAUFZYPC',
//     organisationGroupType: 'RELATED_ENTITY',
//     organisationStaffId: 'a2a6f64ff',
//     organisationStaffName: 'Mary Shepard',
//     customerReference: 'YIC95322OA',
//     vehicleId: 'a2a6f64ff',
//     plateNumber: 'TAXI00007',
//     totalFare: '61.92',
//     totalFareSubsidy: '0.00',
//     totalSale: '61.92',
//     primarySale: '61.92',
//     primarySaleTotalFees: '6.19',
//     primarySaleTotalSurcharge: '0.00',
//     primarySaleTotalDue: '68.11',
//     primarySaleTotalTax: '6.18',
//     startStreetNumber: '139',
//     startStreetName: 'James St',
//     startLocality: 'Perth CBD',
//     startRegionCode: 'WA',
//     startPostcode: '6000',
//     startCountryCode: 'AUS',
//     startLatitude: '-31.949615',
//     startLongitude: '115.858921',
//     endLocality: 'Perth Airport',
//     endRegionCode: 'WA',
//     endPostcode: '6105',
//     endCountryCode: 'AUS',
//     endLatitude: '-31.945072',
//     endLongitude: '115.974477',
//     distanceInKm: '8.735',
//     networkServiceId: 'a17179149',
//     networkServiceDisplayName: 'Standard',
//     networkConditionIds: ['a305b9147'],
//     numberOfTravellers: 1,
//     dispatchSlaStartTime: '2023-08-01T08:19:21.000+08:00',
//     dispatchSlaLevelOne: '2023-08-01T08:24:20.662+08:00',
//     dispatchSlaLevelTwo: '2023-08-01T08:29:20.662+08:00',
//     dispatchSlaLevelThree: '2023-08-01T08:34:20.662+08:00',
//     pickupSuburb: 'Perth CBD',
//     destinationSuburb: 'Perth Airport',
//   },
// ] as any;
