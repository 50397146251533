import { Pipe, PipeTransform } from '@angular/core';
import {
  JobPaymentMethodModel,
  LocationModel,
  PaymentMethodModel,
  PaymentMethodTypes,
} from '@fleet/model';
import { PaymentOptionDisplay } from './job-payment-method-selector.model';
import { DateTime } from 'luxon';
import { payInVehiclePaymentOption } from './job-payment-method-selector.component';

@Pipe({
  name: 'jobPaymentOptionDisplay',
})
export class JobPaymentOptionDisplayPipe implements PipeTransform {
  transform(
    payment: JobPaymentMethodModel,
    paymentOptions: PaymentOptionDisplay[]
  ) {
    if (
      (payment?.type !== 'OTHER' ||
        (payment?.type === 'OTHER' && payment.organisationGroupId)) &&
      (payment?.paymentMethodId || payment.organisationGroupId)
    ) {
      let foundOption;

      paymentOptions.forEach((option: PaymentOptionDisplay) => {
        if (
          payment.paymentMethodId === option.jobPaymentMethod?.paymentMethodId
        ) {
          foundOption = option;
        }
      });

      if (payment.type === 'OTHER' && payment.organisationGroupId) {
        //check on
        paymentOptions.forEach((option: PaymentOptionDisplay) => {
          if (
            payment.organisationGroupId ===
            option.jobPaymentMethod?.organisationGroupId
          ) {
            foundOption = {
              ...payInVehiclePaymentOption,
              jobPaymentMethod: {
                type: 'OTHER',
                organisationGroupId: payment.organisationGroupId,
              } as JobPaymentMethodModel,
              organisationProfileGroupWhenPayingDriver: {
                displayName: option.displayName,
              },
            };
          }
        });
      }

      return foundOption;
    } else {
      return payInVehiclePaymentOption;
    }
  }
}
//   transform(
//     value: PaymentOptionDisplay[],
//     endLocation: LocationModel,
//     startTime: DateTime
//   ): PaymentOptionDisplay[] {
//     return checkPaymentOptionsForDisabled(value, endLocation, startTime);
//   }
// }

export const checkPaymentOptionsForDisabled = (
  value: PaymentOptionDisplay[],
  endLocation: LocationModel,
  startTime: DateTime
): PaymentOptionDisplay[] => {
  const results = [...value].map((payment: PaymentOptionDisplay) => {
    payment.disabledMessage = null;
    if (payment && payment.cardType && !payment.isOrganisationCard) {
      if (!endLocation) {
        return {
          ...payment,
          disabledMessage:
            'Credit cards are only available for trips with a destination',
        };
      } else if (startTime) {
        const dayDiff = startTime.diffNow('days').days;
        if (dayDiff > 7) {
          return {
            ...payment,
            disabledMessage:
              'Credit cards accepted for trips planned within the next 7 days only',
          };
        }
      }
    }
    return payment;
  });
  return results;
};
