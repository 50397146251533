import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalityAutocompleteService {
  regionCode: string;
  constructor() {}

  //   set regionCode(value) {
  //     this.reg;
  //   }
  // }
}
