import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { LoginModel } from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  nonAuthorisedHttp: HttpClient;
  headers: HttpHeaders;
  host: string;
  fleetProduct: string;
  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    @Inject('env') env: any
  ) {
    this.headers = new HttpHeaders({
      'fleet-channel': 'WEB',
      'fleet-product': env.fleetProduct,
    });
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.auth;
    this.fleetProduct = env.fleetProduct;
  }

  changePassword(payload: any) {
    return this.http
      .post(`${this.host}/changePassword`, payload, {
        observe: 'response',
        headers: this.headers,
      })
      .pipe(catchError(handleApiError));
  }

  forgotPassword(payload: any) {
    return this.nonAuthorisedHttp
      .post(`${this.host}/forgotPassword`, payload, {
        headers: this.headers,
      })
      .pipe(catchError(handleApiError));
  }

  resetPassword(payload: any) {
    return this.nonAuthorisedHttp
      .post(`${this.host}/resetPassword`, payload, { observe: 'response' })
      .pipe(catchError(handleApiError));
  }

  changeUsername(payload: LoginModel) {
    return this.http
      .post(`${this.host}/changeUsername`, payload, {
        // headers: new HttpHeaders({
        //   Authorization: 'Bearer ' + jwt,
        //   'fleet-channel': 'WEB',
        //   'fleet-product': this.fleetProduct,
        // }),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  changePhoneNumber(payload: LoginModel) {
    return this.http
      .post(`${this.host}/changePhoneNumber`, payload)
      .pipe(catchError(handleApiError));
  }

  getPermissions() {
    return this.http
      .get(`${this.host}/permissions`)
      .pipe(catchError(handleApiError));
  }
}
