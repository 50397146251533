<!-- Navigation -->

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <fuse-loading-bar></fuse-loading-bar>
  <fleet-update-banner></fleet-update-banner>

  <!-- Header -->
  <div
    class="relative flex flex-0 items-center justify-between w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Logo -->
    <div class="flex items-center mx-2 lg:mr-8">
      <div class="hidden lg:flex">
        <img class="dark:hidden w-32" [src]="logoSrc" />
        <img class="hidden dark:flex w-32" [src]="logoSrc" />
      </div>
      <img class="flex lg:hidden w-32" [src]="logoSrc" />
    </div>

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="exitKioskMode()">Exit Kiosk Mode</button>
    </mat-menu>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
