export const successNotification = {
  icon: { name: 'check', color: 'success' },
  autoDismiss: 5000,
};

export const failureNotification = {
  icon: { name: 'warning', color: 'warn' },
  autoDismiss: 5000,
};

export const deleteNotification = {
  icon: { name: 'delete_sweep', color: 'red' },
  autoDismiss: 5000,
};

export const savedNotification = {
  icon: { name: 'bookmark_added', color: 'green' },
  autoDismiss: 5000,
};
