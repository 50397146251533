import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeRangeControlComponent } from './date-time-range-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { DateWithTimeControlModule } from '../date-with-time-control';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CustomCalendarHeaderComponent } from './custom-calendar-header.component';

@NgModule({
  declarations: [DateTimeRangeControlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    CustomCalendarHeaderComponent,
    DateWithTimeControlModule,
    MatDatepickerModule,
    MatFormFieldModule,
  ],
  exports: [DateTimeRangeControlComponent],
})
export class DateTimeRangeControlModule {}
