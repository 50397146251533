import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertModule } from '@fleet/fuse';
import { ContractSelectorComponent } from './contract-selector.component';

@NgModule({
  declarations: [ContractSelectorComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FuseAlertModule,
  ],

  exports: [ContractSelectorComponent],
})
export class ContractSelectorModule {}
