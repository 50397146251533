<ng-container *ngIf="search">
  <ng-container *ngIf="!search.searching; else isLoading">
    <div
      *ngIf="
        search.pageData?.totalCount !== 0 && search.data.length !== 0;
        else noPageData
      "
    >
      <div *ngIf="search.pageData?.totalCount; else noResults">
        <ng-container
          *ngIf="
            search.pageData?.totalCount === search.data?.length;
            else summary
          "
        >
          {{ search.data?.length > 0 ? search.data.length : '' }}
          {{
            search.data?.length
              | i18nPlural
                : {
                    '=0': plural ? plural : entity + 's',
                    '=1': entity,
                    other: plural ? plural : entity + 's'
                  }
          }}
        </ng-container>
        <ng-template #summary>
          {{ search.data?.length > 0 ? search.data.length : '' }}

          of

          {{
            search.pageData?.totalCount > 0 ? search.pageData?.totalCount : ''
          }}

          {{
            search.pageData?.totalCount
              | i18nPlural
                : {
                    '=0': plural ? plural : entity + 's',
                    '=1': entity,
                    other: plural ? plural : entity + 's'
                  }
          }}
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noResults> No {{ plural ? plural : entity + 's' }} </ng-template>

<ng-template #noPageData>
  <div *ngIf="search.data?.length > 0; else noResults">
    {{ search.data?.length > 0 ? search.data.length : '' }}
    {{
      search.data?.length
        | i18nPlural
          : {
              '=0': plural ? plural : entity + 's',
              '=1': entity,
              other: plural ? plural : entity + 's'
            }
    }}
  </div>
</ng-template>

<ng-template #isLoading class="flex justify-center items-center">
  <span class="animate-pulse">...Searching...</span>
</ng-template>
