import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { StatusChipComponent } from './status-chip.component';
import { StatusChipPipe } from './status-chip.pipe';

@NgModule({
  declarations: [StatusChipComponent, StatusChipPipe],
  imports: [CommonModule, TitleCaseAndCleanPipeModule],
  exports: [StatusChipComponent, StatusChipPipe],
})
export class StatusChipModule {}
