import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardDescriptionListComponent } from './card-description-list.component';

@NgModule({
  declarations: [CardDescriptionListComponent],
  imports: [CommonModule],
  exports: [CardDescriptionListComponent],
})
export class CardDescriptionListModule {}
