import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { FuseConfirmationService } from '@fleet/fuse';

import { BehaviorSubject, concat, interval } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class VersionUpdateService {
  isProduction: boolean;
  fleetProduct: string;
  showUpdateAvailable: BehaviorSubject<any> = new BehaviorSubject(true);
  updateAvailable: BehaviorSubject<any> = new BehaviorSubject(false);
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private fuseConfirmationService: FuseConfirmationService,
    private router: Router,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
    this.isProduction = env.production;
  }

  get showUpdateAvailable$() {
    return this.showUpdateAvailable.asObservable();
  }

  get updateAvailable$() {
    return this.updateAvailable.asObservable();
  }

  initialise() {
    const appIsStable$ = this.appRef.isStable; //.pipe(first((isStable) => isStable === true));
    const everyHour$ = interval(1 * 60 * 60 * 1000);
    const every5mins$ = interval(300000);

    const every5minsOnceAppIsStable$ = concat(appIsStable$, every5mins$);
    //   const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    every5mins$.subscribe(() => {
      if (this.isProduction) {
        this.updates.checkForUpdate();
      }
    });

    //if new update prompt user to refresh
    this.updates.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type === 'VERSION_READY') {
        if (this.fleetProduct === 'HUB') {
          this.updateAvailable.next(true);
          this.showUpdateAvailable.next(true);
        } else {
          const dialog = this.fuseConfirmationService.open({
            title: 'Important Update',
            message: `There is a new version of this app`,
            icon: {
              show: true,
              name: 'heroicons_outline:badge-check',
              color: 'success',
            },
            dismissible: true,
            actions: {
              confirm: { show: true, label: 'UPDATE', color: 'primary' },
              cancel: { show: false },
            },
          });

          dialog.afterClosed().subscribe({
            next: () =>
              this.updates
                .activateUpdate()
                .then(() => document.location.reload()),
          });
        }
      }
    });

    this.updates.unrecoverable.subscribe((event) => {
      console.log('Unrecoverable error reloading app');
      // Sentry.captureException(
      //   'Unrecoverable app version issue: \n' + JSON.stringify(event)
      // );
      document.location.reload();
      this.router.navigate(['/reload']);
    });
  }

  dismissUpdate() {
    this.showUpdateAvailable.next(false);
  }

  activateUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
