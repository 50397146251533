import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatIntlTelInputModule } from '@fleet/custom-controls';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { SidebarFormLayoutModule } from '@fleet/layout';
import { PlacesAutocompleteModule } from '@fleet/location';
import { NetworkServiceConfigurationSelectorModule } from '@fleet/network-shared';
import { OrganisationGroupAutocompleteModule } from '@fleet/organisation-shared';
import {
  ServiceClassAndLineSelectorModule,
  ServicePricingSelectModule,
  ServiceSearchListModule,
  ServiceSelectModule,
} from '@fleet/service';
import { ProgressButtonModule } from '@fleet/shared';
import { ActionButtonsModule, AlertModule } from '@fleet/ui';
import { JobPaymentMethodSelectorModule } from '../../components/job-payment-method-selector/job-payment-method-selector.module';
import { JobConditionSelectorModule } from '../../job-condition-selector/job-condition-selector.module';
import { JobTravellerProfileSelectorModule } from '../../job-traveller-profile-selector/job-traveller-profile-selector.module';
import { JobTemplateCreateEditComponent } from './job-template-create-edit.component';

@NgModule({
  declarations: [JobTemplateCreateEditComponent],
  imports: [
    CommonModule,
    MatInputModule,
    ProgressButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    ActionButtonsModule,
    SidebarFormLayoutModule,
    PlacesAutocompleteModule,
    ServiceSelectModule,
    ServicePricingSelectModule,
    ServiceSearchListModule,
    JobTravellerProfileSelectorModule,
    JobConditionSelectorModule,
    JobPaymentMethodSelectorModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    OrganisationGroupAutocompleteModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ServiceClassAndLineSelectorModule,
    MatRadioModule,
    NetworkServiceConfigurationSelectorModule,
    NgxMatIntlTelInputModule,
    AlertModule,
  ],
  exports: [JobTemplateCreateEditComponent],
})
export class JobTemplateCreateEditModule {}
