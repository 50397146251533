import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { JobApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  IssueModel,
  JobModel,
  PaymentTransactionModel,
  TravellerModel,
} from '@fleet/model';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { failureNotification, successNotification } from '@fleet/utilities';

@Component({
  selector: 'fleet-job-payment-transaction-receipt',
  templateUrl: './job-payment-transaction-receipt.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class JobPaymentTransactionReceiptComponent implements OnInit {
  @Input() jobId: string;
  @Input() saleId: string;

  form: UntypedFormGroup;
  @Output() successful = new EventEmitter();
  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      this._traveller = value;
      this.buildForm();
      if (!value?.receiptEmail && value?.status === 'UNREGISTERED') {
        this.form.get('receipt').patchValue('EMAIL');
      } else {
        this.form.get('receipt').patchValue('TRAVELLER');
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  get traveller() {
    return this._traveller;
  }
  issues: IssueModel[] = [];
  loading: boolean;

  constructor(
    private jobApiService: JobApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private onScreenNotificationService: OnscreenNotificationService
  ) {}

  ngOnInit(): void {
    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      receipt: ['EMAIL'],
      email: [null, [Validators.email, Validators.required]],
    });

    this.changeDetectorRef.markForCheck();
  }

  send() {
    this.loading = true;
    this.issues = [];
    this.changeDetectorRef.markForCheck();

    const payload = {
      saleId: this.saleId,
    } as any;
    const receiptType = this.form.get('receipt').value;
    if (receiptType === 'EMAIL') {
      payload['emailAddresses'] = [this.form.get('email').value];
    }
    this.jobApiService.receipt(this.jobId, payload).subscribe({
      next: () => {
        this.loading = false;
        this.changeDetectorRef.markForCheck();
        this.onScreenNotificationService.setNotification({
          ...successNotification,
          title: 'Receipt Emailed!',
          subTitle:
            'The receipt has been emailed to: ' +
            (receiptType === 'EMAIL'
              ? payload.emailAddresses[0]
              : this.traveller.receiptEmail),
        } as OnScreenNotification);
        this.successful.emit();
      },
      error: (issues: IssueModel[]) => {
        this.issues = issues;
        this.loading = false;
        this.onScreenNotificationService.setNotification({
          ...failureNotification,
          title: 'Receipt not emailed!',
          subTitle:
            'There was an issue sending the receipt via email. Please try again',
        } as OnScreenNotification);
        this.changeDetectorRef.markForCheck();
      },
    });
  }
}
