<mat-form-field class="w-full" *ngIf="bsbControl">
  <mat-label>BSB</mat-label>
  <input
    [required]="showRequired"
    matInput
    placeholder="BSB"
    [formControl]="bsbControl"
  />
  <mat-spinner matSuffix diameter="24" *ngIf="validating"></mat-spinner>
  <div
    class="text-sm"
    *ngIf="
      bsb && valid && bsbControl.dirty && !bsbControl.errors && !validating
    "
    matSuffix
    style="color: green"
  >
    {{ bsb }}
  </div>

  <mat-error
    *ngIf="bsbControl.hasError('restrictedWords') && !bsbControl.disabled"
    >BSB cannot be 000000</mat-error
  >
  <mat-error *ngIf="bsbControl.hasError('invalidBsb') && !bsbControl.disabled"
    >Please ensure you have provided a valid BSB</mat-error
  >
  <mat-error *ngIf="bsbControl.hasError('noResults') && !bsbControl.disabled"
    >The BSB provided has not returned any valid results</mat-error
  >
  <mat-error *ngIf="bsbControl.hasError('required') && !bsbControl.disabled"
    >A valid BSB is required</mat-error
  >
</mat-form-field>
