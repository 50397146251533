import { HttpHeaders } from '@angular/common/http';
import { PageData, SearchResult } from '@fleet/model';

export function getSearchResultFromHeaders(headers: HttpHeaders): SearchResult {
  let totalCount;
  let totalPages;
  let currentPage;
  try {
    totalCount = Number(headers.get('x-total-count'));
    totalPages = Number(headers.get('x-total-pages'));
    currentPage = Number(headers.get('x-current-page'));
  } catch {
    totalCount = 0;
    totalPages = 0;
    currentPage = 0;
  }
  const x = <SearchResult>{};
  x.currentPage = currentPage;
  x.totalCount = totalCount;
  x.totalPages = totalPages;
  return x;
}

export function clearNullandEmptySearchParams(searchParams: any) {
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key] == null || searchParams[key].toString() == '') {
      delete searchParams[key];
    }
  });
}

export function filterUniversallyOnFields(term: any, item: any) {
  for (const property in item) {
    //Dont bother if the value is null
    if (item[property] === null) {
      continue;
    }
    // Ignore everything but strings
    // (SEARCHABLE FIELDS NEED TO BE STRINGS OR OBJECTS)
    else if (item[property] !== null && typeof item[property] === 'string') {
      if (
        item[property].toString().toLowerCase().includes(term.toLowerCase())
      ) {
        return true;
      }
    }
    // Not a string, check if it is a searchable cheeky object
    else if (typeof item[property] === 'object') {
      // Does it have the search term?
      if (filterUniversallyOnFields(term, item[property])) {
        return true;
      } else {
        continue;
      }
    } else if (typeof item[property] === 'boolean') {
      //If we want to search on true/false
    } else {
      continue;
    }
  }
  return false;
}

export function shouldGetNextPage(e: any) {
  const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
  const tableScrollHeight = e.target.scrollHeight; // length of all table
  const scrollLocation = e.target.scrollTop; // how far user scrolled

  // If the user has scrolled within 200px of the bottom, add more data
  const scrollThreshold = 200;

  const scrollDownLimit = tableScrollHeight - tableViewHeight - scrollThreshold;
  if (scrollLocation > scrollDownLimit) {
    return tableScrollHeight / 2 + tableViewHeight;
  }
  return null;
}

export function nextPageAvailable(pageData: PageData) {
  return pageData && pageData.currentPage < pageData.totalPages;
}

export function getNextPageParams(pageData: PageData) {
  if (pageData.currentPage != pageData.totalPages) {
    const newParams = {
      ...pageData.params,
      offset: String(pageData.currentPage * Number(pageData.params.limit)),
    };
    return newParams;
  }
  return null;
}

export function getNextPageParamsOnMorePages(pageData: PageData) {
  if (pageData?.morePages) {
    const newParams = {
      ...pageData.params,
      offset: Number(
        Number(pageData.params.offset) + Number(pageData.params.limit)
      ),
    };
    return newParams;
  }
  return null;
}

export function hasScrolledToBottomforVirtualPaging(e: any) {
  let isBottom = false;
  const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
  const tableScrollHeight = e.target.scrollHeight; // length of all table
  const scrollLocation = e.target.scrollTop; // how far user scrolled
  // If the user has scrolled within 200px of the bottom, add more data
  const buffer = 200;
  const limit = tableScrollHeight - tableViewHeight - buffer;
  if (scrollLocation > limit) {
    isBottom = true;
  }
  return isBottom;
}
