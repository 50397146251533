import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapService } from './map.service';
import { JobModel } from '@fleet/model';
import { decode } from './heremaps-flexible-polyline';
import { mainRouteColor, startedRouteColor } from './map-utilities';
import { GoogleMap } from '@angular/google-maps';
// import * as THREE from 'three';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
@Injectable({
  providedIn: 'root',
})
export class MapAnimationService {
  animatedPolylines: BehaviorSubject<google.maps.PolygonOptions[]> =
    new BehaviorSubject([]);
  currentAnimationStep: BehaviorSubject<any> = new BehaviorSubject(null);
  isAnimationPaused = false;
  private animationStep = 0;
  private decodedData: any[]; // Assuming this is the structure of your data
  private pathCoordinates: any[];
  private timestamps: number[];
  animationSpeed = 50;
  private totalSteps: number;
  private numIntermediateSteps = 10;
  private currentPolylineIndex = 0;
  routesToAnimate: any[] = [];

  googleMap: GoogleMap;

  // private scene: THREE.Scene;
  // private camera: THREE.PerspectiveCamera;
  // private renderer: THREE.WebGLRenderer;
  // private loader: GLTFLoader;
  // private routeMarker: THREE.Mesh;
  get animatedPolylines$() {
    return this.animatedPolylines.asObservable();
  }
  get currentAnimatedStep$() {
    return this.currentAnimationStep.asObservable();
  }
  constructor(private mapService: MapService) {}

  animateJob(job: JobModel) {
    // this.mapService.resetMap();
    this.animatedPolylines.next([]);
    this.currentAnimationStep.next(null);
    this.currentPolylineIndex = 0;
    this.isAnimationPaused = false;
    const decodedData = [];
    setTimeout(() => {
      this.mapService.mapState.next({
        ...this.mapService.mapState.value,
        zoom: 13,
      });
    }, 100);

    if (job.jobDetail.startedRoute) {
      const startRouteDecodedData = decode(job.jobDetail.startedRoute).polyline
        ? decode(job.jobDetail.startedRoute).polyline.map((data: any) => ({
            lat: data[0],
            lng: data[1],
            timestamp: data[2],
            color: startedRouteColor,
          }))
        : [];
      this.routesToAnimate.push({
        routeDescription: 'Starting Route',
        decodedData: startRouteDecodedData,
      });
    }

    if (job.jobDetail.inProgressRoute) {
      const inProgressRouteDecodedData = decode(job.jobDetail.inProgressRoute)
        .polyline
        ? decode(job.jobDetail.inProgressRoute).polyline.map((data: any) => ({
            lat: data[0],
            lng: data[1],
            timestamp: data[2],
            color: mainRouteColor,
          }))
        : [];
      this.routesToAnimate.push({
        routeDescription: 'In-progress Route',
        decodedData: inProgressRouteDecodedData,
      });
    }

    if (this.routesToAnimate.length > 0) {
      this.animateRoutes();
    }
  }

  animateRoutes() {
    if (this.currentPolylineIndex >= this.routesToAnimate.length) {
      this.routesToAnimate = [];

      return; // All routes have been animated
    }

    this.decodedData =
      this.routesToAnimate[this.currentPolylineIndex].decodedData;
    this.pathCoordinates = this.decodedData.map((data: any) => ({
      lat: data.lat,
      lng: data.lng,
    }));
    this.timestamps = this.decodedData.map((data) => data.timestamp * 1000);
    this.totalSteps = this.timestamps.length - 1;
    this.animationStep = 0; // Reset for each new route

    this.animateStep();
  }

  private animateStep = () => {
    if (this.isAnimationPaused) {
      return; // Exit the function if the animation is paused
    }

    const step = this.animationStep;
    const currentTime = this.timestamps[step];
    const nextTime = this.timestamps[step + 1];
    const timeDifference = nextTime - currentTime;

    const currentPath = this.pathCoordinates.slice(0, step + 1);
    const polyline = {
      path: currentPath,
      strokeColor: this.decodedData[step].color,
    } as google.maps.PolygonOptions;

    this.currentAnimationStep.next(this.decodedData[step]);
    this.mapService.googleMap.panTo(currentPath[step]);

    const polylines = this.animatedPolylines.value;
    polylines[this.currentPolylineIndex] = polyline;
    this.animatedPolylines.next(polylines);

    if (step < this.totalSteps && this.pathCoordinates[step + 1]) {
      for (let i = 1; i <= this.numIntermediateSteps; i++) {
        setTimeout(() => {
          if (this.isAnimationPaused) return;
          const fraction = i / this.numIntermediateSteps;
          const nextStepLat =
            this.pathCoordinates[step].lat +
            fraction *
              (this.pathCoordinates[step + 1].lat -
                this.pathCoordinates[step].lat);
          const nextStepLng =
            this.pathCoordinates[step].lng +
            fraction *
              (this.pathCoordinates[step + 1].lng -
                this.pathCoordinates[step].lng);
          this.mapService.googleMap.panTo({
            lat: nextStepLat,
            lng: nextStepLng,
          });
        }, (timeDifference / this.animationSpeed) * (i / this.numIntermediateSteps));
      }
      setTimeout(() => {
        if (!this.isAnimationPaused) {
          this.animationStep++;
          this.animateStep();
        }
      }, timeDifference / this.animationSpeed);
    } else if (step >= this.totalSteps) {
      this.currentPolylineIndex++;
      this.animateRoutes();
    }
  };

  togglePauseAnimation() {
    this.isAnimationPaused = !this.isAnimationPaused;
    if (!this.isAnimationPaused) {
      // Directly call animateStep to continue from the current step
      this.animateStep();
    }
  }

  reset() {
    this.isAnimationPaused = false;
    this.animationStep = 0;
    this.decodedData = []; // Assuming this is the structure of your data
    this.pathCoordinates = [];
    this.timestamps = [];
    this.animationSpeed = 50;
    this.totalSteps = 0; // Assuming initialization is required
    this.numIntermediateSteps = 10;
    this.currentPolylineIndex = 0;
    this.routesToAnimate = [];
    this.animatedPolylines.next([]);
    this.currentAnimationStep.next(null);
  }

  // initWebglOverlayView(job: JobModel): void {
  //   const webglOverlayView = new google.maps.WebGLOverlayView();

  //   webglOverlayView.onAdd = () => {
  //     this.scene = new THREE.Scene();
  //     this.camera = new THREE.PerspectiveCamera();

  //     // Lighting
  //     const ambientLight = new THREE.AmbientLight(0xffffff, 0.75);
  //     this.scene.add(ambientLight);
  //     const directionalLight = new THREE.DirectionalLight(0xffffff, 0.25);
  //     directionalLight.position.set(0.5, -1, 0.5);
  //     this.scene.add(directionalLight);

  //     // Route marker

  //     this.scene.add(this.routeMarker);

  //     // Model loading can also be done here if specific to the route
  //   };

  //   webglOverlayView.onContextRestored = ({ gl }) => {
  //     this.renderer = new THREE.WebGLRenderer({
  //       canvas: gl.canvas,
  //       context: gl,
  //       antialias: true,
  //     });
  //     this.renderer.autoClear = false;
  //   };

  //   webglOverlayView.onDraw = ({ transformer }) => {
  //     // Update camera matrix to ensure the model is georeferenced correctly on the map.
  //     const matrix = transformer.fromLatLngAltitude({
  //       lat: 35.6594945,
  //       lng: 139.6999859,
  //       altitude: 100,
  //     });
  //     this.camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix);

  //     this.renderer.render(this.scene, this.camera);
  //     this.renderer.resetState();
  //   };

  //   setTimeout(() => {
  //     webglOverlayView.setMap(this.mapService.googleMap.googleMap);
  //     setTimeout(() => {
  //       this.animateJob(job);
  //     }, 1000);
  //   }, 1000);
  // }

  // animateRouteWebGL(decodedData: any[]): void {
  //   const pathCoordinates = decodedData.map((data: any) => ({
  //     lat: data.lat,
  //     lng: data.lng,
  //   }));

  //   let step = 0;
  //   const totalSteps = pathCoordinates.length - 1;
  //   const animateStep = () => {
  //     if (step > totalSteps) {
  //       return; // Stop the animation when all steps are completed.
  //     }

  //     const { lat, lng } = pathCoordinates[step];
  //     const latLngAltitude = { lat, lng, altitude: 100 }; // Set altitude as needed
  //     const matrix = this.mapService.googleMap
  //       .getProjection()
  //       .fromLatLngToPoint(new google.maps.LatLng(lat, lng));

  //     this.routeMarker.position.set(matrix.x, matrix.y, 0); // Adjust positioning accordingly

  //     this.renderer.render(this.scene, this.camera);

  //     step++;
  //     requestAnimationFrame(animateStep); // Use requestAnimationFrame for smoother animation
  //   };

  //   animateStep();
  // }
}
// animateRoute(encodedRoute: string) {
//   this.mapState.next({ ...this.mapState.value, zoom: 13 });
//   const decodedPath = decode(encodedRoute).polyline;
//   const pathCoordinates = decodedPath.map((coord) => ({
//     lat: coord[0],
//     lng: coord[1],
//   }));
//   const timestamps = decodedPath.map((coord) => coord[2] * 1000);
//   const animationSpeed = 30; // Speed up the animation by a factor of 10

//   let step = 0; // Represents the current step of the animation
//   const totalSteps = timestamps.length - 1; // Total number of steps in the animation

//   const animateStep = () => {
//     const currentTime = timestamps[step];
//     const nextTime = timestamps[step + 1];
//     const timeDifference = nextTime - currentTime;

//     // Calculate the next step's timestamp based on the animation speed
//     const nextStepTime = currentTime + timeDifference / animationSpeed;

//     // Update the polyline to only include coordinates up to the current step
//     const currentPath = pathCoordinates.slice(0, step + 1);
//     const polyline = {
//       path: currentPath,
//       // Other polyline options like stroke color, opacity, etc. can be set here
//     } as google.maps.PolygonOptions;
//     this.googleMap.panTo(currentPath[step]);

//     // Set the polyline on the map
//     this.animatedPolyline.next(polyline);

//     // If we haven't reached the last step, set a timeout to animate the next step
//     if (step < totalSteps) {
//       setTimeout(animateStep, timeDifference / animationSpeed);
//       step++; // Increment the step
//     }
//   };

//   // Start the animation
//   animateStep();
// }
