import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteDetailComponent } from './note-detail.component';
import {
  ActionButtonsModule,
  BadgeModule,
  CardDescriptionListModule,
  SidebarHeaderModule,
  StatusChipModule,
} from '@fleet/ui';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { LineBreakPipeModule, TitleCaseAndCleanPipeModule } from '@fleet/pipes';

@NgModule({
  declarations: [NoteDetailComponent],
  imports: [
    CommonModule,
    CardDescriptionListModule,
    SidebarHeaderModule,
    ActionButtonsModule,
    TitleCaseAndCleanPipeModule,
    InternationalizationDatePipeModule,
    LineBreakPipeModule,
    StatusChipModule,
    BadgeModule,
  ],
  exports: [NoteDetailComponent],
})
export class NoteDetailModule {}
