import { DateTime, Interval } from 'luxon';

export function titleCaseClean(value: any): any {
  let formattedString = '';
  if (value) {
    formattedString = titleCase(value);
    formattedString = formattedString.split('_').join(' ');
    return formattedString;
  } else {
    return formattedString;
  }
}

export function camelCaseClean(value: any): any {
  if (!value) {
    return '';
  }

  const result = value.replace(/([a-z])([A-Z])/g, '$1 $2');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function titleCase(str: any) {
  str = str.toLowerCase().split(' ');
  if (str) {
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }
  return str;
}

export function internationalizedDate(
  value: DateTime | string,
  format: string,
  relative?: boolean,
  type?: string,
  locale?: string
) {
  if (!value) {
    return '';
  }
  if (!format) {
    format = 'D';
  }

  switch (format) {
    case 'CONDENSED_DATE':
      format = 'D'; // 2/01/2024
      break;
    case 'DATETIME_SHORT':
      format = 'f'; //8/6/2014, 1:07 PM
      break;
    case 'DATETIME_SHORT_WITH_SECONDS':
      format = 'F'; //DISPATCH LOGS - ABOVE WITH SECONDS
      break;
    case 'LOCALIZED_TIME':
      format = 't'; //01:30 pm
      break;
    case 'SHORT_DATE':
      format = 'ccc d LLL yyyy'; //Fri 26 Nov 2021
      break;
    case 'TIME_OFFSET':
      format = 'hh:mm a ZZZ'; //time offset - 12:17 pm +1100
      break;
    case 'CONDENSED_DATE_TIME_OFFSET':
      format = 'D hh:mm a ZZZ'; //2/01/2024 12:17 pm +1100
      break;
    case 'LOCALISED_DATE_FULL':
      format = 'DDDD';
      break;

    default:
  }

  // Ensure value is a DateTime object
  if (typeof value === 'string') {
    value = DateTime.fromISO(value);
  }

  if (type === 'millis') {
    const date = DateTime.fromMillis(+value * 1000);
    return date.toFormat(format);
  }

  if (type === 'durationSince') {
    const now = DateTime.local();

    const interval = Interval.fromDateTimes(value, now);
    const duration = interval.toDuration(['hours', 'minutes', 'days']);

    if (duration.days > 1) {
      return `${duration.days.toFixed(0)} days ago`;
    } else if (duration.days === 1) {
      return `${duration.days.toFixed(0)} day ago`;
    } else if (duration.hours > 1) {
      return `${duration.hours.toFixed(0)} hrs ago`;
    } else if (duration.hours === 1) {
      return `${duration.hours.toFixed(0)} hr ago`;
    } else if (duration.minutes > 1) {
      return `${duration.minutes.toFixed(0)} mins ago`;
    } else {
      return `1 min ago`;
    }
  }

  if (relative) {
    const relativeDate = value.toRelativeCalendar({
      locale: locale,
    });

    // If there's a relative date (yesterday, today, tomorrow), return it
    if (relativeDate) {
      return relativeDate.charAt(0).toUpperCase() + relativeDate.slice(1); // Capitalize the first letter
    }

    // If relativeDate is undefined, fall through to normal formatting
  }

  return value.setLocale(locale).toFormat(format);
}

export function getReadableFromCamelCase(fieldName: string): string {
  return fieldName
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
