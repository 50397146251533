null; /* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-progress-button',
  templateUrl: './progress-button.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressButtonComponent implements OnInit {
  @Input() color = 'primary';
  @Input() type = 'button';
  @Input() label: string;
  @Input() loading: boolean;

  @Input() buttonClass: string;
  //accent for dialog

  @Input() state: any;

  @Input() disabled: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
