import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IvrApiService } from '@fleet/api';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fleet/fuse';
import { ApiResponse, CallModel } from '@fleet/model';

@Injectable({
  providedIn: 'root',
})
export class IvrTransferNoteService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private ivrApiService: IvrApiService,
    private confirmationService: FuseConfirmationService
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['callId']) {
        this.ivrApiService.getCall(params['callId']).subscribe({
          next: (resp: ApiResponse<CallModel>) => {
            if (resp.data.transferRedirectUrl && resp.data.note?.content) {
              this.confirmationService.open({
                title: 'Call Transfer Note',
                message: resp.data.note.content,
                icon: { show: true, color: 'primary', name: 'info' },
                actions: {
                  confirm: { show: true, color: 'primary', label: 'OK' },
                  cancel: { show: false },
                },
              } as FuseConfirmationConfig);
            }
          },
        });
      }
    });
  }
}
