import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { FleetReponsiveElementBaseComponent } from '@fleet/layout';
import { BaseSearchState, CallModel } from '@fleet/model';
import { DateTime } from 'luxon';
import {
  NgResizeObserver,
  ngResizeObserverProviders,
} from 'ng-resize-observer';

@Component({
  selector: 'fleet-entity-call-search-results',
  templateUrl: './entity-call-search-results.component.html',
  styleUrls: ['./entity-call-search-results.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...ngResizeObserverProviders],
})
export class EntityCallSearchResultsComponent
  extends FleetReponsiveElementBaseComponent
  implements OnInit
{
  get dateTime() {
    return DateTime;
  }

  @Input() disablePreview = false;
  @Input() disableRecordings = false;

  fleetProduct: string;
  @Output() playRecording = new EventEmitter();
  @Output() previewCall = new EventEmitter();
  displayColumns: string[] = [];

  _search: BaseSearchState<CallModel>;
  @Input() set search(value: BaseSearchState<CallModel>) {
    this._search = value;
    if (value) {
      if (value.pageData?.params?.type === 'INBOUND,OUTBOUND') {
        this.displayColumns = [
          'callTime',
          'incomingOutgoing',
          'moli',
          'agentHandover',
          'note',
          'recording',
          'initiatedBy',
          'links',
        ];
      } else if (value.pageData?.params?.type === 'PROXY') {
        {
          this.displayColumns = [
            'callTime',
            'incomingOutgoing',
            'content',
            'initiatedBy',
            'links',
          ];
        }
      }
    }
  }
  get search() {
    return this._search;
  }

  constructor(
    resize$: NgResizeObserver,
    private router: Router,
    @Inject('env') env: any
  ) {
    super(resize$);
    this.fleetProduct = env.fleetProduct;
  }
  ngOnInit(): void {}

  routeToTraveller(travellerId: string) {
    this.router.navigate([`traveller/${travellerId}/`]);
  }
  routeToDriver(driverId: string) {
    this.router.navigate([`driver/${driverId}/`]);
  }

  routeToJob(jobId: string) {
    this.router.navigate([`job/${jobId}/`]);
  }

  routeToOrganisation(organisationId: string) {
    this.router.navigate([`organisation/${organisationId}/`]);
  }
}
