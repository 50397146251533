import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { SidebarFormLayoutModule } from '@fleet/layout';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { NgxCurrencyModule, ProgressButtonModule } from '@fleet/shared';
import { ActionButtonsModule } from '@fleet/ui';
import { EntityPtdRefundComponent } from './entity-ptd-refund.component';

@NgModule({
  declarations: [EntityPtdRefundComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    TitleCaseAndCleanPipeModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ProgressButtonModule,
    SidebarFormLayoutModule,
    FuseAlertModule,
    ActionButtonsModule,
    AlertsFromIssuesModule,

    NgxCurrencyModule,
  ],
  exports: [EntityPtdRefundComponent],
})
export class EntityPtdModule {}
