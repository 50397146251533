import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DriverApiService, VehicleApiService } from '@fleet/api';
import {
  DriverModel,
  IssueModel,
  PositionDetailModel,
  VehicleModel,
} from '@fleet/model';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'fleet-position-detail-card',
  templateUrl: './position-detail-card.component.html',
  styleUrls: ['./position-detail-card.component.scss'],
})
export class PositionDetailCardComponent implements OnInit {
  _positionDetail: PositionDetailModel;
  @Input() set positionDetail(value: PositionDetailModel) {
    this._positionDetail = value;
    if (value) {
      this.fetchDriverAndVehicle(value.driverId, value.vehicleId);
    }
  }

  get positionDetail() {
    return this._positionDetail;
  }

  @Input() driver: DriverModel;
  @Input() vehicle: VehicleModel;
  @Input() isCard = true;
  issues: IssueModel[] = [];
  loading = false;
  constructor(
    private driverApiService: DriverApiService,
    private vehicleApiService: VehicleApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {}

  fetchDriverAndVehicle(driverId: string, vehicleId: string) {
    this.loading = true;
    forkJoin([
      this.driverApiService.getDriver(driverId),
      this.vehicleApiService.getVehicle(vehicleId),
    ]).subscribe({
      next: ([driverResp, vehicleResp]) => {
        this.driver = driverResp.data;
        this.vehicle = vehicleResp.data;
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      },
      error: (issues) => {
        this.issues = issues;
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  navigateToDriver() {
    this.router.navigate(['driver/' + this.positionDetail.driverId]);
  }

  navigateToVehicle() {
    this.router.navigate(['vehicle/' + this.positionDetail.vehicleId]);
  }
}
