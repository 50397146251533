<fleet-entity-detail-layout *ngIf="job" [navigation]="jobDetailNavigation">
  <fleet-job-extend-expiry
    *ngIf="fleetProduct !== 'DRIVER' && job.jobDetail.extendConfirmationTime"
    slot="banner"
    [job]="job"
    (jobExtended)="jobExtended($event)"
  ></fleet-job-extend-expiry>
  <ng-container *ngIf="showHeader === true" slot="entity-header">
    <div class="flex flex-row items-center justify-between pt-9 px-12 mb-8">
      <fleet-entity-header-with-breadcrumbs></fleet-entity-header-with-breadcrumbs>
      <div
        class="flex flex-row gap-2 items-center"
        *ngIf="fleetProduct === 'HUB'"
      >
        <!-- <mat-slide-toggle
          class="ml-2"
          [color]="'primary'"
          [checked]="isSocketOpen"
          (change)="socketToggleChange($event)"
        >
        </mat-slide-toggle> -->
        <mat-icon
          *ngIf="shouldListen"
          [ngClass]="{
            'text-emerald-500 animate-pulse': isSocketOpen,
            'text-red-500 ': !isSocketOpen
          }"
          [matTooltip]="
            isSocketOpen
              ? 'Listening for job updates'
              : 'Not listening for changes: Reload page for updates'
          "
          >sensors</mat-icon
        >

        <fleet-job-action
          [job]="job"
          [hideActions]="hiddenActions"
        ></fleet-job-action>
        <fleet-ticket-action-button
          *ngIf="'JOB_TICKETS:create' | hasFunction"
          class="ml-3"
          [job]="job"
        >
        </fleet-ticket-action-button>
      </div>

      <div
        class="flex flex-row gap-2 items-center"
        *ngIf="fleetProduct === 'BUSINESS' || fleetProduct === 'TRAVELLER'"
      >
        <fleet-job-action
          [job]="job"
          [hideActions]="hiddenActions"
        ></fleet-job-action>
        <!-- <fleet-job-call [jobId]="job.jobId" *ngIf="job?.driver">
        </fleet-job-call> -->
      </div>
    </div>
  </ng-container>

  <ng-container slot="main">
    <router-outlet></router-outlet>
  </ng-container>
</fleet-entity-detail-layout>
