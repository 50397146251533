import { CurrencyMaskConfig } from '@fleet/model';

// Add more config as needed

const defaultConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  thousands: ',',
  suffix: '',
  nullable: false,
  allowZero: false,
  label: 'Default',
  currencyCode: 'AUD',
};

const usdConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: '$ ',
  label: 'United States Dollar (USD)',
  currencyCode: 'USD',
};

const eurConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  decimal: ',',
  prefix: '€ ',
  thousands: '.',
  label: 'Euro (EUR)',
  currencyCode: 'EUR',
};

const gbpConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: '£ ',
  label: 'British Pound Sterling (GBP)',
  currencyCode: 'GBP',
};

const audConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: '$ ',
  label: 'Australian Dollar (AUD)',
  currencyCode: 'AUD',
};

const sgdConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: 'S$ ',
  label: 'Singapore Dollar (SGD)',
  currencyCode: 'SGD',
};

const jpyConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  precision: 0, // No decimal places for JPY in most cases
  prefix: '¥ ',
  label: 'Japanese Yen (JPY)',
  currencyCode: 'JPY',
};

const cnyConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: '¥ ',
  label: 'Chinese Yuan (CNY)',
  currencyCode: 'CNY',
};

const penConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: 'S/ ',
  label: 'Peruvian Sol (PEN)',
  currencyCode: 'PEN',
};

const frConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  decimal: ',',
  prefix: '€ ',
  thousands: '.',
  label: 'Euro (EUR)',
  currencyCode: 'EUR',
};

const cadConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: 'C$ ',
  label: 'Canadian Dollar (CAD)',
  currencyCode: 'CAD',
};

const chfConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: 'CHF ',
  label: 'Swiss Franc (CHF)',
  currencyCode: 'CHF',
};

const inrConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: '₹ ',
  label: 'Indian Rupee (INR)',
  currencyCode: 'INR',
};

const brlConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  decimal: ',',
  prefix: 'R$ ',
  thousands: '.',
  label: 'Brazilian Real (BRL)',
  currencyCode: 'BRL',
};

const rubConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  decimal: ',',
  prefix: '₽ ',
  thousands: '.',
  label: 'Russian Ruble (RUB)',
  currencyCode: 'RUB',
};

const zarConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: 'R ',
  label: 'South African Rand (ZAR)',
  currencyCode: 'ZAR',
};

const nzdConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: 'NZ$ ',
  label: 'New Zealand Dollar (NZD)',
  currencyCode: 'NZD',
};

const mxnConfig: CurrencyMaskConfig = {
  ...defaultConfig,
  prefix: 'Mex$ ',
  label: 'Mexican Peso (MXN)',
  currencyCode: 'MXN',
};

export const currencyConfigs = [
  usdConfig,
  eurConfig,
  gbpConfig,
  audConfig,
  sgdConfig,
  jpyConfig,
  cnyConfig,
  penConfig,
  cadConfig,
  chfConfig,
  inrConfig,
  brlConfig,
  rubConfig,
  zarConfig,
  nzdConfig,
  mxnConfig,
];

export const localeData = [
  { code: 'en-US', label: 'United States' },
  { code: 'fr-FR', label: 'France' },
  { code: 'de-DE', label: 'Germany' },
  { code: 'es-ES', label: 'Spain' },
  { code: 'en-GB', label: 'United Kingdom' },
  { code: 'en-CA', label: 'Canadxwa (English)' },
  { code: 'fr-CA', label: 'Canada (French)' },
  { code: 'en-AU', label: 'Australia' },
  { code: 'ja-JP', label: 'Japan' },
  { code: 'zh-CN', label: 'China' },
  { code: 'de-CH', label: 'Switzerland (German)' },
  { code: 'fr-CH', label: 'Switzerland (French)' },
  { code: 'it-CH', label: 'Switzerland (Italian)' },
  { code: 'hi-IN', label: 'India' },
  { code: 'pt-BR', label: 'Brazil' },
  { code: 'ru-RU', label: 'Russia' },
  { code: 'en-ZA', label: 'South Africa' },
  { code: 'en-NZ', label: 'New Zealand' },
  { code: 'es-MX', label: 'Mexico' },
  { code: 'en-SG', label: 'Singapore' },
  // Add more mappings as needed
];
