import { FuseNavigationItem } from '@fleet/model';

export const organisationMenu: FuseNavigationItem[] = [
  {
    id: 'overview',
    title: 'Overview',
    icon: 'widgets',
    link: '/detail',
    type: 'basic',
    exactMatch: false,
    securityFunctions: ['ORGANISATION:read'],
  },
  {
    id: 'organisation-jobs',
    title: 'Jobs',
    type: 'collapsable',
    link: '/job',
    icon: 'place',
    securityFunctions: [
      'JOB_DETAIL:read',
      'ORGANISATION_JOB_SEARCH:read',
      'ORGANISATION_JOB_IMPORT:read',
      'JOB_ACTIVITY:read',
      'JOB_DETAIL:create',
    ],

    children: [
      {
        id: 'organisation-job-search',
        type: 'basic',
        link: '/job/search',
        icon: 'search',
        title: 'Search',
        securityFunctions: ['ORGANISATION_JOB_SEARCH:read'],
      },
      {
        id: 'organisation-job-import',
        type: 'basic',
        link: '/job/import',
        icon: 'cloud_upload',
        title: 'Import Jobs',
        securityFunctions: ['ORGANISATION_JOB_IMPORT:read'],
      },
      {
        id: 'active-jobs',
        type: 'basic',
        link: '/job/active',
        icon: 'bolt',
        title: 'Active Jobs',
        securityFunctions: ['JOB_ACTIVITY:read'],
      },
      {
        id: 'organisation-job-new',
        type: 'basic',
        link: '/job/new',
        icon: 'add',
        title: 'New Job',
        securityFunctions: ['JOB_DETAIL:create'],
      },
      {
        id: 'organisation-kiosk',
        type: 'basic',
        link: '/kiosk',
        icon: 'web_asset',
        title: 'Booking Kiosk',

        securityFunctions: ['JOB_DETAIL:create'],
      },
    ],
  },
  {
    id: 'staff',
    title: 'Staff',
    type: 'basic',
    icon: 'person',
    link: '/staff',
    securityFunctions: ['ORGANISATION_STAFF_SEARCH:read'],
  },

  {
    id: 'group',
    title: 'Groups',
    type: 'basic',
    icon: 'group',
    link: '/group',
    securityFunctions: ['ORGANISATION_GROUP_SEARCH:read'],
  },

  {
    id: 'policy',
    title: 'Policies',
    type: 'collapsable',
    icon: 'policy',
    link: '/policy/search',
    securityFunctions: ['ORGANISATION_TRAVEL_POLICY_SEARCH:read'],
    children: [
      {
        id: 'policy.search',
        title: 'Search',
        type: 'basic',
        icon: 'search',
        link: '/policy/search',
        exactMatch: true,
        securityFunctions: ['ORGANISATION_TRAVEL_POLICY_SEARCH:read'],
      },

      // {
      //   id: 'policy.radius',
      //   icon: 'place',
      //   title: 'Radiuses',
      //   type: 'basic',
      //   link: '/policy/radius',
      //   exactMatch: true,
      // },

      {
        id: 'policy.boundary',
        icon: 'room',
        title: 'Boundaries',
        type: 'basic',
        link: '/policy/boundary',
        exactMatch: true,
        securityFunctions: ['ORGANISATION_BOUNDARY_SEARCH:read'],
      },
    ],
  },
];

// export const organisationProMenu: FuseNavigationItem[] = [
//   {
//     id: 'overview',
//     title: 'Overview',
//     icon: 'widgets',
//     link: '/detail',
//     type: 'basic',
//     exactMatch: false,
//   },
//   {
//     id: 'organisation-jobs',
//     title: 'Jobs',
//     type: 'collapsable',
//     link: '/job/active',
//     icon: 'place',
//     children: [
//       {
//         id: 'active-jobs',
//         type: 'basic',
//         link: '/job/active',
//         icon: 'bolt',
//         title: 'Active Jobs',
//       },
//       {
//         id: 'organisation-job-new',
//         type: 'basic',
//         link: '/job/new',
//         icon: 'add',
//         title: 'New Job',
//       },
//     ],
//   },
//   {
//     id: 'staff',
//     title: 'Staff',
//     type: 'basic',
//     icon: 'person',
//     link: '/staff',
//   },

//   {
//     id: 'group',
//     title: 'Groups',
//     type: 'basic',
//     icon: 'group',
//     link: '/group',
//   },

//   {
//     id: 'policy',
//     title: 'Policies',
//     type: 'collapsable',
//     icon: 'settings',
//     link: '/policy/search',

//     children: [
//       {
//         id: 'policy.search',
//         title: 'Search',
//         type: 'basic',
//         icon: 'search',
//         link: '/policy/search',
//         exactMatch: true,
//       },

//       {
//         id: 'policy.boundary',
//         icon: 'room',
//         title: 'Boundaries',
//         type: 'basic',
//         link: '/policy/boundary',
//         exactMatch: true,
//       },
//     ],
//   },
// ];
