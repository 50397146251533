//ingnore sentry errors with the following messages:
import * as Sentry from '@sentry/angular';
export const sentryIgnoreList = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
  'Service workers are disabled or not supported by this browser',
  'Fullscreen mode is not supported by this browser',
  'fullscreen error',
  'Handled unknown error',
  '[{"level":"ERROR","message":"Not Authenticated"}]',
];

export function sentryFilter(event: Sentry.Event) {
  if (
    event.exception &&
    event.exception.values &&
    event.exception.values.length > 0
  ) {
    const exceptionValue = event.exception.values[0];

    if (exceptionValue.value) {
      sentryIgnoreList.forEach((ignoreMessage) => {
        if (exceptionValue.value.includes(ignoreMessage)) {
          event = null;
        }
      });
      if (event != null && isLoadingChunkError(exceptionValue.value)) {
        event = null;
      }
    }
  }
  return event;
}

function isLoadingChunkError(errorMessage: string) {
  if (errorMessage.includes('ChunkLoadError')) {
    alert('Page reload required due to app update');
    location.reload();

    return true;
  }
  return false;
}

export function sentryInitialise(
  dsn: string,
  release: string,
  environment: string
) {
  Sentry.init({
    dsn: dsn,
    environment: environment,
    beforeSend(event: Sentry.Event, hint: Sentry.EventHint): any {
      const filtered = sentryFilter(event);

      return filtered;
    },
    tracesSampleRate: 0,
  });
}
