import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { paths } from '@fleet/environment';
import { catchError } from 'rxjs/operators';
import { encodeParams, handleApiError } from '@fleet/utilities';
import {
  ApiResponse,
  LocalityModel,
  PlaceModel,
  RegionModel,
  TimeZoneModel,
} from '@fleet/model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PlaceApiService {
  host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.place;
  }

  //   GET

  // Perform a reverse geocode on a location to return matching places
  reverseGeocode(payload: {
    latitude: string;
    longitude: string;
    locationType?: string;
    limit?: string;
    offset?: string;
  }): ApiResponse<PlaceModel> | any {
    return this.http
      .get(`${this.host}/reverseGeocode`, { params: payload })
      .pipe(catchError(handleApiError));
  }

  // GET

  // Perform an autocomplete style search for a place or address
  autocompleteSearch(payload: {
    latitude: string;
    longitude: string;
    query: string;
    locationType?: string;
    sessionId?: string;
    limit?: string;
    offset?: string;
    filterType?: string;
    countryCode?: string;
  }): Observable<ApiResponse<PlaceModel[]>> | any {
    return this.http
      .get(`${this.host}/autoComplete`, { params: payload })
      .pipe(catchError(handleApiError));
  }

  getPlace(
    placeId: string,
    params: any
  ): Observable<ApiResponse<PlaceModel[]>> | any {
    return this.http
      .get(`${this.host}/${placeId}`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getTimezone(
    latitude: any,
    longitude: any,
    time: any
  ): Observable<ApiResponse<TimeZoneModel> | any> {
    return this.http
      .get(
        `${this.host}/timezone?latitude=${latitude}&longitude=${longitude}&time=${time}`
      )
      .pipe(catchError(handleApiError));
  }

  searchLocalities(
    params: any
  ): Observable<ApiResponse<LocalityModel[]> | any> {
    return this.http
      .get(`${this.host}/locality`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }

  searchRegions(params: any): Observable<ApiResponse<RegionModel[]> | any> {
    return this.http
      .get(`${this.host}/region`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }
}
