import { Pipe, PipeTransform } from '@angular/core';
import { entityStatusPill } from '@fleet/utilities';

@Pipe({
  name: 'statusChip',
})
export class StatusChipPipe implements PipeTransform {
  transform(action: string, inverse?: boolean): any {
    if (action) {
      action = action.toUpperCase();
      return entityStatusPill(action, inverse);
    }

    return null;
  }
}
