import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { TravellerApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import {
  ApiResponse,
  IssueModel,
  JobModel,
  JobProgressModel,
  JobSearchResultModel,
  TravellerModel,
} from '@fleet/model';
import { WebsocketService } from '@fleet/socket';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TravellerService {
  fleetProduct: string;
  issues: Subject<IssueModel[]> = new Subject();

  traveller: BehaviorSubject<TravellerModel> = new BehaviorSubject(null);
  currentJob: BehaviorSubject<JobModel> = new BehaviorSubject(null);
  upcomingJobs: BehaviorSubject<JobSearchResultModel[]> = new BehaviorSubject(
    []
  );
  constructor(
    private travellerApiService: TravellerApiService,
    @Inject('env') env: any,
    private socketService: WebsocketService
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  get traveller$(): Observable<TravellerModel> {
    return this.traveller.asObservable();
  }

  get currentJob$(): Observable<JobModel> {
    return this.currentJob.asObservable();
  }

  setTraveller(traveller: TravellerModel) {
    if (traveller) {
      if (
        this.fleetProduct === 'TRAVELLER' &&
        (!this.traveller.value ||
          traveller.travellerId != this.traveller.value?.travellerId)
      ) {
        this.initiateTravellerSocket(traveller.travellerId);
      }

      this.traveller.next(traveller);
    } else {
      this.resetTravellerState();
    }
  }

  get travellerId(): string {
    return this.traveller.value ? this.traveller.value.travellerId : null;
  }

  getTraveller(travellerId: string) {
    this.travellerApiService.getTraveller(travellerId).subscribe({
      next: (resp: ApiResponse<TravellerModel> | any) => {
        this.traveller.next(resp.data);
      },
      error: (error) => this.issues.next(error),
    });
  }

  fetchTravellerState() {
    // fetch upcoming jobs
  }

  setCurrentJob(job: JobModel) {
    this.currentJob.next(job);
  }

  initiateTravellerSocket(travellerId: string) {
    this.socketService.connect('traveller.' + travellerId);
    this.socketService.messages$.subscribe({
      next: (message: any) => {
        if (message.type === 'JobProgressModel') {
          const jobProgress = message.data as JobProgressModel;
          if (jobProgress.jobId == this.currentJob.value?.jobId) {
            if (jobProgress.jobStatus != this.currentJob.value.jobStatus) {
              //update status on job
            }
            this.currentJob.next({
              ...this.currentJob.value,
              jobStatus: jobProgress.jobStatus,
              jobProgress: jobProgress,
            });
          }
        }

        if (message.type === 'JobModel') {
          const job = message.data;

          if (job.jobId === this.currentJob.value?.jobId) {
            this.currentJob.next(job);
          }
        }
      },
    });
  }

  resetTravellerState() {
    if (this.fleetProduct === 'TRAVELLER' && this.socketService) {
      this.socketService.close();
    }
    this.traveller.next(null);
    this.currentJob.next(null);
  }
}
