<fleet-sidebar-header
  [title]="call.medium === 'VOICE' ? 'Call Details' : 'Message Details'"
  class="flex flex-col flex-auto w-full sm:w-140"
  (closeSidebar)="cancelled.emit()"
  backgroundClass="bg-default"
>
  <div class="flex flex-col gap-6 w-full">
    <fleet-card-description-list
      [title]="call.medium === 'VOICE' ? 'Call Details' : 'Message Details'"
    >
      <ng-container slot="main">
        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">When</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.startTime | internationalizationDate : 'SHORT_DATE_TIME_OFFSET' }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ call?.type | titleCaseAndClean }} number
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.incomingNumber | mobileFormat }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">Dialled number</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.dialledNumber | mobileFormat }}
          </dd>
        </div>

        <div class="sm:col-span-1" *ngIf="call?.moli">
          <dt class="text-sm font-medium text-gray-500">MOLI</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.moli }}
          </dd>
        </div>
        <div class="sm:col-span-1" *ngIf="call.medium === 'VOICE'">
          <dt class="text-sm font-medium text-gray-500">Agent Handover</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.agentHandover ? 'Yes' : 'No' }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">Who</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <ng-container *ngIf="call?.type === 'INBOUND'; else callCenter">
              <mat-icon
                *ngIf="call.callerType | callerType as callType"
                [matTooltip]="callType.tooltip"
              >
                {{ callType.icon }}
              </mat-icon>
            </ng-container>

            <ng-template #callCenter>
              <mat-icon
                *ngIf="'CALL_CENTRE' | callerType as callType"
                [matTooltip]="callType.tooltip"
              >
                {{ callType.icon }}
              </mat-icon>
            </ng-template>
          </dd>
        </div>

        <div class="sm:col-span-1" *ngIf="call?.callerName">
          <dt class="text-sm font-medium text-gray-500">Caller name</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.callerName }}
          </dd>
        </div>

        <div class="sm:col-span-2" *ngIf="call?.ticketRedirectUrl">
          <dt class="text-sm font-medium text-gray-500">Ticket URL</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <a
              target="_blank"
              [href]="call?.ticketRedirectUrl"
              class="underline cursor-pointer"
            >
              {{ call?.ticketRedirectUrl }}
            </a>
          </dd>
        </div>

        <div class="sm:col-span-1" *ngIf="call.recordingDuration">
          <dt class="text-sm font-medium text-gray-500">Duration</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call.recordingDuration | duration }}
          </dd>
        </div>

        <div
          class="sm:col-span-2"
          *ngIf="call?.outcome && call.medium === 'VOICE'"
        >
          <dt class="text-sm font-medium text-gray-500">Outcome</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.outcome }}
          </dd>
        </div>

        <div class="sm:col-span-2" *ngIf="call?.note?.content">
          <dt class="text-sm font-medium text-gray-500">Note</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.note?.content }}
          </dd>
        </div>

        <div class="sm:col-span-2" *ngIf="call?.message">
          <dt class="text-sm font-medium text-gray-500">Message</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ call?.message }}
          </dd>
        </div>

        <div
          class="sm:col-span-2"
          *ngIf="
            call.travellerId ||
            call.organisationId ||
            call.driverId ||
            call.jobId
          "
        >
          <dt class="text-sm font-medium text-gray-500">Links</dt>
          <dd class="mt-1 text-sm text-gray-900 flex flex-row gap-4">
            <div
              *ngIf="call.travellerId && entityType !== 'TRAVELLER'"
              class="cursor-pointer"
              [matTooltip]="'Navigate to traveller'"
              (click)="routeToTraveller(call.travellerId)"
            >
              <mat-icon>hail</mat-icon>
            </div>

            <div
              *ngIf="call.organisationId && entityType !== 'ORGANISATION'"
              class="cursor-pointer"
              [matTooltip]="'Navigate to Organisation'"
              (click)="routeToOrganisation(call.organisationId)"
            >
              <mat-icon>business</mat-icon>
            </div>

            <div
              *ngIf="call.driverId && entityType !== 'DRIVER'"
              class="cursor-pointer"
              [matTooltip]="'Navigate to driver'"
              (click)="routeToDriver(call.driverId)"
            >
              <mat-icon>local_taxi</mat-icon>
            </div>

            <div
              *ngIf="call.jobId && entityType !== 'JOB'"
              class="cursor-pointer"
              [matTooltip]="'Navigate to Job'"
              (click)="routeToJob(call.jobId)"
            >
              <mat-icon>location_on</mat-icon>
            </div>
          </dd>
        </div>

        <div
          class="sm:col-span-2"
          *ngIf="call?.recordingUrl && ('CALL_RECORDING:read' | hasFunction)"
        >
          <audio *ngIf="call.recordingUrl" controls [autoplay]="autoPlay">
            <source [src]="call.recordingUrl" type="audio/wav" />
          </audio>
        </div>
      </ng-container>
    </fleet-card-description-list>
  </div>
</fleet-sidebar-header>
