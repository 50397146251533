import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JobApiService } from '@fleet/api';
import {
  ApiResponse,
  IssueModel,
  JobLifecycleListenerModel,
  JobModel,
  JobProgressModel,
  JobSearchResultModel,
  JobStatusUpdateAndProgress,
} from '@fleet/model';
import { WebsocketService } from '@fleet/socket';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { failureNotification, jobColorFromStatus } from '@fleet/utilities';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService } from '@fleet/auth';
import {
  MOCK_LISTENER_MODEL,
  CURRENT_MOCK_LISTENER_MODEL,
} from './mock/job-listener-mock';
import { mockActiveJobs } from './mock/job-mocks';

@Injectable({ providedIn: 'root' })
export class JobListenerService extends WebsocketService {
  jobListener: BehaviorSubject<JobLifecycleListenerModel> = new BehaviorSubject(
    null
  );
  jobListenerRequest: BehaviorSubject<JobLifecycleListenerModel> =
    new BehaviorSubject(null);

  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  issues: BehaviorSubject<IssueModel[]> = new BehaviorSubject([]);

  client = 'Default Holder';

  constructor(
    private jobApiService: JobApiService,
    authService: AuthService,
    private onScreenNotificationService: OnscreenNotificationService,
    httpClient: HttpClient,
    @Inject('env') env: any
  ) {
    super(env, httpClient);
    this.messages$.subscribe({
      next: (message: any) => {
        if (message.type === 'JobLifecycleListenerModel') {
          this.setListener(message.data);
        }
      },
    });
  }

  get listener() {
    return this.jobListener.value;
  }

  get listener$() {
    return this.jobListener.asObservable();
  }

  get loading$() {
    return this.loading.asObservable();
  }

  get issues$() {
    return this.issues.asObservable();
  }

  get listenerParams$() {
    return this.listener$.pipe(
      filter((l) => l !== null),
      map((listener: JobLifecycleListenerModel) => {
        return {
          fromOffset: listener.fromOffset,
          toOffset: listener.toOffset,
          statuses: listener.statuses,
        } as JobLifecycleListenerModel;
      })
    );
  }

  get currentJob$() {
    return this.jobListener.asObservable().pipe(
      map((jobLifecycle: JobLifecycleListenerModel) => {
        if (
          jobLifecycle &&
          jobLifecycle.statusUpdates &&
          jobLifecycle.statusUpdates.length === 1 &&
          jobLifecycle.jobId
        ) {
          return this.jobFromJobSearchResult(jobLifecycle.statusUpdates[0]);
        }
        return null;
      })
    );
  }

  get currentJobProgressUpdate$() {
    return this.jobListener.asObservable().pipe(
      filter((jobLifecycle: JobLifecycleListenerModel) => {
        if (
          jobLifecycle &&
          jobLifecycle.progressUpdates &&
          jobLifecycle.progressUpdates.length === 1 &&
          jobLifecycle.jobId
        ) {
          return true;
        }
        return false;
      }),
      map(
        (jobLifecycle: JobLifecycleListenerModel) =>
          jobLifecycle.progressUpdates[0]
      )
    );
  }

  jobFromJobSearchResult(jsr: JobSearchResultModel) {
    return {
      jobStatus: jsr.status,
      paymentStatus: jsr.paymentStatus,
      jobId: jsr.jobId,
    } as JobModel;
  }

  get jobs$(): Observable<JobStatusUpdateAndProgress[]> {
    return this.jobListener.asObservable().pipe(
      map((listener: JobLifecycleListenerModel) =>
        listener && listener.statusUpdates
          ? listener.statusUpdates.map((statusUpdate: JobSearchResultModel) => {
              const foundProgress = listener.progressUpdates
                ? listener.progressUpdates.find(
                    (s) => s.jobId === statusUpdate.jobId
                  )
                : null;
              return {
                latestStatusUpdate: statusUpdate,
                latestJobProgress: foundProgress,
                statusColor: jobColorFromStatus(statusUpdate.status),
              } as JobStatusUpdateAndProgress;
            })
          : []
      )
    );
  }

  setListener(jobListener: JobLifecycleListenerModel) {
    if (jobListener) {
      if (jobListener.delta === true) {
        if (jobListener.eventType === 'BOOKING_EXPIRY_CONFIRMED') {
          if (
            jobListener.statusUpdates[0] &&
            jobListener.statusUpdates[0].status === 'EXPIRED'
          ) {
            //remove the job from the listener
            this.removeJobFromListener(jobListener.statusUpdates[0].jobId);
          }
        } else {
          if (jobListener.statusUpdates) {
            let statusUpdates = [];
            let found = false;
            const statusUpdate = jobListener.statusUpdates[0];
            statusUpdates =
              this.jobListener.value && this.jobListener.value.statusUpdates
                ? this.jobListener.value.statusUpdates.map(
                    (job: JobSearchResultModel) => {
                      if (job.jobId === statusUpdate.jobId) {
                        found = true;
                        return statusUpdate;
                      }
                      return job;
                    }
                  )
                : [];
            if (!found) {
              //add to list
              statusUpdates.push(statusUpdate);
            }
            this.jobListener.next({
              ...this.jobListener.value,
              statusUpdates: statusUpdates,
            });
          }
          if (jobListener.progressUpdates) {
            let progressUpdates = [];
            let found = false;
            const progressUpdate = jobListener.progressUpdates[0];
            progressUpdates =
              this.jobListener.value && this.jobListener.value.progressUpdates
                ? this.jobListener.value.progressUpdates.map(
                    (progress: JobProgressModel) => {
                      if (progress.jobId === progressUpdate.jobId) {
                        found = true;
                        return progressUpdate;
                      }
                      return progress;
                    }
                  )
                : [];
            if (!found) {
              //add to list
              progressUpdates.push(progressUpdate);
            }

            this.jobListener.next({
              ...this.jobListener.value,
              progressUpdates: progressUpdates,
            });
          }
        }
      } else if (jobListener.delta === false) {
        this.jobListener.next(jobListener);
      } else {
        //CHECK WITH SIMON - is this just a listener param update
        this.jobListener.next(jobListener);
      }
    } else {
      this.jobListener.next(jobListener);
    }
  }

  registerListener(jobListener: JobLifecycleListenerModel) {
    this.jobListenerRequest.next(jobListener);
    this.loading.next(true);
    this.issues.next([]);
    this.jobApiService.registerListener(jobListener).subscribe({
      next: (resp: ApiResponse<JobLifecycleListenerModel>) => {
        this.loading.next(false);

        //PUT MOCK HERE FOR DRIVER.
        this.jobListener.next(resp.data);
        //this.jobListener.next(CURRENT_MOCK_LISTENER_MODEL.data as any);

        this.connect(
          'jobLifecycleListener.' + this.jobListener.value.listenerId
        );
      },
      error: (issues: IssueModel[]) => {
        this.loading.next(false);
        this.issues.next(issues);
      },
    });
  }

  updateListener(jobListener: JobLifecycleListenerModel) {
    this.jobListenerRequest.next(jobListener);
    this.loading.next(true);
    this.issues.next([]);
    this.jobApiService.updateListener(jobListener).subscribe({
      next: (resp: ApiResponse<JobLifecycleListenerModel>) => {
        this.loading.next(false);
        this.setListener(resp.data);
      },
      error: (issues: IssueModel[]) => {
        this.loading.next(false);
        this.issues.next(issues);
      },
    });
  }

  deregisterListener() {
    if (this.jobListener.value && this.jobListener.value.listenerId) {
      console.log('Deregistering Listener: ' + this.client);
      this.jobApiService
        .deregisterListener(this.jobListener.value.listenerId)
        .subscribe({
          next: () => {
            this.jobListener.next(null);
            this.jobListenerRequest.next(null);
            this.close();
          },
          error: (error) => {
            this.jobListener.next(null);
            this.jobListenerRequest.next(null);
            this.close();
          },
        });
    }
  }

  get isListening$() {
    return this.isOpen$;
  }

  configureListener(jobListener: JobLifecycleListenerModel) {
    if (!this.jobListener.value) {
      this.registerListener(jobListener);
    } else {
      this.updateListener(jobListener);
    }
  }

  dismissJob(jobId: string) {
    this.jobApiService
      .dismissJobFromListener(this.jobListener.value.listenerId, jobId)
      .subscribe({
        next: (resp: ApiResponse<JobLifecycleListenerModel>) => {
          this.removeJobFromListener(jobId);
        },
        error: (issues: IssueModel[]) => {
          this.onScreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Failed to dismiss job!',
          } as OnScreenNotification);
        },
      });
  }

  removeJobFromListener(jobId: string) {
    this.jobListener.next({
      ...this.jobListener.value,
      statusUpdates: this.jobListener.value.statusUpdates.filter(
        (s) => s.jobId !== jobId
      ),
    });
  }
}
