import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseMediaWatcherService } from '@fleet/fuse';
import { EntityHeaderService } from '@fleet/layout';
import { FuseNavigationItem, JobModel, NetworkGroupModel } from '@fleet/model';

import { AuthService } from '@fleet/auth';
import { filterNavigationItemsByFunctions } from '@fleet/navigation';
import { NetworkGroupService } from '@fleet/network-group';
import { HasFunctionPipe } from '@fleet/security-shared';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { JobActionType } from '../../../job-action';
import {
  JobActionExecution,
  JobActionService,
} from '../../../job-action/job-action.service';
import {
  jobDetailNavigation,
  travellerJobDetailNavigation,
} from '../../job-detail-navigation';
import { JobDetailService } from '../../job-detail.service';
@Component({
  selector: 'fleet-job-detail-container',
  templateUrl: './job-detail-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
// extends EntityDetailLayoutComponent
export class JobDetailContainerComponent implements OnInit, OnDestroy {
  job: JobModel;
  isSocketOpen: boolean;
  shouldListen = false;
  jobDetailNavigation: FuseNavigationItem[] = [];
  feesExternalLink: string;

  showHeader: boolean;
  routerData$: BehaviorSubject<any> = new BehaviorSubject(null);
  _unsubscribeAll = new Subject();
  fleetProduct: string;
  hiddenActions: string[] = [];

  hasFunction = new HasFunctionPipe(this.authService);
  constructor(
    fuseMediaWatcherService: FuseMediaWatcherService,
    private changeDetectorRef: ChangeDetectorRef,
    protected router: Router,
    private _activatedRoute: ActivatedRoute,
    private jobDetailService: JobDetailService,
    private entityHeaderService: EntityHeaderService,
    private jobActionService: JobActionService,
    private authService: AuthService,
    private networkGroupService: NetworkGroupService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
    // super(fuseMediaWatcherService, changeDetectorRef, router, activatedRoute);
  }

  ngOnInit(): void {
    if (!this.hasFunction.transform('JOB_ACTIVITY_ASSIGNMENT:execute')) {
      this.hiddenActions.push('MANUAL_DISPATCH');
      this.changeDetectorRef.markForCheck();
    }
    if (!this.hasFunction.transform('JOB_CANCEL:execute')) {
      this.hiddenActions.push('CANCEL');
      this.changeDetectorRef.markForCheck();
    }

    if (!this.hasFunction.transform('JOB_CLONE:execute')) {
      this.hiddenActions.push('CLONE');
      this.changeDetectorRef.markForCheck();
    }

    if (this.fleetProduct === 'TRAVELLER') {
      this.jobDetailNavigation = travellerJobDetailNavigation;

      //subscribe to network group to get the external taxi fee links
      this.networkGroupService.networkGroup$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (networkGroup: NetworkGroupModel) => {
            if (networkGroup && networkGroup.websiteUrl) {
              this.feesExternalLink = networkGroup.websiteUrl + '/taxi-fares';
            }
          },
        });
    } else {
      this.jobDetailNavigation = filterNavigationItemsByFunctions(
        jobDetailNavigation,
        this.authService.permissions.value
      );
    }
    // this.fleetProduct === 'HUB'
    //   ? jobDetailNavigation
    //   : nonHubJobDetailNavigation;
    this.routerData$.next(this._activatedRoute.snapshot.firstChild.data);

    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((routeEvent) => routeEvent instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map((activatedRoute) => activatedRoute.firstChild),
        switchMap((firstChild) => firstChild.data),
        map((data: any) => data)
      )
      .subscribe((data: any) => {
        this.routerData$.next({
          title: data.title,
          showHeader: data.showHeader,
        });
      });

    combineLatest([this.routerData$.asObservable(), this.jobDetailService.job$])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([routeData, job]) => {
        this.job = job;

        if (routeData) {
          this.showHeader = routeData.showHeader;

          this.entityHeaderService.setEntityHeaderJobModel(
            job,
            routeData.title,
            'DETAIL'
          );
        }
        this.changeDetectorRef.markForCheck();
      });

    this.jobDetailService.shouldListen$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (shouldListen: boolean) => {
          this.shouldListen = shouldListen;
          this.changeDetectorRef.markForCheck();
        },
      });

    //subscribe if job action service and update state
    this.jobActionService.jobActionExecution$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (execution: JobActionExecution) => {
          if (execution) {
            if (execution.success && this.job.jobId === execution.job.jobId) {
              if (execution.actionType === JobActionType.delete) {
                if (this.fleetProduct === 'HUB') {
                  this.router.navigate(['/job/search']);
                } else if (this.fleetProduct === 'TRAVELLER') {
                  this.router.navigate(['/job/new']);
                }
              } else {
                this.jobDetailService.setJob(execution.job);
              }
            }
          }
        },
      });

    this.jobDetailService.listening$.subscribe({
      next: (isOpen) => {
        this.isSocketOpen = isOpen;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  socketToggleChange($event: any) {
    console.log($event);
  }

  jobExtended(job: JobModel) {
    this.jobDetailService.setJob(job);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.entityHeaderService.setEntityHeader(null);
    // this.jobDetailService.setJob(null);
  }
}
