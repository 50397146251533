<mat-form-field *ngIf="timeTypeControl && !customOnly" class="w-full">
  <mat-label class="text-sm font-light">{{ label }}</mat-label>
  <mat-select [formControl]="timeTypeControl">
    <mat-option [value]="'CUSTOM'"> Custom</mat-option>
    <mat-option [value]="'24HRS'">Last 24Hrs</mat-option>
    <mat-option [value]="'12HRS'">Last 12hrs</mat-option>
    <mat-option [value]="'3HRS'">Last 3hrs</mat-option>
    <mat-option [value]="'NOW'">Current (-1hr to +1hr)</mat-option>
    <mat-option [value]="'NEXT_HOUR'">Next Hour</mat-option>
    <mat-option [value]="'NEXT_3HOURS'">Next 3 Hours</mat-option>
    <mat-option [value]="'NEXT_12HOURS'">Next 12 Hours</mat-option>
    <mat-option [value]="'TODAY'">Today</mat-option>
    <mat-option [value]="'TOMORROW'">Tomorrow</mat-option>
    <!-- <mat-option [value]="'2DAYS'">Next 2 Days</mat-option> -->
    <mat-option [value]="'WEEK'">Next 7 Days</mat-option>
  </mat-select>
</mat-form-field>
<ng-container
  *ngIf="
    customTimeForm && timeTypeControl && timeTypeControl.value === 'CUSTOM'
  "
>
  <form
    [formGroup]="customTimeForm"
    [class]="formCustomClass ? formCustomClass : 'flex flex-col  w-full'"
  >
    <fleet-date-with-time-control
      [minDate]="minDate"
      [defaultTime]="defaultStartTime"
      [showNowControl]="showNowControl"
      #startDate
      formControlName="fromDate"
      [dateLabel]="effectiveFromLabel ? effectiveFromLabel : 'Effective from'"
      [timeLabel]="'&nbsp;'"
      [labelClass]="inSearchForm ? 'text-sm font-light' : ''"
      [required]="fromRequired"
      [futureDateOnly]="minDate ? true : false"
      [controlCustomClass]="controlCustomClass"
      [timeRequired]="false"
      [includeTime]="includeTime"
      class="w-full"
    >
    </fleet-date-with-time-control>
    <fleet-date-with-time-control
      [ngClass]="formCustomClass ? '' : 'mt-4'"
      [minDate]="minDate"
      [defaultTime]="defaultEndTime"
      [required]="toRequired"
      [timeRequired]="false"
      #endDate
      formControlName="toDate"
      [dateLabel]="effectiveToLabel ? effectiveToLabel : 'Effective to'"
      [timeLabel]="'&nbsp;'"
      [labelClass]="inSearchForm ? 'text-sm font-light' : ''"
      [futureDateOnly]="minDate ? true : false"
      [controlCustomClass]="controlCustomClass"
      [includeTime]="includeTime"
      class="w-full"
    >
    </fleet-date-with-time-control>
    <mat-form-field class="w-full mt-3" *ngIf="!hideTimezone">
      <mat-label [class]="inSearchForm ? 'text-sm font-light' : ''"
        >Timezone</mat-label
      >
      <mat-select [formControl]="avaiableTimezoneControl">
        <mat-option *ngFor="let zone of timezones" [value]="zone">{{
          zone
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ng-container>
