import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravellerSearchByPhoneNumberComponent } from './traveller-search-by-phone-number.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [TravellerSearchByPhoneNumberComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [TravellerSearchByPhoneNumberComponent],
})
export class TravellerSearchByPhoneNumberModule {}
