<div class="flex items-center flex-wrap items-center font-medium">
  <ng-container *ngIf="group.parent; else noParents">
    <div *ngIf="group.parent.parent">
      <ng-container
        *ngTemplateOutlet="
          groupBreadcrumb;
          context: { $implicit: group.parent.parent }
        "
      ></ng-container>
    </div>
    <ng-container
      *ngTemplateOutlet="groupBreadcrumb; context: { $implicit: group.parent }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="groupBreadcrumb; context: { $implicit: group }"
    ></ng-container>
  </ng-container>
  <ng-template #noParents>
    <ng-container
      *ngTemplateOutlet="groupBreadcrumb; context: { $implicit: group }"
    ></ng-container
  ></ng-template>
</div>

<ng-template #groupBreadcrumb let-group>
  <div class="flex flex-row items-center">
    <mat-icon
      *ngIf="group.parent"
      class="icon-size-5 text-secondary ml-1 text-current"
      [svgIcon]="'heroicons_solid:chevron-right'"
    ></mat-icon>

    <mat-icon
      matTooltip="Close All Group"
      class="mr-2 text-current cursor-pointer"
      [svgIcon]="'heroicons_outline:x-circle'"
      *ngIf="!group.parent"
      [routerLink]="['/group']"
    ></mat-icon>
    <a class="whitespace-nowrap" [routerLink]="[group.link]">{{
      group.displayName
    }}</a>
  </div>
</ng-template>

<ng-template #groupPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg"
  >
    <!-- Header -->
    <div
      class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary-600 text-white"
    >
      <div class="sm:hidden -ml-1 mr-3">
        <button mat-icon-button (click)="closePanel()">
          <mat-icon
            class="icon-size-5 text-current"
            [svgIcon]="'heroicons_solid:x'"
          ></mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-10">Current Group</div>
    </div>

    <!-- Content -->
    <div
      class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card"
    ></div>
  </div>
</ng-template>
