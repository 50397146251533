import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { EntityHeaderService } from './entity-header.service';

import { EntityHeader } from '@fleet/model';
import { Router } from '@angular/router';
import { EntityDetailLayoutService } from '../entity-detail-layout';

@Component({
  selector: 'fleet-entity-header-with-breadcrumbs',
  templateUrl: './entity-header-with-breadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityHeaderWithBreadcrumbsComponent implements OnInit {
  @Input() header: EntityHeader;
  showMenuButton: boolean;
  @Input() isCard: boolean;

  @Input() set mode(value: 'DETAIL' | 'SETTINGS') {
    if (value === 'DETAIL') {
      this.entityDetailLayoutService.drawOpened$.subscribe({
        next: (opened: boolean) => {
          this.showMenuButton = !opened;
          this.changeDetectorRef.markForCheck();
        },
      });
    }
  }

  constructor(
    private entityDetailLayoutService: EntityDetailLayoutService,
    private changeDetectorRef: ChangeDetectorRef,
    private entityHeaderService: EntityHeaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.entityHeaderService.entityHeader$.subscribe((header) => {
      this.header = header;
      this.changeDetectorRef.markForCheck();
    });
  }

  menuButtonClicked() {
    this.entityDetailLayoutService.setDrawOpened(true);
  }

  dataItemClicked(item: any) {
    if (item.link && !item.externalLink) {
      this.router.navigate([item.link]);
    } else if (item.link && item.externalLink) {
      window.open(item.link, '_blank');
    } else if (item.statusLink) {
      this.router.navigate([item.statusLink]);
    }
  }
}
