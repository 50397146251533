import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ChangeLogComponent } from '../change-log/change-log.component';

@Component({
  selector: 'fleet-version-banner',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule],
  template: `<div class="relative bg-primary-600" *ngIf="!hide">
    <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:px-16 sm:text-center">
        <p class="font-medium text-white">
          <span> {{ product }} {{ envName }} v{{ version }}</span>
        </p>
      </div>
      <div
        class="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2"
      >
        <button
          type="button"
          (click)="hideBanner()"
          class="flex rounded-md p-2 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white"
        >
          <span class="sr-only">Dismiss</span>
          <!-- Heroicon name: outline/x-mark -->
          <svg
            class="h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <button
          type="button"
          (click)="openReleaseNotes()"
          class="ml-4 text-white underline"
        >
          Release Notes
        </button>
      </div>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionBannerComponent implements OnInit {
  envName: any;
  product: string;
  version: string;
  hide = false;
  constructor(
    @Inject('env') env: any,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.envName = env.envName;
    this.product = env.fleetProduct;
    this.version = env.version;
  }

  ngOnInit(): void {
    // Initialization logic if needed
  }

  hideBanner() {
    this.hide = true;
    this.changeDetectorRef.markForCheck();
  }

  openReleaseNotes() {
    this.dialog.open(ChangeLogComponent, {
      width: '80%',
      height: '80%',
    });
  }
}
