/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleMapsApiService, JobApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import { ApiResponse, JobModel, JobSearchResultModel } from '@fleet/model';
import { Observable } from 'rxjs';

@Component({
  selector: 'fleet-job-card',
  templateUrl: './job-card.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class JobCardComponent implements OnInit {
  @Input() job: JobModel;
  @Input() showDetailsLink = true;

  fleetProduct: string;

  @Output() selectJob = new EventEmitter();

  @Input() set jobId(value: string) {
    if (value) {
      this.jobApiService.getJob(value).subscribe({
        next: (resp: ApiResponse<JobModel>) => {
          this.job = resp.data;
          this.changeDetectionRef.markForCheck();
        },
      });
    }
  }

  @Output() jobDetailRouteSelected = new EventEmitter();

  constructor(
    private jobApiService: JobApiService,
    private changeDetectionRef: ChangeDetectorRef,

    private router: Router,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  ngOnInit() {}

  jobSelected() {
    this.selectJob.emit(this.job);
  }

  navigateToDriver() {
    if (this.fleetProduct === 'HUB') {
      const url = 'driver/' + this.job.driver.driverId;
      this.router.navigate([url]);
    }
  }

  navigateToTraveller() {
    if (this.fleetProduct === 'HUB') {
      const url = 'traveller/' + this.job.traveller.travellerId;
      this.router.navigate([url]);
    }
  }
  selectJobDetailRoute(job: JobModel) {
    let url: any;
    if (this.fleetProduct === 'TRAVELLER') {
      url = '/traveller/job/' + job.jobId;
    } else {
      url = '/job/' + job.jobId;
    }
    this.jobDetailRouteSelected.emit(job);
    setTimeout(() => {
      this.router.navigate([url]);
    }, 1);
  }
}
