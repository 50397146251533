import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { PlaceApiService, ServiceApiService } from '@fleet/api';
import {
  ApiResponse,
  LocationModel,
  NetworkServicePriceModel,
  ServicePriceModel,
  TimeZoneModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { DateTime } from 'luxon';
const defaultTimezone = <TimeZoneModel>{
  id: 'Sydney/Australia',
  offset: 36000,
  dstOffset: 0,
};

@Component({
  selector: 'fleet-service-select',
  templateUrl: './service-select.component.html',
  styleUrls: ['./service-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceSelectComponent),
      multi: true,
    },
  ],
})
export class ServiceSelectComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  // mocksData: any = [
  //   {
  //     serviceId: 'a17179149',
  //     networkServiceId: 'a17179149',
  //     defaultService: true,
  //     displayName: 'Standard',
  //     description: 'Standard taxi with 4 passengers or less',
  //     prices: [
  //       {
  //         pricingMethodId: 'a17179149',
  //         pricingMethodType: 'METERED',
  //         currencyCode: 'AUD',
  //       },
  //       {
  //         pricingMethodId: 'a2e2f2292',
  //         pricingMethodType: 'QUOTED',
  //         currencyCode: 'AUD',
  //       },
  //     ],
  //   },
  //   {
  //     serviceId: 'a17179149',
  //     networkServiceId: 'a2e2f2292',
  //     defaultService: false,
  //     displayName: 'Easy access',
  //     description: 'Vehicles with no step and low ground clearance',
  //     prices: [
  //       {
  //         pricingMethodId: 'a17179149',
  //         pricingMethodType: 'METERED',
  //         currencyCode: 'AUD',
  //       },
  //       {
  //         pricingMethodId: 'a2e2f2292',
  //         pricingMethodType: 'QUOTED',
  //         currencyCode: 'AUD',
  //       },
  //     ],
  //   },
  //   {
  //     serviceId: 'a2e2f2292',
  //     networkServiceId: 'a09abe9db',
  //     defaultService: false,
  //     displayName: 'SUV',
  //     description:
  //       'Larger vehicle with additional luggage space or additional passengers',
  //     prices: [
  //       {
  //         pricingMethodId: 'a17179149',
  //         pricingMethodType: 'METERED',
  //         currencyCode: 'AUD',
  //       },
  //       {
  //         pricingMethodId: 'a2e2f2292',
  //         pricingMethodType: 'QUOTED',
  //         currencyCode: 'AUD',
  //       },
  //     ],
  //   },
  //   {
  //     serviceId: 'a09abe9db',
  //     networkServiceId: 'a20c37b24',
  //     defaultService: false,
  //     displayName: 'Small Van',
  //     description:
  //       'People mover seats uo to 7 passengers or additional luggage',
  //     prices: [
  //       {
  //         pricingMethodId: 'a17179149',
  //         pricingMethodType: 'METERED',
  //         currencyCode: 'AUD',
  //       },
  //       {
  //         pricingMethodId: 'a2e2f2292',
  //         pricingMethodType: 'QUOTED',
  //         currencyCode: 'AUD',
  //       },
  //     ],
  //   },
  //   {
  //     serviceId: 'a09abe9db',
  //     networkServiceId: 'a37db0c6d',
  //     defaultService: false,
  //     displayName: 'Large Van',
  //     description: 'Small bus seats uo to 12 passengers or large luggage',
  //     prices: [
  //       {
  //         pricingMethodId: 'a17179149',
  //         pricingMethodType: 'METERED',
  //         currencyCode: 'AUD',
  //       },
  //       {
  //         pricingMethodId: 'a2e2f2292',
  //         pricingMethodType: 'QUOTED',
  //         currencyCode: 'AUD',
  //       },
  //     ],
  //   },
  //   {
  //     serviceId: 'a20c37b24',
  //     networkServiceId: 'a1357d3b6',
  //     defaultService: false,
  //     displayName: 'Eco-friendly',
  //     description: 'Environmentally friendly hybrid or electric vehicles',
  //     prices: [
  //       {
  //         pricingMethodId: 'a17179149',
  //         pricingMethodType: 'METERED',
  //         currencyCode: 'AUD',
  //       },
  //       {
  //         pricingMethodId: 'a2e2f2292',
  //         pricingMethodType: 'QUOTED',
  //         currencyCode: 'AUD',
  //       },
  //     ],
  //   },
  //   {
  //     serviceId: 'a20c37b24',
  //     networkServiceId: 'a2a6f64ff',
  //     defaultService: false,
  //     displayName: 'Electric',
  //     description: 'Electric vehicle',
  //     prices: [
  //       {
  //         pricingMethodId: 'a17179149',
  //         pricingMethodType: 'METERED',
  //         currencyCode: 'AUD',
  //       },
  //       {
  //         pricingMethodId: 'a2e2f2292',
  //         pricingMethodType: 'QUOTED',
  //         currencyCode: 'AUD',
  //       },
  //     ],
  //   },
  //   {
  //     serviceId: 'a37db0c6d',
  //     networkServiceId: 'a05ec2c48',
  //     defaultService: false,
  //     displayName: 'Wheelchair accessible',
  //     description: 'Vehicle fitted with a wheelchair lift',
  //     prices: [
  //       {
  //         pricingMethodId: 'a17179149',
  //         pricingMethodType: 'METERED',
  //         currencyCode: 'AUD',
  //       },
  //       {
  //         pricingMethodId: 'a2e2f2292',
  //         pricingMethodType: 'QUOTED',
  //         currencyCode: 'AUD',
  //       },
  //     ],
  //   },
  // ];
  serviceControl = new UntypedFormControl();
  _services: ServicePriceModel[];
  @Input() set services(services: ServicePriceModel[]) {
    this._services = services;
  }
  get services() {
    return this._services;
  }

  @Input() startLocation: LocationModel;
  @Input() endLocation: LocationModel;
  @Input() waypoints: LocationModel[];
  @Input() startTime: DateTime;
  timeZone: TimeZoneModel = defaultTimezone;
  constructor(
    private serviceApiService: ServiceApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private placesApiService: PlaceApiService,
    private networkGroupService: NetworkGroupService
  ) {}

  ngOnInit(): void {
    this;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    console.log(this.startLocation);
    console.log(this.startTime);
    if (this.startLocation && this.startTime && this.startTime.isValid) {
      //@TODO service selector handle endLocation
      this.placesApiService
        .getTimezone(
          this.startLocation.latitude,
          this.startLocation.longitude,
          this.startTime.toISO()
        )
        .subscribe({
          next: (resp: ApiResponse<TimeZoneModel> | any) => {
            this.timeZone = resp.data;
            const params = this.prepareParamsForServiceSearch(
              this.startLocation,
              this.startTime,
              this.endLocation,
              this.waypoints
            );
            if (this.endLocation)
              this.serviceApiService.searchServices(params).subscribe({
                next: (resp: ApiResponse<NetworkServicePriceModel[]>) => {
                  this.services = resp.data[0].services;
                  this.changeDetectorRef.markForCheck();
                },
              });
          },
        });
    } else {
      this.services = [
        {
          displayName: 'Please select a valid start location and time',
          disabled: true,
        } as any,
      ];
    }

    this.serviceControl.valueChanges.subscribe({
      next: (service: ServicePriceModel) => {
        this.onChange(service);
        this.onTouched();
      },
    });
  }

  writeValue(service: ServicePriceModel): void {
    if (service) {
      this.serviceControl.setValue(service);
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.serviceControl.disable();
    } else {
      this.serviceControl.enable();
    }
  }

  prepareParamsForServiceSearch(
    startLocation: LocationModel,
    startTime: DateTime,
    endLocation?: LocationModel,

    waypoints?: LocationModel[]
  ) {
    const params = {
      startLocation: startLocation.latitude + '|' + startLocation.longitude,
      startTime: startTime,
    } as any;
    if (endLocation) {
      params['endLocation'] =
        endLocation.latitude + '|' + endLocation.longitude;
    }
    if (
      waypoints?.filter(
        (waypoint: LocationModel) => waypoint?.latitude !== null
      ).length > 0
    ) {
      params['waypointLocation'] = waypoints
        .filter(
          (waypoint: LocationModel) => waypoint != null && waypoint != undefined
        )
        .map(
          (waypoint: LocationModel) =>
            waypoint.latitude + '|' + waypoint.longitude
        )
        .join(',')
        .toString();
    }
    if (this.networkGroupService.networkGroupId) {
      params['networkGroupId'] = this.networkGroupService.networkGroupId;
    }
    return params;
  }
}
