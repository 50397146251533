import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  AlertModel,
  ApiResponse,
  IssueModel,
  StateChangeModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlertApiService {
  host;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.alert;
  }

  searchAlerts(
    params: any
  ): Observable<HttpResponse<ApiResponse<AlertModel[]>> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getAlert(alertId: string): Observable<any> {
    return this.http
      .get(`${this.host}/${alertId}`)
      .pipe(catchError(handleApiError));
  }

  updateAlert(
    alertId: string,
    alert: AlertModel
  ): Observable<ApiResponse<AlertModel>> | any {
    return this.http
      .put(`${this.host}/${alertId}`, alert)
      .pipe(catchError(handleApiError));
  }

  closeAlert(
    alertId: string,
    stateChangeModel: StateChangeModel
  ): Observable<any> {
    return this.http
      .post(`${this.host}/${alertId}/close`, stateChangeModel)
      .pipe(catchError(handleApiError));
  }
}
