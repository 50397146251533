import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TicketApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  JobModel,
  PaymentTransactionModel,
  TicketModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';
import { DialogLayoutComponent } from '@fleet/ui';
import { BehaviorSubject } from 'rxjs';

export interface TicketCreateEditState {
  loading: boolean;
  issues: IssueModel[];
  buttonLabel: string;
  title: string;
  instructions: string;
}

@Component({
  selector: 'fleet-ticket-create-edit',
  templateUrl: './ticket-create-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class TicketCreateEditComponent
  extends DialogLayoutComponent
  implements OnInit
{
  CATEGORY_TYPES: string[] = [
    'BILLING',
    'GENERAL_INQUIRY',
    'RECEIPT_REQUEST',
    'LOST_PROPERTY',
    'TRANSACTION',
    'SETTLEMENT',
    'PAYMENT',
    'ACCOUNT',
    'COMMENDATION',
    'COMPLAINT',
    'INCIDENT',
    'VEHICLE',
    'TRAINING',
    'SALES',
  ];

  ISSUE_TYPES: string[] = [
    'ACCOUNT_DETAILS_UPDATE_CREDENTIALS',
    'ACCOUNT_DETAILS_UPDATE_PAYMENT',
    'ACCOUNT_DETAILS_UPDATE_USERS',
    'ACCOUNT_DETAILS_UPDATE',
    'COMMENDATION_DRIVER',
    'COMMENDATION_OTHER',
    'COMMENDATION_STAFF',
    'COMPLAINT_APP',
    'COMPLAINT_BOOKING_LATE_PICKUP',
    'COMPLAINT_BOOKING_NO_PICKUP',
    'COMPLAINT_DISPATCH',
    'COMPLAINT_DRIVER',
    'COMPLAINT_DRIVING',
    'COMPLAINT_EQUIPMENT',
    'COMPLAINT_FARE_EVASION',
    'COMPLAINT_PRICING',
    'COMPLAINT_STAFF',
    'COMPLAINT_TRAVELLER',
    'COMPLAINT_VEHICLE',
    'FARE_EVASION',
    'FARE',
    'INQUIRY',
    'LEASE_NEW',
    'LEASE_QUERY',
    'LOST_PROPERTY',
    'MISSING_PAYMENT',
    'MISSING_SETTLEMENT',
    'NEW_ACCOUNT',
    'OPERATIONAL',
    'PRODUCT_ACTIVATION',
    'PRODUCT_SUPPORT',
    'RECEIPT_REQUEST',
    'REFUND',
    'REGULATORY',
    'REPORTABLE_INCIDENT',
    'SALES_CAMPAIGN',
    'SALES_ENQUIRY',
    'SPAM',
    'TAXI_SUBSIDY',
    'TERMINAL_ACTIVATION',
    'TERMINAL_FEE',
    'TERMINAL_RECOVERY',
    'TERMINAL_REPAIR',
    'TERMINAL_SUPPORT',
    'TRAINING',
    'UNAUTHORIZED_FARE',
    'VEHICLE_BOOKING_INSPECTION',
    'VEHICLE_BOOKING_SERVICE',
    'VEHICLE_BREAKDOWN_MAJOR',
    'VEHICLE_BREAKDOWN_MINOR',
    'VEHICLE_EQUIPMENT',
    'VEHICLE_INCIDENT',
    'VEHICLE_RANK_FEE',
  ];
  ticketCreateEditState: BehaviorSubject<TicketCreateEditState> =
    new BehaviorSubject(<TicketCreateEditState>{
      loading: false,
      issues: [],
      buttonLabel: 'Create',
      title: 'Create Ticket',
    });

  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('driverId').patchValue(value.driverId);

      this.ticketCreateEditState.next({
        ...this.ticketCreateEditState.value,
        instructions: `Create a ticket for ${value.firstName} ${value.lastName}`,
      });
    }
  }
  get driver() {
    return this._driver;
  }

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('travellerId').patchValue(value.travellerId);
      this.ticketCreateEditState.next({
        ...this.ticketCreateEditState.value,
        instructions: `Create a ticket for ${value.firstName} ${value.lastName}`,
      });
    }
  }

  get traveller() {
    return this._traveller;
  }

  _vehicle: VehicleModel;
  @Input() set vehicle(value: VehicleModel) {
    this._vehicle = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.form.get('vehicleId').patchValue(value.vehicleId);
      this.ticketCreateEditState.next({
        ...this.ticketCreateEditState.value,
        instructions: `Create a ticket for ${value.displayName}`,
      });
    }
  }

  get vehicle() {
    return this._vehicle;
  }

  _job: JobModel;
  @Input() set job(value: JobModel) {
    this._job = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('jobId').patchValue(value.jobId);
      if (value.driver) {
        this.form.get('driverId').patchValue(value.driver.driverId);
        this.form.get('linkDriver').patchValue(true);
      }
      if (value.vehicle) {
        this.form.get('vehicleId').patchValue(value.vehicle.vehicleId);
        this.form.get('linkVehicle').patchValue(true);
      }
      if (value.traveller) {
        this.form.get('travellerId').patchValue(value.traveller.travellerId);
        this.form.get('linkTraveller').patchValue(true);
      }
      // if (value.payment) {
      //   this.form
      //     .get('transactionReferenceNumber')
      //     .patchValue(
      //       value.payment.primarySale.paymentTransaction
      //         .transactionReferenceNumber
      //     );
      //   this.form.get('linkPayment').patchValue(true);
      // }

      this.ticketCreateEditState.next({
        ...this.ticketCreateEditState.value,
        instructions: `Create a ticket`,
      });
    }
  }

  get job() {
    return this._job;
  }

  @Output() successful = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  @Input() transaction: PaymentTransactionModel;

  @Input() ticket: TicketModel;
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private ticketApiService: TicketApiService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      driverId: [],
      travellerId: [],
      vehicleId: [],
      jobId: [],
      transactionReferenceNumber: [],
      ticketType: [null, [Validators.required]],
      issueType: [null, [Validators.required]],
      content: [null, [Validators.required]],
      linkDriver: [],
      linkTraveller: [],
      linkVehicle: [],
      linkPayment: [],
      ticketStatus: ['NEW'],
    });
  }

  createTicket() {
    this.ticketCreateEditState.next({
      ...this.ticketCreateEditState.value,
      loading: true,
      issues: [],
    });

    let payload = Object.assign({}, this.form.value);
    delete payload['linkDriver'];
    delete payload['linkVehicle'];
    delete payload['linkTraveller'];
    delete payload['linkPayment'];

    if (!this.form.value.linkDriver && !this.driver) {
      delete payload['driverId'];
    }
    if (!this.form.value.linkTraveller && !this.traveller) {
      delete payload['travellerId'];
    }
    if (!this.form.value.linkVehicle && !this.vehicle) {
      delete payload['vehicleId'];
    }
    if (!this.form.value.linkPayment) {
      delete payload['transactionReferenceNumber'];
    }
    if (!this.form.value.jobId) {
      delete payload['jobId'];
    }

    this.ticketApiService.createTicket(payload).subscribe({
      next: (resp: ApiResponse<TicketModel>) => {
        this.ticketCreateEditState.next({
          ...this.ticketCreateEditState.value,
          loading: false,
        });
        this.successful.emit(resp.data);
      },
      error: (error: IssueModel[]) => {
        this.ticketCreateEditState.next({
          ...this.ticketCreateEditState.value,
          loading: false,
          issues: error,
        });
      },
    });
  }

  // Create new method that will get categories - this will be called on each of the inputs for driver etc.
  // (source) then filter this array by this value to get the categories back, and then once a category
  //  is selected do search for issues
}
