import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { JobApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import { ApiResponse, IssueModel, JobModel } from '@fleet/model';

@Component({
  selector: 'fleet-job-id-search-control',
  templateUrl: './job-id-search-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class JobIdSearchControlComponent {
  searchControl = new UntypedFormControl();
  isValid = false;
  selectedJob: JobModel = null;
  searching: boolean;
  issues: IssueModel[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private jobApiService: JobApiService,
    private router: Router
  ) {}

  searchJob() {
    this.issues = [];
    this.searching = false;
    this.changeDetectorRef.markForCheck();

    this.jobApiService
      .searchJobs({ jobId: this.searchControl.value } as any)
      .subscribe({
        next: (resp: HttpResponse<ApiResponse<JobModel[]>> | any) => {
          this.searching = false;
          if (resp.body.data.length > 0) {
            this.selectedJob = resp.body.data[0];
            this.isValid = true;
            const url = `job/${this.selectedJob.jobId}/`;

            this.router.navigate([url]);
          } else {
            //no drivers found
            this.selectedJob = null;
            this.isValid = false;
            this.searchControl.setErrors({
              NO_RESULTS: 'No job found with the provided ID',
            });
          }
          this.changeDetectorRef.markForCheck();
        },
        error: (issues: IssueModel[]) => {
          this.issues = issues;
          this.searching = false;
          this.selectedJob = null;
          this.isValid = false;
          this.searchControl.setErrors({
            NO_RESULTS: 'No job found with the provided ID',
          });
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  clearSearch() {
    this.searchControl.reset();
    this.changeDetectorRef.markForCheck();
  }
}
