import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobPaymentTransactionReceiptComponent } from './job-payment-transaction-receipt.component';
import { DialogLayoutModule } from '@fleet/ui';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ProgressButtonModule } from '@fleet/shared';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { FuseAlertModule } from '@fleet/fuse';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [JobPaymentTransactionReceiptComponent],
  imports: [
    CommonModule,
    DialogLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    ProgressButtonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FuseAlertModule,
    MatDialogModule,
    AlertsFromIssuesModule,
    MatRadioModule,
  ],
  exports: [JobPaymentTransactionReceiptComponent],
})
export class JobPaymentTransactionReceiptModule {}
