// export interface AppConfiguration {
//   productConfiguration: any;
//   layout: Layout;
//   scheme: Scheme;
//   theme: Theme;
//   mainLayout: FleetLayout;
// }
export type Layout =
  | 'empty'
  // Horizontal
  | 'centered'
  | 'enterprise'
  | 'material'
  | 'modern'
  // Vertical
  | 'classic'
  | 'classy'
  | 'compact'
  | 'dense'
  | 'futuristic'
  | 'thin'
  | 'fleet'
  | 'flex'
  | 'traveller'
  | 'kiosk'
  | 'native-app';

export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };
export type Theme = 'theme-default' | string;
export type Themes = { id: string; name: string }[];
export type FleetLayout = 'flex' | 'fleet' | 'native-app' | 'kiosk';

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface ProductConfig {
  layout: Layout;
  scheme: Scheme;
  screens: Screens;
  theme: Theme;
  themes: Themes;
  mainLayout: FleetLayout;
  brand?: {
    horizontalLogo: string;
    horizontalLogoMono: string;
    favIcon: string;
    primaryColor: string;
    vehicleMarkerSet: {
      vehicleAvailableSvg: string;
      vehicleBusySvg: string;
      vehicleAssignedSvg: string;
    };
  };
}

export const productConfig: ProductConfig = {
  layout: 'fleet',
  scheme: 'light',
  mainLayout: 'fleet',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px',
  },
  theme: 'theme-default',
  themes: [
    {
      id: 'theme-default',
      name: 'Default',
    },
    {
      id: 'theme-brand',
      name: 'Brand',
    },
    {
      id: 'theme-teal',
      name: 'Teal',
    },
    {
      id: 'theme-rose',
      name: 'Rose',
    },
    {
      id: 'theme-purple',
      name: 'Purple',
    },
    {
      id: 'theme-amber',
      name: 'Amber',
    },
  ],
};
