<form [formGroup]="form" (ngSubmit)="changePassword()">
  <fuse-alert
    [appearance]="'soft'"
    [dismissible]="true"
    [dismissed]="true"
    [name]="'successAlert'"
    [type]="'success'"
  >
    Password was successfully reset!
  </fuse-alert>

  <fuse-alert
    *ngFor="let alert of issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
    class="pb-3"
  >
    {{ alert.message }}
  </fuse-alert>

  <div class="flex flex-col">
    <div class="sm:col-span-4">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <mat-label>New password</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:key'"
          matPrefix
        ></mat-icon>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          formControlName="password"
          required
          type="password"
          matInput
        />
        <mat-error *ngIf="form.hasError('required', 'password')">
          Enter new password
        </mat-error>
      </mat-form-field>
    </div>

    <div class="sm:col-span-4">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <mat-label>Confirm new password</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:key'"
          matPrefix
        ></mat-icon>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          formControlName="confirmPassword"
          type="password"
          matInput
        />
      </mat-form-field>
      <div class="mt-1 text-md text-hint">
        Minimum 8 characters. Must include numbers, letters and special
        characters.
      </div>
    </div>
  </div>

  <div class="flex items-center justify-end mt-3">
    <button
      *ngIf="showCancel"
      mat-stroked-button
      type="button"
      (click)="cancel()"
    >
      Cancel
    </button>
    <div class="pl-4">
      <fleet-progress-button
        [disabled]="!form.valid"
        (onClick)="changePassword()"
        [state]="{ loading: loading, buttonLabel: 'Reset' }"
      >
      </fleet-progress-button>
    </div>
  </div>
</form>
<!-- </ng-container>
</fleet-section> -->
