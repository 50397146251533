import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BlocklistApiService, TravellerApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  BlockAttributeModel,
  IssueModel,
  PaymentMethodSearchResultModel,
  TravellerActionType,
  TravellerModel,
} from '@fleet/model';
import { BehaviorSubject } from 'rxjs';
import { TravellerStatusAction } from '../traveller-status/traveller-status-action.pipe';
import { OnscreenNotificationService } from '@fleet/ui';
import { successNotification } from '@fleet/utilities';
import { failureNotification } from '@fleet/utilities';
import { titleCaseClean } from '@fleet/utilities';

interface TravellerActionState {
  loading: boolean;
  error: any;
  issues: IssueModel[];
  action: TravellerStatusAction;
  buttonLabel: string;
  traveller: TravellerModel;
  title: string;
  iconColour: string;
}

@Component({
  selector: 'fleet-traveller-status-change',
  templateUrl: './traveller-status-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TravellerStatusChangeComponent implements OnInit {
  blocklistError = false;
  blocklistErrorAttribute = '';
  attributeControl: UntypedFormControl = new UntypedFormControl();
  travellerStatusActioned = false;

  travellerActionState: BehaviorSubject<TravellerActionState> =
    new BehaviorSubject(<TravellerActionState>{});

  @Output() updateTraveller = new EventEmitter();

  _references: any;
  @Input() set references(value: {
    traveller: TravellerModel;
    action: TravellerStatusAction;
  }) {
    if (value) {
      this.travellerActionState.next({
        ...this.travellerActionState.value,
        traveller: value.traveller,
        action: value.action,
        buttonLabel: value.action.label,
      });

      this.buildForm();
    }
  }

  get references() {
    return this._references;
  }

  form: UntypedFormGroup;

  @Output() cancelled = new EventEmitter();
  @Output() actionCompleted = new EventEmitter();

  constructor(
    private travellerApiService: TravellerApiService,
    private fb: UntypedFormBuilder,
    private blocklistApiService: BlocklistApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private onScreenNotificaitonService: OnscreenNotificationService
  ) {}
  ngOnInit(): void {}

  buildForm() {
    this.form = this.fb.group({
      description: [null, [Validators.required]],
    });
  }

  actionTraveller() {
    this.travellerActionState.next({
      ...this.travellerActionState.value,
      loading: true,
      error: null,
      issues: [],
    });

    let apiCall;

    switch (this.travellerActionState.value.action.action) {
      case TravellerActionType.SUSPEND_TRAVELLER:
        this.form.patchValue({ reasonCode: 'SUSPEND_TRAVELLER' });
        apiCall = this.travellerApiService.suspendTraveller(
          this.travellerActionState.value.traveller.travellerId,
          this.form.value
        );
        break;

      case TravellerActionType.ACTIVATE_TRAVELLER:
        this.form.patchValue({ reasonCode: 'ACTIVATE_TRAVELLER' });

        apiCall = this.travellerApiService.activateTraveller(
          this.travellerActionState.value.traveller.travellerId,
          this.form.value
        );
        break;

      case TravellerActionType.DELETE_TRAVELLER:
        apiCall = this.travellerApiService.deleteTraveller(
          this.travellerActionState.value.traveller.travellerId
        );
        break;

      case TravellerActionType.BAN_TRAVELLER:
        this.form.patchValue({ reasonCode: 'BAN_TRAVELLER' });

        apiCall = this.travellerApiService.banTraveller(
          this.travellerActionState.value.traveller.travellerId,
          this.form.value
        );
        break;

      case TravellerActionType.DISABLE_TRAVELLER:
        this.form.patchValue({ reasonCode: 'DISABLE_TRAVELLER' });

        apiCall = this.travellerApiService.disableTraveller(
          this.travellerActionState.value.traveller.travellerId,
          this.form.value
        );
        break;

      case TravellerActionType.RESURRECT_TRAVELLER:
        this.form.patchValue({ reasonCode: 'RESURRECT_TRAVELLER' });

        apiCall = this.travellerApiService.resurrectTraveller(
          this.travellerActionState.value.traveller.travellerId,
          this.form.value
        );
        break;

      default:
        break;
    }

    apiCall.subscribe(
      (resp: ApiResponse<TravellerModel> | any) => {
        this.travellerActionState.next({
          ...this.travellerActionState.value,
          loading: false,
        });

        //if suspend - we need to block some attributes;
        if (
          this.travellerActionState.value.action.action === 'SUSPEND_TRAVELLER'
        ) {
          this.travellerStatusActioned = true;
          this.updateTraveller.emit();

          this.changeDetectorRef.markForCheck();
        } else {
          this.actionCompleted.emit({
            traveller: resp ? resp.data : this.references.traveller,
            action: this.travellerActionState.value.action,
          });
        }
      },
      (error) => {
        this.travellerActionState.next({
          ...this.travellerActionState.value,
          loading: false,
          error: error,
          issues: error,
        });
      }
    );
  }

  blockAttributes() {
    this.blocklistError = false;
    this.blocklistErrorAttribute = null;
    this.changeDetectorRef.markForCheck();

    if (this.attributeControl.value && this.attributeControl.value.length > 0) {
      const apiCalls: { call: any; name: string; displayValue?: string }[] = [];
      this.attributeControl.value.forEach(
        (attribute: {
          type: string;
          value: string;
          checked: boolean;
          display?: string;
          card?: PaymentMethodSearchResultModel;
        }) => {
          if (attribute.type === 'EMAIL') {
            apiCalls.push({
              call: this.blocklistApiService.createBlocklistAttribute({
                email: attribute.value,
                actionType: 'BLOCK',
              } as any),
              name: 'EMAIL',
              displayValue: attribute.value,
            });
          }
          if (attribute.type === 'PHONE_NUMBER') {
            apiCalls.push({
              call: this.blocklistApiService.createBlocklistAttribute({
                phoneNumber: attribute.value,
                actionType: 'BLOCK',
              } as any),
              name: 'PHONE_NUMBER',
              displayValue: attribute.value,
            });
          }
          if (attribute.type.includes('STORED_CARD')) {
            apiCalls.push({
              call: this.blocklistApiService.createBlocklistAttribute({
                storedCard: {
                  cardId: attribute.value,
                },
                actionType: 'BLOCK',
              } as any),
              name: 'STORED_CARD',
              displayValue: attribute.card.description,
            });
          }
        }
      );

      this.travellerActionState.next({
        ...this.travellerActionState.value,
        loading: true,
      });

      apiCalls.forEach((element) => {
        const blockType = (
          titleCaseClean(element.name) as string
        ).toLowerCase();
        element.call.subscribe({
          next: (resp: ApiResponse<BlockAttributeModel>) => {
            this.travellerActionState.next({
              ...this.travellerActionState.value,
              loading: false,
            });

            this.onScreenNotificaitonService.setNotification({
              ...successNotification,
              title: 'Successfully blocked ' + blockType + '!',
              subTitle: element.displayValue + ' has been successfully blocked',
            });
            if (
              apiCalls[apiCalls.length - 1] === element &&
              !this.travellerActionState.value.loading
            ) {
              this.actionCompleted.emit({
                traveller: this.travellerActionState.value.traveller,
                action: this.travellerActionState.value.action,
              });
            }
          },
          error: (error: any) => {
            this.travellerActionState.next({
              ...this.travellerActionState.value,
              loading: false,
            });

            // this.blocklistError = true;
            // this.blocklistErrorAttribute = element.name;

            //Rather than stopping the whole show on the dialog because one of the X many possible blocked things breaking on the end point,
            // just show notificaiton on individual ones  that failed.

            const notification = {
              ...failureNotification,
              title: 'Failed to block ' + blockType + '!',

              subTitle:
                'Unable to block the ' +
                blockType +
                ' ' +
                element.displayValue +
                ' at this time',
            };

            this.onScreenNotificaitonService.setNotification(notification);

            //action has still 'completed' despite not working
            this.actionCompleted.emit({
              traveller: this.travellerActionState.value.traveller,
              action: this.travellerActionState.value.action,
            });
            this.changeDetectorRef.markForCheck();
          },
        });
      });
    } else {
      this.actionCompleted.emit({
        traveller: this.travellerActionState.value.traveller,
        action: this.travellerActionState.value.action,
      });
    }
  }
}
