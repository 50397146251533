import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketSearchFormComponent } from './ticket-search-form.component';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [TicketSearchFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,

    MatDatepickerModule,
    MatInputModule,
    MatTooltipModule,
  ],
  exports: [TicketSearchFormComponent],
})
export class TicketSearchFormModule {}
