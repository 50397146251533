import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import {
  DriverApiService,
  TravellerApiService,
  VehicleApiService,
} from '@fleet/api';
import { ApiResponse, IssueModel, ReferenceDataItemModel } from '@fleet/model';

import { ReferenceService } from '@fleet/reference';
import { Observable } from 'rxjs';

@Component({
  selector: 'fleet-lifecycle-stage-status-selector',
  templateUrl: './lifecycle-stage-status-selector.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LifecycleStageStatusSelectorComponent),
      multi: true,
    },
  ],
})
export class LifecycleStageStatusSelectorComponent
  implements OnInit, ControlValueAccessor
{
  lifecycles$: Observable<ReferenceDataItemModel[]>;
  @Input() type: 'DRIVER' | 'VEHICLE' | 'TRAVELLER' = 'DRIVER';
  @Input() mode = 'list';

  statuses: ReferenceDataItemModel[] = [];
  lifecycle: ReferenceDataItemModel;
  issues: IssueModel[];
  loading: boolean;
  searchingLifecycle = false;
  searchingStatuses = false;

  lifecycleAndStatuses = new UntypedFormControl();

  lifecycleStageControl = new UntypedFormControl();
  statusControl = new UntypedFormControl();

  @Output() statusesLoaded = new EventEmitter();

  lifecycles: ReferenceDataItemModel[];

  constructor(
    private referenceService: ReferenceService,
    private driverApiService: DriverApiService,
    private vehicleApiService: VehicleApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private travellerApiService: TravellerApiService
  ) {}

  ngOnInit(): void {
    this.referenceService.lifeCycleStages$.subscribe(
      (resp: ReferenceDataItemModel[]) => {
        this.lifecycles = resp;
        if (resp && this.lifecycleStageControl.value) {
          const find = resp.find(
            (s) => s.code === this.lifecycleStageControl.value.code
          );
          if (find) {
            this.lifecycleStageControl.setValue(find, { emitEvent: false });
          }
        }

        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }
    );

    this.lifecycleStageControl.valueChanges.subscribe(
      (lifecycle: ReferenceDataItemModel) => {
        if (this.lifecycles && lifecycle) {
          const lifecyclePatch = this.lifecycles.find(
            (s) => s.code === lifecycle.code
          );

          if (lifecyclePatch) {
            this.lifecycleStageControl.patchValue(lifecyclePatch, {
              emitEvent: false,
            });
          }
        }

        this.changeDetectorRef.markForCheck();

        if (lifecycle) {
          this.searchingStatuses = true;
          this.changeDetectorRef.markForCheck();

          let statusCall;
          if (this.type === 'DRIVER') {
            statusCall = this.driverApiService.statuses({
              lifecycleStage: lifecycle.code,
            });
          }
          if (this.type === 'VEHICLE') {
            statusCall = this.vehicleApiService.statuses({
              lifecycleStage: lifecycle.code,
            });
          }
          if (this.type === 'TRAVELLER') {
            statusCall = this.travellerApiService.statuses({
              lifecycleStage: lifecycle.code,
            });
          }

          statusCall.subscribe({
            next: (resp: ApiResponse<ReferenceDataItemModel[]>) => {
              this.statuses = resp.data;

              if (this.statusControl.value) {
                const statuses = resp.data.filter((obj) =>
                  this.statusControl.value.includes(obj.code)
                );
                this.statusControl.setValue(statuses, {
                  emitEvent: false,
                });
              }

              this.searchingStatuses = false;
              this.changeDetectorRef.markForCheck();
            },
            error: (error: IssueModel[]) => {},
          });
          this.changeDetectorRef.markForCheck();
        } else {
          this.statuses = [];
          this.changeDetectorRef.markForCheck();
        }
      }
    );

    this.statusControl.valueChanges.subscribe((value: any) => {
      let statuses = value;

      this.onChange({
        lifecycle: this.lifecycle,
        statuses: statuses ? statuses : null,
      });
      this.onTouched();

      this.changeDetectorRef.markForCheck();
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(val: any): void {
    //reset patching null
    if (val === null) {
      this.lifecycleStageControl.patchValue(
        this.lifecycle ? this.lifecycle : null
      );
      this.statusControl.reset();
    } else {
      this.lifecycleStageControl.patchValue(val.stage ? val.stage : null);

      const statuses =
        typeof val.statuses === 'string'
          ? val.statuses.split(',')
          : val.statuses;
      this.statusControl.patchValue(statuses, {
        emitEvent: false,
      });
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  searchStatuses(type: string, lifecycleCode: string) {
    let statusCall;
    if (type === 'DRIVER') {
      statusCall = this.driverApiService.statuses({
        lifecycleStage: lifecycleCode,
      });
    }
    if (type === 'VEHICLE') {
      statusCall = this.vehicleApiService.statuses({
        lifecycleStage: lifecycleCode,
      });
    }
    if (type === 'TRAVELLER') {
      statusCall = this.travellerApiService.statuses({
        lifecycleStage: lifecycleCode,
      });
    }

    statusCall.subscribe({
      next: (resp: ApiResponse<ReferenceDataItemModel[]>) => {
        this.statuses = resp.data;
      },
      error: (error: IssueModel[]) => {},
    });
  }
}
