<form
  *ngIf="dateTimeForm"
  [formGroup]="dateTimeForm"
  [class]="controlCustomClass ? controlCustomClass : 'flex flex-col w-full'"
>
  <mat-form-field class="w-full" *ngIf="showNowControl">
    <mat-label [ngClass]="labelClass" *ngIf="dateLabel">{{
      dateLabel
    }}</mat-label>
    <mat-select
      [formControl]="nowControl"
      class="w-full"
      [placeholder]="datePlaceholder"
    >
      <mat-option value="NOW"> Now </mat-option>
      <mat-option value="SCHEDULED"> Scheduled </mat-option>
    </mat-select>
  </mat-form-field>
  <div
    class="flex flex-col sm:flex-row gap-2 items-start w-full"
    *ngIf="
      !showNowControl || (showNowControl && nowControl.value === 'SCHEDULED')
    "
  >
    <mat-form-field class="w-full sm:flex-grow" [subscriptSizing]="'dynamic'">
      <mat-label [ngClass]="labelClass" *ngIf="dateLabel && !showNowControl">{{
        dateLabel
      }}</mat-label>
      <mat-icon class="icon-size-5" *ngIf="datePrefix" matPrefix>{{
        datePrefix
      }}</mat-icon>
      <input
        #datePicker
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="date"
        [placeholder]="datePlaceholder"
        matInput
        formControlName="date"
      />
      <mat-datepicker #date></mat-datepicker>

      <mat-error
        *ngIf="
          dateTimeForm?.get('date')?.errors?.required ||
          dateTimeForm?.get('date')?.errors?.matDatepickerParse
        "
        >A valid date in the format dd/mm/yyyy is required
      </mat-error>
      <mat-error
        *ngIf="
          dateTimeForm?.get('date')?.errors?.dateNotFuture &&
          !dateTimeForm?.get('date')?.errors?.required
        "
        >Please ensure that this is a future date
      </mat-error>
      <mat-error
        *ngIf="
          !dateTimeForm?.get('date')?.errors?.dateNotFuture &&
          dateTimeForm?.errors?.dateNotFuture &&
          !dateTimeForm?.get('date')?.errors?.required
        "
        >Please ensure that this is a future date
      </mat-error>

      <mat-error
        *ngIf="
          !dateTimeForm?.get('date')?.errors?.required &&
          !dateTimeForm?.errors?.dateNotFuture &&
          dateTimeForm?.get('date')?.errors?.dateNotFuture
        "
        >Please ensure that this is a future date
      </mat-error>
      <mat-datepicker-toggle matSuffix [for]="date">
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
      </mat-datepicker-toggle>
    </mat-form-field>

    <mat-form-field
      class="w-full sm:max-w-40"
      [subscriptSizing]="'dynamic'"
      *ngIf="includeTime"
    >
      <mat-icon class="icon-size-5" *ngIf="timePrefix" matPrefix>{{
        timePrefix
      }}</mat-icon>
      <mat-label [ngClass]="labelClass" *ngIf="timeLabel && !showNowControl">{{
        timeLabel
      }}</mat-label>
      <input
        #timePicker
        [placeholder]="timePlaceholder"
        matInput
        formControlName="time"
        type="time"
        (blur)="dateTimeForm.get('time').updateValueAndValidity()"
      />

      <mat-error *ngIf="dateTimeForm?.get('time')?.errors?.required"
        >A valid time is required</mat-error
      >
      <mat-error
        *ngIf="
          !dateTimeForm?.get('time')?.errors?.required &&
          !dateTimeForm?.errors?.timeNotFuture &&
          dateTimeForm?.get('time')?.errors?.invalidTimeFormat
        "
        >Please enter a valid time
      </mat-error>
      <mat-error
        *ngIf="
          dateTimeForm?.get('time')?.errors?.timeNotFuture &&
          !dateTimeForm?.get('time')?.errors?.required &&
          !dateTimeForm?.get('time')?.errors?.invalidTimeFormat
        "
        >Please ensure that this is a future time
      </mat-error>
    </mat-form-field>
  </div>
</form>
