import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobApiService } from '@fleet/api';
import {
  ApiResponse,
  IssueModel,
  JobModel,
  JobSearchResultModel,
} from '@fleet/model';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { deleteNotification } from '@fleet/utilities';
import { BehaviorSubject } from 'rxjs';
import { throwIfEmpty } from 'rxjs/operators';
import { JobUnassignDialogComponent } from '../job-unassign-dialog/job-unassign-dialog.component';
import { JobAction, JobActionType } from './job-action.model';

export interface JobActionExecution {
  job: JobModel;
  action?: JobAction;
  actionType: JobActionType;
  executing: boolean;
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class JobActionService {
  job: JobModel | JobSearchResultModel;
  jobActionExecution: BehaviorSubject<JobActionExecution> = new BehaviorSubject(
    null
  );
  constructor(
    private jobApiService: JobApiService,
    private onScreenNotificationService: OnscreenNotificationService,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  setJob(job: JobModel | JobSearchResultModel) {
    this.job = job;
  }

  get jobActionExecution$() {
    return this.jobActionExecution.asObservable();
  }

  executeAction(action: JobAction, job: JobModel | JobSearchResultModel) {
    this.job;
    switch (action.type) {
      case JobActionType.book:
        // this.bookJob();
        break;
      case JobActionType.update:
        //this.updateJob();
        break;
      case JobActionType.cancel:
        // this.cancelJob(job);
        break;
      case JobActionType.clear:
        //this.clearJob();
        break;

      case JobActionType.edit:
        break;

      case JobActionType.delete:
        this.deleteJob(job);
        break;

      case JobActionType.unassign:
        this.unAssignJob(job);
        break;

      case JobActionType.clone:
        this.clone(job.jobId);
        break;

      case JobActionType.manualDispatch:
        this.manualDispatch(job);
        break;
    }
  }

  // cancelJob(job: JobModel | JobSearchResultModel) {
  //   this.jobApiService.cancelJob(job.jobId).subscribe({
  //     next: (resp: ApiResponse<JobModel>) => {
  //       this.onScreenNotificationService.setNotification({
  //         title: 'Job Cancelled!',
  //         icon: { name: 'delete_sweep', color: 'red' },
  //         autoDismiss: 5000,
  //       } as OnScreenNotification);
  //       this.jobActionExecution.next({
  //         job: resp.data,
  //         actionType: JobActionType.cancel,
  //         success: true,
  //         executing: false,
  //       });
  //       //clear state
  //       this.jobActionExecution.next(null);
  //     },
  //     error: (issues: IssueModel[]) => {
  //       this.onScreenNotificationService.setNotification({
  //         title: 'Failed to cancel job!',

  //         icon: { name: 'warning', color: 'red' },
  //       } as OnScreenNotification);
  //       this.jobActionExecution.next({
  //         job: job as any,
  //         executing: false,
  //         success: false,
  //         actionType: JobActionType.cancel,
  //       });
  //       //clear state
  //       this.jobActionExecution.next(null);
  //     },
  //   });
  // }

  deleteJob(job: JobModel | JobSearchResultModel) {
    this.jobApiService.deleteJob(job.jobId).subscribe({
      next: (resp: any) => {
        this.onScreenNotificationService.setNotification({
          ...deleteNotification,
          title: 'Job Deleted!',
        } as OnScreenNotification);
        this.jobActionExecution.next({
          job: { jobId: job.jobId } as JobModel,
          actionType: JobActionType.delete,
          success: true,
          executing: false,
        });
        //clear state
        this.jobActionExecution.next(null);
      },
    });
  }

  unAssignJob(job: any) {
    const dialog = this.matDialog.open(JobUnassignDialogComponent);
    dialog.componentInstance.job = job;
    dialog.componentInstance.unassigned.subscribe({
      next: (job: JobModel) => {
        dialog.close();
        this.jobActionExecution.next({
          job: job,
          actionType: JobActionType.unassign,
          success: true,
          executing: false,
        });
        this.jobActionExecution.next(null);
      },
    });
  }

  manualDispatch(job: JobModel | JobSearchResultModel) {
    this.router.navigate(['/job/' + job.jobId + '/dispatch']);
  }

  clone(jobId: string) {
    this.router.navigate(['/job/clone/' + jobId]);
    // this.router.navigate(
    //   ['job/new?jobId=' + execution.job.jobId + '&clone=true'],
    //   { relativeTo: this._activatedRoute.parent }
    // );
  }
}
