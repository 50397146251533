import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ConditionConfigurationModel } from '@fleet/model';
import { Subject } from 'rxjs';

import { NetworkConditionsService } from '../network-conditions.service';

@Component({
  selector: 'fleet-network-conditions-selector',
  templateUrl: './network-conditions-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NetworkConditionsSelectorComponent),
      multi: true,
    },
  ],
})
export class NetworkConditionsSelectorComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  driverConditions: ConditionConfigurationModel[] = [];
  vehicleConditions: ConditionConfigurationModel[] = [];
  travellerConditions: ConditionConfigurationModel[] = [];
  jobConditions: ConditionConfigurationModel[] = [];
  organisationConditions: ConditionConfigurationModel[] = [];

  allConditions: ConditionConfigurationModel[] = [];

  currentEntityConditionIds: string[] = [];

  conditionsFormArray: UntypedFormArray = new UntypedFormArray([]);

  _unsubscribeAll: Subject<any> = new Subject();

  @Input() filter: string;
  formControl = new UntypedFormControl();
  @Input() mode: string;
  @Input() valueMode: string;
  @Input() columns = '2';

  @Output() addCondition = new EventEmitter();
  @Output() removeCondition = new EventEmitter();
  @ViewChild('conditionSelectionList') conditionSelectionList: MatSelectionList;

  selectedConditions: ConditionConfigurationModel[] = [];
  constructor(
    private networkConditionsService: NetworkConditionsService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.networkConditionsService.driverConditions.subscribe(
      (c: ConditionConfigurationModel[]) => {
        if (this.driverConditions.length === 0 && c) {
          this.driverConditions = c;
          this.syncConditionsAndFormArray(c);
        }

        this.changeDetectorRef.markForCheck();
      }
    );

    this.networkConditionsService.vehicleConditions.subscribe(
      (c: ConditionConfigurationModel[]) => {
        if (this.vehicleConditions.length === 0 && c) {
          this.vehicleConditions = c;
          this.syncConditionsAndFormArray(c);
        }

        this.changeDetectorRef.markForCheck();
      }
    );

    this.networkConditionsService.travellerConditions.subscribe(
      (c: ConditionConfigurationModel[]) => {
        if (this.travellerConditions.length === 0 && c) {
          this.travellerConditions = c;
          this.syncConditionsAndFormArray(c);
        }

        this.changeDetectorRef.markForCheck();
      }
    );

    this.networkConditionsService.jobConditions.subscribe(
      (c: ConditionConfigurationModel[]) => {
        if (this.jobConditions.length === 0 && c) {
          this.jobConditions = c;
          this.syncConditionsAndFormArray(c);
        }

        this.changeDetectorRef.markForCheck();
      }
    );

    this.networkConditionsService.organisationConditions.subscribe(
      (c: ConditionConfigurationModel[]) => {
        if (this.organisationConditions.length === 0 && c) {
          this.organisationConditions = c;
          this.syncConditionsAndFormArray(c);
        }

        this.changeDetectorRef.markForCheck();
      }
    );

    this.formControl.valueChanges.subscribe(
      (value: ConditionConfigurationModel[]) => {
        //this should onchnage array of ids
        //used in job, driver ,vehicle search and manula dispatch

        //you can check that write value is working in search context by running
        //WhenCreatingJobWithConditions and then opening manual dispath at job/dispatch/{{jobId}}
        //the job.jobDetails.conditions needs to be mapped to array of ids and form control value in manual dispatch needs to be patch
        this.onTouched();
        this.onChange(value);
      }
    );
  }

  onChange: any = (condition: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    console.log('network condition selector disble state:' + isDisabled);
    //This will override CERTIFICATE on individual conrols toggles from being read only. Do not disable/enable form control array only form control.
    //As slide toggle is looking at the formcontrol for its disabled value (and the individual control too), we do not have to reset the conditions array
    //only the main control
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  //this should be array of Ids
  writeValue(conditionIds: string[]) {
    if (conditionIds) {
      //currentEntityConditions is array of ids

      if (typeof conditionIds === 'string') {
        //single id
        this.currentEntityConditionIds = [conditionIds];
      } else {
        this.currentEntityConditionIds = conditionIds;
      }

      this.formControl.setValue(this.currentEntityConditionIds, {
        emitEvent: false,
      });

      if (this.filter === 'DRIVER') {
        this.conditionsFormArray.setValue(
          this.driverConditions.map(
            (condition: ConditionConfigurationModel) => {
              if (conditionIds.includes(condition.networkConditionId)) {
                return true;
              } else {
                return false;
              }
            }
          ),
          { emitEvent: false }
        );
      } else if (this.filter === 'VEHICLE') {
        this.conditionsFormArray.setValue(
          this.vehicleConditions.map(
            (condition: ConditionConfigurationModel) => {
              if (conditionIds.includes(condition.networkConditionId)) {
                return true;
              } else {
                return false;
              }
            }
          ),
          { emitEvent: false }
        );

        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      } else if (this.filter === 'TRAVELLER') {
        this.conditionsFormArray.setValue(
          this.travellerConditions.map(
            (condition: ConditionConfigurationModel) => {
              if (conditionIds.includes(condition.networkConditionId)) {
                return true;
              } else {
                return false;
              }
            }
          ),
          { emitEvent: false }
        );
      } else if (this.filter === 'BOOKING') {
        this.conditionsFormArray.setValue(
          this.jobConditions.map((condition: ConditionConfigurationModel) => {
            if (conditionIds.includes(condition.networkConditionId)) {
              return true;
            } else {
              return false;
            }
          }),
          { emitEvent: false }
        );
      } else if (this.filter === 'ORGANISATION') {
        this.conditionsFormArray.setValue(
          this.organisationConditions.map(
            (condition: ConditionConfigurationModel) => {
              if (conditionIds.includes(condition.networkConditionId)) {
                return true;
              } else {
                return false;
              }
            }
          ),
          { emitEvent: false }
        );
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      } else {
        this.conditionsFormArray.setValue(
          this.allConditions.map((condition: ConditionConfigurationModel) => {
            if (conditionIds.includes(condition.networkConditionId)) {
              return true;
            } else {
              return false;
            }
          }),
          { emitEvent: false }
        );
      }
    }
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  syncConditionsAndFormArray(conditions: ConditionConfigurationModel[]) {
    if (this.filter === 'DRIVER') {
      this.conditionsFormArray = new UntypedFormArray(
        this.driverConditions.map(
          (condition) =>
            new UntypedFormControl({
              value: null,
              disabled: condition.assignmentMode === 'CERTIFICATE',
            })
        )
      );
    } else if (this.filter === 'VEHICLE') {
      this.conditionsFormArray = new UntypedFormArray(
        this.vehicleConditions.map((condition) => new UntypedFormControl())
      );
    } else if (this.filter === 'TRAVELLER') {
      this.conditionsFormArray = new UntypedFormArray(
        this.travellerConditions.map((condition) => new UntypedFormControl())
      );
    } else if (this.filter === 'BOOKING') {
      this.conditionsFormArray = new UntypedFormArray(
        this.jobConditions.map((condition) => new UntypedFormControl())
      );
    } else if (this.filter === 'ORGANISATION') {
      this.conditionsFormArray = new UntypedFormArray(
        this.organisationConditions.map(
          (condition) =>
            new UntypedFormControl({
              value: null,
              disabled: condition.assignmentMode === 'CERTIFICATE',
            })
        )
      );
    }
  }

  toggleCondition(
    condition: ConditionConfigurationModel,
    event: MatSlideToggleChange
  ) {
    if (!event.checked) {
      this.removeCondition.emit(condition.networkConditionId);
    } else {
      this.addCondition.emit(condition.networkConditionId);
    }
  }
}
