<div
  class="absolute inset-0 flex flex-col min-w-0"
  cdkTrapFocus
  (keydown.esc)="cancel.emit()"
>
  <div class="pt-6">
    <div class="w-full max-w-3xl px-4">
      <div class="flex flex-row justify-between">
        <div>
          <div class="text-xl">{{ title }}</div>
        </div>
        <button mat-icon-button (click)="cancel.emit()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="flex flex-col gap-3 w-full mt-8">
        <ng-content select="[slot=filters]"></ng-content>
        <ng-content select="[slot=error]"></ng-content>
      </div>
    </div>
  </div>
  <div class="flex flex-auto flex-col overflow-y-auto" cdkScrollable>
    <ng-content slot="list"></ng-content>
  </div>
</div>
