<ng-container *ngIf="mode === 'select'">
  <mat-form-field class="w-full">
    <mat-label>Conditions</mat-label>
    <mat-select
      [disabled]="formControl.disabled"
      [formControl]="formControl"
      multiple
    >
      <mat-option
        *ngFor="
          let condition of filter === 'VEHICLE'
            ? vehicleConditions
            : filter === 'DRIVER'
            ? driverConditions
            : filter === 'TRAVELLER'
            ? travellerConditions
            : filter === 'BOOKING'
            ? jobConditions
            : filter === 'ORGANISATION'
            ? organisationConditions
            : null
        "
        [value]="condition"
      >
        {{ condition.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="mode === 'list'">
  <mat-selection-list
    [formControl]="formControl"
    #conditionSelectionList
    class="grid condensed-leading-checkbox-selection-list"
    [ngClass]="'grid-cols-' + columns"
  >
    <mat-list-option
      [disabled]="formControl.disabled"
      checkboxPosition="before"
      *ngFor="
        let condition of filter === 'VEHICLE'
          ? vehicleConditions
          : filter === 'DRIVER'
          ? driverConditions
          : filter === 'TRAVELLER'
          ? travellerConditions
          : filter === 'BOOKING'
          ? jobConditions
          : filter === 'ORGANISATION'
          ? organisationConditions
          : null
      "
      [value]="condition.networkConditionId"
    >
      <!-- <span class="break-normal"> -->
      {{ condition.name }}
      <!-- </span> -->
    </mat-list-option>
  </mat-selection-list>
</ng-container>

<ng-container *ngIf="mode === 'switch'">
  <div class="grid grid-cols-1 gap-6 w-full">
    <div
      *ngFor="
        let condition of filter === 'VEHICLE'
          ? vehicleConditions
          : filter === 'DRIVER'
          ? driverConditions
          : filter === 'TRAVELLER'
          ? travellerConditions
          : filter === 'BOOKING'
          ? jobConditions
          : filter === 'ORGANISATION'
          ? organisationConditions
          : [];
        let i = index
      "
      class="flex items-center justify-between"
    >
      <div class="flex flex-col px-1 cursor-pointer">
        <div class="leading-6 font-medium">{{ condition.name }}</div>
        <div class="text-md text-secondary">{{ condition.description }}</div>
      </div>
      <mat-slide-toggle
        [disabled]="
          formControl.disabled || conditionsFormArray.controls[i].disabled
        "
        class="ml-4"
        [color]="'primary'"
        (change)="toggleCondition(condition, $event)"
        [formControl]="$any(conditionsFormArray.controls[i])"
        #femaleToggle
      >
      </mat-slide-toggle>
    </div>
  </div>
</ng-container>
