import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LifecycleTransitionActionPipe } from './lifecycle-transition-action.pipe';

@NgModule({
  declarations: [LifecycleTransitionActionPipe],
  imports: [CommonModule],
  exports: [LifecycleTransitionActionPipe],
})
export class LifecycleTransitionActionPipeModule {}
