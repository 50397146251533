import { IssueModel } from '@fleet/model';
import * as braintree from 'braintree-web';

export const transformBraintreeErrorToIssueModel = (
  err: braintree.BraintreeError
): IssueModel[] => {
  const issues: IssueModel[] = [];
  if (err) {
    issues.push({
      message: err.message,
    } as IssueModel);
  }
  return issues;
};
