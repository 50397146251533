import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  ContractModel,
  CreditModel,
  InvoiceModel,
  IssueModel,
  NoteModel,
  OperatorModel,
  OperatorUserModel,
  PaymentMethodModel,
  PaymentModel,
  PurchaseModel,
  StateChangeModel,
  VehicleModel,
} from '@fleet/model';

import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OperatorApiService {
  host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.operator;
  }

  searchOperators(params: any) {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getOperator(
    operatorId: string
  ): Observable<ApiResponse<OperatorModel> | any> {
    return this.http
      .get(`${this.host}/${operatorId}`)
      .pipe(catchError(handleApiError));
  }

  deletePaymentMethod(paymentMethodId: string, operatorId: string) {
    return this.http
      .delete(`${this.host}/${operatorId}/paymentMethod/${paymentMethodId}`)
      .pipe(catchError(handleApiError));
  }

  updatePaymentMethod(payload: PaymentMethodModel, operatorId: string) {
    return this.http
      .put(
        `${this.host}/${operatorId}/paymentMethod/${payload.paymentMethodId}`,
        payload
      )
      .pipe(catchError(handleApiError));
  }

  addPaymentMethod(payload: any, operatorId: string) {
    return this.http
      .post(`${this.host}/${operatorId}/paymentMethod`, payload)
      .pipe(catchError(handleApiError));
  }

  getPaymentMethod(paymentId: string, operatorId: string) {
    return this.http.get(
      `${this.host}/${operatorId}/paymentMethod/${paymentId}`
    );
  }

  createNoncePaymentMethod(operatorId: any, payment: any) {
    return this.http
      .post(`${this.host}/${operatorId}/paymentMethod`, payment)
      .pipe(catchError(handleApiError));
  }

  updateOperator(
    payload: OperatorModel,
    operatorId: string
  ): Observable<ApiResponse<OperatorModel> | any> {
    return this.http
      .put(`${this.host}/${operatorId}`, payload)
      .pipe(catchError(handleApiError));
  }

  searchPaymentMethods(
    params: any,
    operatorId: any
  ): Observable<HttpResponse<ApiResponse<PaymentMethodModel[]>> | any> {
    return this.http
      .get(`${this.host}/${operatorId}/paymentMethod`, {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  addVehicleToOperator(
    operatorId: string,
    vehicleModel: VehicleModel
  ): Observable<ApiResponse<VehicleModel> | any> {
    return this.http
      .post(`${this.host}/${operatorId}/vehicle`, vehicleModel)
      .pipe(catchError(handleApiError));
  }

  createOperator(
    operator: OperatorModel
  ): Observable<ApiResponse<OperatorModel>> | any {
    return this.http
      .post(`${this.host}/`, operator)
      .pipe(catchError(handleApiError));
  }

  searchContracts(
    params: any,
    operatorId: string
  ): Observable<HttpResponse<ApiResponse<ContractModel[]>> | any> {
    return this.http
      .get(`${this.host}/${operatorId}/contract`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  createContract(params: any, operatorId: string) {
    return this.http
      .post(`${this.host}/${operatorId}/contract`, params)
      .pipe(catchError(handleApiError));
  }

  updateContract(params: any, contractId: string, operatorId: string) {
    delete params.billingContacts; // this shouldnt exist on update?
    return this.http
      .put(`${this.host}/${operatorId}/contract/${contractId}`, params)
      .pipe(catchError(handleApiError));
  }

  getContract(
    contractId: string,
    operatorId: string
  ): Observable<ApiResponse<ContractModel> | any> {
    return this.http
      .get(`${this.host}/${operatorId}/contract/${contractId}`)
      .pipe(catchError(handleApiError));
  }

  removeContract(contractId: string, operatorId: string) {
    return this.http
      .delete(`${this.host}/${operatorId}/contract/${contractId}`)
      .pipe(catchError(handleApiError));
  }

  searchContacts(
    params: { [x: string]: string | number | boolean },
    contractId: string,
    operatorId: string
  ) {
    return this.http
      .get(`${this.host}/${operatorId}/contract/${contractId}/contact`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  createContact(params: any, contractId: any, operatorId: any) {
    return this.http
      .post(`${this.host}/${operatorId}/contract/${contractId}/contact`, params)
      .pipe(catchError(handleApiError));
  }

  removeContact(contactId: any, contractId: any, operatorId: any) {
    return this.http
      .delete(
        `${this.host}/${operatorId}/contract/${contractId}/contact/${contactId}`
      )
      .pipe(catchError(handleApiError));
  }

  updateContact(
    params: any,
    contractId: string,
    contactId: string,
    operatorId: string
  ) {
    return this.http
      .put(
        `${this.host}/${operatorId}/contract/${contractId}/contact/${contactId}`,
        params
      )
      .pipe(catchError(handleApiError));
  }

  acceptTermsandConditions(
    operatorId: string,
    networkId: string
  ): Observable<ApiResponse<OperatorModel> | IssueModel[] | any> {
    return this.http.post(
      `${this.host}/${operatorId}/acceptTerms/${networkId}`,
      {}
    );
  }

  getLatestTermsandConditions(
    operatorId: string,
    networkId: string,
    params: any
  ): Observable<any> {
    return this.http
      .get(`${this.host}/${operatorId}/terms/${networkId}`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  activateOperator(operatorId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${operatorId}/activate`, stateChange)
      .pipe(catchError(handleApiError));
  }

  suspendOperator(operatorId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${operatorId}/suspend`, stateChange)
      .pipe(catchError(handleApiError));
  }
  searchLifecycleTransitions(
    operatorId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/${operatorId}/lifecycleTransition`)
      .pipe(catchError(handleApiError));
  }

  searchInvoices(params: any): Observable<any> {
    return this.http
      .get(`${this.host}/invoice`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  payInvoice(
    operatorId: string,
    invoiceId: any,
    invoicePaymentModel: any
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${operatorId}/invoice/${invoiceId}/pay`,
        invoicePaymentModel
      )
      .pipe(catchError(handleApiError));
  }

  getInvoice(
    operatorId: string,
    invoiceId: string
  ): Observable<ApiResponse<InvoiceModel> | any> {
    return this.http
      .get(`${this.host}/${operatorId}/invoice/${invoiceId}`)
      .pipe(catchError(handleApiError));
  }

  voidCreditInvoice(
    operatorId: string,
    invoiceReference: string,
    invoice: InvoiceModel
  ) {
    return this.http
      .delete(`${this.host}/${operatorId}/creditInvoice/${invoiceReference}`, {
        body: invoice,
      })
      .pipe(catchError(handleApiError));
  }

  voidChargeInvoice(
    operatorId: string,
    invoiceReference: string,
    invoice: InvoiceModel
  ) {
    return this.http
      .delete(`${this.host}/${operatorId}/chargeInvoice/${invoiceReference}`, {
        body: invoice,
      })
      .pipe(catchError(handleApiError));
  }

  linkContractToPaymentType(
    operatorId: string,
    contractId: string,
    paymentMethod: any
  ): Observable<ApiResponse<ContractModel> | any> {
    return this.http
      .post(
        `${this.host}/${operatorId}/contract/${contractId}/paymentMethod`,
        paymentMethod
      )
      .pipe(catchError(handleApiError));
  }

  deleteContract(contractId: string, operatorId: string) {
    return this.http
      .delete(`${this.host}/${operatorId}/contract/${contractId}`)
      .pipe(catchError(handleApiError));
  }

  activateContract(contractId: string, operatorId: string) {
    return this.http
      .post(`${this.host}/${operatorId}/contract/${contractId}/activate`, {})
      .pipe(catchError(handleApiError));
  }

  adjustRankFee(operatorId: any, adjustmentModel: any) {
    return this.http
      .post(`${this.host}/${operatorId}/adjust`, adjustmentModel)
      .pipe(catchError(handleApiError));
  }

  searchPendingBillingAdjustments(
    operatorId: string,
    contractId: string
  ): Observable<HttpResponse<ApiResponse<ContractModel[]>> | any> {
    return this.http
      .get(
        `${this.host}/${operatorId}/contract/${contractId}/pendingBillingItems`,
        {
          observe: 'response',
        }
      )
      .pipe(catchError(handleApiError));
  }

  createFinalInvoice(
    operatorId: string,
    finalInvoiceRequest: any
  ): Observable<any> {
    return this.http.post(
      `${this.host}/${operatorId}/createFinalInvoice`,
      finalInvoiceRequest
    );
  }

  cancelFinalInvoice(
    operatorId: string,
    finalInvoiceRequest: any
  ): Observable<any> {
    return this.http.post(
      `${this.host}/${operatorId}/cancelFinalInvoice`,
      finalInvoiceRequest
    );
  }

  searchUsers(params: any, operatorId: string): Observable<any> {
    return this.http
      .get(`${this.host}/${operatorId}/user`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getUser(
    userId: string,
    operatorId: string
  ): Observable<OperatorUserModel | any> {
    return this.http
      .get(`${this.host}/${operatorId}/user/${userId}`)
      .pipe(catchError(handleApiError));
  }

  createUser(user: OperatorUserModel, operatorId: string) {
    return this.http
      .post(`${this.host}/${operatorId}/user`, user)
      .pipe(catchError(handleApiError));
  }

  updateUser(
    user: OperatorUserModel,
    operatorId: string,
    operatorUserId: string
  ) {
    return this.http
      .put(`${this.host}/${operatorId}/user/${operatorUserId}`, user)
      .pipe(catchError(handleApiError));
  }

  deleteUser(operatorUserId: string, operatorId: string) {
    return this.http
      .delete(`${this.host}/${operatorId}/user/${operatorUserId}`)
      .pipe(catchError(handleApiError));
  }

  resendInvitation(
    operatorUserId: string,
    operatorId: string
  ): Observable<ApiResponse<OperatorUserModel> | any> {
    return this.http
      .post(`${this.host}/${operatorId}/user/${operatorUserId}/invite`, {})
      .pipe(catchError(handleApiError));
  }

  getUserRoles(operatorId: string, operatorUserId: string) {
    return this.http
      .get(`${this.host}/${operatorId}/user/${operatorUserId}/role`)
      .pipe(catchError(handleApiError));
  }

  emailChargeInvoice(operatorId: string, invoiceId: string, emails: string) {
    const url = emails
      ? `${this.host}/${operatorId}/chargeInvoice/${invoiceId}/email?emails=${emails}`
      : `${this.host}/${operatorId}/chargeInvoice/${invoiceId}/email`;

    return this.http.post(url, {}).pipe(catchError(handleApiError));
  }

  emailCreditInvoice(operatorId: string, invoiceId: string, emails: string) {
    const url = emails
      ? `${this.host}/${operatorId}/creditInvoice/${invoiceId}/email?emails=${emails}`
      : `${this.host}/${operatorId}/creditInvoice/${invoiceId}/email`;

    return this.http.post(url, {}).pipe(catchError(handleApiError));
  }

  issueCredit(
    operatorId: string,
    creditModel: CreditModel
  ): Observable<ApiResponse<CreditModel> | any> {
    return this.http
      .post(`${this.host}/${operatorId}/credit`, creditModel)
      .pipe(catchError(handleApiError));
  }

  processPurchase(
    operatorId: string,
    purchase: PurchaseModel
  ): Observable<ApiResponse<PurchaseModel> | any> {
    return this.http
      .post(`${this.host}/${operatorId}/purchase`, purchase)
      .pipe(catchError(handleApiError));
  }

  payChargeInvoice(
    operatorId: string,
    invoiceReference: string,
    payload: any
  ): Observable<ApiResponse<PaymentModel> | any> {
    return this.http
      .post(
        `${this.host}/${operatorId}/chargeInvoice/${invoiceReference}/pay`,
        payload
      )
      .pipe(catchError(handleApiError));
  }

  disburseCreditInvoice(
    operatorId: string,
    invoiceReference: string,
    payload: any
  ): Observable<ApiResponse<PaymentModel> | any> {
    return this.http
      .post(
        `${this.host}/${operatorId}/creditInvoice/${invoiceReference}/disburse`,
        payload
      )

      .pipe(catchError(handleApiError));
  }
}
