import { DateTime } from 'luxon';

export function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0: {
      break;
    }
    case 2: {
      output += '==';
      break;
    }
    case 3: {
      output += '=';
      break;
    }
    default: {
      throw new Error('Illegal base64url string!');
    }
  }
  return decodeURIComponent(encodeURI(window.atob(output)));
}

export function decodeToken(token: string): any {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }
  return JSON.parse(decoded);
}

export function getUserRoles(decodedToken: any) {
  const userRoles = [];
  userRoles.push('BUSINESS_ADMIN');

  return decodedToken.roles;
}

export function getOrganisationId(decodedToken: any) {
  if (decodedToken.organisationId) {
    return decodedToken.organisationId;
  }
  return null;
}

export function getOrganisationUserId(decodedToken: any) {
  if (decodedToken.organisationUserId) {
    return decodedToken.organisationUserId;
  }
  return null;
}

export function isTokenValid(token: any) {
  if (!token) {
    return false;
  }
  const decodedToken = decodeToken(token);

  const now = DateTime.local();
  const epochTime = DateTime.fromMillis(decodedToken.exp);
  const diffInMinutes = now.diff(epochTime, 'minutes').minutes;
  console.log('Diff in minutes: ' + diffInMinutes);
  if (diffInMinutes > 0) {
    return true;
  }
  return false;
}
