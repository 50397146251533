<fleet-dialog-layout
  [title]="'Change Phone Number'"
  [iconColour]="'primary'"
  [icon]="'warning'"
  [showCancel]="!hasPhonenumberChanged"
>
  <div slot="main">
    <ng-container *ngIf="hasPhonenumberChanged; else notChanged">
      <div class="text-lg">
        {{
          'Your phone number has changed to
      ' + phonenumberControl.value
        }}
      </div>
    </ng-container>

    <ng-template #notChanged>
      <ng-container *ngIf="verification; else enterPhoneNumber">
        <ng-container slot="main">
          <div>
            We've sms'd a code {{ phonenumberControl.value }}
            Please check your phone and enter the code below
          </div>
          <div class="flex w-full m-auto p-8 justify-center">
            <mat-form-field class="w-full w-40">
              <input
                matInput
                placeholder="Enter code"
                [formControl]="codeControl"
              />
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #enterPhoneNumber>
        <mat-form-field class="w-full">
          <input
            matInput
            placeholder="New Phone Number"
            [formControl]="phonenumberControl"
          />
        </mat-form-field>
      </ng-template>
    </ng-template>
  </div>
  <fuse-alert
    slot="error"
    *ngFor="let alert of issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <ng-container slot="action">
    <button
      *ngIf="!verification && !hasPhonenumberChanged"
      [disabled]="!phonenumberControl.valid"
      mat-flat-button
      class="bg-primary-600 text-white disabled:bg-gray-200"
      (click)="begin()"
    >
      NEXT
    </button>
    <fleet-progress-button
      [state]="{ buttonLabel: 'Verify', loading: loading }"
      [loading]="loading"
      (onClick)="verify()"
      *ngIf="verification && !hasPhonenumberChanged"
    ></fleet-progress-button>
    <button
      class="bg-primary-600 text-white disabled:bg-gray-200"
      *ngIf="hasPhonenumberChanged"
      mat-flat-button
      [matDialogClose]
    >
      OK
    </button>
  </ng-container>
</fleet-dialog-layout>
