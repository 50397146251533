import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityCallSearchResultsComponent } from './entity-call-search-results.component';
import { MatButtonModule } from '@angular/material/button';
import {
  DurationPipeModule,
  MobileFormatModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ResponsiveElementModule } from '@fleet/layout';

import { MatTooltipModule } from '@angular/material/tooltip';
import { CallerTypePipeModule } from '../../caller-type-pipe/caller-type-pipe.module';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';

@NgModule({
  declarations: [EntityCallSearchResultsComponent],
  imports: [
    CommonModule,
    ResponsiveElementModule,
    MatTableModule,
    MatIconModule,

    TitleCaseAndCleanPipeModule,
    MatButtonModule,
    InternationalizationDatePipeModule,
    DurationPipeModule,
    MobileFormatModule,
    MatTooltipModule,
    CallerTypePipeModule,
  ],
  exports: [EntityCallSearchResultsComponent],
})
export class EntityCallSearchResultsModule {}
