import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TicketApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import {
  ApiResponse,
  BaseSearchState,
  initialBaseSearchState,
  TicketModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { pageDataFromSearchResultHeaders } from '@fleet/utilities';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TicketSearchService {
  ticketSearchState: BehaviorSubject<BaseSearchState<TicketModel>> =
    new BehaviorSubject(initialBaseSearchState);

  get ticketSearchState$() {
    return this.ticketSearchState.asObservable();
  }
  private apiCallSubscription: Subscription;

  constructor(
    private ticketApiService: TicketApiService,
    private authService: AuthService,
    private networkGroupService: NetworkGroupService
  ) {}

  searchTickets(params: any) {
    params['networkId'] = this.authService.networkId;
    if (this.networkGroupService.networkGroupId) {
      params['networkGroupId'] = this.networkGroupService.networkGroupId;
    }

    this.ticketSearchState.next({
      ...this.ticketSearchState.value,
      searching: true,
      issues: [],
    });

    if (this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
    }

    this.apiCallSubscription = this.ticketApiService
      .searchTickets(params)
      .subscribe(
        (resp: HttpResponse<ApiResponse<TicketModel[]>> | any) => {
          let tickets = [];

          if (params['offset'] === 0) {
            tickets = resp.body.data;
          } else {
            tickets = [...this.ticketSearchState.value.data, ...resp.body.data];
          }

          this.ticketSearchState.next({
            ...this.ticketSearchState.value,
            searching: false,
            data: tickets,
            filteredData: tickets,
            pageData: pageDataFromSearchResultHeaders(resp.headers, params),
          });
        },
        (error) => {
          this.ticketSearchState.next({
            ...this.ticketSearchState.value,
            searching: false,
            issues: error,
          });
        }
      );
  }

  resetTicketSearchState() {
    this.ticketSearchState.next(initialBaseSearchState);
  }

  getNextPage() {
    if (
      this.ticketSearchState.value.pageData.currentPage !=
      this.ticketSearchState.value.pageData.totalPages
    ) {
      const newOffset = String(
        this.ticketSearchState.value.pageData.currentPage *
          Number(this.ticketSearchState.value.pageData.params.limit)
      );
      const newParams = {
        ...this.ticketSearchState.value.pageData.params,
        offset: newOffset,
      };

      this.searchTickets(newParams);
    }
  }

  removeParamAndSearch(param: string) {
    const params = Object.assign(
      {},
      this.ticketSearchState.value.pageData.params
    );

    delete params[param];
    this.searchTickets(params);
  }
}
