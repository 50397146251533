import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetChipComponent } from './fleet-chip.component';
// import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';

@NgModule({
  declarations: [FleetChipComponent],
  imports: [CommonModule],
  exports: [FleetChipComponent],
})
export class FleetChipModule {}
