import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TravellerSearchListAutocompleteComponent } from './traveller-search-list-autocomplete.component';

import { MatListModule } from '@angular/material/list';

import { A11yModule } from '@angular/cdk/a11y';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { SelectionListInDrawerModule } from '@fleet/layout';
import { MobileFormatModule } from '@fleet/pipes';
import { ProgressButtonModule } from '@fleet/shared';

@NgModule({
  declarations: [TravellerSearchListAutocompleteComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,

    MatListModule,

    CdkScrollableModule,
    A11yModule,
    SelectionListInDrawerModule,
    FormsModule,
    ReactiveFormsModule,

    MatAutocompleteModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MobileFormatModule,
    MatProgressSpinnerModule,
    ProgressButtonModule,
  ],
  exports: [TravellerSearchListAutocompleteComponent],
})
export class TravellerSearchListAutocompleteModule {}
