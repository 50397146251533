import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { FuseMediaWatcherService } from '@fleet/fuse';

import { IvrTransferNoteService } from '@fleet/ivr';
import {
  FleetLayout,
  Layout,
  ProductConfig,
  ProductConfigurationService,
  Scheme,
  Theme,
} from '@fleet/product-configuration';

@Component({
  selector: 'fleet-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit, OnDestroy {
  config: ProductConfig;
  layout: Layout;
  overideLayout: Layout;
  scheme: 'dark' | 'light';
  theme: string;
  themes: [string, any][] = [];
  onscreenNotification: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private _document: any,
    private _renderer2: Renderer2,
    private _router: Router,
    private productConfigService: ProductConfigurationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    //below is included to monitor for callId queryParam
    private ivrTransferNoteService: IvrTransferNoteService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the themes

    // Set the theme and scheme based on the configuration
    combineLatest([
      this.productConfigService.config$,
      this._fuseMediaWatcherService.onMediaQueryChange$([
        '(prefers-color-scheme: dark)',
        '(prefers-color-scheme: light)',
      ]),
    ])
      .pipe(
        takeUntil(this._unsubscribeAll),
        map(([config, mql]) => {
          const options = {
            scheme: config.scheme,
            theme: config.theme.name,
          };

          // If the scheme is set to 'auto'...
          if (config.scheme === 'auto') {
            // Decide the scheme using the media query
            options.scheme = (mql as any).breakpoints[
              '(prefers-color-scheme: dark)'
            ]
              ? 'dark'
              : 'light';
          }

          return options;
        })
      )
      .subscribe((options) => {
        // Store the options
        this.scheme = options.scheme;
        this.theme = options.theme;

        // Update the scheme and theme
        this._updateScheme();
        this._updateTheme();
      });

    // Subscribe to config changes
    this.productConfigService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: ProductConfig) => {
        // Store the config
        this.config = config;

        // Update the layout
        this._updateLayout();
      });

    // Subscribe to NavigationEnd event
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((event: any) => {
        // Update the layout

        // post to iframe parent latest url whilst removing jwt token
        const queryStringIndex = event.url.indexOf('?');
        if (queryStringIndex !== -1) {
          const baseUrl = event.url.substring(0, queryStringIndex);
          const queryParams = new URLSearchParams(
            event.url.substring(queryStringIndex)
          );

          // Store whether jwt is the only query param or not
          const isJwtOnlyQueryParam =
            queryParams.toString() === 'jwt' && queryParams.get('jwt') !== null;

          queryParams.delete('jwt');
          let hasOtherParams = false;
          queryParams.forEach((param) => {
            hasOtherParams = true;
          });

          const modifiedUrl = !hasOtherParams
            ? baseUrl
            : baseUrl + '?' + queryParams.toString();

          window.parent.postMessage({ redirectUrl: modifiedUrl }, '*');
        } else {
          // No query parameters found, just post the original URL
          window.parent.postMessage({ redirectUrl: event.url }, '*');
        }

        this._updateLayout();
      });

    // Set the app version

    //subscribe to onscreen notification
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the layout on the config
   *
   * @param layout
   */
  setLayout(layout: string): void {
    // Clear the 'layout' query param to allow layout changes
    this._router
      .navigate([], {
        queryParams: {
          layout: null,
        },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        // Set the config
        this.productConfigService.config = { layout };
      });
  }

  /**
   * Set the scheme on the config
   *
   * @param scheme
   */
  setScheme(scheme: Scheme): void {
    this.productConfigService.config = { scheme };
  }

  /**
   * Set the theme on the config
   *
   * @param theme
   */
  setTheme(theme: Theme): void {
    this.productConfigService.config = { theme };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the selected layout
   */
  private _updateLayout(): void {
    // Get the current activated route
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // 1. Set the layout from the config

    this.layout = this.config.layout;

    // 2. Get the query parameter from the current route and
    // set the layout and save the layout to the config

    // 3. Iterate through the paths and change the layout as we find
    // a config for it.
    //
    // The reason we do this is that there might be empty grouping
    // paths or componentless routes along the path. Because of that,
    // we cannot just assume that the layout configuration will be
    // in the last path's config or in the first path's config.
    //
    // So, we get all the paths that matched starting from root all
    // the way to the current activated route, walk through them one
    // by one and change the layout as we find the layout config. This
    // way, layout configuration can live anywhere within the path and
    // we won't miss it.
    //
    // Also, this will allow overriding the layout in any time so we
    // can have different layouts for different routes.
    const paths = route.pathFromRoot;
    paths.forEach((path) => {
      // Check if there is a 'layout' data
      if (
        path.routeConfig &&
        path.routeConfig.data &&
        path.routeConfig.data.layout
      ) {
        // Set the layout
        if (path.routeConfig.data.layout !== 'empty') {
          if (
            this.config.mainLayout !== 'flex' &&
            this.config.mainLayout !== 'native-app'
          ) {
            this.layout = path.routeConfig.data.layout; // this.config.mainLayout;
          }
        } else {
          this.layout = 'empty';
        }
      }
    });

    if (route.snapshot) {
      const layoutFromQueryParam = route.snapshot.queryParamMap.get(
        'layout'
      ) as FleetLayout;
      if (layoutFromQueryParam) {
        this.layout = layoutFromQueryParam;
        if (this.config) {
          this.config.mainLayout = layoutFromQueryParam;
        }
      }
    }
  }

  /**
   * Update the selected scheme
   *
   * @private
   */
  private _updateScheme(): void {
    // Remove class names for all schemes
    this._document.body.classList.remove('light', 'dark');

    // Add class name for the currently selected scheme
    this._document.body.classList.add(this.scheme);
  }

  /**
   * Update the selected theme
   *
   * @private
   */
  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(
          className,
          className.split('-')[1]
        );
      }
    });
    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-default`);
    // this._document.body.classList.add(this.theme);
  }
}
