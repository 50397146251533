<div [ngSwitch]="viewMode">
  <div *ngSwitchCase="'COMMA_SEPERATED'" class="flex flex-wrap">
    <span *ngFor="let condition of conditionsDisplay$ | async; let last = last"
      >{{ condition.name }}{{ last ? '' : ',&nbsp;' }}</span
    >
  </div>

  <div *ngSwitchDefault class="flex flex-wrap items-center justify-end gap-1">
    <mat-icon
      [ngClass]="iconClass"
      *ngFor="let condition of conditionsDisplay$ | async"
      [matTooltip]="condition?.name"
      >{{ condition?.iconName }}</mat-icon
    >

    <div
      *ngIf="numberOfTravellers && numberOfTravellers > 4"
      class="flex flex-row items-center gap-1"
      [matTooltip]="'Number of travellers'"
    >
      <mat-icon [ngClass]="iconClass" class="items-start">person_add</mat-icon>
      <div class="text-secondary justify-end">
        {{ numberOfTravellers }}
      </div>
    </div>
  </div>
</div>
