import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ResponsiveElementModule } from '@fleet/layout';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { TicketCreateEditModule } from '../ticket-create-edit/ticket-create-edit.module';
import { TicketLinkModule } from '../ticket-link/ticket-link.module';
import { TicketActionButtonComponent } from './ticket-action-button.component';

@NgModule({
  declarations: [TicketActionButtonComponent],
  imports: [
    CommonModule,
    TitleCaseAndCleanPipeModule,
    MatButtonModule,
    MatIconModule,
    ResponsiveElementModule,

    MatMenuModule,
    MatDialogModule,
    TicketCreateEditModule,
    TicketLinkModule,
    HasFunctionPipeModule,
  ],
  exports: [TicketActionButtonComponent],
})
export class TicketActionButtonModule {}
