import { Pipe, PipeTransform } from '@angular/core';
import { ServiceClassModel, ServiceClassWithLines } from '@fleet/model';

@Pipe({
  name: 'serviceClassesFromServiceClassAndLines',
})
export class ServiceClassesFromServiceClassAndLinesPipe
  implements PipeTransform
{
  transform(classAndLines: ServiceClassWithLines[]): ServiceClassModel[] {
    if (classAndLines) {
      return classAndLines.map((cl) => cl.class);
    } else {
      return [];
    }
  }
}
