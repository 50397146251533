<fleet-selection-list-in-drawer title="Find By Map" (cancel)="cancel.emit()">
  <fuse-alert
    slot="error"
    *ngFor="let alert of reverseGeocodeState.issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <ng-container slot="list">
    <ng-container *ngIf="selectedPlace; else results">
      <form id="location-form" [formGroup]="form" class="w-full p-4">
        <div class="flex w-full flex-row gap-2">
          <mat-form-field class="fuse-mat-dense flex-auto">
            <mat-label class="text-sm font-light">Unit Number</mat-label>
            <input
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              matInput
              placeholder="Unit Number"
              formControlName="unitNumber"
            />
          </mat-form-field>
          <mat-form-field class="fuse-mat-dense flex-auto">
            <mat-label class="text-sm font-light">Street Number</mat-label>
            <input
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              matInput
              placeholder="Street Number"
              formControlName="streetNumber"
            />
          </mat-form-field>
        </div>

        <mat-form-field class="fuse-mat-dense w-full">
          <mat-label class="text-sm font-light">Street Address</mat-label>
          <input
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
            matInput
            placeholder="Street Name"
            formControlName="streetName"
          />
        </mat-form-field>

        <mat-form-field class="fuse-mat-dense w-full">
          <mat-label class="text-sm font-light">Suburb</mat-label>
          <input
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
            matInput
            placeholder="Suburb"
            formControlName="locality"
          />
        </mat-form-field>

        <div class="flex flex-row gap-2 w-full">
          <mat-form-field class="fuse-mat-dense flex-auto">
            <mat-label>State</mat-label>
            <input
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              matInput
              placeholder="State"
              formControlName="regionCode"
            />
          </mat-form-field>
          <mat-form-field class="fuse-mat-dense flex-auto">
            <mat-label class="text-sm font-light">Postcode</mat-label>
            <input
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              matInput
              placeholder="Post Code"
              formControlName="postalCode"
            />
          </mat-form-field>
        </div>

        <div class="flex justify-end">
          <button
            mat-flat-button
            type="button"
            class="bg-primary-600 text-white disabled:bg-gray-200"
            [disabled]="form.invalid"
            (click)="confirm()"
          >
            Confirm
          </button>
        </div>
      </form>
    </ng-container>
    <ng-template #results>
      <div class="text-lg p-4" *ngIf="!reverseGeocodeState?.latitude">
        Please place the marker at the correct location by dragging and zooming
        the map. Once the marker is in position click the 'CONFIRM LOCATION'
        button.
      </div>
      <div
        *ngIf="reverseGeocodeState.loading"
        class="flex w-full items-center justify-center"
      >
        <mat-spinner matSuffix [diameter]="24"></mat-spinner>
      </div>

      <ng-container *ngIf="places?.length > 1">
        <div class="text-lg p-4">Multiple Results please select one:</div>
        <div class="border-b-2 my-4"></div>
        <mat-selection-list class="selection-hide-checkboxes" #placesList>
          <mat-list-option
            *ngFor="let place of places"
            (click)="selectPlace(place)"
            (keydown.enter)="selectPlace(place)"
          >
            <span>{{ place.displayLine1 + ', ' + place.displayLine2 }}</span>
          </mat-list-option>
        </mat-selection-list>
      </ng-container>
    </ng-template>
  </ng-container>
</fleet-selection-list-in-drawer>

<ng-template #addressForm> </ng-template>

<ng-template #placeTemplate> </ng-template>
