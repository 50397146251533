import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-badge',
  templateUrl: './badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BadgeComponent {
  @Output() removeBadge = new EventEmitter();
  _variationConfig: {
    color: string;
    size: string;
    variation: string;
  };
  @Input() set variationConfig(value: {
    color: string;
    size: string;
    variation: string;
    dark?: boolean;
  }) {
    this._variationConfig = value;
    if (value) {
      this.setVariation(value.variation, value.color, value.size, value.dark);
    }
  }

  get variationConfig() {
    return this._variationConfig;
  }

  magic: string;
  @Input() label: string;

  mode: 'dot' | 'button' | 'default' = 'default';
  svgClass: string;
  variationClass = '';

  withDot: boolean;
  withButton: boolean;
  buttonClass: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  returnSize(size: string): string {
    switch (size) {
      case 'small':
        return 'px-2 py-1';
      default:
        return 'px-2 py-1';
    }
  }

  textColourAndBackground(color: string, dark: boolean) {
    switch (color) {
      case 'green':
        return dark ? 'bg-green-700 text-white' : 'bg-green-100 text-green-700';
      case 'red':
        return dark ? 'bg-red-700 text-white' : 'bg-red-100 text-red-700';
      case 'yellow':
        return dark
          ? 'bg-yellow-700 text-white'
          : 'bg-yellow-100 text-yellow-700';
      case 'gray':
        return dark ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-700';
      case 'blue':
        return dark ? 'bg-blue-700 text-white' : 'bg-blue-100 text-blue-700';
      case 'blue-500':
        return dark ? 'bg-blue-500 text-white' : 'bg-blue-50 text-blue-500';

      case 'indigo':
        return dark
          ? 'bg-indigo-700 text-white'
          : 'bg-indigo-100 text-indigo-700';
      case 'purple':
        return dark
          ? 'bg-purple-700 text-white'
          : 'bg-purple-100 text-purple-700';
      case 'pink':
        return dark ? 'bg-pink-700 text-white' : 'bg-pink-100 text-pink-700';
      case 'orange':
        return dark
          ? 'bg-orange-700 text-white'
          : 'bg-orange-100 text-orange-700';
      case 'orange-500':
        return dark
          ? 'bg-orange-500 text-white'
          : 'bg-orange-50 text-orange-500';
      case 'amber':
        return dark ? 'bg-amber-700 text-white' : 'bg-amber-100 text-amber-700';
      case 'amber-500':
        return dark ? 'bg-amber-500 text-white' : 'bg-amber-50 text-amber-500';
      case 'emerald':
        return dark
          ? 'bg-emerald-700 text-white'
          : 'bg-emerald-100 text-emerald-700';
      case 'emerald-500':
        return dark
          ? 'bg-emerald-500 text-white'
          : 'bg-emerald-50 text-emerald-500';
      case 'lime-500':
        return dark ? 'bg-lime-500 text-white' : 'bg-lime-50 text-lime-500';

      case 'green-500':
        return dark ? 'bg-green-500 text-white' : 'bg-green-50 text-green-500';
      case 'red-600':
        return dark ? 'bg-red-600 text-white' : 'bg-red-50 text-red-600';
      default:
        return dark ? 'bg-green-700 text-white' : 'bg-green-100 text-green-700';
    }
  }

  textColour(color: string) {
    switch (color) {
      case 'green':
        return 'text-green-700';
      case 'red':
        return 'text-red-700';
      case 'yellow':
        return 'text-yellow-700';
      case 'gray':
        return 'text-gray-700';
      case 'blue':
        return 'text-blue-700';
      case 'indigo':
        return 'text-indigo-700';
      case 'purple':
        return 'text-purple-700';
      case 'pink':
        return 'text-pink-700';
      default:
        return 'text-green-700';
    }
  }

  strokeColour(color: string) {
    switch (color) {
      case 'green':
        return 'stroke-green-600/50 group-hover:stroke-green-600/75';
      case 'yellow':
        return 'stroke-yellow-600/50 group-hover:stroke-yellow-600/75';
      case 'gray':
        return 'stroke-gray-600/50 group-hover:stroke-gray-600/75';
      case 'blue':
        return 'stroke-blue-600/50 group-hover:stroke-blue-600/75';
      case 'indigo':
        return 'stroke-indigo-600/50 group-hover:stroke-indigo-600/75';
      case 'purple':
        return 'stroke-purple-600/50 group-hover:stroke-purple-600/75';
      case 'pink':
        return 'stroke-pink-600/50 group-hover:stroke-pink-600/75';

      case 'red':
        return 'stroke-red-600/50 group-hover:stroke-red-600/75';
      case 'emerald-500':
      case 'green-500':
      case 'red-600':
      case 'lime-500':
      case 'blue-500':
      case 'orange-500':
        return 'stroke-white ';
      default:
        return 'stroke-white';
    }
  }

  buttonClasses(color: string) {
    switch (color) {
      case 'green':
        return 'hover:bg-green-800/20';
      case 'yellow':
        return 'hover:bg-yellow-800/20';
      case 'gray':
        return 'hover:bg-gray-800/20';
      case 'blue':
        return 'hover:bg-blue-800/20';
      case 'indigo':
        return 'hover:bg-indigo-800/20';
      case 'purple':
        return 'hover:bg-purple-800/20';
      case 'pink':
        return 'hover:bg-pink-800/20';
      case 'red':
        return 'hover:bg-red-800/20';
      case 'emerald-500':
        return 'hover:bg-emerald-800/20 text-white';
      default:
        return 'hover:bg-green-800/20';
    }
  }

  ringColour(color: string) {
    switch (color) {
      case 'green':
        return 'ring-green-500/10';

      case 'yellow':
        return 'ring-yellow-500/10';
      case 'gray':
        return 'ring-gray-500/10';
      case 'blue':
        return 'ring-blue-500/10';
      case 'indigo':
        return 'ring-indigo-500/10';
      case 'purple':
        return 'ring-purple-500/10';
      case 'pink':
        return 'ring-pink-500/10';

      case 'red':
        return 'ring-red-500/10';

      default:
        return 'ring-green-500/10';
    }
  }

  fillColour(color: string) {
    switch (color) {
      case 'green':
        return 'fill-green-500';
      case 'yellow':
        return 'fill-yellow-500';

      case 'gray':
        return 'fill-gray-500';

      case 'blue':
        return 'fill-blue-500';

      case 'indigo':
        return 'fill-indigo-500';

      case 'purple':
        return 'fill-purple-500';

      case 'pink':
        return 'fill-pink-500';

      case 'red':
        return 'fill-red-500';

      default:
        return 'fill-green-500';
    }
  }

  setVariation(variation: string, color: string, size: string, dark?: boolean) {
    switch (variation) {
      case 'flat-pill':
        this.variationClass =
          'rounded-full ' +
          this.textColourAndBackground(color, dark) +
          ' ' +
          this.returnSize(size);
        break;

      case 'flat-pill-with-remove':
        this.variationClass =
          'rounded-full ' +
          this.textColourAndBackground(color, dark) +
          ' ' +
          this.returnSize(size);

        this.svgClass = this.strokeColour(color);
        this.buttonClass = this.buttonClasses(color);
        this.withButton = true;
        break;
      case 'with-border':
        this.variationClass =
          'rounded-md  ' +
          this.ringColour(color) +
          ' ' +
          this.textColourAndBackground(color, dark) +
          ' ' +
          this.ringColour(color) +
          ' ' +
          this.returnSize(size);
        break;
      case 'flat-dot':
        this.variationClass =
          'rounded-md gap-x-1.5 ' +
          this.textColourAndBackground(color, dark) +
          ' ' +
          this.returnSize(size);

        this.svgClass = this.fillColour(color);
        this.withDot = true;
        break;
      case 'border-and-dot':
        this.variationClass =
          'rounded-md gap-x-1.5 ring-gray-200 ' +
          +this.textColour(color) +
          ' ' +
          this.returnSize(size);

        this.svgClass = this.fillColour(color);

        this.withDot = true;
        break;
      case 'border-and-remove':
        this.variationClass =
          'gap-x-0.5 rounded-md ' +
          this.textColourAndBackground(color, dark) +
          ' ' +
          this.ringColour(color) +
          ' ' +
          this.returnSize(size);

        this.svgClass = this.strokeColour(color);

        this.buttonClass = this.buttonClasses(color);
        this.withButton = true;
        break;
      case 'border':
        this.variationClass =
          'gap-x-0.5 rounded-md ' +
          this.textColourAndBackground(color, dark) +
          ' ' +
          this.ringColour(color) +
          ' ' +
          this.returnSize(size);

        this.svgClass = this.strokeColour(color);

        break;
      default:
        this.variationClass =
          'rounded-full ' +
          this.textColourAndBackground(color, dark) +
          ' ' +
          this.returnSize(size);

        break;
    }

    this.magic = this.variationClass;
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}
