import { Injectable } from '@angular/core';

import { ApiResponse, IssueModel, OrganisationModel } from '@fleet/model';

import { OrganisationApiService } from '@fleet/api';

import { EntityHeaderService } from '@fleet/layout';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AuthService } from '@fleet/auth';
import { NetworkGroupService } from '@fleet/network-group';
@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  organisation: BehaviorSubject<OrganisationModel> = new BehaviorSubject(null);

  issues: Subject<IssueModel[]> = new Subject();

  constructor(
    private organisationApiService: OrganisationApiService,

    private entityHeaderService: EntityHeaderService
  ) {}

  setOrganisation(organisation: OrganisationModel) {
    this.organisation.next(organisation);
    this.entityHeaderService.setOrganisationId(
      organisation ? organisation.organisationId : null
    );
  }

  get organisation$() {
    return this.organisation.asObservable();
  }

  get organisationId() {
    return this.organisation.value
      ? this.organisation.value.organisationId
      : null;
  }

  get currentOrganisation() {
    return this.organisation.value;
  }

  getOrganisation(organisationId: string) {
    this.organisationApiService.getOrganisation(organisationId).subscribe({
      next: (resp: ApiResponse<OrganisationModel> | any) => {
        this.organisation.next(resp.data);
      },
      error: (error) => this.issues.next(error),
    });
  }
}
