import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionListInDrawerComponent } from './selection-list-in-drawer.component';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
  declarations: [SelectionListInDrawerComponent],
  imports: [
    CommonModule,
    CdkScrollableModule,
    A11yModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [SelectionListInDrawerComponent],
})
export class SelectionListInDrawerModule {}
