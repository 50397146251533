<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [appearance]="'compact'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.compact"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center justify-center h-20 mt-3 mb-4">
      <img class="w-10" src="assets/images/logo/logo.svg" alt="Logo image" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="
      relative
      flex flex-0
      items-center
      w-full
      h-16
      px-4
      md:px-6
      z-49
      shadow
      dark:shadow-none dark:border-b
      bg-card
      dark:bg-transparent
      print:hidden
    "
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-2">
      <languages></languages>
      <fuse-fullscreen></fuse-fullscreen>
      <search [appearance]="'bar'"></search>
      <shortcuts></shortcuts>
      <messages></messages>
      <notifications></notifications>
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="
      relative
      flex flex-0
      items-center
      justify-start
      w-full
      h-14
      px-4
      md:px-6
      z-49
      border-t
      bg-card
      dark:bg-transparent
      print:hidden
    "
  >
    <span class="font-medium text-secondary"
      >Fleet &copy; {{ currentYear }}</span
    >
  </div>
</div>
