import { Pipe, PipeTransform } from '@angular/core';

import { DateTime } from 'luxon';
import { LocaleService } from '@fleet/locale';
import { internationalizedDate } from '@fleet/utilities';

@Pipe({
  name: 'internationalizationDate',
})
export class InternationalizationDatePipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(
    value: DateTime | string,
    format: string,
    relative?: boolean,
    type?: string
  ) {
    return internationalizedDate(
      value,
      format,
      relative,
      type,
      this.localeService.getLocale()
    );
  }
}
