import {
  AddressModel,
  JobDetailModel,
  LocationModel,
  LocationSearchResultModel,
  PlaceModel,
} from '@fleet/model';

export const centerOfAustralia = {
  latitude: '-25.2744',
  longitude: '133.7751',
};

export function locationModelFromLocationSearchResultModel(
  locationSearch: LocationSearchResultModel
) {
  return {
    locationId: locationSearch.locationId,
    organisationLocationId: locationSearch.organisationLocationId,
    organisationGroupLocationId: locationSearch.organisationGroupLocationId,
    latitude: locationSearch.latitude,
    longitude: locationSearch.longitude,
    displayLine1: locationSearch.address,
    displayLine2: '',
    name: locationSearch.name,
    type: locationSearch.locationType,
  } as LocationModel;
}

export function placeModelToLocationModel(place: PlaceModel) {
  if (place) {
    const structuredAddress = place.structuredAddress
      ? {
          placeName: place.structuredAddress.placeName || null,
          unitNumber: place.structuredAddress.unitNumber || null,
          streetNumber: place.structuredAddress.streetNumber || null,
          streetName: place.structuredAddress.streetName || null,
          locality: place.structuredAddress.locality || null,
          regionCode: place.structuredAddress.regionCode || null,
          postalCode: place.structuredAddress.postalCode || null,
          countryCode: place.structuredAddress.countryCode || null,
        }
      : null;

    const location = {
      latitude: place.latitude,
      longitude: place.longitude,
      structuredAddress: structuredAddress,
      displayLine1: place.displayLine1,
      displayLine2: place.displayLine2,
    } as LocationModel;

    if (place.networkGroupLocationId) {
      location.networkGroupLocationId = place.networkGroupLocationId;
    }
    if (place.organisationLocationId) {
      location.organisationLocationId = place.organisationLocationId;
    }
    if (place.organisationGroupLocationId) {
      location.organisationGroupLocationId = place.organisationGroupLocationId;
    }
    if (place.organisationStaffLocationId) {
      location.organisationStaffLocationId = place.organisationStaffLocationId;
    }
    if (place.networkLocationId) {
      location.networkLocationId = place.networkLocationId;
    }

    return location;
  } else {
    return null;
  }
}
export function locationModelToPlaceModel(location: LocationModel) {
  return {
    latitude: location.latitude,
    longitude: location.longitude,
    structuredAddress: {
      placeName: location.structuredAddress?.placeName || null,
      unitNumber: location.structuredAddress?.unitNumber || null,
      streetNumber: location.structuredAddress?.streetNumber || null,
      streetName: location.structuredAddress?.streetName || null,
      locality: location.structuredAddress?.locality || null,
      regionCode: location.structuredAddress?.regionCode || null,
      postalCode: location.structuredAddress?.postalCode || null,
      countryCode: location.structuredAddress?.countryCode || null,
    },
    displayLine1: location.displayLine1 || null,
    displayLine2: location.displayLine2 || null,
  } as PlaceModel;
}

export function latLngStringFromLocation(location: LocationModel) {
  return location.latitude + ',' + location.longitude;
}

export function placeModelArrayFromJobDetail(jobDetail: JobDetailModel) {
  let waypoints = [jobDetail.startLocation];
  if (jobDetail.waypoints) {
    waypoints = [...waypoints, ...jobDetail.waypoints];
  }
  waypoints = [...waypoints, jobDetail.endLocation];
  return waypoints;
  // return waypoints.map((location: LocationModel) =>
  //   location ? locationModelToPlaceModel(location) : null
  // );
}

export function locationsArrayFromJobDetail(jobDetail: JobDetailModel) {
  let waypoints = [jobDetail.startLocation];
  if (jobDetail.waypoints) {
    waypoints = [...waypoints, ...jobDetail.waypoints];
  }
  waypoints = [...waypoints, jobDetail.endLocation];
  return waypoints;
}

export function jobLocationsFromLocationArray(locations: LocationModel[]) {
  let jobLocations: any;
  if (locations.length === 2) {
    jobLocations = {
      startLocation: locations[0],
      endLocation: locations[1],
      waypoints: [],
    };
  } else {
    jobLocations = {
      startLocation: locations[0],
      endLocation: locations[locations.length - 1],
      waypoints: locations.slice(1, length - 2).map((l) => {
        return { ...l, type: 'VIA' };
      }),
    };
  }
  return jobLocations;
}

export function jobLocationsFromPlaceModelArray(places: PlaceModel[]) {
  const startLocation = placeModelToLocationModel(places[0]);
  let jobLocations: any;
  if (places.length === 2) {
    jobLocations = {
      startLocation: startLocation,
      endLocation: placeModelToLocationModel(places[1]),
      waypoints: [],
    };
  } else {
    jobLocations = {
      startLocation: startLocation,
      endLocation: placeModelToLocationModel(places[places.length - 1]),
      waypoints: places.slice(1, length - 2).map((p) => {
        return { ...placeModelToLocationModel(p), type: 'VIA' };
      }),
    };
  }
  return jobLocations;
}

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export function waypointLetterFromAlphabet(index: number): string {
  if (index || index === 0) {
    return alphabet[index].toUpperCase();
  }
  return null;
}

export function displayLinesFromAddress(
  address: AddressModel,
  onlyLocalityForDisplayLine2?: boolean
) {
  const displayLine1 = [
    address.unitNumber,
    address.streetNumber,
    address.streetName,
  ]
    .filter(Boolean)
    .join(' ');

  const displayLine2 = onlyLocalityForDisplayLine2
    ? address.locality
    : [address.locality, address.postalCode, address.regionCode]
        .filter(Boolean)
        .join(' ');
  // const displayLine2 = onlyLocalityForDisplayLine2
  //   ? address.locality
  //   : address.locality + ' ' + address.postalCode + ' ' + address.regionCode;
  return { displayLine1: displayLine1, displayLine2: displayLine2 };
}
