<mat-form-field class="w-full">
  <mat-label [ngClass]="inSearch ? 'text-sm font-light' : ''">Action </mat-label>

  <mat-select [required]="required" [formControl]="blockActionControl" class="w-full" placeholder="Select action..." [formControl]="blockActionControl">
    <mat-option *ngFor="let action of blockActions$ | async" [value]="action.code">
      {{action.displayValue}}
    </mat-option>

  </mat-select>
</mat-form-field>
