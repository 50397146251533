import { Pipe, PipeTransform } from '@angular/core';
import { RuleModelTypes, PolicyDisplayArg } from '@fleet/model';

@Pipe({
  name: 'policyDisplay',
})
export class PolicyDisplayPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (args) {
      case PolicyDisplayArg.RuleModelTypeClass:
        switch (value) {
          case RuleModelTypes.TimeOfDay:
            // return 'in_icon-light_clock';
            return 'fa fa-draw-circle';
          case RuleModelTypes.TransactionLimit:
            return 'in_icon-light_money';
          case RuleModelTypes.TripReason:
            return 'in_icon-light_documentAlt';
          case RuleModelTypes.Boundary:
            return 'in_icon-light_';
          case RuleModelTypes.Airport:
            return 'in_icon-light_plane';
          case RuleModelTypes.Suburb:
            return 'in_icon-light_';
          case RuleModelTypes.Radius:
            return 'in_icon-light_';
        }
        break;
    }
    return null;
  }
}
