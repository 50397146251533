/* eslint-disable no-useless-escape */
//CUSTOM- combination of the two phone regex + allowing ( ) as area code.
export const AUS_PHONE = /^\(?(?:\+?61|0)(?:[ -]?[2-478])\)?(?:[ -]?[0-9]){8}$/;
export const AUS_PHONE_PRECISE = /^\(?(?:\+?61|0)(?:(?:2\)?[ -]?(?:3[ -]?[38]|[46-9][ -]?[0-9]|5[ -]?[0-35-9])|3\)?(?:4[ -]?[0-57-9]|[57-9][ -]?[0-9]|6[ -]?[1-67])|7\)?[ -]?(?:[2-4][ -]?[0-9]|5[ -]?[2-7]|7[ -]?6)|8\)?[ -]?(?:5[ -]?[1-4]|6[ -]?[0-8]|[7-9][ -]?[0-9]))(?:[ -]?[0-9]){6}|4\)?[ -]?(?:(?:[01][ -]?[0-9]|2[ -]?[0-57-9]|3[ -]?[1-9]|4[ -]?[7-9]|5[ -]?[018])[ -]?[0-9]|3[ -]?0[ -]?[0-5])(?:[ -]?[0-9]){5})$/;
export const AUS_PHONE_UNPRECISE = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;

//CUSTOM - combination of the below - allows also for space after +61
export const AUS_MOBILE = /^(?:\(?(?:\+?61|0|)?\ ?4\)?(?:[ -]?[0-9]){7}[0-9])$/;

export const AUS_MOBILE_UNPRECISE = /^(?:\(?(?:\+?61|0)4\)?(?:[ -]?[0-9]){7}[0-9])$/;
export const AUS_MOBILE_PRECISE = /^(?:\+?61|0)4 ?(?:(?:[01] ?[0-9]|2 ?[0-57-9]|3 ?[1-9]|4 ?[7-9]|5 ?[018]) ?[0-9]|3 ?0 ?[0-5])(?: ?[0-9]){5}$/;

// ^\(?(?:\+?61|0)(?:[ -]?[2-478])(?:[ -]?[0-9]){8}$
