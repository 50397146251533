/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DriverModel,
  DriverStatus,
  FunctionPermissionModel,
  LifeCycleStage,
} from '@fleet/model';

@Injectable({
  providedIn: 'root',
})
export class UserInitialRouteService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  routeUser(type: string, functions: FunctionPermissionModel[], user: any) {
    //based on roles route user
    const queryParams = this.activatedRoute.snapshot.queryParams;

    if (queryParams && queryParams.redirectUrl) {
      const url = new URL(queryParams.redirectUrl, 'http://dummy.com');
      const path = url.pathname;
      const searchParams = new URLSearchParams(url.search);

      let params: any = {};
      searchParams.forEach((value, key) => {
        if (key !== 'jwt') {
          params[key] = value;
        }
      });

      this.router.navigate([path], { queryParams: params });
    } else {
      //we have just signed up
      switch (type) {
        case 'DRIVER':
          if (
            (user.lifecycleStage === LifeCycleStage.Opportunity &&
              user.status === DriverStatus.Incomplete) ||
            (user.lifecycleStage === LifeCycleStage.Customer &&
              user.status == DriverStatus.Incomplete)
          ) {
            this.router.navigate(['driver/registration']);
          } else {
            this.router.navigate(['/driver/home']);
          }
          break;

        case 'NETWORK':
        case 'ORGANISATION':
        case 'OPERATOR':
          this.router.navigate(['']);
          break;

        case 'TRAVELLER':
          if (!user.firstName) {
            this.router.navigate(['/traveller/details-required']);
          } else {
            this.router.navigate(['/traveller/job/new']);
          }
      }
    }
  }
}
