import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { FuseMediaWatcherService } from '@fleet/fuse';
import { BaseSearchState } from '@fleet/model';
import { FleetNavigationService } from '@fleet/navigation';

import { Subject } from 'rxjs';
import {
  debounceTime,
  map,
  pairwise,
  startWith,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'fleet-related-entity-search-layout',
  templateUrl: './related-entity-search-layout.component.html',
  styleUrls: ['./related-entity-search-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RelatedEntitySearchLayoutComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Output() backdropClosed = new EventEmitter();
  @Input() searchData: BaseSearchState<any>;
  @Input() resultEntityType: string;
  @Input() requiresPadding = false;
  @Input() searchExpanded = false;

  detailDrawerOpen = false;
  @Input() title: string;
  @Input() flexAutoMainSlot: any;
  @Input() showEntityHeader = true;

  @Input() drawerWidth: string = null;

  @Input() showFilterButton = true;

  @Input() customClass: string = null;

  drawMode: string = null;

  @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;

  _mainDrawerContent: MatDrawerContent;
  @ViewChild('matDrawerContent')
  set matDrawerContent(value: MatDrawerContent) {
    this._mainDrawerContent = value;
    if (value) {
      this.registerContent(value, this.nextDrawerPage);
    }
  }
  get mainDrawerContent() {
    return this._mainDrawerContent;
  }

  @Output() nextPage = new EventEmitter();
  @Output() nextDrawerPage = new EventEmitter();

  @Output() clearSearch = new EventEmitter();
  // @Output() search = new EventEmitter();

  protected _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() entityName: string;
  currentMediaAlias: string[];

  _mainContent: MatDrawerContent;
  @ViewChild('mainContent')
  set mainContent(content: MatDrawerContent) {
    this._mainContent = content;
    if (content) {
      this.registerContent(content, this.nextPage);
    }
  }
  get mainContent() {
    return this._mainContent;
  }

  constructor(
    protected _changeDetectorRef: ChangeDetectorRef,
    protected _fuseMediaWatcherService: FuseMediaWatcherService,
    protected fleetNavigationService: FleetNavigationService
  ) {}
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((media: any) => {
        this.currentMediaAlias = media.matchingAliases;
        if (this.showFilterButton) {
          if (
            this.currentMediaAlias.length === 0 ||
            media.matchingAliases.includes('sm')
          ) {
            this.searchExpanded = false;
            this._changeDetectorRef.markForCheck();
          } else {
            this.searchExpanded = true;
            this._changeDetectorRef.markForCheck();
          }
        }
      });
  }

  ngAfterViewInit(): void {}
  backdropClicked() {
    this.matDrawer.close();
    this.backdropClosed.emit();
    this._changeDetectorRef.markForCheck();
  }

  setEndDrawer(value: boolean) {
    if (value) {
      this.matDrawer.open();
    } else {
      this.matDrawer.close();
      this.drawMode = null;
    }
    this._changeDetectorRef.markForCheck();
  }

  toggleSearchExpanded() {
    this.searchExpanded = !this.searchExpanded;
    this._changeDetectorRef.markForCheck();
  }

  setSearchFilter(value: boolean) {
    this.searchExpanded = value;
    this._changeDetectorRef.markForCheck();
  }

  registerContent(content: MatDrawerContent, emitter: EventEmitter<any>) {
    content
      .elementScrolled()
      .pipe(
        startWith(10000),
        debounceTime(300),
        map(() => content.measureScrollOffset('bottom')),
        pairwise()
      )
      .subscribe((pairs: any) => {
        const currentV = pairs[1];
        const prevV = pairs[0];
        const scrollOffset = content.measureScrollOffset('bottom');

        console.log(scrollOffset);
        console.log('Current:' + currentV + ' -  Prev:' + prevV);
        if (currentV < prevV || prevV === 0) {
          if (currentV < 300) {
            console.log('next page');
            emitter.emit();
          }
        }
      });
  }
}
