import { Component, Input, OnInit } from '@angular/core';
import { ConditionConfigurationModel } from '@fleet/model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkConditionsService } from './../network-conditions/network-conditions.service';

interface ConditionDisplay {
  name: string;
  icon: string;
}

@Component({
  selector: 'fleet-network-conditions-display-list',
  templateUrl: './network-conditions-display-list.component.html',
  styleUrls: ['./network-conditions-display-list.component.scss'],
})
export class NetworkConditionsDisplayListComponent implements OnInit {
  @Input() numberOfTravellers: number;
  @Input() viewMode: 'ICONS_HORIZONTAL' | 'CHECK_LIST' | 'COMMA_SEPERATED' =
    'ICONS_HORIZONTAL';
  conditionsDisplay$: Observable<any>;
  selectedConditions$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  @Input() iconClass: string;
  @Input() set networkConditionIds(value: string[]) {
    this.selectedConditions$.next(value);
  }

  @Input() set networkConditions(value: { networkConditionId: string }[]) {
    this.selectedConditions$.next(value.map((s) => s.networkConditionId));
  }
  constructor(private networkConditionService: NetworkConditionsService) {
    this.conditionsDisplay$ = combineLatest([
      this.selectedConditions$.asObservable(),
      this.networkConditionService.allConditions$,
    ]).pipe(
      map(([selectedConditions, allConditions]) => {
        if (allConditions && allConditions.length > 0 && selectedConditions) {
          return selectedConditions.map((networkConditionId: string) => {
            return allConditions.find(
              (c: ConditionConfigurationModel) =>
                c.networkConditionId === networkConditionId
            );
          });
        }
        return [];
      })
    );
  }

  ngOnInit(): void {}
}
