<div
  class="flex dark:text-gray-300"
  *ngIf="!breadcrumb.prefixArrow; else withArrow"
>
  <a
    (click)="breadcrumb.clickable ? clicked() : null"
    class="text-sm font-medium text-gray-500 dark:hover:text-gray-100 cursor-default dark:text-gray-300"
    [ngClass]="{
      ' hover:text-gray-700 cursor-pointer hover:underline':
        breadcrumb.clickable
    }"
    >{{ breadcrumb.label }}</a
  >
</div>

<ng-template #withArrow>
  <div class="flex items-center">
    <!-- Heroicon name: solid/chevron-right -->
    <svg
      class="flex-shrink-0 h-5 w-5 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
      />
    </svg>
    <a
      (click)="breadcrumb.clickable ? clicked() : null"
      class="ml-4 text-sm font-medium text-gray-500 cursor-default dark:text-gray-300"
      [ngClass]="{
        ' hover:text-gray-700 dark:hover:text-gray-100 cursor-pointer hover:underline':
          breadcrumb.clickable
      }"
      >{{ breadcrumb.label }}</a
    >
  </div>
</ng-template>
