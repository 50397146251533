import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutFormSectionComponent } from './layout-form-section.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [LayoutFormSectionComponent],
  imports: [CommonModule, MatDividerModule],
  exports: [LayoutFormSectionComponent],
})
export class LayoutFormSectionModule {}
