import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { SourceComponent } from './source.component';

@NgModule({
  declarations: [SourceComponent],
  imports: [
    CommonModule,
    TitleCaseAndCleanPipeModule,
    MatTooltipModule,
    MatIconModule,
  ],
  exports: [SourceComponent],
})
export class SourceModule {}
