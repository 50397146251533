import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolTipDirective, CustomToolTipComponent } from './tool-tip.directive';

@NgModule({
  declarations: [ToolTipDirective, CustomToolTipComponent],
  imports: [CommonModule],
  exports: [ToolTipDirective],
})
export class ToolTipModule {}
