<ng-container *ngIf="mode === 'SELECT'; else list">
  <mat-form-field class="w-full" *ngIf="ENTITY_ATTRIBUTES">
    <mat-label>Block Attributes</mat-label>
    <mat-select
      multiple
      [formControl]="blockAttributeControl"
      placeholder="Block attributes"
    >
      <mat-option
        *ngFor="let attribute of ENTITY_ATTRIBUTES"
        [value]="attribute"
      >
        <div *ngIf="attribute?.display; else type">
          {{ attribute?.display }}
        </div>
        <ng-template #type>
          {{ attribute.type | titleCaseAndClean }} - {{ attribute.value }}
        </ng-template>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<ng-template #list>
  <div class="flex flex-col gap-2 items-start">
    <ng-container *ngFor="let attribute of ENTITY_ATTRIBUTES">
      <mat-checkbox
        *ngIf="
          (attribute?.card && attribute?.card?.status === 'ACTIVE') ||
          (attribute && !attribute?.card)
        "
        (change)="checkboxChanged(attribute)"
        [checked]="attribute?.checked"
      >
        <ng-container
          *ngIf="attribute.type !== 'STORED_CARD'; else showPaymentMethod"
        >
          <div *ngIf="attribute?.display; else type">
            {{ attribute?.display }}
          </div>
          <ng-template #type>
            {{ attribute.type | titleCaseAndClean }} -
            {{ attribute.value }}
          </ng-template>
        </ng-container>
        <ng-template #showPaymentMethod>
          <fleet-payment-method-list-item
            [mode]="'list'"
            [paymentMethod]="attribute.card"
          >
          </fleet-payment-method-list-item>
        </ng-template>
      </mat-checkbox>
    </ng-container>
  </div>
</ng-template>
