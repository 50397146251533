<mat-form-field class="w-full">
  <mat-label>Hubspot Ticket Reference</mat-label>
  <input
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    matInput
    placeholder="Hubspot Ticket Reference"
    [formControl]="hubspotControl"
  />
  <mat-spinner matSuffix diameter="24" *ngIf="loading"></mat-spinner>
  <mat-icon *ngIf="success" matSuffix style="color: green">done</mat-icon>
  <mat-icon *ngIf="failure" matSuffix style="color: red">close</mat-icon>
</mat-form-field>

<div class="text-medium text-secondary mt-4" *ngIf="ticketDisplay">
  {{ ticketDisplay }}
</div>
