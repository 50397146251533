import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { NetworkGroupApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import {
  ApiResponse,
  BaseSearchState,
  initialBaseSearchState,
  NetworkGroupModel,
} from '@fleet/model';
import { pageDataFromSearchResultHeaders } from '@fleet/utilities';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fleet-network-group-selector',
  templateUrl: './network-group-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NetworkGroupSelectorComponent),
      multi: true,
    },
  ],
})
export class NetworkGroupSelectorComponent
  implements OnInit, ControlValueAccessor
{
  @Input() type: string;
  @Input() mode = 'id';

  @Input() matLabelClasses = false;
  @Input() required = false;

  networkGroupSearchState: BehaviorSubject<BaseSearchState<any>> =
    new BehaviorSubject(initialBaseSearchState);

  networkGroups: NetworkGroupModel[];
  networkGroupControl = new UntypedFormControl();

  constructor(
    private networkGroupApiService: NetworkGroupApiService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.searchNetworkGroups({ limit: 100, offset: 0 });
    this.networkGroupControl.valueChanges.subscribe((value) => {
      this.onTouched();

      if (value) {
        if (this.networkGroupSearchState.value.data.length > 0) {
          const found = this.networkGroupSearchState.value.data.find(
            (network: NetworkGroupModel) =>
              network.networkGroupId ===
              (this.mode === 'id' ? value : value.networkGroupId)
          );
          if (found) {
            this.networkGroupControl.setValue(found, {
              emitEvent: false,
            });
            this.onChange(this.mode === 'id' ? found.networkGroupId : found);
          } else {
            this.onChange(this.mode === 'id' ? value.networkGroupId : value);
          }
        } else {
          this.onChange(this.mode === 'id' ? value.networkGroupId : value);
        }
      }
    });
  }

  onChange: any = (group: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(networkGroup: NetworkGroupModel) {
    this.networkGroupControl.setValue(networkGroup);
  }

  searchNetworkGroups(params: any) {
    this.networkGroupSearchState.next({
      ...this.networkGroupSearchState.value,
      searching: true,
      issues: [],
    });

    params['includeChildren'] = true;
    if (this.type) {
      params['type'] = this.type;
    }

    this.networkGroupApiService
      .searchNetworkGroups(params, this.authService.networkId)
      .subscribe({
        next: (resp: HttpResponse<ApiResponse<NetworkGroupModel[]>> | any) => {
          let networks = [];
          if (params['offset'] === 0) {
            // FIRST PAGE
            networks = resp.body.data;
          } else {
            // SUBSEQUENT PAGES
            networks = [
              ...this.networkGroupSearchState.value.data,
              ...resp.body.data,
            ];
          }

          if (this.networkGroupControl.value) {
            const found = networks.find(
              (network: NetworkGroupModel) =>
                network.networkGroupId ===
                (this.mode === 'id'
                  ? this.networkGroupControl.value
                  : this.networkGroupControl.value.networkGroupId)
            );

            if (found) {
              this.networkGroupControl.setValue(found);
            }
          }

          this.networkGroupSearchState.next({
            ...this.networkGroupSearchState.value,
            data: networks,
            searching: false,
            pageData: pageDataFromSearchResultHeaders(resp.headers, params),
          });
        },
        error: (error: any) =>
          this.networkGroupSearchState.next({
            ...this.networkGroupSearchState.value,
            issues: error,
            searching: false,
          }),
      });
  }
}
