<div class="relative" *ngIf="display">
  <div
    class="flex w-full items-center"
    [ngClass]="[
      (display.status === 'ASSIGNED' ||
        display.status === 'DISPATCHED' ||
        display.status === 'ABANDONED') &&
      display.slaLevel > 1
        ? 'border-' + display.slaColor
        : '',
      isCard ? 'shadow rounded-2xl bg-card border-2' : '',
      paddingClasses ? paddingClasses : ''
    ]"
    fleetDoubleClick
    (singleClick)="
      selectJob.emit({ job: job.latestStatusUpdate, click: 'single' })
    "
    (doubleClick)="
      selectJob.emit({ job: job.latestStatusUpdate, click: 'double' })
    "
  >
    <button
      *ngIf="display.status === 'EXPIRED'"
      class="absolute top-0 right-0 z-50"
      (click)="dismissJob($event)"
      mat-icon-button
      matTooltip="Dismiss"
    >
      <mat-icon>close</mat-icon>
    </button>

    <div class="flex flex-col flex-auto">
      <div class="flex flex-row justify-between items-start">
        <div class="flex flex-row">
          <div class="mr-1 font-medium text-md text-secondary">
            {{ display.startLocality }}
          </div>
          <ng-container
            *ngIf="display.endLocality && display.endLocality !== 'Unknown'"
          >
            <span class="mx-1 font-medium text-md text-secondary">></span>
            <div
              class="mr-2 font-medium text-md text-secondary"
              *ngIf="display.endLocality"
            >
              {{ display.endLocality }}
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="display.networkService"
          class="font-medium text-sm text-hint uppercase tracking-wider"
        >
          ({{ display.networkService }})
        </div>

        <fleet-network-conditions-display-list
          [ngClass]="{ 'mr-4': display.status === 'EXPIRED' }"
          [networkConditionIds]="display.networkConditionIds"
          [numberOfTravellers]="display?.numberOfTravellers"
          [iconClass]="'icon-size-5'"
        >
        </fleet-network-conditions-display-list>
      </div>

      <div class="flex flex-row justify-start mt-2 w-full">
        <div class="flex flex-col items-start justify-start min-w-32">
          <div class="flex flex-row gap-1 content-end items-baseline">
            <div
              class="flex-none leading-none"
              style="font-family: 'Teko', sans-serif; font-size: 3em"
            >
              {{ display.slaStartTime }}
            </div>
            <div
              class="flex leading-none"
              style="font-family: 'Teko', sans-serif; font-size: 1em"
            >
              {{ display.slaStartTimeAMPM | uppercase }}
            </div>
          </div>

          <ng-container
            *ngIf="
              display.status === 'DISPATCHED' ||
              display.status === 'ASSIGNED' ||
              display.status === 'ABANDONED'
            "
          >
            <div class="font-medium text-sm leading-none mt-1">
              {{ display.timeUntilNextSla }}
            </div>
          </ng-container>

          <div *ngIf="job.latestJobProgress" class="flex flex-row gap-2">
            <div class="flex flex-row gap-2">
              <div
                *ngIf="display.status === 'ARRIVED'; else showEta"
                class="font-medium text-sm text-secondary leading-none mt-1"
              >
                ARRIVED
              </div>
              <ng-template #showEta>
                <div class="font-medium text-sm leading-none mt-1">
                  {{ display.eta }}
                </div>
                <div
                  class="font-medium text-sm text-secondary leading-none mt-1"
                >
                  {{ display.distance }}
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-start justify-start min-w-32">
          <div
            *ngIf="display.travellerFirstName"
            class="min-w-20 w-full justify-between tracking-tighter font-bold text-md flex flex-row gap-1 items-center"
          >
            <div class="flex flex-row items-center pr-1 -ml-1 gap-1">
              <mat-icon
                class="w-3 h-3"
                *ngIf="
                  !display.organisationName || fleetProduct === 'BUSINESS';
                  else orgIcon
                "
                >hail</mat-icon
              >
              <ng-template #orgIcon
                ><mat-icon [matTooltip]="display.organisationName"
                  >corporate_fare</mat-icon
                ></ng-template
              >
              <div>
                {{ display.travellerFirstName }}
              </div>
            </div>
          </div>

          <div
            *ngIf="display.driverFirstName"
            class="flex flex-row gap-1 mt-1 -ml-1 items-start"
          >
            <div class="flex flex-col gap-1">
              <mat-icon class="w-3 h-3">local_taxi</mat-icon>
            </div>
            <div class="flex flex-col">
              <div class="min-w-20 tracking-tighter font-bold text-md">
                {{ display.plateNumber }}
              </div>

              <div class="flex flex-row items-center ml-0.5 -mt-1 gap-1">
                <div
                  *ngIf="display.driverFirstName"
                  class="font-medium text-sm"
                >
                  {{ display.driverFirstName }}
                </div>
                <div
                  *ngIf="display.driverTaxiAuthorityNumber"
                  class="font-medium text-sm"
                >
                  ({{ display.driverTaxiAuthorityNumber }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
