/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Inject,
} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import {
  OrganisationGroupSearchResultModel,
  ApiResponse,
  OrganisationGroupModel,
  IssueModel,
} from '@fleet/model';
import { HttpResponse, HttpParams } from '@angular/common/http';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  withLatestFrom,
  switchMap,
  map,
  catchError,
  finalize,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  Validators,
} from '@angular/forms';
import { OrganisationApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';

@Component({
  selector: 'fleet-organisation-group-autocomplete',
  templateUrl: './organisation-group-autocomplete.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganisationGroupAutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrganisationGroupAutocompleteComponent),
      multi: true,
    },
  ],
})
export class OrganisationGroupAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  searching: boolean;
  @Output() organisationGroupSelected = new EventEmitter();

  @Input() label: string;
  issues: IssueModel[];
  // @Input() currentGroup;
  @Input() organisationId: string;
  @Input() prefixIcon = 'search';

  @Input() staticGroups: OrganisationGroupSearchResultModel[];

  _parentGroupId: any;
  @Input()
  set parentGroupId(value) {
    this.writeValue(value);
  }
  get parentGroupId() {
    return this._parentGroupId;
  }

  fleetProduct: string;

  @Input() placeHolder: any;
  @Input() organisationUserId: any;
  @Input() organisationStaffId: any;
  @Input() organisationGroupId: any;

  @Input() set required(value: boolean) {
    if (value) {
      this.searchControl.setValidators([Validators.required]);
    } else {
      this.searchControl.clearValidators();
    }
    this.searchControl.updateValueAndValidity();
  }

  @Input() params: any;

  @Input() mode: string;

  @Output() groupSelected = new EventEmitter<string>();

  _readOnly: boolean;
  @Input() set readOnly(value: boolean) {
    this._readOnly = value;
    if (value) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  get readOnly() {
    return this._readOnly;
  }

  // @Input() label;

  @ViewChild('groupSearchInput') groupSearchInput: ElementRef;
  groupSearch$: Observable<any>;
  // @Input() propertyForValue;

  searchControl: UntypedFormControl = new UntypedFormControl();
  searchComplete = false;

  @ViewChild('groupAutoComplete') groupAutoComplete: MatAutocomplete;
  constructor(
    private organisationApiService: OrganisationApiService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  ngOnInit() {
    this.groupSearch$ = this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        this.issues = [];
        this.searchComplete = false;
        this.changeDetectorRef.markForCheck();
      }),
      switchMap((value) => {
        if (this.staticGroups) {
          return this.staticGroups;
        } else {
          const params: string = this.assembleParams(value);
          if (value === '') {
            this.onChange(value);
            this.onTouched();
          }

          return this.organisationApiService
            .searchGroups(params, this.organisationId)
            .pipe(
              map(
                (
                  resp:
                    | HttpResponse<
                        ApiResponse<OrganisationGroupSearchResultModel[]>
                      >
                    | any
                ) => {
                  this.searching = false;
                  this.searchComplete = true;
                  this.changeDetectorRef.markForCheck();

                  if (this.organisationGroupId) {
                    const noParentOrganisations = resp.body.data.filter(
                      (group: OrganisationGroupSearchResultModel) =>
                        group.organisationGroupId !== this.organisationGroupId
                    );

                    return noParentOrganisations;
                  } else {
                    return resp.body.data;
                  }
                }
              ),
              catchError((issues: IssueModel[]) => {
                this.issues = issues;
                this.searchComplete = false;
                this.searching = false;
                this.changeDetectorRef.markForCheck();
                return [];
              })
            );
        }
      })
    );
  }

  assembleParams(groupName: string) {
    const params: any = Object.assign(
      {},
      { limit: 10, offset: 0, displayName: groupName },
      this.params
    );
    //checkuser security
    if (this.organisationUserId && this.fleetProduct == 'BUSINESS') {
      params.organisationUserId = this.organisationUserId;
      params.includeChildren = true;
    }
    //check staffreference
    if (this.organisationStaffId) {
      params.organisationStaffId = this.organisationStaffId;
    }
    return params;
  }

  checkForUserSecurity(params: any) {
    if (this.organisationUserId && this.fleetProduct == 'BUSINESS') {
      params.organisationUserId = this.organisationUserId;
      params.includeChildren = true;
    }
  }

  displayFn(group?: OrganisationGroupSearchResultModel): string | undefined {
    return group ? group.displayName : undefined;
  }

  onChange: any = (object: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      if (!value?.organisationId || typeof value === 'string') {
        this.searching = true;
        this.organisationApiService
          .getGroup(
            typeof value === 'string' ? value : value.organisationGroupId,
            this.organisationId
          )
          .pipe(
            map((resp: ApiResponse<OrganisationGroupModel> | any) => resp.data),
            catchError((error) => (this.issues = error)),
            tap(() => (this.searching = false))
          )
          .subscribe((group: OrganisationGroupModel) => {
            this.searching = false;
            this.setNativeElementValue(group.displayName);

            this.selectGroup({ option: { value: group } });
            this.changeDetectorRef.markForCheck();
          });
      } else {
        this.setNativeElementValue(value.displayName);

        this.selectGroup({ option: { value: value } });
        this.changeDetectorRef.markForCheck();
      }
    } else {
      // this.setNativeElementValue(null);
      this.searchControl.reset();

      this.changeDetectorRef.markForCheck();
    }
  }

  private setNativeElementValue(value: any) {
    setTimeout(() => {
      this.groupSearchInput.nativeElement.value = value ? value : '';
    }, 1);
  }

  selectGroup(e: any) {
    this.organisationGroupSelected.emit(e?.option?.value);

    let value = e?.option?.value;
    value =
      this.mode === 'id' ? value?.organisationGroupId : value ? value : '';

    this.onChange(value);
    this.onTouched();
  }

  validate(c: UntypedFormControl) {
    return c.value
      ? null
      : c.touched && this.required
      ? {
          groupAutocomplete: {
            message: 'Please ensure search and select a group from the list',
          },
        }
      : null;
  }

  initialiseSearching() {
    //Initial search on click when blank
    if (this.searchControl.value === '' || this.searchControl.value == null) {
      this.searchControl.setValue('');
      this.searchControl.updateValueAndValidity();
    }
    setTimeout(() => this.groupSearchInput.nativeElement.focus(), 0);
  }
}
