import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {
  s3Client: HttpClient;
  documentEndpoint;
  constructor(
    private http: HttpClient,
    private backEnd: HttpBackend,
    @Inject('env') env: any
  ) {
    this.s3Client = new HttpClient(backEnd);
    this.documentEndpoint = env.host + paths.document;
  }

  requestS3DocumentUpload(document: any) {
    return this.http
      .post(`${this.documentEndpoint}/uploadUrl`, document)
      .pipe(catchError(handleApiError));
  }

  uploadDocumenttoS3(url: any, file: any) {
    return this.s3Client
      .put(url, file, {
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream',
        }),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(handleApiError));
  }

  getS3ResourceasText(url: string): Observable<any> {
    return this.s3Client.get(url, { responseType: 'text' });
  }

  getDownloadUrl(documentId: string, params: any): Observable<any> {
    return this.http
      .get(`${this.documentEndpoint}/${documentId}`, { params: params })
      .pipe(catchError(handleApiError));
  }
}
