import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  AreaModel,
  AreaStatisticsModel,
  VehicleRankingModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AreaApiService {
  host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.area;
  }

  getAreaRanking(
    areaId: string
  ): Observable<ApiResponse<VehicleRankingModel[]> | any> {
    return this.http
      .get(`${this.host}/${areaId}/ranking`)
      .pipe(catchError(handleApiError));
  }

  searchStatistics(
    params: any
  ): Observable<ApiResponse<AreaStatisticsModel> | any> {
    return this.http
      .get(`${this.host}/statistics`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }

  searchAreas(params: any): Observable<ApiResponse<AreaModel[]> | any> {
    return this.http
      .get(`${this.host}`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }

  getArea(areaId: string): Observable<ApiResponse<AreaModel> | any> {
    return this.http
      .get(`${this.host}/${areaId}`)
      .pipe(catchError(handleApiError));
  }

  updateArea(
    area: AreaModel,
    areaId: string
  ): Observable<ApiResponse<AreaModel> | any> {
    return this.http
      .put(`${this.host}/${areaId}`, area)
      .pipe(catchError(handleApiError));
  }

  searchServiceAreas(params: any): Observable<ApiResponse<AreaModel[]> | any> {
    return this.http
      .get(`${this.host}`, { params: params })
      .pipe(catchError(handleApiError));
  }

  deleteArea(areaId: string): Observable<any> {
    return this.http
      .delete(`${this.host}/${areaId}`)
      .pipe(catchError(handleApiError));
  }
}
