import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { ApiResponse, AuditDiffModel, IssueModel } from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReferenceApiService {
  host;

  fleetHeaders: HttpHeaders = new HttpHeaders({
    'fleet-channel': 'WEB',
    'fleet-product': 'HUB',
  });
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.reference;
  }

  setFleetHeaders(product: any) {
    this.fleetHeaders = new HttpHeaders({
      'fleet-channel': 'WEB',
      'fleet-product': product,
    });
  }

  getReferenceData(fields: any) {
    return this.http
      .get(`${this.host}`, {
        params: { fields: fields },
        headers: this.fleetHeaders,
      })
      .pipe(catchError(handleApiError));
  }

  searchAuditFields(
    params: any
  ): Observable<ApiResponse<string[]> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/auditFields`, {
        params: params,
        headers: this.fleetHeaders,
      })
      .pipe(catchError(handleApiError));
  }
}
