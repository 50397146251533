//marker that is a white circle and features stroke that moves around the inner edge of the circle, its starts at 12 o'clock and moves around completely, the stroke color will change as it moves around
//around the inner edge of the circle is a very light track that the stroke will move around
// when now is before dispatchSlaStartTime the stroke is at its 0 position
// when now is after dispatchSlaStartTime and before dispatchSlaLevelOne the stroke is green
// when now is after dispatchSlaLevelOne and before dispatchSlaLevelTwo the stroke is orange
// when now is after dispatchSlaLevelTwo and before dispatchSlaLevelThree the stroke is red
// when now is after dispatchSlaLevelThree the circle goes red (the stroke disappears) and the minutes over is shown offset veritically at the bottom of the marker
// if activejob.latestStatusUpdate.plateNumber then a small car icon will be present at the top of the circle offset veritically up
// for the stroke it moves around the circle based on where now is in relation to dispatchSlaStartTime (represents 12 oclock) and dispatchSlaLevelThree (represents the stroke going all the way around),
// these markers will be redrawn constantly so there is no need for animations

import {
  ActiveJobDisplay,
  JobSearchResultModel,
  JobStatusUpdateAndProgress,
  LocationModel,
} from '@fleet/model';
import { createActiveJobDisplay } from '@fleet/utilities';
import { Marker } from '@googlemaps/adv-markers-utils';

import { mockLocations } from 'libs/job/src/lib/services/mock/job-mocks';
import { DateTime } from 'luxon';
// ... existing code ...

export function createSvgContainer(
  scale: number,
  circumference: number,
  initialDashOffset: number,
  strokeColor: string,
  overTimeDisplay: string,
  centerIconDisplay: string
): HTMLElement {
  const scaledRadius = 15 * scale;
  const scaledCircumference = circumference * scale;
  const scaledInitialDashOffset = initialDashOffset * scale;
  const scaledStrokeWidth = 2 * scale;

  const svgContainer = document.createElement('div');
  svgContainer.innerHTML = `
  
   <svg viewBox="0 0 32 48" width="${32 * scale}" height="${48 * scale}">
      <circle cx="16" cy="16" r="${scaledRadius}" fill="white" stroke-width="${scaledStrokeWidth}"
        stroke="black" stroke-opacity="0.1" />
      <circle
        cx="16"
        cy="16"
        r="${scaledRadius}"
        fill="none"
        stroke-width="${scaledStrokeWidth}"
        stroke="${strokeColor}"
        stroke-dasharray="${scaledCircumference}"
        stroke-dashoffset="${scaledInitialDashOffset}"
       
         transform="rotate(-90 16 16)"
      />
      ${overTimeDisplay}
      ${centerIconDisplay}
    </svg>
  `;
  return svgContainer;
}

export function activeJobMarkerv2(
  activeJob: JobStatusUpdateAndProgress,
  disabled?: boolean
) {
  const sla = createActiveJobDisplay(activeJob);

  const radius = 15;
  const circumference = 2 * Math.PI * radius;
  const now = DateTime.now().toMillis();
  const dispatchSlaStartTime = DateTime.fromISO(
    activeJob.latestStatusUpdate.dispatchSlaStartTime.toString()
  ).toMillis();
  const dispatchSlaLevelOne = DateTime.fromISO(
    activeJob.latestStatusUpdate.dispatchSlaLevelOne.toString()
  ).toMillis();
  const dispatchSlaLevelTwo = DateTime.fromISO(
    activeJob.latestStatusUpdate.dispatchSlaLevelTwo.toString()
  ).toMillis();
  const dispatchSlaLevelThree = DateTime.fromISO(
    activeJob.latestStatusUpdate.dispatchSlaLevelThree.toString()
  ).toMillis();

  let initialDashOffset = circumference;
  let strokeColor = 'green';
  let overTimeDisplay = '';
  let centerIconDisplay = '';
  let scale = 1;
  if (activeJob.latestStatusUpdate.plateNumber) {
    centerIconDisplay = `
      <svg x="8" y="8" width="${16 * scale}" height="${
      16 * scale
    }" viewBox="0 -960 960 960" fill="#5f6368">
        <path d="M240-200v40q0 17-11.5 28.5T200-120h-40q-17 0-28.5-11.5T120-160v-320l84-240q6-18 21.5-29t34.5-11h100v-80h240v80h100q19 0 34.5 11t21.5 29l84 240v320q0 17-11.5 28.5T800-120h-40q-17 0-28.5-11.5T720-160v-40H240Zm-8-360h496l-42-120H274l-42 120Zm-32 80v200-200Zm100 160q25 0 42.5-17.5T360-380q0-25-17.5-42.5T300-440q-25 0-42.5 17.5T240-380q0 25 17.5 42.5T300-320Zm360 0q25 0 42.5-17.5T720-380q0-25-17.5-42.5T660-440q-25 0-42.5 17.5T600-380q0 25 17.5 42.5T660-320Zm-460 40h560v-200H200v200Z"/>
      </svg>
    `;
  } else {
    centerIconDisplay = `
      <svg x="8" y="8" width="${16 * scale}" height="${
      16 * scale
    }" viewBox="0 -960 960 960" fill="#5f6368">
        <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/>
      </svg>
    `;
  }

  if (now < dispatchSlaStartTime) {
    initialDashOffset = circumference;
  } else {
    const totalSlaDuration = dispatchSlaLevelThree - dispatchSlaStartTime;
    const elapsedDuration = now - dispatchSlaStartTime;
    initialDashOffset = Math.max(
      circumference - (circumference * elapsedDuration) / totalSlaDuration,
      0
    );
  }

  if (now > dispatchSlaLevelThree) {
    strokeColor = 'red';
    const minutesOver = Math.floor((now - dispatchSlaLevelThree) / 60000);
    overTimeDisplay = `<foreignObject x="${2 * scale}" y="${
      28 * scale
    }" width="${28 * scale}" height="${16 * scale}">
      <div xmlns="http://www.w3.org/1999/xhtml" style="background-color: red; border-radius: 8px; padding: ${
        2 * scale
      }px; color: white; font-size: ${8 * scale}px; text-align: center;">
        ${minutesOver}m
      </div>
    </foreignObject>`;
  } else if (now > dispatchSlaLevelTwo) {
    strokeColor = 'red';
  } else if (now > dispatchSlaLevelOne) {
    strokeColor = 'orange';
  }

  const marker = new Marker({
    position: {
      lat: parseFloat(activeJob.latestStatusUpdate.startLatitude),
      lng: parseFloat(activeJob.latestStatusUpdate.startLongitude),
    },
    content: createSvgContainer(
      1,
      circumference,
      initialDashOffset,
      strokeColor,
      overTimeDisplay,
      centerIconDisplay
    ),
  } as any) as any;

  marker.setData({ activeJob: activeJob, slaDisplay: sla });

  // Scale the marker based on zoom level
  marker.scale = ({ map }: { map: google.maps.Map }) => {
    const scale = Math.max(
      0.5,
      Math.min(2, Math.pow(1.45, map.getZoom() - 12))
    );
    marker.content = createSvgContainer(
      scale,
      circumference,
      initialDashOffset,
      strokeColor,
      overTimeDisplay,
      centerIconDisplay
    );
    return scale;
  };

  return marker;
}
