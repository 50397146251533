import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { PlaceApiService } from '@fleet/api';

import {
  ApiResponse,
  IssueModel,
  LocalityModel,
  PlaceModel,
} from '@fleet/model';
import { identity, Observable } from 'rxjs';

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { LocalityAutocompleteService } from './locality-autocomplete.service';

@Component({
  selector: 'fleet-locality-autocomplete',
  templateUrl: './locality-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalityAutocompleteComponent),
      multi: true,
    },
  ],
})
export class LocalityAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  loading = false;
  searching = true;
  searchComplete = false;
  @Input() mode = 'id';
  @Input() countryCode = 'AU';
  @Input() regionCode: string;
  @Input() initialEmptySearch: boolean;
  @ViewChild('input') input: ElementRef;
  searchControl = new UntypedFormControl();
  results$: Observable<any[]>;
  issues: IssueModel[];
  @Input() label: string;
  @Input() prefixIcon = 'search';
  @Input() placeHolder: string;
  @Input() classes: any;
  @Output() localityLatLng = new EventEmitter();

  constructor(
    private placesApiService: PlaceApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private localityAutocompleteService: LocalityAutocompleteService
  ) {}

  writeValue(value: any): void {
    if (value) {
      if (this.input) {
        this.searchControl.setValue(value, { emitEvent: false });
        //this.input.nativeElement.value = value ? value.name : '';
      }
    } else if (this.input) {
      this.searchControl.setValue(null, { emitEvent: false });
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchControl.disable({ emitEvent: false });
    } else {
      this.searchControl.enable({ emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.results$ = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeWhile((value) => value !== 'not-found'),
      tap(() => {
        this.searching = true;
        this.issues = [];
        this.searchComplete = false;
        this.changeDetectorRef.markForCheck();
      }),
      switchMap((value) => {
        let params = {} as any;

        if (typeof value === 'object' || value == '' || value == null) {
          //it MUST have a value.
          if (value === '') {
            this.onChange(null);
            this.onTouched();
          }
          return [];
        } else {
          params = {
            limit: 10,
            offset: 0,
            localityFilter: value,
          };
        }

        if (this.regionCode) {
          params['regionCode'] = this.regionCode;
        } else if (this.localityAutocompleteService.regionCode) {
          params['regionCode'] = this.localityAutocompleteService.regionCode;
        }
        params['countryCode'] = this.countryCode;

        return this.placesApiService.searchLocalities(params).pipe(
          map((resp: ApiResponse<LocalityModel[]>) => {
            this.searchComplete = true;
            this.searching = false;
            this.changeDetectorRef.markForCheck();
            return resp.data;
          }),
          catchError((issues) => {
            this.issues = issues;
            this.searchComplete = false;
            this.searching = false;
            this.changeDetectorRef.markForCheck();
            return [];
          })
        );
      })
    );
  }

  selectLocality(e: MatAutocompleteSelectedEvent) {
    const value =
      this.mode === 'id' ? e.option.value.name : e.option.value.name;
    if (e.option.value.latitude) {
      this.localityLatLng.emit({
        latitude: e.option.value.latitude,
        longitude: e.option.value.longitude,
      });
    }
    this.onChange(value);
    this.onTouched();
  }

  displayFn(place?: LocalityModel): string | undefined {
    return place ? place.name : '';
  }

  inputClick(eventTarget: any) {
    if (
      (this.searchControl.value === '' || this.searchControl.value == null) &&
      this.initialEmptySearch
    ) {
      this.searchControl.setValue('empty-search');
      this.searchControl.updateValueAndValidity();
    } else {
      eventTarget.select();
    }
  }
}
