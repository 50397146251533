import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VehicleApiService } from '@fleet/api';
import { FuseConfirmationService, fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  IssueModel,
  LifecycleTransitionModel,
} from '@fleet/model';
import { titleCaseClean } from '@fleet/utilities';

@Component({
  selector: 'fleet-lifecycle-transitions',
  templateUrl: './lifecycle-transitions.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class LifecycleTransitionsComponent implements OnInit, OnDestroy {
  @Input() vehicleId: string;
  @Input() mode: 'LIST' | 'CARDS' = 'LIST';
  @Input() search: LifecycleTransitionModel[];
  @Output() lifecycleUpdated = new EventEmitter();
  issues: IssueModel[];
  loading: boolean;

  constructor(
    private vehicleApiService: VehicleApiService,
    private changeDetectorRef: ChangeDetectorRef,

    private matDialog: MatDialog,
    private fuseConfirmationService: FuseConfirmationService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.issues = [];
    this.loading = false;
    this.search = [];
    this.changeDetectorRef.markForCheck();
  }
  actionLifecycleTransition(
    action: { label: string; type: string },
    transition: LifecycleTransitionModel
  ) {
    const dialogRef = this.fuseConfirmationService.open({
      title: action.label,
      message:
        'Are you sure you want to ' +
        action.type.toLowerCase() +
        ' this lifecycle transition?',
      actions: {
        confirm: {
          color: 'primary',
          label: titleCaseClean(action.type),
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this.issues = [];
        this.loading = true;
        this.changeDetectorRef.markForCheck();

        let apiCall;

        if (this.vehicleId) {
          switch (action.type) {
            case 'DELETE':
              apiCall = this.vehicleApiService.deleteLifecycleTransition(
                transition.lifecycleTransitionId,
                this.vehicleId
              );
              break;

            default:
              break;
          }
        }
        this.loading = true;
        this.issues = [];
        this.changeDetectorRef.markForCheck();
        apiCall.subscribe({
          next: (resp: ApiResponse<null>) => {
            this.loading = false;
            this.changeDetectorRef.markForCheck();
            this.lifecycleUpdated.emit();
          },
          error: (issues: IssueModel[]) => {
            this.issues = issues;
            this.loading = false;
            this.changeDetectorRef.markForCheck();
          },
        });
      }
    });
  }
}
