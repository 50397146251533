import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsFromIssuesPipe } from './alerts-from-issues.pipe';

@NgModule({
  declarations: [AlertsFromIssuesPipe],
  imports: [CommonModule],
  exports: [AlertsFromIssuesPipe],
})
export class AlertsFromIssuesModule {}
