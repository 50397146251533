import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FuseCardModule } from '@fleet/fuse';
import { MarkedPipeModule, TotalFromFareGroupsPipeModule } from '@fleet/pipes';
import { JobCardFareDetailComponent } from './job-card-fare-detail.component';
import { FareGroupTotalsPipe } from './pipes/fare-group-totals.pipe';
import { FareGroupPipe } from './pipes/fare-group.pipe';
@NgModule({
  declarations: [
    JobCardFareDetailComponent,
    FareGroupPipe,

    FareGroupTotalsPipe,
  ],
  imports: [
    CommonModule,
    FuseCardModule,
    MatButtonModule,
    RouterModule,
    MarkedPipeModule,
    TotalFromFareGroupsPipeModule,
  ],
  exports: [JobCardFareDetailComponent],
})
export class JobCardFareDetailModule {}
