import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JobOrganisationModel } from '@fleet/model';
import { getOrganisationRoutePrefix } from '@fleet/utilities';

@Component({
  selector: 'fleet-job-organisation-card',
  templateUrl: './job-organisation-card.component.html',
  styleUrls: ['./job-organisation-card.component.scss'],
})
export class JobOrganisationCardComponent implements OnInit {
  @Input() jobOrganisation: JobOrganisationModel;
  @Input() canNavigate = false;
  @Input() isCard = true;
  @Input() paddingClasses = 'px-3 py-4';

  fleetProduct: string;
  constructor(private router: Router, @Inject('env') env: any) {
    this.fleetProduct = env.fleetProduct;
  }

  ngOnInit(): void {}

  navigateToOrganisation() {
    const orgRoute = getOrganisationRoutePrefix(
      this.fleetProduct,
      this.jobOrganisation.organisationId
    );

    this.router.navigate([orgRoute]);
  }

  navigateToOrganisationGroup() {
    const orgRoute = getOrganisationRoutePrefix(
      this.fleetProduct,
      this.jobOrganisation.organisationId
    );

    this.router.navigate([
      orgRoute + '/group/' + this.jobOrganisation.organisationGroupId,
    ]);
  }
}
