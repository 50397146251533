import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@fleet/auth';
import { FleetNavigationService, fleetFlexNavigation } from '@fleet/navigation';
import { ProductConfigurationService } from '@fleet/product-configuration';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  fleetProduct: string;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/logout' ? '/' : state.url;

    return this.authService.checkAuth().pipe(
      switchMap((authenticated: boolean) => {
        if (route.queryParams['jwt']) {
          this.authService.accessToken = route.queryParams['jwt'];
          const updatedQueryParams = { ...route.queryParams };
          delete updatedQueryParams.jwt;
          const url = new URL(redirectUrl, 'http://dummy.com');
          const path = url.pathname;
          this.router.navigate([path], {
            queryParams: updatedQueryParams,
            queryParamsHandling: 'merge',
          });
        }
        if (route.queryParams['layout']) {
          this.productConfigurationService.config = {
            layout: route.queryParams['layout'],
          };
          if (route.queryParams['layout'] === 'flex') {
            this.fleetNavigationService.flexNavigation = true;
            this.fleetNavigationService.setNavigation(
              fleetFlexNavigation,
              this.authService.permissions.value
            );
          }
        }
        if (route.queryParams['scheme']) {
          this.productConfigurationService.config = {
            scheme: route.queryParams['scheme'],
          };
        }
        if (!authenticated) {
          //check for jwt in url ie coming from flex
          if (route.queryParams['jwt']) {
            // Sentry.withScope(function (scope) {
            //   scope.setTag('jwt', route.queryParams['jwt']);
            //   Sentry.captureMessage('JWT handover from app');
            // });

            return this.authService.checkAuth().pipe(
              switchMap((jwtAuthenticated: boolean) => {
                if (jwtAuthenticated) {
                  // this.router.navigate([redirectUrl], {
                  //   queryParams: { jwt: null, layout: null },
                  // });
                  return of(true);
                }
                this.router.navigate(['/auth/login'], {
                  queryParams: { redirectUrl },
                });
                return of(false);
              })
            );
          } else {
            if (this.fleetProduct != 'TRAVELLER') {
              this.router.navigate(['/auth/login'], {
                queryParams: { redirectUrl },
              });
            } else {
              this.router.navigate(['/auth/welcome'], {
                queryParams: { redirectUrl },
              });
            }
            return of(false);
          }
        }
        return of(true);
      })
    );
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private productConfigurationService: ProductConfigurationService,
    private fleetNavigationService: FleetNavigationService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
  }
}
