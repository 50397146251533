import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { GroupHierarchyItem } from '@fleet/model';

@Component({
  selector: 'fleet-group-view-control',
  templateUrl: './group-view-control.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupViewControlComponent implements OnInit, OnDestroy {
  @Input() group: GroupHierarchyItem;

  @ViewChild('groupControl') groupControlOrigin: MatButton;
  @ViewChild('groupPanel') groupPanel: TemplateRef<any>;

  _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  openPanel(): void {
    // Return if the messages panel or its origin is not defined
    if (!this.groupPanel || !this.groupControlOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this.createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this.groupPanel, this._viewContainerRef)
    );
  }

  closePanel(): void {
    this._overlayRef.detach();
  }

  createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this.groupControlOrigin._elementRef.nativeElement)
        .withLockedPosition()
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }
}
