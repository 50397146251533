import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { JobApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  IssueModel,
  JobModel,
  JobSearchResultModel,
  ReferenceDataItemModel,
} from '@fleet/model';
import { ReferenceService } from '@fleet/reference';
import { Observable } from 'rxjs';

@Component({
  selector: 'fleet-job-cancel-dialog',
  templateUrl: './job-cancel-dialog.component.html',

  animations: fuseAnimations,
})
export class JobCancelDialogComponent implements OnInit {
  @Input() job: JobModel | JobSearchResultModel;
  loading: boolean;
  issues: IssueModel[] = [];
  reasons$: Observable<ReferenceDataItemModel[]>;
  fleetProduct: string;
  reasonControl: UntypedFormControl = new UntypedFormControl();

  @Output() successful = new EventEmitter();
  @Output() cancelled = new EventEmitter();
  constructor(
    private jobApiService: JobApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private referenceService: ReferenceService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  ngOnInit(): void {
    this.reasons$ = this.referenceService.networkCancellationReasons$;
  }

  cancelJob() {
    this.issues = [];
    this.loading = true;
    this.changeDetectorRef.markForCheck();

    this.jobApiService
      .cancelJob(this.job.jobId, this.reasonControl.value)
      .subscribe({
        next: (resp: ApiResponse<JobModel>) => {
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          this.successful.emit(resp.data);
        },
        error: (issues: IssueModel[]) => {
          this.loading = false;
          this.issues = issues;
          this.changeDetectorRef.markForCheck();
        },
      });
  }
}
