export const allowedTravellerFunctionsForNow = [
  'JOB',
  'JOB_SEARCH',
  'JOB_DETAIL',
  'JOB_CANCEL',
  'JOB_START_LOCATION',
  'JOB_START_PLACE_SEARCH',
  'JOB_END_LOCATION',
  'JOB_END_PLACE_SEARCH',
  'JOB_WAYPOINT_LOCATION',
  'JOB_WAYPOINT_PLACE_SEARCH',
  'JOB_ORGANISATION_STAFF',
  'JOB_ORGANISATION_GUEST',
  'JOB_PRICING',
  'JOB_PAYMENT',
  'JOB_MAP',
  'TRAVELLER_JOB_SEARCH',
  'TRAVELLER_SETTINGS',
  'TRAVELLER_PAYMENT_METHODS',
  'TRAVELLER_PROFILES',
  'TRAVELLER_STATUS',
];
