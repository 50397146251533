import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityCallDetailComponent } from './entity-call-detail.component';
import {
  ActionButtonsModule,
  CardDescriptionListModule,
  SidebarHeaderModule,
} from '@fleet/ui';
import {
  TitleCaseAndCleanPipeModule,
  MobileFormatModule,
  DurationPipeModule,
} from '@fleet/pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { CallerTypePipeModule } from '../caller-type-pipe/caller-type-pipe.module';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';

@NgModule({
  declarations: [EntityCallDetailComponent],
  imports: [
    CommonModule,
    CardDescriptionListModule,
    SidebarHeaderModule,
    ActionButtonsModule,
    TitleCaseAndCleanPipeModule,

    MobileFormatModule,
    DurationPipeModule,
    MatIconModule,
    MatTooltipModule,
    HasFunctionPipeModule,
    InternationalizationDatePipeModule,
    CallerTypePipeModule,
  ],
  exports: [EntityCallDetailComponent],
})
export class EntityCallDetailModule {}
