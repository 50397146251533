import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { paths } from '@fleet/environment';
import { catchError } from 'rxjs/operators';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { NotificationModel, NotificationThreadModel } from '@fleet/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  host;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.notification;
  }

  searchTemplates(params: any): Observable<any> {
    return this.http
      .get(`${this.host}/template`, { params: encodeParams(params) })
      .pipe(catchError(handleApiError));
  }

  createNotification(notification: NotificationModel): Observable<any> {
    return this.http
      .post(`${this.host}`, notification)
      .pipe(catchError(handleApiError));
  }

  getNotifcation(notificationId: string): Observable<NotificationModel | any> {
    return this.http
      .get(`${this.host}/${notificationId}`)
      .pipe(catchError(handleApiError));
  }

  sendNotification(
    notificationId: string
  ): Observable<NotificationModel | any> {
    return this.http
      .post(`${this.host}/${notificationId}/send`, {})
      .pipe(catchError(handleApiError));
  }

  searchNotifications(params: any) {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
}
