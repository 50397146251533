import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { ServiceSelectComponent } from './service-select.component';

@NgModule({
  declarations: [ServiceSelectComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    TitleCaseAndCleanPipeModule,
    MatIconModule,
  ],
  exports: [ServiceSelectComponent],
})
export class ServiceSelectModule {}
