/* eslint-disable no-sparse-arrays */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {
  ApiResponse,
  DriverModel,
  InvoiceModel,
  IssueModel,
  PaymentTransactionModel,
  SaleModel,
} from '@fleet/model';
import { SaleApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import { PaymentTransactionGroup } from '../job-sale-transaction-results/';
import { titleCaseClean } from '@fleet/utilities';
export interface EntityPTDRefundState {
  loading: boolean;
  issues: any;
  buttonLabel: string;
}

@Component({
  selector: 'fleet-entity-ptd-refund',
  templateUrl: './entity-ptd-refund.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class EntityPtdRefundComponent implements OnInit, OnDestroy {
  title: string;

  _label: 'REFUND' | 'REVERSE' = 'REFUND';
  @Input() set label(value: 'REFUND' | 'REVERSE') {
    this._label = value;
    if (value) {
      let buttonLabel;
      if (value === 'REFUND') {
        this.title = 'Refund Payment';
        buttonLabel = titleCaseClean(value);
      } else if (value === 'REVERSE') {
        this.title = 'Reverse Credit Allocation';
        buttonLabel = titleCaseClean(value);
      }

      this.entityPTDRefundState.next({
        ...this.entityPTDRefundState.value,
        buttonLabel: buttonLabel,
      });
    }
  }

  get label() {
    return this._label;
  }

  entityPTDRefundState: BehaviorSubject<EntityPTDRefundState> =
    new BehaviorSubject(<EntityPTDRefundState>{
      buttonLabel: 'Refund',
    });
  @Input() color = 'primary';

  @Output() cancelled = new EventEmitter();
  @Output() refunded = new EventEmitter();

  form: UntypedFormGroup;

  //SALE MODEL OR TRANSACTION PAYMENT MODEL
  _sale: any;
  @Input() set sale(value: any) {
    this._sale = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.entityPTDRefundState.next({
        ...this.entityPTDRefundState.value,
        issues: [],
      });

      if (
        value.paymentTransaction &&
        value.paymentTransaction.refundableAmount
      ) {
        this.form
          .get('refundAmount')
          .patchValue(value.paymentTransaction.refundableAmount);
      } else {
        this.form.get('refundAmount').patchValue(value.amount);
      }

      if (value.paymentType === 'CREDIT_INVOICE') {
        //MUST BE PAID IN FULL - DISABLED FOR THE USER
        this.form.get('refundAmount').disable();
        this.form.get('refundAmount').updateValueAndValidity();
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  get sale() {
    return this._sale;
  }

  _transaction: PaymentTransactionModel;
  @Input() set transaction(value: PaymentTransactionModel) {
    this._transaction = value;
    if (value) {
      this.buildForm();
      this.entityPTDRefundState.next({
        ...this.entityPTDRefundState.value,
        issues: [],
      });
      this.form.get('refundAmount').patchValue(value.refundableAmount);

      if (value.paymentType === 'CREDIT_INVOICE') {
        //MUST BE PAID IN FULL - DISABLED FOR THE USER
        this.form.get('refundAmount').disable();
        this.form.get('refundAmount').updateValueAndValidity();
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  get transaction() {
    return this._transaction;
  }

  _paymentTransactionGroup: PaymentTransactionGroup;
  @Input() set paymentTransactionGroup(value: PaymentTransactionGroup) {
    this._paymentTransactionGroup = value;
    if (value) {
      this.buildForm();
      this.entityPTDRefundState.next({
        ...this.entityPTDRefundState.value,
        issues: [],
      });
      this.form.get('refundAmount').patchValue(value.totalRefundableAmount);
      this.form
        .get('refundAmount')
        .setValidators([Validators.max(Number(value.totalRefundableAmount))]);
      this.form.get('refundAmount').updateValueAndValidity();
    }
  }

  get paymentTransactionGroup() {
    return this._paymentTransactionGroup;
  }

  @Input() driver: DriverModel;

  @Input() invoice: InvoiceModel;

  constructor(
    private saleApiService: SaleApiService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      reason: [null, [Validators.required]],
      refundAmount: [{ value: null }, [Validators.required]],
    });
  }

  refundPTD() {
    this.entityPTDRefundState.next({
      ...this.entityPTDRefundState.value,
      issues: [],
      loading: true,
    });

    const payload = {
      reason: this.form.value.reason,
      refundAmount: this.form.value.refundableAmount,
    } as any;

    //WHere am i getting the sale id from?
    let saleId;
    if (this.sale) {
      saleId = this.sale.saleId;
    } else if (this.transaction) {
      saleId = this.transaction.saleId;
    } else if (
      this.paymentTransactionGroup &&
      this.paymentTransactionGroup.purchaseTransactions &&
      this.paymentTransactionGroup.purchaseTransactions.length > 0
    ) {
      // Use the first APPROVED purchaseTransaction as there can be more than one and this is the only thing that can be refunded
      const approvedTransaction =
        this.paymentTransactionGroup.purchaseTransactions.find(
          (transaction) => transaction.paymentStatus === 'APPROVED'
        );
      if (approvedTransaction) {
        saleId = approvedTransaction.saleId;
        payload['paymentTransactionId'] =
          approvedTransaction.paymentTransactionId;
      }
    }

    this.saleApiService.refundSale(saleId, this.form.getRawValue()).subscribe({
      next: (resp: ApiResponse<any>) => {
        setTimeout(() => {
          //ACCOUNT IS SLIGHTLY SLOW THIS IS IMPORTANT TO WAIT
          this.entityPTDRefundState.next({
            ...this.entityPTDRefundState.value,
            loading: false,
          });
          this.refunded.emit(resp.data);
        }, 250);
      },
      error: (issues: IssueModel[]) => {
        this.entityPTDRefundState.next({
          ...this.entityPTDRefundState.value,
          loading: false,
          issues: issues,
        });
      },
    });
  }

  getState() {
    return this.entityPTDRefundState.asObservable();
  }

  ngOnDestroy(): void {
    this.entityPTDRefundState.next({
      ...this.entityPTDRefundState.value,
      loading: false,
      issues: [],
    });
  }
}
