import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodListComponent } from './payment-method-list.component';
import { PaymentMethodListItemModule } from '../payment-method-list-item/payment-method-list-item.module';

@NgModule({
  declarations: [PaymentMethodListComponent],
  imports: [CommonModule, PaymentMethodListItemModule],
  exports: [PaymentMethodListComponent],
})
export class PaymentMethodListModule {}
