import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductTypeSelectorComponent } from './product-type-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { MatListModule } from '@angular/material/list';
import { NoResultsLabelModule } from '@fleet/shared';

@NgModule({
  declarations: [ProductTypeSelectorComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TitleCaseAndCleanPipeModule,
    MatListModule,
    NoResultsLabelModule,
  ],
  exports: [ProductTypeSelectorComponent],
})
export class ProductTypeSelectorModule {}
