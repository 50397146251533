import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnregisteredTravellerLayoutComponent } from './unregistered-traveller-layout.component';
import { FuseLoadingBarModule } from '@fleet/fuse';
import { RouterModule } from '@angular/router';
import { OnscreenNotificationModule } from '@fleet/ui';

@NgModule({
  declarations: [UnregisteredTravellerLayoutComponent],
  imports: [
    CommonModule,
    FuseLoadingBarModule,
    RouterModule,
    OnscreenNotificationModule,
  ],
  exports: [UnregisteredTravellerLayoutComponent],
})
export class UnregisteredTravellerLayoutModule {}
