import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthService } from '@fleet/auth';
import { fuseAnimations } from '@fleet/fuse';
import { IssueModel } from '@fleet/model';

@Component({
  selector: 'fleet-change-password',
  templateUrl: './change-password.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class ChangePasswordComponent implements OnInit {
  loading = false;
  issues: IssueModel[];
  form: UntypedFormGroup;
  showCancel = false;

  @Output() changePasswordSuccess = new EventEmitter();

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  changePassword() {
    this.loading = true;
    this.issues = [];

    this.changeDetectorRef.markForCheck();

    this.authService
      .changePassword({ password: this.form.get('password').value })
      .subscribe({
        next: () => {
          this.loading = false;
          this.changePasswordSuccess.emit();
          this.changeDetectorRef.markForCheck();
        },
        error: (error: IssueModel[]) => {
          this.loading = false;
          this.issues = error;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  buildForm() {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: [''],
      },
      { validators: this.checkPasswords }
    );
  }

  checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  };

  cancel() {}
}
