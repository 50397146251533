import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ActionModel,
  ApiResponse,
  AssetGroupModel,
  AssetModel,
  AssetSkuModel,
  DocumentModel,
  OrderModel,
  ReturnAuthorisationModel,
  ReturnAuthorisationSearchModel,
  ReviewModel,
  StockMovementModel,
  SwapModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssetApiService {
  host;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.asset;
  }

  searchAssets(params: any) {
    // if (params.effectiveFrom != undefined && params.effectiveTo != undefined) {
    //   params.effectiveTo = new Date(params.effectiveTo).toISO();
    //   params.effectiveFrom = new Date(params.effectiveFrom).toISO();
    // }

    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchAssetGroups(params: any) {
    return this.http
      .get(`${this.host}/group`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  //@TODO: AssetReturnModel
  returnAsset(driverId: string, assetModel: any) {
    return this.http
      .post(`${this.host}/driver/${driverId}/return`, assetModel)
      .pipe(catchError(handleApiError));
  }

  //@TODO: AllocateAssetInfo
  allocateAsset(assetModel: any) {
    return this.http
      .post(`${this.host}/provision`, assetModel)
      .pipe(catchError(handleApiError));
  }

  // transferAsset(assetGroupId: string, transferModel: any[]) {
  //   return this.http
  //     .post(`${this.host}/group/${assetGroupId}/transfer`, transferModel)
  //     .pipe(catchError(handleApiError));
  // }

  // POST /api/v2/asset/asset/{assetId}/document/{documentId}

  //OLD
  // processDocument(assetId: string, documentId: string) {
  //   return this.http
  //     .post(`${this.host}/${assetId}/document/${documentId}`, {})
  //     .pipe(catchError(handleApiError));
  // }

  processDocument(assetReturnAuthorisationId: string, documentId: string) {
    return this.http
      .post(
        `${this.host}/returnAuthorisation/${assetReturnAuthorisationId}/document/${documentId}`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  //OLD
  //    @RequestMapping( value = "/asset/{assetId}/document", method = RequestMethod.GET )
  // searchDocuments(assetId: string, params) {
  //   let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
  //   Object.keys(params).forEach((key) => {
  //     httpParams = httpParams.set(key, params[key]);
  //   });

  //   return this.http
  //     .get(`${this.host}/returnAuthorisation/document`, {
  //       observe: 'response',
  //       params: encodeParams(params),
  //     })
  //     .pipe(catchError(handleApiError));
  // }

  searchDocuments(
    returnAuthorisationId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<DocumentModel[]>>> | any {
    return this.http
      .get(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}/document`,
        {
          observe: 'response',
          params: encodeParams(params),
        }
      )
      .pipe(catchError(handleApiError));
  }

  // // /asset/driver/{driverId}/exchange"
  // exchangeAsset(driverId, exchangeModel: AssetExchangeModel) {
  //   return this.http
  //     .post(`${this.host}/driver/${driverId}/exchange`, exchangeModel)
  //     .pipe(catchError(handleApiError));
  // }

  //OLD JUST LEAVING HERE

  /**
   *
   *  ASSET GET CALL - does not exist
   */
  getAsset(assetId: string) {
    return this.http
      .get(`${this.host}/${assetId}`, {})
      .pipe(catchError(handleApiError));
  }

  createAsset(asset: AssetModel): Observable<any> {
    return this.http
      .post(`${this.host}`, asset, {})
      .pipe(catchError(handleApiError));
  }

  updateAsset(asset: AssetModel) {
    return this.http
      .post(`${this.host}`, asset, {})
      .pipe(catchError(handleApiError));
  }

  deleteAsset(assetId: string) {
    return this.http
      .delete(`${this.host}/${assetId}`)
      .pipe(catchError(handleApiError));
  }

  deleteShipment(shipmentId: string) {
    return this.http
      .delete(`${this.host}/shipment/${shipmentId}`)
      .pipe(catchError(handleApiError));
  }

  orderAsset(orderModel: OrderModel) {
    return this.http
      .post(`${this.host}/order`, orderModel)
      .pipe(catchError(handleApiError));
  }

  createShipment(shipment: AssetGroupModel) {
    return this.http
      .post(`${this.host}/shipment`, shipment)
      .pipe(catchError(handleApiError));
  }

  closeShipment(shipmentId: string) {
    return this.http
      .post(`${this.host}/shipment/${shipmentId}/close`, {})
      .pipe(catchError(handleApiError));
  }

  updateShipment(shipment: AssetGroupModel) {
    return this.http
      .put(`${this.host}/shipment/${shipment.assetGroupId}`, shipment)
      .pipe(catchError(handleApiError));
  }

  activateAsset(assetId: string) {
    return this.http
      .put(`${this.host}/${assetId}/activate`, {})
      .pipe(catchError(handleApiError));
  }

  updateOrder(assetGroupId: string, siteLocation: string) {
    return this.http
      .put(`${this.host}/order/${assetGroupId}`, { orderType: siteLocation })
      .pipe(catchError(handleApiError));
  }

  startReturnAuthorisationReview(
    returnAuthorisationId: string,
    reviewId: string
  ) {
    return this.http
      .post(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}/review/${reviewId}/start`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  searchReturnAuthorisations(
    params: any
  ): Observable<
    HttpResponse<ApiResponse<ReturnAuthorisationSearchModel[]>> | any
  > {
    return this.http
      .get(`${this.host}/returnAuthorisation`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  submitReturnAuthorisation(
    returnAuthorisationModel: ReturnAuthorisationModel
  ) {
    return this.http
      .post(
        `${this.host}/returnAuthorisation/${returnAuthorisationModel.assetReturnAuthorisationId}/submit`,
        returnAuthorisationModel
      )
      .pipe(catchError(handleApiError));
  }

  submitReturnAuthorisationReview(
    returnAuthorisationId: string,
    reviewId: string
  ): Observable<ReviewModel | any> {
    return this.http
      .post(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}/review/${reviewId}/submit`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  updateReturnAuthorisation(
    returnAuthorisationId: string,
    returnAuthorisationModel: ReturnAuthorisationModel
  ) {
    return this.http
      .put(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}`,
        returnAuthorisationModel
      )
      .pipe(catchError(handleApiError));
  }

  getReturnAuthorisation(
    returnAuthorisationId: string
  ): Observable<ReturnAuthorisationModel | any> {
    return this.http
      .get(`${this.host}/returnAuthorisation/${returnAuthorisationId}`, {})
      .pipe(catchError(handleApiError));
  }

  approveReview(returnAuthorisationId: string, reviewId: string) {
    return this.http
      .post(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}/review/${reviewId}/approve`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  // reportLostAsset(driverId, assetId, payload: ReturnAuthorisationModel) {
  //   return this.http
  //     .post(`${this.host}/${driverId}/asset/${assetId}/lost`, { payload })
  //     .pipe(catchError(handleApiError));
  // }

  createAction(
    returnAuthorisationId: string,
    notificationId: string,
    actionModel: ActionModel
  ) {
    ///returnAuthorisation/{returnAuthorisationId}/notification/{notificationId}/action
    return this.http
      .post(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}/notification/${notificationId}/action`,
        actionModel
      )
      .pipe(catchError(handleApiError));
  }

  rejectReturnAuthorisationReview(
    returnAuthorisationId: string,
    reviewId: string
  ) {
    return this.http
      .post(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}/review/${reviewId}/reject`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  getOrder(orderId: string) {
    return this.http
      .get(`${this.host}/order/${orderId}`, {})
      .pipe(catchError(handleApiError));
  }

  updateAction(
    returnAuthorisationId: string,
    notificationId: string,
    actionId: string,
    payload: any
  ) {
    return this.http
      .put(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}/notification/${notificationId}/action/${actionId}`,
        payload
      )
      .pipe(catchError(handleApiError));
  }

  addAssetsToShipment(shipmentId: string, assetsToAdd: string[]) {
    return this.http
      .post(`${this.host}/shipment/${shipmentId}/add`, assetsToAdd)
      .pipe(catchError(handleApiError));
  }

  removeAssetsFromShipment(shipmentId: string, assetsToRemove: string[]) {
    return this.http
      .post(`${this.host}/shipment/${shipmentId}/remove`, assetsToRemove)
      .pipe(catchError(handleApiError));
  }

  verifyAsset(shipmentId: string, assetId: string) {
    return this.http
      .post(`${this.host}/shipment/${shipmentId}/asset/${assetId}/verify`, {})
      .pipe(catchError(handleApiError));
  }

  finaliseShipment(shipmentId: string) {
    return this.http
      .post(`${this.host}/shipment/${shipmentId}/finalise`, {})
      .pipe(catchError(handleApiError));
  }

  collectShipment(shipmentId: string) {
    return this.http
      .post(`${this.host}/shipment/${shipmentId}/collect`, {})
      .pipe(catchError(handleApiError));
  }

  getShipment(shipmentId: string) {
    return this.http
      .get(`${this.host}/shipment/${shipmentId}`)
      .pipe(catchError(handleApiError));
  }

  processUploadedDocument(returnAuthorisationId: string, documentId: string) {
    return this.http
      .post(
        `${this.host}/returnAuthorisation/${returnAuthorisationId}/document/${documentId}`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  swapAsset(assetId: string, swap: SwapModel) {
    return this.http
      .post(`${this.host}/${assetId}/swap`, swap)
      .pipe(catchError(handleApiError));
  }

  moveToRepairs(assetId: string, movement: StockMovementModel) {
    return this.http
      .post(`${this.host}/${assetId}/moveToRepairs`, movement)
      .pipe(catchError(handleApiError));
  }

  moveToStock(assetId: string, movement: StockMovementModel) {
    return this.http
      .post(`${this.host}/${assetId}/moveToStock`, movement)
      .pipe(catchError(handleApiError));
  }

  searchSKU(
    params: any
  ): Observable<HttpResponse<ApiResponse<AssetSkuModel[]>> | any> {
    return this.http
      .get(`${this.host}/sku`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
}
