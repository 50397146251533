import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FuseCardModule } from '@fleet/fuse';
import { StatusChipModule } from '@fleet/ui';
import { DriverCardComponent } from './driver-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [DriverCardComponent],
  imports: [
    CommonModule,
    StatusChipModule,
    MatIconModule,
    FuseCardModule,
    MatTooltipModule,
  ],
  exports: [DriverCardComponent],
})
export class DriverCardModule {}
