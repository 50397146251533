import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { JobDetailModel, JobSearchResultModel } from '@fleet/model';

@Component({
  selector: 'fleet-source',
  templateUrl: './source.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SourceComponent {
  @Input() mode = 'ICONS_ONLY';
  @Input() iconSize = 'icon-size-5';
  @Input() set jobDetail(
    value: JobDetailModel | { productType: string; channelType: string }
  ) {
    if (value) {
      this.generateIcon(value);
    }
  }
  @Input() set jobSearchResult(value: JobSearchResultModel) {
    if (value) {
      this.generateIcon(value);
    }
  }

  icons: {
    productTypeIcon: string;
    productTypeLabel: string;
    channelTypeIcon: string;
    channelTypeLabel: string;
  };
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  generateIcon(
    value:
      | JobDetailModel
      | { productType: string; channelType: string }
      | JobSearchResultModel
  ) {
    this.icons = {
      productTypeIcon: '',
      channelTypeIcon: '',
      productTypeLabel: '',
      channelTypeLabel: '',
    } as any;

    if (value && value.productType) {
      switch (value.productType) {
        case 'TRAVELLER':
          this.icons.productTypeIcon = 'hail';
          this.icons.productTypeLabel = 'TRAVELLER';

          break;
        case 'CONTACT_CENTRE_AGENT':
          this.icons.productTypeIcon = 'support_agent';
          this.icons.productTypeLabel = 'CONTACT_CENTRE_AGENT';

          break;

        case 'CONTACT_CENTRE_IVR':
          this.icons.productTypeIcon = 'call';
          this.icons.productTypeLabel = 'CONTACT_CENTRE_IVR';

          break;

        case 'DRIVER':
          this.icons.productTypeIcon = 'local_taxi';
          this.icons.productTypeLabel = 'DRIVER';

          break;

        case 'NETCABS_VDS':
          this.icons.productTypeIcon = 'local_taxi';
          this.icons.productTypeLabel = 'VDS';

          break;

        case 'BUSINESS':
          this.icons.productTypeIcon = 'corporate_fare';
          this.icons.productTypeLabel = 'BUSINESS';

          break;

        case 'PRESTO_STANDALONE_PLUS':
          this.icons.productTypeIcon = 'local_taxi';
          this.icons.productTypeLabel = 'PRESTO_STANDALONE_PLUS';

          break;

        case 'TAXI_BUTLER':
          this.icons.productTypeIcon = 'doorbell';
          this.icons.productTypeLabel = 'TAXI_BUTLER';

          break;

        case 'HUB':
          this.icons.productTypeIcon = 'hub';
          this.icons.productTypeLabel = 'HUB';

          break;

        default:
          this.icons.productTypeIcon = '';
          this.icons.productTypeLabel = '';

          break;
      }
    }

    if (value && value.channelType) {
      switch (value.channelType) {
        case 'APP':
          this.icons.channelTypeIcon = 'phone_iphone';
          this.icons.channelTypeLabel = 'APP';
          break;

        case 'WEB':
          this.icons.channelTypeIcon = 'public';
          this.icons.channelTypeLabel = 'WEB';

          break;

        case 'API':
          this.icons.channelTypeIcon = 'api';
          this.icons.channelTypeLabel = 'API';

          break;

        case 'SERVICE':
          this.icons.channelTypeIcon = 'settings_suggest';
          this.icons.channelTypeLabel = 'SERVICE';

          break;

        case 'TEST':
          this.icons.channelTypeIcon = 'device_unknown';
          this.icons.channelTypeLabel = 'TEST';

          break;

        default:
          this.icons.channelTypeIcon = '';
          this.icons.channelTypeLabel = '';

          break;
      }
    }
    this.changeDetectorRef.markForCheck();
  }
}
