<ng-container *ngIf="viewMode === 'SELECT'">
  <!-- <ng-container *ngIf="!multiSelector; else multiple"> -->
  <mat-form-field class="w-full" [ngClass]="classes">
    <mat-label
      *ngIf="label"
      [ngClass]="[matLabelClasses ? matLabelClasses : 'text-sm font-light']"
      >{{ label }}
    </mat-label>

    <mat-select
      [multiple]="multiSelector"
      [placeholder]="placeholder"
      [formControl]="control"
    >
      <mat-option
        *ngFor="let service of networkServices"
        [value]="service.networkServiceId"
      >
        {{ service.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="viewMode === 'LIST'">
  <mat-selection-list [formControl]="control" class="grid grid-cols-2">
    <mat-list-option
      [disabled]="control.disabled"
      class="text-md font-medium"
      checkboxPosition="before"
      *ngFor="let service of networkServices"
      [value]="service.networkServiceId"
    >
      {{ service.displayName }}
    </mat-list-option>
  </mat-selection-list>
</ng-container>
