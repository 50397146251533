import { Injectable } from '@angular/core';
import { OrganisationApiService } from '@fleet/api';

import {
  ApiResponse,
  BatchExportModel,
  IssueModel,
  TransactionExportCriteriaModel,
  JobExportSearchCriteriaModel,
  OrganisationStaffModel,
  OrganisationStaffExportSearchCriteriaModel,
  OrganisationTransactionApprovalSearchModel,
} from '@fleet/model';
import { OnscreenNotificationService } from '@fleet/ui';
import { failureNotification, pollWhile } from '@fleet/utilities';
import { BehaviorSubject, forkJoin, merge, timer } from 'rxjs';
import {
  concatMap,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

export interface ExportDisplay {
  export: BatchExportModel;
  organisationId?: string;
  name: string;
  issues?: IssueModel[];
}

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  batchExports: BehaviorSubject<ExportDisplay[]> = new BehaviorSubject([]);
  //   [
  //   { name: 'blah', export: mockExportStatus.data as any } as ExportDisplay,
  // ]);
  polling: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private organisationApiService: OrganisationApiService,
    private onscreenNotificationService: OnscreenNotificationService
  ) {}

  get exports$() {
    return this.batchExports.asObservable();
  }

  exportOrganisationJobs(
    organisationId: string,
    jobExport: JobExportSearchCriteriaModel,
    name: string
  ) {
    this.organisationApiService
      .exportJobs(organisationId, jobExport)
      .subscribe({
        next: (resp: ApiResponse<BatchExportModel>) => {
          this.batchExports.next([
            ...this.batchExports.value,
            {
              export: resp.data,
              organisationId: organisationId,
              name: name,
            },
          ]);
          if (!this.polling.value) {
            this.watchExports().subscribe();
          }
        },
        error: (issues: IssueModel[]) => {
          this.onscreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Unable to export!',
            subTitle: 'Please try again',
          });
        },
      });
  }

  exportOrganisationTransactions(
    organisationId: string,
    transactionExport: TransactionExportCriteriaModel,
    name: string
  ) {
    this.organisationApiService
      .exportTransactions(organisationId, transactionExport)
      .subscribe({
        next: (resp: ApiResponse<BatchExportModel>) => {
          this.batchExports.next([
            ...this.batchExports.value,
            {
              export: resp.data,
              organisationId: organisationId,
              name: name,
            },
          ]);
          if (!this.polling.value) {
            this.watchExports().subscribe();
          }
        },
        error: (issues: IssueModel[]) => {
          this.onscreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Unable to export!',
            subTitle: 'Please try again',
          });
        },
      });
  }

  exportOrganisationTransactionApprovals(
    organisationId: string,
    transactionApproval: OrganisationTransactionApprovalSearchModel,
    name: string
  ) {
    this.organisationApiService
      .exportTransactionApprovals(organisationId, transactionApproval)
      .subscribe({
        next: (resp: ApiResponse<BatchExportModel>) => {
          this.batchExports.next([
            ...this.batchExports.value,
            {
              export: resp.data,
              organisationId: organisationId,
              name: name,
            },
          ]);
          if (!this.polling.value) {
            this.watchExports().subscribe();
          }
        },
        error: (issues: IssueModel[]) => {
          this.onscreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Unable to export!',
            subTitle: 'Please try again',
          });
        },
      });
  }

  exportOrganisationStaff(
    organisationId: string,
    organisationStaff: OrganisationStaffExportSearchCriteriaModel,
    name: string
  ) {
    this.organisationApiService
      .exportStaff(organisationId, organisationStaff)
      .subscribe({
        next: (resp: ApiResponse<BatchExportModel>) => {
          this.batchExports.next([
            ...this.batchExports.value,
            {
              export: resp.data,
              organisationId: organisationId,
              name: name,
            },
          ]);
          if (!this.polling.value) {
            this.watchExports().subscribe();
          }
        },
        error: (issues: IssueModel[]) => {
          this.onscreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Unable to export!',
            subTitle: 'Please try again',
          });
        },
      });
  }

  getExportStatus(ex: ExportDisplay) {
    if (ex.organisationId) {
      this.organisationApiService
        .exportStatus(ex.organisationId, ex.export.batchExportId)
        .subscribe({
          next: (resp: ApiResponse<BatchExportModel>) => {
            this.batchExports.next(
              this.batchExports.value.map((nex: ExportDisplay) => {
                if (nex.export.batchExportId === resp.data.batchExportId) {
                  return {
                    organisationId: ex.organisationId,
                    export: resp.data,
                  } as ExportDisplay;
                }
                return ex;
              })
            );
          },
        });
    }
  }

  startPolling() {
    return timer(3000, 3000).pipe(
      takeWhile(() => {
        const stillProgress =
          this.batchExports.value.filter(
            (s) =>
              s.export.status !== 'COMPLETED' && s.export.status !== 'FAILURE'
          ).length > 0;
        return stillProgress;
      }),
      switchMap((_) =>
        forkJoin(
          this.batchExports.value
            .filter(
              (s) =>
                s.export.status !== 'COMPLETED' && s.export.status !== 'FAILURE'
            )
            .map((ex: ExportDisplay) => {
              // if (ex.organisationId) {
              return this.organisationApiService.exportStatus(
                ex.organisationId,
                ex.export.batchExportId
              );
              // }
            })
        )
      )
    );
  }

  watchExports() {
    return this.startPolling().pipe(
      tap((resps: any) => {
        this.polling.next(true);
        this.batchExports.next(
          this.batchExports.value.map((s) => {
            const found = resps.find(
              (resp: ApiResponse<BatchExportModel>) =>
                resp.data.batchExportId === s.export.batchExportId
            );
            if (found) {
              return { ...s, export: found.data };
            }
            return s;
          })
        );
      }),

      // stop polling on either button click or change of categories
      finalize(() => this.polling.next(null))
    );
  }

  clearExports() {
    this.batchExports.next([]);
  }
}

// export const mockExportStatus = {
//   uuid: '1502aaec-399d-45d1-b669-14237ab67e59',
//   timestamp: '2022-08-30T23:21:57.332Z',
//   status: { code: 200, success: true },
//   type: 'BatchExportModel',
//   data: {
//     batchExportId: 'a08189afd',
//     type: 'BOOKING',
//     documentId: 'de3ae513ce34930a13fb4e357b606905',
//     s3Url:
//       'https://ingogoaws-external.s3.ap-southeast-2.amazonaws.com/documents/local/organisationExport/de3ae513ce34930a13fb4e357b606905/organisationBookingExport.xlsx?response-content-disposition=inline%3B%20filename%3D%22organisationBookingExport-20220830-232154.xlsx%22&response-content-type=application%2Fxlsx&AWSAccessKeyId=AKIAIK5NU5S2CSI4FWXA&Expires=1664493715&Signature=C79ayrC5fcOnZnBOAnWhHyGWFfE%3D',
//     status: 'COMPLETEDD',
//     percentComplete: 0,
//     processedItems: 0,
//     effectiveFrom: '2022-08-30T23:21:54.273Z',
//     effectiveTo: '2022-09-29T23:21:55.034Z',
//   },
// };
