// tailwind-util.ts

import chroma from 'chroma-js';

import {
  forEach,
  fromPairs,
  isPlainObject,
  isString,
  map,
  mergeWith,
  pick,
} from 'lodash';

// export function updateCSSThemeVariables(theme: TailwindTheme, document: Document) {
//   for (const [name, color] of Object.entries(theme)) {
//     const palette = computeColorPalette(color:any);
//     for (const variant of palette) {
//       document.documentElement.style.setProperty(
//         `--${name}-${variant.name}`,
//         variant.hex
//       );
//     }
//   }
// }

export const generatePalette = (config: any) => {
  // Prepare an empty palette
  const palette = {
    50: null,
    100: null,
    200: null,
    300: null,
    400: null,
    500: null,
    600: null,
    700: null,
    800: null,
    900: null,
  } as any;

  // If a single color is provided,
  // assign it to the 500
  if (isString(config)) {
    palette[500] = chroma.valid(config) ? config : null;
  }

  // If a partial palette is provided,
  // assign the values
  if (isPlainObject(config)) {
    if (!chroma.valid(config[500])) {
      throw new Error(
        'You must have a 500 hue in your palette configuration! Make sure the main color of your palette is marked as 500.'
      );
    }

    // Remove everything that is not a hue/color entry
    config = pick(config, Object.keys(palette));

    // Merge the values
    mergeWith(palette, config, (objValue, srcValue) =>
      chroma.valid(srcValue) ? srcValue : null
    );
  }

  // Prepare the colors array
  const colors = Object.values(palette).filter((color) => color) as any;

  // Generate a very dark and a very light versions of the
  // default color to use them as the boundary colors rather
  // than using pure white and pure black. This will stop
  // in between colors' hue values to slipping into the grays.
  colors.unshift(
    chroma
      .scale(['white', palette[500]])
      .domain([0, 1])
      .mode('lrgb')
      .colors(50)[1]
  );
  colors.push(
    chroma
      .scale(['black', palette[500]])
      .domain([0, 1])
      .mode('lrgb')
      .colors(10)[1]
  );

  // Prepare the domains array
  const domain = [
    0,
    ...Object.entries(palette)
      .filter(([key, value]) => value)
      .map(([key]) => parseInt(key) / 1000),
    1,
  ];

  // Generate the color scale
  const scale = chroma.scale(colors).domain(domain).mode('lrgb');

  // Build and return the final palette
  return {
    50: scale(0.05).hex(),
    100: scale(0.1).hex(),
    200: scale(0.2).hex(),
    300: scale(0.3).hex(),
    400: scale(0.4).hex(),
    500: scale(0.5).hex(),
    600: scale(0.6).hex(),
    700: scale(0.7).hex(),
    800: scale(0.8).hex(),
    900: scale(0.9).hex(),
  };
};

export const generateContrasts = (palette: any) => {
  const lightColor = '#FFFFFF';
  let darkColor = '#FFFFFF';

  // Iterate through the palette to find the darkest color
  forEach(palette, (color) => {
    darkColor =
      chroma.contrast(color, '#FFFFFF') > chroma.contrast(darkColor, '#FFFFFF')
        ? color
        : darkColor;
  });

  // Generate the contrasting colors
  return fromPairs(
    map(palette, (color, hue) => [
      hue,
      chroma.contrast(color, darkColor) > chroma.contrast(color, lightColor)
        ? darkColor
        : lightColor,
    ])
  );
};

export const flattenColorPalette = (
  colors: { [key: string]: any } | null | undefined
): { [key: string]: string } => {
  const entries = Object.entries(colors || {}) as any;
  const flattenedEntries = entries.flatMap(([color, values]: any) => {
    if (typeof values === 'object') {
      return Object.entries(flattenColorPalette(values)).map(
        ([number, hex]) => {
          const colorKey = color + (number === 'DEFAULT' ? '' : `-${number}`);
          return { [colorKey]: hex };
        }
      );
    }
    return [{ [color]: values }];
  });
  return Object.assign({}, ...flattenedEntries);
};
