import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { OrganisationApiService, TravellerApiService } from '@fleet/api';
import {
  ApiResponse,
  CreditCardModel,
  IssueModel,
  JobOrganisationModel,
  JobPaymentMethodModel,
  JobTravellerModel,
  LocationModel,
  OrganisationGroupSearchResultModel,
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
  PaymentMethodTypes,
  TravellerModel,
  TravellerProfileModel,
} from '@fleet/model';
import { ProductConfigurationService } from '@fleet/product-configuration';

import { Observable, Subscription, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentOptionDisplay } from './job-payment-method-selector.model';
import { hasRequiredValidator } from '@fleet/utilities';

import { DateTime } from 'luxon';

export const payInVehiclePaymentOption: PaymentOptionDisplay = {
  icon: 'attach_money',
  displayName: 'Pay Driver',
  type: 'OTHER',
  jobPaymentMethod: { type: 'OTHER' } as JobPaymentMethodModel,
};

@Component({
  selector: 'fleet-job-payment-method-selector',
  templateUrl: './job-payment-method-selector.component.html',
  styleUrls: ['./job-payment-method-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class JobPaymentMethodSelectorComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  private _unsubscribeAll: Subject<any> = new Subject();
  payInVehicleOption: PaymentOptionDisplay = {
    icon: 'attach_money',
    displayName: 'Pay Driver',
    type: 'OTHER',
    jobPaymentMethod: { type: 'OTHER' } as JobPaymentMethodModel,
  };
  paymentControl = new UntypedFormControl(null);
  loading = false;
  issues: IssueModel[];
  @ViewChild('paymentList') paymentList: MatSelectionList;
  hostFocused = false;
  @Input() title: string;
  hostRef: ElementRef;
  showList = false;
  forceSelection: boolean;
  salesType: 'primary' | 'secondary';
  showRequired: boolean;
  payments: PaymentOptionDisplay[] = [];
  fleetProduct: string;
  travellerPayments: PaymentMethodSearchResultModel[];
  currentProfile: TravellerProfileModel;
  payInVehicleWithGroupOption: PaymentOptionDisplay;
  @Input() setDefaultOrganisationPaymentMethod = false;

  private jobConfigSubject = new Subject<{
    jobTraveller: JobTravellerModel;
    traveller: TravellerModel;
    jobOrganisation: JobOrganisationModel;
    jobPaymentMethod: JobPaymentMethodModel;
  }>();

  private jobConfigSubscription: Subscription;
  _jobConfig: any;
  @Input() set jobConfig(value: {
    jobTraveller: JobTravellerModel;
    traveller: TravellerModel;
    jobOrganisation: JobOrganisationModel;
    jobPaymentMethod: JobPaymentMethodModel;
  }) {
    this.jobConfigSubject.next(value);
  }

  get jobConfig() {
    return this._jobConfig;
  }

  get isOrgProfile$() {
    return this.jobConfigSubject.pipe(
      map((config: any) => {
        const profile = this.findCurrentProfile();
        if (profile.type === 'ORGANISATION') {
          return profile;
        }
        return null;
      })
    );
  }

  updatingInternally = false;

  @Input() endLocation: LocationModel;
  @Input() startTime: DateTime;

  @Output() groupRequiredOnPayDriverChange = new EventEmitter();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private organisationApiService: OrganisationApiService,
    private travellerApiService: TravellerApiService,
    private productConfigurationService: ProductConfigurationService,
    @Inject('env') env: any,
    @Self() public ngControl: NgControl
  ) {
    this.fleetProduct = env.fleetProduct;
    ngControl.valueAccessor = this;
    this.jobConfigSubscription = this.jobConfigSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe((value) => {
        this._jobConfig = value;
        this.payments = [];
        this.travellerPayments = [];

        this.currentProfile = this.findCurrentProfile();

        if (
          (value?.jobOrganisation?.organisationUserId &&
            value?.jobOrganisation?.organisationGroupId) ||
          (value.traveller?.travellerId &&
            (value?.traveller?.status === 'UNREGISTERED' ||
              ((value?.traveller?.personalProfile ||
                value.traveller?.organisationProfiles?.length > 0) &&
                value?.jobTraveller?.travellerProfileId)))
        ) {
          //we are searching for  payment methods or groups

          this.loadPaymentMethods();
        } else {
          //set pay driver
          this.payInVehicleOption = payInVehiclePaymentOption;
          this.paymentControl.setValue({ type: 'OTHER' });
          this.changeDetectorRef.markForCheck();
        }

        if (
          this.jobConfig.jobPaymentMethod?.type === 'OTHER' &&
          this.currentProfile?.groupPaymentOptions?.length > 0
        ) {
          this.groupRequiredOnPayDriverChange.emit(true);
        } else {
          this.groupRequiredOnPayDriverChange.emit(false);
        }
      });
  }

  ngOnInit(): void {
    //VALIDATOR SYNC
    this.ngControl.control.statusChanges.subscribe((status: string) => {
      this.paymentControl.setValidators(this.ngControl.control.validator);

      if (hasRequiredValidator(this.ngControl.control)) {
        const currentValidators = this.ngControl.control.validator;
        const newValidators = Array.isArray(currentValidators)
          ? [...currentValidators, Validators.required]
          : [currentValidators, Validators.required];

        this.paymentControl.setValidators(newValidators);
      }
      if (this.ngControl.touched) {
        this.paymentControl.markAsTouched();
        this.paymentControl.updateValueAndValidity({
          emitEvent: false,
        });
      }

      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });

    this.paymentControl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (jobPayment: JobPaymentMethodModel) => {
          this.onChange(jobPayment);
          this.onTouched();

          this.updatingInternally = false;
        },
      });
  }

  @HostListener('focus')
  onFocus() {
    this.hostFocused = true;
  }

  @HostListener('blur', ['$event.target'])
  onBlur() {
    this.hostFocused = false;
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.toggleList();
    }
  }

  loadPaymentMethods() {
    this.searchPaymentMethods().subscribe({
      next: (payments: PaymentOptionDisplay[]) => {
        this.loading = false;

        const profile = this.findCurrentProfile();
        if (
          profile?.organisationId &&
          !this.jobConfig.jobOrganisation?.organisationUserId
        ) {
          // we are in traveller context, lets exclude searched payment methods and just rely on profile
        } else {
          this.payments = [...payments];
          this.changeDetectorRef.markForCheck();
        }
        // check if profile and add payment methods and check
        if (profile) {
          if (profile.groupPaymentOptions) {
            profile.groupPaymentOptions.forEach(
              (paymentMethod: PaymentMethodModel) => {
                if (!paymentMethod.type) {
                  paymentMethod.type = 'ORGANISATION_ACCOUNT';
                }
                //ensure it has a payment method id for display in hub or business but show all if traveller portal
                if (
                  paymentMethod?.paymentMethodId ||
                  this.fleetProduct === 'TRAVELLER' ||
                  (this.fleetProduct === 'HUB' &&
                    !this.jobConfig.jobOrganisation?.organisationUserId)
                ) {
                  //find existing
                  const existingIndex = this.payments.findIndex(
                    (s) =>
                      s.paymentMethod?.paymentMethodId ===
                        paymentMethod.paymentMethodId ||
                      s.jobPaymentMethod?.paymentMethodId ===
                        paymentMethod.paymentMethodId
                  );
                  const paymentDisplay =
                    this.paymentOptionDisplayFromPaymentMethod(
                      paymentMethod,
                      profile
                    );
                  if (existingIndex !== -1) {
                    // Replace existing group payment method
                    this.payments[existingIndex] = paymentDisplay;
                  } else {
                    // Add new group payment method
                    this.payments.push(paymentDisplay);
                  }
                }
              }
            );
          }
        }

        // if (!this.paymentControl.value) {
        //lets check if the jobs payment method is currently available otherwise we will set the default or show list
        if (this.jobConfig.jobPaymentMethod?.type === 'OTHER') {
          this.payInVehicleWithGroupOption = null;
          if (
            this.jobConfig.jobPaymentMethod.organisationGroupId &&
            this.currentProfile?.type === 'ORGANISATION'
          ) {
            const foundGroup = this.currentProfile?.groupPaymentOptions.find(
              (g: PaymentMethodModel) =>
                g.organisationGroupId ===
                this.jobConfig.jobPaymentMethod.organisationGroupId
            );
            if (foundGroup) {
              this.setPayDriverOnGroup(foundGroup);
            }
          } else {
            this.paymentControl.setValue(
              payInVehiclePaymentOption.jobPaymentMethod
            );
          }
        } else if (this.jobConfig.jobPaymentMethod?.paymentMethodId) {
          const foundJobPaymentMethod = this.payments.find(
            (s) =>
              s.jobPaymentMethod.paymentMethodId ===
              this.jobConfig.jobPaymentMethod?.paymentMethodId
          );
          if (!foundJobPaymentMethod) {
            //not found so lets set default if profile
            if (
              this.jobConfig.jobTraveller?.travellerProfileId &&
              this.jobConfig.traveller
            ) {
              const profile = this.findCurrentProfile();
              if (profile?.defaultPaymentOption) {
                // set the defaul
                this.paymentControl.setValue({
                  profileId: profile.travellerProfileId,
                  paymentMethodId: profile.defaultPaymentOption.paymentMethodId,
                } as JobPaymentMethodModel);
              } else {
                //the profile doesnt have a default payment option
                //clear and
                //check if one payment method if personal
                if (this.payments.length === 1) {
                  if (profile?.type === 'PERSONAL') {
                    // just one payment lets set it
                    this.paymentControl.setValue(
                      this.payments[0].jobPaymentMethod
                    );
                  } else {
                    this.paymentControl.reset();
                    this.showList = true;
                  }
                } else if (this.payments.length > 0) {
                  this.showList = true;
                  this.paymentControl.reset();
                } else {
                  this.paymentControl.reset();
                }
              }
            } else {
              //no profile, check if org user id and set default org
              if (
                this.jobConfig.jobOrganisation.organisationUserId &&
                this.setDefaultOrganisationPaymentMethod &&
                this.payments.length > 0
              ) {
                this.paymentControl.setValue(this.payments[0].jobPaymentMethod);
              } else if (this.payments.length > 0) {
                this.showList;
                this.paymentControl.setValue(null);
              } else {
                //clear if not found
                this.paymentControl.setValue(null);
              }
            }
          } else {
            //found payment in search result
            if (
              this.paymentControl.value?.paymentMethodId ===
              foundJobPaymentMethod.jobPaymentMethod.paymentMethodId
            ) {
              //its already set, do nothing
              this.paymentControl.setValue(
                foundJobPaymentMethod.jobPaymentMethod,
                { emitEvent: false }
              );
              //this.changeDetectorRef.markForCheck();
            } else {
              this.paymentControl.setValue(
                foundJobPaymentMethod.jobPaymentMethod
              );
            }
          }
        } else if (
          profile?.defaultPaymentOption &&
          profile?.type === 'PERSONAL'
        ) {
          //no payment method, but there is a profile default lets set that
          this.paymentControl.setValue({
            profileId: profile.travellerProfileId,
            paymentMethodId: profile.defaultPaymentOption?.paymentMethodId,
          } as JobPaymentMethodModel);
          this.showList = false;
        } else if (
          profile?.groupPaymentOptions?.length === 1 &&
          !this.jobConfig.jobOrganisation?.organisationUserId
        ) {
          const orgGroupJobPaymentMethod = {
            profileId: profile.travellerProfileId,
            paymentMethodId: profile.groupPaymentOptions[0].paymentMethodId
              ? profile.groupPaymentOptions[0].paymentMethodId
              : profile.defaultPaymentOption?.paymentMethodId
              ? profile.defaultPaymentOption?.paymentMethodId
              : null,
            organisationGroupId:
              profile.groupPaymentOptions[0].organisationGroupId,
          } as JobPaymentMethodModel;
          this.paymentControl.setValue(orgGroupJobPaymentMethod);
          this.showList = false;
        } else if (
          this.jobConfig.jobOrganisation?.organisationUserId &&
          this.setDefaultOrganisationPaymentMethod &&
          this.payments.length > 0
        ) {
          this.paymentControl.setValue(this.payments[0].jobPaymentMethod);
          this.showList = false;
        } else if (this.payments.length > 0) {
          //lets check payments and if only 1 set it under circumstance
          if (this.paymentControl.value) {
            const foundPayment = this.payments.find(
              (s) =>
                s.jobPaymentMethod.paymentMethodId ===
                this.paymentControl.value?.paymentMethodId
            );
            if (!foundPayment) {
              this.paymentControl.setValue(null);
              this.showList = true;
            }
          } else if (this.payments.length === 1) {
            if (profile?.type === 'PERSONAL') {
              // just one payment lets set it
              this.paymentControl.setValue(this.payments[0].jobPaymentMethod);
            } else {
              this.showList = true;
              this.paymentControl.setValue(null);
            }
          } else if (this.payments.length > 0) {
            this.showList = true;
            this.paymentControl.setValue(null);
          }
        } else {
          //no payments set in vehicle payement option
          this.paymentControl.setValue(
            payInVehiclePaymentOption.jobPaymentMethod
          );
        }

        this.changeDetectorRef.markForCheck();
      },
    });
  }

  findCurrentProfile() {
    if (!this.jobConfig.jobOrganisation?.organisationUserId) {
      if (
        this.jobConfig.traveller &&
        this.jobConfig.jobTraveller?.travellerProfileId
      ) {
        const avaiableProfiles = [
          ...(this.jobConfig.traveller.personalProfile
            ? [this.jobConfig.traveller.personalProfile]
            : []),
          ...(this.jobConfig.traveller.organisationProfiles
            ? this.jobConfig.traveller.organisationProfiles
            : []),
        ];
        const profile = avaiableProfiles.find(
          (p) =>
            p.travellerProfileId ===
            this.jobConfig.jobTraveller.travellerProfileId
        );
        return profile;
      }
      return null;
    }
    return null;
  }

  searchPaymentMethods(): Observable<PaymentOptionDisplay[]> {
    const apiCalls: Observable<HttpResponse<ApiResponse<any[]>>>[] = [];
    const params = { limit: 10, offset: 0, status: 'ACTIVE' } as any;

    //search is a group search under and authorised user
    if (this.jobConfig.jobOrganisation?.organisationUserId) {
      if (this.jobConfig.jobOrganisation?.organisationGroupId) {
        const groupParams = {
          ...params,
          organisationGroupId:
            this.jobConfig.jobOrganisation.organisationGroupId,
        };
        apiCalls.push(
          this.organisationApiService.searchGroups(
            groupParams,
            this.jobConfig.jobOrganisation.organisationId
          )
        );
      }

      // also search for the staff members payments
      if (this.jobConfig.jobTraveller?.organisationStaffId) {
        const staffParams = {
          ...params,
          organisationStaffId: this.jobConfig.jobTraveller.organisationStaffId,
        };
        apiCalls.push(
          this.organisationApiService.searchGroups(
            staffParams,
            this.jobConfig.jobOrganisation.organisationId
          )
        );
      }
    } else if (this.jobConfig.traveller?.travellerId) {
      apiCalls.push(
        this.travellerApiService.searchPaymentMethods(
          this.jobConfig.traveller.travellerId,
          params
        )
      );
    }

    this.loading = true;
    this.issues = [];
    this.changeDetectorRef.markForCheck();

    return forkJoin(apiCalls).pipe(
      tap((responses: HttpResponse<ApiResponse<any[]>>[]) => {
        if (this.fleetProduct === 'TRAVELLER') {
          this.travellerPayments = responses.flatMap((resp) => resp.body.data);
        }
      }),
      map((responses: HttpResponse<ApiResponse<any[]>>[]) => {
        const paymentMap = new Map<string, PaymentOptionDisplay>();

        responses.forEach((resp) => {
          resp.body.data.forEach((payment: any) => {
            let paymentOption: PaymentOptionDisplay;
            if (resp.url.includes('group')) {
              paymentOption =
                this.paymentOptionDisplayFromOrganisationGroupSearchResult(
                  payment
                );
            } else {
              paymentOption =
                this.paymentOptionDisplayFromPaymentMethodSearchResult(
                  payment,
                  this.jobConfig.jobOrganisation?.organisationGroupId,
                  this.jobConfig.traveller?.personalProfile?.travellerProfileId
                );
            }

            // Use organisationGroupId as the key if it exists, otherwise use paymentMethodId
            const key = resp.url.includes('group')
              ? `group-${paymentOption.jobPaymentMethod.organisationGroupId}`
              : `payment-${paymentOption.jobPaymentMethod.paymentMethodId}`;

            if (!paymentMap.has(key)) {
              paymentMap.set(key, paymentOption);
            }
          });
        });

        return Array.from(paymentMap.values());
      })
    );
  }

  searchGroups() {}

  handleNoPaymentMethods() {
    this.payments = [];
  }

  toggleList() {
    this.paymentControl.markAsTouched();
    this.onTouched();
    this.showList = !this.showList;
    if (this.showList) {
      this.updatingInternally = false;
      this.paymentControl.reset();
    }
    this.changeDetectorRef.markForCheck();

    if (this.showList && this.payments.length > 0) {
      setTimeout(() => {
        if (this.paymentControl.value && this.paymentList) {
          const selectedService = this.paymentList.options.find(
            (s) =>
              s.value.networkServiceId ==
              this.paymentControl.value.networkServiceId
          );
          if (selectedService) {
            selectedService.focus();
          } else if (this.paymentList) {
            this.paymentList.options.first.focus();
          }
        } else if (this.paymentList) {
          this.paymentList.options.first.focus();
        }
        this.changeDetectorRef.markForCheck();
      }, 100);
    }
  }

  writeValue(value: JobPaymentMethodModel): void {
    if (value) {
      this.paymentControl.setValue(value, { emitEvent: false });
      this.changeDetectorRef.markForCheck();
    }
  }
  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (this.paymentControl.disabled !== isDisabled) {
      if (isDisabled) {
        this.paymentControl.disable({ emitEvent: false, onlySelf: true });
      } else {
        this.paymentControl.enable({ emitEvent: false, onlySelf: true });
      }
      this.paymentControl.updateValueAndValidity();
      this.changeDetectorRef.markForCheck();
    }
  }

  paymentOptionSelected(paymentOption: PaymentOptionDisplay) {
    this.showList = false;
    if (
      paymentOption.jobPaymentMethod.type === 'OTHER' &&
      this.currentProfile?.groupPaymentOptions?.length > 0
    ) {
      this.groupRequiredOnPayDriverChange.emit(true);
    } else {
      this.groupRequiredOnPayDriverChange.emit(false);
    }

    this.paymentControl.setValue(paymentOption.jobPaymentMethod);

    this.paymentControl.updateValueAndValidity();
    this.changeDetectorRef.markForCheck();

    this.onTouched();
  }

  setPayDriverOnGroup(group: PaymentMethodModel) {
    this.paymentControl.setValue({
      ...this.paymentControl.value,
      type: 'OTHER',
      organisationGroupId: group.organisationGroupId,
    });
    this.currentProfile?.groupPaymentOptions.forEach(
      (paymentMethod: PaymentMethodModel) => {
        if (paymentMethod.organisationGroupId === group.organisationGroupId) {
          this.payInVehicleWithGroupOption = {
            ...this.payInVehicleOption,
            jobPaymentMethod: {
              type: 'OTHER',
              organisationGroupId: group.organisationGroupId,
            } as JobPaymentMethodModel,
            organisationProfileGroupWhenPayingDriver: {
              displayName: group.displayName,
            } as PaymentMethodModel,
          };
        }
      }
    );
    this.showList = false;
    this.changeDetectorRef.markForCheck();
  }

  setTravellerPaymentOnGroup(
    paymentMethod: PaymentMethodSearchResultModel,
    group: PaymentOptionDisplay
  ) {
    this.paymentControl.setValue({
      ...group.jobPaymentMethod,
      paymentMethodId: paymentMethod.paymentMethodId,
    });

    this.payments = this.payments.map((payment: PaymentOptionDisplay) => {
      if (
        payment.jobPaymentMethod.organisationGroupId ===
        group.jobPaymentMethod.organisationGroupId
      ) {
        return {
          ...payment,
          jobPaymentMethod: {
            ...payment.jobPaymentMethod,
            paymentMethodId: paymentMethod.paymentMethodId,
          },
          defaultPaymentMethodOnOrganisationProfile:
            this.paymentMethodFromPaymentMethodSearchResult(paymentMethod),
        };
      }
      return payment;
    }) as any;
    this.showList = false;
    this.changeDetectorRef.markForCheck();
  }

  paymentOptionDisplayFromJobPaymentMethodModel(
    paymentMethod: JobPaymentMethodModel
  ) {
    const icon = this.iconFromPaymentTypeAndCardType(
      paymentMethod.type,
      paymentMethod.cardType
    );
    return {
      paymentMethod: {
        paymentMethodId: paymentMethod.paymentMethodId,
      } as PaymentMethodModel,
      type: 'search-result',
      icon: icon,
      displayName: paymentMethod.cardType
        ? paymentMethod.description
        : paymentMethod.name,
      isOrganisationCard: paymentMethod.organisationGroupId ? true : false,
    } as PaymentOptionDisplay;
  }

  paymentMethodModelFromJobPaymentMethodModel(payment: JobPaymentMethodModel) {
    return {
      paymentMethodId: payment.paymentMethodId,
      displayName: payment.description,
      type: 'BRAINTREE',
      card: {
        cardType: payment.cardType,
      },
    } as PaymentMethodModel;
  }
  paymentOptionDisplayFromPaymentMethod(
    paymentMethod: PaymentMethodModel | any,
    profile?: TravellerProfileModel,
    organisationGroupId?: string,
    type?: string
  ): PaymentOptionDisplay {
    const icon = this.iconFromPaymentTypeAndCardType(
      paymentMethod.type,
      paymentMethod.cardType
        ? paymentMethod.cardType
        : paymentMethod.card?.cardType
    );

    return {
      paymentMethod: paymentMethod,
      type: 'search-result',
      icon: icon,
      displayName: paymentMethod.displayName,
      description: paymentMethod.card?.cardType
        ? paymentMethod.description
        : paymentMethod.name,
      cardType: paymentMethod.cardType
        ? paymentMethod.cardType
        : paymentMethod.card?.cardType,
      isOrganisationCard: paymentMethod.organisationId ? true : false,
      profileId: profile.travellerProfileId,
      defaultPaymentMethodOnOrganisationProfile:
        !paymentMethod.paymentMethodId &&
        profile.defaultPaymentOption &&
        profile.organisationId
          ? profile.defaultPaymentOption
          : null,
      jobPaymentMethod: {
        type: type,
        profileId: profile.travellerProfileId,
        organisationGroupId: organisationGroupId
          ? organisationGroupId
          : paymentMethod.organisationGroupId,
        paymentMethodId: paymentMethod.paymentMethodId
          ? paymentMethod.paymentMethodId
          : !paymentMethod.paymentMethodId &&
            profile.defaultPaymentOption &&
            profile.organisationId
          ? profile.defaultPaymentOption.paymentMethodId
          : null,
      } as JobPaymentMethodModel,
    } as PaymentOptionDisplay;
  }

  paymentOptionDisplayFromPaymentMethodSearchResult(
    paymentMethod: PaymentMethodSearchResultModel,
    organisationGroupId: string,
    profileId: string
  ): PaymentOptionDisplay {
    const icon = this.iconFromPaymentTypeAndCardType(
      paymentMethod.paymentType,
      paymentMethod.cardType
    );

    return {
      paymentMethod: paymentMethod,
      type: 'search-result',
      icon: icon,
      displayName: paymentMethod.displayName,
      description: paymentMethod.description,
      cardType: paymentMethod.cardType,
      isOrganisationCard: paymentMethod.organisationId ? true : false,
      jobPaymentMethod: {
        paymentMethodId: paymentMethod.paymentMethodId,
        organisationGroupId: organisationGroupId,
        profileId: profileId,
      },
    } as PaymentOptionDisplay;
  }

  paymentOptionDisplayFromOrganisationGroupSearchResult(
    group: OrganisationGroupSearchResultModel
  ) {
    const pod = {
      icon: 'card_travel',
      jobPaymentMethod: {
        paymentMethodId: group.paymentMethodId || null,
        organisationGroupId: group.organisationGroupId,
      },

      displayName: group.displayName,
    } as PaymentOptionDisplay;
    if (!group.paymentMethodId) {
      pod.jobPaymentMethod.type === 'OTHER';
    }
    return pod;
  }

  iconFromPaymentTypeAndCardType(paymentType: string, cardType?: string) {
    switch (paymentType) {
      case 'STORED_CARD':
      case PaymentMethodTypes.Braintree:
        cardType = cardType.toLowerCase();
        if (cardType === 'american express') {
          cardType = 'amex';
        }
        return 'creditcards:' + cardType;
      case PaymentMethodTypes.OrganisationAccount:
        return 'card_travel';
      default:
        return 'credit-card';
    }
  }

  paymentMethodFromPaymentMethodSearchResult(
    searchResult: PaymentMethodSearchResultModel
  ) {
    const paymentMethod = {
      paymentMethodId: searchResult.paymentMethodId,
      displayName: searchResult.displayName,
      description: searchResult.description,
      card: {
        cardType: searchResult.cardType,
        maskedCardNumber: searchResult.displayName,
      } as CreditCardModel,
    } as PaymentMethodModel;
    return paymentMethod;
  }

  ngOnDestroy(): void {
    this.jobConfigSubscription.unsubscribe();

    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
