import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-avatar',
  templateUrl: './avatar.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit {
  @Input() background = 'bg-gray-500';
  @Input() initials: string;
  @Input() image: string;
  @Input() size = 10;

  constructor() {}

  ngOnInit(): void {}
}
