import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { StatusChipModule } from '@fleet/ui';
import { TravellerStatusChangeModule } from '../traveller-status-change/traveller-status-change.module';
import { TravellerStatusActionPipe } from './traveller-status-action.pipe';
import { TravellerStatusComponent } from './traveller-status.component';

@NgModule({
  declarations: [TravellerStatusComponent, TravellerStatusActionPipe],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatIconModule,
    StatusChipModule,
    TitleCaseAndCleanPipeModule,
    TravellerStatusChangeModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MatSelectModule,
  ],
  exports: [TravellerStatusComponent, TravellerStatusActionPipe],
})
export class TravellerStatusModule {}
