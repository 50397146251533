import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobStatus',
})
export class JobStatusPipe implements PipeTransform {
  transform(action: string, inverse?: boolean): any {
    if (action) {
      switch (action) {
        case 'INITIAL':
          return inverse
            ? {
                icon: 'done',
                background: 'blue-700',
                text: 'blue-100',
              }
            : {
                icon: 'done',
                background: 'blue-100',
                text: 'blue-600',
              };

        case 'INVALID':
          return inverse
            ? { background: 'red-600', text: 'red-100', icon: 'warning' }
            : { background: 'red-100', text: 'red-700', icon: 'warning' };

        case 'ACKNOWLEDGEMENT_REQUIRED':
          return inverse
            ? {
                icon: 'block',
                background: 'yellow-700',
                text: 'yellow-100',
              }
            : {
                icon: 'block',
                background: 'yellow-100',
                text: 'yellow-700',
              };

        case 'BLOCKED':
          return inverse
            ? {
                icon: 'error',
                background: 'red-700',
                text: 'red-100',
              }
            : {
                icon: 'error',
                background: 'red-100',
                text: 'red-700',
              };

        case 'OPEN':
          return inverse
            ? {
                icon: 'circle',
                background: 'gray-700',
                text: 'gray-100',
              }
            : {
                icon: 'circle',
                background: 'gray-100',
                text: 'gray-700',
              };

        case 'DISPATCHED':
          return inverse
            ? {
                icon: 'info',
                background: 'blue-700',
                text: 'blue-100',
              }
            : {
                icon: 'info',
                background: 'blue-100',
                text: 'blue-700',
              };

        case 'ABANDONED':
          return inverse
            ? {
                icon: 'cancel',
                background: 'orange-700',
                text: 'orange-100',
              }
            : {
                icon: 'cancel',
                background: 'orange-100',
                text: 'orange-700',
              };

        case 'ASSIGNED':
          return inverse
            ? {
                icon: 'info',
                background: 'gray-700',
                text: 'gray-100',
              }
            : {
                icon: 'info',
                background: 'gray-100',
                text: 'gray-700',
              };

        case 'STARTED':
          return inverse
            ? {
                icon: 'info',
                background: 'blue-700',
                text: 'blue-100',
              }
            : {
                icon: 'info',
                background: 'blue-100',
                text: 'blue-700',
              };

        case 'IN_PROGRESS':
          return inverse
            ? {
                icon: 'done',
                background: 'pink-700',
                text: 'pink-100',
              }
            : {
                icon: 'done',
                background: 'pink-100',
                text: 'pink-700',
              };

        case 'FINISHED':
          return inverse
            ? {
                icon: 'check_circle',
                background: 'green-700',
                text: 'green-100',
              }
            : {
                icon: 'check_circle',
                background: 'green-100',
                text: 'green-700',
              };

        case 'CANCELLED':
          return inverse
            ? {
                icon: 'cancel',
                background: 'red-700',
                text: 'red-100',
              }
            : {
                icon: 'cancel',
                background: 'red-100',
                text: 'red-600',
              };

        case 'EXPIRED':
          return inverse
            ? {
                icon: 'error',
                background: 'gray-700',
                text: 'white',
              }
            : {
                icon: 'error',
                background: 'gray-100',
                text: 'gray-600',
              };

        default:
          return inverse
            ? {
                icon: 'info',
                background: 'blue-700',
                text: 'blue-100',
              }
            : {
                icon: 'info',
                background: 'blue-100',
                text: 'blue-700',
              };
      }
    }

    return null;
  }
}
