import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewWithLeftAndRightDrawerComponent } from './view-with-left-and-right-drawer.component';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [ViewWithLeftAndRightDrawerComponent],
  imports: [CommonModule, MatSidenavModule],
  exports: [ViewWithLeftAndRightDrawerComponent],
})
export class ViewWithLeftAndRightDrawerModule {}
