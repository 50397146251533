<ng-container *ngIf="viewMode === 'ICON'; else button">
  <mat-menu #actionMenu="matMenu" [overlapTrigger]="false">
    <ng-container *ngIf="job | jobAction: hideActions as jobActions">
      <button *ngFor="let action of jobActions" mat-menu-item (click)="click(action)">
        {{ action.label }}
      </button>

      <ng-container *ngIf="extraActions">
        <button *ngFor="let action of extraActions" mat-menu-item (click)="customAction.emit(action)">
          {{ action.label }}
        </button>

      </ng-container>

      <button *ngIf="jobActions.length === 0 && extraActions.length === 0" mat-menu-item disabled>
        No actions available
      </button>
    </ng-container>





  </mat-menu>

  <button mat-icon-button [matMenuTriggerFor]="actionMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-container>
<ng-template #button>
  <div *ngFor="let action of job | jobAction">
    <fleet-progress-button [disabled]="!action.enabled" class="w-full"
      [state]="{ loading: false, buttonLabel: action.label }" (onClick)="click(action)"></fleet-progress-button>
  </div>
</ng-template>
