import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KioskExitComponent } from '@fleet/kiosk';
import { ProductConfigurationService } from '@fleet/product-configuration';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fleet-kiosk-layout',
  templateUrl: './kiosk-layout.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskLayoutComponent implements OnInit, OnDestroy {
  logoSrc: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private productConfigurationService: ProductConfigurationService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    localStorage.setItem('kiosk_mode', 'true');
    this.productConfigurationService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (config: any) => {
          if (config.horizontalLogo) {
            this.logoSrc = config.horizontalLogo;
            this.changeDetectorRef.markForCheck();
          }
        },
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    localStorage.removeItem('kiosk_mode');
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  exitKioskMode() {
    const dialog = this.matDialog.open(KioskExitComponent);
    dialog.componentInstance.loginSuccess.subscribe({
      next: () => {
        //localStorage.removeItem('kiosk_mode');
        dialog.close();
      },
    });
  }
}
