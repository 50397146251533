import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlocklistAttributeCreateEditComponent } from './blocklist-attribute-create-edit.component';
import { ProgressButtonModule } from '@fleet/shared';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { ActionButtonsModule, DialogLayoutModule } from '@fleet/ui';
import { SidebarFormLayoutModule } from '@fleet/layout';
import { BlockActionSelectorModule } from '../block-action-selector/block-action-selector.module';
import { BlockReasonsSelectorModule } from '../block-reason-selector/block-reason-selector.module';
import { PlacesAutocompleteModule } from '@fleet/location';
import { MatSelectModule } from '@angular/material/select';
import { PaymentMethodCardModule } from '@fleet/payment';
import { NgxMatIntlTelInputModule } from '@fleet/custom-controls';

@NgModule({
  declarations: [BlocklistAttributeCreateEditComponent],
  imports: [
    CommonModule,
    MatInputModule,
    ProgressButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    ActionButtonsModule,
    SidebarFormLayoutModule,
    BlockActionSelectorModule,
    BlockReasonsSelectorModule,
    PlacesAutocompleteModule,
    MatSelectModule,
    PlacesAutocompleteModule,
    DialogLayoutModule,
    PaymentMethodCardModule,
    NgxMatIntlTelInputModule,
  ],
  exports: [BlocklistAttributeCreateEditComponent],
})
export class BlocklistAttributeCreateEditModule {}
