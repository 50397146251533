import { DOWN_ARROW } from '@angular/cdk/keycodes';
import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { OrganisationApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  IssueModel,
  OrganisationSearchResultModel,
} from '@fleet/model';
import { Observable } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'fleet-organisation-search-list-autocomplete',
  templateUrl: './organisation-search-list-autocomplete.component.html',
  styleUrls: ['./organisation-search-list-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class OrganisationSearchListAutocompleteComponent
  implements OnInit, AfterViewInit
{
  searching: boolean;
  issues: IssueModel[];

  searchControl: UntypedFormControl = new UntypedFormControl();

  organisationSearch: OrganisationSearchResultModel[];
  searchCompleted = false;

  // organisations: any[] = [...mock, ...mock, ...mock];
  @Output() organisationSelected = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @ViewChild('organisationList') organisationList: MatSelectionList;
  @ViewChild('input') input: ElementRef;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private organisationApiService: OrganisationApiService
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value: string) => {
        if (value) {
          this.searching = true;
          this.searchCompleted = false;
          this.issues = [];
          this.changeDetectorRef.markForCheck();

          const params = this.assembleParams(value);

          this.organisationApiService.searchOrganisations(params).subscribe({
            next: (
              resp:
                | HttpResponse<ApiResponse<OrganisationSearchResultModel[]>>
                | any
            ) => {
              this.searching = false;
              this.organisationSearch = resp.body.data;
              this.searchCompleted = true;
              this.changeDetectorRef.markForCheck();
            },
            error: (issues: IssueModel[]) => {
              this.searching = false;
              this.issues = issues;
              this.searchCompleted = false;
              this.changeDetectorRef.markForCheck();
            },
          });
        }
      });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
      }
    }, 100);
  }

  selectOrganisation(organisation: OrganisationSearchResultModel) {
    this.organisationSelected.emit(organisation);
  }

  inputKeydown(event: { keyCode: number }) {
    if (event.keyCode === DOWN_ARROW) {
      //If key down, lets check if we can navigate on the service
      this.organisationList.options.first.focus();
    }
  }

  assembleParams(name: string) {
    const params: any = { limit: 25, offset: 0, name: name };

    return params;
  }
}
