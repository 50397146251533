<div *ngIf="mode === 'list'; else cards" class="flex flex-wrap gap-2">
  <fleet-payment-method-list-item
    class="w-full"
    *ngFor="let payment of listItems"
    [mode]="mode"
    [paymentMethod]="payment.paymentMethod"
    [actions]="payment.actions"
    (actionSelected)="actionSelected.emit({ action: $event, item: payment })"
    [isDefault]="showDefault && payment.isDefault"
    [disableActions]="disableActions"
  >
  </fleet-payment-method-list-item>
</div>

<ng-template #cards>
  <div class="flex flex-auto flex-wrap gap-3 w-full">
    <fleet-payment-method-list-item
      *ngFor="let payment of listItems"
      [mode]="mode"
      [paymentMethod]="payment.paymentMethod"
      [actions]="payment.actions"
      (cardSelected)="cardSelected.emit($event)"
      [isDefault]="showDefault && payment.isDefault"
      [disableActions]="disableActions"
    >
    </fleet-payment-method-list-item>

    <fleet-payment-method-list-item
      *ngIf="includeCreate"
      [mode]="mode"
      [paymentMethod]="{
          displayName: 'Create Payment Method',
          description: 'Select to create a new payment method',
          paymentType: 'CREATE_PAYMENT',
          isDefault: false,
          actions: null,
        }"
      (cardSelected)="cardSelected.emit($event)"
      [disableActions]="disableActions"
    >
    </fleet-payment-method-list-item>
  </div>
</ng-template>
<!-- "payment.paymentMethod.paymentType === 'CREATE_PAYMENT' -->
