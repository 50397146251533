import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import {
  CreditCardPipeModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { PaymentMethodListItemComponent } from './payment-method-list-item.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusChipModule, ToolTipModule } from '@fleet/ui';

@NgModule({
  declarations: [PaymentMethodListItemComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatMenuModule,

    MatTooltipModule,
    StatusChipModule,
    ToolTipModule,

    CreditCardPipeModule,
    MatTooltipModule,
    TitleCaseAndCleanPipeModule,
  ],
  exports: [PaymentMethodListItemComponent],
})
export class PaymentMethodListItemModule {}
