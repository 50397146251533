<!-- Navigation -->

<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="false"
  >
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <img class="w-24" [src]="monoLogoSrc" alt="Logo image" />
      </div>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <fleet-update-banner></fleet-update-banner>

  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <div class="flex items-center mx-2 lg:mr-8">
        <div class="hidden lg:flex">
          <img class="dark:hidden w-32" [src]="logoSrc" />
          <img class="hidden dark:flex w-32" [src]="logoSrc" />
        </div>
        <img class="flex lg:hidden w-32" [src]="logoSrc" />
      </div>
      <!-- Horizontal navigation -->
      <fuse-horizontal-navigation
        class="mr-2"
        [name]="'mainNavigation'"
        [navigation]="navigation"
      ></fuse-horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <ng-container *ngIf="user; else unregisteredLogo">
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
          <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
      </ng-container>
      <ng-template #unregisteredLogo>
        <div class="flex items-center mx-2 lg:mr-8">
          <div class="hidden lg:flex">
            <img class="dark:hidden w-32" [src]="logoSrc" />
            <img class="hidden dark:flex w-32" [src]="logoSrc" />
          </div>
          <img class="flex lg:hidden w-32" [src]="logoSrc" />
        </div>
      </ng-template>
    </ng-container>
    <!-- Components -->
    <div
      class="flex items-center pl-2 ml-auto space-x-2"
      *ngIf="showNewJobButton"
    >
      <button
        class="bg-primary-600 text-white disabled:bg-gray-200"
        mat-raised-button
        (click)="routeToNewJob()"
      >
        NEW BOOKING
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
