<div class="flex flex-row gap-3 w-full" *ngIf="productTypes">
  <mat-form-field class="w-full">
    <mat-label [ngClass]="inSearchForm ? 'text-sm font-light' : ''">
      Product
    </mat-label>
    <mat-select
      multiple
      [formControl]="productTypeControl"
      placeholder="Product Types"
    >
      <mat-option
        *ngFor="let productType of productTypes"
        [value]="productType.code"
      >
        {{ productType.displayValue | titleCaseAndClean }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
