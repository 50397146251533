<fleet-dialog-layout
  [title]="'Change Username / email'"
  [iconColour]="'primary'"
  [icon]="'warning'"
  [showCancel]="!hasUsernameChanged"
>
  <div slot="main">
    <ng-container *ngIf="hasUsernameChanged; else notChanged">
      <div class="text-lg">
        {{
          'Your username/email has changed to ' +
            usernameControl.value +
            '. Whenever you log in again this will be your new username'
        }}
      </div>
    </ng-container>

    <ng-template #notChanged>
      <ng-container *ngIf="verification; else enterEmail">
        <ng-container slot="main">
          <div>
            We've sent a code {{ usernameControl.value }}
            Please check your email and enter the code below
          </div>
          <div class="flex w-full m-auto p-8 justify-center">
            <mat-form-field class="w-full w-40">
              <input
                matInput
                placeholder="Enter code"
                [formControl]="codeControl"
              />
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #enterEmail>
        <mat-form-field class="w-full">
          <input
            matInput
            placeholder="New Email"
            [formControl]="usernameControl"
          />
        </mat-form-field>
      </ng-template>
    </ng-template>
  </div>
  <fuse-alert
    slot="error"
    *ngFor="let alert of issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <ng-container slot="action">
    <button
      *ngIf="!verification && !hasUsernameChanged"
      [disabled]="!usernameControl.valid"
      mat-flat-button
      class="bg-primary-600 text-white disabled:bg-gray-200"
      (click)="begin()"
    >
      NEXT
    </button>
    <fleet-progress-button
      [state]="{ buttonLabel: 'Verify', loading: loading }"
      [loading]="loading"
      (onClick)="verify()"
      *ngIf="verification && !hasUsernameChanged"
    ></fleet-progress-button>
    <button
      class="bg-primary-600 text-white disabled:bg-gray-200"
      *ngIf="hasUsernameChanged"
      mat-flat-button
      [matDialogClose]
    >
      OK
    </button>
  </ng-container>
</fleet-dialog-layout>
