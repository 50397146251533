export const emailRegexOptional =
  '[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-zA-Z]{2,6}';

export const positiveTwoDecimalIntegerRegex = '^([0-9]*(.d+)?|2(.0+)?)$';

export const decimalTwoPlacesForcedRegex = '^[0-9]*.[0-9]{2}$';
export const decimalFourPlacesForcedRegex = '^[0-9]*.[0-9]{4}$';

export const currencyRegex = '^[0-9]*.[0-9]*$';

export const positiveIntegerRegex = '^([1-9]+[0-9]*|[1-9])$';
