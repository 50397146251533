import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
// import { CallModel } from '@fleet/model';

@Component({
  selector: 'fleet-entity-call-detail',
  templateUrl: './entity-call-detail.component.html',
  styleUrls: ['./entity-call-detail.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCallDetailComponent implements OnInit {
  @Input() entityType: string;

  @Input() call: any;
  @Input() autoPlay = false;
  @Output() cancelled = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  routeToTraveller(travellerId: string) {
    this.router.navigate([`traveller/${travellerId}/`]);
  }
  routeToDriver(driverId: string) {
    this.router.navigate([`driver/${driverId}/`]);
  }

  routeToJob(jobId: string) {
    this.router.navigate([`job/${jobId}/`]);
  }

  routeToOrganisation(organisationId: string) {
    this.router.navigate([`organisation/${organisationId}/`]);
  }
}
