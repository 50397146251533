import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialablePhoneNumberComponent } from './dialable-phone-number.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [DialablePhoneNumberComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  exports: [DialablePhoneNumberComponent],
})
export class DialablePhoneNumberModule {}
