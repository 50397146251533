import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentStatusChipPipe } from './payment-status-chip.pipe';

@NgModule({
  declarations: [PaymentStatusChipPipe],
  imports: [CommonModule],
  exports: [PaymentStatusChipPipe],
})
export class PaymentStatusChipPipeModule {}
