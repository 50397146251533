import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fuseAnimations } from '@fleet/fuse';
import { ReferenceDataItemModel } from '@fleet/model';
import { ReferenceService } from '@fleet/reference';
import { Observable } from 'rxjs';

@Component({
  selector: 'fleet-block-reason-selector',
  templateUrl: './block-reason-selector.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BlockReasonSelectorComponent),
      multi: true,
    },
  ],
})
export class BlockReasonSelectorComponent
  implements OnInit, ControlValueAccessor
{
  @Input() inSearch = false;

  @Input() required = false;

  blockReasonControl = new UntypedFormControl();
  blockReasons$: Observable<ReferenceDataItemModel[]>;

  blockReasons: ReferenceDataItemModel[];

  constructor(
    private referenceService: ReferenceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.referenceService.blockReasons$.subscribe(
      (reasons: ReferenceDataItemModel[]) => {
        this.blockReasons = reasons;
        this.changeDetectorRef.markForCheck();
      }
    );

    this.blockReasonControl.valueChanges.subscribe((val: string) => {
      this.onTouched();
      this.onChange(val);
    });
  }

  onChange: any = (condition: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(val: string): void {
    if (val) {
      let find;
      if (val) {
        find = this.blockReasons.find(
          (block: ReferenceDataItemModel) =>
            block.displayValue.toLowerCase() === val.toLowerCase()
        );
      }
      this.blockReasonControl.setValue(find ? find.code : val);
    } else {
      this.blockReasonControl.reset();
    }
    this.changeDetectorRef.markForCheck();
  }
}
