<fleet-dialog-layout
  *ngIf="job"
  [title]="'Unassign Job'"
  [instructions]="
    'Confirm you wish to un-assign ' +
    job.driver.name +
    ' from this job. You may record an optional reason for doing so.'
  "
  [iconColour]="'accent'"
  [icon]="'warning'"
>
  <div slot="main">
    <mat-form-field class="w-full">
      <mat-label>Unassign Reason</mat-label>
      <textarea
        maxlength="50"
        matInput
        placeholder="Unassign Reason"
        [formControl]="unassignReasonControl"
      ></textarea>
    </mat-form-field>
  </div>

  <fleet-progress-button
    slot="action"
    [state]="{ loading: loading, buttonLabel: 'Unassign Job' }"
    [disabled]="loading"
    (onClick)="unAssign()"
  >
  </fleet-progress-button>

  <div slot="error">
    <fuse-alert
      *ngFor="let alert of issues | alertsFromIssues"
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>
  </div>
</fleet-dialog-layout>
