import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodCardComponent } from './payment-method-card.component';
import { MatIconModule } from '@angular/material/icon';

import { FuseAlertModule } from '@fleet/fuse';
import { PaymentMethodIconModule } from '../payment-method-icon/payment-method-icon.module';

@NgModule({
  declarations: [PaymentMethodCardComponent],
  imports: [CommonModule, MatIconModule, PaymentMethodIconModule],
  exports: [PaymentMethodCardComponent],
})
export class PaymentMethodCardModule {}
