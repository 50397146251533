<div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
  <div
    *ngIf="title"
    class="flex flex-row gt-xs:flex-row gt-xs:items-start justify-between w-full"
  >
    <div class="gt-xs:max-w-60 gt-xs:pr-12">
      <p class="text-lg font-medium">{{ title }}</p>
      <p class="text-secondary mb-6">{{ subtitle }}</p>
    </div>

    <div class="self-start -mt-2">
      <ng-content select="[slot=actions]"> </ng-content>
    </div>
  </div>

  <ng-content> </ng-content>
</div>

<mat-divider *ngIf="hasDivider" class="mt-8 mb-4"></mat-divider>
