import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreadcrumbItemComponent } from './breadcrumb-item.component';
import { MatIconModule } from '@angular/material/icon';

import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [BreadcrumbItemComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  exports: [BreadcrumbItemComponent],
})
export class BreadcrumbItemModule {}
