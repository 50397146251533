import { Pipe, PipeTransform } from '@angular/core';
import {
  TravellerActionStatus,
  TravellerActionType,
  TravellerModel,
} from '@fleet/model';

export interface TravellerStatusAction {
  label: string;
  title: string;
  messageBody: string;
  action: string;
  icon: string;
  iconColour: string;
}

@Pipe({
  name: 'travellerAction',
})
export class TravellerStatusActionPipe implements PipeTransform {
  transform(traveller: TravellerModel): any {
    if (traveller) {
      switch (traveller.status) {
        case TravellerActionStatus.INVITED:
          return [deleteAction(traveller)];
        case TravellerActionStatus.UNREGISTERED:
          return [deleteAction(traveller)];
        case TravellerActionStatus.DORMANT:
          return [deleteAction(traveller)];

        case TravellerActionStatus.RESURRECTED:
          return [
            suspendAction(traveller),
            banAction(traveller),
            deleteAction(traveller),
          ];
        case TravellerActionStatus.PENDING_ACTIVATION:
          return [
            suspendAction(traveller),
            banAction(traveller),
            deleteAction(traveller),
          ];

        case TravellerActionStatus.ACTIVE:
          return [
            suspendAction(traveller),
            banAction(traveller),
            deleteAction(traveller),
          ];

        case TravellerActionStatus.BANNED:
          return [activateAction(traveller), deleteAction(traveller)];
        case TravellerActionStatus.SUSPENDED:
          return [activateAction(traveller), deleteAction(traveller)];

        case TravellerActionStatus.CHURNED:
          return [deleteAction(traveller)];

        case TravellerActionStatus.DISABLED:
          return [];
      }
    }

    return null;
  }
}

function deleteAction(traveller: TravellerModel) {
  return {
    label: 'Delete',
    title: 'Delete Traveller',
    messageBody:
      'Are you want to delete ' +
      traveller.firstName +
      ' ' +
      traveller.lastName +
      '?',
    action: TravellerActionType.DELETE_TRAVELLER,
    icon: 'delete',
    iconColour: 'error',
  };
}

function activateAction(traveller: TravellerModel) {
  return {
    label: 'Activate',
    title: 'Activate Traveller',
    messageBody:
      'Are you sure you want to activate ' +
      traveller.firstName +
      ' ' +
      traveller.lastName +
      '?',
    action: TravellerActionType.ACTIVATE_TRAVELLER,
    icon: 'check',
    iconColour: 'success',
  };
}

function banAction(traveller: TravellerModel) {
  return {
    label: 'Ban',
    title: 'Ban Traveller',
    messageBody:
      'Are you want to ban ' +
      traveller.firstName +
      ' ' +
      traveller.lastName +
      '?',
    action: TravellerActionType.BAN_TRAVELLER,
    icon: 'block',
    iconColour: 'error',
  };
}

function disableAction(traveller: TravellerModel) {
  return {
    label: 'Disable',
    title: 'Disable Traveller',
    messageBody:
      'Are you want to disable ' +
      traveller.firstName +
      ' ' +
      traveller.lastName +
      '?',
    action: TravellerActionType.DISABLE_TRAVELLER,
    icon: 'disabled_by_default',
    iconColour: 'error',
  };
}

function resurrectAction(traveller: TravellerModel) {
  return {
    label: 'Resurrect',
    title: 'Resurrect Traveller',
    messageBody:
      'Are you want to resurrect ' +
      traveller.firstName +
      ' ' +
      traveller.lastName +
      '?',
    action: TravellerActionType.RESURRECT_TRAVELLER,
    icon: 'restore_from_trash',
    iconColour: 'basic',
  };
}

function suspendAction(traveller: TravellerModel) {
  return {
    label: 'Suspend',
    title: 'Suspend Traveller',
    messageBody:
      traveller.firstName +
      ' ' +
      traveller.lastName +
      'will be prevented from placing bookings.',
    action: TravellerActionType.SUSPEND_TRAVELLER,
    icon: 'block',
    iconColour: 'error',
  };
}
