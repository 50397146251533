<fleet-responsive-element class="flex flex-grow w-full">
  <ng-container slot="xs">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
  <ng-container slot="sm">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
  <ng-container slot="md">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
  <ng-container slot="lg">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
  <ng-container slot="xl">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
</fleet-responsive-element>

<ng-template #default>
  <mat-table #table [dataSource]="search.data" class="w-full bg:card">
    <ng-container matColumnDef="callTime">
      <mat-header-cell *matHeaderCellDef class="flex min-w-32"
        >When</mat-header-cell
      >
      <mat-cell
        (click)="disablePreview ? null : previewCall.emit(call)"
        *matCellDef="let call"
        class="flex min-w-32 items-start"
      >
        <div class="flex flex-col">
          <div>
            {{
              call.startTime
                | internationalizationDate : 'CONDENSED_DATE_TIME_OFFSET'
            }}
          </div>
          <div
            class="flex flex-row items-center text-sm"
            *ngIf="call.recordingDuration"
          >
            <div class="text-sm">
              Duration: {{ call.recordingDuration | duration }}
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="incomingOutgoing">
      <mat-header-cell *matHeaderCellDef class="flex min-w-36 px-2">
        Caller / Dialled</mat-header-cell
      >
      <mat-cell
        (click)="disablePreview ? null : previewCall.emit(call)"
        *matCellDef="let call"
        class="flex min-w-36 px-2 items-start"
      >
        <div class="flex flex-col gap-1 -ml-1 text-sm">
          <div
            *ngIf="call.incomingNumber"
            matTooltip="Incoming"
            class="flex flex-row whitespace-nowrap items-center"
          >
            <mat-icon class="icon-size-4"> call_received</mat-icon>
            <div>{{ call.incomingNumber | mobileFormat }}</div>
          </div>
          <div
            *ngIf="call.dialledNumber"
            matTooltip="Outgoing"
            class="flex flex-row whitespace-nowrap items-center"
          >
            <mat-icon class="icon-size-4">call_made</mat-icon>
            <div>{{ call.dialledNumber | mobileFormat }}</div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="moli">
      <mat-header-cell *matHeaderCellDef class="flex max-w-20">
        MOLI</mat-header-cell
      >
      <mat-cell
        (click)="disablePreview ? null : previewCall.emit(call)"
        *matCellDef="let call"
        class="flex max-w-20 items-start"
      >
        <div class="">
          {{ call.moli }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="agentHandover">
      <mat-header-cell *matHeaderCellDef class="flex max-w-22">
        Handover</mat-header-cell
      >
      <mat-cell
        (click)="disablePreview ? null : previewCall.emit(call)"
        *matCellDef="let call"
        class="flex max-w-22 items-start"
      >
        <div class="">
          {{ call.agentHandover ? 'Yes' : 'No' }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="note">
      <mat-header-cell *matHeaderCellDef class="flex flex-grow">
        Note</mat-header-cell
      >
      <mat-cell
        (click)="disablePreview ? null : previewCall.emit(call)"
        *matCellDef="let call"
        class="flex flex-grow items-start"
      >
        <div class="line-clamp-3">
          {{ call?.note?.content }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="initiatedBy">
      <mat-header-cell *matHeaderCellDef class="flex min-w-20 px-2">
        Initiated By</mat-header-cell
      >
      <mat-cell
        (click)="disablePreview ? null : previewCall.emit(call)"
        *matCellDef="let call"
        class="flex min-w-20 px-2 items-start"
      >
        <button mat-icon-button>
          <ng-container *ngIf="call?.type === 'INBOUND'; else callCenter">
            <mat-icon
              *ngIf="call.callerType | callerType as callType"
              [matTooltip]="callType.tooltip"
            >
              {{ callType.icon }}
            </mat-icon>
          </ng-container>

          <ng-template #callCenter>
            <mat-icon
              *ngIf="'CALL_CENTRE' | callerType as callType"
              [matTooltip]="callType.tooltip"
            >
              {{ callType.icon }}
            </mat-icon>
          </ng-template>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="recording">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="{ hidden: disableRecordings }"
        class="flex max-w-28"
      >
        Recording</mat-header-cell
      >
      <mat-cell
        *matCellDef="let call"
        [ngClass]="{ hidden: disableRecordings }"
        class="flex max-w-28 items-start"
      >
        <button
          matTooltip="Listen"
          *ngIf="call.recordingUrl"
          mat-icon-button
          (click)="$event.preventDefault(); playRecording.emit(call)"
        >
          <mat-icon>play_circle</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="content">
      <mat-header-cell *matHeaderCellDef class="flex flex-grow">
        Message/Recording</mat-header-cell
      >
      <mat-cell *matCellDef="let call" class="items-start">
        <button
          [ngClass]="{ hidden: disableRecordings }"
          matTooltip="Listen"
          *ngIf="call.recordingUrl"
          mat-icon-button
          (click)="$event.preventDefault(); playRecording.emit(call)"
        >
          <mat-icon>play_circle</mat-icon>
        </button>

        <span
          (click)="disablePreview ? null : previewCall.emit(call)"
          class="text-sm truncate w-full"
          *ngIf="call.message"
          >{{ call.message }}</span
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="links">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="{ hidden: fleetProduct !== 'HUB' }"
        class="flex px-2"
        >Links
      </mat-header-cell>
      <mat-cell
        *matCellDef="let call"
        class="flex px-2 items-start"
        [ngClass]="{ hidden: fleetProduct !== 'HUB' }"
      >
        <div class="flex flex-row gap-3">
          <button
            mat-icon-button
            *ngIf="call.travellerId && !search.pageData?.params?.travellerId"
            class="cursor-pointer"
            [matTooltip]="'Navigate to traveller'"
            (click)="routeToTraveller(call.travellerId)"
          >
            <mat-icon>hail</mat-icon>
          </button>

          <button
            mat-icon-button
            *ngIf="
              call.organisationId && !search.pageData?.params?.organisationId
            "
            class="cursor-pointer"
            [matTooltip]="'Navigate to Organisation'"
            (click)="routeToOrganisation(call.organisationId)"
          >
            <mat-icon>business</mat-icon>
          </button>

          <button
            mat-icon-button
            *ngIf="call.driverId && !search.pageData?.params?.driverId"
            class="cursor-pointer"
            [matTooltip]="'Navigate to driver'"
            (click)="routeToDriver(call.driverId)"
          >
            <mat-icon>local_taxi</mat-icon>
          </button>

          <button
            mat-icon-button
            *ngIf="call.jobId && !search.pageData?.params?.jobId"
            class="cursor-pointer"
            [matTooltip]="'Navigate to Job'"
            (click)="routeToJob(call.jobId)"
          >
            <mat-icon>location_on</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      mat-header-row
      *matHeaderRowDef="displayColumns; sticky: true"
    >
    </mat-header-row>
    <mat-row
      mat-row
      *matRowDef="let row; columns: displayColumns"
      class="py-2"
      [ngClass]="{ 'cursor-pointer': !disablePreview }"
      style="vertical-align: top"
    >
    </mat-row>
  </mat-table>
</ng-template>
