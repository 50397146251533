import { HttpErrorResponse } from '@angular/common/http';
import { IssueLevel, IssueModel } from '@fleet/model';
import { Observable, throwError } from 'rxjs';

import { urlBase64Decode } from './jwt-helper';

const supportMessage =
  'An error has occurred. Please try again, or contact support for further assistance.';
export function handleApiError(
  httpErrorResponse: HttpErrorResponse
): Observable<IssueModel[]> {
  let issues: IssueModel[] = [];
  if (httpErrorResponse.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', httpErrorResponse.error.message);
    issues.push(<IssueModel>{
      message:
        'There was an issue contacting the server, please try again later',
      level: IssueLevel.ERROR,
    });
  } else {
    switch (httpErrorResponse.status) {
      case 0:
        issues.push(<IssueModel>{
          message:
            'There was an issue contacting the server, please try again later',
          level: IssueLevel.ERROR,
        });
        // issue.type = "ERROR";
        break;
      case 401:
        issues.push(<IssueModel>{
          message:
            httpErrorResponse.url.includes('signin') ||
            httpErrorResponse.url.includes('saml')
              ? 'Username or password incorrect'
              : 'Not Authenticated',
          level: IssueLevel.ERROR,
        });
        break;
      case 403:
        issues.push(<IssueModel>{
          message: httpErrorResponse.url.includes('saml')
            ? 'There is an issue with your account. Please contact your administrator'
            : 'Not Authorised',
          level: IssueLevel.ERROR,
        });
        break;
      case 400:
      case 404:
      case 500:
        issues =
          httpErrorResponse.error &&
          httpErrorResponse.error.status &&
          httpErrorResponse.error.status.issues
            ? httpErrorResponse.error.status.issues.filter(
                (issue: IssueModel) => issue.type || issue.message
              )
            : httpErrorResponse.error &&
              httpErrorResponse.error.status &&
              httpErrorResponse.error.status.messages
            ? httpErrorResponse.error.status.messages
            : [
                {
                  message: supportMessage,
                  level: IssueLevel.ERROR,
                },
              ];

        break;
      case 503:
      case 504:
        issues.push(<IssueModel>{
          message: 'Server is not responding. Please have a try later.',
          level: IssueLevel.ERROR,
        });
        break;
      case 409:
        issues = httpErrorResponse.error
          ? httpErrorResponse.error.status.issues
          : [
              {
                message: supportMessage,
                level: IssueLevel.ERROR,
              },
            ];

        break;

      // if length is zero above error;
      // if length is greater than zero set the message

      default:
        issues.push(<IssueModel>{
          message:
            'There was an issue contacting the server, please try again later',
          level: IssueLevel.ERROR,
        });
        break;
    }

    if (issues.length === 0) {
      issues.push(<IssueModel>{
        message: supportMessage,
        level: IssueLevel.ERROR,
      });
    } else {
      if (!issues[0].message) {
        issues[0].message = supportMessage;
      }
    }
  }
  return throwError(issues);
}
