import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { DialogLayoutModule } from '@fleet/ui';
import { JobUnassignDialogComponent } from './job-unassign-dialog.component';
import { ProgressButtonModule } from '@fleet/shared';

@NgModule({
  declarations: [JobUnassignDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    DialogLayoutModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
    MatButtonModule,
    ProgressButtonModule,
  ],
  exports: [JobUnassignDialogComponent],
})
export class JobUnassignDialogModule {}
