import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-entity-search-layout-sidebar',
  templateUrl: './entity-search-layout-sidebar.component.html',
  styleUrls: ['./entity-search-layout-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitySearchLayoutSidebarComponent {
  @Input() showCloseButton = true;
  @Input() showTitleAndSubtitle = true;
  @Input() showSearchButtons = true;

  @Output() closeDrawer = new EventEmitter();
  constructor() {}
}
