/// <reference types='google.maps' />
import { JobModel, PointModel, PositionListenerModel } from '@fleet/model';

export function positionListenerModelFromJobModel(job: JobModel) {
  const bounds = boundsFromJob(job);
  const lm = {} as PositionListenerModel;
  if (job.vehicle) {
    lm.vehicleIds = [job.vehicle.vehicleId];
  }
  lm.northEast = pointModelFromLatLngLiterial(bounds.getNorthEast());
  lm.southWest = pointModelFromLatLngLiterial(bounds.getSouthWest());
  if (job.network) {
    lm.networkId = job.network.attributionNetworkId;
    if (job.network.attributionNetworkGroupId) {
      lm.networkGroupId = job.network.attributionNetworkGroupId;
    }
  }
  return lm;
}

export function boundsFromJob(job: JobModel) {
  const bounds = new google.maps.LatLngBounds();
  if (job.jobDetail.startLocation) {
    bounds.extend({
      lat: +job.jobDetail.startLocation.latitude,
      lng: +job.jobDetail.startLocation.longitude,
    });
  }
  if (job.jobDetail.endLocation && job.jobDetail.endLocation.latitude) {
    bounds.extend({
      lat: +job.jobDetail.endLocation.latitude,
      lng: +job.jobDetail.endLocation.longitude,
    });
  }

  if (job.jobProgress && job.jobProgress.position) {
    bounds.extend({
      lat: +job.jobProgress.position.latitude,
      lng: +job.jobProgress.position.longitude,
    });
  }
  return bounds;
}

export function pointModelFromLatLngLiterial(latLng: google.maps.LatLng) {
  return {
    latitude: latLng.lat().toString(),
    longitude: latLng.lng().toString(),
  } as PointModel;
}

export function latLngFromPointModel(point: PointModel) {
  return new google.maps.LatLng(+point.latitude, +point.longitude);
}
