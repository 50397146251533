import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobCloneComponent } from './job-clone.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [JobCloneComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
  ],
  exports: [JobCloneComponent],
})
export class JobCloneModule {}
