import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { TicketApiService } from '@fleet/api';
import { ApiResponse, TicketModel } from '@fleet/model';

@Component({
  selector: 'fleet-ticket-hubspot-control',
  templateUrl: './ticket-hubspot-control.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TicketHubspotControlComponent),
      multi: true,
    },
  ],
})
export class TicketHubspotControlComponent
  implements OnInit, ControlValueAccessor
{
  hubspotControl = new UntypedFormControl();
  loading: boolean;
  ticketDisplay: string;
  success: boolean;
  failure: boolean;

  @Output() controllerError = new EventEmitter();
  constructor(
    private ticketApiService: TicketApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.hubspotControl.valueChanges.subscribe((value: string) => {
      this.searchTicket(value);
    });
  }

  searchTicket(externalTicketId: string) {
    this.loading = true;
    this.success = false;
    this.failure = false;

    this.changeDetectorRef.markForCheck();
    const params = { limit: 10, offset: 0, externalTicketId: externalTicketId };

    this.ticketApiService.searchTickets(params).subscribe(
      (resp: HttpResponse<ApiResponse<TicketModel[]>>) => {
        if (resp.body.data.length > 0) {
          this.ticketDisplay = resp.body.data[0].content;
          this.onChange(resp.body.data[0]);
          this.onTouched();
          this.success = true;
        } else {
          this.onChange(null);
          this.failure = true;
          this.ticketDisplay = 'Ticket could not be found!';
        }

        this.loading = false;

        this.changeDetectorRef.markForCheck();
      },
      (error) => {
        this.loading = false;
        this.failure = true;
        this.controllerError.emit(error);
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  writeValue(value: TicketModel): void {
    if (value) {
      this.hubspotControl.setValue(value.externalTicketId, {
        emitEvent: false,
      });
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
