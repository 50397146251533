import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarHeaderComponent } from './sidebar-header.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SidebarHeaderComponent],
  imports: [CommonModule, MatIconModule],
  exports: [SidebarHeaderComponent],
})
export class SidebarHeaderModule {}
