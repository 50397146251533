import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSaleModelItemComponent } from './job-sale-model-item.component';
import {
  CreditCardPipeModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AlertModule } from '@fleet/ui';
import { MatMenuModule } from '@angular/material/menu';
import { JobPaymentTransactionReceiptModule } from '../job-payment-transaction-receipt/job-payment-transaction-receipt.module';
import { HasFunctionPipeModule } from '@fleet/security-shared';

@NgModule({
  declarations: [JobSaleModelItemComponent],
  imports: [
    CommonModule,
    TitleCaseAndCleanPipeModule,
    CreditCardPipeModule,
    MatIconModule,
    MatButtonModule,
    AlertModule,
    MatMenuModule,
    HasFunctionPipeModule,
    JobPaymentTransactionReceiptModule,
  ],
  exports: [JobSaleModelItemComponent],
})
export class JobSaleModelItemModule {}
