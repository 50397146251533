import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { JobStatus } from '@fleet/model';
import { Subject } from 'rxjs';

@Component({
  selector: 'fleet-job-status-selector',
  templateUrl: './job-status-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JobStatusSelectorComponent),
      multi: true,
    },
  ],
})
export class JobStatusSelectorComponent
  implements OnInit, ControlValueAccessor
{
  @Input() inSearchForm = false;
  formControl = new FormControl();

  @Input() set excludedStatuses(value: string[]) {
    this.OPTIONS = this.OPTIONS.filter((o) => !value.includes(o));
  }
  @Input() mode: 'list' | 'select' = 'list';

  _unsubscribeAll: Subject<any> = new Subject();

  OPTIONS = Object.values(JobStatus);

  constructor() {}

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value: string[]) => {
      this.onTouched();
      this.onChange(value);
    });
  }

  onChange: any = (condition: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(statuses: string[]) {
    if (statuses) {
      this.formControl.setValue(statuses);
    } else {
      this.formControl.setValue(null);
    }
  }
}
