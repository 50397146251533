import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteSearchComponent } from './note-search.component';
import { RelatedEntitySearchLayoutModule } from '@fleet/layout';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { SearchChipsListModule } from '@fleet/shared';
import { RemovePropertiesPipeModule } from '@fleet/pipes';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
import { SidebarHeaderModule } from '@fleet/ui';

import { NoteSearchResultsModule } from '../note-search-results/note-search-results.module';
import { NoteDetailModule } from '../note-detail/note-detail.module';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { NoteCreateEditModule } from '../note-create-edit/note-create-edit.module';

@NgModule({
  declarations: [NoteSearchComponent],
  imports: [
    CommonModule,
    RelatedEntitySearchLayoutModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    SearchChipsListModule,
    RemovePropertiesPipeModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    SidebarHeaderModule,
    NoteCreateEditModule,
    NoteSearchResultsModule,
    NoteDetailModule,
    HasFunctionPipeModule,
  ],
  exports: [NoteSearchComponent],
})
export class NoteSearchModule {}
