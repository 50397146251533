import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesListCardComponent } from './notes-list-card.component';
import { BadgeModule, CardModule } from '@fleet/ui';
import { NoteSearchResultsModule } from '../note-search-results/note-search-results.module';
import { NoResultsLabelModule } from '@fleet/shared';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
import { LineBreakPipeModule } from '@fleet/pipes';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';

@NgModule({
  declarations: [NotesListCardComponent],
  imports: [
    CommonModule,
    CardModule,
    NoteSearchResultsModule,
    NoResultsLabelModule,
    BadgeModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    LineBreakPipeModule,
    InternationalizationDatePipeModule,
  ],
  exports: [NotesListCardComponent],
})
export class NotesListCardModule {}
