export const genericAvailableVehicle = `<svg version="1.1" id="Layer_1_copy" xmlns:svg="http://www.w3.org/2000/svg"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"
	 style="enable-background:new 0 0 30 30;" xml:space="preserve" width="30"
   height="30">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#26308c;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
</style>
<circle id="path493_00000058549672099441420100000010551755381302457495_" class="st0" cx="15" cy="15" r="15"/>
<circle id="path493-6_00000099659339257015819210000013581910417784009916_" class="st1" cx="15" cy="14.9" r="12.7"/>
<path d="M22.5,14.1c-0.4-0.4-1-0.5-1-0.5s-1.4-3.4-1.4-3.5c-0.1-0.1-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.3-1-0.3c-0.2,0-1,0-2,0V8.1h0
	c0-0.3-0.2-0.5-0.5-0.5h-2.2c-0.3,0-0.5,0.2-0.5,0.5h0v0c0,0,0,0,0,0c0,0,0,0,0,0V9c-1,0-1.9,0-2,0c-0.4,0-0.6,0.1-1,0.3
	S9.9,10,9.9,10l-1.4,3.5c0,0-0.5,0.1-0.9,0.5s-0.5,1-0.5,1s0,2.8,0,3.7c0,0.9,1.3,0.9,1.3,0.9V21h0c0,0,0,0,0,0.1
	c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2c0,0,0,0,0-0.1h0v-1.3h8.5v1.4c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2v-1.4
	c0,0,1.3-0.1,1.3-0.9c0-0.9,0-3.7,0-3.7C23,14.9,22.9,14.5,22.5,14.1z M9.6,17C9,17,8.5,16.6,8.5,16s0.5-1.1,1.1-1.1
	c0.6,0,1.1,0.5,1.1,1.1S10.2,17,9.6,17z M9.9,13.5c0,0,1-2.6,1.1-2.8c0.1-0.2,0.1-0.2,0.3-0.3c0.2-0.1,0.4-0.1,0.4-0.1h6.6
	c0,0,0.2,0,0.4,0.1c0.2,0.1,0.3,0.3,0.3,0.3l1,2.7H9.9z M20.5,17c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
	S21.1,17,20.5,17z"/>
</svg>`;

export const genericBusyVehicle = `<svg version="1.1" id="Layer_1_copy" xmlns:svg="http://www.w3.org/2000/svg"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"
	 style="enable-background:new 0 0 30 30;" xml:space="preserve" width="30"
   height="30">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#F96938;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
</style>
<circle id="path493_00000117658694286007092480000011611831216926394536_" class="st0" cx="15" cy="15" r="15"/>
<circle id="path493-6_00000160899502501152989210000005663354959907766172_" class="st1" cx="15" cy="14.9" r="12.7"/>
<path d="M22.5,14.1c-0.4-0.4-1-0.5-1-0.5s-1.4-3.4-1.4-3.5c-0.1-0.1-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.3-1-0.3c-0.2,0-1,0-2,0V8.1h0
	c0-0.3-0.2-0.5-0.5-0.5h-2.2c-0.3,0-0.5,0.2-0.5,0.5h0v0c0,0,0,0,0,0c0,0,0,0,0,0V9c-1,0-1.9,0-2,0c-0.4,0-0.6,0.1-1,0.3
	S9.9,10,9.9,10l-1.4,3.5c0,0-0.5,0.1-0.9,0.5s-0.5,1-0.5,1s0,2.8,0,3.7c0,0.9,1.3,0.9,1.3,0.9V21h0c0,0,0,0,0,0.1
	c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2c0,0,0,0,0-0.1h0v-1.3h8.5v1.4c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2v-1.4
	c0,0,1.3-0.1,1.3-0.9c0-0.9,0-3.7,0-3.7C23,14.9,22.9,14.5,22.5,14.1z M9.6,17C9,17,8.5,16.6,8.5,16s0.5-1.1,1.1-1.1
	c0.6,0,1.1,0.5,1.1,1.1S10.2,17,9.6,17z M9.9,13.5c0,0,1-2.6,1.1-2.8c0.1-0.2,0.1-0.2,0.3-0.3c0.2-0.1,0.4-0.1,0.4-0.1h6.6
	c0,0,0.2,0,0.4,0.1c0.2,0.1,0.3,0.3,0.3,0.3l1,2.7H9.9z M20.5,17c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
	S21.1,17,20.5,17z"/>
</svg>`;

export const genericAssignedVehicle = `<svg version="1.1" id="Layer_1_copy" xmlns:svg="http://www.w3.org/2000/svg"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"
	 style="enable-background:new 0 0 30 30;" xml:space="preserve" width="30"
   height="30">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFCC33;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;}

</style>
<circle id="path493_00000058549672099441420100000010551755381302457495_" class="st0" cx="15" cy="15" r="15"/>
<circle id="path493-6_00000099659339257015819210000013581910417784009916_" class="st1" cx="15" cy="14.9" r="12.7"/>
<path d="M22.5,14.1c-0.4-0.4-1-0.5-1-0.5s-1.4-3.4-1.4-3.5c-0.1-0.1-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.3-1-0.3c-0.2,0-1,0-2,0V8.1h0
	c0-0.3-0.2-0.5-0.5-0.5h-2.2c-0.3,0-0.5,0.2-0.5,0.5h0v0c0,0,0,0,0,0c0,0,0,0,0,0V9c-1,0-1.9,0-2,0c-0.4,0-0.6,0.1-1,0.3
	S9.9,10,9.9,10l-1.4,3.5c0,0-0.5,0.1-0.9,0.5s-0.5,1-0.5,1s0,2.8,0,3.7c0,0.9,1.3,0.9,1.3,0.9V21h0c0,0,0,0,0,0.1
	c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2c0,0,0,0,0-0.1h0v-1.3h8.5v1.4c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2v-1.4
	c0,0,1.3-0.1,1.3-0.9c0-0.9,0-3.7,0-3.7C23,14.9,22.9,14.5,22.5,14.1z M9.6,17C9,17,8.5,16.6,8.5,16s0.5-1.1,1.1-1.1
	c0.6,0,1.1,0.5,1.1,1.1S10.2,17,9.6,17z M9.9,13.5c0,0,1-2.6,1.1-2.8c0.1-0.2,0.1-0.2,0.3-0.3c0.2-0.1,0.4-0.1,0.4-0.1h6.6
	c0,0,0.2,0,0.4,0.1c0.2,0.1,0.3,0.3,0.3,0.3l1,2.7H9.9z M20.5,17c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
	S21.1,17,20.5,17z" fill="white"/>
</svg>`;
