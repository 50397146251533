import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Breadcrumb } from '../../../../../model/src/lib/client-model/entity-header.model';

@Component({
  selector: 'fleet-breadcrumb-item',
  templateUrl: './breadcrumb-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbItemComponent implements OnInit {
  @Input() breadcrumb: Breadcrumb;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  clicked() {
    if (this.breadcrumb.link) {
      this.router.navigate([this.breadcrumb.link]);
    }
  }
}
