<fuse-alert
  *ngFor="let alert of issues | alertsFromIssues"
  [appearance]="'outline'"
  [showIcon]="true"
  [type]="alert.type"
  [@shake]="alert.type === 'error'"
>
  {{ alert.message }}
</fuse-alert>

<mat-form-field class="w-full">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    matInput
    #input
    [placeholder]="placeHolder"
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    (click)="inputClick($event.target)"
  />
  <mat-icon matPrefix *ngIf="prefixIcon">{{ prefixIcon }} </mat-icon>
  <mat-spinner matSuffix diameter="18" *ngIf="searching"></mat-spinner>
  <mat-error *ngIf="searchControl?.errors?.required">
    Staff member is required or selected staff is invalid
  </mat-error>
</mat-form-field>
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  (optionSelected)="onStaffSelected($event)"
>
  <ng-container *ngIf="staff$ | async as search">
    <mat-option *ngFor="let staff of search" [value]="staff">
      <span>{{ staff.firstName }} {{ staff.lastName }} </span>
    </mat-option>

    <mat-option *ngIf="search.length === 0 && searchComplete" [disabled]="true"
      >No results found</mat-option
    >
  </ng-container>
</mat-autocomplete>
