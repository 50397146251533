<mat-form-field class="w-full">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-icon class="icon-size-5" matPrefix>date_range</mat-icon>
  <mat-select #scheduleSelect [formControl]="scheduleControl" name="item">
    <mat-option *ngFor="let option of startTimeOptions" [value]="option">
      {{ option }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="w-full" *ngIf="scheduleControl.value === 'Pick Date'">
  <input
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    matInput
    #calendarInput
    placeHolder="Choose Date"
    [matDatepicker]="calendarPicker"
    [formControl]="calendarControl"
    [min]="minDate"
  />
  <mat-icon class="icon-size-5" matPrefix>event</mat-icon>
  <mat-datepicker #calendarPicker></mat-datepicker>
  <mat-datepicker-toggle matSuffix [for]="calendarPicker">
    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
  </mat-datepicker-toggle>

  <mat-error *ngIf="calendarControl?.errors?.required"
    >A valid date is required</mat-error
  >
</mat-form-field>

<mat-form-field
  *ngIf="showControl"
  class="w-full"
  [subscriptSizing]="'dynamic'"
>
  <input
    id="time-input"
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    matInput
    type="time"
    style="height: 100%"
    #timeInput
    [formControl]="timeControl"
  />

  <mat-icon class="icon-size-5" matPrefix>schedule</mat-icon>

  <mat-error *ngIf="timeControl?.errors && !timeControl?.errors?.BEFORE_TIME"
    >Please ensure you have a valid time in the future and specify
    am/pm</mat-error
  >
  <mat-hint>12hr time with am/pm</mat-hint>
  <mat-error *ngIf="timeControl?.errors?.BEFORE_TIME">
    Please ensure time is not in the past
  </mat-error>
</mat-form-field>

<mat-error *ngFor="let issue of jobState | jobIssues : 'startTime'">{{
  issue.message
}}</mat-error>
