import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormControl,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  DriverApiService,
  JobApiService,
  OperatorApiService,
  OrganisationApiService,
  TravellerApiService,
} from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  JobModel,
  OperatorModel,
  OrganisationGroupModel,
  OrganisationModel,
  PaymentMethodModel,
  PaymentType,
  TravellerModel,
} from '@fleet/model';

import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import {
  BraintreePaymentMethodComponent,
  BrainTreeState,
} from '../braintree-payment-method/braintree-payment-method.component';
import { BraintreeHostedFieldsComponent } from '../braintree-hosted-fields/braintree-hosted-fields.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface PaymentMethodCreateEditState {
  paymentMethod: PaymentMethodModel;
  loading: boolean;
  issues: IssueModel[];
  buttonLabel: string;
  title: string;
}

const initialState: PaymentMethodCreateEditState = {
  paymentMethod: null,
  buttonLabel: 'Create',
  issues: [],
  loading: false,
  title: 'New Payment Method',
};

@Component({
  selector: 'fleet-payment-method-create-edit',
  templateUrl: './payment-method-create-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class PaymentMethodCreateEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  _paymentMethodType: string;
  @Input() set paymentMethodType(value: string) {
    this._paymentMethodType = value;

    if (value) {
      this.paymentTypeControl.setValue(value);

      if (!this.paymentMethodForm && !this.payment) {
        this.buildForm(value);
      }
    }
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  get paymentMethodType() {
    return this._paymentMethodType;
  }

  _mode: 'BRAINTREE' | 'DIALOG' | 'SIDEBAR' = 'SIDEBAR';
  @Input() set mode(value: 'BRAINTREE' | 'DIALOG' | 'SIDEBAR') {
    this._mode = value;
    if (value === 'BRAINTREE') {
      setTimeout(() => {
        this.paymentTypeControl.setValue(value);
      }, 100);
    }
  }

  get mode() {
    return this._mode;
  }

  _organisation: OrganisationModel;
  @Input() set organisation(value: OrganisationModel) {
    this._organisation = value;
    if (value) {
      this.PAYMENT_TYPES = [
        { name: 'Credit Card', value: 'BRAINTREE' },

        { name: 'Organisation Account', value: 'ORGANISATION_ACCOUNT' },
      ];

      if (!this.paymentMethodForm) {
        this.buildForm('BRAINTREE');
      }
      this.paymentMethodForm.addControl(
        'displayName',
        new FormControl('', [
          Validators.required,
          Validators.pattern(`^[a-zA-Z ']+$`),
        ])
      );
      this.paymentMethodForm.updateValueAndValidity();
      this.changeDetectorRef.markForCheck();
    }
  }

  get organisation() {
    return this._organisation;
  }

  @Input() driver: DriverModel;

  @Input() traveller: TravellerModel;

  @Input() organisationGroup: OrganisationGroupModel;

  @Input() job: JobModel;

  _operator: OperatorModel;
  @Input() set operator(value: OperatorModel) {
    this._operator = value;
    if (value) {
      this.PAYMENT_TYPES = [
        { name: 'Credit Card', value: 'BRAINTREE' },
        {
          name: 'Direct Debit Bank Account',
          value: 'DIRECT_DEBIT_BANK_ACCOUNT',
        },
      ];

      this.changeDetectorRef.markForCheck();
    }
  }

  get operator() {
    return this._operator;
  }

  @Output() onCreateSuccess = new EventEmitter();
  @Output() onEditSuccess = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  paymentMethodCreateEditState: BehaviorSubject<PaymentMethodCreateEditState> =
    new BehaviorSubject(initialState);

  paymentTypeControl: UntypedFormControl = new UntypedFormControl();

  PAYMENT_TYPES = [
    { name: 'Credit Card', value: 'BRAINTREE' },
    { name: 'Direct Debit Bank Account', value: 'DIRECT_DEBIT_BANK_ACCOUNT' },
  ];

  braintreeStatus: any;
  braintreeButtonState: any;
  unsubscribeAll: Subject<any> = new Subject();

  _braintreeHostedFields: BraintreeHostedFieldsComponent;
  @ViewChild(BraintreeHostedFieldsComponent, { static: false })
  set braintreeHostedFields(value: BraintreeHostedFieldsComponent) {
    this._braintreeHostedFields = value;
    if (value) {
      value.status$.pipe(takeUntil(this.unsubscribeAll)).subscribe({
        next: (hostedFieldsStatus: any) => {
          console.log('Braintree status update');
          this.braintreeStatus = hostedFieldsStatus;
          this.braintreeButtonState = {
            loading:
              hostedFieldsStatus.loading && hostedFieldsStatus.isInitialised,
            buttonLabel: 'Save',
          };

          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        },
      });
    }
  }
  get braintreeHostedFields() {
    return this._braintreeHostedFields;
  }

  paymentMethodForm: FormGroup;

  _payment: any;
  @Input()
  set payment(value: any) {
    this._payment = value;
    if (value) {
      if (value.paymentType === 'BRAINTREE' && value.organisationId) {
        //org braintree
        this.paymentMethodType = 'BRAINTREE';
        this.buildForm('BRAINTREE');
        this.paymentMethodForm.addControl(
          'displayName',
          new FormControl('', [
            Validators.required,
            Validators.pattern(`^[a-zA-Z ']+$`),
          ])
        );
        this.paymentMethodForm.get('displayName').patchValue(value.displayName);
        this.paymentMethodForm.updateValueAndValidity();
      } else {
        this.paymentMethodType = value.paymentType;
        this.buildForm(value.paymentType);
        this.paymentMethodForm.updateValueAndValidity();

        setTimeout(() => {
          if (this.paymentMethodForm.get('account')) {
            this.paymentMethodForm.get('account').patchValue(value);
          }
        }, 10);
      }

      this.paymentMethodCreateEditState.next({
        ...this.paymentMethodCreateEditState.value,
        paymentMethod: value as any,
        title: 'Edit Payment Method',
        buttonLabel: 'Update',
      });

      if (value.paymentType === 'ORGANISATION_ACCOUNT') {
        this.paymentMethodForm.get('displayName').patchValue(value.displayName);
      }

      this.changeDetectorRef.markForCheck();
    }
  }
  get payment() {
    return this._payment;
  }

  constructor(
    private organisationApiService: OrganisationApiService,
    private operatorApiService: OperatorApiService,
    private driverApiService: DriverApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private jobApiService: JobApiService,
    private travellerApiService: TravellerApiService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.paymentTypeControl.valueChanges.subscribe((value) => {
      this.buildForm(value);
      if (this.organisation) {
        this.paymentMethodForm.addControl(
          'displayName',
          new FormControl('', [
            Validators.required,
            Validators.pattern(`^[a-zA-Z ']+$`),
          ])
        );
        this.paymentMethodForm.updateValueAndValidity();
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {}

  buildForm(paymentType: string) {
    switch (paymentType) {
      case 'BRAINTREE':
        this.paymentMethodForm = this.fb.group({});

        break;

      case 'ORGANISATION_ACCOUNT':
        this.paymentMethodForm = this.fb.group({
          displayName: [
            null,
            [Validators.required, Validators.pattern(`^[a-zA-Z ']+$`)],
          ],
          contractId: [null, !this.payment ? [Validators.required] : null],
          type: [paymentType],
        });
        break;

      case 'DIRECT_DEBIT_BANK_ACCOUNT':
        this.paymentMethodForm = this.fb.group({
          type: [paymentType],
          account: [null, [Validators.required]],
        });
        break;

      case 'SETTLEMENT_BANK_ACCOUNT':
        this.paymentMethodForm = this.fb.group({
          type: [paymentType],
          account: [null, [Validators.required]],
        });
        break;

      default:
        this.paymentMethodForm = this.fb.group({
          type: [null],
        });
        break;
    }
    this.paymentMethodForm.updateValueAndValidity();

    this.changeDetectorRef.markForCheck();
  }

  toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  submitBraintreePaymentMethod() {
    if (this.organisation && this.payment) {
      this.submit();
    } else {
      this.braintreeHostedFields.requestPayment();
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  save() {
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      loading: true,
      issues: [],
    });

    let createCall: any;

    let payload = {} as any;

    switch (this.paymentTypeControl.value) {
      case 'DIRECT_DEBIT_BANK_ACCOUNT':
        payload = <PaymentMethodModel>{
          account: this.paymentMethodForm.get('account').value,
          displayName: this.paymentMethodForm.get('account').value.accountName,
          type: 'DIRECT_DEBIT_BANK_ACCOUNT',
        };
        break;
      case 'SETTLEMENT_BANK_ACCOUNT':
        payload = <PaymentMethodModel>{
          account: this.paymentMethodForm.get('account').value,
          displayName: this.paymentMethodForm.get('account').value.accountName,
          type: 'SETTLEMENT_BANK_ACCOUNT',
        };
        break;

      case 'ORGANISATION_ACCOUNT':
        //if ORGANISATION_ACCOUNT we need to get the display name and make it unique rather than using account name
        payload = <PaymentMethodModel>{
          displayName: this.paymentMethodForm.get('displayName').value,
          type: 'ORGANISATION_ACCOUNT',
        };
        break;

      default:
        break;
    }

    if (this.operator) {
      createCall = this.operatorApiService.addPaymentMethod(
        payload,
        this.operator.operatorId
      );
    }

    if (this.organisation) {
      if (this.paymentTypeControl.value !== 'ORGANISATION_CARD') {
        payload = Object.assign({}, payload, {
          account: {
            contractId: this.paymentMethodForm.value.contractId,
          },
        });
      }

      createCall = this.organisationApiService.addPaymentMethod(
        payload,
        this.organisation.organisationId
      );
    }

    if (this.organisationGroup) {
      createCall =
        this.organisationApiService.createOrganisationGroupPaymentMethod(
          this.organisationGroup.organisationId,
          this.organisationGroup.organisationGroupId,
          payload
        );
    }

    if (this.driver) {
      createCall = this.driverApiService.createPaymentMethod(
        this.driver.driverId,
        payload
      );
    }

    if (this.job) {
      createCall = this.jobApiService.createPaymentMethod(
        this.job.jobId,
        payload
      );
    }
    console.log(
      'State loading:' + this.paymentMethodCreateEditState.value.loading
    );
    createCall.subscribe(
      (resp: ApiResponse<PaymentMethodModel> | any) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          paymentMethod: resp.data,
        });

        //if operator we are updating this as theri default billing
        if (this.operator) {
          const payload = {
            ...this.operator,
            billingPaymentMethod: {
              paymentMethodId: resp.data.paymentMethodId,
            },
          } as any;

          this.operatorApiService
            .updateOperator(payload, this.operator.operatorId)
            .subscribe({
              next: (operatorResp: ApiResponse<OperatorModel>) => {
                this.paymentMethodCreateEditState.next({
                  ...this.paymentMethodCreateEditState.value,
                  loading: false,
                });
                this.operator = operatorResp.data;

                this.onCreateSuccess.emit(resp.data);
              },
              error: (issues: IssueModel[]) => {
                this.paymentMethodCreateEditState.next({
                  ...this.paymentMethodCreateEditState.value,
                  loading: false,
                  issues: issues,
                });
              },
            });
        } else if (this.job) {
          //the create payment method call on a job returns a job
          //the job create that is using this as a dialog is expecting a job.
          this.onCreateSuccess.emit(resp.data);
        } else {
          this.onCreateSuccess.emit(
            this.paymentTypeControl.value === 'BANK_DEPOSIT'
              ? { type: 'BANK_DEPOSIT' }
              : resp.data
          );
        }
      },
      (error: IssueModel[]) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          issues: error,
        });
      }
    );
  }

  edit() {
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      loading: true,
      issues: [],
    });

    let update = {} as any;

    switch (this.paymentTypeControl.value) {
      case 'DIRECT_DEBIT_BANK_ACCOUNT':
        update = <PaymentMethodModel>{
          account: this.paymentMethodForm.get('account').value,
          displayName: this.paymentMethodForm
            .get('displayName')
            .value.toUpperCase(),
          type: 'DIRECT_DEBIT_BANK_ACCOUNT',
          paymentMethodId:
            this.paymentMethodCreateEditState.value.paymentMethod
              .paymentMethodId,
        };
        break;
      case 'SETTLEMENT_BANK_ACCOUNT':
        update = <PaymentMethodModel>{
          account: this.paymentMethodForm.get('account').value,
          displayName: this.driver
            ? this.paymentMethodForm
                .get('account')
                .value.accountName.toUpperCase()
            : this.paymentMethodForm.get('displayName').value.toUpperCase(),
          type: 'SETTLEMENT_BANK_ACCOUNT',
          paymentMethodId:
            this.paymentMethodCreateEditState.value.paymentMethod
              .paymentMethodId,
        };
        break;

      case 'ORGANISATION_ACCOUNT':
        //if ORGANISATION_ACCOUNT we need to get the display name and make it unique rather than using account name
        update = <PaymentMethodModel>{
          // account: this.paymentMethodForm.get('account').value,
          displayName: this.paymentMethodForm.get('displayName').value,
          type: 'ORGANISATION_ACCOUNT',
        };
        break;

      default:
        break;
    }

    let updateCall: any;

    if (this.operator) {
      updateCall = this.operatorApiService.updatePaymentMethod(
        update,
        this.operator.operatorId
      );
    } else if (this.organisation) {
      update = {
        paymentMethodId:
          this.paymentMethodCreateEditState.value.paymentMethod.paymentMethodId,
        displayName: this.paymentMethodForm.get('displayName').value,
      } as any;

      updateCall = this.organisationApiService.updatePaymentMethod(
        update,
        this.organisation.organisationId
      );
    } else if (this.organisationGroup) {
      update = {
        paymentMethodId:
          this.paymentMethodCreateEditState.value.paymentMethod.paymentMethodId,
        displayName: this.paymentMethodForm.get('displayName').value,
      } as any;

      updateCall =
        this.organisationApiService.updateOrganisationGroupPaymentMethod(
          this.organisationGroup.organisationId,
          this.organisationGroup.organisationGroupId,
          update
        );
    } else if (this.driver) {
      updateCall = this.driverApiService.updateSettlementAccount(
        this.driver.driverId,
        update.paymentMethodId,
        update
      );
    }

    updateCall.subscribe(
      (resp: ApiResponse<PaymentMethodModel> | any) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          paymentMethod: resp.data,
        });

        //operator has this set.
        if (this.operator) {
          const payload = {
            ...this.operator,
            billingPaymentMethod: {
              paymentMethodId: resp.data.paymentMethodId,
            },
          } as any;

          this.operatorApiService
            .updateOperator(payload, this.operator.operatorId)
            .subscribe({
              next: (operatorResp: ApiResponse<OperatorModel>) => {
                this.paymentMethodCreateEditState.next({
                  ...this.paymentMethodCreateEditState.value,
                  loading: false,
                });

                this.operator = operatorResp.data;
                this.onEditSuccess.emit(resp.data);
              },
              error: (issues: IssueModel[]) => {
                this.paymentMethodCreateEditState.next({
                  ...this.paymentMethodCreateEditState.value,
                  loading: false,
                  issues: issues,
                });
              },
            });
        } else {
          this.onEditSuccess.emit(resp.data);
        }
      },
      (error: IssueModel[]) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          issues: error,
        });
      }
    );
  }

  submit() {
    if (this.paymentMethodCreateEditState.value.paymentMethod) {
      this.edit();
    } else {
      this.save();
    }
  }

  createPaymentMethodFromNonce(payload: {
    nonce: string;
    deviceData: string;
    type: string;
  }) {
    this.braintreeHostedFields.loading.next(true);
    this.braintreeHostedFields.loadingMessage = 'Saving card';
    let createCall;
    if (this.driver) {
      createCall = this.driverApiService.createNoncePaymentMethod(
        this.driver.driverId,
        payload
      );
    } else if (this.job) {
      createCall = this.jobApiService.createPaymentMethod(
        this.job.jobId,
        payload
      );
    } else if (this.operator) {
      createCall = this.operatorApiService.createNoncePaymentMethod(
        this.operator.operatorId,
        payload
      );
    } else if (this.organisation) {
      //Grab the display name

      payload = {
        ...payload,
        displayName: this.paymentMethodForm.value.displayName,
      } as any;

      createCall = this.organisationApiService.createNoncePaymentMethod(
        this.organisation.organisationId,
        payload
      );
    } else if (this.organisationGroup) {
      createCall =
        this.organisationApiService.createOrganisationGroupPaymentMethod(
          this.organisationGroup.organisationId,
          this.organisationGroup.organisationGroupId,
          payload as any
        );
    } else if (this.traveller) {
      createCall = this.travellerApiService.createPaymentMethod(
        this.traveller.travellerId,
        payload as any
      );
    }
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      loading: true,
      issues: [],
    });
    createCall.subscribe(
      (resp: ApiResponse<PaymentMethodModel> | any) => {
        //if operator we are updating this as theri default billing

        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          paymentMethod: resp.data,
          loading: false,
        });
        // this.braintreeHostedFields.loading.next(false);

        this.onCreateSuccess.emit(
          this.paymentTypeControl.value === 'BANK_DEPOSIT'
            ? { type: 'BANK_DEPOSIT' }
            : resp.data
        );
      },
      (error: IssueModel[]) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          issues: error,
        });
        this.braintreeHostedFields.loading.next(false);
      }
    );
  }

  brainTreeError(issues: IssueModel[]) {
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      issues: issues,
      loading: false,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
