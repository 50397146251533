import {
  AbstractControl,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { JobPaymentMethodModel } from '@fleet/model';
import { DateTime } from 'luxon';

export function optionalValidator(
  validators?: (ValidatorFn | null | undefined)[]
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const error =
      control.value && control.value !== ''
        ? Validators.compose(validators)(control)
        : null;
    return error;
  };
}

export function minDate(minDate: DateTime): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    console.log(control.value);

    const dateToValidate = DateTime.fromISO(control.value);
    return minDate <= dateToValidate
      ? null
      : {
          minDate:
            'Please ensure date is on or after ' +
            minDate.toFormat('dd/MM/yyyy'),
        };
  };
}

export function maxDate(maxDate: DateTime): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    console.log(control.value);

    const dateToValidate = DateTime.fromISO(control.value);

    return maxDate >= dateToValidate
      ? null
      : {
          maxDate:
            'Please ensure date is on or before ' +
            maxDate.toFormat('dd/MM/yyyy'),
        };
  };
}
export function dateRangeBeforeAfterValidator(
  startDateTerm: string,
  endDateTerm: string,
  ignoreTime?: boolean
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let fromDate = control.get(startDateTerm).value;
    let toDate = control.get(endDateTerm).value;
    if (typeof fromDate === 'string') {
      fromDate = DateTime.fromISO(fromDate);
    }
    if (typeof toDate === 'string') {
      toDate = DateTime.fromISO(toDate);
    }

    //TO DATE COULD BE NULL - IT MAY BE IN AN OPTIONAL STATE.

    if (fromDate && toDate) {
      if (fromDate.isValid && toDate.isValid) {
        if (
          !ignoreTime &&
          fromDate.hasSame(toDate, 'day') &&
          (fromDate.hour > toDate.hour ||
            (fromDate.hour === toDate.hour && fromDate.minute >= toDate.minute))
        ) {
          return {
            timeNotFuture: true,
            dateRangeBeforeAfter: true,
          };

          //So lets purposely only set the time to need to be
        } else if (fromDate.equals(toDate) || fromDate > toDate) {
          // its the same date, but the time is okay

          return {
            dateNotFuture: true,
            dateRangeBeforeAfter: true,
          };
        }
      } else {
        return {
          dateRangeBeforeAfter: true,
          timeNotFuture: true,
        };
      }

      if (fromDate.toMillis() > toDate.toMillis()) {
        return {
          dateNotFuture: true,
          dateRangeBeforeAfter: true,
        };
      }
    } else if (fromDate && fromDate.invalid) {
      return {
        dateNotFuture: true,
        dateRangeBeforeAfter: true,
      };
    } else if (toDate && toDate.invalid) {
      return {
        dateNotFuture: true,
        dateRangeBeforeAfter: true,
      };
    }

    return null;
  };
}

export function bsbValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (value === null || value === undefined || value === '') {
      return null; // allow empty values
    }

    const bsbRegex = /^(\d{3})-?(\d{3})$/;
    if (!bsbRegex.test(value)) {
      return { invalidBsb: true };
    }

    return null;
  };
}

export function latLngValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.latitude && control.value.longitude) {
      return null;
    } else {
      return { placeInvalid: 'Please select a valid address' };
    }
  };
}
export function addressValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null; // If there's no value, it's considered valid.
    }
    if (
      value.structuredAddress?.placeName ||
      value.structuredAddress?.streetName ||
      value.organisationGroupLocationId
    ) {
      return null; // If it has a structuredAddress or both locationId and organisationGroupLocationId, it's valid.
    }
    // If none of the above conditions are met, the address is invalid.
    return { placeInvalid: 'Please select a valid address' };
  };
}

export function postalValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.structuredAddress) {
      return null;
    } else {
      return { placeInvalid: 'Please select a valid address' };
    }
  };
}

export function hasRequiredValidator(control: AbstractControl): boolean {
  if (!control.validator) return false;

  const validationResult = Object.assign(
    {},
    control.validator({} as AbstractControl)
  );
  return validationResult && validationResult.required;
}

export const minDateValidator = (
  minDate: DateTime,
  excludeTime?: boolean
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    if (!controlValue) {
      return null; // If no value is present, do not perform validation for minDate
    }

    let combinedDateTime: DateTime;

    if (controlValue instanceof DateTime) {
      combinedDateTime = controlValue;
    } else {
      if (!controlValue.date && !controlValue.time) {
        return null; // If no value is present, do not perform validation for minDate
      } else if (!controlValue.date && controlValue.time) {
        return { dateNotFuture: true };
      }

      const inputDate = controlValue.date;
      const inputTime = controlValue.time;

      const timeParts = inputTime ? inputTime.split(':') : null;

      if (!timeParts && !excludeTime) {
        return { timeNotFuture: true };
      }
      combinedDateTime = inputDate.set({
        hour: excludeTime ? parseInt('00', 10) : parseInt(timeParts[0], 10),
        minute: excludeTime ? parseInt('00', 10) : parseInt(timeParts[1], 10),
      });
    }

    if (
      !excludeTime &&
      minDate.hasSame(combinedDateTime, 'day') &&
      minDate.hour > combinedDateTime.hour
    ) {
      // It is the SAME date and the from TIMES are greater..
      return { timeNotFuture: true };
    } else if (
      !excludeTime &&
      minDate.hasSame(combinedDateTime, 'day') &&
      minDate.hour === combinedDateTime.hour &&
      minDate.minute >= combinedDateTime.minute
    ) {
      return { timeNotFuture: true };
    } else if (excludeTime && minDate.hasSame(combinedDateTime, 'day')) {
      return { dateNotFuture: true };
    } else if (minDate.toMillis() > combinedDateTime.toMillis()) {
      return { dateNotFuture: true };
    } else {
      return null; // If minDate and time are not in the past, validation passes
    }
  };
};
export function jobPaymentMethodValidator(
  groupRequiredOnPayDriver: boolean
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as JobPaymentMethodModel;
    if (value?.type === 'OTHER' && groupRequiredOnPayDriver) {
      return value.organisationGroupId
        ? null
        : {
            groupRequiredOnPayDriver:
              'Please ensure you set a group on pay driver',
          };
    } else if (value?.type === 'OTHER' || value?.paymentMethodId) {
      return null; // The control is valid if it has type 'OTHER' or a paymentMethodId
    } else if (value?.organisationGroupId) {
      let errorMessage =
        'This organisation group payment option cannot be used.';
      if (value.profileId) {
        errorMessage +=
          ' Please set a default payment method on your organisation profile.';
      } else {
        errorMessage +=
          ' Please choose a group that has a payment method assigned. ';
      }
      return {
        organisationGroupPayment: errorMessage,
      };
    }
    return null;

    // /return { invalidJobPaymentMethod: 'The payment method is not valid.' }; // Return an error object if the conditions are not met
  };
}
