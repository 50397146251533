import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-view-with-left-and-right-drawer',
  templateUrl: './view-with-left-and-right-drawer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewWithLeftAndRightDrawerComponent implements OnInit {
  @Input() customRightDrawerWidth = '';
  @Output() drawerClosed = new EventEmitter();
  @Input() disableLeftSidebarClose = true;
  @Input() hideLeftDrawer = false;
  @Input() customLeftDrawerWidth = '';
  leftDrawerMode: 'over' | 'side' = 'side';
  @Input() leftDrawerOpened = true;
  rightDrawerOpened = false;
  rightDrawerBackdrop = true;
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    // this.leftDrawerOpened = this.disableLeftSidebarClose
    //   ? this.disableLeftSidebarClose
    //   : true;
  }

  setRightDrawer(isOpen: boolean) {
    this.rightDrawerOpened = isOpen;
    if (!isOpen) {
      //only if it is closing..
      this.drawerClosed.emit();
    }
    this.changeDetectorRef.markForCheck();
  }

  setLeftDrawer(isOpen: boolean) {
    this.leftDrawerOpened = isOpen;
    if (!isOpen) {
      //only if it is closing..
      this.drawerClosed.emit();
    }
    this.changeDetectorRef.markForCheck();
  }
}
