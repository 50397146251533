<img
  *ngIf="image; else noImage"
  class="rounded-full object-cover"
  [ngClass]="'h-' + size + ' w-' + size"
  [src]="image"
  onerror="this.style.display='none'"
/>
<ng-template #noImage>
  <span
    class="inline-block rounded-full overflow-hidden bg-gray-100"
    [ngClass]="'h-' + size + ' w-' + size"
  >
    <svg
      class="h-full w-full text-gray-300"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
      />
    </svg>
  </span>

  <!-- <span
    class="inline-flex items-center justify-center rounded-full"
    [ngClass]="'h-' + size + ' w-' + size + ' ' + background"
  >
    <span class="text-xl font-medium leading-none text-white">
      {{ initials }}
    </span>
  </span> -->
</ng-template>
