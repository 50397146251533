/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import {
  AddressModel,
  ApiResponse,
  IssueModel,
  LocationModel,
  PlaceModel,
} from '@fleet/model';
import { v4 as uuidv4 } from 'uuid';

import { MOCK_AIRPORT_PLACE, PlaceApiService } from '@fleet/api';
import { centerOfAustralia } from '@fleet/utilities';

export interface LocationSearchState {
  searching: boolean;
  error: any;
  results: PlaceModel[];
  isLocationListOverlayOpen: boolean;
  textQuery: string;
  moreThanOnePlaceDetail: boolean;
}

const initialLocationSearchState: LocationSearchState = <LocationSearchState>{
  results: [],
  searching: false,
  error: null,
  isLocationListOverlayOpen: false,
  textQuery: null,
};

export interface ReverseGeocodeState {
  loading: boolean;
  issues: IssueModel[];
  latitude: number;
  longitude: number;
}

//default location center of australia

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  locationSearchState$: BehaviorSubject<LocationSearchState> =
    new BehaviorSubject(initialLocationSearchState);

  inputArrowDown$: Subject<boolean> = new Subject();

  selectedPlace$: BehaviorSubject<PlaceModel> = new BehaviorSubject(null);

  selectedPlaceDetail$: BehaviorSubject<PlaceModel> = new BehaviorSubject(null);

  selectedLocation$: Subject<LocationModel> = new Subject();

  currentWaypointIndex: BehaviorSubject<number> = new BehaviorSubject(null);

  reverseGeoCodedPlace$: Subject<PlaceModel> = new Subject();
  reverseGeocode: BehaviorSubject<ReverseGeocodeState> = new BehaviorSubject(
    {} as ReverseGeocodeState
  );
  reverseGeocodeResults: BehaviorSubject<PlaceModel[]> = new BehaviorSubject(
    []
  );

  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  searchQueryString: Subject<string> = new Subject();

  searchParams: Subject<any> = new Subject();

  searchLocation: {
    latitude: string;
    longitude: string;
    dontOveride?: boolean;
  } = { ...centerOfAustralia, dontOveride: false };

  searchSessionId: string;
  excludeDisambiguate = false;

  fetchingReverseGeoPlaceDetail = false;

  airportPickup: BehaviorSubject<PlaceModel> = new BehaviorSubject(
    MOCK_AIRPORT_PLACE
  );

  manualAddressEntry: BehaviorSubject<AddressModel> = new BehaviorSubject(null);

  constructor(private placesApiService: PlaceApiService) {
    this.wireSearch();
    this.wirePlaceDetail();
    this.searchSessionId = uuidv4();

    this.selectedPlaceDetail$.subscribe((value) => {
      console.log('Selected place detail sub');
      if (value) {
        this.locationSearchState$.next(initialLocationSearchState);
      }

      this.searchSessionId = uuidv4();
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setSearchLocation(
          position.coords.latitude.toString(),
          position.coords.longitude.toString(),
          false
        );
      });
    }
  }

  get locationSearch$() {
    return this.locationSearchState$.asObservable();
  }

  get reverseGeocode$() {
    return this.reverseGeocode.asObservable();
  }

  get reverseGeocodeResult$() {
    return this.reverseGeocodeResults.asObservable();
  }

  setSearchLocation(latitude: string, longitude: string, dontOveride: boolean) {
    if (!this.searchLocation.dontOveride) {
      this.searchLocation = {
        latitude: latitude,
        longitude: longitude,
        dontOveride: dontOveride,
      };
    }
  }

  setSelectedPlace(place: PlaceModel) {
    this.selectedPlace$.next(place);
  }

  get selectedPlace() {
    return this.selectedPlace$.value;
  }

  // handleWaypointInputKeydown() {
  //   if (
  //     this.locationSearchState$.value.results.length > 0 &&
  //     !this.locationSearchState$.value.searching
  //   ) {
  //     //results exist AND not loading
  //     this.inputArrowDown$.next(true);
  //   }
  //   //else who cares
  // }

  // setLocationOverlayStatus(value: boolean) {
  //   this.locationSearchState$.next({
  //     ...this.locationSearchState$.value,
  //     isLocationListOverlayOpen: value,
  //   });
  // }

  clearResults() {
    this.locationSearchState$.next({
      ...this.locationSearchState$.value,
      results: [],
    });
  }

  setSelectedPlaceDetail(value: any) {
    if (value !== this.selectedPlaceDetail$.value) {
      this.selectedPlaceDetail$.next(value);
    }
  }

  wireSearch() {
    this.searchParams.subscribe((params) => {
      //begin search
      this.locationSearchState$.next({
        ...this.locationSearchState$.value,
        searching: true,
        error: null,
        isLocationListOverlayOpen: true,
        textQuery: params.query,
      });
      this.loading.next(true);

      const search = {
        latitude: params.latitude,
        longitude: params.longitude,
        query: params.query,
        locationType: params.locationType,
        sessionId: this.searchSessionId,
        limit: '10',
        offset: '0',
      } as any;

      if (params.filterType) {
        search['filterType'] = params.filterType;
      }
      if (params.countryCode) {
        search['countryCode'] = params.countryCode;
      }

      this.placesApiService.autocompleteSearch(search).subscribe({
        next: (resp: ApiResponse<PlaceModel[]> | any) => {
          this.locationSearchState$.next({
            ...this.locationSearchState$.value,
            searching: false,
            error: null,
            results: resp.data,
          });
          this.loading.next(false);
        },
        error: (error: any) => {
          this.locationSearchState$.next({
            ...this.locationSearchState$.value,
            searching: false,
            error: error,
            textQuery: null,
          });
          this.loading.next(false);
        },
      });
    });
  }

  wirePlaceDetail() {
    this.selectedPlace$.subscribe({
      next: (place: PlaceModel) => {
        if (place && place.id) {
          const params = { sessionId: this.searchSessionId } as any;
          if (this.excludeDisambiguate) {
            params['disambiguate'] = false;
          }
          this.loading.next(true);
          this.placesApiService.getPlace(place.id, params).subscribe({
            next: (resp: ApiResponse<PlaceModel[]>) => {
              this.loading.next(false);
              if (resp.data.length > 1) {
                //set search list
                this.locationSearchState$.next({
                  ...this.locationSearchState$.value,
                  results: resp.data,
                  moreThanOnePlaceDetail: true,
                });
              } else {
                //only one so set the detail
                this.locationSearchState$.next({
                  ...this.locationSearchState$.value,
                  results: resp.data,
                  moreThanOnePlaceDetail: false,
                });
                this.selectedPlaceDetail$.next(resp.data[0]);
              }
              if (this.fetchingReverseGeoPlaceDetail) {
                this.reverseGeoCodedPlace$.next(resp.data[0]);
                this.fetchingReverseGeoPlaceDetail = false;
              }
            },
            error: (error: any) => {
              //fall back is just to set the selected place for search to the place detail
              this.selectedPlaceDetail$.next(place);
              this.loading.next(false);
              this.fetchingReverseGeoPlaceDetail = false;
            },
          });
        }
      },
    });
  }

  wireManualEntry() {
    this.manualAddressEntry.asObservable().subscribe({
      next: (address: AddressModel) => {
        const addressSearchString =
          address.streetNumber +
          ' ' +
          address.streetName +
          ' ' +
          address.locality;
        this.searchParams.next(addressSearchString);
      },
    });
  }

  reverseGeoCodeAndSetSelectedPlaceDetail(latitude: any, longitude: any) {
    this.fetchingReverseGeoPlaceDetail = true;
    this.reverseGeocode.next({
      issues: [],
      loading: true,
      latitude: latitude,
      longitude: longitude,
    });
    this.placesApiService
      .reverseGeocode({ latitude: latitude, longitude: longitude })
      .subscribe({
        next: (resp: ApiResponse<PlaceModel[]> | any) => {
          this.reverseGeocodeResults.next(resp.data);
          this.reverseGeocode.next({
            ...this.reverseGeocode.value,
            loading: false,
          });

          // if (resp.data.length > 0) {
          //   //right now lets just take the first one and get details
          //   this.selectedPlace$.next(resp.data[0]);
          // }
        },
      });
  }

  resetReverseGeoState() {
    this.reverseGeocode.next({} as ReverseGeocodeState);
    this.reverseGeocodeResults.next([]);
  }

  setCurrentWaypointIndex(index: any) {
    this.currentWaypointIndex.next(index);
  }

  setPlaceDetailFromAddressModel(addressModel: AddressModel) {
    const displayLine1 =
      (addressModel.unitNumber ? addressModel.unitNumber + ' ' : '') +
      (addressModel.streetNumber ? addressModel.streetNumber + ' ' : '') +
      addressModel.streetName;
    const displayLine2 =
      addressModel.locality +
      ' ' +
      addressModel.postalCode +
      ' ' +
      addressModel.regionCode;
    addressModel.streetName;
    const placeDetail = {
      structuredAddress: addressModel,
      displayLine1: displayLine1,
      displayLine2: displayLine2,
    } as PlaceModel;
    this.selectedPlaceDetail$.next(placeDetail);
  }

  get airportPickup$() {
    return this.airportPickup.asObservable();
  }
}
