import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fleet-selection-list-in-drawer',
  templateUrl: './selection-list-in-drawer.component.html',
  styleUrls: ['./selection-list-in-drawer.component.scss'],
})
export class SelectionListInDrawerComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Input() title: string;
  constructor() {}

  ngOnInit(): void {}
}
