import { Component, OnInit } from '@angular/core';
import { VersionUpdateService } from './../version-update.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ChangeLogComponent } from './../change-log/change-log.component';

@Component({
  selector: 'fleet-update-banner',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  template: `<div
    class="bg-primary-600"
    *ngIf="showUpdateAvailable$ | async as showUpdateAvailable"
  >
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex p-2 rounded-lg bg-primary-800">
            <!-- Heroicon name: outline/speakerphone -->
            <svg
              class="h-6 w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
              />
            </svg>
          </span>
          <p class="ml-3 font-medium text-white truncate">
            <span class="md:hidden"> New Update! </span>
            <span class="hidden md:inline">
              There is a new update for this app!
            </span>
          </p>
        </div>
        <div
          class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto mr-6"
        >
          <a
            (click)="openReleaseNotes()"
            class="text-white text-sm hover:underline cursor-pointer"
          >
            Release Notes
          </a>
        </div>
        <div
          class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
        >
          <a
            (click)="activate()"
            class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-600 bg-white hover:bg-primary-50 cursor-pointer"
          >
            Update Now
          </a>
        </div>
      </div>
    </div>
  </div>`,
})
export class UpdateBannerComponent implements OnInit {
  showUpdateAvailable$: Observable<boolean>;
  constructor(
    private versionUpdateService: VersionUpdateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.showUpdateAvailable$ = this.versionUpdateService.showUpdateAvailable$;
  }

  dismiss() {
    this.versionUpdateService.dismissUpdate();
  }

  activate() {
    this.versionUpdateService.activateUpdate();
  }

  openReleaseNotes() {
    this.dialog.open(ChangeLogComponent, {
      width: '80%',
      height: '80%',
    });
  }
}
