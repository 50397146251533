import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callerType',
})
export class CallerTypePipe implements PipeTransform {
  transform(type: string): { icon: string; tooltip: string } {
    if (type) {
      switch (type) {
        case 'DRIVER':
          return { icon: 'local_taxi', tooltip: 'Driver' };

        case 'ORGANISATION':
          return { icon: 'business', tooltip: 'Organisation' };

        case 'UNKNOWN':
          return { icon: 'device_unknown', tooltip: 'Unknown' };

        case 'TRAVELLER':
          return { icon: 'hail', tooltip: 'Traveller' };

        case 'ORGANISATION_TRAVELLER':
          return { icon: 'hail', tooltip: 'Organisation Traveller' };

        case 'CALL_CENTRE':
          return { icon: 'support_agent', tooltip: 'Call Centre' };

        default:
          break;
      }
    }
    return null;
  }
}
