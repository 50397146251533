<mat-form-field class="w-full" [ngClass]="classes">
  <mat-label
    *ngIf="label"
    [ngClass]="[matLabelClasses ? matLabelClasses : '']"
    >{{ label }}</mat-label
  >

  <mat-select [placeholder]="placeholder" [formControl]="control">
    <mat-option *ngFor="let region of regions" [value]="region">
      {{ region.regionCode }}
    </mat-option>
  </mat-select>
</mat-form-field>
