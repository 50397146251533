import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  DriverModel,
  NetworkGroupModel,
  NetworkUserModel,
  VehicleModel,
} from '@fleet/model';

import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkGroupApiService {
  host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.network;
  }

  searchNetworkGroups(
    params: any,
    networkId: string
  ): Observable<ApiResponse<NetworkGroupModel[]> | any> {
    return this.http
      .get(`${this.host}/${networkId}/group`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getNetworkGroup(
    networkGroupId: string,
    networkId: string
  ): Observable<ApiResponse<NetworkGroupModel> | any> {
    return this.http
      .get(`${this.host}/${networkId}/group/${networkGroupId}`)
      .pipe(catchError(handleApiError));
  }

  addDriverToNetworkGroup(
    networkId: string,
    networkGroupId: string,
    driver: DriverModel
  ): Observable<ApiResponse<DriverModel> | any> {
    return this.http
      .post(`${this.host}/${networkId}/group/${networkGroupId}/driver`, driver)
      .pipe(catchError(handleApiError));
  }

  addUserToNetworkGroup(
    networkId: string,
    networkGroupId: string,
    user: NetworkUserModel
  ): Observable<ApiResponse<NetworkUserModel> | any> {
    return this.http
      .post(`${this.host}/${networkId}/group/${networkGroupId}/user`, user)
      .pipe(catchError(handleApiError));
  }

  addVehicleToNetworkGroup(
    networkId: string,
    networkGroupId: string,
    vehicle: VehicleModel
  ): Observable<ApiResponse<VehicleModel> | any> {
    return this.http
      .post(
        `${this.host}/${networkId}/group/${networkGroupId}/vehicle`,
        vehicle
      )
      .pipe(catchError(handleApiError));
  }

  createNetworkGroup(
    networkId: string,
    networkGroup: NetworkGroupModel
  ): Observable<ApiResponse<NetworkGroupModel> | any> {
    return this.http
      .post(`${this.host}/${networkId}/group/`, networkGroup)
      .pipe(catchError(handleApiError));
  }

  removeDriverFromNetworkGroup(
    networkId: string,
    networkGroupId: string,
    driverId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${networkId}/group/${networkGroupId}/driver/${driverId}`
      )
      .pipe(catchError(handleApiError));
  }

  removeUserFromNetworkGroup(
    networkId: string,
    networkGroupId: string,
    networkUserId: string
  ): Observable<ApiResponse<null> | any> {
    return this.http
      .delete(
        `${this.host}/${networkId}/group/${networkGroupId}/user/${networkUserId}`
      )
      .pipe(catchError(handleApiError));
  }

  removeVehicleFromGroup(
    networkId: string,
    networkGroupId: string,
    vehicleId: string
  ): Observable<ApiResponse<null> | any> {
    return this.http
      .delete(
        `${this.host}/${networkId}/group/${networkGroupId}/vehicle/${vehicleId}`
      )
      .pipe(catchError(handleApiError));
  }

  updateNetworkGroup(
    networkId: string,
    networkGroupId: string,
    networkGroup: NetworkGroupModel
  ): Observable<ApiResponse<NetworkGroupModel> | any> {
    return this.http
      .post(`${this.host}/${networkId}/group/${networkGroupId}`, networkGroup)
      .pipe(catchError(handleApiError));
  }

  searchNetworkGroupUsers(
    params: any,
    networkId: string
  ): Observable<ApiResponse<NetworkUserModel[]> | any> {
    return this.http
      .get(`${this.host}/${networkId}/user`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  createNetworkGroupUser(user: NetworkUserModel, networkId: string) {
    return this.http
      .post(`${this.host}/${networkId}/user`, user)
      .pipe(catchError(handleApiError));
  }
}
