import { Routes } from '@angular/router';
import { FunctionAuthorisedGuard } from '@fleet/auth';

import { JobDetailContainerComponent } from './components/job-detail-container/job-detail-container.component';
export const jobDetailRoutes: Routes = [
  {
    path: '',
    component: JobDetailContainerComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'not-authorised',
        loadChildren: () =>
          import('@fleet/authorisation').then((m) => m.AuthorisationModule),
      },
      {
        path: 'edit/:jobId',
        canActivate: [FunctionAuthorisedGuard],
        data: { functions: ['JOB_DETAIL:update'] },

        loadChildren: () =>
          import('./../job-create/job-create.module').then(
            (m) => m.JobCreateModule
          ),
      },
      {
        path: 'dashboard',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import('./../job-dashboard/job-dashboard.module').then(
            (m) => m.JobDashboardModule
          ),
        data: {
          title: 'Dashboard',
          showHeader: true,
          functions: ['JOB_DETAIL:read'],
        },
      },
      {
        path: 'map',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import('./../job-map/job-map.module').then((m) => m.JobMapModule),
        data: { title: 'Map', functions: ['JOB_MAP:read'] },
      },
      {
        path: 'timeline',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import(
            './../job-detail/components/job-timeline-container/job-timeline-container.module'
          ).then((m) => m.JobTimelineContainerModule),
        data: {
          title: 'Timeline',
          showHeader: false,
          functions: ['JOB_TIMELINE:read'],
        },
      },
      {
        path: 'dispatch-logs',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import(
            './../job-dispatch-logs-view/job-dispatch-logs-view.module'
          ).then((m) => m.JobDispatchLogsViewModule),
        data: {
          title: 'Dispatch Logs',
          showHeader: false,
          functions: ['JOB_DISPATCH_LOGS:read'],
        },
      },

      {
        path: 'pricing',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import('./../job-pricing/job-pricing.module').then(
            (m) => m.JobPricingModule
          ),
        data: {
          title: 'Pricing',
          showHeader: true,
          functions: ['JOB_PRICING:read'],
        },
      },

      {
        path: 'payments',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import('./../job-payment-sales/job-payment-sales.module').then(
            (m) => m.JobPaymentSalesModule
          ),
        data: {
          title: 'Payment/Sales',
          showHeader: true,
          functions: ['JOB_PAYMENT:read'],
        },
      },

      {
        path: 'ratings',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import('./../job-ratings/job-ratings.module').then(
            (m) => m.JobRatingsModule
          ),
        data: {
          title: 'Ratings',
          showHeader: true,
          functions: ['JOB_RATINGS:read'],
        },
      },

      {
        path: 'communications',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import('./../job-communications/job-communications.module').then(
            (m) => m.JobCommunicationsModule
          ),
        data: {
          title: 'Calls',
          showHeader: false,
          functions: ['JOB_CALLS:read'],
        },
      },

      {
        path: 'notes',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import('./../job-notes/job-notes.module').then(
            (m) => m.JobNotesModule
          ),
        data: {
          title: 'Notes',
          showHeader: false,
          functions: ['JOB_NOTES:read'],
        },
      },
      {
        path: 'tickets',
        canActivate: [FunctionAuthorisedGuard],
        loadChildren: () =>
          import('./../job-tickets/job-tickets.module').then(
            (m) => m.JobTicketsModule
          ),
        data: {
          title: 'Tickets',
          showHeader: false,
          functions: ['JOB_TICKETS:read'],
        },
      },
    ],
  },
];
