import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationListItemComponent } from './location-list-item.component';
import { LocationListItemPipe } from './location-list-item.pipe';

@NgModule({
  declarations: [LocationListItemComponent, LocationListItemPipe],
  imports: [CommonModule],
  exports: [LocationListItemComponent, LocationListItemPipe],
})
export class LocationListItemModule {}
