<fleet-dialog-layout
  *ngIf="ticketCreateEditState | async as state"
  [title]="state.title"
  [instructions]="state.instructions"
>
  <form
    slot="main"
    *ngIf="form"
    [formGroup]="form"
    class="flex flex-col gap-3 w-full"
  >
    <mat-form-field>
      <mat-label>Category type</mat-label>

      <mat-select formControlName="ticketType">
        <mat-option *ngFor="let category of CATEGORY_TYPES" [value]="category">
          {{ category | titleCaseAndClean }}
        </mat-option>
      </mat-select>
      <mat-error>Category type is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Issue type</mat-label>

      <mat-select formControlName="issueType">
        <mat-option *ngFor="let issue of ISSUE_TYPES" [value]="issue">
          {{ issue | titleCaseAndClean }}
        </mat-option>
      </mat-select>
      <mat-error>Issue type is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Content</mat-label>
      <textarea
        matInput
        formControlName="content"
        placeholder="Content"
      ></textarea>
      <mat-error>Content must be provided</mat-error>
    </mat-form-field>

    <ng-container *ngIf="job">
      <mat-checkbox *ngIf="job.driver" formControlName="linkDriver"
        >Link Driver</mat-checkbox
      >
      <mat-checkbox *ngIf="job.traveller" formControlName="linkTraveller"
        >Link Traveller</mat-checkbox
      >
      <mat-checkbox *ngIf="job.vehicle" formControlName="linkVehicle"
        >Link Vehicle</mat-checkbox
      >
      <!-- <mat-checkbox *ngIf="job.payment" formControlName="linkPayment">Link Payment</mat-checkbox> -->
    </ng-container>
  </form>

  <fleet-progress-button
    slot="action"
    [state]="state"
    [disabled]="!form.valid || state.loading"
    (onClick)="createTicket()"
  >
  </fleet-progress-button>

  <fuse-alert
    slot="error"
    *ngFor="let alert of state.issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>
</fleet-dialog-layout>
