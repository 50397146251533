import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobOrganisationCardComponent } from './job-organisation-card.component';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [JobOrganisationCardComponent],
  imports: [CommonModule, RouterModule, MatTooltipModule],
  exports: [JobOrganisationCardComponent],
})
export class JobOrganisationCardModule {}
