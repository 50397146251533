import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetTravellerLayoutComponent } from './fleet-traveller-layout.component';

import { FuseFullscreenModule } from '@fleet/fuse/lib/components/fullscreen';

import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationModule } from '@fleet/fuse';
import { UpdateBannerComponent } from '@fleet/version';
@NgModule({
  declarations: [FleetTravellerLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    FuseFullscreenModule,
    FuseNavigationModule,
    UpdateBannerComponent,
  ],
  exports: [FleetTravellerLayoutComponent],
})
export class FleetTravellerLayoutModule {}
