import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { RelatedEntitySearchLayoutModule } from '@fleet/layout';

import { SidebarHeaderModule } from '@fleet/ui';
import { JobTemplateCreateEditModule } from '../job-template-create-edit/job-template-create-edit.module';
import { EntityJobTemplateSearchComponent } from './entity-job-template-search.component';
import { JobTemplateSearchFormModule } from './job-template-search-form/job-template-search-form.module';
import { JobTemplateSearchResultsModule } from './job-template-search-results/job-template-search-results.module';

@NgModule({
  declarations: [EntityJobTemplateSearchComponent],
  imports: [
    CommonModule,
    RelatedEntitySearchLayoutModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,

    FuseAlertModule,
    AlertsFromIssuesModule,
    SidebarHeaderModule,
    JobTemplateSearchResultsModule,
    JobTemplateCreateEditModule,
    MatDialogModule,
    JobTemplateSearchFormModule,
  ],
  exports: [EntityJobTemplateSearchComponent],
})
export class EntityJobTemplateSearchModule {}
