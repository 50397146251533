import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettlementApiService {
  host;
  settlementHost;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.admin;
    this.settlementHost = env.host + paths.settlement;
  }

  // searchRecentSettlements() {
  //   return this.http
  //     .get(`${this.settlementHost}/batch/search`, {
  //       params: {
  //         limit: '100',
  //         offset: '0',
  //       },
  //     })
  //     .pipe(catchError(handleApiError));
  // }

  searchSettlementBatches(params: any) {
    return this.http
      .get(`${this.settlementHost}/batch/search`, {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  searchSettlements(params: any) {
    return this.http
      .get(`${this.settlementHost}/search`, {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  rejectSettlement(rejectionModel: any, settlementId: string) {
    return this.http
      .put(`${this.settlementHost}/${settlementId}/reject`, rejectionModel)
      .pipe(catchError(handleApiError));
  }

  initiateSettlements(initiateModel: any) {
    return this.http
      .post(`${this.settlementHost}`, initiateModel)
      .pipe(catchError(handleApiError));
  }

  getRejectionReasons() {
    return this.http
      .get(`${this.settlementHost}/rejectionReasons`)
      .pipe(catchError(handleApiError));
  }

  downloadWestpacABA(settlementBatchId: string): Observable<any> {
    return this.http
      .get(`${this.settlementHost}/batch/${settlementBatchId}`, {
        observe: 'response',
        responseType: 'blob' as 'json',
        params: { settlementType: 'WESTPAC' },
      })
      .pipe(catchError(handleApiError));
  }
}
