import { FuseNavigationItem, NetworkUserModel } from '@fleet/model';

export function isNavigationItemAllowed(
  nav: FuseNavigationItem,
  functions: any[]
) {
  if (nav.securityFunctions) {
    let foundFunction;
    nav.securityFunctions.every((functionAction: string) => {
      const split = functionAction.split(':');
      const functionType = split[0];
      const action = split[1];
      foundFunction = functions.find(
        (s) => s.type === functionType && s[action]
      ) as any;
      if (foundFunction) {
        return false;
      }
      return true;
    });
    return foundFunction ? true : false;
  }

  if (!nav.securityFunctionType) {
    return true;
  } else {
    const foundFunction = functions.find(
      (s) => s.type === nav.securityFunctionType && s[nav.securityAction]
    ) as any;
    return foundFunction ? true : false;
  }
}

export function filterNavigationItemsByFunctions(
  navigationItems: FuseNavigationItem[],
  functions: any[]
): FuseNavigationItem[] {
  return navigationItems
    .filter((nav) => isNavigationItemAllowed(nav, functions))
    .map((nav) => {
      return {
        ...nav,
        children: nav.children
          ? filterNavigationItemsByFunctions(nav.children, functions)
          : nav.children,
      } as any;
    });
}

export function filterNavigationItemsForFleet(
  navigationItems: FuseNavigationItem[],
  user: any
): FuseNavigationItem[] {
  return navigationItems
    .filter((nav) => {
      if (nav.fleetOnly) {
        return user?.username?.endsWith('@fleet.com.au');
      }

      return true;
    })
    .map((nav) => {
      const filteredChildren = nav.children
        ? filterNavigationItemsForFleet(nav.children, user)
        : nav.children;
      return {
        ...nav,
        children: filteredChildren,
      } as any;
    });
}

// filterByUserFunction(
//     navigation: FuseNavigationItem[],
//     functions?: FunctionPermissionModel[]
//   ) {
//     const allowedNavigation: FuseNavigationItem[] = [];
//     // if (!functions) {
//     //   functions = [...this.functions];
//     // }

//     navigation.forEach((nav: FuseNavigationItem) => {
//       if (isNavigationItemAllowed(nav, functions)) {
//         if (nav.children) {
//           //we only have one level deep currently
//           allowedNavigation.push({
//             ...nav,
//             children: filterNavigationItemsByFunctions(nav.children, functions),
//           });
//         } else {
//           allowedNavigation.push(nav);
//         }
//       }
//     });
//     return allowedNavigation;
//   }
