/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fleet-settings-tab-label',
  templateUrl: './settings-tab-label.component.html',
  styleUrls: ['./settings-tab-label.component.scss'],
})
export class SettingsTabLabelComponent implements OnInit {
  // @Input() selectedPanel: any;
  @Input() panel: any;

  constructor() {}

  ngOnInit(): void {}
}
