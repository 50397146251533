import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { JobApiService } from '@fleet/api';
import { ApiResponse, JobModel, JobStatus } from '@fleet/model';
import { OrganisationService } from '@fleet/organisation-shared';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { failureNotification } from '@fleet/utilities';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { JobDetailService } from '../job-detail/job-detail.service';

// export function jobGuard(
//   route: ActivatedRouteSnapshot,
//   state: RouterStateSnapshot
// ) {
//   const router = inject(Router);
//   // const activatedRouteSnapshot = Inject(ActivatedRouteSnapshot);
//   const jobService = inject(JobDetailService);
//   const onScreenNotifcationService = inject(OnscreenNotificationService);
//   const jobApiService = inject(JobApiService);

//   const organisationService = inject(OrganisationService);
//   const fleetProduct = (inject(new InjectionToken<string>('env')) as any)
//     .fleetProduct;
//   const jobId = route.paramMap.get('jobId');
//   return jobApiService.getJob(jobId).pipe(
//     take(1),
//     mergeMap((resp: ApiResponse<JobModel> | any) => {
//       const job = resp.data;
//       if (job) {
//         if (
//           job.jobStatus === JobStatus.OPEN ||
//           job.jobStatus === JobStatus.ACKNOWLEDGEMENT_REQUIRED
//         ) {
//           // redirect to job form
//           let redirectUrl = '/job/edit/' + job.jobId;
//           if (fleetProduct === 'HUB' && organisationService.organisationId) {
//             redirectUrl =
//               '/organisation/' +
//               organisationService.organisationId +
//               redirectUrl;
//           }
//           return of(router.parseUrl(redirectUrl));
//         } else {
//           jobService.setJob(job);
//           return of(true);
//         }
//       } else {
//         return of(false);
//       }
//     }),
//     catchError((error: any) => {
//       onScreenNotifcationService.setNotification({
//         ...failureNotification,
//         title: 'Failed to load job!',
//         icon: { color: 'red', name: 'warning' },
//       } as OnScreenNotification);
//       //this.router.navigate(['/job']);
//       return of(false);
//     })
//   );
// }

@Injectable({
  providedIn: 'root',
})
export class JobGuard {
  fleetProduct: string;
  constructor(
    private jobApiService: JobApiService,
    private router: Router,
    private jobService: JobDetailService,
    private onScreenNotifcationService: OnscreenNotificationService,

    @Inject('env') env: any,
    private organisationService: OrganisationService
  ) {
    this.fleetProduct = env.fleetProduct;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const jobId = route.paramMap.get('jobId');
    return this.jobApiService.getJob(jobId).pipe(
      take(1),
      mergeMap((resp: ApiResponse<JobModel> | any) => {
        const job = resp.data;
        if (job) {
          if (
            job.jobStatus === JobStatus.OPEN ||
            job.jobStatus === JobStatus.ACKNOWLEDGEMENT_REQUIRED
          ) {
            // redirect to job form
            let redirectUrl = '/job/edit/' + job.jobId;
            if (
              this.fleetProduct === 'HUB' &&
              this.organisationService.organisationId
            ) {
              redirectUrl =
                '/organisation/' +
                this.organisationService.organisationId +
                redirectUrl;
            }
            return of(this.router.parseUrl(redirectUrl));
          } else {
            this.jobService.setJob(job);
            // if (
            //   job.jobStatus === JobStatus.STARTED ||
            //   job.jobStatus === JobStatus.IN_PROGRESS
            // ) {
            //   return this.router.parseUrl()
            // }
            return of(true);
          }
        } else {
          return of(false);
        }
      }),
      catchError((error: any) => {
        this.onScreenNotifcationService.setNotification({
          ...failureNotification,
          title: 'Failed to load job!',
          icon: { color: 'red', name: 'warning' },
        } as OnScreenNotification);
        //this.router.navigate(['/job']);
        return of(false);
      })
    );

    return true;
  }
}
