<div
  *ngIf="notifications"
  aria-live="assertive"
  class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
>
  <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
    <!--
      Notification panel, dynamically insert this into the live region when it needs to be displayed

      Entering: "transform ease-out duration-300 transition"
        From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        To: "translate-y-0 opacity-100 sm:translate-x-0"
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div
      *ngFor="let notification of notifications"
      class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    >
      <div class="p-4">
        <div class="flex items-start">
          <ng-container *ngIf="notification.icon">
            <div
              class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full"
              [ngClass]="{
                'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600':
                  notification.icon.color === 'primary',
                'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600':
                  notification.icon.color === 'accent',
                'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600':
                  notification.icon.color === 'warn',
                'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600':
                  notification.icon.color === 'basic',
                'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600':
                  notification.icon.color === 'info',
                'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500':
                  notification.icon.color === 'success',
                'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500':
                  notification.icon.color === 'warning',
                'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600':
                  notification.icon.color === 'error'
              }"
            >
              <mat-icon
                class="text-current"
                [svgIcon]="notification.icon.name"
              ></mat-icon>
            </div>
          </ng-container>

          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">
              {{ notification.title }}
            </p>
            <p class="mt-1 text-sm text-gray-500">
              {{ notification.subTitle }}
            </p>
            <div
              class="mt-4 text-primary text-semibold"
              *ngIf="notification.link"
            >
              <a class="underline" [routerLink]="[notification.link]">{{
                notification.linkLabel
              }}</a>
            </div>
          </div>

          <div class="ml-4 flex-shrink-0 flex">
            <button
              class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              (click)="close(notification)"
            >
              <span class="sr-only">Close</span>
              <!-- Heroicon name: solid/x -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
