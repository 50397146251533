import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeControlCreateEditComponent } from './attribute-control-create-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AttributeControlModule } from '../attribute-control/attribute-control.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogLayoutModule } from '@fleet/ui';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';

@NgModule({
  declarations: [AttributeControlCreateEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AttributeControlModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    AlertsFromIssuesModule,
    FuseAlertModule,

    DialogLayoutModule,
  ],
  exports: [AttributeControlCreateEditComponent],
})
export class AttributeControlCreateEditModule {}
