import {
  ActiveJobDisplay,
  JobStatusUpdateAndProgress,
  LocationModel,
} from '@fleet/model';
import { createActiveJobDisplay } from '@fleet/utilities';
import { CollisionBehavior, Marker } from '@googlemaps/adv-markers-utils';
import { DateTime } from 'luxon';
export function createCustomPinMarker(
  color: string,
  character: string,
  location: LocationModel,
  draggable: boolean = false
): google.maps.marker.AdvancedMarkerElement {
  const pinElement = new google.maps.marker.PinElement({
    background: color,
    glyph: character,
    glyphColor: 'white',
    borderColor: color,
    scale: 1.0,
  });

  return new google.maps.marker.AdvancedMarkerElement({
    content: pinElement.element,
    position: {
      lat: +location.latitude,
      lng: +location.longitude,
    },
    collisionBehavior: CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL,
  });
}

export function createCustomImageMarker(
  imageUrl: string,
  position: any,
  draggable: boolean = false
): google.maps.marker.AdvancedMarkerElement {
  const imgTag = document.createElement('img');
  imgTag.src = imageUrl;
  imgTag.classList.add('w-10', 'h-10');
  return new google.maps.marker.AdvancedMarkerElement({
    content: imgTag,
    position: {
      lat: +position.latitude,
      lng: +position.longitude,
    },
    collisionBehavior: CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL,
  });
}

export function createCustomSVGMarker(
  svg: string,
  position: any,
  draggable: boolean = false
): google.maps.marker.AdvancedMarkerElement {
  const domParser = new DOMParser();
  const domElement = domParser.parseFromString(
    svg,
    'image/svg+xml'
  ).documentElement;
  domElement.classList.add('w-10 h-10');
  return new google.maps.marker.AdvancedMarkerElement({
    content: domParser.parseFromString(svg, 'image/svg+xml').documentElement,
    position: {
      lat: +position.latitude,
      lng: +position.longitude,
    },
    collisionBehavior: CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL,
  });
}

// export function activeJobMarker(
//   activeJob: JobStatusUpdateAndProgress,
//   disabled?: boolean
// ) {
//   const sla = createActiveJobDisplay(activeJob);

//   // Calculate the initial stroke-dashoffset based on the time until SLA level 3
//   const totalDashArray = 94;
//   const now = new Date().getTime();
//   const slaLevelThreeTime = sla.millisecondsTillSLA3;
//   const millisecondsTillSLA3 = slaLevelThreeTime - now;
//   const initialDashOffset =
//     totalDashArray * (millisecondsTillSLA3 / (100 * 60 * 1000)); // Assuming 100 minutes for full cycle

//   // Create a div element to hold the SVG
//   const svgContainer = document.createElement('div');
//   //svgContainer.innerHTML = '<h1>blah</h1>';
//   // svgContainer.style.animation = `draw ${millisecondsTillSLA3}ms linear forwards, colorChange ${millisecondsTillSLA3}ms linear forwards`;
//   svgContainer.innerHTML = `
//     <style>
//       .border {
//         stroke-dasharray: ${totalDashArray};
//         stroke-dashoffset: ${initialDashOffset};
//         transform: rotate(-90deg); /* Start at 12 o'clock position */
//         transform-origin: center;
//         animation: draw ${millisecondsTillSLA3}ms linear forwards, colorChange ${millisecondsTillSLA3}ms linear forwards;
//       }

//       @keyframes draw {
//         to {
//           stroke-dashoffset: 0;
//         }
//       }

//       @keyframes colorChange {
//         0% {
//           stroke: ${
//             sla.slaLevel >= 3 ? 'red' : sla.slaLevel === 2 ? 'orange' : 'green'
//           };
//         }
//         33% {
//           stroke: ${
//             sla.slaLevel >= 3 ? 'red' : sla.slaLevel === 2 ? 'orange' : 'green'
//           };
//         }
//         66% {
//           stroke: ${
//             sla.slaLevel >= 3 ? 'red' : sla.slaLevel === 2 ? 'orange' : 'green'
//           };
//         }
//         100% {
//           stroke: ${
//             sla.slaLevel >= 3 ? 'red' : sla.slaLevel === 2 ? 'orange' : 'green'
//           };
//         }
//       }
//     </style>
//     <svg viewBox="0 0 32 32" width="64" height="64">
//       <circle cx="16" cy="16" r="15" fill="white" />
//       <circle
//         class="border"
//         cx="16"
//         cy="16"
//         r="15"
//         fill="none"
//         stroke-width="2"
//       />
//     </svg>
//   `;
//   const latitude = parseFloat(activeJob.latestStatusUpdate.startLatitude);
//   const longitude = parseFloat(activeJob.latestStatusUpdate.startLongitude);

//   if (isNaN(latitude) || isNaN(longitude)) {
//     throw new Error('Invalid latitude or longitude values');
//   }

//   console.log('content', svgContainer);

//   const marker = new google.maps.marker.AdvancedMarkerElement({
//     position: {
//       lat: +activeJob.latestStatusUpdate.startLatitude,
//       lng: +activeJob.latestStatusUpdate.startLongitude,
//     },
//     content: svgContainer,
//   } as any) as any;

//   marker.activeJob = activeJob;
//   marker.slaDisplay = sla;

//   return marker;
// }
// export function activeJobMarkerv2(
//   activeJob: JobStatusUpdateAndProgress,
//   disabled?: boolean
// ) {
//   const sla = createActiveJobDisplay(activeJob);

//   const radius = 15;
//   const circumference = 2 * Math.PI * radius;
//   const now = DateTime.now().toMillis();
//   const dispatchSlaStartTime = DateTime.fromISO(
//     activeJob.latestStatusUpdate.dispatchSlaStartTime.toString()
//   ).toMillis();
//   const dispatchSlaLevelOne = DateTime.fromISO(
//     activeJob.latestStatusUpdate.dispatchSlaLevelOne.toString()
//   ).toMillis();
//   const dispatchSlaLevelTwo = DateTime.fromISO(
//     activeJob.latestStatusUpdate.dispatchSlaLevelTwo.toString()
//   ).toMillis();
//   const dispatchSlaLevelThree = DateTime.fromISO(
//     activeJob.latestStatusUpdate.dispatchSlaLevelThree.toString()
//   ).toMillis();

//   let initialDashOffset = 0;
//   let strokeColor = 'green';
//   let overTimeDisplay = '';

//   if (now > dispatchSlaStartTime) {
//     const millisecondsTillSLA3 = dispatchSlaLevelThree - now;
//     initialDashOffset =
//       circumference *
//       (1 -
//         millisecondsTillSLA3 / (dispatchSlaLevelThree - dispatchSlaStartTime));

//     if (now > dispatchSlaLevelThree) {
//       strokeColor = 'red';
//       const minutesOver = Math.floor((now - dispatchSlaLevelThree) / 60000);
//       overTimeDisplay = `<text x="16" y="28" text-anchor="middle" fill="red" font-size="8">${minutesOver}m</text>`;
//     } else if (now > dispatchSlaLevelTwo) {
//       strokeColor = 'red';
//     } else if (now > dispatchSlaLevelOne) {
//       strokeColor = 'orange';
//     }
//   }

//   const svgContainer = document.createElement('div');

//   svgContainer.innerHTML = `
//     <style>
//       .border {
//         stroke-dasharray: ${circumference};
//         stroke-dashoffset: ${initialDashOffset};
//         animation: draw ${
//           dispatchSlaLevelThree - now
//         }ms linear forwards, colorChange ${
//     dispatchSlaLevelThree - now
//   }ms linear forwards;
//       }

//       @keyframes draw {
//         to {
//           stroke-dashoffset: ${circumference};
//         }
//       }

//       @keyframes colorChange {
//         0% {
//           stroke: ${strokeColor};
//         }
//         100% {
//           stroke: ${strokeColor};
//         }
//       }
//     </style>
//     <svg viewBox="0 0 32 32" width="64" height="64">
//       <circle cx="16" cy="16" r="15" fill="white" />
//       <circle
//         class="border"
//         cx="16"
//         cy="16"
//         r="15"
//         fill="none"
//         stroke-width="2"
//       />
//       ${overTimeDisplay}
//     </svg>
//   `;
//   //svgContainer.innerHTML = '<h1>blaj</h1>';

//   const latitude = parseFloat(activeJob.latestStatusUpdate.startLatitude);
//   const longitude = parseFloat(activeJob.latestStatusUpdate.startLongitude);

//   if (isNaN(latitude) || isNaN(longitude)) {
//     throw new Error('Invalid latitude or longitude values');
//   }

//   const marker = new Marker({
//     position: {
//       lat: latitude,
//       lng: longitude,
//     },
//     content: svgContainer,
//   } as any) as any;

//   try {
//     const m = new google.maps.marker.AdvancedMarkerElement(marker.options);
//   } catch (error) {
//     console.log(error);
//   }
//   marker.setData({ activeJob: activeJob, slaDisplay: sla });
//   // marker.activeJob = activeJob;
//   // marker.slaDisplay = sla;

//   return marker;
// }
