<div *ngIf="job" class="flex flex-col w-full bg-card rounded-2xl shadow">
  <div
    *ngIf="job.jobDetail?.mapImage"
    class="relative w-full first-line:rounded-t-2xl"
  >
    <img [src]="job.jobDetail.mapImage.url" class="h-50 object-cover" />
    <!-- <fleet-static-map *ngIf="apiLoaded$ | async" [job]="job" class="overflow-hidden" height="100%"></fleet-static-map> -->
  </div>
  <!-- <img
    class="object-cover rounded-t-2xl"
    src="assets/images/cards/03-320x200.jpg"
    alt="Card cover image"
  /> -->
  <div class="m-8 mb-4">
    <div class="flex justify-between">
      <div class="flex w-full flex-col gap-4">
        <div class="flex flex-row w-full items-center justify-between">
          <fleet-badge
            [variationConfig]="{
              variation: 'flat',
              color: (
                $any({
                  status: job.jobStatus
                }) | jobColors
              ).badgeColor,
              size: 'small',
              dark: true
            }"
            [label]="job.jobStatus | titleCaseAndClean"
          ></fleet-badge>
          <div
            *ngIf="job.jobDetail?.productType"
            class="text-secondary text-sm flex flex-row items-center justify-center"
          >
            <div>Source:</div>

            <fleet-source [jobDetail]="job.jobDetail" [mode]="'ICONS_ONLY'">
            </fleet-source>
          </div>
        </div>

        <div
          class="flex flex-col gap-2"
          *ngIf="job | jobCard : 'waypoint-display' as waypoints"
        >
          <div class="flex items-center">
            <mat-icon class="mr-2 icon-size-5 text-gray-400">schedule</mat-icon>
            <div class="text-md">
              {{
                job.jobDetail.startTime
                  | internationalizationDate : 'DATETIME_SHORT'
              }}
            </div>
          </div>
          <div class="flex items-center">
            <mat-icon
              class="icon-size-5 mr-2 text-gray-400"
              [svgIcon]="'heroicons_outline:hashtag'"
            ></mat-icon>
            <div class="text-md" matTooltip="Job Reference">
              {{ job.jobId }}
            </div>
          </div>

          <div class="flex items-center" *ngIf="job.jobNumber">
            <mat-icon class="icon-size-5 mr-2 text-gray-400">123</mat-icon>
            <div class="text-md" matTooltip="Job Number">
              {{ job.jobNumber }}
            </div>
          </div>

          <div
            class="flex items-center"
            *ngIf="job.jobDetail.customerReference"
          >
            <mat-icon class="icon-size-5 mr-2 text-gray-400"
              >confirmation_number</mat-icon
            >
            <div class="text-md" matTooltip="Customer Reference">
              {{ job.jobDetail.customerReference }}
            </div>
          </div>

          <div
            class="flex items-center -mt-1"
            *ngFor="let waypoint of waypoints; let i = index"
          >
            <ng-container *ngIf="waypoint?.place">
              <fleet-letter-icon
                class="mt-0.5 mr-2"
                [text]="waypoint.letter"
                iconSize="5"
              ></fleet-letter-icon>
              <div class="text-md mt-1 flex flex-col">
                <div>{{ waypoint.place?.displayLine1 }}</div>
                <div *ngIf="waypoint.place.displayLine1 !== 'As Directed'">
                  <span class="text-secondary uppercase text-sm">{{
                    waypoint.place?.displayLine2
                  }}</span>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="flex items-center">
            <mat-icon class="icon-size-5 mr-2 text-gray-400"
              >local_taxi</mat-icon
            >
            <div class="text-md">
              {{ job.jobDetail.serviceLine | titleCaseAndClean }} -
              {{ job.jobDetail.networkServiceDisplayName }}
            </div>
          </div>

          <div class="flex items-center">
            <mat-icon class="icon-size-5 mr-2 text-gray-400">paid</mat-icon>
            <div class="text-md">
              {{ job.fare?.pricingMethod | titlecase }}
            </div>
          </div>

          <div class="flex items-center">
            <mat-icon class="mr-2 icon-size-5 text-gray-400"
              >person_add</mat-icon
            >
            <div class="text-md">
              Number of travellers: {{ job.jobDetail?.numberOfTravellers }}
            </div>
          </div>

          <div
            *ngIf="job.jobDetail?.contactName"
            class="flex items-center"
            matTooltip="Contact Name"
          >
            <mat-icon class="mr-2 icon-size-5 text-gray-400"
              >contact_emergency</mat-icon
            >
            <div class="text-md">
              {{ job.jobDetail?.contactName }}
            </div>
          </div>
          <div
            *ngIf="job.jobDetail?.contactPhoneNumber"
            matTooltip="Contact Phone Number"
            class="flex items-center"
          >
            <mat-icon class="mr-2 icon-size-5 text-gray-400"
              >contact_phone</mat-icon
            >
            <div class="text-md">
              {{ job.jobDetail?.contactPhoneNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="job.driver">
      <mat-divider class="my-4"></mat-divider>
      <!-- <hr class="border-b my-4" /> -->
      <div class="flex items-center mt-6">
        <img
          *ngIf="job.driver?.photoUrl"
          class="w-14 h-14 mr-4 rounded-full object-cover"
          (click)="navigateToDriver()"
          [src]="job.driver?.photoUrl"
          alt="Card cover image"
        />
        <div class="flex flex-col min-w-0">
          <div
            class="font-medium leading-none underline cursor-pointer"
            (click)="navigateToDriver()"
          >
            {{ job.driver?.name }} (Driver)
          </div>
          <div class="text-md leading-none text-secondary mt-1">
            {{ job.driver?.taxiAuthorityNumber }}
          </div>
          <div class="text-md leading-none text-secondary mt-1">
            {{ job.vehicle?.plate?.plateNumber }}
          </div>
          <!-- <div class="flex items-center leading-none">
            <div class="flex items-center -ml-0.5">
              <mat-icon class="text-orange-500 icon-size-4" [svgIcon]="'mat_outline:star'"></mat-icon>
              <mat-icon class="text-orange-500 icon-size-4" [svgIcon]="'mat_outline:star'"></mat-icon>
              <mat-icon class="text-orange-500 icon-size-4" [svgIcon]="'mat_outline:star'"></mat-icon>
              <mat-icon class="text-orange-500 icon-size-4" [svgIcon]="'mat_outline:star'"></mat-icon>
              <mat-icon class="text-orange-500 icon-size-4" [svgIcon]="'mat_outline:star_half'"></mat-icon>
            </div>

          </div> -->
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="job.traveller">
      <mat-divider class="my-4"></mat-divider>
      <!-- <hr class="border-b my-4" /> -->
      <div class="flex items-center mt-6">
        <img
          *ngIf="job.traveller?.photoUrl; else placeHolder"
          class="w-14 h-14 mr-4 rounded-full object-cover"
          [src]="job.traveller?.photoUrl"
          alt="Traveller photo"
        />

        <ng-template #placeHolder>
          <span
            *ngIf="job.traveller.name"
            class="inline-flex items-center justify-center w-14 h-14 mr-4 rounded-full bg-gray-500"
          >
            <span class="text-xl font-medium leading-none text-white">
              {{ job.traveller.name ? job.traveller.name.substring(0, 1) : '' }}
            </span>
          </span>
        </ng-template>

        <div class="flex flex-col min-w-0">
          <div
            class="font-medium leading-none underline cursor-pointer"
            (click)="navigateToTraveller()"
          >
            {{ job.traveller?.name }} (Traveller)
          </div>
          <div class="text-md leading-none text-secondary mt-1">
            {{ job.traveller?.contactNumber | mobileFormat }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="job.organisation && fleetProduct === 'HUB'">
      <mat-divider class="my-4"></mat-divider>

      <fleet-job-organisation-card
        [canNavigate]="
          ('ORGANISATION_DETAIL:read' | hasFunction) ? true : false
        "
        paddingClasses="p-0"
        [jobOrganisation]="job.organisation"
      ></fleet-job-organisation-card>
    </ng-container>

    <!-- <div class="w-full flex items-start justify-between my-4 px-2">
      <div class="flex flex-row items-baseline gap-0.5">
        <span class="font-bold">12.4</span>
        <span class="text-sm font-medium text-secondary">km</span>
      </div>
      <div class="flex flex-row items-baseline gap-0.5">
        <span class="font-bold">22</span>
        <span class="text-sm font-medium text-secondary">mins</span>
      </div>
    </div> -->
    <ng-container
      *ngIf="job.jobDetail?.conditions && job.jobDetail.conditions.length > 0"
    >
      <div class="flex items-center mt-10">
        <div class="font-medium text-secondary">CONDITIONS</div>
        <div class="flex-auto ml-2 border-b-2"></div>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-4 mt-6">
        <div
          *ngFor="let condition of job.jobDetail.conditions"
          class="flex items-center"
        >
          <mat-icon class="icon-size-5 text-primary">
            {{ condition.iconName }}
          </mat-icon>
          <div class="ml-2 text-md">{{ condition.name }}</div>
        </div>
      </div>
    </ng-container>
    <div
      class="flex flex-col mt-10"
      *ngIf="job.jobDetail?.additionalInstructions"
    >
      <span class="text-secondary text-sm">Additional instructions:</span>
      <span class="text-sm">
        {{ job.jobDetail.additionalInstructions }}
      </span>
    </div>
    <!-- <hr class="border-b my-4" /> -->
    <div class="mt-3 -mx-3">
      <button
        *ngIf="showDetailsLink"
        class="px-3 mr-1 link-button"
        (click)="selectJobDetailRoute(job)"
      >
        Details
      </button>
      <button
        *ngIf="'JOB_MAP:read' | hasFunction"
        class="px-3 link-button"
        [routerLink]="
          fleetProduct === 'TRAVELLER'
            ? ['/traveller/job/' + job.jobId + '/map']
            : ['/job/' + job.jobId + '/map']
        "
      >
        See on map
      </button>
    </div>
  </div>
</div>
