import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositionDetailCardComponent } from './position-detail-card.component';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { MatIconModule } from '@angular/material/icon';
import { DriverCardModule } from '../driver-card';
import { VehicleCardModule } from '../vehicle-card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [PositionDetailCardComponent],
  imports: [
    CommonModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MatIconModule,
    DriverCardModule,
    VehicleCardModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  exports: [PositionDetailCardComponent],
})
export class PositionDetailCardModule {}
