import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasFunctionPipe } from './has-function.pipe';

@NgModule({
  declarations: [HasFunctionPipe],
  imports: [CommonModule],
  exports: [HasFunctionPipe],
})
export class HasFunctionPipeModule {}
