import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityCallSearchComponent } from './entity-call-search.component';
import { EntityCallSearchResultsModule } from './entity-call-search-results/entity-call-search-results.module';
import { SidebarHeaderModule } from '@fleet/ui';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
import { DurationPipeModule, RemovePropertiesPipeModule } from '@fleet/pipes';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { RelatedEntitySearchLayoutModule } from '@fleet/layout';
import { MatIconModule } from '@angular/material/icon';
import { EntityCallDetailModule } from '../entity-call-detail/entity-call-detail.module';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  DateTimeRangeControlModule,
  DateWithTimeControlModule,
  NoResultsLabelModule,
  SearchChipsListModule,
} from '@fleet/shared';
import { HasFunctionPipeModule } from '@fleet/security-shared';

@NgModule({
  declarations: [EntityCallSearchComponent],
  imports: [
    CommonModule,
    RelatedEntitySearchLayoutModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,

    FuseAlertModule,
    AlertsFromIssuesModule,
    SidebarHeaderModule,
    EntityCallSearchResultsModule,
    EntityCallDetailModule,
    DurationPipeModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    RemovePropertiesPipeModule,
    SearchChipsListModule,
    NoResultsLabelModule,
    DateTimeRangeControlModule,
    HasFunctionPipeModule,
    DateWithTimeControlModule,
  ],
  exports: [EntityCallSearchComponent],
})
export class EntityCallSearchModule {}
