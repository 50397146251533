import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { NgResizeObserver } from 'ng-resize-observer';

export interface Size {
  width: string;
  height: string;

  wAlias: string;
  hAlias: string;

  alias: string;
}

export abstract class FleetReponsiveElementBaseComponent {
  size$ = this.resize$.pipe(
    map((entry: any) => {
      const size: Size = {
        height: entry.contentRect.height,
        width: entry.contentRect.width,
      } as any;

      if (entry.contentRect.width > 1000) {
        size.alias = 'xl';
      } else if (entry.contentRect.width > 800) {
        size.alias = 'lg';
      } else if (entry.contentRect.width > 600) {
        size.alias = 'md';
      } else if (entry.contentRect.width > 500) {
        size.alias = 'sm';
      } else {
        size.alias = 'xs';
      }

      return size;
    })
  );
  constructor(private resize$: NgResizeObserver) {}
}
