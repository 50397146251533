import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationStaffAutocompleteComponent } from './organisation-staff-autocomplete.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';

@NgModule({
  declarations: [OrganisationStaffAutocompleteComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
  ],

  exports: [OrganisationStaffAutocompleteComponent],
})
export class OrganisationStaffAutocompleteModule {}
