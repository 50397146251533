import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fleet-letter-icon',
  templateUrl: './letter-icon.component.html',
  styleUrls: ['./letter-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LetterIconComponent implements OnInit {
  @Input() text: string;
  @Input() iconSize = '5';
  @Input() backgroundColor: string;

  constructor() {}

  ngOnInit(): void {}

  calculateClasses(size: string, backgroundColor: string) {
    const h = 'h-' + size;
    const w = 'w-' + size;
    const bg = backgroundColor ? 'bg-' + backgroundColor : null;

    return {
      h: true,
      w: true,
      bg: backgroundColor ? true : false,
    };
  }
}
