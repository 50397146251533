import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { VoucherModel } from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VoucherApiService {
  host;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.voucher;
  }

  searchVouchers(params: any) {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getVoucher(voucherId: string): Observable<VoucherModel | any> {
    return this.http
      .get(`${this.host}/${voucherId}`)
      .pipe(catchError(handleApiError));
  }

  createVoucher(voucher: VoucherModel): Observable<VoucherModel> | any {
    return this.http
      .post(`${this.host}`, voucher)
      .pipe(catchError(handleApiError));
  }

  updateVoucher(
    voucherId: string,
    voucher: VoucherModel
  ): Observable<VoucherModel> | any {
    return this.http
      .put(`${this.host}/${voucherId}`, voucher)
      .pipe(catchError(handleApiError));
  }
  deleteVoucher(voucherId: any) {
    return this.http
      .delete(`${this.host}/${voucherId}`)
      .pipe(catchError(handleApiError));
  }
}
