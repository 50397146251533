<div class="absolute inset-0 flex flex-col min-w-0">
  <div class="flex flex-auto flex-col gap-10 overflow-y-auto" cdkScrollable>
    <div class="flex flex-col px-8 pt-10 bg-card">
      <div class="flex flex-1 flex-col">
        <div class="flex flex-col gt-xs:flex-row gt-xs:items-start">
          <ng-content select="[slot=top-error]"></ng-content>

          <!-- THIS IS TITLE AND BACK BUTTON -->
          <div class="flex flex-row justify-between items-start">
            <div
              class="gt-xs:max-w-80 gt-xs:pr-12"
              *ngIf="showTitleAndSubtitle"
            >
              <p class="text-lg font-medium">
                <ng-content select="[slot=advanced-search-title]"></ng-content>
              </p>
              <p class="text-secondary mb-6">
                <ng-content
                  select="[slot=advanced-search-subtitle]"
                ></ng-content>
              </p>
            </div>

            <button
              *ngIf="showCloseButton"
              mat-icon-button
              (click)="closeDrawer.emit()"
            >
              <mat-icon [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
            </button>
          </div>

          <div class="mt-4">
            <ng-content select="[slot=form-fields]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="px-8 pb-6">
    <ng-content select="[slot=error]"></ng-content>
  </div>

  <div
    *ngIf="showSearchButtons"
    class="flex shrink items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700"
  >
    <ng-content select="[slot=advanced-search-action]"></ng-content>
  </div>
</div>
