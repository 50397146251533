import { UntypedFormControl } from '@angular/forms';

export function restrictedWords(words: any[]) {
  return (control: UntypedFormControl): { [key: string]: any } => {
    if (!words || !control.value) return null;
    const invalidWords = words
      .map((w) => (control.value.includes(w) ? w : null))
      .filter((w) => w != null);
    return invalidWords && invalidWords.length > 0
      ? { restrictedWords: invalidWords.join(', ') }
      : null;
  };
}
