import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntityDataItem } from '@fleet/model';

@Component({
  selector: 'fleet-entity-header-data-item',
  templateUrl: './entity-header-data-item.component.html',
  styleUrls: ['./entity-header-data-item.component.scss'],
})
export class EntityHeaderDataItemComponent implements OnInit {
  @Input() dataItem: EntityDataItem;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  dataItemClicked() {
    if (this.dataItem.link) {
      this.router.navigate([this.dataItem.link]);
    }
  }
}
