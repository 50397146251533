<form [formGroup]="form" *ngIf="form" class="flex flex-col flex-auto">
  <div class="flex flex-col" [ngClass]="{ 'mt-8 px-6': padding }">
    <fleet-bsb-form-control
      #bsbInput
      formControlName="bsb"
      [showRequired]="true"
    >
    </fleet-bsb-form-control>

    <mat-form-field class="mb-4 w-full" [subscriptSizing]="'dynamic'">
      <mat-label>Account number</mat-label>
      <input
        type="tel"
        matInput
        placeholder="Account number"
        formControlName="accountNumber"
        maxlength="9"
        required
      />
      <mat-error *ngIf="form.get('accountNumber').hasError('required')"
        >Account number is required</mat-error
      >
      <mat-error
        *ngIf="
          form.get('accountNumber').hasError('pattern') ||
          form.get('accountNumber').hasError('valid')
        "
      >
        A valid account number is required and must only contain
        numbers</mat-error
      >
      <mat-error *ngIf="form.get('accountNumber').hasError('maxlength')"
        >Your account number must have a maximum of 9 digits</mat-error
      >
    </mat-form-field>
    <mat-form-field [subscriptSizing]="'dynamic'" class="w-full mb-4">
      <mat-label>Account name</mat-label>
      <input
        matInput
        placeholder="Account name"
        formControlName="accountName"
        maxlength="32"
        required
        style="text-transform: uppercase"
        #accountNameInput
      />
      <mat-error *ngIf="form.get('accountName').hasError('required')"
        >Account name is required</mat-error
      >
      <mat-error
        *ngIf="
          form.get('accountName').hasError('pattern') ||
          form.get('accountName').hasError('valid')
        "
        >A valid account name is required and must only contain numbers and
        letters</mat-error
      >
      <mat-error *ngIf="form.get('accountName').hasError('maxlength')"
        >Your account name can have a maximum of 32 characters</mat-error
      >
    </mat-form-field>

    <mat-radio-group
      *ngIf="paymentType === 'SETTLEMENT_BANK_ACCOUNT'"
      formControlName="settlementFrequency"
      class="flex flex-col gap-2"
    >
      <div class="flex flex-row gap-2">
        <mat-label>Settlement frequency</mat-label>

        <mat-radio-button [value]="'DAILY'"> Daily </mat-radio-button>
        <mat-radio-button [value]="'WEEKLY'"> Weekly </mat-radio-button>
      </div>

      <mat-error *ngIf="form.get('settlementFrequency').hasError('required')"
        >Settlement frequency is required
      </mat-error>
    </mat-radio-group>
  </div>
</form>
