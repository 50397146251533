import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSearchResultToAddressDisplayPipe } from './job-search-result-to-address-display.pipe';

@NgModule({
  declarations: [JobSearchResultToAddressDisplayPipe],
  imports: [CommonModule],
  exports: [JobSearchResultToAddressDisplayPipe],
})
export class JobSearchResultToAddressDisplayPipeModule {}
