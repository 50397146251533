import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FuseMediaWatcherService,
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fleet/fuse';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FleetNavigationService } from '@fleet/navigation';
import { GroupHierarchyItem } from '@fleet/model';
import { ProductConfigurationService } from '@fleet/product-configuration';
import { AuthService } from '@fleet/auth';

@Component({
  selector: 'fleet-main-layout',
  templateUrl: './fleet-layout.component.html',
  styleUrls: ['./fleet-layout.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FleetLayoutComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isScreenSmall: boolean;
  navigation: FuseNavigationItem[];
  currentGroup: GroupHierarchyItem;
  fleetProduct: string;
  envName: string;
  logoSrc: string = 'assets/logos/fleet-driver-horiz.svg';
  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private fleetNavigationService: FleetNavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private _productConfigurationService: ProductConfigurationService,
    private authService: AuthService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
    this.envName = env.envName;
  }

  ngOnInit(): void {
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this.fleetNavigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (navigation: FuseNavigationItem[]) => {
          this.navigation = navigation;

          this.changeDetectorRef.markForCheck();
        },
      });

    this.fleetNavigationService.currentGroup$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (currentGroup: GroupHierarchyItem) => {
          this.currentGroup = currentGroup;
        },
      });

    this._productConfigurationService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (config: any) => {
          if (config.horizontalMonoLogo) {
            this.logoSrc = config.horizontalMonoLogo;
            this.changeDetectorRef.markForCheck();
          }
        },
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }
}
