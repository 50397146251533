import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobPaymentMethodSelectorComponent } from './job-payment-method-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreditCardPipeModule } from '@fleet/pipes';

import { JobPaymentOptionDisplayPipe } from './job-payment-option-display.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PaymentMethodListItemModule } from '@fleet/payment';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    JobPaymentMethodSelectorComponent,
    JobPaymentOptionDisplayPipe,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CreditCardPipeModule,
    MatFormFieldModule,
    PaymentMethodListItemModule,
    MatMenuModule,
  ],
  exports: [JobPaymentMethodSelectorComponent],
})
export class JobPaymentMethodSelectorModule {}
