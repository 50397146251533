import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { JobIssuesPipe } from './job-issues.pipe';

@NgModule({
  declarations: [JobIssuesPipe],
  imports: [CommonModule, MatListModule, MatIconModule, MatCardModule],
  exports: [JobIssuesPipe],
})
export class JobIssuesModule {}
