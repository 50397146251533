/* eslint-disable @typescript-eslint/member-ordering, no-underscore-dangle, id-blacklist,
id-match, @typescript-eslint/naming-convention */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';

import {
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALIDATORS,
  NgControl,
  NgForm,
  UntypedFormControl,
} from '@angular/forms';
import {
  AsYouType,
  CountryCode as CC,
  PhoneNumber,
  getExampleNumber,
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { CountryCode, Examples } from './data/country-code';
import { Country } from './model/country.model';
import { PhoneNumberFormat } from './model/phone-number-format.model';
import { phoneNumberValidator } from './ngx-mat-intl-tel-input.validator';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CanUpdateErrorState, ErrorStateMatcher } from '@angular/material/core';
import { MatMenu } from '@angular/material/menu';
import { Subject, takeUntil } from 'rxjs';
// import {
//   AbstractConstructor,
//   Constructor,
// } from '@angular/material/core/common-behaviors/constructor';
import { NationalNumber } from 'libphonenumber-js/types';
import { NetworkGroupService } from '@fleet/network-group';
import { NetworkGroupModel } from '@fleet/model';
import {
  ProductConfigurationService,
  BrandConfig,
} from '@fleet/product-configuration';

class PhoneErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const showError =
      !control.disabled &&
      ((!control.valid && control.value) ||
        (control.pristine && !control.value) ||
        (!control.value && control.dirty));
    console.log('error state show error: ' + showError);
    return showError;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ngx-mat-intl-tel-input',
  templateUrl: './ngx-mat-intl-tel-input.component.html',
  styleUrls: ['./ngx-mat-intl-tel-input.component.css'],
  providers: [
    CountryCode,
    { provide: MatFormFieldControl, useExisting: NgxMatIntlTelInputComponent },
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxMatIntlTelInputComponent
  implements
    OnInit,
    OnDestroy,
    CanUpdateErrorState,
    ControlValueAccessor,
    MatFormFieldControl<NgxMatIntlTelInputComponent>
{
  static nextId = 0;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() preferredCountries: Array<string> = ['us', 'au'];
  @Input() enablePlaceholder = true;
  @Input() inputPlaceholder: string;
  @Input() cssClass: any;
  @Input() name: string;

  @Input() onlyCountries: Array<string> = [];
  @Input() errorStateMatcher = new PhoneErrorStateMatcher();
  @Input() enableSearch = false;
  @Input() searchPlaceholder: string;
  @Input() describedBy = '';

  @ViewChild('focusable')
  focusable: ElementRef;

  @Input()
  get format(): PhoneNumberFormat {
    return this._format;
  }

  set format(value: PhoneNumberFormat) {
    this._format = value;
    this.phoneNumber = this.formattedPhoneNumber;
    this.stateChanges.next();
  }

  @ViewChild(MatMenu) matMenu: MatMenu;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  stateChanges = new Subject<void>();
  focused = false;
  @HostBinding()
  id = `ngx-mat-intl-tel-input-${NgxMatIntlTelInputComponent.nextId++}`;
  phoneNumber: NationalNumber | string = '';
  allCountries: Array<Country> = [];
  preferredCountriesInDropDown: Array<Country> = [];
  selectedCountry: Country;
  numberInstance: PhoneNumber;
  value: any;
  searchCriteria: string;
  @Output()
  countryChanged: EventEmitter<Country> = new EventEmitter<Country>();

  private previousFormattedNumber: string;
  private _format: PhoneNumberFormat = 'default';

  static getPhoneNumberPlaceHolder(countryISOCode: any): string | any {
    try {
      return getExampleNumber(countryISOCode, Examples).number.toString();
    } catch (e) {
      return e;
    }
  }

  onTouched = () => {};

  propagateChange = (_: any) => {};

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private countryCodeData: CountryCode,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private networkGroupService: NetworkGroupService,
    private productConfigurationService: ProductConfigurationService
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(elRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.fetchCountryData();
  }

  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  updateErrorState(): void {
    throw new Error('Method not implemented.');
  }

  get errorState(): boolean {
    if (this.ngControl.touched) {
      return this.ngControl.invalid;
    } else {
      return false;
    }

    //return true;
  }
  ngOnInit() {
    this.productConfigurationService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (brand: BrandConfig) => {
          if (brand) {
            const localeParts = brand.locale.split('-');
            const countryCode = localeParts[1].toLowerCase();
            this.preferredCountries = [countryCode];
          }
        },
      });
    this.networkGroupService.networkGroup$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (networkGroup: NetworkGroupModel) => {
          if (networkGroup) {
            const localeParts = networkGroup.locale.split('-');
            const countryCode = localeParts[1].toLowerCase();

            this.preferredCountries = [countryCode];
          }
        },
      });

    if (!this.searchPlaceholder) {
      this.searchPlaceholder = 'Search ...';
    }
    if (this.preferredCountries.length) {
      this.preferredCountries.forEach((iso2) => {
        const preferredCountry = this.allCountries
          .filter((c) => c.iso2 === iso2)
          .shift();
        this.preferredCountriesInDropDown.push(preferredCountry);
      });
    }
    if (this.onlyCountries.length) {
      this.allCountries = this.allCountries.filter((c) =>
        this.onlyCountries.includes(c.iso2)
      );
    }
    if (this.numberInstance && this.numberInstance.country) {
      // If an existing number is present, we use it to determine selectedCountry
      this.selectedCountry = this.getCountry(this.numberInstance.country);
    } else {
      if (this.preferredCountriesInDropDown.length) {
        this.selectedCountry = this.preferredCountriesInDropDown[0];
      } else {
        this.selectedCountry = this.allCountries[0];
      }
    }
    this.countryChanged.emit(this.selectedCountry);
    this._changeDetectorRef.markForCheck();
    this.stateChanges.next();
  }

  // ngDoCheck(): void {
  //   if (this.ngControl) {
  //     this.updateErrorState();
  //   }
  // }

  public onPhoneNumberChange(): void {
    try {
      this.numberInstance = parsePhoneNumberFromString(
        this.phoneNumber.toString(),
        this.selectedCountry.iso2.toUpperCase() as CC
      );
      this.formatAsYouTypeIfEnabled();
      this.value = this.numberInstance.number;
      if (this.numberInstance && this.numberInstance.isValid()) {
        if (this.phoneNumber !== this.formattedPhoneNumber) {
          this.phoneNumber = this.formattedPhoneNumber;
        }
        if (this.selectedCountry.iso2 !== this.numberInstance.country) {
          this.selectedCountry = this.getCountry(this.numberInstance.country);
          this.countryChanged.emit(this.selectedCountry);
        }
      }
    } catch (e) {
      // if no possible numbers are there,
      // then the full number is passed so that validator could be triggered and proper error could be shown
      this.value = this.phoneNumber.toString();
    }
    this.propagateChange(this.value);

    this._changeDetectorRef.markForCheck();
  }

  public onCountrySelect(country: Country, el: any): void {
    if (this.phoneNumber) {
      this.phoneNumber = this.numberInstance.nationalNumber;
    }
    this.selectedCountry = country;
    this.countryChanged.emit(this.selectedCountry);
    this.onPhoneNumberChange();
    el.focus();
  }

  public getCountry(code: any) {
    return (
      this.allCountries.find((c) => c.iso2 === code.toLowerCase()) || {
        name: 'UN',
        iso2: 'UN',
        dialCode: undefined,
        priority: 0,
        areaCodes: undefined,
        flagClass: 'UN',
        placeHolder: '',
      }
    );
  }

  public onInputKeyPress(event: any): void {
    const pattern = /[0-9+\- ]/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  protected fetchCountryData(): void {
    this.countryCodeData.allCountries.forEach((c) => {
      const country: Country = {
        name: c[0].toString(),
        iso2: c[1].toString(),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: (c[4] as string[]) || undefined,
        flagClass: c[1].toString().toUpperCase(),
        placeHolder: '',
      };

      if (this.enablePlaceholder) {
        country.placeHolder =
          NgxMatIntlTelInputComponent.getPhoneNumberPlaceHolder(
            country.iso2.toUpperCase()
          );
      }

      this.allCountries.push(country);
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._changeDetectorRef.markForCheck();
    this.stateChanges.next();
  }

  writeValue(value: any): void {
    if (value) {
      this.numberInstance = parsePhoneNumberFromString(value);
      if (this.numberInstance) {
        const countryCode = this.numberInstance.country;
        this.phoneNumber = this.formattedPhoneNumber;
        if (!countryCode) {
          this._changeDetectorRef.markForCheck();
          this.stateChanges.next();
          return;
        }
        setTimeout(() => {
          this.selectedCountry = this.getCountry(countryCode);
          if (
            this.selectedCountry.dialCode &&
            !this.preferredCountries.includes(this.selectedCountry.iso2)
          ) {
            this.preferredCountriesInDropDown.push(this.selectedCountry);
          }
          this.countryChanged.emit(this.selectedCountry);

          // Initial value is set
          this._changeDetectorRef.markForCheck();
          this.stateChanges.next();
        }, 1);
      } else {
        this.phoneNumber = value;
      }
    } else {
      this.phoneNumber = '';
    }

    // Value is set from outside using setValue()
    this._changeDetectorRef.markForCheck();
    this.stateChanges.next();
  }

  get empty() {
    return !this.phoneNumber;
  }

  @HostBinding('class.ngx-floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.elRef.nativeElement.querySelector('input')!.focus();
    }
  }

  reset() {
    this.phoneNumber = '';
    this.propagateChange(null);

    this._changeDetectorRef.markForCheck();
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.fm.stopMonitoring(this.elRef);
  }

  private get formattedPhoneNumber(): string {
    if (!this.numberInstance) {
      return this.phoneNumber.toString();
    }
    switch (this.format) {
      case 'national':
        return this.numberInstance.formatNational();
      case 'international':
        return this.numberInstance.formatInternational();
      default:
        return this.numberInstance.nationalNumber.toString();
    }
  }

  private formatAsYouTypeIfEnabled(): void {
    if (this.format === 'default') {
      return;
    }
    const asYouType: AsYouType = new AsYouType(
      this.selectedCountry.iso2.toUpperCase() as CC
    );
    // To avoid caret positioning we apply formatting only if the caret is at the end:
    if (
      this.phoneNumber.toString().startsWith(this.previousFormattedNumber || '')
    ) {
      this.phoneNumber = asYouType.input(this.phoneNumber.toString());
    }
    this.previousFormattedNumber = this.phoneNumber.toString();
  }

  validate(control: UntypedFormControl) {
    const error = { validatePhoneNumber: true };
    let numberInstance: PhoneNumber;
    if (control.value) {
      try {
        numberInstance = parsePhoneNumber(control.value);
      } catch (e) {
        //control.setValue(null);

        return error;
      }

      if (numberInstance && !numberInstance.isValid()) {
        //control.setValue(null);
        if (!control.touched) {
          control.markAsTouched();
        }
        // if (!this.required) {
        //   return error;
        // }
      }
    }
    return null;
  }
}
