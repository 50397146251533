<fleet-responsive-element class="flex flex-grow">
  <ng-container slot="xs">
    <ng-container *ngTemplateOutlet="mobile"></ng-container>
  </ng-container>
  <ng-container slot="sm">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
  <ng-container slot="md">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
  <ng-container slot="lg">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
  <ng-container slot="xl">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
</fleet-responsive-element>

<ng-template #default>
  <div>
    <div class="flex flex-row justify-between">
      <div class="text-2xl font-semibold leading-tight">Transactions</div>
    </div>

    <div
      class="flex items-center leading-none mt-3 ml-1"
      *ngIf="transactions && transactions.length > 0; else noResults"
    >
      <div class="flex-grow">
        <div class="grid grid-cols-12 gap-2 mt-4">
          <div class="col-span-2 font-medium text-medium text-secondary">
            Date
          </div>

          <div class="col-span-2 font-medium text-medium text-secondary">
            Status
          </div>
          <div class="col-span-2 font-medium text-medium text-secondary">
            Type
          </div>
          <div class="col-span-3 font-medium text-medium text-secondary">
            Description
          </div>
          <div
            class="col-span-2 font-medium text-medium text-secondary text-right"
          >
            Total
          </div>
          <div
            class="col-span-1 font-medium text-medium text-secondary text-right"
          ></div>
          <div class="col-span-12 my-4 border-b"></div>

          <ng-container
            *ngFor="
              let transactionGroup of transactions;
              index as i;
              first as isFirst;
              last as isLast
            "
          >
            <div
              *ngFor="
                let purchaseTransaction of transactionGroup.purchaseTransactions
              "
              class="grid grid-cols-12 col-span-12 pt-3 relative"
            >
              <ng-template
                [ngTemplateOutlet]="transactionTemplate"
                [ngTemplateOutletContext]="{
                  transaction: purchaseTransaction,
                  groupingReference: transactionGroup.groupingReference,
                  transactionGroup: transactionGroup
                }"
              ></ng-template>
              <!-- <mat-icon
                *ngIf="!showRefunds"
                (click)="showRefunds = !showRefunds"
                class="absolute right-0 mr-5 center mt-6 cursor-pointer"
                >arrow_drop_down</mat-icon
              > -->
            </div>
            <ng-container *ngIf="showRefunds">
              <div
                class="grid grid-cols-12 col-span-12 pt-3 relative border-dotted border-b"
              >
                <ng-container
                  *ngFor="
                    let refundTransaction of transactionGroup.refundTransactions
                  "
                >
                  <ng-template
                    [ngTemplateOutlet]="transactionTemplate"
                    [ngTemplateOutletContext]="{
                      transaction: refundTransaction,
                      groupingReference: transactionGroup.groupingReference,
                      transactionGroup: transactionGroup
                    }"
                  ></ng-template>
                </ng-container>
                <!--
                <mat-icon
                  (click)="showRefunds = !showRefunds"
                  class="absolute right-0 mr-5 center mt-6 cursor-pointer"
                  *ngIf="showRefunds"
                  >arrow_drop_up</mat-icon
                > -->
              </div>
            </ng-container>
            <ng-container
              *ngFor="
                let authorisationTransaction of transactionGroup.authorisationTransactions
              "
            >
              <ng-template
                [ngTemplateOutlet]="transactionTemplate"
                [ngTemplateOutletContext]="{
                  transaction: authorisationTransaction,
                  groupingReference: transactionGroup.groupingReference,
                  transactionGroup: transactionGroup
                }"
              ></ng-template>
            </ng-container>
            <ng-container *ngIf="transactionGroup.voidTransaction">
              <ng-template
                [ngTemplateOutlet]="transactionTemplate"
                [ngTemplateOutletContext]="{
                  transaction: transactionGroup.voidTransaction,
                  groupingReference: transactionGroup.groupingReference,
                  transactionGroup: transactionGroup
                }"
              ></ng-template>
            </ng-container>

            <div class="col-span-12 my-2 border-b" *ngIf="!isLast"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobile>
  <div class="grid grid-cols-1 gap-4 w-full">
    <div *ngFor="let transactionGroup of transactions; let isLast = last">
      <div
        class="flex flex-col gap-2"
        *ngFor="
          let purchaseTransaction of transactionGroup.purchaseTransactions
        "
      >
        <div class="flex flex-col gap-1 relative">
          <div class="font-medium">
            {{
              purchaseTransaction.processedTimestamp
                | internationalizationDate : 'DATETIME_SHORT'
            }}
          </div>
          <div>{{ purchaseTransaction.paymentStatus | titleCaseAndClean }}</div>
          <div>
            {{ purchaseTransaction.transactionType | titleCaseAndClean }}
          </div>
          <div>
            <ng-template
              [ngTemplateOutlet]="paymentTypeTemplate"
              [ngTemplateOutletContext]="{ transaction: purchaseTransaction }"
            ></ng-template>
          </div>
          <div>
            {{
              purchaseTransaction.amount
                ? (purchaseTransaction.amount | currency)
                : ('0.00' | currency)
            }}
          </div>
          <div
            *ngIf="
              purchaseTransaction.paymentStatus === 'APPROVED' &&
              fleetProduct === 'HUB' &&
              (purchaseTransaction.eftposReceipt ||
                (purchaseTransaction?.transactionType === 'PURCHASE' &&
                  ('JOB_PAYMENT_REFUND:execute' | hasFunction) &&
                  transactionGroup.totalRefundableAmount !== 0))
            "
            class="absolute top-0 right-0"
          >
            <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
              <button
                *ngIf="
                  purchaseTransaction?.transactionType === 'PURCHASE' &&
                  ('JOB_PAYMENT_REFUND:execute' | hasFunction) &&
                  transactionGroup.totalRefundableAmount !== 0
                "
                mat-menu-item
                (click)="refundTransaction.emit(transactionGroup)"
              >
                Refund
              </button>
              <button
                *ngIf="purchaseTransaction.eftposReceipt"
                mat-menu-item
                (click)="viewEFTPOSReceipt.emit(purchaseTransaction)"
              >
                View EFTPOS Receipt
              </button>
            </mat-menu>
            <button
              *ngIf="
                purchaseTransaction.transactionType === 'PURCHASE' ||
                purchaseTransaction.eftposReceipt
              "
              class="-mt-3"
              mat-icon-button
              [matMenuTriggerFor]="appMenu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
        <div class="my-2"></div>
      </div>
      <div
        *ngFor="let refundTransaction of transactionGroup.refundTransactions"
      >
        <div class="flex flex-col gap-1">
          <div class="font-medium">
            {{
              refundTransaction.processedTimestamp
                | internationalizationDate : 'DATETIME_SHORT'
            }}
          </div>
          <div>{{ refundTransaction.paymentStatus | titleCaseAndClean }}</div>
          <div>{{ refundTransaction.transactionType | titleCaseAndClean }}</div>
          <div>
            <ng-template
              [ngTemplateOutlet]="paymentTypeTemplate"
              [ngTemplateOutletContext]="{ transaction: refundTransaction }"
            ></ng-template>
          </div>
          <div>
            {{
              refundTransaction.amount
                ? (refundTransaction.amount | currency)
                : ('0.00' | currency)
            }}
          </div>
        </div>
        <div class="my-2"></div>
      </div>
      <div
        *ngFor="
          let authorisationTransaction of transactionGroup.authorisationTransactions
        "
      >
        <div class="flex flex-col gap-1">
          <div class="font-medium">
            {{
              authorisationTransaction.processedTimestamp
                | internationalizationDate : 'DATETIME_SHORT'
            }}
          </div>
          <div>
            {{ authorisationTransaction.paymentStatus | titleCaseAndClean }}
          </div>
          <div>
            {{ authorisationTransaction.transactionType | titleCaseAndClean }}
          </div>
          <div>
            <ng-template
              [ngTemplateOutlet]="paymentTypeTemplate"
              [ngTemplateOutletContext]="{
                transaction: authorisationTransaction
              }"
            ></ng-template>
          </div>
          <div>
            {{
              authorisationTransaction.amount
                ? (authorisationTransaction.amount | currency)
                : ('0.00' | currency)
            }}
          </div>
        </div>
        <div class="my-2"></div>
      </div>
      <div *ngIf="transactionGroup.voidTransaction">
        <div class="flex flex-col gap-1">
          <div class="font-medium">
            {{
              transactionGroup.voidTransaction.processedTimestamp
                | internationalizationDate : 'DATETIME_SHORT'
            }}
          </div>
          <div>
            {{
              transactionGroup.voidTransaction.paymentStatus | titleCaseAndClean
            }}
          </div>
          <div>
            {{
              transactionGroup.voidTransaction.transactionType
                | titleCaseAndClean
            }}
          </div>
          <div>
            <ng-template
              [ngTemplateOutlet]="paymentTypeTemplate"
              [ngTemplateOutletContext]="{
                transaction: transactionGroup.voidTransaction
              }"
            ></ng-template>
          </div>
          <div>
            {{
              transactionGroup.voidTransaction.amount
                ? (transactionGroup.voidTransaction.amount | currency)
                : ('0.00' | currency)
            }}
          </div>
        </div>
        <div class="my-2"></div>
      </div>
      <div class="col-span-1 my-2 border-b" *ngIf="!isLast"></div>
    </div>
  </div>
</ng-template>

<ng-template
  #transactionTemplate
  let-transaction="transaction"
  let-transactionGroup="transactionGroup"
>
  <!-- [ngClass]="{
      'cursor-pointer':
        (transaction.transactionType === 'PURCHASE' &&
          transaction.paymentStatus === 'APPROVED') ||
        transaction.transactionType === 'REFUND'
    }"
    (click)="
      (transaction.transactionType === 'PURCHASE' &&
        transaction.paymentStatus === 'APPROVED') ||
      transaction.transactionType === 'REFUND'
        ? (showRefunds = !showRefunds)
        : null
    " -->
  <div class="grid grid-cols-12 col-span-12">
    <div class="col-span-2 text-medium self-start">
      {{
        transaction.processedTimestamp
          | internationalizationDate : 'DATETIME_SHORT'
      }}
    </div>

    <div class="col-span-2 self-start flex flex-col gap-3 justify-between">
      {{ transaction.paymentStatus | titleCaseAndClean }}
    </div>
    <div class="col-span-2 self-start flex flex-col gap-3 justify-between">
      {{ transaction.transactionType | titleCaseAndClean }}
    </div>

    <div class="col-span-3 self-start">
      <ng-template
        [ngTemplateOutlet]="paymentTypeTemplate"
        [ngTemplateOutletContext]="{ transaction: transaction }"
      ></ng-template>
    </div>

    <div class="col-span-2 self-start text-right">
      {{
        transaction.amount
          ? (transaction.amount | currency)
          : ('0.00' | currency)
      }}
    </div>

    <div
      *ngIf="
        transaction.paymentStatus === 'APPROVED' &&
        fleetProduct === 'HUB' &&
        (transaction.eftposReceipt ||
          (transaction?.transactionType === 'PURCHASE' &&
            ('JOB_PAYMENT_REFUND:execute' | hasFunction) &&
            transactionGroup.totalRefundableAmount !== 0))
      "
      class="col-span-1 justify-center"
    >
      <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
        <button
          *ngIf="
            transaction?.transactionType === 'PURCHASE' &&
            ('JOB_PAYMENT_REFUND:execute' | hasFunction) &&
            transactionGroup.totalRefundableAmount !== 0
          "
          mat-menu-item
          (click)="
            refundTransaction.emit(transactionGroup); $event.stopPropagation()
          "
        >
          Refund
        </button>
        <button
          *ngIf="transaction.eftposReceipt"
          mat-menu-item
          (click)="
            viewEFTPOSReceipt.emit(transaction); $event.stopPropagation()
          "
        >
          View EFTPOS Receipt
        </button>
      </mat-menu>

      <button
        *ngIf="
          transaction.transactionType === 'PURCHASE' ||
          transaction.eftposReceipt
        "
        class="-mt-3 ml-3"
        mat-icon-button
        [matMenuTriggerFor]="appMenu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>

  <div class="col-span-12 my-2"></div>
</ng-template>

<ng-template #paymentTypeTemplate let-transaction="transaction">
  <div class="flex flex-col gap-2 self-start">
    <div class="self-start">
      {{
        transaction.paymentType !== 'SPI'
          ? (transaction.paymentType | titleCaseAndClean)
          : transaction.paymentType
      }}
    </div>

    <div
      class="flex flex-row"
      *ngIf="
        transaction.cardType &&
        transaction.paymentType !== 'OTHER' &&
        transaction.paymentType !== 'ORGANISATION_ACCOUNT'
      "
    >
      <div>
        {{
          transaction.cardType +
            ' ' +
            (transaction.cardAccountType ? transaction.cardAccountType : '') +
            ' ' +
            (transaction.schemeAppName ? transaction.schemeAppName : '') +
            ' ' +
            (transaction.cardLastFour ? transaction.cardLastFour : '')
            | uppercase
        }}
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-2 mt-2">
    <div class="flex flex-row w-full items-end">
      <div *ngIf="transaction.responseStatus">
        {{ transaction.responseStatus | titleCaseAndClean }}
      </div>
      <div *ngIf="transaction.responseCode" class="ml-1">
        ({{ transaction.responseCode }})
      </div>
    </div>
    <div *ngIf="transaction.responseMessage">
      {{ transaction.responseMessage }}
    </div>
  </div>
</ng-template>

<ng-template #noResults>
  <div class="mt-2">
    <fleet-no-results-label
      [type]="'payment transactions'"
    ></fleet-no-results-label>
  </div>
</ng-template>
