<ng-container *ngIf="mode === 'DEFAULT'; else searchMode">
  <form id="location-form" [formGroup]="form" class="w-full" *ngIf="form">
    <div class="flex w-full flex-row gap-2">
      <mat-form-field
        class="fuse-mat-dense flex-auto"
        [appearance]="appearance"
      >
        <mat-label class="text-sm font-light">Unit Number</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Unit Number"
          formControlName="unitNumber"
        />
      </mat-form-field>
      <mat-form-field
        class="fuse-mat-dense flex-auto"
        [appearance]="appearance"
      >
        <mat-label class="text-sm font-light">Street Number</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Street Number"
          formControlName="streetNumber"
        />
      </mat-form-field>
    </div>

    <mat-form-field class="fuse-mat-dense w-full" [appearance]="appearance">
      <mat-label class="text-sm font-light">Street Address</mat-label>
      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        matInput
        placeholder="Street Name"
        formControlName="streetName"
      />
    </mat-form-field>

    <mat-form-field class="fuse-mat-dense w-full" [appearance]="appearance">
      <mat-label class="text-sm font-light">Suburb </mat-label>
      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        matInput
        placeholder="Suburb"
        formControlName="locality"
      />
    </mat-form-field>

    <!-- <fleet-locality-autocomplete
    [regionCode]="form?.get('regionCode').value"
    class="flex-auto"
    label="Suburb"
    countryCode="AUS"
    formControlName="locality"
    classes="fuse-mat-dense w-full"
    (localityLatLng)="localityLatLng.emit($event)"
  ></fleet-locality-autocomplete> -->

    <div class="flex flex-row gap-2 w-full">
      <fleet-region-selector
        formControlName="regionCode"
        countryCode="AUS"
        placeholder="State"
        label="State"
        class="flex-auto"
        classes="fuse-mat-dense w-full"
      ></fleet-region-selector>

      <mat-form-field
        class="fuse-mat-dense flex-auto"
        [appearance]="appearance"
      >
        <mat-label class="text-sm font-light">Postcode</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Post Code"
          formControlName="postalCode"
        />
      </mat-form-field>
    </div>

    <div class="flex justify-end">
      <button
        mat-flat-button
        type="button"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        Confirm
      </button>
    </div>
  </form>
</ng-container>
<ng-template #searchMode>
  <form id="location-form" [formGroup]="form" class="w-full">
    <div class="flex flex-row gap-2">
      <mat-form-field class="flex-auto" [appearance]="appearance">
        <mat-label>Unit Number</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Unit Number"
          formControlName="unitNumber"
        />
      </mat-form-field>
      <mat-form-field class="flex-auto" [appearance]="appearance">
        <mat-label>Street Number</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Street Number"
          formControlName="streetNumber"
        />
      </mat-form-field>
    </div>
    <div class="flex flex-row gap-2">
      <mat-form-field class="flex-auto" [appearance]="appearance">
        <mat-label>Street Address</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Street Name"
          formControlName="streetName"
        />
      </mat-form-field>
    </div>
    <div class="flex flex-row gap-2">
      <mat-form-field class="flex-auto" [appearance]="appearance">
        <mat-label c>Suburb</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Suburb"
          formControlName="locality"
        />
      </mat-form-field>
    </div>
    <div class="flex flex-row gap-2">
      <fleet-region-selector
        formControlName="regionCode"
        countryCode="AUS"
        placeholder="State"
        label="State"
        class="flex-auto"
        classes="w-full"
        [matLabelClasses]="null"
      ></fleet-region-selector>
      <mat-form-field class="flex-auto" [appearance]="appearance">
        <mat-label>Postcode</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          matInput
          placeholder="Post Code"
          formControlName="postalCode"
        />
      </mat-form-field>
    </div>
  </form>
</ng-template>
