import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AttributeModel, IssueModel } from '@fleet/model';
import { BehaviorSubject } from 'rxjs';

interface AttributeCreateEditState {
  attribute: AttributeModel;
  buttonLabel: string;
  title: string;
  instructions: string;
}

@Component({
  selector: 'fleet-attribute-control-create-edit',
  templateUrl: './attribute-control-create-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeControlCreateEditComponent implements OnInit {
  issues: IssueModel[] = [];
  loading = false;
  addControl = new UntypedFormControl(null, [Validators.required]);

  iconColour = 'accent';
  icon = 'warning';
  attributeCreateEditState: BehaviorSubject<AttributeCreateEditState> =
    new BehaviorSubject(<AttributeCreateEditState>{
      title: 'Create Attribute',
      buttonLabel: 'Create',
      attribute: null,
      instructions: 'Create a new attribute',
    });

  _attribute: AttributeModel;
  @Input() set attribute(value: AttributeModel) {
    this._attribute = value;
    if (value) {
      this.attributeCreateEditState.next({
        ...this.attributeCreateEditState.value,
        buttonLabel: 'Update',
        title: 'Edit Attribute',
        attribute: value,
        instructions: 'Edit the selected attribute',
      });
      this.addControl.patchValue(value);
      this.changeDetectorRef.markForCheck();
    }
  }

  get attribute() {
    return this._attribute;
  }

  @Output() successful = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  saveAttribute() {
    this.successful.emit(this.addControl.value);
  }
}
