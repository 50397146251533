const KEYS_TO_PRESERVE = ['job_search_result_columns', 'active_job_view'];

export function preserveAndClearKeys() {
  const keys = Object.keys(localStorage);

  for (let i = 0; i < keys.length; i++) {
    //are we keeping this key?
    const key = keys[i];
    const foundKey = KEYS_TO_PRESERVE.find((s) => {
      if (key.includes(s)) {
        return s;
      } else {
        return null;
      }
    });
    if (!foundKey) {
      localStorage.removeItem(key);
    }
  }
}
