<a
  [routerLink]="[panel.link]"
  [routerLinkActive]="'hover:bg-gray-100 dark:hover:bg-hover'"
  [routerLinkActiveOptions]="{ exact: true }"
>
  <!-- {{ rla.isActive ? 'I AM ACTIVE' : ''}} -->
  <!-- #rla="routerLinkActive"  -->

  <div
    class="flex px-8 py-5 cursor-pointer"
    [routerLinkActive]="'bg-primary-50 dark:bg-hover'"
  >
    <mat-icon
      [routerLinkActive]="'text-primary dark:text-primary-500'"
      [svgIcon]="panel.icon"
    ></mat-icon>
    <div class="ml-3">
      <div
        class="font-medium leading-6"
        [routerLinkActive]="'text-primary dark:text-primary-500'"
      >
        {{ panel.title }}
      </div>
      <div class="mt-0.5 text-secondary">
        {{ panel.description }}
      </div>
    </div>
  </div>
</a>
