import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BraintreePaymentMethodComponent } from './braintree-payment-method.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ProgressButtonModule } from '@fleet/shared';
import { SidebarFormLayoutModule } from '@fleet/layout';
import { FuseAlertModule } from '@fleet/fuse';
import { ActionButtonsModule, DialogLayoutModule } from '@fleet/ui';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [BraintreePaymentMethodComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    ProgressButtonModule,
    SidebarFormLayoutModule,
    FuseAlertModule,
    ActionButtonsModule,
    AlertsFromIssuesModule,
    DialogLayoutModule,
    MatProgressSpinnerModule,
  ],
  exports: [BraintreePaymentMethodComponent],
})
export class BraintreePaymentMethodModule {}
