<fleet-dialog-layout
  *ngIf="ticketLinkState | async as state"
  [title]="state.title"
  [instructions]="state.instructions"
  [iconColour]="'accent'"
  [icon]="'warning'"
>
  <form
    slot="main"
    *ngIf="form"
    [formGroup]="form"
    class="flex flex-col gap-3 w-full"
  >
    <fleet-ticket-hubspot-control
      (controllerError)="setError($event)"
      *ngIf="!ticket"
      formControlName="externalTicket"
    >
    </fleet-ticket-hubspot-control>

    <ng-container *ngIf="job && ticket">
      <mat-checkbox *ngIf="job.driver" formControlName="linkDriver"
        >Driver ({{ job.driver.name }})
      </mat-checkbox>
      <mat-checkbox *ngIf="job.traveller" formControlName="linkTraveller"
        >Traveller ({{ job.traveller.name }})</mat-checkbox
      >
      <mat-checkbox *ngIf="job.vehicle" formControlName="linkVehicle"
        >Vehicle ({{ job.vehicle.displayName }})
      </mat-checkbox>
      <!-- <mat-checkbox *ngIf="job.payment" formControlName="linkPayment">Link Payment</mat-checkbox> -->
    </ng-container>
  </form>

  <fleet-progress-button
    slot="action"
    [state]="state"
    [disabled]="!form.valid || state.loading"
    (onClick)="linkTicket()"
  >
  </fleet-progress-button>

  <fuse-alert
    slot="error"
    *ngFor="let alert of state.issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>
</fleet-dialog-layout>
