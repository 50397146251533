<ng-container *ngIf="serviceClassesWithLines | async as serviceClassAndLines">
  <div
    *ngIf="style === 'BOTH'; else singleMode"
    class="flex flex-row gap-3 w-full"
    [ngClass]="{
      'flex-col': isStacking,
      'gap-0': isStacking
    }"
  >
    <mat-form-field
      class="w-full"
      [ngClass]="{ 'fuse-mat-dense': inSearchForm }"
    >
      <mat-label [ngClass]="{ 'text-sm font-light': inSearchForm }">
        {{ label ? label : 'Service Class' }}
      </mat-label>
      <mat-select
        [formControl]="serviceClassControl"
        placeholder="Service Class"
      >
        <mat-option *ngIf="includeNoneOption" [value]="''">None </mat-option>
        <mat-option
          *ngFor="
            let class of serviceClassAndLines
              | serviceClassesFromServiceClassAndLines
          "
          [value]="class"
        >
          {{ class.displayName }}
        </mat-option>
      </mat-select>

      <mat-error *ngIf="serviceClassControl?.errors?.noServiceClasses">
        No service classes found
      </mat-error>

      <mat-error *ngIf="serviceClassControl.errors?.required">
        Service class is required
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="w-full"
      [ngClass]="{ 'fuse-mat-dense': inSearchForm }"
    >
      <mat-label [ngClass]="{ 'text-sm font-light': inSearchForm }">
        Service Lines
      </mat-label>
      <mat-select
        [multiple]="singleSelection ? false : true"
        [formControl]="serviceLineControl"
        placeholder="Service Lines"
      >
        <mat-option
          *ngFor="
            let line of serviceClassAndLines
              | serviceLinesFromServiceClassAndLines : serviceClassControl.value
          "
          [value]="line"
        >
          {{ line.displayName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="serviceLineControl?.errors?.noServiceLines">
        No service lines found
      </mat-error>

      <mat-error *ngIf="serviceLineControl?.errors?.required">
        Service line is required
      </mat-error>
    </mat-form-field>
  </div>

  <ng-template #singleMode>
    <div
      *ngIf="viewMode === 'CUSTOM_SELECT'; else select"
      class="bg-card rounded-md shadow-sm border border-gray-300"
      [ngClass]="{
        'border-gray-500': hostFocused,
        'border-red-600':
          singleClassLineControl.errors && singleClassLineControl.touched,
        'opacity-60': readOnly
      }"
    >
      <div
        class="w-full flex flex-row justify-between items-center py-3 px-4"
        (click)="readOnly ? null : toggleList()"
        [ngClass]="{ 'cursor-pointer': !readOnly }"
      >
        <div class="flex shrink flex-row">
          <mat-icon class="mr-3 icon-size-5 text-hint">settings</mat-icon>

          <div
            [ngClass]="{
              'text-red-600':
                singleClassLineControl.errors && singleClassLineControl.touched
            }"
          >
            {{ label ? label : 'Service Class' }}
          </div>
        </div>

        <mat-icon
          [svgIcon]="expanded ? 'expand_less' : 'expand_more'"
          class="icon-size-5"
        >
        </mat-icon>
      </div>
      <div *ngIf="expanded" class="border-t-2"></div>
      <fuse-alert *ngIf="serviceClassAndLines.length === 0" type="warn">
        No Service Classes Available
      </fuse-alert>
      <ng-container *ngIf="expanded; else showSelected">
        <mat-selection-list class="selection-hide-checkboxes">
          <ng-container *ngFor="let c of serviceClassAndLines; let last">
            <ng-container *ngIf="c.lines.length > 0">
              <mat-list-option
                *ngFor="let line of c.lines"
                (click)="lineSelected(line)"
                (keydown.enter)="lineSelected(line)"
                [value]="line.serviceLineId"
              >
                <div mat-line>{{ line.displayName }}</div>
              </mat-list-option>
              <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
          </ng-container>
        </mat-selection-list>
      </ng-container>

      <ng-template #showSelected>
        <div
          class="text-md p-4"
          *ngIf="selectedLine && selectedLine.serviceLineId"
          [ngClass]="{
            'cursor-pointer': !readOnly
          }"
          [ngClass]="{}"
          (click)="readOnly ? null : toggleList()"
        >
          {{ selectedLine?.displayName }}
        </div>
      </ng-template>
    </div>

    <ng-template #select>
      <mat-form-field class="w-full">
        <mat-label *ngIf="label">
          {{ label }}
        </mat-label>
        <mat-select
          [value]="selectedLine?.serviceLineId"
          [disabled]="readOnly"
          placeholder="Service Line"
        >
          <ng-container *ngFor="let c of serviceClassAndLines">
            <mat-option
              *ngFor="let line of c.lines"
              (click)="lineSelected(line)"
              (keydown.enter)="lineSelected(line)"
              [value]="line.serviceLineId"
            >
              {{ line.displayName }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </ng-template>
</ng-container>
