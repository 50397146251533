import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { ApiResponse, IssueModel } from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IvrApiService {
  host;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.ivr;
  }

  searchCalls(
    params: any
  ): Observable<HttpResponse<ApiResponse<any[]>> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/call`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getCall(callId: string): Observable<any> {
    return this.http
      .get(`${this.host}/call/${callId}`)
      .pipe(catchError(handleApiError));
  }
}
