/* eslint-disable @nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobActionPipe } from './job-action.pipe';
import { JobActionComponent } from './job-action.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ProgressButtonModule } from '@fleet/shared';
import { ConfirmationDialogModule } from '@fleet/shared';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { JobCloneModule } from './../job-clone/job-clone.module';
import { JobCancelDialogModule } from '../job-cancel-dialog/job-cancel-dialog.module';

@NgModule({
  declarations: [JobActionComponent, JobActionPipe],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    ProgressButtonModule,
    ConfirmationDialogModule,
    JobCloneModule,
    MatMenuModule,
    MatIconModule,
    JobCancelDialogModule,
  ],
  exports: [JobActionComponent],
})
export class JobActionModule {}
