import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { VersionUpdateService } from '@fleet/version';
@Component({
  selector: 'fleet-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private versionUpdateService: VersionUpdateService
  ) {
    iconRegistry.addSvgIconSetInNamespace(
      'creditcards',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/credit-cards/credit-card-set.svg'
      )
    );
    this.versionUpdateService.initialise();
  }
}
