import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return null;
    }
    const duration = Duration.fromISO(value);
    const hours = duration.as('hours');
    const minutes = duration.as('minutes') % 60;
    const seconds = duration.as('seconds') % 60;
    return `${this.pad(Math.floor(hours))}:${this.pad(
      Math.floor(minutes)
    )}:${this.pad(seconds)}`;
    // if (hours >= 1) {
    //   return `${Math.floor(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    // } else {
    //   return `${Math.floor(minutes)}:${this.pad(seconds)}`;
    // }
  }

  private pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
