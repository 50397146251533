import { Pipe, PipeTransform, Sanitizer, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlaceModel } from '@fleet/model';

@Pipe({
  name: 'locationListItem',
  pure: false,
})
export class LocationListItemPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: PlaceModel, args: any): any {
    if (!args) {
      return value.displayLine1;
    }

    try {
      const re = new RegExp(args, 'gi'); //‘gi’ for case insensitive and can use ‘g’ if you want the search to be case sensitive.
      const formatted = value.displayLine1.replace(re, '<strong>$&</strong>');

      //return formatted + ', ' + value.displayLine2;
      return `<div>${formatted}</div><div class="text-sm text-secondary">${value.displayLine2}</div>`;
    } catch (error: any) {
      return `<div>${value.displayLine1}</div><div class="text-sm text-secondary">${value.displayLine2}</div>`;
    }
  }
}
