<div
  *ngIf="paymentMethodDisplay"
  class="w-full"
  [ngClass]="{ 'bg-white rounded-lg shadow  px-6 py-2': displayConfig.card }"
>
  <div
    class="w-full flex items-center justify-between space-x-6"
    [ngClass]="displayConfig.paddingClass"
  >
    <div class="flex flex-row items-center truncate">
      <fleet-payment-method-icon
        [paymentMethod]="paymentMethod"
        [styleClass]="paymentMethodDisplay.iconStyles"
      >
      </fleet-payment-method-icon>

      <div class="ml-4 -mt-1 truncate gap-1">
        <div class="text-sm font-medium text-gray-900 truncate">
          {{ paymentMethodDisplay.displayLine1 }}
        </div>
        <div
          class="text-sm text-secondary truncate"
          *ngIf="paymentMethodDisplay.displayLine2"
        >
          {{ paymentMethodDisplay.displayLine2 }}
        </div>
        <div
          *ngIf="paymentMethodDisplay.expiry"
          class="text-sm text-gray-600 truncate"
        >
          {{ paymentMethodDisplay.expiry }}
        </div>
      </div>
    </div>
  </div>
</div>
