import { Component, Input, OnInit } from '@angular/core';
import { BatchExportModel } from '@fleet/model';
import { Observable } from 'rxjs';
import { ExportDisplay, ExportService } from '../export/export.service';

@Component({
  selector: 'fleet-batch-export-item',
  templateUrl: './batch-export-item.component.html',
  styleUrls: ['./batch-export-item.component.scss'],
})
export class BatchExportItemComponent implements OnInit {
  timer: Observable<any>;
  _exportDisplay: ExportDisplay;
  @Input() set exportDisplay(value: ExportDisplay) {
    this._exportDisplay = value;
    if (
      (value && value.export.status === 'STARTED') ||
      value.export.status === 'IN_PROGRESS'
    ) {
      setTimeout(() => {
        // this.exportService.getExportStatus(value);
      }, 3000);
      //
    }
  }

  get exportDisplay() {
    return this._exportDisplay;
  }
  constructor(private exportService: ExportService) {}

  ngOnInit(): void {}
}
