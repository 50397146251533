<fleet-dialog-layout title="Login to exit kiosk mode">
  <div slot="main">
    <form
      *ngIf="loginForm"
      class="mt-8"
      [formGroup]="loginForm"
      #signInNgForm="ngForm"
    >
      <mat-form-field class="w-full">
        <mat-label>Email address</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          id="email"
          matInput
          [formControlName]="'username'"
        />
        <mat-error *ngIf="loginForm.get('username').hasError('required')">
          Email address is required
        </mat-error>
        <mat-error *ngIf="loginForm.get('username').hasError('email')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>Password</mat-label>
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          id="password"
          matInput
          type="password"
          [formControlName]="'password'"
          #passwordField
        />
        <button
          mat-icon-button
          type="button"
          (click)="
            passwordField.type === 'password'
              ? (passwordField.type = 'text')
              : (passwordField.type = 'password')
          "
          matSuffix
        ></button>
        <mat-error> Password is required </mat-error>
      </mat-form-field>

      <fuse-alert
        *ngFor="let alert of error | alertsFromIssues"
        class="py-3"
        [appearance]="'outline'"
        [showIcon]="true"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
      >
        {{ alert.message }}
      </fuse-alert>
      <button
        mat-flat-button
        class="bg-primary-600 text-white disabled:bg-gray-200 fuse-mat-button-large w-full mt-6"
        [disabled]="loginForm.disabled || loginForm.invalid || loading"
        (click)="submit()"
      >
        <span *ngIf="!loginForm.disabled"> Submit </span>
        <mat-progress-spinner
          *ngIf="loading"
          [diameter]="24"
          [mode]="'indeterminate'"
        ></mat-progress-spinner>
      </button>
    </form>
  </div>
</fleet-dialog-layout>
