<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <mat-drawer-container
    (backdropClick)="closeDrawer()"
    class="flex-auto h-full dark:bg-transparent"
  >
    <!-- Drawer -->
    <mat-drawer
      class="w-full sm:w-80 dark:bg-gray-900"
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened$ | async"
      #drawer
    >
      <!-- Back to Detail -->
      <a
        *ngIf="showBackButton"
        class="m-8 mr-6 mt-4 flex flex-row items-center"
        [routerLink]="customURL ? [customURL] : ['../']"
      >
        <mat-icon [svgIcon]="'heroicons_outline:arrow-sm-left'"></mat-icon>
        Back
      </a>
      <!-- Header -->
      <div class="flex items-center justify-between m-8 mr-6 sm:mt-4 sm:mb-6">
        <!-- Title -->
        <div class="text-2xl font-extrabold tracking-tight leading-none">
          Settings
        </div>
        <!-- Close button -->
        <div [class.hidden]="drawerMode === 'side'">
          <button mat-icon-button (click)="closeDrawer()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
          </button>
        </div>
      </div>
      <ng-container *ngIf="drawerMode === 'over'">
        <div class="m-6 overflow-hidden">
          <ng-content select="[slot=settings-sidebar-header]"></ng-content>
        </div>
      </ng-container>

      <!-- Card #42 -->

      <!-- Panel links -->
      <div class="flex flex-col divide-y border-t border-b">
        <ng-container *ngFor="let item of navigationMenu">
          <fleet-settings-tab-label [panel]="item"> </fleet-settings-tab-label>
        </ng-container>
      </div>
    </mat-drawer>

    <!-- Drawer content -->
    <mat-drawer-content class="absolute inset-0 flex flex-col">
      <!-- <mat-icon
        (click)="toggleDrawer()"
        class="pt-9 px-12 cursor-pointer"
        *ngIf="drawerMode === 'over'"
        >settings</mat-icon
      > -->
      <span
        (click)="toggleDrawer()"
        class="pt-9 px-6 cursor-pointer underline"
        *ngIf="hideDrawer !== true && drawerMode === 'over'"
        >Settings</span
      >
      <ng-content select="[slot=entity-header]"></ng-content>

      <ng-content select="[slot=main]"></ng-content>
      <!-- Main -->
      <!-- <div
        class="flex flex-col flex-auto px-6 pt-6 pb-12 md:p-8 md:pb-12 lg:p-12"
      >

        <div class="flex flex-col">

          <div class="flex flex-row gap-4 items-center">
            <button
              class="lg:hidden -ml-2"
              mat-icon-button
              (click)="drawer.toggle()"
            >
              <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>

            <ng-content select="[slot=entity-content]"></ng-content>
          </div>


        </div>


        <div class="flex flex-auto mt-8">
          <router-outlet></router-outlet>
        </div>
      </div> -->
    </mat-drawer-content>
  </mat-drawer-container>
</div>
