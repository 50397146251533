import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  LoginModel,
  NetworkUserModel,
  VerificationModel,
} from '@fleet/model';
import { AuthApiService, DriverApiService } from '@fleet/api';
import { AuthService } from './../../services/auth.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'fleet-change-phonenumber-dialog',
  templateUrl: './change-phonenumber-dialog.component.html',
  styleUrls: ['./change-phonenumber-dialog.component.scss'],
})
export class ChangePhonenumberDialogComponent implements OnInit {
  verification: VerificationModel;
  @Input() user: any;
  phonenumberControl = new UntypedFormControl(null, [Validators.required]);
  codeControl = new UntypedFormControl(null, [Validators.required]);
  fleetProduct: string;
  loading = false;
  issues: IssueModel[] = [];
  hasPhonenumberChanged = false;

  jwt: string;
  @Output() changeSuccess = new EventEmitter();

  constructor(
    private driverApiService: DriverApiService,
    private authService: AuthService,
    @Inject('env') env: any,
    private matDialog: MatDialogRef<ChangePhonenumberDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  ngOnInit(): void {}

  begin() {
    //DP
    this.loading = true;
    this.issues = [];
    this.changeDetectorRef.markForCheck();
    const verifyPhonePayload = {
      phoneNumber: this.phonenumberControl.value,
    } as VerificationModel;

    this.authService.verifyPhoneNumber(verifyPhonePayload, true).subscribe({
      next: (resp: ApiResponse<VerificationModel>) => {
        this.verification = resp.data;
        if (this.verification && this.verification.code) {
          this.codeControl.setValue(this.verification.code);
        }
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      },
      error: (error: any) => {
        this.loading = false;
        this.issues = error;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  verify() {
    this.loading = true;
    this.issues = [];
    this.changeDetectorRef.markForCheck();
    this.authService
      .verify({
        ...this.verification,
        code: this.codeControl.value,
      })
      .subscribe({
        next: (resp: ApiResponse<VerificationModel>) => {
          this.verification = resp.data;
          if (resp.data.token) {
            const payload = {
              verificationToken: resp.data.token,
            } as LoginModel;
            this.authService.changePhoneNumber(payload).subscribe({
              next: (resp: any) => {
                this.hasPhonenumberChanged = true;

                //POST TO MOBILE APP
                // try {
                //   (window as any).MobileCallBack.postMessage(
                //     JSON.stringify({
                //       action: 'CHANGE_PHONENUMBER',
                //       data: {
                //         loginModel: payload,
                //       },
                //     })
                //   );
                // } catch (error) {
                //   console.log(error);
                // }
                this.loading = false;
                this.changeDetectorRef.markForCheck();
                this.changeSuccess.emit(this.phonenumberControl.value);
              },
              error: (error: any) => {
                this.loading = false;
                this.issues = error;
                this.changeDetectorRef.markForCheck();
              },
            });
          }
        },
        error: (error: any) => {
          this.loading = false;
          this.issues = error;
          this.changeDetectorRef.markForCheck();
        },
      });
  }
}
