<span
  class="truncate inline-flex items-center text-xs font-medium leading-none"
  [ngClass]="variationClass"
>
  <svg
    *ngIf="withDot"
    class="h-1.5 w-1.5"
    [ngClass]="svgClass"
    viewBox="0 0 6 6"
    aria-hidden="true"
  >
    <circle cx="3" cy="3" r="3" />
  </svg>

  <span class="truncate min-w-0">{{ label }}</span>

  <button
    type="button"
    [ngClass]="buttonClass"
    class="group relative -mr-1 h-3.5 w-3.5 rounded-sm"
    *ngIf="withButton"
    (click)="removeBadge.emit()"
  >
    <svg viewBox="0 0 14 14" class="h-3.5 w-3.5" [ngClass]="svgClass">
      <path d="M4 4l6 6m0-6l-6 6" />
    </svg>
    <span class="absolute -inset-1"></span>
  </button>
</span>
