import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { BatchExportModel } from '@fleet/model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExportDisplay, ExportService } from './export.service';

@Component({
  selector: 'fleet-export',
  templateUrl: './export.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportComponent implements OnInit {
  @ViewChild('exportsOrigin') private _exportsOrigin: ElementRef;
  @ViewChild('exportsPanel') private _exportsPanel: TemplateRef<any>;
  exports: ExportDisplay[];
  _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private exportService: ExportService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.exportService.exports$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (exportList: ExportDisplay[]) => {
          if (exportList.length > 0) {
            if (
              !this.exports ||
              (this.exports && this.exports.length != exportList.length)
            ) {
              this.openPanel();
            }
          }
          this.exports = exportList;
          this._changeDetectorRef.markForCheck();
        },
      });
  }

  trackByFn(index: number, ex: ExportDisplay) {
    return ex ? ex.export.batchExportId : null;
  }

  closePanel() {
    this._overlayRef.detach();
  }

  openPanel() {
    if (!this._exportsPanel || !this._exportsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._exportsPanel, this._viewContainerRef)
    );
  }

  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._exportsOrigin.nativeElement)
        .withLockedPosition()
        .withPush(true)
        .withPositions([
          {
            panelClass: 'mt-4',
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            panelClass: 'mt-4',
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            panelClass: 'mt-4',
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            panelClass: 'mt-4',
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  clearExports() {
    this.closePanel();
    this.exportService.clearExports();
  }
}
