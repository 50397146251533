import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobColorsPipe } from './job-colors.pipe';

@NgModule({
  declarations: [JobColorsPipe],
  imports: [CommonModule],
  exports: [JobColorsPipe],
})
export class JobColorsPipeModule {}
