<ng-container *ngIf="icons">
  <div class="flex flex-col gap-1" *ngIf="mode === 'FULL'; else iconsOnly">
    <div class="flex items-center">
      <mat-icon [ngClass]="iconSize" class="mr-2">{{
        icons.productTypeIcon
      }}</mat-icon>
      <div class="text-md">
        {{ icons?.productTypeLabel | titleCaseAndClean }}
      </div>
    </div>

    <div class="flex items-center">
      <mat-icon [ngClass]="iconSize" class="mr-2">{{
        icons.channelTypeIcon
      }}</mat-icon>
      <div class="text-md">
        {{ icons?.channelTypeLabel | titleCaseAndClean }}
      </div>
    </div>
  </div>

  <ng-template #iconsOnly>
    <div class="flex flex-row gap-1">
      <mat-icon
        [ngClass]="iconSize"
        matTooltip="{{ icons?.productTypeLabel | titleCaseAndClean }}"
      >
        {{ icons.productTypeIcon }}</mat-icon
      >
      <mat-icon
        [ngClass]="iconSize"
        matTooltip="{{ icons?.channelTypeLabel | titleCaseAndClean }}"
      >
        {{ icons.channelTypeIcon }}</mat-icon
      >
    </div>
  </ng-template>
</ng-container>
