import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceLineFromIdPipe } from './service-line-from-id.pipe';

@NgModule({
  declarations: [ServiceLineFromIdPipe],
  imports: [CommonModule],
  exports: [ServiceLineFromIdPipe],
})
export class ServiceLineFromIdPipeModule {}
