import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogLayoutComponent implements OnInit {
  @Input() iconColour = 'accent';
  @Input() icon: string;
  @Input() showCancel = true;
  @Input() title: string;
  @Input() instructions: string;
  @Input() mainPaddingClasses = 'p-8 pb-6 sm:pb-8';
  @Input() showClose = true;
  @Input() cancelLabel = 'Cancel';
  @Output() cancelled = new EventEmitter();
  @Input() width = 'md:w-128';
  @Input() padding: string = null;
  @Input() margin = '-m-6';

  constructor() {}

  ngOnInit(): void {}
}
