import { Pipe, PipeTransform } from '@angular/core';
import { LifecycleTransitionModel } from '@fleet/model';
import { DateTime } from 'luxon';

@Pipe({
  name: 'lifecycleTransitionAction',
})
export class LifecycleTransitionActionPipe implements PipeTransform {
  transform(
    value: LifecycleTransitionModel
  ): { label: string; type: string }[] {
    if (value) {
      const test = DateTime.fromISO(value.effectiveFrom as any);
      const isDateEffectiveFromInFuture = test > DateTime.now() ? true : false;

      if (isDateEffectiveFromInFuture) {
        return [
          {
            label: 'Delete Transition',
            type: 'DELETE',
          },
        ];
      }
    }

    return null;
  }
}
