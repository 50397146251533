import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { FuseCardModule } from '@fleet/fuse';
import {
  JobColorsPipeModule,
  MobileFormatModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { JobCardComponent } from './job-card.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { BadgeModule, LetterIconModule, SourceModule } from '@fleet/ui';

import { StatusChipModule } from '@fleet/ui';
import { JobOrganisationCardModule } from './../job-organisation-card/job-organisation-card.module';
import { JobCardPipe } from './job-card.pipe';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';

@NgModule({
  declarations: [JobCardComponent, JobCardPipe],
  imports: [
    CommonModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    TitleCaseAndCleanPipeModule,
    MatIconModule,
    FuseCardModule,
    MatButtonModule,
    RouterModule,
    MatDividerModule,
    StatusChipModule,
    HasFunctionPipeModule,
    LetterIconModule,
    MobileFormatModule,
    SourceModule,
    MatTooltipModule,
    JobOrganisationCardModule,
    BadgeModule,
    InternationalizationDatePipeModule,
    JobColorsPipeModule,
  ],
  exports: [JobCardComponent],
})
export class JobCardModule {}
