import { Pipe, PipeTransform } from '@angular/core';
import { JobModel, PlaceModel } from '@fleet/model';
import {
  placeModelArrayFromJobDetail,
  waypointLetterFromAlphabet,
} from '@fleet/utilities';
@Pipe({
  name: 'jobCard',
})
export class JobCardPipe implements PipeTransform {
  transform(job: JobModel, ...args: unknown[]): any | PlaceModel[] {
    if (args) {
      if (args[0] === 'waypoint-display') {
        const waypointPlacesWithLetter = [];
        const waypoints = placeModelArrayFromJobDetail(job.jobDetail);
        for (let i = 0; i < waypoints.length; i++) {
          waypointPlacesWithLetter.push({
            place: waypoints[i],
            letter: waypointLetterFromAlphabet(i),
          });
        }
        return waypointPlacesWithLetter;
      }
    }
    return null;
  }
}
