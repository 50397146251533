import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { encodeParams, isTokenValid } from '@fleet/utilities';
import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private socket: WebSocket;
  private messages: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private isOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly retryInterval: number = 5000; // 5 seconds
  private readonly maxRetries: number = 5;
  private connectionAttempts = 0;

  private heartbeatInterval: any;
  private readonly heartbeatMessage: any = 'X';
  private readonly heartbeatDelay: number = 60000; // 60 seconds
  fleetProduct: string;
  host: string;
  uuid: string;
  topicPrefix: string;
  topic: string;
  socketAcknowledgeEndpoint: string;

  get messages$() {
    return this.messages.asObservable();
  }

  get isOpen$() {
    return this.isOpen.asObservable();
  }

  constructor(@Inject('env') env: any, private httpClient: HttpClient) {
    this.host = env.socketHost;
    this.topicPrefix = env.socketTopicPrefix;
    this.fleetProduct = env.fleetProduct;
    this.socketAcknowledgeEndpoint = env.socketAcknowledge;
  }

  connect(topic: string): Subject<any> {
    this.topic = topic;
    const headersAsQueryString = encodeParams({
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'fleet-channel': 'WEB',
      'fleet-product': this.fleetProduct,
      'X-Atmosphere-tracking-id': this.getUuid(topic),
      'Content-Type': 'application/json',
      'X-Atmosphere-Transport': 'websocket',
      'X-Atmosphere-TrackMessageSize': false,
      'X-atmo-protocol': true,
    }).toString();

    const url = `${this.host}${this.topicPrefix}${topic}?${headersAsQueryString}`;
    this.socket = new WebSocket(url);

    this.socket.onopen = (event) => {
      this.isOpen.next(true);
      console.log('WebSocket opened:', event);
      this.connectionAttempts = 0; // Reset the connection attempts

      // Start the heartbeat when the connection is opened
      this.startHeartbeat();
    };

    this.socket.onmessage = (event: any) => {
      if (event.data && event.data.length > 0) {
        try {
          const message =
            event.data && event.data.length > 0 ? JSON.parse(event.data) : null;
          if (message) {
            console.log(message);
            this.messages.next(message);
            if (
              message.acknowledge === undefined ||
              message.acknowledge === true
            ) {
              this.acknowledgeSocketMessage(message, topic);
            }
          }
        } catch (error: any) {
          console.log('Couldnt parse socket message: ' + event.data);
        }
      }
    };

    this.socket.onerror = (error) => {
      console.log('WebSocket error:', error);
    };

    this.socket.onclose = (event) => {
      this.isOpen.next(false);
      // Clear the heartbeat interval when the connection is closed
      this.clearHeartbeat();

      if (event.wasClean) {
        this.topic = '';

        console.log(
          `Closed cleanly, code=${event.code}, reason=${event.reason}`
        );
      } else {
        console.log('Connection died');
        this.tryReconnect();
      }
    };

    return this.messages;
  }

  private tryReconnect(): void {
    const jwt = localStorage.getItem('access_token');
    if (isTokenValid(jwt)) {
      if (this.connectionAttempts < this.maxRetries) {
        this.connectionAttempts++;
        console.log(`Attempt ${this.connectionAttempts} to reconnect...`);
        setTimeout(() => {
          this.connect(this.topic);
        }, this.retryInterval);
      } else {
        this.topic = '';
        console.error('Max reconnection attempts reached');
      }
    } else {
      console.log('Attempted socket reconnect cancelled due to invalid jwt');
    }
  }

  private startHeartbeat(): void {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(this.heartbeatMessage);
      }
    }, this.heartbeatDelay);
  }

  private clearHeartbeat(): void {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  send(data: any): void {
    this.socket.send(data);
  }

  close(): void {
    this.clearHeartbeat();
    if (this.socket) {
      this.topic = '';

      this.socket.close();
    }
  }

  getUuid(topic: string) {
    this.uuid = localStorage.getItem('socket-uuid-' + topic);
    if (!this.uuid) {
      console.log('no socket uuid for topic in local');
      this.uuid = uuidv4();
      localStorage.setItem('socket-uuid-' + topic, this.uuid);
    }
    return this.uuid;
  }

  acknowledgeSocketMessage(message: any, topic: string) {
    if (message.uuid) {
      const socketAcknowledgeEndpoint =
        this.socketAcknowledgeEndpoint.replace('{{clientId}}', this.uuid) +
        message.uuid;
      this.httpClient
        .post(socketAcknowledgeEndpoint, {
          broadcasterId: this.topicPrefix + topic,
          timestamp: message.timestamp,
        })
        .subscribe(
          (resp) => {
            console.log('socket acknowledged: ' + message.uuid);
          },
          (error) => {
            console.log('socket acknowledged failure: ' + error);
          }
        );
    }
  }
}
