import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

@Component({
  standalone: true,
  selector: 'fleet-custom-calendar-header',
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'D',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'fff',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
    { provide: LOCALE_ID, useValue: 'en-AU' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
  ],
  template: `
    <div class="px-2 flex flex-col w-full">
      <div class="flex items-center justify-center w-full">
        {{ selectionLabel }}
      </div>
      <div class="flex justify-between items-center w-full">
        <button
          mat-icon-button
          (click)="previousClicked('year')"
          class="flex-none"
        >
          <mat-icon>keyboard_double_arrow_left</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="previousClicked('month')"
          class="flex-none"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span class="flex-grow text-center mx-4">{{ periodLabel }}</span>
        <button
          mat-icon-button
          (click)="nextClicked('month')"
          class="flex-none"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button mat-icon-button (click)="nextClicked('year')" class="flex-none">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class CustomCalendarHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  @Input() selectionLabel: string;

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
      cdr.markForCheck();
      cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  updateLabel(label: string) {
    this.selectionLabel = label;
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
