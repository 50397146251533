import { HttpParameterCodec, HttpParams } from '@angular/common/http';
export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export function encodeParams(params: any) {
  let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
  Object.keys(params).forEach((key) => {
    //This will trim any leading/ending spaces on inputs
    if (typeof params[key] === 'string') {
      params[key] = (params[key] as string).trim();
    }
    httpParams = httpParams.set(key, params[key]);
  });
  return httpParams;
}

export function removeEmptyParams(searchParams: any) {
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key] == null || searchParams[key].toString() == '') {
      delete searchParams[key];
    }
  });
  return searchParams;
}
