import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SearchResult, PageData } from '@fleet/model';
import { DateTime } from 'luxon';

export function searchResultFromResponse(resp: HttpResponse<any>, params: any) {
  let totalCount = 0;
  let totalPages = 0;
  let currentPage = 0;

  if (resp) {
    totalCount = Number(resp.headers.get('x-total-count'));
    totalPages = Number(resp.headers.get('x-total-pages'));
    currentPage = Number(resp.headers.get('x-current-page'));
  }

  return <SearchResult>{
    totalCount: totalCount,
    totalPages: totalPages,
    currentPage: currentPage,
    params: params,
  };
}

export function pageDataFromSearchResultHeaders(
  headers: HttpHeaders,
  params?: any
): PageData {
  const pageData: PageData = <PageData>{
    params: params,
  };
  try {
    pageData.totalCount = Number(headers.get('x-total-count'));
    pageData.totalPages = Number(headers.get('x-total-pages'));
    pageData.currentPage = Number(headers.get('x-current-page'));
    pageData.morePages = headers.get('x-more-pages') === 'true' ? true : false;
  } catch {
    pageData.totalCount = 0;
    pageData.totalPages = 0;
    pageData.currentPage = 0;
    pageData.morePages = null;
  }
  return pageData;
}

export function compareForSort(
  a: number | string,
  b: number | string,
  isAsc: boolean
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function compareForSortDate(a: DateTime, b: DateTime, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
export function removeNullVariables(obj: any): any {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null) {
      (acc as any)[key] = value;
    }
    return acc;
  }, {});
}
