import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveJobPipe } from './active-job.pipe';

@NgModule({
  declarations: [ActiveJobPipe],
  imports: [CommonModule],
  exports: [ActiveJobPipe],
})
export class ActiveJobPipeModule {}
