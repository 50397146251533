import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { PaymentTransactionModel } from '@fleet/model';
import { DateTime } from 'luxon';

export class PaymentTransactionGroup {
  groupingReference: string;
  firstTransactionDate: DateTime;
  purchaseTransactions: PaymentTransactionModel[];
  refundTransactions: PaymentTransactionModel[];
  authorisationTransactions: PaymentTransactionModel[];
  voidTransaction: PaymentTransactionModel;
  combinedResults: PaymentTransactionModel[];
  totalRefundableAmount: number;
}
@Component({
  selector: 'fleet-job-sale-transaction-results',
  templateUrl: './job-sale-transaction-results.component.html',
  styleUrls: ['./job-sale-transaction-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobSaleTransactionResultsComponent implements OnInit {
  showRefunds = true;
  @Output() viewEFTPOSReceipt = new EventEmitter();
  @Output() refundTransaction = new EventEmitter();
  @Output() emailReceipt = new EventEmitter();
  fleetProduct: string;

  @Input() transactions: PaymentTransactionGroup[];
  constructor(@Inject('env') env: any) {
    this.fleetProduct = env.fleetProduct;
  }

  ngOnInit(): void {}
}
