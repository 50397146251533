import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityBlockAttributeListComponent } from './entity-block-attribute-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaymentMethodCardModule } from '@fleet/payment';
import { PaymentMethodListItemModule } from '@fleet/payment';

@NgModule({
  declarations: [EntityBlockAttributeListComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TitleCaseAndCleanPipeModule,
    MatListModule,
    MatCheckboxModule,
    PaymentMethodCardModule,
    PaymentMethodListItemModule,
  ],
  exports: [EntityBlockAttributeListComponent],
})
export class EntityBlockAttributeListModule {}
