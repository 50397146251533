<fleet-dialog-layout
  (cancelled)="cancelled.emit()"
  [title]="'Cancel Job'"
  [instructions]="'Are you sure you want to cancel this job?'"
  [iconColour]="'basic'"
  [icon]="'info'"
>
  <fuse-alert
    slot="error"
    *ngFor="let alert of issues | alertsFromIssues"
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <div
    slot="main"
    class="flex flex-col"
    *ngIf="fleetProduct === 'HUB' || fleetProduct === 'BUSINESS'"
  >
    <span class="text-md mb-4">Reason</span>
    <mat-radio-group
      required
      class="flex flex-col gap-3"
      [formControl]="reasonControl"
    >
      <mat-radio-button
        class="text-md"
        *ngFor="let reason of reasons$ | async"
        [value]="reason.code"
      >
        {{ reason.displayValue }}</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <fleet-progress-button
    slot="action"
    [state]="{ loading: loading, buttonLabel: 'YES' }"
    [disabled]="
      fleetProduct === 'HUB' || fleetProduct === 'BUSINESS'
        ? !reasonControl.value
        : false
    "
    (onClick)="cancelJob()"
  >
  </fleet-progress-button>
</fleet-dialog-layout>
