import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-card-description-list',
  templateUrl: './card-description-list.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDescriptionListComponent implements OnInit {
  @Input() title: string = null;
  @Input() subtitle: string = null;
  @Input() subsection: {
    title: string;
    subtitle: string;
  };
  @Input() backgroundClassOnCard: string;
  constructor() {}

  ngOnInit(): void {}
}
