import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationFromMapComponent } from './location-from-map.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapModule } from '@fleet/map';
import { ReverseGeoLocationModule } from '../reverse-geo-location/reverse-geo-location.module';

@NgModule({
  declarations: [LocationFromMapComponent],
  imports: [
    CommonModule,

    MatDialogModule,
    MatButtonModule,
    MatSidenavModule,
    GoogleMapsModule,
    MapModule,
    ReverseGeoLocationModule,
  ],
  exports: [LocationFromMapComponent],
})
export class LocationFromMapModule {}
