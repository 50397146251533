import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthorisationService } from '@fleet/authorisation';
import { Observable } from 'rxjs';
import { AuthService } from './../services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class FunctionAuthorisedGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,

    private authorisationService: AuthorisationService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (route.data.functions && route.data.functions.length > 0) {
      let hasFunction = false;
      route.data.functions.forEach((functionAction: string) => {
        const split = functionAction.split(':');
        const functionType = split[0];
        const action = split[1];

        if (this.authService.userHasFunction(functionType, action)) {
          hasFunction = true;
        }
      });

      if (hasFunction) {
        return true;
      } else {
        //onscreen notfiicatn Not Authorised
        const lastSlashIndex = state.url.lastIndexOf('/');
        this.authorisationService.setNotAuthorisedRouteDetails({
          route: '',
          functionTypes: route.data.functions,
        });
        if (route.data.nonAuthorisedRedirect) {
          this.router.navigate([route.data.nonAuthorisedRedirect]);
        } else {
          let unauthorisedRoute =
            state.url.substring(0, lastSlashIndex) + '/not-authorised';
          if (route.data.nonAuthorisedRedirectRemove) {
            unauthorisedRoute = unauthorisedRoute.replace(
              route.data.nonAuthorisedRedirectRemove,
              ''
            );
          }
          this.router.navigate([unauthorisedRoute]);
        }

        return false;
      }
    }

    return false;
  }
}
